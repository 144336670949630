/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
//constructor function
function Users(app) {
    this.application = app;
    this.api = app.api;
    this.genericError = this.application.GetConfigSetting("UserErrorMessage_GenericUserMessage");

    this.userService = window.$$userRepositoryService;
    this.addressBookService = window.$$addressBookRepositoryService;

}

Users.prototype.CreateUser = function(email, callBackFunc) {
    if (isset(email) && validateEmail(email)) {
        var userData = this.application.GetUserData();
        //console.log("empty emails...");
        var _self = this;
        //this.addressBookService.remove(userData.userId).then(function(){
            console.log("creating new user...");
            _self.api.CallAPI("users", "create", {Email:email}, callBackFunc);
        //});
    } else {
        this._showErrorDialogue("empty email (Users CreateUser method). email: " + email);
    }
};

Users.prototype.Reset = function() {
    this.api.CallAPI("users", "resetTrial", "", function(){});
};

Users.prototype.ResetCache = function(cb) {
    this.api.CallAPI("users", "resetCache", "", cb);
};

Users.prototype.AddAddressToBook = function(email, callBackFunc) {
    if (isset(email) && validateEmail(email)) {
        this.api.CallAPI("addressBook", "create", {Email:email}, function(){
            var json = jsonDecode(this.responseText);
            if(isFunc(callBackFunc)) callBackFunc(json);
        });
    } else {
        throw new Error("Empty or invalid email address");
    }
};

Users.prototype.GetAddressBook = function(callBackFunc) {
    this.api.CallAPI("addressBook", "load", "", function(){
        var json = jsonDecode(this.responseText);
        if(isFunc(callBackFunc)) callBackFunc(json);
    });
};

Users.prototype.DeleteFromAddressBook = function(email, callBackFunc) {
    this.api.CallAPI("addressBook", "delete", {Email:email}, function(){
        var json = jsonDecode(this.responseText);
        if(isFunc(callBackFunc)) callBackFunc(json);
    });
};

Users.prototype.LoadUserAvatar = deprecated(function Users__LoadUserAvatar (userId, callBackFunc) {
    if (isset(userId)) {
        console.log('Hello developer, please track this function\'s usage and remove it. We have the `ppAvatar` directive for this.');
        debugger;

        var self = this;
        this.api.CallAPI("users", "getAvatar", userId, function(){
            var imageData = "";
            var decodedStr = jsonDecode(this.responseText);
            if(decodedStr["ResponseStatus"] == "NotFound"){
                callBackFunc(imageData, userId);
            }else if(isset(decodedStr["Image"])){
                imageData = decodedStr["Image"];
                callBackFunc(self.application.GetConfigSetting("DataUrlImage") + imageData, userId);
            }
        });
    } else {
        throw new Error("Empty user id.");
    }
});

Users.prototype.SearchUser = function(email, callBackFunc) {
    if (isset(email) && validateEmail(email)) {
        this.api.CallAPI("users", "searchByEmail", {Email:email}, function(){
            var event = $.Event('search-by-email', {email: email});
            $(window).trigger(event);
            if (isFunc(callBackFunc)) callBackFunc.apply(this, arguments);
        });
    } else {
        this._showErrorDialogue("empty email (Users SearchUser method). email: " + email);
    }
};

Users.prototype.Load = function(userId, callBackFunc) {
    if (isset(userId)) {
        this.api.CallAPI("users", "load", userId, callBackFunc);
    } else {
        this._showErrorDialogue("empty userUUID (Users Load method)");
    }
};

Users.prototype.LoadFromRepo = function(userId, callBackFunc) {
    if (isset(userId)) {
        this.userService.get(userId).then(function(data){
            callBackFunc(data);
        });
    } else {
        this._showErrorDialogue("empty userUUID (Users Load method)");
    }
};

Users.prototype.Register = function(email, password, callBackFunc) {
    if (isset(email) && isset(password) && validateEmail(email)) {
        this.api.CallAPI("users", "register", {Email: email, Password: password, PublicKey: '#pubKey', PrivateKey: '#priKey'}, callBackFunc);
    } else {
        this._showErrorDialogue("empty params (Users Register method). email: " + email + ", password " + password + ", email: " + email);
    }
};

Users.prototype.Update = function(userId, name, email, dob, callBackFunc) {
    if( isset(userId) ){
        var updateObj = {};
        if( isset(name) ){
            updateObj.Name = name;
        }
        if( isset(email) ){
            updateObj.email = email;
        }
        if( isset(dob) ){
            //updateObj.email = dob;
        }
        updateObj.userId = userId;
        if( isset(updateObj) ){
            this.api.CallAPI("users", "update", updateObj, callBackFunc);
        } else {
            this._showErrorDialogue("empty updateData (Users Update method)");
        }
    } else {
        this._showErrorDialogue("empty userId (Users Update method)");
    }
};

Users.prototype.Domain_SetUserEnabled = function (email, enabled, callback) {
    if (typeof enabled !== 'boolean') {
        throw Error('Users.Domain_SetUserEnabled was passed a non-boolean argument (' + enabled + ').');
    }

    this.api.CallAPI('domain', 'setUserStatus', { Email: email, Enabled: enabled }, function () {
        var json = jsonDecode(this.responseText);

        callback(json.Error || null, json);
    });
};

Users.prototype._showErrorDialogue = function(techInfo) {
    this.application.showErrorBox(this.genericError, techInfo);
};
