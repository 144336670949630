/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class CommentsController {
    /**
     * The selected comment.
     *
     * @type {PPProofComment}
     */
    selectedComment = null;

    /**
     * An array of all the containing child comment controllers.
     *
     * @type {CommentController[]}
     */
    commentCtrls = [];

    /**
     * Callback when the selection changes/updates.
     *
     * @type {Function}
     */
    whenSelectionUpdate = null;

    /**
     * @constructor
     */
    constructor ($q, $element, domService) {
        this.$$ = { $q, $element, domService };
    }

    $onDestroy = () => {
        this.unselectComment();
    }

    /**
     * Update the selected comment.
     *
     * @param {PPProofComment} current
     */
    $updateSelectedComment (current) {
        let previous = this.selectedComment;
        this.selectedComment = current;

        this.whenSelectionUpdate({
            previous,
            current
        });
    }

    /**
     * Un-selects the current comment.
     */
    unselectComment () {
        if (this.selectedComment) {
            this.$updateSelectedComment(null);
        }
    }

    /**
     * Select/un-selects a comment.
     *
     * @param {PPProofComment} comment
     */
    selectComment (comment) {
        if (comment === null) {
            this.unselectComment();
        } else if ( ! this.isSelected(comment)) {
            this.$updateSelectedComment(comment);
        }
    }

    /**
     * Whether a specific comment is currently selected in the group.
     *
     * @param {PPProofComment} comment
     * @returns {Boolean}
     */
    isSelected(comment) {
        if (this.$$.$scope.proofCtrl && !this.$$.$scope.proofCtrl.showPins) {
            //on comment click show pins if they are hidden, while on compare screen no hidden pins, hence no need to call it
            this.$$.$scope.proofCtrl.togglePins();
        }
        return this.selectedComment === comment;
    }

    /**
     * Registers a comment controller.
     *
     * @param {CommentController} commentCtrl
     * @returns {Function}
     */
    registerCommentCtrl (commentCtrl) {
        this.commentCtrls.push(commentCtrl);

        return () => {
            this.deregisterCommentCtrl(commentCtrl);
        };
    }

    /**
     * Deregisters a comment controller.
     *
     * @param {CommentController} commentCtrl
     */
    deregisterCommentCtrl (commentCtrl) {
        let index = this.commentCtrls.indexOf(commentCtrl);

        if (index !== -1) {
            this.commentCtrls.splice(index, 1);
        }
    }

    /**
     * Scrolls to a specific comment (by id).
     *
     * @param {String} id
     * @returns {$q}
     */
    scrollToComment (id) {
        const proofCtrl = this.$$.$scope.proofCtrl || this.$$.$scope.compareProofCtrl;
        return this.$$.domService.scrollTo(
            `#comment-${id || 'creating'},#reply-${id || 'creating'}`,
            500,
            - (proofCtrl.commentPaneHeaderHeight() + proofCtrl.headerHeight())
        );
    }

    scrollToPage(pageNumber) {
        const proofCtrl = this.$$.$scope.proofCtrl || this.$$.$scope.compareProofCtrl;
        return this.$$.domService.scrollTo(
            `.app__comments-page[data-page-number="${pageNumber}"]`,
            500,
            -(proofCtrl.commentPaneHeaderHeight()));
    }

    /**
     * Get the comment object (by id).
     *
     * @param {string} id
     * @returns {PPProofComment}
     */
    getCommentById(id, shouldReturnReply = false) {
        const pages = this.$$.$scope.proofCtrl.proof.pages;
        for (let i = 0; i < pages.length; i++) {
            const comments = pages[i].comments;
            for (let a = 0; a < comments.length; a++) {
                const comment = comments[a];
                if (comment.id === id) {
                    return comment;
                }
                if (comment.replies && comment.replies.length) {
                    const replies = comment.replies;
                    for (let b = 0; b < replies.length; b++) {
                        const reply = replies[b];
                        if (reply.id === id) {
                            return shouldReturnReply ? reply : comment; // returns the parent / reply comment depends what is required.
                        }
                    }
                }
            }
        }
        return null;
    }

    /**
     * Get the comment object (by parent comment number).
     *
     * @param {number} id
     * @param {ppProof} proof
     * @returns {PPProofComment}
     */
    getCommentByNumber(number, proof) {
        const pages = proof ? proof.pages : this.$$.$scope.proofCtrl.proof.pages;
        for (let i = 0; i < pages.length; i++) {
            const comments = pages[i].comments;
            for (let a = 0; a < comments.length; a++) {
                const comment = comments[a];
                if (comment.number === number) {
                    return comment;
                }
            }
        }
        return null;
    }
}

function CommentsDirective (directiveHelper) {
    return {
        restrict: 'E',
        require: ['comments'],
        controller: 'CommentsController',
        controllerAs: 'commentsCtrl',
        transclude: true,
        replace: true,
        scope: true,

        template: `
            <div class="app__comments" ng-transclude></div>
        `,

        link (scope, element, attr, [commentsCtrl]) {
            commentsCtrl.$$.$scope = scope;
            directiveHelper.controllerBinding(scope, attr, 'bind', commentsCtrl);
            directiveHelper.callbackBinding(scope, attr, 'whenSelectionUpdate', commentsCtrl, 'whenSelectionUpdate');
        }
    };
}

app
    .controller('CommentsController', CommentsController)
    .directive('comments', CommentsDirective);
