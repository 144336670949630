/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


(function(undefined){

	// UI OBJECT ==================================================================================

	var ui = function () {

		//	GLOBAL UI VARS ------------------------------------------------------------------------
		var $win		= null,
			$body		= null,
			isMob		= null;

		/*	UI.LOADING ----------------------------------------------------------------------------
			handles showing / hiding the 'loading' animation
		*/
		var loading = function(){

			var $loader	= null,

			showLoader = function() {

				$loader	= $('.loading');
				$loader.addClass('active');

			},

			hideLoader = function() {

				$loader	= $('.loading');
				$loader.removeClass('active');

			};

			return { init: init, showLoader: showLoader, hideLoader: hideLoader };

		}();

		/*	UI.FORMS ------------------------------------------------------------------------------
			specific UI behaviours for form elements
		*/
		var forms = function(){

			var val						= null,
				editTagAngMethod 		= null,
				shouldIDoAngCallback	= false,
				canTag 					= true,

			init = function() {

				// bind events
				$body
					.on('click', '.tag-output', function(){

						if( canTag == false ) {
							return "";
						}
						// focus the fake tag input when the control is clicked
						$(this).find('.fake-tag-input').focus();

					})
					.on('click', '.tag-output .tag', function(){

						if( canTag == false ) {
							return "";
						}

						var $fti = $(this).siblings('.fake-tag-input');
						$(this).remove();
						$fti.trigger('callTagAngular');
						return false;

					})

					.on('touchstart', '.tag-output .tag', function(){

						$(this).attr('contenteditable', 'true');

					})
					.on('touchend', '.tag-output .tag', function(){

						var $tag = $(this);
						setTimeout(function() {
							$tag.removeAttr('contenteditable');
						}, 10);

					})

					.on('focus blur', '.fake-tag-input', toggleAutoTags)
					.on('keyup blur', '.fake-tag-input', doAutoTags)

					.on('callTagAngular', '.fake-tag-input', callTagAngular)

					.on('setTagOutput', '.fake-tag-input', setTagOutput)
					.on('updateAutocomplete', '.auto-complete input', updateAutocomplete)
					.on('blur', '.auto-complete input', doAutoComplete)
					.on('change', '.file-input input[type=file]', doFileInput)

			},

			// autogrow textareas =================================================================

			autoGrow = function() {

				// on keyup, size the textarea to it's scroll height.
				// this code seems needlessly verbose because firefox has trouble with textareas + padding + overflow: hidden + scrollHeight :/

				var h = 0;

					$(this).addClass('set').height( 0 );

					h = this.scrollHeight;

					// add height for info pane message
					if( h > 50 ) h = h + 6;
					//if( $(this).parents('#proof-info').length & !$(this).parents('.meta').length ) h += 20;

					$(this).height( h ).removeClass('set');

			},

			// tag UI =============================================================================

			initAutoTags = function() {

				// setup tag entry UI
				var frag		= '<div class="tag-output"><input type="text" name="" value="" id="fake-tag-input" class="fake-tag-input" /></div>',
					disFrag		= '<div class="tag-output"><input type="text" name="" value="" id="fake-tag-input" disabled="disabled" class="fake-tag-input" /></div>',
					initVal		= [],
					initTags	= '';

				$('.auto-tags').each(function(){

					// get any initially present values
					initVal = $(this).find('input').val().split(' ');

					// hide the actual input, append the fake stuff

					if(canTag == false){
						frag = disFrag;
					}
					$(this).find('input').hide().end().append( frag );


					// pre-add tags from initial values
					for (var i=0; i < initVal.length; i++) {
						initTags += '<span class="tag" tabindex="-1">' + initVal[i] + '</span>';
					};
					$(this).find('.tag-output').prepend( initTags );

				});

			},

			initTag = function() {
				var initTagOutputDiv = '<div class="tag-output"><input type="text" name="" value="" id="fake-tag-input" class="fake-tag-input" /></div>';
				$('.auto-tags').find('input').hide().end().append( initTagOutputDiv );
			}

			toggleAutoTags = function( e ) {

				if( canTag == false ) return "";

				// sets / nukes the active class on the containing element
				if( e.type === 'focusout' ) {
					$(this).parents('.auto-tags').removeClass('active');
				}
				else {
					$(this).parents('.auto-tags').addClass('active');
				}

			},

			doAutoTags = function( e ) {

				if( canTag == false ) return "";

				shouldIDoAngCallback = false;

				if( e.keyCode === 32 || e.keyCode === 188 || e.keyCode === 13 || e.type === 'focusout' ) {

					// space hit OR comma hit OR input blurred
					if( $(this).val() != '' && $(this).val() != ' ' && $(this).val() != ',' ) {

						// if the field has a value, add it as a tag
						$(this)
							.before('<span class="tag" tabindex="-1">' + $(this).val().replace(' ','').replace(',','') + '</span>')
							.val('')
							.data().doDel = true;

						//doAngCallback();
						shouldIDoAngCallback = true;

					}
					else {

						// otherwise, it just nukes the space - no soup for you
						$(this).val('');

					}

				}
				else if( e.keyCode === 8 ) {

					// delete hit - if the doDel data attr is set, remove the last tag entered
					if( $(this).data().doDel ) {
						$(this).prev('.tag').remove();
						shouldIDoAngCallback = true;
					}

					// if this is the delete that removes the last character from the input, set doDel data attr
					( $(this).val() == '' ) ? $(this).data().doDel = true : $(this).data().doDel = false;

				}
				else {

					// any other key hit - set doDel data attr
					$(this).data().doDel = false;

				}

				// if it's new or deleted tag, call the angular function (which then calls 'setTagOutput')
				if( shouldIDoAngCallback ) {
					$(this).trigger('callTagAngular');
				}
				// if not, just call 'setTagOutput'
				else {
					$(this).trigger('setTagOutput');
				}

			},

			callTagAngular = function() {

				$(this).trigger('setTagOutput');
				doAngCallback();

			},

			setTagOutput = function() {

				var output = '',
					$tags = $(this).parents('.auto-tags').find('.tag');

				$tags.each(function(){
					output += $(this).text() + ' ';
				});

				$(this).parents('.auto-tags').find('input:first').val( output.trim() );

			},

			doAngCallback = function() {

				if( typeof editTagAngMethod == 'function' ) {
					editTagAngMethod();
				}

			},

			// autocomplete textfields ============================================================

			initAutoComplete = function() {

				$('.auto-complete').each(function(){

					// append the div which holds autocomplete text
					$(this).append('<div class="autocomplete-output"></div>');

				});

			},

			updateAutocomplete = function() {

				// update the div with value from the fields 'data-autocompletevalue' attr
				$(this).siblings('.autocomplete-output').text( $(this).data().autocompletevalue );

			},

			doAutoComplete = function() {

				// when the input is blurred, if the div contains a value, write it back to the field
				var $acDiv = $(this).siblings('.autocomplete-output');

				if( $acDiv.text() != '' ) {

					$(this).val( $acDiv.text() );
					$acDiv.text('');

				}

			},

			// date textfields ====================================================================

			initDatepicker = function() {

				var picker = new Pikaday({ field: $('.datepicker')[0] });

			},

			// file input fields ==================================================================

			doFileInput = function() {

				// when our input[type=file] 's value changes, write it into the adjacent text input
				var theVal = $(this).val().split('\\');
				$(this).siblings('input').val( theVal[ theVal.length - 1 ] );

			},

			// server messages ====================================================================

			doServerMsg = function( _title, _msg, _type ){

				// create frag
				var $msg	= $( '<div class="server-msg"><h3>' + _title + '</h3><p>' + _msg + '</p></div>' );

				// if a type has been passed, add it as a class
				if( _type ) $msg.addClass('server-msg-' + _type);

				// apend after current scope - should always be an h1
				$('#main .ng-scope h1:first').after( $msg );

			},

			clearServerMsg = function() {

				$('.server-msg').remove();

			}

			return { init: init, initAutoTags: initAutoTags, initTag: initTag, initAutoComplete: initAutoComplete, initDatepicker: initDatepicker, doServerMsg: doServerMsg, clearServerMsg: clearServerMsg };

		}();

		/*	UI.UTILS ------------------------------------------------------------------------------
		 utility methods used throughout
		 */
		var utils = function() {

			var val = null,

				returnTransform = function( _args ){

					var css 					= {
						'-webkit-transform' 	: _args,
						'-moz-transform' 		: _args,
						'-ms-transform' 		: _args,
						'transform' 			: _args
					};

					return css;

				},

				returnDelay = function( _delay ) {

					var css 						= {
						'-webkit-transition-delay'	: _delay + 'ms',
						'-moz-transition-delay'		: _delay + 'ms',
						'-ms-transition-delay'		: _delay + 'ms',
						'transition-delay'			: _delay + 'ms'
					};

					return css;
				},

				getPosition = function( e ){

					return (e && e.originalEvent && e.originalEvent.touches && e.originalEvent.touches[0]) || e;

				},

				disableScreenScroll = function() {

					// disable default page scrolling in touch
					document.ontouchmove = function(e){ e.preventDefault(); }

				},

				enableScreenScroll = function() {

					// reenable default page scrolling in touch
					document.ontouchmove = function(e){ return true; }

				},

				checkTitle = function() {

				},

				randRange = function(minNum, maxNum) {

					return (Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum);

				};

			return { returnTransform: returnTransform, returnDelay: returnDelay, getPosition: getPosition, disableScreenScroll: disableScreenScroll, enableScreenScroll: enableScreenScroll, checkTitle: checkTitle, randRange: randRange };

		}();

		/*	UI.INIT -------------------------------------------------------------------------------
			UI all inits here
		*/
		var init = function () {

			// set global vars
			$win		= $(window);
			$body		= $('body');
			isMob		= ($win.width() < 651 );

			forms.init();

			if( $('.tabbed').length )			tabs.init();

			// remove 300ms delay on links
			FastClick.attach(document.body);

		};

		return { init: init, utils: utils, forms: forms, loading: loading };

	}();

	window.bby_ui	= ui;

})();
