/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class PinSurfaceController {
    /**
     * The pin surface's element.
     *
     * @type {Element}
     */
    element = null;

    /**
     * Whether the user has permission to create pins on the surface.
     *
     * @type {Boolean}
     */
    canCreate = false;

    /**
     * Whether the pin surface is hidden.
     *
     * @type {Boolean}
     */
    isVisible = true;

    /**
     * The xy12 of the temporary pin.
     *
     * @type {Object}
     */
    temporaryPin = {
        active: false,
        data: {
            x1: null,
            y1: null,
            x2: null,
            y2: null
        },
        ctrl: null
    };

    /**
     * Gets the surface area of the surface directive.
     *
     * @returns {ClientRect}
     */
    getSurface () {
        return this.element.getBoundingClientRect();
    }
}

function PinSurfaceDirective (eventService, pinService, directiveHelper) {
    return {
        require: ['pinSurface'],
        controller: 'PinSurfaceController',
        controllerAs: 'pinSurfaceCtrl',
        templateUrl: 'templates/partials/proof/components/pin-surface.html',
        replace: true,
        transclude: true,
        scope: true,

        link (scope, element, attr, [pinSurfaceCtrl]) {
            pinSurfaceCtrl.element = element[0];

            if ('isVisible' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'isVisible', pinSurfaceCtrl, 'isVisible');
            }

            if ('canCreate' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'canCreate', pinSurfaceCtrl, 'canCreate');
                directiveHelper.callbackBinding(scope, attr, 'whenCreate', pinSurfaceCtrl, 'whenCreate');

                eventService.on(pinSurfaceCtrl.element, 'pointerdown', (event) => {
                    if (event.target === pinSurfaceCtrl.element && pinSurfaceCtrl.canCreate) {
                        event.preventDefault();
                        event.stopPropagation();

                        let surface = pinSurfaceCtrl.getSurface();
                        let { x, y } = pinService.getPointFromEvent(event, surface);
                        pinSurfaceCtrl.temporaryPin.active = true;
                        pinSurfaceCtrl.temporaryPin.data.x1 = x;
                        pinSurfaceCtrl.temporaryPin.data.y1 = y;
                        pinSurfaceCtrl.temporaryPin.data.x2 =
                        pinSurfaceCtrl.temporaryPin.data.y2 = null;

                        let offset = pinService.getOffsetFromPoints(surface, {x: x, y: y});
                        let box= {
                            left: offset.x + surface.left,
                            top: offset.y + surface.top,
                        };
                        pinSurfaceCtrl.temporaryPin.ctrl._startResize(event, null, (data) => {
                            pinSurfaceCtrl.temporaryPin.active = false;
                            pinSurfaceCtrl.whenCreate({ data: angular.extend({}, data), box: box });
                        });
                    }
                });
            }
        }
    };
}

app
    .controller('PinSurfaceController', PinSurfaceController)
    .directive('pinSurface', PinSurfaceDirective);
