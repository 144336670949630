/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
function DashboardProofDirective ($parse, directiveHelper) {
    return {
        templateUrl: 'templates/partials/dashboard/proof.html',
        scope: true,
        link (scope, element, attr) {
            if (attr.proof) {
                scope.proof = $parse(attr.proof)(scope);
            }

            // Bind `view` attr to scope `view` variable
            directiveHelper.oneWayBinding(scope, attr, 'view', scope, 'view');
        }
    };
}

app.directive('dashboardProof', DashboardProofDirective);