/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
angular
    .module('ppxGlobalControllers')
    .value('shortcutConfig', {
        defaults: {
            shortcuts: '?',
            menu: 'm',
            globalSearch: '/',
            proofInfo: 'i',
            togglePages: 'p',
            commentTool: 'c',
            markComment: ['`'],
            switchPen: ['shift+c'],
            commentPane: ['<', '>'],
            previousPage: 'left',
            nextPage: 'right',
            zoomFit: '0',
            zoom100: '1',
            zoom200: '2',
            zoom400: '4',
            printProof: ['command+p', 'ctrl+p'],
            playPause: 'space',
            fullScreen: ['ctrl+f'],
            togglePins: '\\',
            invertPins: '*',
            notifications: 'n',
            zoomIn: 'up',
            zoomOut: 'down',
            nudgeZoomIn: ['shift+up'],
            nudgeZoomOut: ['shift+down'],
            rotate: 'r',
            rotateCC: 'R',
            scrollMode: 'z',
            marqueeZoom: 'Z',
            commentCreate: ['shift+enter'],
            outline: ['[', ']'],
            darkMode: ['d d'],
            ruler: ['-'],
            focusMode: ['f'],
            gridlines: ['g'],
            previousCollectionProof: ['shift+left'],
            nextCollectionProof: ['shift+right'],
            nextFrame: ['.'],
            previousFrame: [','],
            accountSwitcher: ['command+escape'],
            barcodeScanner: 'b',
            barcodeScannerSpotlight: 's',
            barcodeScannerClose: 'escape',
            changeDevice: 'v',
            changeDeviceReverse: 'V',
        }
    })
    .factory('shortcutService',
        function ($rootScope, browserService, shortcutConfig, mousetrapService, storageService) {
            const localStorageKey = 'pageproof.app.shortcut.';
            var storage = storageService(localStorageKey);

            return {
                /**
                 * The browsers/os' meta key.
                 *
                 * @type {String}
                 */
                metaKey: browserService.os === 'mac' ? 'cmd' : 'ctrl',
                /**
                 * Gets all the keyboard shortcuts (in a map) by the shortcut name & the array of
                 * shortcut triggers (as strings).
                 *
                 * @returns {Object}
                 */
                getAllShortcuts: function () {
                        // Grab all the names of the default keyboard shortcuts
                    var defaultNames = Object.keys(shortcutConfig.defaults),

                        // Grab the names of the customised keyboard shortcuts
                        storageNames = storage.keys(),

                        // Aimlessly merge the two lists together (don't worry about dupes)
                        mergedNames = defaultNames.concat(storageNames),

                        // Grab only the names (without duplicates), by checking if the current iterated
                        // item's index matches the first index.
                        names = mergedNames.filter(function (name, index, names) {
                            return names.indexOf(name) === index;
                        }),

                        // The map of keyboard shortcuts (indexed by name)
                        shortcuts = {};

                    names.forEach(function (name) {
                        // Collect a map of all the shortcuts (by name vs shortcuts)
                        shortcuts[name] = this.getShortcuts(name);
                    }, this);

                    return shortcuts;
                },
                /**
                 * Returns the keyboard shortcut(s).
                 *
                 * @returns {Array}
                 */
                getShortcuts: function (name) {
                    // Return the current shortcut string (defaulting to the defaults)
                    return [].concat(storage.get(name) || shortcutConfig.defaults[name]);
                },
                /**
                 * Checks if a shortcut is changed or not
                 *
                 * @param {String} name
                 * @returns {Boolean}
                 */
                hasShortcutBeenCustomised: function (name) {
                    var currentShortcutKey = this.getShortcuts(name);
                    return currentShortcutKey.toString() !== shortcutConfig.defaults[name].toString();
                },
                /**
                 * Checks if an existing shortcut is already used.
                 *
                 * @param {String} shortcut
                 * @returns {Boolean}
                 */
                isExisting: function (shortcut) {
                    var exists = false,
                        shortcuts = this.getAllShortcuts();

                    Object.keys(shortcuts).forEach(function (name) {
                        // Check if the shortcut occurs in any of the existing shortcuts arrays
                        if ( ! exists && shortcuts[name].indexOf(shortcut) !== -1) {
                            exists = true;
                        }
                    });

                    return exists;
                },
                /**
                 * Watch for when a user enters a keyboard shortcut.
                 *
                 * @param {String} name
                 * @param {Function} callback
                 * @returns {Function}
                 */
                watch: function (name, callback) {
                    var shortcuts,
                        mousetrapWatcher,
                        wrapped = function (event) {
                            event.preventDefault();
                            callback.apply(this, arguments);
                        },
                        update = function () {
                            var oldShortcuts = shortcuts;

                            // If there has been a previous watcher registered, de-register it
                            if (angular.isFunction(mousetrapWatcher)) {
                                mousetrapWatcher();
                                mousetrapWatcher = null; // gc
                            }

                            // If there is currently a keyboard shortcut registered
                            if ((shortcuts = this.getShortcuts(name)).length) {
                                // Register a new watcher and save to the `watcher` function
                                mousetrapWatcher = mousetrapService.watch(shortcuts, wrapped);
                            }

                            console.info('Updating \'%s\' keyboard shortcut %s => %s.', name,
                                [].concat(oldShortcuts).join(', ') || '(none)',
                                [].concat(shortcuts).join(', ') || '(none)');
                        }.bind(this);

                    // Subscribe to a storage watcher
                    var unwatchStorage = window.__pageproof_quark__.localStorage.watch(localStorageKey + name, update);

                    // Register an initial mousetrap watcher
                    update();

                    // Log ;)
                    console.info('Watching for keyboard shortcut \'%s\'.', name);

                    return function () {
                        // Remove the storage watcher (make sure the storage watcher doesn't invoke
                        // the update function, which creates a new mousetrap watcher.
                        unwatchStorage();
                        unwatchStorage = null;

                        // Remove the mousetrap watcher (make sure keyboard events don't work)
                        mousetrapWatcher();
                        mousetrapWatcher = null;

                        // Remove any other functions created by this closure
                        update = null;

                        // Log ;)
                        console.info('No longer watching for keyboard shortcut \'%s\'.', name);
                    };
                },
                /**
                 * Watch for the user to enter a custom keyboard shortcut.
                 *
                 * @param {String} name
                 * @param {Function} [callback]
                 * @returns {Function}
                 */
                custom: function (name, callback) {
                    var self = this;

                    return mousetrapService.record(function (sequence) {
                        var shortcut = sequence.join(' ');

                        if ( ! self.isExisting(shortcut)) {
                            // Set the custom shortcut in localStorage
                            storage.set(name, shortcut);
                        }

                        if (angular.isFunction(callback)) {
                            // Pass through the new shortcut to the callback
                            callback(self.getShortcuts(name));
                        }
                    });
                }
            };
        }
    );
