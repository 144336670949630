/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const schema = {
    'proof.enqueue.envelope': {
        type: 'POST',
        url: 'admin/proof/rekey/{{ ProofId }}',
        params: ['ProofId'],
    },
    'file.enqueue.process': {
        type: 'POST',
        url: 'admin/file/queue/{{ FileId }}',
        params: ['FileId'],
    },
    'proof.load': {
        type: 'GET',
        url: 'proofs/{{ ProofId }}/',
        params: ['ProofId'],
        query: ['referrer'],
    },
    'user.load': {
        type: 'GET',
        url: 'users/{{ UserId }}/',
        params: ['UserId']
    },
    'user.avatar': {
        type: 'GET',
        url: 'media/getavatar/{{ UserId }}',
        params: ['UserId']
    },
    'user.update.notifyme': {
        type: 'POST',
        url: 'users/owneremail',
        params: ['UserId', 'CommentNew', 'CommentReply', 'Overdue', 'Coowner', 'UnlockRequest', 'Unlocked', 'InvitedToProof', 'Approved', 'ToDoReceived', 'Finished', 'MandatoryFinished', 'GatekeeperApproved', 'ProofStarted', 'OutOfOffice', 'ReviewerFinishedToApprover', 'CommentReplyToLocker', 'BatchTodoReceived', 'BatchApproved', 'BatchInvitedToProof', 'ApprovedToReviewer', 'CommentNewOrReplyToReviewer']
    },
    'dashboard.inbox': {
        type: 'GET',
        url: 'proofs/dashboard/proofin/'
    },
    'dashboard.sent': {
        type: 'GET',
        url: 'proofs/dashboard/proofout/'
    },
    'dashboard.outbox': {
        type: 'GET',
        url: 'proofs/dashboard/proofoutbox/'
    },
    'dashboard.all': {
        type: 'GET',
        url: 'proofs/dashboard/all/'
    },
    'dashboard.search': {
        type: 'POST',
        url: 'proofs/search',
        params: ['Query', 'All', 'FilterBy']
    },
    'dashboard.briefs.all': {
        type: 'GET',
        url: 'briefs/dashboard/'
    },
    'dashboard.group': {
        type: 'GET',
        url: 'proofs/group/{{groupId}}'
    },
    'dashboard.teamInbox': {
        type: 'GET',
        url: 'admin/dashboard/proofin/{{page}}',
        params: ["page"]
    },
    'dashboard.teamSent': {
        type: 'GET',
        url: 'admin/dashboard/proofsent/{{page}}',
        params: ["page"]
    },
    'dashboard.teamOutbox': {
        type: 'GET',
        url: 'admin/dashboard/proofout/{{page}}',
        params: ["page"]
    },
    'domain.dashboard.addOwner': {
        type: 'POST',
        url: 'admin/permission/add',
        params: ['Email', 'RelatedId', 'AllVersions'],
    },
    'domain.team-dashboard.search': {
        type: 'POST',
        url: 'admin/search',
        params: ['Query', 'FilterBy', 'Page'],
    },
    'domain.integrations.load': {
        type: 'GET',
        url: 'admin/integrations/get'
    },
    'domain.integrations.update': {
        type: 'POST',
        url: 'admin/integrations/set',
        params: ['Type', 'Enabled', 'AuthString', 'ConnectString']
    },
    'domain.branding.upload': {
        type: 'POST',
        url: 'admin/branding/upload',
        params: ['Type', 'Image', 'Alignment', 'Enabled']
    },
    'domain.branding.update': {
        type: 'POST',
        url: 'admin/branding/update',
        params: ['Type', 'Enabled', 'Alignment']
    },
    'domain.branding.load': {
        type: 'GET',
        url: 'admin/branding/{{ Type }}',
        params: ['Type']
    },
    'domain.team-dashboard.proofs.get': {
        type: 'GET',
        url: 'admin/getproofs'
    },
    'domain.team-dashboard.proofs.add-coowner': {
        type: 'POST',
        url: 'admin/permission/add',
        params: ['UserId', 'RelatedId', 'ProofCoOwner']
    },
    'domain.profile.update': {
        type: 'POST',
        url: 'admin/member/update',
        params: ['UserId', 'Name']
    },
    'domain.profile.admin.set': {
        type: 'POST',
        url: 'admin/member/admin/set',
        params: ['Email', 'UserId', 'IsSet']
    },
    'domain.profile.load': {
        type: 'GET',
        url: 'admin/member/{{ UserId }}',
        params: ['UserId']
    },
    'domain.user.delete': {
        type: 'POST',
        url: 'admin/member/delete',
        params: ['Email']
    },
    'proof.thumb': {
        type: 'GET',
        url: 'media/getimage/thumb/{{ FileId }}',
        params: ['FileId']
    },
    'proof.preview': {
        type: 'GET',
        url: 'media/getimage/{{ FileId }}/{{ Quality }}/{{ PageNumber }}/',
        params: ['FileId', 'PageNumber', 'Quality']
    },
    'proof.distribute': {
        type: 'GET',
        url: 'proofs/distribute/{{ProofId}}',
        params: ['ProofId']
    },
    'proof.report-issue-loading': {
        type: 'GET',
        url: 'proofs/postmanpat/{{ProofId}}',
        params: ['ProofId']
    },
    'proof.team.load': {
        type: 'GET',
        url: 'proofs/permission/getlist/{{ ProofId }}/',
        params: ['ProofId']
    },
    'proof.video.get': {
        type: 'GET',
        url: 'media/getvideo/{{ FileId }}/',
        params: ['FileId']
    },
    'proof.seen': {
        type: 'GET',
        url: 'proofs/view/{{ ProofId }}/',
        params: ['ProofId']
    },
    'proof.comments.since': {
        type: 'POST',
        url: 'proofs/recent',
        params: ['ProofId', 'TimeStamp']
    },
    'proof.comment.create': {
        type: 'POST',
        url: 'proofs/comment/create',
        params: ['ProofId', 'Page', 'Envelope', 'EncryptedComment', 'XY', 'Parent', 'Mentions', 'Metadata', 'CreationToken', 'Change', 'BoxFillImage', 'BoxFillType', 'Private']
    },
    'proof.comment.update': {
        type: 'POST',
        url: 'proofs/comment/update',
        params: ['ProofId', 'CommentId', 'Page', 'Envelope', 'EncryptedComment', 'XY', 'Mentions']
    },
    'proof.comment.delete': {
        type: 'POST',
        url: 'proofs/comment/delete',
        params: ['CommentId']
    },
    'proof.attachment.delete': {
        type: 'POST',
        url: 'proofs/comment/deleteattachment',
        params: ['Attachment', 'CommentId']
    },
    'proof.comment.agree': {
        type: 'POST',
        url: 'proofs/comment/agree',
        params: ['CommentId', 'UserId'] // UserId is ignored, but passed anyways
    },
    'proof.comment.pin.update': {
        type: 'POST',
        url: 'proofs/comment/updatexy',
        params: ['CommentId', 'XY']
    },
    'proof.comment.snapshot': {
        type: 'POST',
        url: 'proofs/comment/snapshot',
        params: ['Snapshot', 'CommentId']
    },
    'proof.return': {
        type: 'GET',
        url: 'proofs/returnproof/{{ProofId}}',
        params: ['ProofId']
    },
    'proof.revert-approval': {
        type: 'POST',
        url: 'proofs/revertapproval',
        params: ['ProofId', 'MessageToSender']
    },
    'proof.revert-todos': {
        type: 'POST',
        url: 'proofs/reverttodo',
        params: ['ProofId', 'MessageToSender']
    },
    'proof.info': {
        type: 'GET',
        url: 'proofs/info/{{ProofId}}',
        params: ['ProofId']
    },
    'workflow.nudge-all': {
        type: 'POST',
        url: 'workflows/nudgeall',
        params: ['WorkflowId']
    },
    'workflow.step.user.send-todos': {
        type: 'POST',
        url: 'workflows/step/user/approversendtodos',
        params: ['UserId', 'StepId']
    },
    // Returns the users a workflow template has been shared with, whether or not they are coOwners of the template
    'workflow.shared-with.all': {
        type: 'GET',
        url: 'workflows/share/{{ WorkflowId }}',
        params: ['WorkflowId']
    },
    // Returns workflow template coOwners, inviters, and proof owners of workflow templates
    'workflow.permissions.team-list': {
        type: 'GET',
        url: 'workflows/permission/getlist/{{ WorkflowId }}',
        params: ['WorkflowId']
    },
    'workflow.permissions.add.workflow-co-owner': {
        type: 'POST',
        url: 'workflows/permission/add',
        params: ['WorkflowTemplateCoowner', 'RelatedId', 'UserId']
    },
    'workflow.permissions.remove.workflow-co-owner': {
        type: 'POST',
        url: 'workflows/permission/memberset',
        params: ['WorkflowTemplateCoowner', 'MemberId']
    },
    'workflow.shared.delete-shared': {
        type: 'POST',
        url: 'workflows/share/delete',
        params: ['WorkflowId', 'UserId']
    },
    'workflow.steps.update': {
        type: 'POST',
        url: 'workflows/step/update',
        params: ['StepId', 'Title', 'Description', 'Position', 'MandatoryDecisionThreshold']
    },
    'proof.media.download-option.toggle': {
        type: 'POST',
        url: 'media/downloadoption',
        params: ['FileId', 'DownloadOriginal']
    },
    'addressBook.load': {
        type: 'GET',
        url: 'users/emailaddressbooklist',
        params: []
    },
    'oauth.client': {
        type: 'GET',
        url: 'oauth/client/{{client_id}}',
        query: ['scope', 'redirect_uri', 'state', 'response_type'],
    },
    'oauth.authorize': {
        type: 'POST',
        url: 'oauth/authorize',
        query: ['client_id', 'scope', 'redirect_uri', 'state', 'response_type'],
    },
    'file.chunk': {
        type: 'GET',
        url: 'media/getfilechunk/{{ProofId}}/{{FileId}}/{{Chunk}}',
        params: ['ProofId', 'FileId', 'Chunk']
    },
    'import.url.validate': {
        type: 'POST',
        url: 'media/import/url/validate',
        params: ['Url'],
    },
};

class BackendServiceProvider {
    /**
     * @param {Function} $http
     * @param {Function} $interpolate
     * @param {Function} $q
     * @param {Object} userService
     * @param {Object} apiService
     * @param {Object} backgroundService
     *
     * @constructor
     * @ngInject
     */
    constructor ($http, $interpolate, $q, userService, apiService) {
        this.schema = schema;
        this.$http = $http;
        this.$interpolate = $interpolate;
        this.$q = $q;
        this.userService = userService;
        this.apiService = apiService;
    }

    /**
     * Creates a request to the API - using the name and data (params).
     *
     * @param {String} name
     * @param {Object} [data]
     * @returns {Object}
     *
     * @private
     */
    $$request (name, data = null) {
        let request,
            defer,
            type,
            url,
            params,
            template,
            body,
            cancel = this.$q.defer();

        if (name in schema) {
            template = this.$$template(name);
            params = this.$$params(data, template.params);
            url = env('api_url') + template.$$url$interpolate(params);
            type = template.type;

            if (template.query) {
                const queryParams = this.$$params(data, template.query, false);
                if (Object.keys(queryParams).length) {
                    url += (
                        (url.indexOf('?') === -1 ? '?' : '&') +
                        this.$$serialise(queryParams)
                    );
                }
                body = null;
            }

            if (type.toLowerCase() === 'post') {
                body = params;
            }
        }

        defer = this.$q.defer();
        request = defer.promise;

        this.$http({
            method: type,
            url: url,
            data: this.$$serialise(body),
            headers: this.$$headers(type, url),
            timeout: cancel.promise
        }).then((data) => {
            if (this.apiService.error.is(data.data)) {
                defer.reject(data);
            } else {
                defer.resolve(data);
            }
        }).catch((err) => {
            if (err && err.data && err.data.ResponseCode === 401) {
                var unauthEvent = $.Event('unauthorised.pageproof');
                $(window).trigger(unauthEvent);
            }
            defer.reject(err);
        });

        return {
            url,
            params,
            type,
            body,
            request,
            cancel,
            template
        };
    }

    /**
     *
     * @param {Object} data
     * @returns {String|*}
     */
    $$serialise (data) {
        if (angular.isObject(data)) {
            return Object.keys(data).map((key) => {
                let value = data[key] == null ? '' : encodeURIComponent(data[key]);
                return encodeURIComponent(key) + '=' + value;
            }).join('&');
        } else {
            return data;
        }
    }

    /**
     *
     * @param name
     * @returns {*}
     */
    $$template (name) {
        let template = schema[name];

        if ( ! ('$$url$interpolate' in template)) {
            // Cache the interpolate instance for optimisation purposes
            template.$$url$interpolate = this.$interpolate(template.url);
        }

        return template;
    }

    /**
     * Generates the headers for an authorised request to the backend.
     *
     * @param {String} type (GET/POST/PUT/DELETE)
     * @param {String} url
     * @returns {Object}
     */
    $$headers (type, url) {
        var timestamp, headers,
            { userToken, userSharedSecretKey: sharedKey } =
                this.userService.getUser().getEncryptionData();

        function generate (callback) {
            return (...args) => {
                if ( ! timestamp) {
                    // Lazy generate the timestamp
                    timestamp = Date.now();
                }

                return callback(...args);
            };
        }

        headers = {
            'X-Timestamp': generate(() => timestamp),
            'X-Authorization-Token': generate(() => userToken),
            'X-Authorization-HMAC': generate(() => {
                let hmac = forge.hmac.create();
                hmac.start('sha256', sharedKey);
                hmac.update(userToken);
                hmac.update(type);
                hmac.update(String(timestamp));
                hmac.update(getURLPath(url));
                return forge.util.encode64(hmac.digest().data);
            })
        };

        if (type.toLowerCase() === 'post') {
            headers['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        return headers;
    }

    /**
     * Uses a parameter template/schema to convert the request parameters to their proper cases.
     *
     * @param {Object|null} params
     * @param {Array|null} [template]
     * @param {boolean} [normalise=true]
     * @returns {Object}
     */
    $$params (params, template, normalise = true) {
        if ( ! params) {
            params = {};
        }

        if ( ! template) {
            return params;
        }

        let transformed = {},
            normalisedKeys = {};

        Object.keys(params).forEach((inputKey) => {
            // Strip out underscores, dashes or uppercases from the parameter name
            normalisedKeys[
                normalise
                    ? inputKey.replace(/[_-]/gi, '').toLowerCase()
                    : inputKey
            ] = inputKey;
        });

        template.forEach((key) => {
            let value = params[normalisedKeys[key.toLowerCase()]],
                convertFn = template[key];

            if (angular.isFunction(convertFn)) {
                transformed[key] = convertFn(value);
            } else if (typeof value !== 'undefined') {
                transformed[key] = value;
            }
        });

        return transformed;
    }

    /**
     * Fetches a response from the API.
     *
     * @param {String} name
     * @param {Object} [params]
     * @return {Object}
     */
    fetch (name, params) {
        let that = this,
            request = this.$$request(name, params);

        return {
            /**
             * Returns a promise that resolves the request object.
             *
             * @returns {$q}
             */
            raw () {
                return this.promise().then(() => request).catch(() => request);
            },
            /**
             * Returns a promise that resolves the response data.
             *
             * @returns {$q}
             */
            promise () {
                // Creates a new promise which returns the entire request object when complete
                return that.$q.when(request.request);
            },
            /**
             * Returns a promise that resolves the response body data.
             *
             * @returns {$q}
             */
            data () {
                // Creates a new promise which resolves the response body
                return this.promise().then(response => response.data);
            },
            /**
             * Aborts the request if there is not yet a response.
             *
             * Automatically rejects the $http promise object (causing any sub-promises) to reject too.
             *
             * @see {$http.timeout}
             */
            abort () {
                request.cancel.resolve();
            }
        };
    }
}

app.factory('backendService', ($injector) => {
    return $injector.instantiate(BackendServiceProvider);
});
