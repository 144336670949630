/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class ProofDialogService {
    /**
     * @param {PPProofDialogType} PPProofDialogType
     * @constructor
     */

    constructor (PPProofDialogType, PPProofStatus, PPProofType, storageService, userService) {
        this.$$ = { PPProofDialogType, PPProofStatus, PPProofType, storageService, userService };

        this.user = this.$$.userService.getUser();
        this.proofStorage = this.$$.storageService('pageproof.app.proof.' + this.user.id + '.');
    }


    /**
     * Gets the proof dialog type to display to the user.
     *
     * This method should be called after the proof object has been populated, and after the permissions
     * object has been generated ("PPProofPermissions"), on load of the proof page.
     *
     * @param {Object} permissions
     * @returns {String|undefined}
     */
    getDialogType (permissions) {
        const skipMessageStatusList = this.proofStorage.json('dialogs') || [];
        const isEditor = permissions.proofer.proofLevel.isEditor;
        const editorMessage = permissions.proof.editorMessage;
        if (
            permissions.proofer.proofLevel.isFinalApprover &&
            ! permissions.general.isLocked &&
            ! permissions.general.isClosed &&
            permissions.proof.workflow.steps.length > 1 &&
            permissions.general.isFinalApproverStage &&
            ! permissions.general.isFinished && // (user has finished on current step)
            skipMessageStatusList.indexOf('final-approver-lock') === -1
        ) {
            return {
                type: this.$$.PPProofDialogType.FINAL_APPROVER_LOCK,
                location: 'modal',
            };
        }

        else if (
            (permissions.proofer.proofLevel.isProofOwner || permissions.proofer.proofLevel.isCoOwner) &&
            ! permissions.general.isWithEditor &&
            permissions.general.proofStatus === this.$$.PPProofStatus.CHANGES_REQUESTED &&
            (skipMessageStatusList.indexOf('owner-todos-requested') === -1 || permissions.proof.ownerMessage)
        ) {
            return {
                type: this.$$.PPProofDialogType.OWNER_TODOS_REQUESTED,
                location: 'modal',
            };
        }

        else if (
            permissions.general.proofType === this.$$.PPProofType.BRIEF &&
            permissions.proofer.proofLevel.isRecipient &&
            ! permissions.general.isWithEditor &&
            permissions.general.proofStatus === this.$$.PPProofStatus.PROOFING &&
            (skipMessageStatusList.indexOf('recipient-upload-proof') === -1 || permissions.proof.description)
        ) {
            return {
                type: this.$$.PPProofDialogType.RECIPIENT_UPLOAD_PROOF,
                location: 'modal',
            };
        }

        else if (
            permissions.general.proofType === this.$$.PPProofType.BRIEF &&
            permissions.proofer.proofLevel.isRecipient &&
            permissions.general.isWithEditor &&
            permissions.general.proofStatus === this.$$.PPProofStatus.PROOFING &&
            skipMessageStatusList.indexOf('brief-with-editor') === -1
        ) {
            return {
                type: this.$$.PPProofDialogType.BRIEF_WITH_EDITOR,
                location: 'modal',
            };
        }

        else if (
            (permissions.proofer.proofLevel.isProofOwner || permissions.proofer.proofLevel.isCoOwner) &&
            permissions.general.isWithEditor &&
            permissions.general.proofStatus === this.$$.PPProofStatus.CHANGES_REQUESTED &&
            (skipMessageStatusList.indexOf('todos-with-editor') === -1)
        ) {
            return {
                type: this.$$.PPProofDialogType.TODOS_WITH_EDITOR,
                location: 'modal',
            };
        }

        else if (
            permissions.proofer.proofLevel.isEditor &&
            permissions.general.proofStatus === this.$$.PPProofStatus.CHANGES_REQUESTED &&
            (skipMessageStatusList.indexOf('editor-todos-requested') === -1 || permissions.proof.editorMessage)
        ) {
            return {
                type: this.$$.PPProofDialogType.EDITOR_TODOS_REQUESTED,
                location: 'modal',
            };
        }

        else if (
            permissions.general.proofType === this.$$.PPProofType.BRIEF &&
            permissions.proofer.proofLevel.isEditor &&
            (permissions.general.proofStatus === this.$$.PPProofStatus.PROOFING ||
            permissions.general.proofStatus === this.$$.PPProofStatus.AWAITING_NEW_VERSION) &&
            (skipMessageStatusList.indexOf('brief-sent-to-editor') === -1 || editorMessage)
        ) {
            return {
                type: this.$$.PPProofDialogType.BRIEF_SENT_TO_EDITOR,
                location: 'modal',
            };
        }

        else if (
            permissions.general.isLatestVersion &&
            permissions.general.proofType === this.$$.PPProofType.BRIEF &&
            permissions.proofer.proofLevel.isRecipient &&
            permissions.general.proofStatus === this.$$.PPProofStatus.AWAITING_NEW_VERSION &&
            (skipMessageStatusList.indexOf('proof-required-for-brief') === -1 || (isEditor && editorMessage))
        ) {
            return {
                type: this.$$.PPProofDialogType.PROOF_REQUIRED_FOR_BRIEF,
                location: 'modal',
            };
        }

        else if (
            (permissions.general.isApproved ||
            permissions.general.proofStatus === this.$$.PPProofStatus.APPROVED) &&
            (skipMessageStatusList.indexOf('proof-approved') === -1 || permissions.proof.ownerMessage)
        ) {
            return {
                type: this.$$.PPProofDialogType.PROOF_APPROVED,
                location: 'modal',
            };
        }

        else if (
            permissions.proofer.proofLevel.isApprover &&
            ! permissions.general.isLocked &&
            ! permissions.general.isClosed &&
            ! permissions.general.isFinished && // (user has finished on current step)
            skipMessageStatusList.indexOf('approver-lock') === -1
        ) {
            return {
                type: this.$$.PPProofDialogType.APPROVER_LOCK,
                location: 'modal',
            };
        }

        else if (
            permissions.general.isLatestVersion &&
            permissions.general.proofType === this.$$.PPProofType.PROOF &&
            (skipMessageStatusList.indexOf('new-version-required') === -1  || (isEditor && editorMessage)) &&
            (permissions.proofer.proofLevel.isProofOwner || permissions.proofer.proofLevel.isCoOwner ||  permissions.proofer.proofLevel.isEditor) &&
            permissions.general.proofStatus === this.$$.PPProofStatus.AWAITING_NEW_VERSION
        ) {
            return {
                type: this.$$.PPProofDialogType.NEW_VERSION_REQUIRED,
                location: 'modal',
            };
        }

        else if (
            permissions.proofer.proofLevel.isProofOwner &&
            permissions.general.proofType === this.$$.PPProofType.BRIEF &&
            permissions.general.proofStatus === this.$$.PPProofStatus.NEW
        ) {
            return this.$$.PPProofDialogType.NEW_BRIEF;
        }
    }
}

app
    .service('proofDialogService', ProofDialogService);
