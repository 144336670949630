/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.config(function ($provide, $httpProvider) {
    $provide.factory('templateUpdater', function () {
        return {
            'request': (config) => {
                if (config.method === 'GET' && config.url.indexOf('templates/partials') === 0) {
                    config.url = antiCache(config.url);
                    //config.headers['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
                    //config.headers['Cache-Control'] = 'no-cache';
                    //config.headers['Pragma'] = 'no-cache';
                }
                return config;
            }
        };
    });

    $httpProvider.interceptors.push('templateUpdater');
});
