/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

function DateTime(date, debug) {
    this.date = date;
    this.day = 0;
    this.month = 0;
    this.year = 0;
    this.hours = 0;
    this.minutes = 0;
    this.seconds = 0;
    this.displayDate = "";
    this.UTCDate = "";
    this.debug = debug;
    this.calculate();

    this.standardDisplayFormat = "Do MMMM YYYY";
    this.dayFormat = "h:mm a";
    this.standardFormat = "YYYY-MM-DD H:mm:ss";
    this.inputFormat = "h:mma[,] DD MMMM YYYY";

    this.currentDayFormat = "YYYY-MM-DD";
    this.currentUTCDay = moment().utc().format("YYYY-MM-DD");
    this.currentDay = moment().format("YYYY-MM-DD");

    this.standardFormatStr = "";
    this.currentTimezone = "";

    this.currentUTCDayPlusOne = moment().utc().add("days", 1).format(this.currentDayFormat);
    this.currentUTCDayPlusSeven = moment().utc().add("days", 7).format(this.currentDayFormat);
    this.currentUTCStr = moment().utc().format();
};

DateTime.prototype.convertUTCToLocal = function(utcStr, format){
    var localTime  = moment.utc(utcStr).toDate();
    return moment(localTime).format(format);
};

DateTime.prototype.getCurrentUTCDate = function(){
    var date = moment(new Date()).utc().format();
    return date;
};

DateTime.prototype.convertStandardFormatToUTCDate = function(timeStr){
    return moment(timeStr, this.standardFormat).utc().format();
};

DateTime.prototype.convertTimeToUTCDate = function(timeStr){
    var date = moment(timeStr).utc().format();
    return date;
};

DateTime.prototype.convertTimeStrToUTCDate = function(timeStr){
    var date = moment(timeStr).utc().format();
    return date;
};

DateTime.prototype.getLocalDateFromUTCstr = function(utcStr){ //this one
    if(empty(utcStr)) return "";
    var formatedDate = this.convertUTCToLocal(utcStr, this.standardDisplayFormat);
    return formatedDate;
};

DateTime.prototype.getLocalTimeFromUTCstr = function(utcStr){
    if(empty(utcStr)) return "";
    var formatedDate = this.convertUTCToLocal(utcStr, this.inputFormat);
    return formatedDate;
};

DateTime.prototype.getLocalDateTimeFromUTCstr = function(utcStr){
    if(empty(utcStr)) return "";

    var formatedDate = moment(this.convertedUTCStr).local().format(this.standardFormat);

    return formatedDate;
};

DateTime.prototype.getDateDiff = function(utcStr){
    if(empty(utcStr)) return "";
    var convertDate = moment(this.convertUTCToLocal(utcStr, "YYYY-MM-DD"), this.standardFormat);
    var diff = moment(convertDate).diff(this.currentDay, 'days');
    return diff;
};

DateTime.prototype.getDiff = function(utcStr, type){
    if(empty(utcStr)) return "";
    var convertDate = moment(this.convertUTCToLocal(utcStr,  this.standardFormat), this.standardFormat);
    var diff = moment().diff(convertDate, type);
    return diff;
};

DateTime.prototype.debugDate = function(utcStr){
    console.log("diff: " + this.getDateDiff(utcStr) + ", currentDay: " + this.currentDay + ", convertUTCToLocal: " + this.convertUTCToLocal(utcStr, "YYYY-MM-DD") + ", utcStr: " + utcStr);
};

DateTime.prototype.isProofOverDue = function(utcStr){
    if(empty(utcStr)) return "";
    var dateNow = moment.utc(new Date()).format();
    if (dateNow > utcStr) {
        return true;
    } else {
        return false;
    }
}

DateTime.prototype.getFormatedDateStrForComment = function(utcStr) {
    if (empty(utcStr)) return "";

    var daysDifferenceUTC = this.getDateDiff(utcStr);

    var minutesDifferenceUTC = this.getDiff(utcStr, "minutes");

    var ret = "";

    if(daysDifferenceUTC == 0){ //today
        if(minutesDifferenceUTC > 1 && minutesDifferenceUTC < 60) { //1 hour
            ret = minutesDifferenceUTC + " minutes ago"
        }else if(minutesDifferenceUTC == 0){
            ret = "just now"
        }else if(minutesDifferenceUTC == 1){
            ret = "1 minute ago"
        }else{
            ret = this.getLocalDateFromUTCstr(utcStr, this.dayFormat);
        }
    }else if( (daysDifferenceUTC < 0 && daysDifferenceUTC > -7) ) {
        if(daysDifferenceUTC == -1){
            ret = "1 day ago";
        }else if(daysDifferenceUTC < 1){
            var date = daysDifferenceUTC + " days ago";
            ret = date.replace("-","");
        }
    }else{
        ret = this.getLocalDateFromUTCstr(utcStr, this.standardDisplayFormat);
    }

    return ret;

};

DateTime.prototype.getFormatedDateStrForDashBoard = function(utcStr){
    if(empty(utcStr)) return "";

    var daysDifferenceUTC = this.getDateDiff(utcStr);

    //this.debugDate(utcStr);

    var ret = "";

    if( (daysDifferenceUTC == 0) ){
        ret += "Due Today at " + this.convertUTCToLocal(utcStr, this.dayFormat);
    }

    if( (daysDifferenceUTC < 0) ){
        ret += "\<span style='color: red;'\>Overdue\</span\>";
    }

    if( (daysDifferenceUTC == 1) ){
        ret += "Due In 1 Day";
    }

    if( (daysDifferenceUTC > 1 && daysDifferenceUTC < 8) ){
        ret += "Due In " + daysDifferenceUTC + " Days";
    }

    if( (daysDifferenceUTC > 8) ){
        ret = "Due " + this.convertUTCToLocal(utcStr, this.standardDisplayFormat);
    }

    return ret;

};

DateTime.prototype.calculate = function() {
    if (empty(this.date))
        return "";
    var timePortion = "";
    var datePortion = "";
    var displayDate = "";
    var dateObj = new Date();
    var currentCent = "20";
    var datePortionSplit = [];
    var specialDateStrings = {"midday": "", "midnight": "", "sparrow fart": "", "christmas": "", "xmas": "", "unix time": "", "yesterday": ""};
    var dateData = this.splitOrginalString(this.date);
    if (this.debug === true)
        console.log(dateData);
    timePortion = dateData["timePortion"];
    datePortion = dateData["datePortion"];
    var timePortionHasAM = strstr(timePortion, "am");
    var timePortionHasPM = strstr(timePortion, "pm");
    var datePortionHasHyphens = strstr(datePortion, "-");
    var datePortionHasSlashes = strstr(datePortion, "/");
    var datePortionHasSpaces = strstr(datePortion, " ");
    if (isset(timePortionHasAM)) {
        var hoursArr = timePortion.split("am");
        if (isset(hoursArr[0])) {
            this.calculateValidHours(hoursArr[0], "am");
        }
    } else if (isset(timePortionHasPM) || isset(timePortionHasAM)) {
        var hoursArr = timePortion.split("pm");
        if (isset(hoursArr[0])) {
            this.calculateValidHours(hoursArr[0], "pm");
        }
    } else {
        this.calculateValidHours(timePortion);
    }
    var dateSpliter = "";
    if (isset(datePortionHasHyphens)) {
        dateSpliter = "-";
    } else if (isset(datePortionHasSlashes)) {
        dateSpliter = "/";
    } else if (isset(datePortionHasSpaces)) {
        dateSpliter = " ";
    }
    if (dateSpliter !== "") {
        datePortionSplit = datePortion.split(dateSpliter);
        if (isset(datePortionSplit[0]) && parseInt(datePortionSplit[0]) >= 1 && parseInt(datePortionSplit[0]) <= 31) {
            this.day = parseInt(datePortionSplit[0]);
        } else {
            this.day = 1;
        }
        if (inArray(this.getMonthArray(), datePortionSplit[1])) {
            this.month = parseInt(inArrayReturnIndex(this.getMonthArray(), datePortionSplit[1])) + 1;
        } else if (inArray(this.getMonthArray("short_one"), datePortionSplit[1])) {
            this.month = parseInt(inArrayReturnIndex(this.getMonthArray("short_one"), datePortionSplit[1])) + 1;
        } else if (inArray(this.getMonthArray("short_two"), datePortionSplit[1])) {
            this.month = parseInt(inArrayReturnIndex(this.getMonthArray("short_two"), datePortionSplit[1])) + 1;
        } else {
            if (isset(datePortionSplit[1]) && parseInt(datePortionSplit[1]) >= 0 && parseInt(datePortionSplit[1]) <= 11) {
                this.month = parseInt(datePortionSplit[1]);
            } else {
                this.month = 1;
            }
        }
        if (isset(datePortionSplit[2]) && parseInt(datePortionSplit[2])) {
            if (datePortionSplit[2].length == 2) {
                this.year = parseInt(currentCent + datePortionSplit[2]);
            } else if (datePortionSplit[2].length == 4) {
                this.year = parseInt(datePortionSplit[2]);
            } else {
                this.year = dateObj.getFullYear();
            }
        }
    }
    this.makeUTFSave();
    if (this.debug === true)
        console.log(this.year + " | " + this.month + " | " + this.day + " | " + this.hours + " | " + this.minutes + " | " + this.seconds);
    var localDate = new Date(this.year, this.month, this.day, this.hours, this.minutes, this.seconds);
    this.setUTCString(localDate.toISOString());
    var hours = localDate.getHours();
    var period = "";
    if (hours > 12) {
        period = "pm";
    } else {
        period = "am";
    }
    var twelveHours = this.convert24To12(hours);
    this.createDisplayString(twelveHours, period, localDate.getDate(), this.convertToFullMonth(localDate.getMonth()), localDate.getFullYear());
    if (this.debug === true) {
        console.log(this.displayDate);
        console.log(this.UTCDate);
    }
};

DateTime.prototype.convertDay = function(dayNumber) {
    var timeArray = time.split(":");
    return timeArray[0] + "";
};

DateTime.prototype.convertToFullMonth = function(monthNumber) {
    var datesMap = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
    if(empty(datesMap[monthNumber])) return "";
    return ucFirst(datesMap[monthNumber]);
};

DateTime.prototype.convertMonth = function(monthShort) {
    var datesMap = {jan:'january', feb:'february', mar:'march', apr:'april', may:'may', jun:'june', jul:'july', aug:'august', sep:'september', sept:'september', oct:'october', nov:'november', dec:'december'};
    if(empty(datesMap[monthShort.toLowerCase()])) return "";
    return datesMap[monthShort.toLowerCase()];
};

DateTime.prototype.convertFullMonthToNumber = function(monthFull) {
    var datesMap = {january:1, february:2, march:3, april:4, may:5, june:6, july:7, august:8, september:9, october:10, november:11, december:12};
    if(empty(datesMap[monthFull.toLowerCase()])) return "";
    return datesMap[monthFull.toLowerCase()];
};

DateTime.prototype.convertSpecialStrings = function(timeStr) {
    var localDate = new Date();
    var specialDateStrings = {"midday": this.createDisplayString(12, "00", "pm", localDate.getDate(),  this.convertToFullMonth(localDate.getMonth()), localDate.getFullYear(), true),
                                "midnight": this.createDisplayString(12, "00", "am", localDate.getDate(),  this.convertToFullMonth(localDate.getMonth()), localDate.getFullYear(), true),
                                "sparrow fart": this.createDisplayString(5, "00", "am", localDate.getDate(),  this.convertToFullMonth(localDate.getMonth()), localDate.getFullYear(), true),
                                "christmas": this.createDisplayString(10, "00", "am", 25,  this.convertToFullMonth(11), localDate.getFullYear(), true),
                                "xmas": this.createDisplayString(10, "00", "am", 25,  this.convertToFullMonth(11), localDate.getFullYear(), true),
                                "new years": this.createDisplayString(10, "00", "am", 1,  this.convertToFullMonth(0), localDate.getFullYear() + 1, true),
                                "lunch time": this.createDisplayString(12, "00", "pm", localDate.getDate(),  this.convertToFullMonth(0), localDate.getFullYear(), true),
                                "unix time": this.createDisplayString(10, "00", "am", 1,  this.convertToFullMonth(0), 1970, true),
                                "chris's birthday":this.createDisplayString(3, 15, "am", 6,  this.convertToFullMonth(4), 1983, true)};

    if(isset(specialDateStrings[timeStr])){
        return specialDateStrings[timeStr];
    }

};

DateTime.prototype.isSpecialDateString = function(timeStr) {
    var specialDateStrings = ["midday","midnight","sparrow fart","christmas","xmas","unix time","chris's birthday", "new years","lunch time"];
    if(inArray(specialDateStrings, timeStr)){
        return true;
    }
    return false;
};


DateTime.prototype.createDisplayStringFromTime = function(convertTime){
    if(this.isSpecialDateString(convertTime) == true){
        return this.convertSpecialStrings(convertTime);
    }
    var localDate = new Date();
    var timeStringArray = convertTime.split(" ");
    var minutesStr = timeStringArray[4];
    var minutesArray = minutesStr.split(":");
    var period = "";
    var hours = minutesArray[0];
    var minutes = minutesArray[1];
    if (hours >= 12) {
        period = "pm";
    } else {
        period = "am";
    }
    if(hours=="00" && minutes=="00"){
        hours = this.convert24To12(localDate.getHours());
        minutes = this.getFormattedMinutes(localDate);
    }else{
        hours = this.convert24To12(hours);
    }
    return this.createDisplayString(hours, minutes, period, timeStringArray[2],  ucFirst(this.convertMonth(timeStringArray[1])), timeStringArray[3], true);
};

DateTime.prototype.convertUTCToLocalTime = function(utcTime){
    if(empty(utcTime)) return "";
    return this.createDisplayStringFromTime(new Date(utcTime).toString());
};

DateTime.prototype.convertTo12Hours = function(hours){
    if(hours > 0 | hours <= 12){
        return hours;
    }else{

    }
};

DateTime.prototype.createStandardFormatStr = function(year, month, day, hours, minutes, seconds) {
    this.standardFormatStr = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
};

DateTime.prototype.isValidDate = function(dateStr){

    console.log(this.convertDisplayStrToStandardFormat(dateStr));

    if(this.convertDisplayStrToStandardFormat(dateStr) == ""){
        return false;
    }

    var valid = moment(this.convertDisplayStrToStandardFormat(dateStr), this.standardFormat).format();

    if(valid == "Invalid date"){
        return false;
    }else{
        return valid;
    }
};

DateTime.prototype.convertDisplayStrToStandardFormat = function(displayStr) {

    var hours;
    var minutes;
    var seconds = "00";

    var dateArray = displayStr.split(",");

    if(!isset(dateArray)){
        return "";
    }

    var timeHalf = dateArray[0];
    var dateHalf = dateArray[1];

    if(!isset(timeHalf) | !isset(dateHalf)){
        return "";
    }

    var yearArray = dateHalf.split(" ");

    var day = yearArray[1];
    var month = this.convertFullMonthToNumber(yearArray[2]);
    var year = yearArray[3];

    if(strstr(timeHalf, "am") != false){
        var timeHalfArray = timeHalf.split("am")[0].split(":");
        hours = timeHalfArray[0];
        minutes = timeHalfArray[1];
    }else{
        var timeHalfArray = timeHalf.split("pm")[0].split(":");

        var timeArray = this.convert12To24(parseInt(timeHalfArray[0]), parseInt(timeHalfArray[1]));
        hours = timeArray[0];
        minutes = timeArray[1];
    }

    return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
};

DateTime.prototype.createDisplayString = function(time, minutes, period, date, month, year, ret) {
    var str = time + ":" + minutes + period + ", " + date + " " + month + " " + year;
    if (ret === true) {
        return str;
    } else {
        this.displayDate = str;
    }
};

DateTime.prototype.generateDisplayString = function(addTime) {
    var localDate = new Date();
    if(isset(addTime))
        localDate.setHours(localDate.getHours() + addTime);
    var hours = localDate.getHours();
    var period = "";
    if (hours > 12) {
        period = "pm";
    } else {
        period = "am";
    }
    var twelveHours = this.convert24To12(hours);
    this.createStandardFormatStr(localDate.getFullYear(), localDate.getMonth() + 1, localDate.getDate(), hours, localDate.getMinutes(), localDate.getSeconds());
    return this.createDisplayString(twelveHours, this.getFormattedMinutes(localDate), period, localDate.getDate(), this.convertToFullMonth(localDate.getMonth()), localDate.getFullYear(), true);
};

DateTime.prototype.getFormattedMinutes = function(localDate) {
    if(localDate.getMinutes() < 10){
        return "0" + localDate.getMinutes();
    }
    return localDate.getMinutes();
};

DateTime.prototype.setUTCString = function(UTCTime) {
    this.UTCDate = UTCTime;
};

DateTime.prototype.getUTCString = function() {
    return this.UTCDate;
};

DateTime.prototype.getDisplayString = function() {
    return this.displayDate;
};

DateTime.prototype.makeUTFSave = function() {
    if (!(this.year > 1900)) {
        this.year = new Date().getFullYear();
    }
    if (this.month > 0) {
        this.month = this.month - 1;
    }
};

DateTime.prototype.getMonthArray = function(type) {
    if (empty(type)) {
        return ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
    } else if (type == "short_one") {
        return ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sept', 'oct', 'nov', 'dec'];
    } else if (type == "short_two") {
        return ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    }
};

DateTime.prototype.splitOrginalString = function(date) {
    var dateSplit = [];
    var timePortion = "";
    var datePortion = "";
    dateSplit = date.split(/[\s,]+/); //split on both spaces and commas

    var i = 0;
    while(i <= dateSplit.length){
        if(dateSplit[i]=="on"){
            dateSplit.splice(i, 1);
        }else if(dateSplit[i]=="of"){
            dateSplit.splice(i, 1);
        }else if(dateSplit[i]=="the"){
            dateSplit.splice(i, 1);
        }else if(dateSplit[i]=="at"){
            dateSplit.splice(i, 1);
        }
        i++;
    }

    if (this.debug === true)
        console.log(dateSplit);
    if ((dateSplit[0] > 0 && dateSplit[0] <= 23) && (dateSplit[1] > 0) && (count(dateSplit) == 4)) {
        timePortion = dateSplit[0];
        datePortion = date.substr(date.indexOf(' ') + 1);
        if (strstr(timePortion, ",")) {
            timePortion = timePortion.replace(",", "");
        }
        if (strstr(datePortion, ",")) {
            datePortion = datePortion.replace(",", "");
        }
    } else if ((isset(strstr(dateSplit[0], "am")) || isset(strstr(dateSplit[0], "pm"))) && (count(dateSplit) == 4)) {
        timePortion = dateSplit[0];
        var i = 1;
        while (i < dateSplit.length) {
            datePortion += " " + dateSplit[i];
            i++;
        }
        if (strstr(timePortion, ",")) {
            timePortion = timePortion.replace(",", "");
        }
        if (strstr(datePortion, ",")) {
            datePortion = datePortion.replace(",", "");
        }
    } else if ((isset(strstr(dateSplit[0], "am")) || isset(strstr(dateSplit[0], "pm"))) && (count(dateSplit) == 4)) {
        timePortion = dateSplit[0];
        var i = 1;
        while (i < dateSplit.length) {
            datePortion += " " + dateSplit[i];
            i++;
        }
        if (strstr(timePortion, ",")) {
            timePortion = timePortion.replace(",", "");
        }
        if (strstr(datePortion, ",")) {
            datePortion = datePortion.replace(",", "");
        }
    } else if ((dateSplit[0] > 0 && dateSplit[0] <= 23) && (isset(dateSplit[1])) && (count(dateSplit) == 4)) {
        timePortion = dateSplit[0];
        var i = 1;
        while (i < dateSplit.length) {
            datePortion += " " + dateSplit[i];
            i++;
        }
        if (strstr(timePortion, ",")) {
            timePortion = timePortion.replace(",", "");
        }
        if (strstr(datePortion, ",")) {
            datePortion = datePortion.replace(",", "");
        }
    } else if ((isset(strstr(dateSplit[0], "am")) || isset(strstr(dateSplit[0], "pm"))) && isset(dateSplit[1])) {
        timePortion = dateSplit[0];
        datePortion = dateSplit[1];
        if (strstr(timePortion, ",")) {
            timePortion = timePortion.replace(",", "");
        }
        if (strstr(datePortion, ",")) {
            datePortion = datePortion.replace(",", "");
        }
    } else if ((dateSplit[0] > 0 && dateSplit[0] <= 23) && isset(dateSplit[1]) && (count(dateSplit) == 2)) {
        timePortion = dateSplit[0];
        datePortion = dateSplit[1];
        if (strstr(timePortion, ",")) {
            timePortion = timePortion.replace(",", "");
        }
        if (strstr(datePortion, ",")) {
            datePortion = datePortion.replace(",", "");
        }
    } else {

        if(strstr(date, "on")) date = date.replace("on","");
        if(strstr(date, "of")) date = date.replace("of","");
        if(strstr(date, "the")) date = date.replace("the","");
        if(strstr(date, "at")) date = date.replace("at","");

        timePortion = "10";
        if (count(dateSplit) == 1) {
            datePortion = dateSplit[0];
        } else {
            datePortion = dateSplit[0] + " " + date.substr(date.indexOf(' ') + 1);
        }
        if (strstr(datePortion, ",")) {
            datePortion = datePortion.replace(",", "");
            datePortion = datePortion.replace(" ", "");
        }
    }
    return {timePortion: timePortion.toLowerCase().trim(), datePortion: datePortion.toLowerCase().trim()};
};

DateTime.prototype.calculateValidHours = function(timePortion, period) {
    if (parseInt(timePortion) >= 1 && parseInt(timePortion) <= 24) {
        if (isset(period)) {
            if (period == "pm" && timePortion < 12) {
                timePortion = parseInt(timePortion) + 12;
            } else if (period == "am" && timePortion == 12) {
                timePortion = parseInt(timePortion) - 12;
            }
        }
        this.hours = parseInt(timePortion); //-1 hours because js's stupid date format starts @ 0
    } else {
        this.hours = 12; //12 noon
    }
};

DateTime.prototype.convert24To12 = function(hours) {
    if (empty(hours))
        return 0;
    if (hours > 12) {
        hours -= 12;
    } else if (hours === 0) {
        hours = 12;
    }
    return hours;
};

DateTime.prototype.convert12To24 = function(hours, minutes) {
    if (hours < 12) hours = hours + 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return [sHours, sMinutes];
};
