/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.filter('deferTranslate', function () {
    /**
     * Renders a $translate promise, and only displays the final result.
     *
     * It is recommended that the code that handles translations is done within the view, however if (for
     * legacy code support) you need to, this filter helps get around the convoluted $translate promise issue.
     *
     * By default, the filter will return an empty string for when the promise is still pending. As the
     * promise does not have access to the original translation key - it cannot render the key as default.
     *
     * Note: This filter does not update when the language changes from calling `$translate.use` and passing
     * through a language key. This could be fixed by passing through the original translation key within the
     * $q object, or by passing a string instead of a promise.
     *
     * @example
     *     app.controller('ExampleController', function ($scope, $translate) {
     *         // What you had to do before...
     *         $translate('example.text').then(function (text) {
     *             $scope.foobar = text;
     *         });
     *     });
     *
     * @example
     *     <script>
     *         app.controller('ExampleController', function ($scope, $translate) {
     *             // What you do now...
     *             $scope.foobar = $translate('example.text');
     *         });
     *     </script>
     *
     *     <div ng-controller="ExampleController">
     *         {{ foobar | deferTranslate }}
     *     </div>
     *
     * @param {$q} data
     * @returns {String}
     */
    return data => (data && data.$$state.status === 1 && data.$$state.value) || '';
});
