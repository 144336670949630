/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class ToolsController {
    /**
     * The tools registered for the tool set.
     *
     * @type {ToolController[]}
     */
    tools = [];

    /**
     * Registers a tool and returns an de-register function.
     *
     * @param {ToolController} toolCtrl
     * @returns {Function}
     */
    registerTool (toolCtrl) {
        let tools = this.tools;

        // Push the controller to the tools array
        tools.push(toolCtrl);

        return function deregisterTool () {
            tools.splice(tools.indexOf(toolCtrl), 1);
        };
    }
}

class ToolController {
    constructor (
        $rootScope
    ) {
        this.$$ = {
            $rootScope,
        }
    }
    /**
     * Whether the tool can be selected.
     *
     * @type {Boolean}
     */
    canSelect = true;

    /**
     * Whether the tool is selected.
     *
     * @type {Boolean}
     */
    selected = false;

    /**
     * Whether the tool is open (if it has options).
     *
     * @type {Boolean}
     */
    open = false;

    /**
     * Which icon the tool renders.
     *
     * @type {String}
     */
    icon = null;

    /**
     * The sub-option which is currently selected for the tool.
     *
     * @type {null}
     */
    option = null;

    /**
     * The sub-options for the tool.
     *
     * @type {ToolOptionController[]}
     */
    options = [];

    /**
     * tool's label color
     * @type {string}
     */
    labelColor = '';

    /**
     * If tool's tooltip is dark background
     * @type {boolean}
    */
    isDark = false;

    /**
     * If icon is disabled
     * @type {boolean}
     */
    isDisabled = false;

    /**
     * If tolltip is delayed
     * @type {string}
     */
    delay = '250ms';

    /**
     *
     */
    toggleTool() {
        if (this.options.length) {
            this.open = ! this.open;
        } else {
            if (this.canSelect) {
                this.selected = ! this.selected;
            } else {
                this.selected = false;
            }
        }
        if (!this.$$.$rootScope.$$phase) {
            // jacob: I've noticed that sometimes this can happen... not sure of the cause yet :/
            this.$$.$rootScope.$apply();
        }
    }

    /**
     * Selects a specific tool option.
     *
     * @param {ToolOptionController} toolOptionCtrl
     */
    selectOption (toolOptionCtrl) {
        this.option = toolOptionCtrl && toolOptionCtrl.canSelect ? toolOptionCtrl.id : null;
        if (toolOptionCtrl.hideTool) {
            this.toggleTool();
        }
    }

    /**
     * Registers a tool option and returns a de-register function.
     *
     * @param {ToolOptionController} toolOptionCtrl
     * @returns {Function}
     */
    registerToolOption (toolOptionCtrl) {
        let options = this.options;

        // Push the controller to the options array
        options.push(toolOptionCtrl);

        return function deregisterToolOption () {
            options.splice(options.indexOf(toolOptionCtrl), 1);
        };
    }
}

class ToolOptionController {
    /**
     * The id of the option.
     *
     * @type {String}
     */
    id = null;

    /**
     * Whether the option is selectable.
     *
     * @type {boolean}
     */
    canSelect = true;

    /**
     * Whether to hide tool on tool-option click.
     *
     * @type {boolean}
     */
    hideTool = false;
}

function ToolsDirective () {
    return {
        require: ['tools'],
        transclude: true,
        templateUrl: 'templates/partials/proof/components/tools.html',
        controller: 'ToolsController',
        controllerAs: 'toolsCtrl',
        replace: true,
        scope: true
    };
}

function ToolDirective (directiveHelper) {
    return {
        require: ['tool', '^tools'],
        transclude: true,
        templateUrl: 'templates/partials/proof/components/tool.html',
        controller: 'ToolController',
        controllerAs: 'toolCtrl',
        replace: true,
        scope: true,

        link (scope, element, attr, [toolCtrl, toolsCtrl]) {
            scope.$on('$destroy', toolsCtrl.registerTool(toolCtrl));

            directiveHelper.expressionBinding(scope, attr, 'icon', toolCtrl, 'icon');

            directiveHelper.expressionBinding(scope, attr, 'label', toolCtrl, 'label');

            if ('labelColor' in attr) {
                directiveHelper.expressionBinding(scope, attr, 'labelColor', toolCtrl, 'labelColor');
            }

            if ('isDark' in attr) {
                directiveHelper.expressionBinding(scope, attr, 'isDark', toolCtrl, 'isDark');
            }

            if ('isDisabled' in attr) {
                directiveHelper.expressionBinding(scope, attr, 'isDisabled', toolCtrl, 'isDisabled');
            }

            if ('delay' in attr) {
                directiveHelper.expressionBinding(scope, attr, 'delay', toolCtrl, 'delay');
            }

            if ('walkthroughHook' in attr) {
                directiveHelper.expressionBinding(scope, attr, 'walkthroughHook', toolCtrl, 'walkthroughHook');
            }

            if ('option' in attr) {
                directiveHelper.twoWayBinding(scope, attr, 'option', toolCtrl, 'option');
            }

            if ('open' in attr) {
                directiveHelper.twoWayBinding(scope, attr, 'open', toolCtrl, 'open');
            }

            if ('selected' in attr) {
                directiveHelper.twoWayBinding(scope, attr, 'selected', toolCtrl, 'selected');
            }

            if ('canSelect' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'canSelect', toolCtrl, 'canSelect');
            }

            const handleLoseFocus = function(event) {
                if (toolCtrl.open && !jQuery.contains(element[0], event.target)) {
                    scope.$apply(() => toolCtrl.toggleTool());
                }
            };
            document.body.addEventListener('mousedown', handleLoseFocus, false);
            document.body.addEventListener('touchstart', handleLoseFocus, false);
            scope.$on('$destroy', () => {
                document.body.removeEventListener('mousedown', handleLoseFocus, false);
                document.body.removeEventListener('touchstart', handleLoseFocus, false);
            });
        }
    };
}

function ToolOptionDirective (directiveHelper) {
    return {
        require: ['toolOption', '^tool'],
        transclude: true,
        templateUrl: 'templates/partials/proof/components/tool-option.html',
        controller: 'ToolOptionController',
        controllerAs: 'toolOptionCtrl',
        scope: true,

        link (scope, element, attr, [toolOptionCtrl, toolCtrl]) {
            scope.toolCtrl = toolCtrl;

            scope.$on('$destroy', toolCtrl.registerToolOption(toolOptionCtrl));

            directiveHelper.expressionBinding(scope, attr, 'id', toolOptionCtrl, 'id');

            if ('canSelect' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'canSelect', toolOptionCtrl, 'canSelect');
            }
            if ('hideTool' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'hideTool', toolOptionCtrl, 'hideTool');
            }
        }
    };
}

app
    .controller('ToolsController', ToolsController)
    .controller('ToolController', ToolController)
    .controller('ToolOptionController', ToolOptionController)
    .directive('tools', ToolsDirective)
    .directive('tool', ToolDirective)
    .directive('toolOption', ToolOptionDirective);
