/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const loadingDefaults = {
    tab: false,
    upload: false,
    alignment: false,
    colors: false,
    toggle: false,
};

const brandingTypes = {
    email: 'email', 
    emailFooter: 'email_footer', 
    logo: 'logo',
}

function isBlobURL(url) {
    return url && url.trim().indexOf('blob:') === 0;
}

class BrandingSettingsController extends BaseController {
    constructor() {
        super();

        this.$$import(this.$$dependencies([
            '$rootScope',
            'backendService',
            'utilService',
            'seoService',
            'PPDomainBranding',
            'UserService',
            'sdk',
            '$scope',
        ]));

        this.props = {
            loading: {
                email: Object.assign({}, loadingDefaults),
                emailFooter: Object.assign({}, loadingDefaults),
                logo: Object.assign({}, loadingDefaults),
            },
            email: new this.$$.PPDomainBranding({
                typeKey: 'email',
            }),
            emailFooter: new this.$$.PPDomainBranding({
                typeKey: 'emailFooter',
            }),
            logo: new this.$$.PPDomainBranding({
                typeKey: 'logo',
            }),
            emailColors: {
                titleTextColor: null,
                linkTextColor: null,
                buttonBackgroundColor: null,
                buttonTextColor: null,
            },
            restrictedFooterBanner: this.$$.sdk.session.user.teamType !== 2,
            isEmailColorsFeatureEnabled: false,
            uploadFile: this.uploadFile,
            toggleBranding: this.toggleBranding,
            updateAlignment: this.updateAlignment,
            updateEmailColors: this.updateEmailColors,
        };
        this.$$.seoService.set({
            title: 'domain-admin.menu.branding',
            pageTitle: 'domain-admin.menu.branding',
            translateTitle: true,
            translatePageTitle: true,
        });

        this.load();
    }

    propagateLogo() {
        if (!this.props.logo.exists) {
            // prevent the logo from being unset in localStorage before the API has responded
            return;
        }

        const url = this.props.logo.enabled
            ? this.props.logo.url
            : null;

        if (!isBlobURL(this.props.logo.url)) {
            // only update the user's localStorage object if the url is pointed at a server,
            // otherwise the user can end up with a broken logo when they refresh.
            this.$$.UserService.UpdateUserData({brandingUrl: url});
        }

        this.$$.$rootScope.logos.user = url;
    }

    load() {
        Object.keys(brandingTypes).forEach(this.loadBranding);
        this.loadEmailColors();
    }

    updateBrandingData = (typeKey, data) => {
        if (data && typeKey) {
            this.props[typeKey].updateFromDomainBrandingData(data);
        }
        this.propagateLogo();
        return this.props[typeKey];
    }

    loading(brandingType, loadingType, promise) {
        this.props.loading[brandingType][loadingType] = true;
        promise.then(data => {
            this.props.loading[brandingType][loadingType] = false;
            if(!this.$$.$scope.$$phase) this.$$.$scope.$apply();
            return data;
        });
        return promise;
    }

    sendRequest(name, params, typeKey) {
        return this.$$.backendService.fetch('domain.branding.' + name, params)
            .data().then(data => this.updateBrandingData(typeKey, data));
    }

    uploadFile = (typeKey, file) => {
        this.props[typeKey].url = URL.createObjectURL(file);
        this.propagateLogo();

        return this.loading(typeKey, 'upload', (
            this.$$.utilService.blobToDataURL(file)
                .then(image => this.sendRequest('upload', {
                    type: brandingTypes[typeKey],
                    image,
                    alignment: this.props[typeKey].alignment,
                    enabled: this.props[typeKey].enabled,
                }, typeKey))
        ));
    }

    loadEmailColors() {
        this.props.loading.email.colors = true;
        this.$$.sdk.admin.team.features.load().then((settings) => {
            const { customStyles, titleTextColor, linkTextColor, buttonBackgroundColor, buttonTextColor } = settings.emails;
            Object.assign(this.props.emailColors, {
                titleTextColor,
                linkTextColor,
                buttonBackgroundColor,
                buttonTextColor,
            });
            this.props.loading.email.colors = false;
            this.props.isEmailColorsFeatureEnabled = customStyles;
        });
    }

    updateEmailColors = (updates) => {
        this.props.loading.email.colors = true;
        Object.assign(this.props.emailColors, updates);
        this.$$.sdk.admin.team.features.update({ emails: updates }).finally(() => {
            this.props.loading.email.colors = false;
        });
    }

    loadBranding = (typeKey) => {
        return this.loading(typeKey, 'tab', (
            this.sendRequest('load', { type: brandingTypes[typeKey] }, typeKey)
        ));
    }

    toggleBranding = (typeKey, enabled) => {
        const updateToggleBranding = [ this.updateBranding(typeKey, { enabled }) ];

        if (typeKey === 'email' && !this.props.restrictedFooterBanner) {
            updateToggleBranding.push(this.updateBranding('emailFooter', { enabled }));
        }

        return this.loading(typeKey, 'toggle', Promise.all(updateToggleBranding));
    }

    updateAlignment = (typeKey, alignment) => {
        return this.loading(typeKey, 'alignment', (
            this.updateBranding(typeKey, { alignment })
        ));
    }

    updateBranding(typeKey, updates) {
        const branding = this.props[typeKey];
        const params = {};
        for (let key in updates) {
            if (updates[key] !== branding[key]) {
                params[key] = updates[key];
            }
        }
        const shouldUpdate = Object.keys(params).length >= 1;
        if (shouldUpdate) {
            Object.assign(branding, params); // Update local branding object before request (prevents request lag)
            const payload = Object.assign(params, { type: brandingTypes[typeKey] });
            return this.sendRequest('update', payload, typeKey);
        }
    }
}

app.controller('BrandingSettingsController', BrandingSettingsController);
