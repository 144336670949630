/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class OrbSelectController extends SelectController {
    /**
     * The context controller.
     *
     * @type {ContextController}
     */
    context = null;

    /**
     * Gets the color for a specific option.
     *
     * @param {*} value
     * @returns {String}
     */
    getColor (value) {
        let option = this.getOption(value);
        return option ? option.color || option.value : 'gray';
    }

    /**
     * Select a new option.
     *
     * @param {*} value
     */
    selectOption (value) {
        if (super.selectOption(value)) {
            this.context.active = false;
        }
    }
}

function OrbSelectDirective (directiveHelper) {
    return {
        require: ['orbSelect'],
        controller: OrbSelectController,
        controllerAs: 'selectCtrl',
        template: `
            <div class="app__orb-select app__orb-select--{{ selectCtrl.getColor(selectCtrl.selected) }}">
                <context bind="selectCtrl.context" direction="{{ selectCtrl.direction }}">
                    <div class="app__orb-select__orb" context-subject></div>
                    <ul class="app__orb-select__options" context-overlay>
                        <li ng-repeat="option in selectCtrl.options"
                            ng-click="selectCtrl.selectOption(option.value)">
                            {{ option.name }}
                        </li>
                    </ul>
                </context>
            </div>
        `,
        scope: true,

        link (scope, element, attr, [selectCtrl]) {
            directiveHelper.twoWayBinding(scope, attr, 'selected', selectCtrl, 'selected', false);
            directiveHelper.oneWayBinding(scope, attr, 'options', selectCtrl, 'options', true);
            directiveHelper.expressionBinding(scope, attr, 'direction', selectCtrl, 'direction');
            directiveHelper.callbackBinding(scope, attr, 'whenChange', selectCtrl, 'whenChange');
        }
    };
}

app.directive('orbSelect', OrbSelectDirective);