/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
//angular
//    .module('createProof')
//    .controller('FileSelectController', function ($exceptionHandler, callbackService, eventService) {
//        var directiveElement,
//            fileElement,
//            allowClick,
//            callback,
//            eventListeners = [];
//
//        this.setCallback = function (newCallback) {
//            callback = newCallback;
//        };
//
//        this.setAllowClick = function (newAllowClick) {
//            allowClick = newAllowClick;
//        };
//
//        this.isAllowClick = function () {
//            return !! allowClick;
//        };
//
//        this.setDirectiveElement = function (newDirectiveElement) {
//            this.clearEventListeners();
//            directiveElement = newDirectiveElement;
//            this.bindElementEventListeners();
//        };
//
//        this.setFileElement = function (newFileElement) {
//            this.clearEventListeners();
//            fileElement = newFileElement;
//            this.bindElementEventListeners();
//        };
//
//        this.clearFileElementValue = function () {
//            try {
//                if (fileElement) {
//                    fileElement.type = 'text';
//                    fileElement.type = 'file';
//                }
//            } catch (err) {
//                $exceptionHandler(err);
//            }
//        };
//
//        this.invokeCallback = function (files) {
//            files = files ? [].slice.apply(files) : [];
//
//            if (callback && files.length) {
//                try {
//                    callback({ files: files, file: files[0] });
//                } catch (err) {
//                    $exceptionHandler(err);
//                }
//            }
//        };
//
//        this.bindElementEventListeners = function () {
//            if (directiveElement) {
//                this.bindDirectiveElementEventListeners();
//            }
//            if (fileElement) {
//                this.bindFileElementEventListeners();
//            }
//        };
//
//        this.bindDirectiveElementEventListeners = function () {
//            eventListeners = eventListeners.concat([
//                /**
//                 * Prevent the browser from handling file drags onto the drop zone and opening the file
//                 * in the current tab. This allows us to capture the drop event.
//                 */
//                eventService.on(directiveElement, 'dragover dragenter drop', function (event) {
//                    event.preventDefault();
//                }),
//
//                /**
//                 * Passes the callback the files that have been dropped onto the dropzone.
//                 */
//                eventService.on(directiveElement, 'drop', function (event) {
//                    var files = event.originalEvent.dataTransfer.files;
//
//                    if (files.length) {
//                        this.invokeCallback(files);
//                    }
//                }.bind(this))
//            ]);
//        };
//
//        this.bindFileElementEventListeners = function () {
//            eventListeners = eventListeners.concat([
//                /**
//                 * When ever the input element changes the inner value, invoke the callback with the
//                 * file. If there are no files, no callback will be invoked.
//                 */
//                eventService.on(fileElement, 'change', function () {
//                    debugger;
//
//                    var files = fileElement.prop('files');
//
//                    if (files.length) {
//                        this.invokeCallback(files);
//                    }
//
//                    this.clearFileElementValue();
//                }.bind(this)),
//
//                /**
//                 * Prevent the parent elements from recieving the click event when force triggered.
//                 *
//                 * @see bindDirectiveElementEventListeners
//                 */
//                eventService.on(fileElement, 'click', function (event) {
//                    event.stopPropagation();
//                    event.stopImmediatePropagation();
//                })
//            ]);
//        };
//
//        this.clearEventListeners = function () {
//            callbackService.clean(eventListeners);
//        };
//    })
//    .directive('fileSelect', function ($parse) {
//        return {
//            controller: 'FileSelectController',
//            controllerAs: 'fileSelect',
//            transclude: true,
//            template: '' +
//                '<div class="file-select">' +
//                    '<div class="file-select__content" ng-transclude></div>' +
//                    '<div class="file-select__wrapper" ng-if="fileSelect.isAllowClick()">' +
//                        '<input type="file" class="file-select__element" multiple file-select-element>' +
//                    '</div>' +
//                '</div>',
//            scope: true,
//            link: function (scope, element, attrs, fileSelect) {
//                fileSelect.setDirectiveElement(element);
//
//                scope.$watch(attrs.allowClick, function (allowClick) {
//                    fileSelect.setAllowClick(!! allowClick);
//                });
//
//                var whenSelect = $parse(attrs.whenSelect);
//                fileSelect.setCallback(function (locals) {
//                    console.log(scope);
//                    return whenSelect(scope, locals);
//                });
//            }
//        };
//    })
//    .directive('fileSelectElement', function () {
//        return {
//            require: '^fileSelect',
//            link: function (scope, element, attrs, fileSelect) {
//                fileSelect.setFileElement(element);
//            }
//        };
//    });
