/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

const DEFAULT_WIDTH = 500;
const MIN_WIDTH = 350;
const STEP_MAGNET_THRESHOLD = 15;

class FlyoutController {
    /**
     * Whether the flyout is open.
     *
     * @type {Boolean}
     */
    isOpen = false;

    /**
     * Whether the user can toggle the flyout.
     *
     * @type {Boolean}
     */
    canToggle = false;

    /**
     * The label to display when the flyout is collapsed.
     *
     * @type {String}
     */
    label = '';

    steps = [DEFAULT_WIDTH];

    /**
     * @constructor
     */
    constructor ($element) {
        this.element = $element;
        this.resizeHandle = $element.find('.app__flyout__resizeHandle');
        this.registerResizeHandleEvents();
    }

    registerResizeHandleEvents() {
        const flyout = this;
        const rootElement = this.element[0];

        this.resizeHandle.on('dblclick', () => {
            this.resetWidth();
        });

        this.resizeHandle.on('mousedown', (event) => {
            let temporaryWidth;

            event.preventDefault();
            rootElement.style.transition = 'none';

            const mousemoveHandler = (event) => {
                temporaryWidth = Math.max(MIN_WIDTH, window.innerWidth - event.x);

                const closestStep = this.getClosestStep(temporaryWidth);
                if (closestStep && Math.abs(closestStep - temporaryWidth) < STEP_MAGNET_THRESHOLD) {
                    temporaryWidth = closestStep;
                }

                rootElement.style.width = temporaryWidth + 'px';
            };

            window.addEventListener('mousemove', mousemoveHandler);
            window.addEventListener('mouseup', function mouseupHandler() {
                window.removeEventListener('mousemove', mousemoveHandler);
                window.removeEventListener('mouseup', mouseupHandler);

                // reset transition style
                rootElement.style.transition = '';

                if (temporaryWidth) {
                    flyout.setWidth(temporaryWidth);

                    if (flyout.whenResized) {
                        flyout.whenResized({ width: temporaryWidth });
                    }
                }
            });
        });
    }

    getNextStep(width) {
        const currentIndex = this.steps.indexOf(width);
        if (currentIndex !== -1) {
            return this.steps[(currentIndex + 1) % this.steps.length];
        }
        return null;
    }

    getClosestStep(width) {
        let shortestDistance = Infinity;
        let step;

        this.steps.forEach((stepWidth) => {
            const distance = Math.abs(stepWidth - width);
            if (distance < shortestDistance) {
                shortestDistance = distance;
                step = stepWidth;
            }
        });

        return step;
    }

    resetWidth() {
        let nextWidth = this.getNextStep(this.width);

        if (!nextWidth) {
            nextWidth = this.getClosestStep(this.width);
        }

        this.setWidth(nextWidth);

        if (this.whenResized) {
            this.whenResized({ width: this.width });
        }
    }

    setWidth(width) {
        this.width = width;
        this.element[0].style.width = width + 'px';
    }

    /**
     * Toggles the `isOpen` state of the flyout.
     *
     * @see {FlyoutController.isOpen}
     */
    toggleOpen () {
        if (this.canToggle) {
            this.whenOpen({open: !this.isOpen});
        }
    }
}

function updateBodyClass (isOpen) {
    angular.element('body')[isOpen ? 'addClass' : 'removeClass']('proof-comments-active');
}

function FlyoutDirective (directiveHelper) {
    return {
        restrict: 'E',
        require: ['flyout'],
        transclude: true,
        replace: true,
        templateUrl: 'templates/partials/proof/components/flyout.html',
        controller: 'FlyoutController',
        controllerAs: 'flyoutCtrl',
        scope: true,

        link (scope, element, attr, [flyoutCtrl]) {
            if ('width' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'width', flyoutCtrl, 'width', false, (width) => {
                    flyoutCtrl.setWidth(width);
                });
            }

            if ('steps' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'steps', flyoutCtrl, 'steps');
            }

            if ('isOpen' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'isOpen', flyoutCtrl, 'isOpen');
            }

            if ('showUpArrow' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'showUpArrow', flyoutCtrl, 'showUpArrow');
            }

            if ('showDownArrow' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'showDownArrow', flyoutCtrl, 'showDownArrow');
            }

            if ('whenOpen' in attr) {
                directiveHelper.callbackBinding(scope, attr, 'whenOpen', flyoutCtrl, 'whenOpen');
            }

            if ('canToggle' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'canToggle', flyoutCtrl, 'canToggle');
            }

            if ('label' in attr) {
                directiveHelper.expressionBinding(scope, attr, 'label', flyoutCtrl, 'label');
            }

            if ('heading' in attr) {
                directiveHelper.expressionBinding(scope, attr, 'heading', flyoutCtrl, 'heading');
            }

            if ('privateCommentsCount' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'privateCommentsCount', flyoutCtrl, 'privateCommentsCount');
            }

            if ('bind' in attr) {
                directiveHelper.controllerBinding(scope, attr, 'bind', flyoutCtrl);
            }

            if ('whenResized' in attr) {
                directiveHelper.callbackBinding(scope, attr, 'whenResized', flyoutCtrl, 'whenResized');
            }

            if ('navigateComments' in attr) {
                directiveHelper.callbackBinding(scope, attr, 'navigateComments', flyoutCtrl, 'navigateComments');
            }

            scope.$watch(() => flyoutCtrl.isOpen, updateBodyClass);
        }
    };
}

app
    .controller('FlyoutController', FlyoutController)
    .directive('flyout', FlyoutDirective);
