/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
angular
    .module('ppxGlobalControllers')
    .factory('updateService', function ($http, $interval) {
        return {
            /**
             * Whether or not the app is currently outdated.
             *
             * @type {Boolean}
             */
            outdated: false,
            /**
             * Check for updates.
             *
             * @returns {$q}
             */
            check: function () {
                return $http.get('ping.php?version=' + PageProof.version)
                    .then(function (response) {
                        return response.data;
                    });
            },
            /**
             * Checks for updates on a regular interval.
             *
             * (Optional) Invokes the callback when outdated state changes.
             *
             * @param {Number} time
             * @param {Function} [callback]
             */
            interval: function (time, callback) {
                if (time) {
                    var that = this;
                    time = Math.max(1e3 * 60, time); // Minimum of 1 minute (for debugging)

                    console.log('Starting update scheduler (interval: %sms)', time);

                    return $interval(function () {
                        that.check().then(function (data) {
                            var beforeOutdated = that.outdated;
                            that.outdated = data.diff === 'major' || data.diff === 'minor';

                            if (beforeOutdated !== that.outdated) {
                                callback(that.outdated);
                            }
                        });
                    }, time);
                } else {
                    console.warn('Disabling update scheduler.');
                }
            }
        };
    });
