/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/**
 * html-content
 *
 * @example
 *     <div html-content="example.html"></div>
 *
 * @param $parse
 * @returns {Object}
 */
function HtmlContentDirective ($parse) {
    return {
        link (scope, element, attr) {
            let parse = $parse(attr.htmlContent);

            scope.$watch(() => parse(scope), function (data, previousData) {
                if (data) {
                    if (angular.isFunction(data)) {
                        data(scope, (html) => element.html(html));
                    } else {
                        element.html(data);
                    }
                } else if (previousData) {
                    element.empty();
                }
            });
        }
    };
}

app
    .directive('htmlContent', HtmlContentDirective);
