/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
var ppb = window.__pageproof_bridge__ = {};
createProof.run(function($location,
                         $rootScope,
                         avatarRepositoryService,
                         userRepositoryService,
                         tooltipService,
                         DataService,
                         UserService,
                         dateService,
                         utilService,
                         addressBookRepositoryService,
                         temporaryStorageService,
                         $addressBook,
                         appService,
                         SegmentIo,
                         rainbowService,
                         NavigationService,
                         importService,
                         DataService,
                         backendService,
                         browserService,
                         $routeParams,
                         storageService,
                         proofInfoService,
                         printService,
                         printServiceLegacy,
                         $filter,
                         seoService,
                         domService,
                         moment,
                         modalService,
                         userService,
                         liveClient,
                         features,
                         PPProofDialogType,
                         backgroundService,
                         zipService) {
  ppb['$location'] = $location;
  ppb['$rootScope'] = $rootScope;
  ppb['avatarRepositoryService'] = avatarRepositoryService;
  ppb['userRepositoryService'] = userRepositoryService;
  ppb['tooltipService'] = tooltipService;
  ppb['dataService'] = DataService;
  ppb['UserService'] = UserService;
  ppb['dateService'] = dateService;
  ppb['utilService'] = utilService;
  ppb['addressBookRepositoryService'] = addressBookRepositoryService;
  ppb['temporaryStorageService'] = temporaryStorageService;
  ppb['$addressBook'] = $addressBook;
  ppb['appService'] = appService;
  ppb['SegmentIo'] = SegmentIo;
  ppb['rainbowService'] = rainbowService;
  ppb['NavigationService'] = NavigationService;
  ppb['importService'] = importService;
  ppb['DataService'] = DataService;
  ppb['backendService'] = backendService;
  ppb['browserService'] = browserService;
  ppb['$routeParams'] = $routeParams;
  ppb['storageService'] = storageService;
  ppb['proofInfoService'] = proofInfoService;
  ppb['printService'] = printService;
  ppb['printServiceLegacy'] = printServiceLegacy;
  ppb['$filter'] = $filter;
  ppb['seoService'] = seoService;
  ppb['domService'] = domService;
  ppb['moment'] = moment;
  ppb['modalService'] = modalService;
  ppb['userService'] = userService;
  ppb['liveClient'] = liveClient;
  ppb['features'] = features;
  ppb['PPProofDialogType'] = PPProofDialogType;
  ppb['backgroundService'] = backgroundService;
  ppb['zipService'] = zipService;
});
