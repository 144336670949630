/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.filter('boldCommentWithMention', function($sce) {
    return function (str) {
        if (str.length > 0) {
            let regex = new RegExp('@\\{(~?\\w+)}\\{(([a-zA-Z0-9-_.@+:()#|$\'%*! ]|[^\\x00-\\x7F])+)}', 'gi');
            let comment_test_replaced = stripText(str).replace(regex, function replacer(a, id, name){
                return `<span class='proof__page__comment__content--bold _${stripText(id)}'>${stripText(name)}</span>`;
            });
            return $sce.trustAsHtml(comment_test_replaced);
        }
        return str;
    };
});
