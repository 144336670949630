/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class PrintServiceLegacy {
    constructor($rootScope, $controller, browserService, modalService, appService) {
        this.$$ = {$rootScope, $controller, browserService, modalService, appService};
    }
    printProof(proofId, type, allCommentsAreTodos, callback = () => {}) {
        this.$$.appService.showTranslatedLoaderMessage('loader-message.generating-comment-list');
        // Mock a proof print controller (HACK)
        var printController = this.$$.$controller('ProofPrintControllerLegacy', {
            $scope: {}, $element: null
        });

        var prompt = () => {
            this.$$.modalService.translate(
                title,
                message,
                buttons
            );
        };

        var print = (printType) => {
            if (fallback) {
                var url = 'printLegacy.php?proofId=' + proofId + '&printType=' + printType;
                open(url, '_blank');
            } else {
                this.$$.$rootScope.$emit('print', {proofId, printType});
            }
            callback();
        };

        var isBrief = window.isBriefId(proofId);
        var proofType = isBrief ? "brief" : "proof";
        var fallback = this.$$.browserService.is('ie') || this.$$.browserService.is('edge'),
            buttons = [],
            message = `print-${proofType}.huge-list`,
            title = 'print-proof.generate-print-sheet';

        if (type === 'approval') {
            message = 'print-proof.approval-comments.message';
            title = 'print-proof.approval-comments.title';

            printController
                .canPrint(proofId, 'comments')
                .then(() => {
                    buttons = [
                        {
                            text: 'button.cancel',
                            type: 'text'
                        },
                        {
                            text: 'print-proof.comments',
                            type: 'outline',
                            click: print.bind(null, 'comments')
                        },
                        {
                            text: 'print-proof.approval',
                            type: 'primary',
                            click: print.bind(null, 'approval')
                        }
                    ];
                    prompt();
                })
              .catch(() => {
                  print('approval');
              })
              .finally(() => {
                  this.$$.appService.hideLoader();
              });
        } else if (allCommentsAreTodos) {
            print('todos');
        } else {
            printController
                .canPrint(proofId, 'todos')
                .then(() => {
                    buttons = [
                        {
                            text: 'button.cancel',
                            type: 'text'
                        },
                        {
                            text: 'print-proof.all-comments',
                            type: 'outline',
                            click: print.bind(null, 'comments')
                        },
                        {
                            text: 'print-proof.only-to-dos',
                            type: 'primary',
                            click: print.bind(null, 'todos')
                        },
                    ];
                  prompt();
              })
              .catch(() => {
                  print('comments');
              })
              .finally(() => {
                  this.$$.appService.hideLoader();
              });
        }
    }
}

app.service('printServiceLegacy', PrintServiceLegacy);