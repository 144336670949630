/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
angular
    .module('ppxGlobalControllers')
    .factory('proofInfoService',
        function ($rootScope) {
            /**
             * The private scope that's used for sending events.
             *
             * @type {$rootScope.Scope}
             */
            var $scope = $rootScope.$new();

            return {
                /**
                 * Emits an event.
                 *
                 * @param {String} event
                 * @param {*} [data]
                 * @returns {Boolean}
                 */
                $$emit: function (event, data) {
                    return ! $scope.$emit('proofInfo.' + event, data).defaultPrevented;
                },
                /**
                 * Opens the proof into pane, with the specified proof id.
                 *
                 * @param {String} id
                 * @param {*} from
                 */
                open: function (id, from, options) {
                    if (this.$$open) return;

                    if (this.$$emit('beforeopen', { id: id, from: from, options: options })) {
                        this.$$emit('open', { id: id, from: from });
                        this.$$open = true;
                    }
                },
                /**
                 * Closes the proof info pane.
                 */
                close: function () {
                    if (this.$$open && this.$$emit('beforeclose')) {
                        this.$$emit('close');
                        this.$$open = false;
                    }
                },
                /**
                 * Toggles the proof info pane.
                 *
                 * @param {String} id
                 */
                toggle: function (id, from) {
                    if (this.$$open) {
                        this.close();
                    } else {
                        this.open(id, from);
                    }
                },
                /**
                 * Listen for when there is an event that fires from the private scope
                 * that the proof into service creates for event triggers.
                 *
                 * @param {String} event
                 * @param {Function} callback
                 */
                on: function (event, callback) {
                    return $scope.$on('proofInfo.' + event, callback);
                },
                /**
                 * Whether or not the proof into pane is currently open.
                 *
                 * @type {Boolean}
                 */
                $$open: false,

                /**
                 * Opens the manage proof pane
                 *
                 * @param {String} id
                 */
                openManageProof: function(id, from, options) {
                    this.open(id, from, options);
                    this.$$emit('openManageProof', options);
                }
            };
        }
    );
