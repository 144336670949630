/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
function flatten(arr) {
  return arr.reduce((a, b) => a.concat(b), []);
}

const TRUE_VALUES = [true, 1, '1', 'true', 'enabled', 'enable'];
const FALSE_VALUES = [false, 0, '0', 'false', 'disabled', 'disable'];
const TRUE_OR_FALSE_VALUES = flatten([TRUE_VALUES, FALSE_VALUES]);

const POSSIBLE_VALUES = {
  'menu': flatten(['advanced', 'simple', FALSE_VALUES]),
  'dashboard': TRUE_OR_FALSE_VALUES,
  'header': TRUE_OR_FALSE_VALUES,
  'logo': TRUE_OR_FALSE_VALUES,
  'notifications': TRUE_OR_FALSE_VALUES,
  'proof-info': TRUE_OR_FALSE_VALUES,
  'toolbar': TRUE_OR_FALSE_VALUES,
  'finished': flatten(['dashboard', 'close-window']),
  'send-to-editor': TRUE_OR_FALSE_VALUES,
  'share-proof-button': TRUE_OR_FALSE_VALUES,
  // upload-new-version.redirect
};

// default to the first possible values
const DEFAULT_FEATURES = {};
Object.keys(POSSIBLE_VALUES).forEach((name) => {
  DEFAULT_FEATURES[name] = POSSIBLE_VALUES[name][0];
});
// put any custom default features below

if (window.__pageproof_feature_flags__) {
  const customDefaultFeatureFlags = window.__pageproof_feature_flags__;
  Object.keys(customDefaultFeatureFlags).forEach((name) => {
    DEFAULT_FEATURES[name] = customDefaultFeatureFlags[name];
  });
}

class FeaturesService {
  updateHandlers = [];

  constructor($location) {
    this.$$ = {$location};

    this.referrer = $location.search()['referrer'];
    this.flags = {};
    this.flags_$location = {};
    this.flags_proof = {};
    this._loadSearch();
    this.update();
  }

  update() {
    this._correctInvalidValues(this.flags_$location);
    this._correctInvalidValues(this.flags_proof);
    this._updateFlags();
    this._applyClasses();
    this._emitUpdateEvent();
  }

  _emitUpdateEvent() {
    this.updateHandlers.forEach((updateHandler) => {
      updateHandler(Object.assign({}, this.flags));
    });
  }

  _loadSearch() {
    const SEARCH_PREFIX = 'feature.';
    const flags = this.flags_$location = {};
    const search = this.$$.$location.search();
    for (let key in search) {
      if (Object.prototype.hasOwnProperty.call(search, key) && key.indexOf(SEARCH_PREFIX) === 0) {
        const name = key.substring(SEARCH_PREFIX.length);
        const value = search[key].toLowerCase();
        flags[name] = value;
      }
    }
  }

  setProof(flags) {
    this.flags_proof = flags || {};
    return this;
  }

  _correctInvalidValues(flags) {
    Object.keys(flags).forEach((name) => {
      const value = flags[name];
      const values = POSSIBLE_VALUES[name];
      if (values && values.indexOf(value) === -1) {
        flags[name] = DEFAULT_FEATURES[name];
      }
      flags[name] = (
        TRUE_VALUES.indexOf(value) !== -1 ? true :
        FALSE_VALUES.indexOf(value) !== -1 ? false :
        value
      );
    });
  }

  _applyClasses() {
    const CLASS_PREFIX = 'feature-';
    const flags = this.flags;
    const classes = Object.keys(flags).map((name) => {
      var value = flags[name];
      if (TRUE_OR_FALSE_VALUES.indexOf(value) !== -1) {
        const falsy = FALSE_VALUES.indexOf(value) !== -1;
        return (falsy ? 'no-' : '') + name;
      }
      return name + '-' + value;
    });
    const classNames = document.documentElement.className.split(/\s+/).filter((className) => {
      // remove any pre-existing feature flag classes
      return className.indexOf(CLASS_PREFIX) !== 0;
    });
    // add the new feature flag classes
    classes.forEach(className => classNames.push(CLASS_PREFIX + className));
    document.documentElement.className = classNames.join(' ');
  }

  _updateFlags() {
    Object.keys(this.flags).forEach((name) => {
      delete this.flags[name];
    });
    Object.assign(this.flags, DEFAULT_FEATURES, this.flags_$location, this.flags_proof);
  }

  onUpdate(updateHandler) {
    this.updateHandlers.push(updateHandler);
    return () => {
      this.updateHandlers.splice(this.updateHandlers.indexOf(updateHandler), 1);
    };
  }
}

app.service('features', FeaturesService);
