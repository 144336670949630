/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.controller('ActivateController', function ($scope, $q, $api, $routeParams, $timeout, $location, navigationService, UserService) {
    var app = UserService,
        user = app.GetUserData();

    $scope.token = $routeParams.code;
    $scope.proofId = $routeParams.proofId;

    $scope.activateCtrl = $scope;
    $scope.generatedAgain = false;

    $scope.activateProps = {
        onCredentials: function(session) {
            $location.url(session.redirectUrl || '/dashboard');
        },
    };

    function handleAccountActivation() {
        if ($scope.token) {
            $scope.submit();
        }
    };

    $scope.email = user.userEmail;
    $scope.checkToken = function (token) {
        return $scope.$$checkToken = $q.track(
            $api
                .users
                .checkAuth
                .fetch({
                    userId: user.userId,
                    authCode: token
                })
                .then(function (response) {
                    app.UpdateUserData({
                        isActivated: response.ResponseStatus === 'OK'
                    });
                })
        );
    };

    $scope.generateToken = function () {
        return $scope.$$generateToken = $q.track(
            $api
                .users
                .generateAuth
                .fetch(user.userId)
        );
    };

    $scope.generate = function (isWait) {
        $scope.token = null;
        $scope.message = null;
        $scope.generatedAgain = true;
        $scope.isWaitForGenerate = !! isWait;

        $scope
            .generateToken()
            .then(function () {
                $timeout.cancel($scope.$$cooldown);
                $scope.$$cooldown = $timeout(function () {
                    $scope.$$cooldown = null;
                }, 10e3);
            });
    };

    $scope.submit = function () {
        if (!$scope.token) return;

        $scope
            .checkToken($scope.token)
            .then(function () {
                if (user.isActivated) {
                    if ($scope.proofId) {
                        $location.url('proof-screen/' + $scope.proofId);
                    } else if ($routeParams.redirect) {
                        $location.url($routeParams.redirect);
                    } else {
                        navigationService.redirectIntended('dashboard');
                    }
                }
            })
            .catch(function () {
                $scope.message = 'invalid-token';
                $scope.token = null;
            });
    };

    $scope.logoutAndRedirect = function () {
        UserService.Logout(function() {
            $location.url('create-account').search({
                email: $scope.email
            });
        });
    };

    handleAccountActivation();
});
