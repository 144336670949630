/* Copyright (C) PageProof Holdings limited - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

'use strict';

var referrer = window.location.search
    ? window.location.search.substring(1).split('&').reduce(function (obj, part) {
        var key = part.substring(0, part.indexOf('='));
        var value = part.substring(part.indexOf('=') + 1);
        obj[key] = decodeURIComponent(value);
        return obj;
    }, {}).ref
    : null;

ppxControllers.controller('LoginController',
    function ($scope, $routeParams, $route, $location, addressBookRepositoryService, UserService) {
        $scope.loginProps = {
            onAwaitingActivation: function() {
                var redirectUrl = $routeParams.redirect;
                if (redirectUrl) {
                    $location.url(redirectUrl);
                }
            },
            onCredentials: function(session) {
                addressBookRepositoryService.remove(session.userId);
                var redirectUrl = $routeParams.redirect || session.redirectUrl || '/dashboard';
                $location.url(redirectUrl);
            },
            onRedirectSingleSignOn: function() {
                if ($routeParams.redirect) {
                    localStorage.setItem('pageproof.sso.redirect', $routeParams.redirect);
                } else {
                    localStorage.removeItem('pageproof.sso.redirect');
                }
            },
            referrer: referrer,
            code: $routeParams.code,
            email: $routeParams.email,
            mode: $routeParams.mode || ($route.current.$$route.data && $route.current.$$route.data.mode),
        };

        UserService.hideLoader();
    }
);
