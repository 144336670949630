/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.factory('browserService', function () {
    var tags = [],
        agent = navigator.userAgent,
        name,
        version,
        os;

    if (/safari/i.test(agent)) {
        if (/yabrowser/i.test(agent)) {
            tags.push(name = 'yandex');
            tags.push('chromium');
            version = parseVersion(agent, 'YaBrowser/');
        } else if (/edge/i.test(agent)) {
            tags.push(name = 'edge');
            version = parseVersion(agent, 'Edge/');
        } else if (/edg/i.test(agent)) {
            tags.push(name = 'edge');
            version = parseVersion(agent, 'Edg/');

            if (/chrome/i.test(agent)) {
                tags.push('chromium');
            }
        } else if (/(opera|opr)/i.test(agent)) {
            tags.push(name = 'opera');
            version = parseVersion(agent, 'Opera/', 'OPR/');

            if (/chrome/i.test(agent)) {
                tags.push('chromium');
            }
        } else if (/chrome/i.test(agent) || /crios/i.test(agent)) {
            tags.push(name = 'chrome');
            tags.push('chromium');
            version = parseVersion(agent, 'Chrome/');
        } else {
            tags.push(name = 'safari');
            tags.push('webkit');
            version = parseVersion(agent, 'Version/');
        }
    }

    if (/firefox/i.test(agent)) {
        tags.push(name = 'firefox');
        tags.push('gecko');
        version = parseVersion(agent, 'Firefox/');
    }

    if (/(msie|trident)/i.test(agent)) {
        tags.push(name = 'ie');
        version = parseVersion(agent, 'MSIE ', 'rv:');
    }

    if (/mobile/i.test(agent)) {
        tags.push('mobile');
    } else if (/ipad/i.test(agent) || /tablet/i.test(agent)) {
        tags.push('tablet');
    } else {
        tags.push('desktop');
    }

    if (/macintosh/i.test(agent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
        tags.push(os = 'ipados');
        tags.push('ios');
        tags.push('tablet');
        const indexOfDesktopTag = tags.indexOf('desktop');
        if (indexOfDesktopTag !== -1) {
          tags.splice(indexOfDesktopTag, 1);
        }
    } else if (/i(phone|pad|pod)/i.test(agent) && !window.MSStream) {
        tags.push(os = 'ios');
    } else if (/android/i.test(agent)) {
        tags.push(os = 'android');
    } else if (/macintosh/i.test(agent)) {
        tags.push(os = 'mac');
    } else if (/windows/i.test(agent)) {
        tags.push(os = 'windows');
    } else if (/linux/i.test(agent)) {
        tags.push(os = 'linux');
    }

    /**
     * Has every tag.
     *
     * @param {...String} args
     * @returns {Boolean}
     */
    function is (...args) {
        return args.every(has);
    }

    /**
     * Has any tag.
     *
     * @param {...String} args
     * @returns {Boolean}
     */
    function any (...args) {
        return args.some(has);
    }

    /**
     * Has one off the tags.
     *
     * @param {String} tag
     * @returns {Boolean}
     */
    function has (tag) {
        return tags.indexOf(tag.toLowerCase()) !== -1;
    }

    /**
     * Parses a user agent to find the version number.
     *
     * @param {String} agent
     * @param {...String} names
     * @returns {String|undefined}
     */
    function parseVersion (agent, ...names) {
        let found;

        names.forEach(name => {
            if ( ! found) {
                let regex = new RegExp(name + '+?([\\d.]+)', 'ig'),
                    matches = regex.exec(agent);

                if (matches) {
                    found = matches[1]; //.split('.')[0]; not sure what is the purpose of only getting first part of version, not exact version. will remove this comment once review OK from Jacob. If notOK, will set one more var into browserService returned data set named fullVersion
                }
            }
        });

        return found;
    }

    angular.element(document.documentElement)
        .addClass('is-' + name)
        .addClass('is-' + os);

    return {
        is,
        any,
        has,
        tags,
        name,
        version,
        os
    };
});
