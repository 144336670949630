/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
let $injector;

class PageController {
    constructor () {
        $injector.invoke(this.$$init, this);
    }

    $$init ($route, $location, $timeout, domService, eventService, UserService) {
        this.app = UserService;
        this.$scope = $route.current.locals.$scope;
        this.$wrapper = angular.element('#main');

        this.$$url = $location.url();

        this.app.hideLoader();
        this.$scope.$on('$destroy', eventService.on(window, 'resize',
            this.$$update.bind(this)));

        let hash = $location.hash();
        if (hash) {
            this.$scope.$on('$viewContentLoaded', () => {
                $timeout(() => domService.scrollTo(angular.element('[scroll-id="' + hash + '"]'), null, -100));
            });
        }

        this.$$update();
    }

    $$update () {
        this.width = this.$wrapper.width();
        this.height = this.$wrapper.height();
    }
}

PageController.prototype.$$init.$inject = [
    '$route', '$location', '$timeout', 'domService', 'eventService', 'UserService'
];

app.controller('PageController', window.PageController = PageController);
app.run(['$injector', (injector) => $injector = injector]);
