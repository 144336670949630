/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
angular
    .module('ppxGlobalControllers')
    .factory('$downloadManager',
        function (UserService) {
            var service = {},
                application = UserService,
                proof = new Proof(application),
                progress = 0,
                progressTotal = 0,
                progressPercent = 1,
                chunksArr = [],
                blobReadyCallback = null,
                concurrentCalls = 4,
                progressCallback = null,
                sessionStorageName = "downloadData_",
                canDownload = true,
                downloadCancelled = null,
                fileType = "",
                sessionStorage = new PageProof.Storage("downloads", "sessionStorage");

            service.createDownloadLink = function(fileName, blob) {
                if ( !fileName ) fileName = "download";
                var fullFileName = fileName + '.' + fileType;

                if (PageProof.Hook.invokeHook(PageProof.Hook.DOWNLOAD_ORIGINAL_FILE, {
                    file: blob,
                    fileName: fullFileName,
                    extension: fileType
                })) {
                    if (navigator.msSaveBlob) {
                        navigator.msSaveBlob(blob, fullFileName);
                    } else {
                        var uri = URL.createObjectURL(blob);
                        var link = document.createElement("a");
                        link.download = fullFileName;
                        link.href = uri;
                        document.body.appendChild(link);
                        link.click();
                    }
                }
            };

            service.fetchFromStorage = function(){
                return sessionStorage.getJSON(sessionStorageName);
            };

            service.isInStorage = function(){
                var storageData = sessionStorage.getJSON(sessionStorageName);
                if(storageData){
                    return true;
                }else{
                    return false;
                }
            };

            service.cancelDownload = function(fileId){
                sessionStorage.remove(sessionStorageName + fileId);
                canDownload = false;
                service.reset();
                if (downloadCancelled) downloadCancelled();
            };

            service.downloadFile = function (file, fileName) {
                service.createDownloadLink(fileName, file);
            };

            service.reset = function(){
                progress = 0;
                progressTotal = 0;
                progressPercent = 1;
                chunksArr.length = 0;
                blobReadyCallback = null;
                concurrentCalls = 4;
                progressCallback = null;
                sessionStorageName = "downloadData_";
                canDownload = true;
                fileType = "";
            };

            return service;
        }
    )
    .factory('downloadManager', function ($downloadManager) {
        return $downloadManager;
    });
