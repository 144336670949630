/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/**
 * Created by chris on 6/03/15.
 */

ppxControllers.controller('pageProofAdmin', ['$scope', '$routeParams', '$location', 'UserService', 'DataService', '$api', 'userRepositoryService', function($scope, $routeParams, $location, UserService, DataService, $api, userRepositoryService) {
    $scope.application = UserService; //make the application class avaliable to this scope
    $scope.data = DataService; //make the dataservice avaliable to this scope
    $scope.proof = new Proof(UserService);
    $scope.users = new Users(UserService);

    $scope.proofId = "";
    $scope.hasSearched = false;

    $scope.proofMap = null;
    $scope.auditMap = null;

    $scope.showProof = true;
    $scope.showWorkflow = true;
    $scope.showComments = true;
    $scope.showCoOwners = true;
    $scope.showWorkflowAdmins = true;
    $scope.showTags = true;
    $scope.showAudit = true;
    $scope.showNotFound = false;

    $scope.ownerEmail = "";

    $scope.go = function(proofId){

        if( proofId ){
            $scope.proofId = proofId;
        }else{
            $scope.proofId = angular.element("#proofId").val();
        }

        if( !$scope.proofId ) return "";

        $scope.proof.Validate($scope.proofId, function(returnData){

            console.log("returnData", returnData);

            if( returnData ) {
                if (returnData.ResponseStatus == "ERROR_NO_ACCESS") {
                    $location.path("dashboard").search({});
                    return "";
                }
            }

            if( returnData ) {

                $scope.hasSearched = true;
                $scope.proofMap = returnData;

                $scope.getAuditLog();

                $scope.showNotFound = false;

                if ($scope.proofMap.proof.UserId) {

                    userRepositoryService.get($scope.proofMap.proof.UserId).then(function(result){
                        $scope.ownerEmail = result.email;
                        if (!$scope.$$phase) $scope.$apply();
                    });

                    //$scope.users.response($scope.proofMap.proof.UserId, function (userData) {
                    //    $scope.ownerEmail = userData.Email;
                    //    if (!$scope.$$phase) $scope.$apply();
                    //});

                }

            }else{
                $scope.showNotFound = true;
            }

            if(!$scope.$$phase) $scope.$apply();
        });
    };

    $scope.getAuditLog = function(){
        if( !$scope.proofId || $scope.proofId.charAt(0).toLowerCase() == "f") return "";
        $scope.proof.GetAuditLog($scope.proofId, function(ret){
            $scope.auditMap = ret;
            if(!$scope.$$phase) $scope.$apply();

        });
    };

    $scope.reset = function(){
        $scope.proofId = "";
        $scope.hasSearched = false;
        $scope.proofMap = null;
        $location.search({});
    };

    var userData = $scope.application.GetUserData();
    var userS = userData.userEmail.split("@");

    console.log("userS[1]:", userS[1]);

    if(userS[1].toLowerCase() != "pageproof.com"){
        $location.path("dashboard").search({});
    }else{
        $scope.go($routeParams.q);
    }

}]);