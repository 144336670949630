/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class CommentsPageController {
    /**
     * The proof page to render.
     *
     * @type {PPProofPage}
     */
    page = null;

    isPage = true;

    showCommentCount = true;

    proofName = null;
}

function CommentsPageDirective (directiveHelper) {
    return {
        restrict: 'E',
        require: ['commentsPage'],
        transclude: true,
        replace: true,
        templateUrl: 'templates/partials/proof/components/comments-page.html',
        controller: 'CommentsPageController',
        controllerAs: 'commentsPageCtrl',
        scope: true,

        link (scope, element, attr, [commentPageCtrl]) {
            scope.$on('$destroy', directiveHelper.callbackBinding(
                scope, attr, 'whenGoToPage', commentPageCtrl, 'whenGoToPage'));

            directiveHelper.oneWayBinding(scope, attr, 'isCurrentPage', commentPageCtrl, 'isCurrentPage');
            directiveHelper.oneWayBinding(scope, attr, 'isPage', commentPageCtrl, 'isPage');
            directiveHelper.oneWayBinding(scope, attr, 'showCommentCount', commentPageCtrl, 'showCommentCount');
            directiveHelper.oneWayBinding(scope, attr, 'proofName', commentPageCtrl, 'proofName');

            scope.$on('$destroy', directiveHelper.oneWayBinding(
                scope, attr, 'data', commentPageCtrl, 'page'
            ));
        }
    };
}

app
    .controller('CommentsPageController', CommentsPageController)
    .directive('commentsPage', CommentsPageDirective);
