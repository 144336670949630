/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class GroupInfoController {

    groupId = null;

    group = null;

    collectionManageMode = false;

    constructor (
        $rootScope,
        $q,
        $routeParams,
        userService,
        $timeout,
        $scope,
        sdk
    ) {
        this.$$ = {
            $rootScope,
            $q,
            $routeParams,
            userService,
            $timeout,
            $scope,
            sdk,
        }
        this.initEventListeners();
    }

    initEventListeners () {
        this.$$.$scope.$on('openCollectionPane', (event, data) => {
            if (typeof data === 'string' && window.isGroupId(data)) {
                this.groupId = data;
                this.loadGroupData()
                    .then((group) => {
                        this.beforeInit(group);
                    });
            } else {
                this.groupId = data.id;
                this.beforeInit(data);
            }
        });
    }

    beforeInit (group) {
        this.group = group;
        this.setOwnedProofCount();
        this.initProps();
        this.init();
    }

    loadGroupData() {
        return this.$$.sdk.proofs.groups.load(this.groupId);
    }

    initProps () {
        let that = this;
        this.props = {
            managePaneProps: {
                group: that.group,
            }
        }
    }

    getFullCollectionName () {
        if (this.group.folder) {
            return `${this.group.folder.name} / ${this.group.name}`;
        } 

        return this.group.name;
    }

    init () {
        this.collectionManageMode = true;
    }

    setOwnedProofCount() {
        const Enum = window.__pageproof_quark__.sdk.Enum;
        let ownedProofsCount = 0;
        let ownedInProofingProofsCount = 0;
        const isUserAdminOfGroupTeam = this.group.isUserAdminOfGroupTeam(this.$$.sdk.session.user);

        this.group.proofs.map(proof => {
            if (this.$$.userService.matches(...proof.ownerUserIds) || isUserAdminOfGroupTeam) {
                ownedProofsCount++;
              if (proof.status === Enum.ProofStatus.PROOFING ||
                  proof.status === Enum.ProofStatus.FINAL_APPROVING) {
                    ownedInProofingProofsCount++;
                }
            }
        });
        this.group.ownedProofsCount = ownedProofsCount;
        this.group.ownedInProofingProofsCount = ownedInProofingProofsCount;
    }

    closeCollectionPane () {
        this.$$.$rootScope.$broadcast('onCloseCollectionPane');
        this.collectionManageMode = false;
    }
}

app.controller('GroupInfoController', GroupInfoController);
