/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const CONTEXT_SUBJECT_SELECTOR = '.app__context__subject';
const CONTEXT_OVERLAY_SELECTOR = '.app__context__overlay';
const CONTEXT_OVERLAY_OFFSET = 20;

class ContextController {
    /**
     * The subject controller.
     *
     * @type {ContextSubjectController}
     */
    subject = null;

    /**
     * The overlay controller.
     *
     * @type {ContextOverlayController}
     */
    overlay = null;

    //

    /**
     * The function to unbind any current activate event listeners.
     *
     * @type {Function|null}
     */
    $$activateWhen = null;

    /**
     * The function to unbind any current deactivate event listeners.
     *
     * @type {Function|null}
     */
    $$deactivateWhen = null;

    /**
     * The timeout for deactivation.
     *
     * @type {$q}
     */
    $$deactivateTimeout = null;

    /**
     * Whether the overlay is scheduled for deactivation (after cooldown).
     *
     * @type {Boolean}
     */
    $$deactivateScheduled = false;

    //

    /**
     * The events to bind to in order to activate the overlay.
     *
     * Multiple events can be registered when delimited by a space.
     * E.g. "click contextmenu" would activate when the user clicks or right clicks the subject.
     *
     * @type {String}
     */
    activateWhen = 'mouseenter';

    /**
     * The events to bind to deactivate the overlay.
     *
     * @type {String}
     */
    deactivateWhen = 'mouseleave';

    /**
     * The time between the deactivate event and the deactivation of the overlay.
     *
     * Useful for hover events activation/deactivation.
     *
     * @type {Number}
     */
    deactivateCooldown = 5e2;

    /**
     * The direction the overlay needs to go.
     *
     * @type {String}
     */
    direction = 'right';

    //

    /**
     * Whether the overlay has been activated.
     *
     * @type {Boolean}
     */
    active = false;

    /**
     * The size of the subject (width/height based on offset).
     *
     * @type {Number}
     */
    size = 0;

    /**
     * @constructor
     */
    constructor ($element, $timeout, eventService) {
        this.$$ = { $element, $timeout, eventService };
    }

    /**
     * Registers the activate/deactivate events.
     *
     * @see activateWhen
     * @see deactivateWhen
     */
    registerEvents () {
        if (this.$$activateWhen) this.$$activateWhen(); // Unbind any existing activate event listeners
        if (this.$$deactivateWhen) this.$$deactivateWhen(); // Unbind any existing deactivate event listeners

        this.$$activateWhen = this.$$.eventService.on(
            this.$$.$element,
            this.activateWhen,
            this.handleActivateEvent.bind(this)
        );

        this.$$deactivateWhen = this.$$.eventService.on(
            this.$$.$element,
            this.deactivateWhen,
            this.handleDeactivateEvent.bind(this)
        );
    }

    updateDimensions () {
        let subject = this.$$.$element.find(CONTEXT_SUBJECT_SELECTOR);
        let overlay = this.$$.$element.find(CONTEXT_OVERLAY_SELECTOR);

        let size = this.size = subject.get(0).getBoundingClientRect().width;
        let offset = size + CONTEXT_OVERLAY_OFFSET;

        switch (this.direction) {
            case 'right':
                overlay.css({ 'padding-left': offset, 'padding-right': void 0 });
                break;
            case 'left':
                overlay.css({ 'padding-right': offset, 'padding-left': void 0 });
                break;
        }
    }

    cancelDeactivateEvent () {
        if (this.$$deactivateTimeout) {
            this.$$.$timeout.cancel(this.$$deactivateTimeout);
            this.$$deactivateTimeout = null;
        }

        this.$$deactivateScheduled = false;
    }

    handleActivateEvent (event) {
        event.preventDefault(); // Required for stuff like `contextmenu` events

        this.cancelDeactivateEvent();
        this.updateDimensions();
        this.active = true;
    }

    handleDeactivateEvent () {
        this.cancelDeactivateEvent();

        this.$$deactivateScheduled = true;
        this.$$deactivateTimeout = this.$$.$timeout(() => {
            if (this.$$deactivateScheduled === true) {
                this.active = false;
            }
        }, this.deactivateCooldown);
    }
}

class ContextSubjectController {
    transclude = null;
}

class ContextOverlayController {
    transclude = null;
}

function ContextDirective (directiveHelper) {
    return {
        require: ['context'],
        transclude: true,
        templateUrl: 'templates/partials/proof/components/context.html',
        controller: ContextController,
        controllerAs: 'contextCtrl',
        scope: true,

        link (scope, element, attr, [contextCtrl]) {
            let updateEvents = () => contextCtrl.registerEvents();

            directiveHelper.controllerBinding(scope, attr, 'bind', contextCtrl);
            directiveHelper.expressionBinding(scope, attr, 'activateWhen', contextCtrl, 'activateWhen', updateEvents);
            directiveHelper.expressionBinding(scope, attr, 'direction', contextCtrl, 'direction');
        }
    };
}

function ContextSubjectDirective () {
    return {
        priority: 1001,
        require: ['^context', 'contextSubject'],
        transclude: 'element',
        controller: ContextSubjectController,
        controllerAs: 'contextSubjectCtrl',
        scope: true,

        link (scope, element, attr, [contextCtrl, contextSubjectCtrl], transclude) {
            contextSubjectCtrl.transclude = transclude;
            contextCtrl.subject = contextSubjectCtrl;
            contextCtrl.registerEvents();
        }
    };
}

function ContextOverlayDirective () {
    return {
        require: ['^context', 'contextOverlay'],
        transclude: 'element',
        controller: ContextOverlayController,
        controllerAs: 'contextOverlayCtrl',
        scope: true,

        link (scope, element, attr, [contextCtrl, contextOverlayCtrl], transclude) {
            contextOverlayCtrl.transclude = transclude;
            contextCtrl.overlay = contextOverlayCtrl;
            contextCtrl.registerEvents();
        }
    };
}

app
    .directive('context', ContextDirective)
    .directive('contextSubject', ContextSubjectDirective)
    .directive('contextOverlay', ContextOverlayDirective);