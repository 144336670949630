/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
window.__pageproof_hooks__ = (function() {
  var hasOwnProperty = Object.prototype.hasOwnProperty;
  var registry = {};

  function addEventListener(event, handler) {
    if (!hasOwnProperty.call(registry, event)) {
      registry[event] = [];
    }
    registry[event].push(handler);
    return removeEventListener.bind(null, event, handler)
  }

  function removeEventListener(event, handler) {
    if (hasOwnProperty.call(registry, event)) {
      var handlers = registry[event];
      var index = handlers.indexOf(handler);
      if (index !== -1) {
        handlers.splice(index, 1);
      }
      if (!handlers.length) {
        delete registry[event];
      }
    }
  }

  function hookable(event, fallback) {
    if (typeof fallback !== 'function') {
      throw new Error('Must provide a fallback action to act as the default action.');
    }
    return function(data) {
      return hook(event, data, fallback);
    };
  }

  function hook(event, data, fallback) {
    if (typeof fallback !== 'function') {
      throw new Error('Must provide a fallback action to act as the default action.');
    }
    var dispatched = emit(event, data || {});
    // If a response was provided and the default action was not prevented
    if (dispatched && dispatched.response && !dispatched.defaultPrevented) {
      return Promise.resolve(dispatched.response).then(function () {
        return fallback(data);
      });
    }
    // If either no handlers were called, or if the default action was not prevented
    if (!dispatched || !dispatched.defaultPrevented) {
      return Promise.resolve(fallback(data));
    }
    // If a handler prevented the default action but did not provide an override
    if (!dispatched.response) {
      return Promise.reject(new Error('An event handler prevented the default action but did not provide a response. Rejecting the hook.'));
    }
    // If the default action was prevented and an override was provided
    return Promise.resolve(dispatched.response);
  }

  function emit(event, data) {
    if (!hasOwnProperty.call(registry, event)) {
      return null;
    }
    var defaultPrevented = false;
    var response = null;
    var obj = {
      type: event,
      data: data,

      preventDefault: function preventDefault() {
        defaultPrevented = true;
      },
      respondWith: function respondWith(promise) {
        if (response) {
          return Promise.reject(new Error('Only one handler can provide a response to the original event. This call will be ignored.'));
        }
        response = Promise.resolve(promise);
        return Promise.resolve();
      },
    };
    var handlers = registry[event];
    var length = handlers.length;
    for (var i = 0; i < length; i++) {
      var handler = handlers[i];
      try {
        var returnValue = handler(obj);
        if (typeof returnValue === 'boolean' && !returnValue) {
          obj.preventDefault();
        }
      } catch (err) {
        console.warn('An event handler threw an error.');
        console.error(err);
      }
    }
    return {
      event: obj,
      defaultPrevented: defaultPrevented,
      response: response,
    };
  }

  var hooks = {
    hookable: hookable,
    hook: hook,
    on: addEventListener,
    off: removeEventListener,
    addEventListener: addEventListener,
    removeEventListener: removeEventListener,
    emit: emit,
  };

  return hooks;
})();
