/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

// Version: 1.0 beta

// Env format - [Key]|[key]|....|[key]|[blocks]


function PageProofEncryption(encData) {
    this.keySeperator = '|';
    this.dataSeperator = '-';
    this.key = '';
    this.iv = '';
    this.version = "0001";

    this.symetricKeySize = 32; // AES-256
    this.symetricKey = null;


    this.userToken = '';
    this.userSharedSecretKey = '';

    this.uploadEndpoint = '';
    this.sendChunkSize = 5; // 2 MB 
    this.encryptBlockSize = 5; // 5 MB

    this.workerDirectory = 'Node/Javascript/';
    this.publicKeyServerPEM = '';
    this.privateKeyServerPEM = '';
    this.publicKeyUserPEM = '';
    this.privateKeyUserPEM = '';
    this.privateKeyUserSendable = '';

    this.password = '';
    this.passwordHash = '';

    this.progressCallback = null;

    this.uploadCompleteCallback = null;

    this.netWorkErrorCallback = null;

    this.storeOriginal = "";

    if (typeof encData !== 'undefined') {
        this.Load(encData);
    }
};

PageProofEncryption.prototype.randomGen = function(howMany){
    var chars = "ABCDEFGHIJKLMNOPQRSTUWXYZ0123456789";
    var value = "";
    for (var i = 0; i < howMany; i++) {
        value +=chars[(Math.random() * (chars.length-1)).toFixed(0)];
    };
    return value;
};

// Passwords and Key Generation
PageProofEncryption.prototype.SetPassword = function (password) {
    this.password = password;
    this.GeneratePasswordHash();
};

PageProofEncryption.prototype.RemovePassword = function () {
    this.password = "";
};

PageProofEncryption.prototype.GeneratePasswordHash = function () {
    if (this.password == '') {
        throw "No Password defined";
    }
    var md = forge.md.sha512.create();
    md.update(this.password);
    var hex = md.digest().toHex();
    md = forge.md.sha512.create();
    md.update(hex);
    hex = md.digest().toHex();
    this.passwordHash = hex;
    return (hex)
};
PageProofEncryption.prototype.GenerateKeys = function (callback) {
    var keys = forge.pki.rsa.generateKeyPair({bits: 2048, e: 0x10001});
    this.privateKeyUserPEM = forge.pki.privateKeyToPem(keys.privateKey);
    this.publicKeyUserPEM = forge.pki.publicKeyToPem(keys.publicKey);

    if (this.password == '') {
        throw "No Password defined";
    }

    this.privateKeyUserSendable = CryptoJS.AES.encrypt(this.privateKeyUserPEM, this.password).toString(); // Encrypted with cleartext password
    callback(this.privateKeyUserPEM, this.publicKeyUserPEM);
};
PageProofEncryption.prototype.LoadPrivateKey = function () {
    if (this.password == '') {
        throw "No Password defined";
    }
    this.privateKeyUserPEM = CryptoJS.AES.decrypt(this.privateKeyUserSendable, this.password).toString(CryptoJS.enc.Latin1);
};
/**
 * Regenerates the private key hash, using another password.
 *
 * Passes back the private key, public key, and password hash.
 *
 * @param {String} newPassword
 * @param {Function<privateKey, publicKey, passwordHash>} callback
 */
PageProofEncryption.prototype.RegeneratePrivateKey = function (password, callback) {
    this.privateKeyUserSendable = CryptoJS.AES.encrypt(this.privateKeyUserPEM, password).toString();
    this.SetPassword(password);

    callback(this.privateKeyUserSendable, this.publicKeyUserPEM, this.passwordHash);
};



// API Authentication Stuff
PageProofEncryption.prototype.SetWorkerDirectory = function (dir) {
    this.workerDirectory = dir;
};
PageProofEncryption.prototype.SetUserTokenSharedKey = function (token, sKey) {
    this.userToken = token;
    this.userSharedSecretKey = sKey;
};
PageProofEncryption.prototype.SetUploadEndpoint = function (ep) {
    this.uploadEndpoint = ep;
};
PageProofEncryption.prototype.SetServerPublicKeyPEM = function (key) {
    this.publicKeyServerPEM = key;
};
PageProofEncryption.prototype.SetServerPrivateKeyPEM = function (key) {
    this.privateKeyServerPEM = key;
};
PageProofEncryption.prototype.SetUserPublicKeyPEM = function (key) {
    this.publicKeyUserPEM = key;
};
PageProofEncryption.prototype.SetUserPrivateKeyPEM = function (key) {
    this.privateKeyUserPEM = key;
};
PageProofEncryption.prototype.SetUserPrivateKeyAES = function (key) {
    this.privateKeyUserSendable = key;
    this.LoadPrivateKey();
};



PageProofEncryption.prototype.GetAuthenticationTimestamp = function () {
    var now = new Date;
    var utc_timestamp = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
    return (utc_timestamp);
};


PageProofEncryption.prototype.GetAuthenticationHeader = function (method, url, timestamp) {
    /*
    console.log('Token: Key - ' + this.userToken + ": " + this.userSharedSecretKey);
    console.log(method);
    console.log(url);
    console.log(timestamp);

//    console.log(params);
*/
//    params.sort(function (a, b) {
//        var keyA = new Date(a.updated_at),
//                keyB = new Date(b.updated_at);
//        // Compare the 2 dates
//        if (keyA < keyB)
//            return -1;
//        if (keyA > keyB)
//            return 1;
//        return 0;
//    });


    var hmac = forge.hmac.create();
    hmac.start('sha256', this.userSharedSecretKey);
    hmac.update(this.userToken);
    hmac.update(method);
    hmac.update(timestamp.toString());
    hmac.update(getURLPath(url));
    //hmac.update(encode_utf8(JSON.stringify(data)));
    var hmacStr = forge.util.encode64(hmac.digest().data);
//    var hmacStr = CryptoJS.HmacSHA1(this.userToken, this.userSharedSecretKey);
    //var hmacStr = hmac.digest().toHex();
    //console.log(hmacStr);
    return (hmacStr);
};


// File Uploading
PageProofEncryption.prototype.SetProgressCallback = function (progress) {
    this.progressCallback = progress;
};

PageProofEncryption.prototype.SetCompleteCallback = function (complete) {
    this.uploadCompleteCallback = complete;
};

PageProofEncryption.prototype.SetNetWorkErrorCallback = function (cb) {
    this.netWorkErrorCallback = cb;
};

// AD
PageProofEncryption.prototype.GetQueryParameter = function (name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
    return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

PageProofEncryption.prototype.Load = function (data) {
    if (data.password) this.SetPassword(data.password);
    if (data.publicKeyUserPEM) this.SetUserPublicKeyPEM(data.publicKeyUserPEM);
    if (data.publicKeyServerPEM) this.SetServerPublicKeyPEM(data.publicKeyServerPEM);
    if (data.privateKeyUserPEM) this.SetUserPrivateKeyPEM(data.privateKeyUserPEM);
    if (data.userSharedSecretKey) this.SetUserTokenSharedKey(data.userToken, data.userSharedSecretKey);
};
