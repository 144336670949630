/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class FileSelectController {
    allowClick = true;
    files = [];

    constructor ($element, $scope, $attrs) {
        this.$scope = $scope;
        this.$element = $element;

        $scope.$watch(() => this.files, _files => {
            if (_files && _files.length) {
                var files = [].slice.apply(_files);

                console.log(files);
                console.log($scope);
                console.log($scope.$parent);
                console.log($attrs.whenSelect);

                $scope.$eval($attrs.whenSelect, {
                    files,
                    file: files[0]
                });
            }
        });

        $scope.$watch(() => $attrs.allowClick, allowClick => {
            this.allowClick = allowClick;
        });
    }

    handleEvent (event) {
        if (event.type === 'change') {
            this.files = event.target.files;
        }

        else if (event.type === 'drop') {
            this.files = event.originalEvent.dataTransfer.files;
        }

        else if (event.type === 'mouseover') {
            this.$element.find('.app__btn').addClass('app__btn--hover');
        }

        else if (event.type === 'mouseout') {
            this.$element.find('.app__btn').removeClass('app__btn--hover');
        }

        else if (event.type === 'dragenter') {
            this.$element.addClass('highlight');
        }

        else if (event.type === 'dragleave') {
            this.$element.removeClass('highlight');
        }
    }
}

function FileSelectElementDirective (eventService) {
    return {
        require: '^fileSelect',
        link (scope, element, attrs, fileSelect) {
            element.on('click', event => event.stopPropagation());
            element.on('change', eventService.$$wrap(event => fileSelect.handleEvent(event)));
        }
    };
}

function FileSelectDropDirective (eventService) {
    return {
        require: '^fileSelect',
        link (scope, element, attrs, fileSelect) {
            element.on('drop mouseover mouseout dragenter dragleave', eventService.$$wrap(event => fileSelect.handleEvent(event)));
            element.on('dragover dragenter dragleave drop', event => event.preventDefault());
        }
    };
}

function FileSelectDirective () {
    return {
        controller: 'FileSelectController',
        controllerAs: 'fileSelect',
        transclude: true,
        template: `
            <div class="file-select" file-select-drop>
                <div class="file-select__content" ng-transclude></div>
                <div class="file-select__wrapper" ng-if="fileSelect.allowClick">
                    <input type="file" class="file-select__element" multiple file-select-element>
                </div>
            </div>
        `
    };
}

app
    .controller('FileSelectController', FileSelectController)
    .directive('fileSelect', FileSelectDirective)
    .directive('fileSelectElement', FileSelectElementDirective)
    .directive('fileSelectDrop', FileSelectDropDirective);
