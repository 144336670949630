/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
(function () {

    'use strict';

    var PageProof = window.PageProof = {/* PageProof namespace */};

    PageProof.version = '<%= pkg.version %>';
    PageProof.hash = '<%= hash %>';

    PageProof.wrap = function (name, obj) {
        Object.keys(obj).forEach(function (methodName) {
            var originalFunction = obj[methodName];

            if (typeof originalFunction === 'function') {
                obj[methodName] = function () {
                    var $return = originalFunction.apply(this, arguments);
                    console.log('invoke', name + '#' + methodName, 'with', Array.prototype.slice.call(arguments), 'returned',
                        (typeof $return === 'string' && $return.length > 100) ? ($return.substring(0, 97) + '...') : $return);
                    return $return;
                };
            }
        });
    };

    var supportsStorage = function (scope, store) {
        try {
            // Exists in window, is truthy, and getItem is a function
            return store in scope && scope[store] && typeof scope[store].getItem === 'function';
        } catch (err) {
            return false;
        }
    };

    var getStorage = function (type) {
        if (supportsStorage(window, type)) {
            return window[type];
        }
    };

    /**
     * Aliases a localStorage function to be used in a safe way.
     *
     * @param {String} fn
     * @returns {Function}
     */
    function aliasLocalStorageFn (fn) {
        return function () {
            try {
                return this.storage[fn].apply(this.storage, arguments);
            } catch (err) {
                return void 0;
            }
        };
    }

    PageProof.Storage = function (prefix, storage) {
        this.storage = getStorage(storage || 'localStorage');
        this.prefix = prefix || 'pageproof.app.';
        this._old = {};
        this._init();
    };

    PageProof.Storage.prototype._init = function () {
        this.keys().forEach(function (key) {
            this._old[key] = this.get(key);
        }, this);
    };

    PageProof.Storage.prototype._get = aliasLocalStorageFn('getItem');
    PageProof.Storage.prototype._set = aliasLocalStorageFn('setItem');
    PageProof.Storage.prototype._remove = aliasLocalStorageFn('removeItem');

    PageProof.Storage.prototype.get = function (key, def) {
        var value = this._get(this.prefix + key);

        if (value == null) value = def;

        return value;
    };

    PageProof.Storage.prototype.getJSON = function (key, def) {
        var raw = this.get(key, def),
            json;

        try {
            json = JSON.parse(raw);
        } catch (err) {
            if (Array.isArray(def)) {
                json = [];
            } else if (Object(def) === def) {
                json = {};
            }
        }

        return json;
    };

    PageProof.Storage.prototype.set = function (key, value) {
        var before = this.get(key);

        this._set(this.prefix + key, value);

        setTimeout(function () {
            this._checkChanges();
        }.bind(this));

        return before;
    };

    PageProof.Storage.prototype.setJSON = function (key, value) {
        var json;

        try {
            json = JSON.stringify(value);
        } catch (err) {
            // Default to whatever type the original value was
            json = Array.isArray(value) ? '[]' : '{}';

            console.error(err);
            Bugsnag.notifyException(err);
        }

        return this.set(key, json);
    };

    PageProof.Storage.prototype.has = function (key) {
        return this.keys().indexOf(key) !== -1;
    };

    PageProof.Storage.prototype.remove = function (key) {
        var before = this.get(key);

        this._remove(this.prefix + key);

        return before;
    };

    PageProof.Storage.prototype.keys = function () {
        var that = this;

        // Fixes cases where localStorage is disabled for security
        if ( ! this.storage) {
            return [];
        }

        return Object
            .keys(this.storage)
            .filter(function (key) {
                // Only keys that begin with the specified prefix
                return key.substring(0, that.prefix.length) === that.prefix;
            })
            .map(function (key) {
                // Remove the prefix from the key (for use within api)
                return key.substring(that.prefix.length);
            });
    };

    PageProof.Storage.prototype.clear = function () {
        var that = this,
            removed = {};

        this.keys().forEach(function (key) {
            removed[key] = that.remove(key);
        });

        return removed;
    };

    PageProof.Storage.prototype.toJSON = function () {
        var keys = this.keys(),
            obj = {};

        keys.forEach(function (key) {
            obj[key] = this.get(key);
        }, this);

        return JSON.stringify(obj);
    };

    PageProof.Storage.prototype.subset = function (prefix) {
        return new PageProof.Storage(this.prefix + prefix);
    };

    //PageProof.Storage.prototype._dirtyCheck = function (callback) {
    //    this.keys().forEach(function (key) {
    //        var old = this._old[key],
    //            current = this.get(key);
    //
    //        if (old !== current) {
    //
    //        }
    //    }, this);
    //};

    PageProof.Storage.prototype._checkChanges = function () {
        this.keys().forEach(function (key) {
            var old = this._old[key],
                current = this.get(key);

            if (old !== current) {
                this._triggerChange(key, old, current);
                this._old[key] = current;
            }
        }, this);
    };

    PageProof.Storage.prototype._triggerChange = function (key, old, current) {
        if (typeof window.dispatchEvent === 'function') {
            try {
                window.dispatchEvent(new StorageEvent('storage', {
                    key: this.prefix + key,
                    oldValue: old,
                    newValue: current
                }));
            } catch(err) {
                // #2779 (red day) error throws on IE 11. The only reason this can error is if the browser doesn't support
                // dispatching these kind of built-in events. No need to log to bugsnag anymore.
            }
        }
    };

    PageProof.Storage.prototype._matchesKey = function (regex, key) {
        return (new RegExp('^' + this.prefix + regex.replace('*', '(.*)') + '$', 'g')).test(key);
    };

    PageProof.Storage.prototype._storageEvent = function (key, callback, event) {
        var keyMatches = this._matchesKey(key, event.key),
            matchedKey = event.key.substring(this.prefix.length);

        if (keyMatches) {
            callback(matchedKey, event.newValue, event.oldValue);
        }
    };

    PageProof.Storage.prototype.watch = function (key, callback) {
        var storageEvent = this._storageEvent.bind(this, key, callback);
        window.addEventListener('storage', storageEvent, false);

        return function () {
            window.removeEventListener('storage', storageEvent, false);
        };
    };

    Object.keys(PageProof.Storage.prototype).forEach(function (key) {
        PageProof.Storage.prototype[key] = deprecated(PageProof.Storage.prototype[key], 'Use `storageService` instead.');
    });

    PageProof.Image = {
        render: function (url, _size, callback, square) {
            if (typeof square === 'undefined') {
                square = true;
            }

            var image = new Image(),
                size = {
                    width: _size.width || _size,
                    height: _size.height || _size
                };

            image.setAttribute('crossOrigin', 'anonymous');

            image.addEventListener('load', function loadEvent() {
                var canvas = document.createElement('canvas'),
                    ctx = canvas.getContext('2d'),
                    imageWidth = image.naturalWidth,
                    imageHeight = image.naturalHeight,
                    ratio = 1,
                    width = 0,
                    height = 0,
                    type = function (_type, _ratio) {
                        ratio = _ratio;

                        if (_type === 'down') {
                            width = imageWidth / ratio;
                            height = imageHeight / ratio;
                        }

                        else if (_type === 'up') {
                            width = imageWidth * ratio;
                            height = imageHeight * ratio;
                        }
                    };

                canvas.width = size.width;
                canvas.height = size.height;

                // BIG WIDTH
                if (imageWidth >= imageHeight && imageWidth >= size.width) {
                    type('down', imageWidth / size.width);
                    console.log('big width', ratio);
                }

                // BIG HEIGHT
                else if (imageHeight >= imageWidth && imageHeight >= size.height) {
                    type('down', imageHeight / size.height);
                    console.log('big height', ratio);
                }

                // SMALL WIDTH
                else if (imageWidth >= imageHeight && size.width >= imageWidth) {
                    type('up', size.width / imageWidth);
                    console.log('small width', ratio);
                }

                // SMALL HEIGHT
                else if (imageHeight >= imageWidth && size.height >= imageHeight) {
                    type('up', size.height / imageHeight);
                    console.log('small height', ratio);
                }

                if (square) {
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(image, (size.width - width) / 2, (size.height - height) / 2, width, height);
                } else {
                    canvas.width = width;
                    canvas.height = height;

                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(image, 0, 0, width, height);
                }

                if (typeof callback === 'function') {
                    callback(canvas.toDataURL());
                }

                // gc
                image.removeEventListener('load', loadEvent, false);
                image = canvas = ctx = null;
            }, false);

            image.src = url;
        }
    };

})();
