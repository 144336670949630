/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class DBService {
    /**
     * Whether the db service has complete initialising.
     *
     * @type {$q}
     */
    $ready = null;

    /**
     * The Dexie instance object.
     *
     * @type {Dexie}
     */
    db = null;

    /**
     * @constructor
     */
    constructor ($q, utilService) {
        this.$$ = {$q, utilService};
        this.init();
    }

    /**
     * Initialise
     */
    init () {
        this.$ready = this.$loadDexie()
            .then(() => this.$createDatabase())
            .then(() => this.$migrateDatabase())
            .then(() => this.$openDatabase());
    }

    /**
     * Wait for the service to initialise.
     *
     * @returns {$q}
     */
    ready () {
        return this.$ready.then(() => this.db);
    }

    /**
     * Wraps a Dexie promise with an angular $q object.
     *
     * Can be invoked without the `ready` method, waits before invoking the fn.
     *
     * @param {Function} fn
     * @returns {$q}
     */
    wrap (fn) {
        return this.ready()
            .then((db) => this.$$.$q.when(fn(db)));

        // Example:
        // dbService.wrap((db) => (
        //     db.friends.where('foo').equals('bar').then(() => {
        //         // Do something
        //     })
        // ))
    }

    /**
     * Load dexie.js (the library).
     *
     * @returns {$q}
     */
    $loadDexie () {
        return this.$$.utilService.loadScript('dist/lib/Dexie.js');
    }

    /**
     * Creates the Dexie instance (database object).
     */
    $createDatabase () {
        this.db = new Dexie('pageproof');
        //this.db.delete();
        //throw '';
    }

    /**
     * Migrate the database.
     *
     * @returns {$q}
     */
    $migrateDatabase () {
        let versions = DBService.versions;
        Object.keys(versions).forEach((version) => {
            let migration = versions[version];
            if (angular.isFunction(migration)) {
                migration(this.db.version(Number(version)));
            }
        });
    }

    /**
     * Opens the connection to the database.
     */
    $openDatabase () {
        return this.db.open().then(() => {
            console.log('Opened database connection.');
        });
    }

    /**
     * @static
     * @type {Object}
     */
    static versions = {
        '1': version1,
        '2': version2,
        '3': version3,
        '4': version4,
        '5': version5,
        '6': version6,
        '7': version7,
        '8': version8
    };
}

function version1 (version) {
    version.stores({
        thumbnails: '&fileId,createdAt,dataType,image,size' // added
    });
}

function version2 (version) {
    version.stores({
        thumbnails: '&fileId,createdAt,dataType,image,size',
        avatars: '&userId,createdAt,imageType,image,hasAvatar,isRegistered,initials' // added
    });
}

function version3 (version) {
    version.stores({
        thumbnails: '&fileId,createdAt,dataType,image,size',
        avatars: '&userId,createdAt,imageType,image,hasAvatar,isRegistered,initials',
        comments: '&hash,commentId,createdAt,comment', // added
        previews: '++,fileId,pageNumber,quality,createdAt,image,imageType,size' // added
    });
}

function version4 (version) {
    version.stores({
        thumbnails: '&fileId,createdAt,dataType,image,size',
        avatars: '&userId,createdAt,imageType,image,hasAvatar,isRegistered,initials',
        comments: '&hash,commentId,createdAt,comment',
        previews: '++,fileId,pageNumber,quality,createdAt,image,imageType,size',
        users: '&userId,email,name' // added
    });
}

function version5 (version) {
    version.stores({
        thumbnails: '&fileId,createdAt,dataType,image,size',
        avatars: '&userId,createdAt,imageType,image,hasAvatar,isRegistered,initials',
        comments: '&hash,commentId,createdAt,comment',
        previews: '++,fileId,pageNumber,quality,createdAt,image,imageType,size',
        users: '&userId,email,name',
        addressbook: 'ownerId,emails' // added
    });
}

function version6 (version) {
    version.stores({
        thumbnails: '&fileId,createdAt,dataType,image,size',
        avatars: '&userId,createdAt,imageType,image,hasAvatar,isRegistered,initials',
        comments: '&hash,commentId,createdAt,comment',
        previews: '++,fileId,pageNumber,quality,createdAt,image,imageType,size',
        users: '&userId,email,name',
        addressbook: 'ownerId,emails',
        temporary: '&key,value' // added
    });
}

function version7 (version) {
    version.stores({
        thumbnails: '&fileId,createdAt,dataType,image,size',
        avatars: '&userId,createdAt,imageType,image,hasAvatar,isRegistered,initials',
        comments: '&hash,commentId,createdAt,comment',
        previews: '++,fileId,pageNumber,quality,createdAt,image,imageType,size',
        users: '&userId,email,name',
        addressbook: 'ownerId,emails',
        temporary: '&key,value,expiresAt' // modified
    });
}

function version8 (version) {
  version.stores({
      thumbnails: '&fileId,createdAt,dataType,image,size',
      avatars: '&userId,createdAt,imageType,image,hasAvatar,isRegistered,initials',
      comments: '&hash,commentId,createdAt,comment',
      previews: '++,fileId,pageNumber,quality,createdAt,image,imageType,size',
      users: '&userId,email,name,createdAt', //modified
      addressbook: 'ownerId,emails,createdAt', //modified
      temporary: '&key,value,expiresAt'
  });
}

app.service('dbService', DBService);
