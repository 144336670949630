/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class MarketingCrossOriginController {
    intent = [];

    constructor(userService, avatarRepositoryService, temporaryStorageService) {
        this.postMessage('ready');

        this.addEventListener('addEventListener', ({value}) => {
            this.registerEventListenerIntent(value);
        });

        this.addEventListener('removeEventListener', ({value}) => {
            this.unregisterEventListenerIntent(value);
        });

        this.addEventListener('getAuthenticatedUser', ({value}) => {
            if (userService.isLoggedIn()) {
                let reply = () => {
                    userService.populateUser()
                        .then((user) => {
                            this.reply('getAuthenticatedUser', {
                                id: user.id,
                                name: user.name,
                                email: user.email,
                                accountType: user.type,
                                teamType: user.teamType,
                            });
                        });
                };

                if (value.fetch === true) {
                    userService.populateUser().then(reply);
                } else {
                    reply();
                }
            } else {
                this.reply('getAuthenticatedUser', null);
            }
        });

        this.addEventListener('getAuthenticatedUsersAvatar', () => {
            if (userService.isLoggedIn()) {
                //avatarRepositoryService
                let {id} = userService.getUser();
                avatarRepositoryService.get({userId: id}).then((avatar) => {
                    this.reply('getAuthenticatedUsersAvatar', {
                        image: avatar._image, // Blob
                        initials: avatar.initials
                    });
                })
            } else {
                this.reply('getAuthenticatedUsersAvatar', null);
            }
        });

        this.addEventListener('isAuthenticated', () => {
            this.reply('isAuthenticated', userService.isLoggedIn());
        });

        this.addEventListener('migrateLocalStorage', ({value}) => {
            try {
                if (Object.prototype.toString.call(value.storage) === '[object Object]') {
                    Object.keys(value.storage).forEach((key) => {
                        if (key.indexOf('pageproof.') === 0) { // (.startsWith)
                            window.localStorage.setItem(key, value.storage[key]);
                        }
                    });

                    this.reply('migrateLocalStorage', true);
                    return;
                }
            } catch(err) {
                Bugsnag.notifyException(err);
            }

            this.reply('migrateLocalStorage', false);
        });

        this.addEventListener('storeTemporaryUpload', ({value}) => {
            if (value && value.file) {
                temporaryStorageService.set('upload', {file: value.file})
                    .then(() => this.reply('storeTemporaryUpload', true))
                    .catch((err) => {
                        Bugsnag.notifyException(err);
                        this.reply('storeTemporaryUpload', false);
                    });
            } else {
                this.reply('storeTemporaryUpload', false);
            }
        });
    }

    reply(eventName, data) {
        this.postMessage('reply:' + eventName, data);
    }

    postMessage(method, value) {
        if (method === 'ready' || this.intent.indexOf(method) !== -1) {
            window.parent.postMessage({method, value}, '*');
        }
    }

    registerEventListenerIntent(eventName) {
        if (this.intent.indexOf(eventName) === -1) {
            this.intent.push(eventName);
        }
    }

    unregisterEventListenerIntent(eventName) {
        if (this.intent.indexOf(eventName) !== -1) {
            this.intent.splice(this.intent.indexOf(eventName), 1);
        }
    }

    addEventListener(eventName, callback) {
        window.addEventListener('message', (event) => {
            let {method, value} = event.data;
            if (method === eventName) callback({method, value}, event);
        });
    }
}

app.controller('MarketingCrossOriginController', MarketingCrossOriginController);
