/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class UrlImportController {
    constructor($routeParams) {
        this.props = {
            proofId: $routeParams.proofId,
            hasFileError: $routeParams.hasFileError,
            proofType: $routeParams.proofType,
            isEditor: $routeParams.isEditor,
        }
    }
}

app.controller('UrlImportController', UrlImportController);
