/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
angular
    .module('createProof')
    .factory('eventService', function ($rootScope, callbackService) {
        var eventService,
            spaceRegex = /\s+/g;

        return eventService = {
            /**
             * Wraps a callback in an angular $digest. This callback should only be used on events
             * that are not already running within an angular $digest cycle.
             *
             * @param {Function} callback
             */
            $$wrap: function (callback) {
                return function () {
                    var that = this,
                        args = arguments;

                    if ($rootScope.$$phase) {
                        return callback.apply(that, args);
                    } else {
                        return $rootScope.$apply(function () {
                            return callback.apply(that, args);
                        });
                    }
                };
            },
            /**
             * Registers an event listener.
             *
             * Supports
             *  - angular.element
             *  - String
             *  - EventTarget
             *  - Array
             *
             * @param {*} node
             * @param {String} events
             * @param {Function} callback
             * @returns {Function}
             */
            on: function (node, events, callback) {
                var listeners = [];

                if (spaceRegex.test(events)) {
                    angular.forEach(events.split(spaceRegex), function (event) {
                        listeners.push(eventService.on(node, event, callback));
                    });
                }

                else {
                    var handler = this.$$wrap(callback),
                        listener;

                    if (angular.isString(node)) {
                        node = angular.element(node);
                    }

                    if (angular.isArray(node)) {
                        angular.forEach(node, function (node) {
                            listeners.push(eventService.on(node, events, callback));
                        });
                    }

                    else if (node instanceof angular.element) {
                        node.on(events, handler);

                        listener = function () {
                            node.off(events, handler);
                        };
                    }

                    else if ('addEventListener' in node) {
                        node.addEventListener(events, handler, false);

                        listener = function () {
                            node.removeEventListener(events, handler, false);
                        };
                    }

                    else {
                        throw new Error('eventService.on \'node\' is not able to listen to events.');
                    }

                    if (listener) {
                        listeners.push(listener);
                    }
                }

                /**
                 * De-binds each listener one by one.
                 *
                 * @see {listeners}
                 */
                return function () {
                    callbackService.clean(listeners);
                };
            },
            /**
             * Registers an event listener on a node, which removes itself after the first event.
             *
             * @see {eventService.on}
             *
             * @param {*} node
             * @param {String} events
             * @param {Function} callback
             * @returns {Function}
             */
            once: function (node, events, callback) {
                var off = this.on(node, events, function () {
                    off();
                    callbackService.safe(callback, this, arguments);
                });

                return off;
            }
        };
    });
