/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class SelectController {
    /**
     * Whether the options menu is open.
     *
     * @type {Boolean}
     */
    open = false;

    /**
     * The available options.
     *
     * @type {Object[]}
     */
    options = [];

    /**
     * The selected option.
     *
     * @type {*}
     */
    selected = null;

    /**
     * The placeholder text.
     *
     * @type {String}
     */
    placeholder = null;

    /**
     * Which way the options menu displays.
     *
     * Options:
     *  - down (default)
     *  - up
     *
     * @type {String}
     */
    direction = 'down';

    /**
     * Whether the select menu is full width.
     *
     * @type {Boolean}
     */
    block = false;

    /**
     * When the value changes.
     *
     * @type {Function|null}
     */
    whenChange = null;

    /**
     * Value related to version.
     *
     * @type {Number|String}
     */
    versionValue = null;

    /**
     *
     * @returns {Object}
     */
    getOption (value) {
        return this.options.filter((option) => {
            return angular.equals(this.getValue(option), value);
        })[0];
    }

    getName(option) {
        this.versionValue = option && option.name !== 'undefined' ? option.name : null;
        const name = option && (typeof option.text !== 'undefined' ? option.text : "");
        return name;
    }

    getValue(option) {
        return option && (typeof option.value !== 'undefined' ? option.value : option);
    }

    /**
     *
     * @param {*} option
     * @returns {Boolean}
     */
    isSelected (option) {
        return angular.equals(this.selected, this.getValue(option));
    }

    /**
     *
     * @param {*} option
     * @returns {Boolean}
     */
    selectOption (option) {
        const value = this.getValue(option);
        this.open = false;

        if ( ! this.isSelected(option)) {
            this.selected = value;
            if (this.whenChange) this.whenChange({ value });
            if (this.whenUpdate) this.whenUpdate({ option });
            return true;
        }

        return false;
    }

    /**
     *
     */
    toggleOpen () {
        this.open = ! this.open;
    }
}

function SelectDirective (directiveHelper, eventService) {
    return {
        restrict: 'A',
        require: ['select'],
        controller: 'SelectController',
        controllerAs: 'selectCtrl',
        templateUrl: 'templates/partials/proof/components/select.html',
        scope: true,

        link (scope, element, attr, [selectCtrl]) {
            directiveHelper.twoWayBinding(scope, attr, 'selected', selectCtrl, 'selected', false);
            directiveHelper.oneWayBinding(scope, attr, 'options', selectCtrl, 'options', true);

            if ('placeholder' in attr) {
                directiveHelper.expressionBinding(scope, attr, 'placeholder', selectCtrl, 'placeholder');
            }

            if ('block' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'block', selectCtrl, 'block');
            }

            if ('direction' in attr) {
                directiveHelper.expressionBinding(scope, attr, 'direction', selectCtrl, 'direction');
            }

            directiveHelper.callbackBinding(scope, attr, 'whenChange', selectCtrl, 'whenChange');
            directiveHelper.callbackBinding(scope, attr, 'whenUpdate', selectCtrl, 'whenUpdate');

            eventService.on(document.body, 'click', (event) => {
                if ( ! element[0].contains(event.target)) {
                    selectCtrl.open = false;
                }
            });
        }
    };
}

app
    .controller('SelectController', SelectController)
    .directive('select', SelectDirective);

window.SelectController = SelectController;