/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class AdminAPIController extends BaseController {
    constructor(backendService) {
        super();
        this.$$import({backendService});

        this.endpoints = Object.keys(backendService.schema).map((name) => {
            return {name, endpoint: backendService.schema[name]};
        });
        this.selectedEndpoint = this.endpoints[0];
        this.history = [];
        this.selectedHistory = -1;
        this.resetMetaData();
    }

    exportMetaData() {
        return {
            selectedEndpoint: this.selectedEndpoint,
            endpointParams: this.endpointParams,
            response: this.response,
            elapsed: this.elapsed,
            requestHeaders: this.requestHeaders,
            responseHeaders: this.responseHeaders,
            responseStatus: this.responseStatus,
            responseStatusText: this.responseStatusText,
            requestURL: this.requestURL,
            requestType: this.requestType,
            body: this.body
        };
    }

    importMetaData(meta) {
        this.selectedEndpoint = meta.selectedEndpoint;
        this.endpointParams = Object.assign({}, meta.endpointParams);
        this.response = meta.response;
        this.elapsed = meta.elapsed;
        this.requestHeaders = meta.requestHeaders;
        this.responseHeaders = meta.responseHeaders;
        this.responseStatus = meta.responseStatus;
        this.responseStatusText = meta.responseStatusText;
        this.requestURL = meta.requestURL;
        this.requestType = meta.requestType;
        this.body = meta.body;
    }

    resetMetaData() {
        this.endpointParams = {};
        this.response = null;
        this.elapsed = -1;
        this.requestHeaders = {};
        this.responseHeaders = {};
        this.responseStatus = -1;
        this.responseStatusText = null;
        this.requestURL = null;
        this.requestType = null;
        this.body = null;
    }

    changeHistory(index) {
        this.selectedHistory = index;
        this.importMetaData(this.history[index].data);
    }

    endpointChanged() {
        this.resetMetaData();
    }

    sendRequest() {
        let startTime = Date.now();
        this.response = null;

        let complete = ({data, status, statusText, headers, config}) => {
            this.requestHeaders = config.headers;
            this.responseHeaders = Object.assign({}, headers());
            this.responseStatus = status;
            this.responseStatusText = statusText;
            this.response = data;
        };

        let after = (meta) => {
            this.requestURL = meta.url;
            this.requestType = meta.type;
            this.body = meta.body;

            meta.request
                .then(complete)
                .catch(complete);
        };

        this.$$
            .backendService
            .fetch(this.selectedEndpoint.name, this.endpointParams)
            .raw().then(after).catch(after)
            .finally(() => this.elapsed = Date.now() - startTime)
            .finally(() => {
                this.selectedHistory = this.history.push({
                    name: this.selectedEndpoint.name + '(' + Object.keys(this.endpointParams).map((key) => {
                        return key + ': ' + this.endpointParams[key];
                    }).join(', ') + ')',
                    data: this.exportMetaData()
                }) - 1;
            });
    }
}

app.controller('AdminAPIController', AdminAPIController);