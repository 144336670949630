/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
function watchMany (scope, watchExpressions, listener) {
    let hasBeenDestroyed = false,
        hasListenerScheduled = false,
        isFirstRun = true,
        oldValues = Array.apply(null, new Array(watchExpressions.length)),
        newValues = Array.apply(null, new Array(watchExpressions.length)),
        watchers = watchExpressions.map((watchExpression, index) => {
            return scope.$watch(watchExpression, handleWatcherCallback.bind(scope, index), true);
        });

    function handleWatcherCallback (index, newValue, oldValue) {
        newValues[index] = newValue;
        oldValues[index] = oldValue;

        if ( ! hasListenerScheduled) {
            hasListenerScheduled = true;
            scope.$evalAsync(doWatchManyListener.bind(scope));
        }
    }

    function doWatchManyListener () {
        if ( ! hasBeenDestroyed) {
            hasListenerScheduled = false;
            listener(newValues, oldValues, isFirstRun, scope);
            isFirstRun = false;
        }
    }

    return function destroyWatchMany () {
        hasBeenDestroyed = true;
        while (watchers.length) {
            watchers.shift()();
        }
    };
}

/**
 * Creates a self-destroying watcher to listen for when something becomes truthy.
 *
 * @param {$rootScope.Scope} scope
 * @param {String|Function} watchExpression
 * @param {Function} listener
 * @returns {Function}
 */
function watchUntil (scope, watchExpression, listener) {
    let hasBeenDestroyed = false,
        watcher;

    watcher = scope.$watch(watchExpression, function handleWatchUntil (value) {
        if (value && ! hasBeenDestroyed) {
            hasBeenDestroyed = true;
            watcher();

            listener.apply(void 0, arguments);
        }
    });

    return function destroyWatchUntil () {
        hasBeenDestroyed = true;
        watcher();
    };
}

app.factory('scopeService', () => {
    return {
        watchMany,
        watchUntil
    };
});
