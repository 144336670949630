/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.filter('userList', ($q, userRepositoryService, userService) => {
    const deferredUserListMap = {};
    const userEmail = userService.getUser().email;

    function getUserListId(userIds) {
        return userIds.join(',');
    }

    function loadUserList(id, userIds) {
        const state = {
            promise: null,
            message: null,
        };
        state.promise = $q.all(userIds.map(userId => (
            userRepositoryService.get(userId)
        ))).then(users => {
            const messageArray = users.map(user => (
                user.email
            ));
            messageArray.sort();
            // '{email} (you)' moved to index[0] if you agree to the comment
            if (messageArray.indexOf(userEmail) !== -1) {
                messageArray.splice(messageArray.indexOf(userEmail), 1);
                messageArray.unshift(userEmail + ' (you)');
            }
            // List shows first 5 users, '+ X other(s)'
            // TODO: translate strings
            const maxUsers = 5;
            const allUsers = messageArray.length;
            if (allUsers > maxUsers) {
                messageArray.splice(maxUsers);
                const additionalUsers = allUsers - maxUsers;
                console.log('additionalUsers', allUsers, maxUsers, additionalUsers);
                if (additionalUsers === 1) {
                    messageArray.push('+ ' + additionalUsers + ' other');
                } else {
                    messageArray.push('+ ' + additionalUsers + ' others');
                }
            }

            state.message = messageArray.join('\n');
        });

        deferredUserListMap[id] = state;
    }

    return (userIds) => {
        if (!userIds || !userIds.length) {
            return '';
        }
        const id = getUserListId(userIds);
        const state = deferredUserListMap[id];
        if (!state) {
            // Hasn't started loading yet
            loadUserList(id, userIds);
        } else if (!state.message) {
            // Hasn't finished loading yet
            return '';
        } else {
            // Has finished loading
            return state.message;
        }
    };
});