/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

class MiddlewareInterceptor {
    /**
     * The name of the interceptor.
     *
     * @type {String}
     */
    name = null;

    /**
     * The list of dependencies for this interceptor.
     *
     * @type {String[]}
     */
    dependencies = [];

    /**
     * The interceptor callback.
     *
     * @type {Function}
     */
    callback = angular.noop;

    /**
     * Constructs a new middleware interceptor.
     *
     * @param {String} name
     * @param {String[]} dependencies
     * @param {Function} callback
     */
    constructor (name, dependencies, callback) {
        this.setName(name);
        this.addDependencies(dependencies);
        this.setCallback(callback);
    }

    /**
     * Sets the interceptor's name.
     *
     * @param {String} name
     */
    setName (name) {
        if (name && angular.isString(name)) {
            this.name = name;
        }
    }

    /**
     * Adds dependencies to the interceptor.
     *
     * @param {String[]} dependencies
     */
    addDependencies (dependencies) {
        if (dependencies && angular.isArray(dependencies)) {
            dependencies.forEach(dependency => {
                this.dependencies.push(dependency);
            });
        }
    }

    /**
     * Sets the interceptor's callback.
     *
     * @param {Function|Array} callback
     */
    setCallback (callback) {
        if (callback && (angular.isFunction(callback) || angular.isArray(callback))) {
            this.callback = callback;
        }
    }
}

class MiddlewareServiceProvider {
    /**
     * The registry of unnamed interceptors.
     *
     * @type {MiddlewareInterceptor[]}
     */
    globals = [];

    /**
     * The registry of interceptors.
     *
     * @type {MiddlewareInterceptor[]}
     */
    registry = [];

    /**
     * Register a middleware interceptor.
     *
     * @param {String} name
     * @param {String[]|*} [dependencies]
     * @param {Function|Array} callback
     */
    register (name, dependencies, callback) {
        if ( ! callback && (angular.isFunction(dependencies) || angular.isArray(dependencies))) {
            callback = dependencies;
            dependencies = void 0;
        }

        let interceptor = new MiddlewareInterceptor(name, dependencies, callback);
        this.registry.push(interceptor);
    }

    /**
     * Registers a global (unnamed) middleware interceptor.
     *
     * @param {String[]|*} [dependencies]
     * @param {Function|Array} callback
     */
    global (dependencies, callback) {
        if ( ! callback && (angular.isFunction(dependencies) || angular.isArray(dependencies))) {
            callback = dependencies;
            dependencies = void 0;
        }

        let interceptor = new MiddlewareInterceptor(null, dependencies, callback);

        this.registry.push(interceptor);
        this.globals.push(interceptor);
    }

    /**
     * Retrieves a middleware interceptor from the register.
     *
     * @param {String} name
     * @returns {MiddlewareInterceptor}
     */
    retrieve (name) {
        let index = -1, found;

        while (++index < this.registry.length && ! found) {
            if (this.registry[index].name === name) {
                found = this.registry[index];
            }
        }

        return found;
    }
}

class MiddlewareService {
    /**
     * The current url.
     *
     * @type {String}
     */
    $$currentUrl = null;

    /**
     * The url the user is attempting to access.
     *
     * @type {String}
     */
    $$nextUrl = null;

    /**
     * @param {Object} $injector
     * @param {Object} $location
     * @param {Object} $route
     * @param {MiddlewareServiceProvider} provider
     *
     * @ngInject
     * @constructor
     */
    constructor ($injector, $location, $route, provider) {
        this.$injector = $injector;
        this.$location = $location;
        this.$route = $route;
        this.provider = provider;

        // Initialise the $$nextUrl for the first interceptor
        this.$$nextUrl = $location.url();
    }

    /**
     * Logs a message, from the service, to the console.
     *
     * @param {String} name
     * @param {...String|*} message
     */
    $$log (name, ...message) {
        let prefix = '[middlewareService.' + name + ']';

        if (angular.isString(message[0])) {
            message[0] = prefix + ' ' + message[0];
        } else {
            message.unshift(prefix);
        }

        // Confuse the minifier
        $$log(...message);
    }

    /**
     * When the route starts to change, invoke the interceptors.
     *
     * @param {Object} event
     * @param {Object} next
     * @param {Object} current
     */
    $routeChangeStart (event, next, current) {
        let success = true,
            prevented = null,
            attempt,
            route,
            displayUrl;

        if ((route = next.$$route)) {
            displayUrl = route.originalPath;

            let globalInterceptors = this.getGlobalInterceptors(),
                routeInterceptors = this.getInterceptorsForRoute(route),
                locals = {
                    route, url: this.$$nextUrl
                };

            if (globalInterceptors.length) {
                this.$$log('$routeChangeStart', '%s global middleware interceptor(s) registered',
                    globalInterceptors.length);

                attempt = this.invokeInterceptors(globalInterceptors, locals);

                success = attempt.success;
                prevented = attempt.prevented;
            }

            if (success) {
                if (routeInterceptors.length) {
                    this.$$log('$routeChangeStart', '%s middleware interceptor(s) (%s) found for route "%s"',
                        routeInterceptors.length, routeInterceptors.map((i) => `"${i.name}"`).join(', '), displayUrl);

                    attempt = this.invokeInterceptors(routeInterceptors, locals);

                    success = attempt.success;
                    prevented = attempt.prevented;
                } else {
                    this.$$log('$routeChangeStart', 'No middleware for route "%s"',
                        displayUrl);
                }
            }
        } else {
            displayUrl = this.$$nextUrl;
        }

        if (success) {
            this.$$log('$routeChangeStart', 'Allowing route "%s"',
                displayUrl);
        } else {
            event.preventDefault();

            this.$$log('$routeChangeStart', 'Prevented route "%s" (from "%s")',
                displayUrl, prevented.name || '(unnamed)');
        }
    }

    /**
     * Invoke middleware interceptors.
     *
     * @param {MiddlewareInterceptor[]} interceptors
     * @param {Object} locals
     * @returns {Object}
     */
    invokeInterceptors (interceptors, locals) {
        let success = true,
            prevented = null;

        if (interceptors && interceptors.length) {
            for (var index = 0; success && index < interceptors.length; index++) {
                let interceptor = interceptors[index];

                let resolved = this.$injector.invoke(interceptor.callback, undefined, locals);

                if (angular.isDefined(resolved) && ! resolved) {
                    prevented = interceptor;
                    success = false;
                }
            }
        }

        return {
            success,
            prevented
        };
    }

    /**
     * Stores the latest urls (due to redirect interception requiring the current & next
     * url in order to be able to redirect to the intended url).
     *
     * @param {Object} event
     * @param {String} next
     * @param {String} current
     */
    $locationChangeStart (event, next, current) {
        this.$$currentUrl = current;
        this.$$nextUrl = next;
    }

    ///**
    // * Gets a route from a url.
    // *
    // * @param {String} url
    // * @returns {Object|null}
    // */
    //getRouteFromUrl (url) {
    //    let routes = this.$route.routes,
    //        route = null;
    //
    //    Object.keys(routes).some((path) => {
    //        if (routes[path].regex && routes[path].regex.test(url)) {
    //            route = routes[path];
    //            return true;
    //        }
    //    });
    //
    //    return route;
    //}

    /**
     * Returns an array of interceptor objects by their names.
     *
     * @param {String[]} names
     * @param {Boolean} [deep]
     * @returns {MiddlewareInterceptor[]}
     */
    getInterceptors (names, deep = false) {
        let interceptors = [];

        names.forEach(name => {
            let interceptor = this.provider.retrieve(name);

            if (interceptor) {
                if (deep && interceptor.dependencies) {
                    let dependencies = this.getInterceptors(interceptor.dependencies, deep);
                    interceptors.push(...dependencies);
                }

                interceptors.push(interceptor);
            }
        });

        return interceptors;
    }

    /**
     * Gets the interceptors that run for every route.
     *
     * @returns {MiddlewareInterceptor[]}
     */
    getGlobalInterceptors () {
        return this.provider.globals;
    }

    /**
     * Returns the array of interceptor objects for a route.
     *
     * @param {Object} route
     * @returns {MiddlewareInterceptor[]}
     */
    getInterceptorsForRoute (route) {
        let middleware = (route.data && route.data.middleware),
            interceptors = [];

        if (route.$$interceptors) {
            interceptors = route.$$interceptors;
        } else {
            if (middleware && middleware.length) {
                interceptors = this.getInterceptors(middleware, true);
            }
            route.$$interceptors = interceptors;
        }

        return interceptors;
    }
}

app.provider('middlewareService', function ($injector) {
    return provide($injector, MiddlewareServiceProvider, MiddlewareService);
});

app.config(function (middlewareServiceProvider) {
    /**
     * auth
     *
     * @param {Object} $location
     * @param {Object} userService
     * @param {String} url
     */
    middlewareServiceProvider.register('auth', /* @ngInject */ function ($location, userService, url) {
        let startIndex = url.indexOf(location.origin) !== -1 ?
                url.indexOf(location.origin) + location.origin.length : 0,
            path = url.substring(startIndex) || '/'; // #892

        if ( ! userService.isLoggedIn() && ! path.startsWith('login', 1)) {
            $location.path('login').search({
                redirect: path,
                email: $location.search().email,
                code: $location.search().code, // allow the login page to activate users if a code is provided
            });
            return false;
        }
    });

    /**
     * guest
     *
     * @param {Object} $location
     * @param {Object} userService
     */
    middlewareServiceProvider.register('guest', /* @ngInject */ function ($location, userService) {
        if (userService.isLoggedIn()) {
            $location.url('dashboard');
            return false;
        }
    });

    middlewareServiceProvider.register('auto.create', /* @ngInject */ function ($location, userService) {
        if (userService.isLoggedIn()) {
            const proofId = $location.search().proofId;
            const redirect = $location.search().redirect;
            if (proofId) {
                $location.url('proof-screen/' + proofId);
            } else if (redirect) {
                $location.url(redirect);
            } else {
                $location.url('dashboard');
            }
            return false;
        }
    });

    /**
     * file
     *
     * @param {Object} $location
     * @param {Object} DataService
     */
    middlewareServiceProvider.register('file', /* @ngInject */ function ($location, DataService) {
        if ( ! DataService.hasFile()) {
            $location.url('dashboard');
            return false;
        }
    });

    /**
     * file.import
     *
     * @param {Object} $location
     * @param {Object} DataService
     */
    middlewareServiceProvider.register('file.import', ['file'], /* @ngInject */ function ($location, DataService) {
        if ( ! DataService.hasImportData()) {
            $location.url('dashboard');
            return false;
        }
    });

    /**
     * domain.admin
     *
     * @param {Object} $location
     * @param {Object} userService
     */
    middlewareServiceProvider.register('domain.admin', ['auth'], /* @ngInject */ function ($location, userService) {
        if ( ! userService.getUser().isDomainAdmin) {
            $location.url('dashboard');
            return false;
        }
    });

    /**
     * active
     *
     * @param {Object} $location
     * @param {Object} DataService
     */
    middlewareServiceProvider.register('active', ['auth'], /* @ngInject */ function ($location, userService) {
        if ( ! userService.getUser().isActivated) {
            $location.url('activate/' + userService.getUser().id);
            return false;
        }
    });

    /**
     * inactive
     *
     * @param {Object} $location
     * @param {Object} userService
     */
    middlewareServiceProvider.register('inactive', ['auth'], /* @ngInject */ function ($location, userService) {
        if (userService.getUser().isActivated) {
            $location.url('dashboard');
            return false;
        }
    });

    /**
     * free
     *
     * @param {Object} $location
     * @param {Object} userService
     */
    middlewareServiceProvider.register('free', ['auth'], /* @ngInject */ function ($location, userService) {
        if (userService.getUser().isPremium) {
            $location.url('dashboard');
            return false;
        }
    });

    /**
     * premium
     *
     * @param {Object} $location
     * @param {Object} userService
     */
    middlewareServiceProvider.register('premium', ['auth'], /* @ngInject */ function ($location, userService) {
        if ( ! userService.getUser().isPremium) {
            $location.url('dashboard');
            return false;
        }
    });

    /**
     * private.admin
     *
     * @param {Object} $location
     * @param {Object} userService
     */
    middlewareServiceProvider.register('private.admin', ['auth'], /* @ngInject */ function ($location, userService) {
        let {email} = userService.getUser();
        if ( ! email.endsWith('@pageproof.com')) {
            $location.url('dashboard');
            return false;
        }
    });
    /**
     * marketing
     *
     * @param {Object} $location
     */
    middlewareServiceProvider.register('marketing', /* @ngInject */ function ($location) {
        location.href = env('marketing_url') + $location.path();
        return false;
    });

    middlewareServiceProvider.global(/* @ngInject */ function (backgroundService) {
        return backgroundService._delegateMiddlewareService();
    });

    //let lastPopulated = -1;
    //middlewareServiceProvider.global(/* @ngInject */ function (userService) {
    //    if (userService.isLoggedIn() && lastPopulated < (Date.now() - 10e3)) {
    //        lastPopulated = Number.MAX_VALUE; // Prevent the interceptor from calling multiple times before the last one has finished
    //        userService.populateUser().then(() => lastPopulated = Date.now());
    //    }
    //});
});

app.run(function ($rootScope, middlewareService) {
    // Keep track of the location when it changes, in order to provide hooks with the users intended url so it
    // can be used within the redirect. Either using a search param of `redirect` or similar.
    $rootScope.$on('$locationChangeStart', (...args) => {
        return middlewareService.$locationChangeStart(...args);
    });

    // Intercept when the route changes and apply any required middleware.
    $rootScope.$on('$routeChangeStart', (...args) => {
        return middlewareService.$routeChangeStart(...args);
    });
});
