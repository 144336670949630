/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class DirectiveHelperService {
    /**
     * @param {Function} $parse
     * @param {Object} callbackService
     * @constructor
     */
    constructor ($parse, callbackService) {
        this.$$ = { $parse, callbackService };
    }

    /**
     *
     * @example
     *     <test example="ctrl.example"></test>
     *     directiveHelper.twoWayBinding(scope, attr, 'example', ctrl, 'example');
     *
     * @param {$rootScope.Scope} scope
     * @param {Object} attr
     * @param {String} key
     * @param {Object} target
     * @param {String} property
     * @param {Boolean} [collection]
     * @param {Function} [callback]
     * @returns {Function}
     */
    twoWayBinding (scope, attr, key, target, property, collection = false, callback) {
        if ( ! (key in attr)) {
            // Prevents the registration of bindings on unspecified attributes
            console.warn('Ignoring attempt at registering a two way binding where directive does not specify an associated attribute.');
            console.warn('Two way binding: %s in ', key, attr);
            return angular.noop;
        }

        let that = this;
        let scopeBinding = this.$$.$parse(attr[key]),
            firstCheck = true,
            lastValue,
            watcher;

        lastValue = target[property] = scopeBinding(scope);

        if (angular.isUndefined(lastValue)) {
            lastValue = target[property];
        }

        assign(lastValue);

        let compare = scopeBinding.literal ? angular.equals :
            function (a, b) { return a === b || (a !== a && b !== b); };

        function assign (value) {
            console.debug('assign ' + property + ' to', target, 'as', value);

            if (scopeBinding.assign) {
                scopeBinding.assign(scope, value);
            }
        }

        function valueWatch (scopeValue) {
            if ( ! compare(scopeValue, target[property])) {
                if ( ! compare(scopeValue, lastValue)) {
                    target[property] = scopeValue;
                } else {
                    assign(scopeValue = target[property]);
                }
            }
            if (firstCheck || ! compare(lastValue, scopeValue)) {
                that.$$.callbackService.safe(callback, void 0, [scopeValue]);
            }
            return (lastValue = scopeValue);
        }

        valueWatch.$stateful = true;

        function destroyTwoWayBinding () {
            if (angular.isFunction(watcher)) {
                watcher();
                watcher = null;
            }
        }

        if (collection) {
            watcher = scope.$watchCollection(scopeBinding, valueWatch);
        } else {
            watcher = scope.$watch(this.$$.$parse(attr[key], valueWatch), null, scopeBinding.literal);
        }

        scope.$on('$destroy', destroyTwoWayBinding);

        return destroyTwoWayBinding;
    }

    /**
     *
     * @example
     *     <test example="ctrl.example"></test>
     *     directiveHelper.oneWayBinding(scope, attr, 'example', ctrl, 'example');
     *
     * @param {$rootScope.Scope} scope
     * @param {Object} attr
     * @param {String} key
     * @param {Object} target
     * @param {String} property
     * @param {Boolean} [collection]
     * @param {Function} [callback]
     * @returns {Function}
     */
    oneWayBinding (scope, attr, key, target, property, collection = false, callback) {
        if ( ! (key in attr)) {
            console.warn('Ignoring attempt at registering a one way binding where directive does not specify an associated attribute.');
            console.warn('One way binding: %s in ', key, attr);
            return angular.noop;
        }

        let that = this;
        let watcher;

        function valueWatch (scopeValue) {
            if (angular.isDefined(scopeValue)) {
                target[property] = scopeValue;
            }
            that.$$.callbackService.safe(callback, void 0, [scopeValue]);
        }

        function destroyOneWayBinding () {
            if (angular.isFunction(watcher)) {
                watcher();
                watcher = null;
            }
        }

        if (collection) {
            watcher = scope.$watchCollection(attr[key], valueWatch);
        } else {
            watcher = scope.$watch(attr[key], valueWatch);
        }

        scope.$on('$destroy', destroyOneWayBinding);

        return destroyOneWayBinding;
    }

    /**
     *
     * @example
     *     <test example="{{ ctrl.example }}"></test>
     *     directiveHelper.expressionBinding(scope, attr, 'example', ctrl, 'example');
     *
     * @param {$rootScope.Scope} scope
     * @param {Object} attr
     * @param {String} key
     * @param {Object} target
     * @param {String} property
     * @param {Function} [callback]
     * @returns {Function}
     */
    expressionBinding (scope, attr, key, target, property, callback) {
        if ( ! (key in attr)) {
            console.warn('Ignoring attempt at registering an expression binding where directive does not specify an associated attribute.');
            console.warn('Expression binding: %s in ', key, attr);
            return angular.noop;
        }

        let that = this;
        let watcher;

        function valueWatch (attrValue) {
            target[property] = attrValue;
            that.$$.callbackService.safe(callback, void 0, [attrValue]);
        }

        function destroyExpressionBinding () {
            if (angular.isFunction(watcher)) {
                watcher();
                watcher = null;
            }
        }

        watcher = scope.$watch(() => attr[key], valueWatch);

        scope.$on('$destroy', destroyExpressionBinding);

        return destroyExpressionBinding;
    }

    /**
     *
     * @param {$rootScope.Scope} scope
     * @param {Object} attr
     * @param {String} key
     * @param {Object} target
     * @param {String} property
     * @returns {Function}
     */
    callbackBinding (scope, attr, key, target, property) {
        if ( ! (key in attr)) {
            console.warn('Ignoring attempt at registering a callback binding where directive does not specify an associated attribute.');
            console.warn('Callback binding: %s in ', key, attr);
            return angular.noop;
        }

        let scopeBinding = this.$$.$parse(attr[key]),
            hasBeenDestroyed = false;

        function invokeCallback (locals) {
            if ( ! hasBeenDestroyed) {
                return scopeBinding(scope, locals);
            }
        }

        target[property] = invokeCallback;

        scope.$on('$destroy', () => hasBeenDestroyed = true);

        return invokeCallback;
    }

    /**
     * Returns a callback based on an attr value.
     *
     * @param {Scope} scope
     * @param {Object} attr
     * @param {string} key
     * @returns {function}
     */
    callback(scope, attr, key) {
        return this.$$.$parse(attr[key]).bind(null, scope);
    }

    /**
     * Assigns an accessor to a controller.
     *
     * @param {$rootScope.Scope} scope
     * @param {Object} attr
     * @param {String} key
     * @param {Object} ctrl
     * @returns {Function}
     */
    controllerBinding (scope, attr, key, ctrl) {
        if (key in attr) {
            let assign = this.$$.$parse(attr[key]).assign;

            if (angular.isFunction(assign)) {
                assign(scope, ctrl);
            } else {
                console.warn('Cannot assign controller - where value is "%s"', attr[key]);
            }
        } else {
            console.warn('Ignoring attempt at registering a controller binding where directive does not specify an associated attribute.');
            console.warn('Controller binding: %s in ', key, attr);
        }

        return angular.noop;
    }
}

app
    .service('directiveHelper', DirectiveHelperService);
