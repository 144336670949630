/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class AutoCreateAccountController {
    constructor (
        $scope,
        $location,
        $api,
        $routeParams,
        userService,
        UserService,
        sdk
    ) {
         this.$$ = {
            $api,
            userService,
            UserService,
            sdk,
        };

        const {
            email,
            code: activationCode,
            redirect: redirectUrl,
            proofId,
            tps: temporaryPasswordSeed,
            referrer,
        } = $routeParams;

        const searchParams = temporaryPasswordSeed
            ? { referrer }
            : {
                email,
                redirectUrl,
                referrer,
            };

        if (userService.isLoggedIn()) {
            redirect();
        } else {
            window.localStorage.setItem('pageproof.app.email', email);

            const temporaryPassword = this.getTemporaryPassword(temporaryPasswordSeed, email);

            this.setLoader(`Hang on a sec, we’re securing your account details.`);
            this.redirectToSso(email)
                .then(() => {
                    if (temporaryPasswordSeed) {
                        return this.login(email, temporaryPassword, activationCode);
                    }
                })
                .catch(() => this.register(email, temporaryPassword, activationCode, proofId))
                .finally(redirect);
        }

        function redirect() {
            $location
                .url(temporaryPasswordSeed ? (redirectUrl || `/proof-screen/${proofId}`) : '/login')
                .search(searchParams);

            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }
    }

    getTemporaryPassword(seed, email) {
        if (seed) {
            const hmac = forge.hmac.create();
            hmac.start('sha256', atob(seed));
            hmac.update(email);
            return hmac.digest().data;
        } else {
            return randomString(32, '*'); // Legacy
        }
    }

    setLoader(message) {
        if (message) {
            this.$$.UserService.showLoaderMessage(message);
            this.$$.UserService.showLoader();
        } else {
            this.$$.UserService.hideLoader();
        }
    }

    redirectToSso(email) {
        return this.$$.sdk.accounts.sso.initiateLogin(email).then((response) => {
            const options = response.options || [response.option];
            const usesEnforcedSSO = options.length === 1 && options[0].type === 'sso';
            if (usesEnforcedSSO) {
                this.setLoader('Redirecting you to your organization’s login portal.');
                location.href = options[0].url;
                return new Promise(() => {});
            }
        });
    }

    register(email, password, activationCode, proofId) {
        return new Promise((resolve, reject) => {
            this.$$.UserService.Register(email, password, true, proofId, (errorResponseStatus, responseBody) => {
                if (errorResponseStatus === null) {
                    if (responseBody.Activated) {
                        resolve();
                    } else {
                        resolve(this.activate(activationCode));
                    }
                } else {
                    reject(new Error(`Failed to register user: ${errorResponseStatus}.`));
                }
            });
        });
    }

    activate(activationCode) {
        return this.$$.$api.users.checkAuth.fetch({
            userId: this.$$.userService.getUser().id,
            authCode: activationCode,
        }).then((response) => {
            if (response.ResponseStatus === 'OK') {
                this.$$.UserService.UpdateUserData({ isActivated: true });
            }
        });
    }

    login(email, password, activationCode) {
        return new Promise((resolve, reject) => {
            this.$$.UserService.Login(email, password, (success, responseBody) => {
                if (success === 'true') {
                    if (responseBody.Activated) {
                        resolve();
                    } else {
                        resolve(this.activate(activationCode));
                    }
                } else {
                    reject(new Error(`Failed to login as ${email}.`));
                }
            });
        });
    }
}

app.controller('AutoCreateAccountController', AutoCreateAccountController);
