/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class UserServiceProvider {
    /**
     * The last known user id.
     *
     * Note: Please do not use this id, get the authenticated user and grab the id from the object.
     *
     * @type {String}
     */
    $$userId = null;

    /**
     * The cached user object.
     *
     * @type {PPUser}
     */
    $$user = null;

    /**
     * @param {Object} $api
     * @param {PPUser} PPUser
     * @param {Object} UserService
     *
     * @constructor
     * @ngInject
     */
    constructor ($api, PPUser, UserService, utilService, SegmentIo) {
        this.apiService = $api;
        this.PPUser = PPUser;
        this.app = UserService;
        this.utilService = utilService;
        this.SegmentIo = SegmentIo;
    }

    /**
     * Returns the currently authenticated user.
     *
     * @returns {PPUser|null}
     */
    getUser () {
        let userData = this.app.GetUserData();

        if (userData) {
            let userId = userData.userId;

            if (userId !== this.$$userId) {
                this.$$userId = userId;
                this.$$user = new this.PPUser();
            }

            this.$$user.updateFromLocalStorageData(userData);
            this.$$user.updateFromEncryptionData(userData.encObj);
        } else {
            this.$$user = this.$$userId = null;
        }

        return this.$$user;
    }

    /**
     * Populates the currently authenticated user object.
     *
     * Resolves the PPUser instance.
     *
     * @returns {$q}
     */
    populateUser () {
        let user = this.getUser();

        if (user) {
            return this.apiService
                .users.load
                .fetch(user.id)
                .then((userData) => {
                    if (userData) {
                        user.updateFromUserData(userData);
                    }
                    return user;
                });
        }
    }

    /**
     * Whether the user is currently authenticated.
     *
     * @returns {Boolean}
     */
    isLoggedIn () {
        return this.app.IsLoggedIn();
    }

    /**
     * Whether the user matches a list of IDs or objects.
     *
     * @param {*[]} others
     */
    matches (...others) {
        let user = this.getUser();

        if ( ! user) {
            return false;
        }

        return others.some((other) => {
            let id;

            if (other instanceof this.PPUser) {
                id = other.id;
            } else if (typeof other === 'string') {
                id = other;
            } else if (angular.isObject(other)) {
                if ('id' in other) {
                    id = other.id;
                } else if ('UserId' in other) {
                    id = other.UserId;
                } else if ('userId' in other) {
                    id = other.userId;
                }
            }

            return id === user.id;
        });
    }

    /**
     * Track the creation of a user. Post conversion details to Google and Segment.
     *
     * @param {Object} user
     */
    track (user) {
        var tracking = {
            google_conversion_id: 960661104,
            google_conversion_language: "en",
            google_conversion_format: "3",
            google_conversion_color: "ffffff",
            google_conversion_label: "TlaVCL3E7WIQ8IyKygM",
            google_remarketing_only: false
        };

        Object.keys(tracking).forEach((key) => {
            window[key] = tracking[key];
        });

        this.utilService.loadScript('//www.googleadservices.com/pagead/conversion.js');

        this.SegmentIo.identifyNewUser(user.UserId);
    }

}

app.factory('userService', ($injector) => {
    return $injector.instantiate(UserServiceProvider);
});
