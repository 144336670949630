/* Copyright (C) PageProof Holdings limited - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

'use strict';

angular
    .module('ppxGlobalControllers')
    .value('securePasswordConfig', {
        prefix: 'securePassword'
    })
    .value('securePasswordRules', {
        uppercase: /[A-Z]/,
        lowercase: /[a-z]/,
        number: /\d/,
        length: function (value) {
            return value && value.length >= 8;
        }
    })
    .directive('securePassword', function (securePasswordConfig, securePasswordRules) {
        var prefix = securePasswordConfig.prefix;

        /**
         * Tests a specific value against an entry in securePasswordRules.
         *
         * @param {*} value
         * @param {String} ruleName
         * @returns {Boolean}
         */
        var testRule = function (value, ruleName) {
            var rule = securePasswordRules[ruleName];

            if (rule instanceof RegExp) {
                return rule.test(value);
            } else if (angular.isFunction(rule)) {
                return !! rule(value);
            }

            return false;
        };

        return {
            require: 'ngModel',
            link: function (scope, element, attrs, model) {
                Object.keys(securePasswordRules).forEach(function (ruleName) {
                    var validatorName = prefix +
                                        ruleName.charAt(0).toUpperCase() +
                                        ruleName.substring(1);

                    model.$validators[validatorName] = function (value) {
                        return testRule(value, ruleName);
                    };
                });

                model.$validators[prefix] = function () {
                    return ! Object.keys(model.$error).some(function (validatorName) {
                        return validatorName !== prefix && // don't include itself (from previous validation)
                               validatorName.substring(0, prefix.length) === prefix; // begins with prefix
                    });
                };
            }
        };
    });
