/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
ppxControllers
    .controller('ImportController', function (
        $scope,
        $q,
        $location,
        $timeout,
        $interval,
        $routeParams,
        importService,
        importProvider,
        eventService,
        browserService,
        modalService,
        bugsnag,
        UserService,
        appService,
        NavigationService,
        SegmentIo
    ) {
        var retryInterval,
            retries = 0;

        var message = 'loader-message.import.default';

        if (browserService.is('mobile')) {
            switch (browserService.name) {
                case 'safari':
                    message = 'loader-message.import.safari.mobile';
                    break;
            }
        } else if (browserService.is('tablet')) {
            //switch (browserService.name) {
            //    case 'chrome':
            //        message = 'Tap the ';
            //        break;
            //}
        } else {
            switch (browserService.name) {
                case 'chrome':
                    message = 'loader-message.import.chrome';
                    break;
                case 'firefox':
                    message = 'loader-message.import.firefox';
                    break;
                case 'safari':
                    message = 'loader-message.import.safari';
                    break;
                case 'ie':
                    message = 'loader-message.import.ie';
                    break;
            }
        }

        $scope.$on('$destroy', function () {
            if (retryInterval) {
                $interval.cancel(retryInterval);
            }
        });

        function validate (files) {
            return $q(function (resolve, reject) {
                var promises = files.map(function (file) {
                    return importService.verify(file)
                        .then(function () {
                            return file;
                        })
                        .catch(function (error) {
                            SegmentIo.track(36, {
                                fileType: file.extension,
                                provider: importService.pretty(importProvider)
                            });

                            throw error;
                        });
                });
        
                Promise.all(promises)
                    .then(resolve)
                    .catch(function(error) {
                        modalService.translate(
                            'import.error.cannot-verify.title',
                            'import.error.cannot-verify.message',
                            [
                                { type: 'outline', text: 'button.back', click: cancel },
                                { type: 'primary', text: 'import.error.cannot-verify.button.try-again', click: request }
                            ]
                        );
                    });
            });
        }

        function success (files) {

            const jsonQueryParam = function (name) {
              return $routeParams[name]
                ? JSON.parse($routeParams[name])
                : null;
            };

            const parameters = jsonQueryParam('parameters');
            const initialize = jsonQueryParam('initialize');
            const SUPPORTED_COMBINATION_PDF_FILE_EXTENSIONS = ['tif', 'tiff', 'ai', 'png', 'jpeg', 'jpg', 'pdf', 'psd', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx']; 

            var fileTypesCanBeCombined = !files.some(function(file) {
                return !SUPPORTED_COMBINATION_PDF_FILE_EXTENSIONS.includes(file.extension);
              });
            

            if (files.length === 1 || ($routeParams.multiple && !fileTypesCanBeCombined)) {
                window.__pageproof_quark__.proofSetup.requestProofSetup(
                    {
                        parameters: parameters,
                        initialize: initialize
                    },
                    function () {
                        files.forEach(function (file) {
                            window.__pageproof_quark__.proofSetup.addFile.addExternalFile(
                                importService.name(importProvider),
                                file.url,
                                file.name + '.' + file.extension,
                                file.size,
                                (file.meta && file.meta.accessToken) || null,
                                initialize,
                                parameters
                            );

                            SegmentIo.track(27, {
                                fileType: file.extension,
                                provider: importService.pretty(importProvider)
                            });
                        });

                        appService.hideLoader();
                        $location.url('/create');
                    });
            } else if (!$routeParams.multiple && !fileTypesCanBeCombined) {
                var modal = modalService.translate(
                    'proof.combine-files.title.select-one',
                    'proof.combine-files.body.select-one',
                    [
                        { text: 'button.ok', type: 'primary', click: cancel }
                    ],
                    {},
                    true
                );
            } else {
                var modal = modalService.createWithComponent('CombineExternalFilesModal', {
                    files: files,
                    importProvider: importService.name(importProvider),
                    initialize: initialize,
                    parameters: parameters,
                    onComplete: function (fileExtension) {
                        modal.destroy();
                        appService.hideLoader();
                        $location.url('/create');

                        if (fileExtension === 'zip-Static') {
                            SegmentIo.track(66, {
                                provider: importService.pretty(importProvider)
                            });
                        } else {
                            files.map(function (file) {
                                SegmentIo.track(27, {
                                    fileType: file.extension,
                                    provider: importService.pretty(importProvider)
                                });
                            })
                        }
                    },
                    onCancel: function() {
                        modal.destroy();
                        cancel();
                    },
                    canAddMultiple: $routeParams.multiple,
                    },
                    null,
                    false
                );
            }
        }

        function log (err) {
            if (err) {
                bugsnag.notifyException(err);
            }

            SegmentIo.track(28, {
                cause: err && (err.message || err.toString()),
                provider: importService.pretty(importProvider)
            });
        }

        function callback (file) {
            return validate(file).then(success).catch(log);
        }

        function cancel () {
            appService.hideLoader();

            NavigationService.back(function () {
                $location.url($routeParams.back || 'dashboard');
            });
        }

        function retry () {
            var seconds = 16;

            retryInterval = $interval(function () {
                seconds--;

                if (seconds > 0) {

                    var messageVariables = {
                        importProvider: importService.pretty(importProvider),
                        seconds: seconds
                    };

                    appService.showTranslatedLoaderMessage(
                        ['loader-message.browser-popups.retry', message],
                        [
                            {
                                text: 'button.back',
                                classes: 'btn-sub',
                                callback: eventService.$$wrap(cancel)
                            },
                            {
                                text: 'loader-message.import.lets-try-again',
                                callback: reload
                            }
                        ],
                        messageVariables
                    );

                } else {
                    $interval.cancel(retryInterval);
                    request();
                }
            }, 1e3);

            retries++;
        }

        function failed () {
            error();
        }

        function error (err) {
            if (err instanceof importService.PopupBlockedError) {
                if (retries < 5) {
                    retry();
                } else {
                    failed();
                }
            } else {
                cancel();
            }
        }

        function reload () {
            $location.path('import/' + importProvider.toLowerCase()).ignore();

            $timeout(function () {
                window.location.reload();
            });
        }

        function request () {
            UserService.showLoader();

            var messageVariables = {
                importProvider: importService.pretty(importProvider),
            };
            appService.showTranslatedLoaderMessage(
                ['loader-message.import.waiting-for-service', message],
                [
                    {
                        text: 'button.back',
                        classes: 'btn-sub',
                        callback: eventService.$$wrap(cancel)
                    },
                    {
                        text: 'loader-message.import.lets-try-again',
                        callback: reload
                    }
                ],
                messageVariables
            );

            importService
                .from(importProvider)
                .then(callback)
                .catch(error);
        }

        if (importProvider === 'Onedrive' && $location.path().startsWith('import/onedrive', 1)) {
            $location.path('onedrive.php').ignore();
        }

        if ($routeParams.url) {
            callback({
                name: getFileName($routeParams.name),
                extension: getFileType($routeParams.name),
                url: $routeParams.url,
                size: $routeParams.size,
                meta: {
                    accessToken: $routeParams.token,
                }
            });
        } else {
            request();
        }

    });
