/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
//constructor function
function WorkFlow(app) {
    this.application = app;
    this.api = app.api;
    this.html = "";
    this.genericError = this.application.GetConfigSetting("UserErrorMessage_GenericUserMessage");

    this.team = new Team(this.application, "workflows");

    this.allowedWorkflowPermissionTypes = ["WorkflowInviter","WorkflowCopyToProof", "WorkflowTemplateCoowner"];

}

WorkFlow.prototype._showErrorDialogue = function(techInfo) {
    this.application.showErrorBox(this.genericError, techInfo);
};

WorkFlow.prototype.SetWorkFlowId = function(workFlowId) {
    if(empty(workFlowId)){
        this._showErrorDialogue("no workFlowId set (WorkFlow SetWorkFlowId method)");
        return "";
    }
    this.workFlowId = workFlowId;
};

WorkFlow.prototype.Create = function(proofId, title, description, isOwnedByTeam, callBackFunc) {
    var self = this;
    if (!isset(isOwnedByTeam)) {
      isOwnedByTeam = false;
    }
    this.api.CallAPI("workflows", "create", {
        Title: stripTags(title),
        Description: stripTags(description),
        OwnedByTeam: isOwnedByTeam,
    }, function () {
        var wfReturnData = jsonDecode(this.responseText);
        if(isset(wfReturnData["WorkflowId"])){
            if(isset(proofId)){
                var proof = new Proof(self.application);
                proof.Update(proofId, {WorkflowId:wfReturnData["WorkflowId"]}, function(){
                   self.SetWorkflowId(wfReturnData["WorkflowId"]);
                });
            }
        }else{
            this._showErrorDialogue("no workFlowId returned from API (WorkFlow Create method)");
        }
        callBackFunc(wfReturnData);
    });
};

WorkFlow.prototype.Search = function(params, callBackFunc) {
    if (isset(params)) {
        this.api.CallAPI("workflows", "search", {Query:params}, callBackFunc);
    } else {
        this._showErrorDialogue("no proofId set (Proof Load method)");
    }
};

WorkFlow.prototype.Duplicate = function(workflowId, proofId, callBackFunc) {
    var data = {};
    data.WorkflowId = workflowId;
    if(proofId) data.ProofId = proofId;
    this.api.CallAPI("workflows", "duplicate", data, function(){
        var wfReturnData = jsonDecode(this.responseText);
        callBackFunc(wfReturnData);
    });
};

WorkFlow.prototype.CreateStepEmail = function(workFlowId, stepId, userId, mandatory, approver, callBackFunc) {
    if (isset(workFlowId)) {
        if(mandatory == false) mandatory = 0;
        if(mandatory == true) mandatory = 1;
        if(approver == false) approver = 0;
        if(approver == true) approver = 1;
        this.api.CallAPI("workflows", "createStepEmail", {WorkflowId:workFlowId, StepId:stepId, UserId:userId, Mandatory:mandatory, Approver:approver  }, function(){
            var returnData = jsonDecode(this.responseText);
            callBackFunc(returnData);
        });
    } else {
        this._showErrorDialogue("no workFlowId set (WorkFlow CreateStepEmail method)");
    }
};

WorkFlow.prototype.UpdateMandatory = function(stepId, userId, mandatory, callBackFunc) {
    if (isset(stepId)) {
        if(mandatory == false) mandatory = 0;
        if(mandatory == true) mandatory = 1;
        this.api.CallAPI("workflows", "mandatory", {StepId:stepId, UserId:userId, Mandatory:mandatory}, function(){
            var returnData = jsonDecode(this.responseText);
            callBackFunc(returnData);
        });
    } else {
        this._showErrorDialogue("no workFlowId set (WorkFlow CreateStepEmail method)");
    }
};

WorkFlow.prototype.UpdateApprover = function(stepId, userId, approver, callBackFunc) {
    if (isset(stepId)) {
        if(approver == false) approver = 0;
        if(approver == true) approver = 1;
        this.api.CallAPI("workflows", "approver", {StepId:stepId, UserId:userId, Approver:approver}, function(){
            var returnData = jsonDecode(this.responseText);
            callBackFunc(returnData);
        });
    } else {
        this._showErrorDialogue("no workFlowId set (WorkFlow CreateStepEmail method)");
    }
};

WorkFlow.prototype.CreateStep = function(workFlowId, title, description, position, callBackFunc) {
    if (isset(workFlowId)) {
        this.api.CallAPI("workflows", "createStep", {WorkflowId:workFlowId, Title:stripTags(title), Description:stripTags(description), Position:position }, function(){
            var returnData = jsonDecode(this.responseText);
            callBackFunc(returnData);
        });
    } else {
        this._showErrorDialogue("no workFlowId set (WorkFlow CreateStep method)");
    }
};

WorkFlow.prototype.CreateStepSpecial = function(workFlowId, title, description, callBackFunc) {
    if (isset(workFlowId)) {
        this.api.CallAPI("workflows", "createSpecialStep", {WorkflowId:workFlowId, Title:stripTags(title), Description:stripTags(description) }, function(){
            var returnData = jsonDecode(this.responseText);
            callBackFunc(returnData);
        });
    } else {
        this._showErrorDialogue("no workFlowId set (WorkFlow CreateStep method)");
    }
};

WorkFlow.prototype.SetWorkflowId = function(workflowId){
    this.application.storage.set('workflowId', workflowId);
};

WorkFlow.prototype.Delete = function(workflowId, callBackFunc) { //deletes and adds new email address
    if (isset(workflowId)) {
        this.api.CallAPI("workflows", "delete", {WorkflowId:workflowId}, function(){
            var returnData = jsonDecode(this.responseText);
            if(isFunc(callBackFunc)) callBackFunc(returnData);
        });
    }
};

/**
 * Moves a user from one step to another.
 *
 * @param {String} userId
 * @param {String} fromStepId
 * @param {String} toStepId
 * @param {Function} callback
 */
WorkFlow.prototype.MoveUser = function(userId, fromStepId, toStepId, callback) {
    if (typeof userId !== 'string' || typeof fromStepId !== 'string' || typeof toStepId !== 'string') {
        throw new Error('WorkFlow.MoveUser expects a "userId", "fromStepId" and "toStepId".');
    }

    if (typeof callback !== 'function') {
        throw new Error.WithMetaData('WorkFlow.MoveUser was invoked without a valid callback.', {
            userId: userId, fromStepId: fromStepId, toStepId: toStepId,
            callbackType: typeof callback
        });
    }

    this.api.fetch(
        'workflows',
        'moveUserStep',
        {
            UserId: userId,
            FromStepId: fromStepId,
            ToStepId: toStepId
        },
        function (err, response) {
            callback(err, response);
        }
    );
};

WorkFlow.prototype.Update = function(workFlowId, updateItems, callBackFunc) {
    if(empty(workFlowId)){
        this._showErrorDialogue("no workFlowId set (WorkFlow Update method)");
        return "";
    }
    updateItems.WorkflowId = workFlowId;
    this.api.CallAPI("workflows", "update", updateItems, callBackFunc);
};

WorkFlow.prototype.GetStep = function(stepId, callBackFunc) {
    if (isset(stepId)) {
        this.api.CallAPI("workflows", "getStepById", stepId, callBackFunc);
    } else {
        this._showErrorDialogue("no stepId set (WorkFlow GetStep method)");
    }
};

WorkFlow.prototype.UpdateStep = function(stepId, title, description, position, callBackFunc) {
    if (isset(stepId)) {
        var updateObj = {};
        if(title == ""){
            updateObj.Title = "+";
        }else{
            if(isset(title)) {
                updateObj.Title = stripTags(title);
            }
        }
        if(isset(description)){
            updateObj.Description = stripTags(description);
        }
        if(isset(position)){
            updateObj.Position = position;
        }
        updateObj.StepId = stepId;
        if (isset(updateObj)) {
            this.api.CallAPI("workflows", "updateStep", updateObj, callBackFunc);
        } else {
            this._showErrorDialogue("empty updateObj (WorkFlow UpdateStep method)");
        }
    } else {
        this._showErrorDialogue("no stepId set (WorkFlow UpdateStep method)");
    }
};

WorkFlow.prototype.FinishedProofing = function(stepId, userId, callBackFunc) {
    if (isset(stepId)) {
        var updateObj = {};
        updateObj.StepId = stepId;
        updateObj.UserId = userId;
        if (isset(updateObj)) {
            this.api.CallAPI("workflows", "complete", updateObj, function(){
                var returnData = jsonDecode(this.responseText);
                callBackFunc(returnData);
            });
        } else {
            this._showErrorDialogue("empty updateObj");
        }
    } else {
        this._showErrorDialogue("no stepId set");
    }
};

WorkFlow.prototype.FinishedProofingApprover = function(stepId, userId, callBackFunc) {
    if (isset(stepId)) {
        var updateObj = {};
        updateObj.StepId = stepId;
        updateObj.UserId = userId;
        if (isset(updateObj)) {
            this.api.CallAPI("workflows", "approverSendTodos", updateObj, function(){
                var returnData = jsonDecode(this.responseText);
                callBackFunc(returnData);
            });
        } else {
            throw new Error("empty update object");
        }
    } else {
        throw new Error("no stepId");
    }
};

WorkFlow.prototype.FinishProofingSpecial = function(stepId, userId, callBackFunc) {
    if (isset(stepId)) {
        var updateObj = {};
        updateObj.StepId = stepId;
        updateObj.UserId = userId;
        if (isset(updateObj)) {
            this.api.CallAPI("workflows", "completeSpecial", updateObj, function(){
                var returnData = jsonDecode(this.responseText);
                callBackFunc(returnData);
            });
        } else {
            throw new Error("empty updateObj");
        }
    } else {
        throw new Error("no stepId set");
    }
};



WorkFlow.prototype.FinishedProofingNonMandatory = function(stepId, userId, callBackFunc) {
    if (isset(stepId)) {
        var updateObj = {};
        updateObj.StepId = stepId;
        updateObj.UserId = userId;
        if (isset(updateObj)) {
            this.api.CallAPI("workflows", "completeNonMandatory", updateObj, function(){
                var returnData = jsonDecode(this.responseText);
                callBackFunc(returnData);
            });
        } else {
            throw new Error("empty updateObj");
        }
    } else {
        throw new Error("no stepId set");
    }
};

//completeNonMandatory

WorkFlow.prototype.SetFirstStepVisible = function(stepId, callBackFunc) {

    if (isset(stepId)) {
        var updateObj = {};
        updateObj.StepId = stepId;
        updateObj.Visible = 1;
        if (isset(updateObj)) {
            this.api.CallAPI("workflows", "updateStep", updateObj, callBackFunc);
        } else {
            this._showErrorDialogue("empty updateObj (WorkFlow UpdateStep method)");
        }
    } else {
        this._showErrorDialogue("no stepId set (WorkFlow UpdateStep method)");
    }
};

WorkFlow.prototype.UserComplete = function(stepId, userId, callBackFunc) {
    if (isset(stepId)) {
        var updateObj = {};
        updateObj.StepId = stepId;
        updateObj.UserId = userId;
        if (isset(updateObj)) {
            this.api.CallAPI("workflows", "complete", updateObj, function(){
                var returnData = jsonDecode(this.responseText);
                callBackFunc(returnData);
            });
        } else {
            this._showErrorDialogue("empty updateObj (WorkFlow UpdateStep method)");
        }
    }
};

WorkFlow.prototype.UserSkip = function(stepId, userId, callBackFunc) {
    if (isset(stepId)) {
        var updateObj = {};
        updateObj.StepId = stepId;
        updateObj.UserId = userId;
        if (isset(updateObj)) {
            this.api.CallAPI("workflows", "skip", updateObj, function(){
                var returnData = jsonDecode(this.responseText);
                callBackFunc(returnData);
            });
        } else {
            this._showErrorDialogue("empty updateObj (WorkFlow UpdateStep method)");
        }
    }
};

WorkFlow.prototype.UserNudge = function(stepId, userId, callBackFunc) {
    if (isset(stepId)) {
        var updateObj = {};
        updateObj.StepId = stepId;
        updateObj.UserId = userId;
        if (isset(updateObj)) {
            this.api.CallAPI("workflows", "nudge", updateObj, function(){
                var returnData = jsonDecode(this.responseText);
                callBackFunc(returnData);
            });
        } else {
            this._showErrorDialogue("empty updateObj (WorkFlow UpdateStep method)");
        }
    }
};

WorkFlow.prototype.DeleteStep = function(stepId, callBackFunc) {
    if (isset(stepId)) {
        this.api.CallAPI("workflows", "deleteStep", {StepId:stepId}, callBackFunc);
    } else {
        this._showErrorDialogue("no stepId set (WorkFlow DeleteStep method)");
    }
};

WorkFlow.prototype.DeleteStepEmail = function(userId, stepId, callBackFunc) {
    if (isset(userId) && isset(stepId)) {
        this.api.CallAPI("workflows", "deleteStepEmail", {UserId:userId, StepId:stepId}, callBackFunc);
    } else {
        this._showErrorDialogue("empty params (WorkFlow DeleteStepEmail method). userId: " + userId + ", stepId " + stepId);
    }
};

WorkFlow.prototype.UpdateEmailStep = function(workFlowId, stepId, userIdToDelete, newEmailAddress, callBackFunc) { //deletes and adds new email address
    var self = this;
    if (isset(stepId) && isset(userIdToDelete) && isset(newEmailAddress)) {
        this.api.CallAPI("workflows", "deleteStepEmail", {StepId:stepId, UserId:userIdToDelete}, function(){
            var returnData = jsonDecode(this.responseText);
            if(isset(returnData["StepId"])){
                self.CreateStepEmail(workFlowId, stepId, newEmailAddress, callBackFunc);
            }
        });
    } else {
        this._showErrorDialogue("empty params (WorkFlow UpdateEmailStep method). stepId: " + stepId + ", userIdToDelete " + userIdToDelete + ", newEmailAddress " + newEmailAddress);
    }
};

WorkFlow.prototype.Share = function(workflowId, userId, callBackFunc) { //deletes and adds new email address

    if (isset(workflowId) && isset(userId)) {
        this.api.CallAPI("workflows", "addToShareOnWorkflow", {WorkflowId:workflowId, UserId:userId}, function(){
            var returnData = jsonDecode(this.responseText);
            if(isFunc(callBackFunc)) callBackFunc(returnData);
        });
    }
};

WorkFlow.prototype.DeleteShared = function(workflowId, userId, callBackFunc) { //deletes and adds new email address
    if (isset(workflowId) && isset(userId)) {
        this.api.CallAPI("workflows", "deleteSharedFromWorkflow", {WorkflowId:workflowId, UserId:userId}, function(){
            var returnData = jsonDecode(this.responseText);
            if(isFunc(callBackFunc)) callBackFunc(returnData);
        });
    }
};


WorkFlow.prototype.GetAllWorkFlows = function(type, callBackFunc) {
    if (this.application.storage.has('delete_workflow_id')) {
        var storage = this.application.storage;
        this.Delete(storage.get('delete_workflow_id'), function() {
            storage.remove('delete_workflow_id');
        });
    };
    if(empty(type)) type = "owned";
    if(type == "owned"){
        this.api.CallAPI("workflows", "getAll", "", callBackFunc);
    }else{
        this.api.CallAPI("workflows", "getAllShared", "", callBackFunc);
    }
};

WorkFlow.prototype.Load = function(workFlowId, callBackFunc, detailed) {
    if (typeof detailed === 'undefined') detailed = true;
    if (isset(workFlowId)) {
        this.api.CallAPI("workflows", detailed ? 'getDetailedById' : 'getById', workFlowId, callBackFunc);
    } else {
        throw new Error("no workFlowId set (WorkFlow Load method)");
    }
};

//workflow admin methods

WorkFlow.prototype.AddAdmin = function(workFlowId, email, type, callBackFunc) {
    if (isset(workFlowId)) {

        var user = new Users(this.application);
        var self = this;

        var permData = {};

        if(inArray(this.allowedWorkflowPermissionTypes, type)){
            permData[type] = 1;
        }

        user.SearchUser(email, function(){
            var returnData = jsonDecode(this.responseText);
            if(returnData.length > 0){
                var userId = returnData[0]["UserId"];
                permData.RelatedId = workFlowId;
                permData.UserId = userId;
                self.team.Add(permData, function(response){
                    callBackFunc(response, userId);
                });
            }else{
                user.CreateUser(email, function(){
                    var returnData = jsonDecode(this.responseText);
                    if(returnData["UserId"]){
                        permData.RelatedId = workFlowId;
                        permData.UserId = returnData["UserId"];
                        self.team.Add(permData, function(response){
                            callBackFunc(response, returnData["UserId"]);
                        });
                    }
                });
            }
        });

    } else {
        throw new Error("no workFlowId");
    }
};

WorkFlow.prototype.AddAdminMany = function(workFlowId, emails, type, callBackFunc) {
    if (isset(workFlowId) && isset(emails)) {

        var self = this;

        var permData = {};

        if(inArray(this.allowedWorkflowPermissionTypes, type)){
            permData[type] = 1;
        }

        permData.AddManyByEmails = emails;
        permData.RelatedId = workFlowId;
        self.team.AddMany(permData, function(response){
            callBackFunc(response);
        });
    } else {
        throw new Error("no workFlowId, or no emails");
    }
};

WorkFlow.prototype.UpdateAdmin = function(memberId, permissions, callBackFunc) {
    if (isset(memberId)) {
        this.team.Update(memberId, permissions, function(response){
            callBackFunc(response);
        });
    } else {
        throw new Error("no memberId");
    }
};

WorkFlow.prototype.RemoveAdmin = function(memberId, type, callBackFunc) {
    if (isset(memberId) && isset(type)) {

        var permData = "";

        if(inArray(this.allowedWorkflowPermissionTypes, type)){
            permData = type
        }

        this.team.Delete(memberId, permData, function(response){
            callBackFunc(response);
        });
    } else {
        throw new Error("no memberId or type");
    }
};

WorkFlow.prototype.GetAllAdmins = function(workFlowId, callBackFunc) {
    if (isset(workFlowId)) {
        this.team.GetList(workFlowId, function(response){
            callBackFunc(response);
        });
    } else {
        throw new Error("no workFlowId");
    }
};

WorkFlow.prototype.GetAdmin = function(workFlowId, userId, callBackFunc) {
    if (isset(workFlowId)) {
        this.team.GetList(workFlowId, function(response){
            callBackFunc(response);
        });
    } else {
        throw new Error("no workFlowId");
    }
};
