/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
function DropRegionDirective($rootScope, $parse, eventService) {
    let dropping = false;

    const $global = angular.element(document.documentElement);

    $global
        .on('dragenter', () => {
            const previousDropping = dropping;
            dropping = true;
            if (!previousDropping && !$rootScope.$$phase) {
                $rootScope.$apply();
                // console.debug('dragenter');
            }
            $global.addClass('global-dropping');
        })
        .on('dragleave', ({originalEvent: event}) => {
            if (dropping && !event.clientX && !event.clientY) {
                dropping = false;
                if (!$rootScope.$$phase) {
                    $rootScope.$apply();
                    // console.debug('dragleave');
                }
                $global.removeClass('global-dropping');
            }
        })
        .on('drop', () => {
            if (dropping) {
                dropping = false;
                if (!$rootScope.$$phase) {
                    $rootScope.$apply();
                    // console.debug('drop');
                }
                $global.removeClass('global-dropping');
            }
        });

    return {
        link(scope, element, attr) {
            let onDrop = angular.noop;

            if (attr.onDrop) {
                // Bind the scope value so it doesn't need to be passed through each time
                onDrop = $parse(attr.onDrop).bind(null, scope);
            }

            // eventService.on(element, 'dragenter dragleave', (event) => {
            //     event.originalEvent.dataTransfer.dropEffect = 'link';
            //     console.debug('event', event);
            // });

            eventService.on(element, 'dragover drop', (event) => {
                event.preventDefault();
            });

            // scope.$on('$destroy', eventService.on(window, 'dragenter', (event) => {
            //     console.debug(event);
            // }));

            // document.body.addEventListener('dragenter', (event) => {
            //     console.debug(1, event);
            // }, false);

            // document.body.addEventListener('dragleave', (event) => {
            //     console.debug(2, event);
            // }, false);
        }
    };
}

app.directive('dropRegion', DropRegionDirective);