/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
// Future developer, you're probably here because you realised there was a bug that causes elements "off-screen" to
// create a tooltip which is wrongly positioned. I anticipated this, it's related to `getBoundingClientRect` on the
// target element. We should probably use `offset` instead (jQuery function).

const BOUNDARY_OFFSET = 20;
const TARGET_OFFSET = 5;
const HEADER_FOOTER = 200;

function makeTooltip(x, y, target, text, hDirection, vDirection, enableHtml, style, tooltipDelay, timeout, enableMobile) {
    let destroyed = false;

    // let {width: maxWidth, height: maxHeight} = document.body.getBoundingClientRect();
    const maxWidth = document.documentElement.clientWidth - BOUNDARY_OFFSET;
    const maxHeight = document.documentElement.clientHeight - BOUNDARY_OFFSET;

    let targetHeight, targetWidth, targetX, targetY;

    if (target) {
        const dimensions = target.getBoundingClientRect();
        targetHeight = dimensions.height;
        targetWidth = dimensions.width;
        targetX = dimensions.left;
        targetY = dimensions.top;
    } else {
        targetHeight = 0;
        targetWidth = 0;
        targetX = x;
        targetY = y;
    }

    if (!style) {
        style = 'default';
    }

    if (tooltipDelay == null && tooltipDelay !== false) {
        tooltipDelay = 600;
    }

    if (timeout == null && timeout !== false) {
        timeout = 10000;
    }

    const tooltip = document.createElement('div');
    tooltip.className = `app__tooltip app__tooltip--${style} ${enableMobile ? 'app__tooltip--enable-mobile' : ''}`;
    tooltip[enableHtml ? 'innerHTML' : 'textContent'] = text;

    if (style !== 'comment') {
        document.body.appendChild(tooltip);
    }

    let tooltipX, tooltipY, tooltipWidth, tooltipHeight;
    let tooltipAttr = [];

    const defaultBoundingClientRect = { top: 0, right: 0, bottom: 0, left: 0, width: 0, height: 0 }; //in IE if tries to call getBoundingClientRect on not added element, throws an error
    let tooltipDimensions;
    try {
        tooltipDimensions = tooltip.getBoundingClientRect();
    } catch (e) {
        tooltipDimensions = defaultBoundingClientRect;
    }
    tooltipWidth = (style === 'comment') ? 400 : tooltipDimensions.width;
    tooltipHeight = (style === 'comment') ? 150 : tooltipDimensions.height;

    const goLeft = targetX + tooltipWidth > maxWidth || (hDirection && hDirection === 'left');
    const goCenter = hDirection && hDirection === 'center';
    const goUp = targetY + targetHeight + TARGET_OFFSET > maxHeight || (vDirection && vDirection === 'up');

    if (goLeft) {
        // GO LEFT
        tooltipAttr.push('left');
        tooltipX = (targetX + targetWidth) - tooltipWidth;
    } else if (goCenter) {
        // CENTER
        tooltipAttr.push('center');
        tooltipX = targetX + (targetWidth / 2) - (tooltipWidth / 2);
    } else {
        // GO RIGHT
        tooltipAttr.push('right');
        tooltipX = targetX;
    }

    if (goUp) {
        // GO UP
        tooltipAttr.push('up');
        tooltipY = targetY - tooltipHeight;
        tooltipY -= TARGET_OFFSET;
    } else {
        // GO DOWN
        tooltipAttr.push('down');
        tooltipY = targetY + targetHeight;
        tooltipY += TARGET_OFFSET;
    }

    // console.log(tooltipX, tooltipY, tooltipWidth, tooltipHeight);
    // console.log(tooltipX, tooltipY);

    const maxX = maxWidth - tooltipWidth;
    const minX = BOUNDARY_OFFSET;

    tooltip.style.left = Math.max(minX, Math.min(maxX, tooltipX)) + 'px';
    tooltip.style.top = tooltipY + 'px';
    tooltip.className += ' ' + tooltipAttr.map((attr) => 'app__tooltip--' + attr).join(' ');
    
    if (style === 'comment') {
        tooltip.style.maxHeight = maxHeight - HEADER_FOOTER - y + 'px';
        return tooltip;
    }

    tooltip.className += ' app__tooltip--ready';
    setTimeout(() => tooltip.className += ' app__tooltip--show', tooltipDelay);

    function destroy() {
        if (!destroy.destroyed) {
            destroy.destroyed = true;
            tooltip.className = tooltip.className.replace('app__tooltip--show', '') + ' app__tooltip--hide';
            setTimeout(() => document.body.removeChild(tooltip), 250);
        }
    }

    if (timeout) {
        setTimeout(destroy, timeout);
    }

    destroy.element = tooltip;
    destroy.destroyed = false;

    return destroy;
}

function TooltipDirective(eventService) {
    let currentTooltip;

    angular.element(document.body).on('mousewheel click keydown', () => {
        if (currentTooltip) currentTooltip();
    });

    return {
        link(scope, element, attr) {
            let localTooltip;
            const bindings = [];

            bindings.push(
                eventService.on(element, 'mouseenter touchstart', () => {
                    if (currentTooltip) currentTooltip();

                    if (attr.tooltip) {
                        const vDirection = String(attr.tooltipDirection).match(/(up|down)/i);
                        const hDirection = String(attr.tooltipDirection).match(/(left|right|center)/i);
                        currentTooltip = localTooltip = makeTooltip(
                            null,
                            null,
                            element[0],
                            attr.tooltip,
                            hDirection && hDirection[1],
                            vDirection && vDirection[1],
                            attr.tooltipEnableHtml,
                            attr.tooltipStyle,
                            attr.tooltipDelay,
                            null,
                            attr.tooltipEnableMobile
                        );
                    }
                }), 
                eventService.on(element, 'mouseleave touchend', () => {
                    if (localTooltip) localTooltip();
                }),
                () => {
                    if (localTooltip) localTooltip();
                }
            );

            scope.$on('$destroy', () => {
                bindings.forEach(binding => binding());
            });
        }
    };
}

const tooltipService = window.$$tooltip = {
    create: makeTooltip,
};

app.directive('tooltip', TooltipDirective);
app.service('tooltipService', () => tooltipService);

