/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const DEFAULT_EXPIRY_MS = ((1000 * 60) * 60) * 24; // 24 hours

function logErrorAndResolveWith(value) {
    return (err) => {
        console.error(err);
        return value;
    };
}

class TemporaryStorageService {
    constructor($q, dbService, utilService) {
        this.$$ = {$q, dbService, utilService};
        this._deleteExpired();
    }

    _deleteExpired() {
        return this.all(true).then((items) => {
            const deleteKeys = Object.keys(items).filter((key) => {
                const data = items[key];
                return data && data.expiresAt < Date.now();
            });
            return this._delete(deleteKeys);
        });
    }

    get(key) {
        key = this._serialiseKey(key);
        return this.$$.dbService.wrap(({temporary}) => (
            temporary.where('key').equals(key).first().then((result) => {
                return result ? result.value : null;
            })
        )).catch(logErrorAndResolveWith(null));
    }

    _serialiseKey(key) {
        if (typeof key === 'string') {
            return key;
        } else {
            let serialised = '';
            if (Array.isArray(key) && key.length && typeof key[0] === 'string' && key[0][0] === '#') {
                serialised += key[0].substr(1) + ':';
            }
            serialised += this.$$.utilService.md5(JSON.stringify(key));
            return serialised;
        }
    }

    set(key, value, expiresAt = (Date.now() + DEFAULT_EXPIRY_MS)) {
        key = this._serialiseKey(key);
        return this.has(key).then((exists) => (
            exists ? this._update(key, value, expiresAt) : this._add(key, value, expiresAt)
        ));
    }

    _update(key, value, expiresAt) {
        return this.$$.dbService.wrap(({temporary}) => {
            return temporary.update(key, {value, expiresAt}).then(() => null);
        }).catch(logErrorAndResolveWith(null));
    }

    _add(key, value, expiresAt) {
        return this.$$.dbService.wrap(({temporary}) => {
            return temporary.add({key, value, expiresAt}).then(() => null);
        }).catch(logErrorAndResolveWith(null));
    }

    has(key) {
        key = this._serialiseKey(key);
        return this.$$.dbService.wrap(({temporary}) => (
            temporary.where('key').equals(key).first().then((result) => {
                return typeof result !== 'undefined';
            })
        )).catch(logErrorAndResolveWith(false));
    }

    remove(key) {
        key = this._serialiseKey(key);
        return this.$$.dbService.wrap(({temporary}) => (
            temporary.delete(key).then(() => null)
        )).catch(logErrorAndResolveWith(null));
    }

    keys() {
        return this.all().then(items => Object.keys(items));
    }

    all(details = false) {
        return this.$$.dbService.wrap(({temporary}) => {
            let map = {};
            return temporary.each((item) => {
                map[item.key] = details ?
                    {value: item.value, expiresAt: item.expiresAt} : // Metadata included (can scale)
                    item.value; // Just value (for simplicity)
            }).then(() => map);
        }).catch(logErrorAndResolveWith({}));
    }

    _delete(keys) {
        return this.$$.dbService.wrap(({temporary}) => (
            this.$$.$q.all(keys.map((key) => {
                return temporary.delete(key);
            })).then(() => null)
        )).catch(logErrorAndResolveWith(null));
    }

    clear() {
        return this.keys().then((keys) => {
            return this._delete(keys);
        });
    }

    /**
     * Handles the auto-caching of temporary objects if they're not already stored.
     *
     * @param {string|*} key
     * @param {function} handle
     * @param {number} expiresAt
     * @returns {$q<*>}
     */
    auto(key, handle, expiresAt) {
        key = this._serialiseKey(key);
        return this.has(key).then((exists) => {
            if (!exists) {
                return this.$$.$q.when(handle())
                    .then((value) => { this.set(key, value, expiresAt); return value; });
            } else {
                return this.get(key);
            }
        });
    }
}

app.service('temporaryStorageService', TemporaryStorageService);
