/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.factory('PPDashboardProof', function (userService, PPProof, PPProofStatus, PPProofType) {
    class PPDashboardProof extends PPProof {
        /**
         * The state of the dashboard thumbnail.
         *
         * (-1) Unknown
         * (0)  Default state
         * (1)  Pending
         * (2)  Failed
         * (3)  Success
         * (4)  Redistributing
         * (5)  Queued for distribution
         *
         * @type {Number}
         */
        $$thumbnailState = 0;

        /**
         * @constructor
         */
        constructor() {
            super();
        }
        /**
         * Returns whether the proof has a thumbnail.
         *
         * Checks the file status to see whether the rip was successful.
         *
         * @returns {Boolean}
         */
        get hasThumbnail () {
            return this.fileStatus === 'OK';
        }

        /**
         * Whether or not the proof is currently in a loading state.
         *
         * @returns {Boolean}
         */
        get isLoading () {
            return this.$$thumbnailState === 1 || this.$$thumbnailState === 5;
        }

        /**
         * Whether the proof's percentage is positive.
         *
         * @returns {Boolean}
         */
        get hasProgress () {
            return this.progressPercent >= 0;
        }

        /**
         * Returns the progress percentage (decimal).
         *
         * @returns {Number}
         */
        get progressPercent () {
            return this.getProgressPercent();
        }

        /**
         * Gets the error state of the proof.
         *
         * @returns {String|undefined}
         */
        get $$state () {
            if (this.hasFileError) {
                return 'file-error';
            } else if (this.hasProgress) {
                return 'processing';
            } else if (this.proofType === PPProofType.BRIEF) {
                switch (this.status) {
                    case PPProofStatus.NEW:
                        return 'brief-setup-needed';
                    case PPProofStatus.PROOFING:
                        if (userService.matches(this.ownerId)) {
                            return 'brief-sent-awaiting-proof';
                        } else if (userService.matches(this.editorId) || userService.matches(this.recipient)) {
                            return (this.isOverdue) ? 'overdue' : 'dueDate';
                        }
                    case PPProofStatus.AWAITING_NEW_VERSION:
                        if (userService.matches(this.ownerId)) {
                            if (!this.isLatestVersion) {
                                return 'brief-sent-awaiting-proof';
                            } else if (this.hasEditors) {
                                return 'brief-with-editor';
                            } else {
                                return 'brief-awaiting-proof';
                            }
                        } else if (userService.matches(this.recipient)) {
                            if (this.hasEditors) {
                                return 'brief-with-editor';
                            } else {
                                return 'brief-upload-proof';
                            }
                        } else if (userService.matches(this.editorId)) {
                            return 'brief-upload-proof';
                        }
                    case PPProofStatus.HAS_NEW_VERSION:
                        return 'has-a-proof';
                    case PPProofStatus.CLOSED:
                        return 'archived';
                }
            } else if (this.status === PPProofStatus.CLOSED && this.hasApproved) {
                return 'approved-and-archived';
            } else if (this.status === PPProofStatus.CLOSED) {
                return 'archived';
            } else if ( ! this.hasWorkflow) {
                return 'needs-proofers';
            } else if (this.status === PPProofStatus.CHANGES_REQUESTED) {
                return 'changes-requested';
            } else if (this.status === PPProofStatus.AWAITING_NEW_VERSION) {
                return 'awaiting-new-version';
            } else if (this.status === PPProofStatus.HAS_NEW_VERSION) {
                return 'has-new-version';
            } else if (this.status === PPProofStatus.APPROVED) {
                return 'approved';
            } else if ( ! this.hasWorkflowStarted) {
                if (this.groupId) {
                    return 'awaiting-collection';
                }
                return 'not-started';
            } else if (this.isOverdue) {
                return 'overdue';
            }
        }

        /**
         * Whether the proof should have an uploader displayed ontop.
         *
         * @returns {Boolean}
         */
        get hasUploader () {
            return (
                // Has a file error (show loader)
                this.hasFileError ||

                // Awaiting a new version (and user is owner, or one of the co-owners)
                ((this.$$state === 'awaiting-new-version' || this.$$state === 'brief-upload-proof') &&
                    (userService.matches(...this.ownerIds) ||
                     userService.matches(...this.coOwnerIds) ||
                     userService.matches(this.recipient) ||
                     userService.matches(...this.editorIds)))
            );
        }

        /**
         * Whether the logged in user owns the proof
         *
         * @returns {Boolean}
         */
        get isOwnerOrCoOwner () {
            return userService.matches(...this.ownerIds);
        }

        /**
         * Whether the dashboard should load the proof's thumbnail.
         *
         * @returns {Boolean}
         */
        get shouldLoadThumbnail () {
            // If the proof has the uploader visible, don't bother loading the thumbnail
            // as the uploader sites on top of where the thumbnail would sit.
            return ! this.hasUploader && this.hasThumbnail;
        }

        /**
         * Whether the proof tile should show the thumbnail.
         *
         * @returns {Boolean}
         */
        get shouldShowThumbnail () {
            return this.shouldLoadThumbnail;
        }

        // 954
        /**
         * Whether the proof workflow has started.
         *
         * @returns {Boolean}
         */
        get hasWorkflowStarted () {
            return this.hasWorkflow && this.status !== PPProofStatus.NEW;
        }

        /**
         * Creates a Proof object from a proof data object.
         *
         * @param {Object} proofData
         * @returns {PPDashboardProof}
         */
        static from (proofData) {
            let proof = new this();
            proof.updateFromProofData(proofData);
            return proof;
        }
    }

    return PPDashboardProof;
});
