/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/*
<ab-test id="example">
  <ab-option a>
    Hello World
  </ab-option>
  <ab-option b>
    Hello Universe
  </ab-option>
</ab-test>
*/

class ABTestController {
    /**
     * The name of the a/b test.
     *
     * @type {String}
     */
    name = null;

    /**
     * The chosen a/b test option.
     *
     * @type {String}
     */
    option = null;

    /**
     * The options available to the a/b test.
     *
     * @type {Object<String, Function>}
     */
    options = {};

    /**
     * @returns {String}
     */
    getRandomOption () {
        // Randomly select an option from the options available
        let ids = Object.keys(this.options);
        return ids[Math.floor(Math.random() * ids.length)];
    }
}

function ABTestDirective (storageService, SegmentIo) {
    return {
        restrict: 'E',
        require: ['abTest'],
        controller: ABTestController,
        controllerAs: 'abTestCtrl',

        /**
         * @param {$rootScope.Scope} scope
         * @param {angular.element} element
         * @param {Object} attr
         * @param {ABTestController} abTestCtrl
         */
        link (scope, element, attr, [abTestCtrl]) {
            let testName = attr.id,
                specifiedOptionId = scope.$eval(attr.option);

            if (testName) {
                let storage = storageService(`pageproof.ab-test.${testName}.`),
                    optionId = specifiedOptionId || storage.get('option') || abTestCtrl.getRandomOption(),
                    optionExists = Object.keys(abTestCtrl.options).indexOf(optionId) !== -1;

                if ( ! optionExists) {
                    optionId = abTestCtrl.getRandomOption();
                }

                storage.set('option', abTestCtrl.option = optionId);

                let transclude = abTestCtrl.options[optionId];

                if (transclude) {
                    transclude(scope, (_element) => {
                        element.after(_element);
                        element.remove();
                    }, element.parent());
                } else {
                    element.remove();
                }

                // If track was turned on
                //  and if the user has not yet notified segment
                //  or the segment notification was different then their previous
                if ('track' in attr && ( ! storage.has('track') || storage.get('track')[0] !== optionId)) {
                    console.log('Track user a/b test - ', testName, optionId);
                    SegmentIo.trackCustom('A/B Test: ' + testName, {
                        option: optionId,
                        specified: !! specifiedOptionId // Specify whether the option was forced/specified
                    });
                    storage.set('track', optionId + Date.now());
                }
            } else {
                console.warn('No :id provided for ab/b test.');
            }
        }
    };
}

function ABOptionDirective () {
    return {
        restrict: 'E',
        require: ['^abTest'],
        transclude: true,

        /**
         * @param {$rootScope.Scope} scope
         * @param {angular.element} element
         * @param {Object} attr
         * @param {ABTestController} abTestCtrl
         * @param {Function} transclude
         */
        link (scope, element, attr, [abTestCtrl], transclude) {
            let optionId = Object.keys(attr).filter((key) => /^[a-zA-Z]$/.test(key))[0];

            if (optionId) {
                abTestCtrl.options[optionId] = transclude;
            } else {
                console.warn('Unable to register <ab-option>.');
            }
        }
    };
}

app
    .directive('abTest', ABTestDirective)
    .directive('abOption', ABOptionDirective);