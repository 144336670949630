/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.factory('PPChecklist', function (PPModel) {
    const PPChecklistTransformer = {
        id: 'Id',
        name: 'Name',
        description: 'Description',
        isRequiredForFinalApproval: 'IsRequiredForFinalApproval',
        shouldOpenOnProofLoad: 'ShouldOpenOnProofLoad',
        templateId: 'TemplateId',
    };

    class PPChecklist extends PPModel {
        /**
         * The id of the checklist.
         *
         * @type {string}
         */
        id;

        /**
         * The name of the checklist.
         *
         * @type {string}
         */
        name;
    
        /**
         * The description of the checklist.
         *
         * @type {string}
         */
        description;
    
        /**
         * Whether the checklist must be completed before final approval can be given.
         *
         * @type {boolean}
         */
        isRequiredForFinalApproval;
    
        /**
         * Whether the checklist should be opened automatically when the proof is loaded.
         *
         * @type {boolean}
         */
        shouldOpenOnProofLoad;
        
    
        /**
         * The id of the checklist template that was used to create the checklist.
         *
         * @type {string}
         */
        templateId;
        
        /**
         * Update from the checklist data.
         *
         * @param {Object} data
         */
        updateFromChecklistData(data) {
            this.transform(data, PPChecklistTransformer);
        }

        /**
         * Create a checklist with a checklist object.
         *
         * @param {Object} data
         */
        static from(data) {
            let checklist = new this();
            checklist.updateFromChecklistData(data)
            return checklist;
        }
    }

    return PPChecklist;
});
