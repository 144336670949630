/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/**
 * Created by preeti on 15/06/17.
 */
const DEFAULT_EXPIRY_MS = ((1000 * 60) * 60) * 24 * 7; // 7 days

class localCommentService {
    constructor(
        $q,
        $rootScope,
        storageService,
        temporaryStorageService,
        PPProofComment,
        PPProofCommentAttachment,
        PPProofCommentSnapshot,
        backgroundService,
        backendService,
        attachmentService
    ) {
        this.$$ = {
            $q,
            $rootScope,
            storageService,
            temporaryStorageService,
            PPProofComment,
            PPProofCommentAttachment,
            PPProofCommentSnapshot,
            backgroundService,
            backendService,
            attachmentService
        };

        this.$$initCommentLocalStorage();
    }

    $$initCommentLocalStorage () {
        this.localStoredComments = this.$$.storageService('pageproof.app.commentQueue.');
        this.commentStorageIndexArr = this.localStoredComments.keys();
    }

    sendCommentCreatedEvent (comment) {
        this.$$.$rootScope.$broadcast('commentCreatedOffline', comment);
    }

    sendAttachmentAddedEvent (comment) {
        this.$$.$rootScope.$broadcast('attachmentAddedOffline', comment);
    }

    sendSnapshotAddedEvent (comment) {
        this.$$.$rootScope.$broadcast('snapshotAddedOffline', comment);
    }

    initOnlineWatcherForComment () {
        this.$$.$rootScope.$watch('online', (isOnline) => {
            if (isOnline) {
                this.handleCommentLocalStorage();
            }
        });
    }

    /**
     * when online, goes through local stored comments array and send them for creation
     */
    handleCommentLocalStorage () {
        this.commentStorageIndexArr.forEach((key) => {
            let commentData = JSON.parse(this.localStoredComments.get(key));
            this.removeCommentFromLocalStorage(key);
            if (commentData) {
                let tobeCreatedComment = new this.$$.PPProofComment();
                tobeCreatedComment.deSerialiseCommentData(commentData);

                let wait = [
                    this.loadAttachmentsData(tobeCreatedComment, key),
                    this.loadSnapshotData(tobeCreatedComment, key),
                ];
                this.$$.$q.all(wait).then(() => {
                    this.sendCommentCreateRequest(tobeCreatedComment);
                });
            }
        });
    }

    sendCommentCreateRequest (tobeCreatedComment) {
        if (tobeCreatedComment.id) {
            this.$updateComment(tobeCreatedComment, true);
        } else {
            this.$createComment(tobeCreatedComment, true);
        }
    }

    loadSnapshotData (tobeCreatedComment, key) {
        return this.$$.$q((resolve) => {
            if (tobeCreatedComment.snapshot && tobeCreatedComment.snapshot.$file) {
                let snapshot = this.$$.PPProofCommentSnapshot.from(tobeCreatedComment);
                snapshot.deSerialiseSnapshotData(tobeCreatedComment.snapshot);
                this.$$.temporaryStorageService.get(['#localSnapshots', key])
                    .then((storedFile) => {
                        this.deleteSnapshotFileFromLocal(key);
                        snapshot.updateFromFile(tobeCreatedComment.snapshot);
                        snapshot.$file = storedFile;
                        tobeCreatedComment.snapshot = snapshot;
                        resolve(true);
                    });
            } else {
                resolve(true);
            }
        });
    }

    loadAttachmentsData (tobeCreatedComment, key) {
        return this.$$.$q((resolve) => {
            if (tobeCreatedComment.attachments.length) {
                const wait = [];
                tobeCreatedComment.attachments.forEach(attachment => {
                    if (!window.isFileId(attachment.id)) {
                        wait.push(this.loadAttachmentFromLocalDB(attachment, tobeCreatedComment, key));
                    }
                });
                this.$$.$q.all(wait).then(() => resolve(true));
            } else {
                resolve(true);
            }
        });
    }

    /**
     * Removes a comment from local storage, when it has been created on getting online
     * @param comment
     */
    removeCommentFromLocalStorage (key) {
        this.localStoredComments.remove(key);
    }

    loadAttachmentFromLocalDB(attachment, comment, key) {
        return this.$$.$q(resolve => {
            let newAttachment = this.$$.PPProofCommentAttachment.from(comment);
            newAttachment.deSerialiseAttachmentData(attachment);
            this.$$.temporaryStorageService.get([`#localAttachments-${attachment.id}`, key])
                .then((storedFile) => {
                    if (storedFile) {
                        this.deleteAttachmentFileFromLocal(key, attachment.id);
                        newAttachment.updateFromFile(storedFile);
                        const changedAttachmentIndex = comment.attachments.indexOf(attachment);
                        comment.attachments.splice(changedAttachmentIndex, 1, newAttachment);
                        resolve(true);
                    } else {
                        resolve(true);
                    }
            });
        });
    }

    /**
     * Delete a locally stored attachment file
     * @param key
     * @param id The attachment random created id, as its' not created yet
     */
    deleteAttachmentFileFromLocal (key, id) {
        this.$$.temporaryStorageService.remove([`#localAttachments-${id}`, key]);
    }

    /**
     * delete a locally stored snapshot file
     * @param key
     */
    deleteSnapshotFileFromLocal (key) {
        this.$$.temporaryStorageService.remove(['#localSnapshots', key]);
    }

    /**
     * stores a comment into local storage, when user is offline
     * @param comment
     */
    storeCommentInLocalStorage (comment) {
        let randomKey = (comment.creationToken) ? comment.creationToken : randomString(32, 'aA#') + '-' + Date.now();
        let isNew = this.isNewComment(comment);
        if (comment.creationToken || isNew) {
            comment.creationToken = randomKey;
            comment.serialiseCommentData().then((serialisedComment) => {
                this.storeAttachmentFilesLocally(comment, randomKey);
                this.storeSnapshotFileLocally(comment, randomKey);
                this.localStoredComments.set(randomKey, JSON.stringify(serialisedComment));
                if (isNew) {
                    this.commentStorageIndexArr.push(''+ randomKey);
                }
            });
        }
    }

    isNewComment (comment) {
        return !this.commentStorageIndexArr.some((index) => {
            let storedComment = JSON.parse(this.localStoredComments.get(index));
            return (storedComment && (comment.encryptedComment === storedComment.encryptedComment) &&
            (comment.proofId === storedComment.proofId) &&
            (comment.ownerId === storedComment.ownerId));
        });
    }

    storeAttachmentFilesLocally(comment, key) {
        if (comment.attachments.length) {
            // Storing locally only new attachments or retry ones, not already uploaded ones
            comment.attachments.forEach(attachment => {
                if (!window.isFileId(attachment.id)) {
                    attachment.id = randomString(32, 'aA#') + '-' + Date.now();
                    this.$$.temporaryStorageService.set([`#localAttachments-${attachment.id}`, key], attachment.$file);
                }
            });
        }
    }

    storeSnapshotFileLocally (comment, key) {
        if (comment.snapshot && comment.snapshot.$file) {
            this.$$.temporaryStorageService.set(['#localSnapshots', key], comment.snapshot.$file);
        }
    }

    /**
     * does the things which suppose to be done after a create/reply creation
     * @param comment
     */
    handleAfterCommentCreationProcesses(comment) {
        if (comment.attachments.length) {
            this.$uploadAndAssignAttachments(comment, comment.attachments).then((data) => {
                this.sendAttachmentAddedEvent(comment);
            });
        }

        if (comment.snapshot && comment.snapshot.$file) {
            this.$uploadAndAssignSnapshot(comment, comment.snapshot).then((data) => {
                comment.snapshot.status = data.statusText;
                this.sendSnapshotAddedEvent(comment);
            });
        }
    }

    getMentionIds(text) {
        return window.__pageproof_quark__.sdk.util.comments.mentions(text);
    }

    /**
     * Sends a proof comment (or comment reply) create request to the API.
     *
     * @param {PPProofComment} comment
     * @returns {$q}
     */
    $createComment (comment, isRetry = false) {
        let done = this.$$.backgroundService.create('Create comment');
        comment.creationToken = (comment.creationToken) ? comment.creationToken : randomString(32, 'aA#') + '-' + Date.now();
        const primaryPin = comment.pins.length ? comment.pins[0] : undefined;
        return (
            this.$$
                .backendService
                .fetch('proof.comment.create', {
                    proofId: comment.proofId,
                    page: comment.pageNumber,
                    envelope: comment.envelope,
                    encryptedComment: comment.encryptedComment,
                    xy: window.__pageproof_quark__.sdk.util.pins.serialize(comment.pins),
                    parent: comment.parentId,
                    mentions: this.getMentionIds(comment.comment || comment.decryptedComment),
                    metadata: comment.getMetadataJSON(),
                    creationToken: comment.creationToken,
                    change: comment.isTodo ? 1 : null,
                    boxFillImage: (primaryPin && primaryPin.fill) ? primaryPin.fill.image : undefined,
                    boxFillType: (primaryPin && primaryPin.fill) ? primaryPin.fill.type : undefined,
                    private: comment.isPrivate ? 1 : null,
                })
                .data()
                .then((commentData) => {
                    comment.updateFromProofCommentData(commentData);
                    if (isRetry) {
                        this.sendCommentCreatedEvent(comment);
                        this.handleAfterCommentCreationProcesses(comment);
                    }
                })
                .catch((error) => {
                    comment.isSaved = false;
                    if (error.data.message === 'Parent comment does not exist.') {
                        comment.haveNoParentComment = true;
                    } else if (comment) {
                        this.storeCommentInLocalStorage(comment);
                    }
                    return error.data;
                })
                .finally(done)
        );
    }

    /**
     * Sends a proof comment update request to the API.
     *
     * @param {PPProofComment} comment
     * @returns {$q}
     */
    $updateComment (comment, isRetry = false) {
        let done = this.$$.backgroundService.create('Update comment');
        return (
            this.$$
                .backendService
                .fetch('proof.comment.update', {
                    proofId: comment.proofId,
                    commentId: comment.id,
                    page: comment.pageNumber,
                    envelope: comment.envelope,
                    encryptedComment: comment.encryptedComment,
                    xy: window.__pageproof_quark__.sdk.util.pins.serialize(comment.pins),
                    mentions: this.getMentionIds(comment.comment || comment.decryptedComment),
                })
                .data()
                .then((commentData) => {
                    if (isRetry) {
                        this.sendCommentCreatedEvent(comment);
                        this.handleAfterCommentCreationProcesses(comment);
                    } else {
                        comment.updateFromProofCommentData(commentData);
                    }
                }).catch((error) => {
                    comment.isSaved = false;
                    if (comment) {
                        this.storeCommentInLocalStorage(comment);
                    }
                    return error.data;
                })
                .finally(done)
        );
    }

    /**
     * Uploads and assign attachments to a comment.
     *
     * @param {PPProofComment} comment
     * @param {Array<PPProofCommentAttachment>} attachments
     * @returns {$q}
     */
    $uploadAndAssignAttachments(comment, attachments) {
        // Filtering new attachments from all attachments before proceeding to actually call backend to upload them.
        const newAttachments = attachments.filter(attachment => !window.isFileId(attachment.id));
        return this.$uploadAttachments(comment, newAttachments);
    }

    /**
     * Uploads and assign a snapshot to a comment.
     *
     * @param {PPProofComment} comment
     * @param {PPProofCommentSnapshot} snapshot
     * @returns {$q}
     */
    $uploadAndAssignSnapshot (comment, snapshot) {
        return this.$uploadSnapshot(comment, snapshot)
            .then(() => this.$assignSnapshot(comment, snapshot));
    }

    /**
     * Encrypts and uploads a snapshot.
     *
     * @param {PPProofComment} comment
     * @param {PPProofCommentSnapshot} snapshot
     */
    $uploadSnapshot (comment, snapshot) {
        return this.$uploadAttachments(comment, [snapshot]); // Pass as an array, as we only allow single snapshot per comment
    }

    /**
     * Encrypts and uploads attachments.
     *
     * @param {PPProofComment} comment
     * @param {Array<PPProofCommentAttachment>} attachments
     * @returns {$q<PPProofCommentAttachment>}
     */
    $uploadAttachments(comment, attachments) {
        let done = this.$$.backgroundService.create('Upload attachment');

        // Set the progress to zero (so everything related to showing the progress renders)
        return (
            this.$$
                .attachmentService
                .upload(comment, attachments)
                .finally(done)
        );
    }

    /**
     * Assigns a snapshot to a comment.
     *
     * @param {PPProofComment} comment
     * @param {PPProofCommentSnapshot} snapshot
     * @returns {$q}
     */
    $assignSnapshot (comment, snapshot) {
        const done = this.$$.backgroundService.create('Assign snapshot');

        return (
            this.$$
                .backendService
                .fetch('proof.comment.snapshot', {
                    commentId: comment.id,
                    snapshot: snapshot.id,
                })
                .promise()
                .finally(done)
        );
    }
}

app.service('localCommentService', localCommentService);
