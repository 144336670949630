app.factory('PPIntegrationReference', function (PPModel) {
    const PPIntegrationReferenceTransformer = {
        referenceId: 'ReferenceId',
        referenceType: 'ReferenceType',
        referenceUrl: 'ReferenceUrl',
        referenceLabel: 'ReferenceLabel',
        metadata: 'Metadata',
    };

    class PPIntegrationReference extends PPModel {
        /**
         * The id of the reference (from the system being referenced)
         *
         * @type {string}
         */
        referenceId;

        /**
         * The URL to view the reference on the system being referenced
         *
         * @type {string}
         */
        referenceUrl;

        /**
         * The type of the reference
         *
         * @type {string}
         */
        referenceType;

        /**
         * The label to be displayed for the reference
         *
         * @type {string}
         */
        referenceLabel;

        /**
         * Arbitrary metadata required internally by PageProof services. 
         *
         * @type {string}
         */
        metadata;

        /**
         * Update from the integration reference data.
         *
         * @param {Object} data
         */
        updateFromIntegrationReferenceData(data) {
            this.transform(data, PPIntegrationReferenceTransformer);
        }

        /**
         * Create an integration reference with an integration reference object.
         *
         * @param {Object} data
         */
        static from(data) {
            let integrationReference = new this();
            integrationReference.updateFromIntegrationReferenceData(data)
            return integrationReference;
        }
    }

    return PPIntegrationReference;
});
