/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
let hasOwnProperty = Object.prototype.hasOwnProperty;
let registry = {};

PageProof.Hook = {
    DOWNLOAD_ORIGINAL_FILE: 'download-original-file',
    ALLOW_DOWNLOAD_ORIGINAL_FILE: 'allow-download-original-file',
    ALLOW_PRINT_COMMENTS: 'allow-print-comments',
    ALLOW_INTEGRATION_GOOGLE_DRIVE: 'allow-integration-google-drive',
    ALLOW_INTEGRATION_BOX: 'allow-integration-box',
    ALLOW_INTEGRATION_ONEDRIVE: 'allow-integration-onedrive',
    ALLOW_INTEGRATION_DROPBOX: 'allow-integration-dropbox',
    ALLOW_INTEGRATIONS_PROMOTION: 'allow-integrations-promotion',

    /**
     * Registers a hook for the main application.
     *
     * This allows external ("trusted") code to extend the default functionality of the app, and in some cases
     * disable functionality - when functionality is not compatible.
     *
     * No hooks expose any sensitive information such as private encryption keys or passwords, all hooks remain
     * anonymous and cannot interact with each other - or directly affect the outcome of another.
     *
     * This function returns another function which can be called to de-register the original hook.
     *
     * @example
     *     PageProof.Hook.registerHook(PageProof.Hook.DOWNLOAD_ORIGINAL_FILE, (event, data) => {
     *         event.preventDefault(); // Prevents the UI from downloading file from PageProof
     *     });
     *
     * @param {String} type
     * @param {Function} callback
     * @returns {Function}
     */
    registerHook (type, callback) {
        if ( ! hasOwnProperty.call(registry, type)) {
            registry[type] = [callback];
        } else {
            registry[type].push(callback);
        }

        return () => {
            // Saves the caller from having to create a named function and keeping track of it
            return this.deregisterHook(type, callback);
        };
    },

    /**
     * Removes a specific hook from the registry.
     *
     * If the hook was removed successfully (meaning a hook was actually removed) this function returns `true`,
     * if the hook was previously removed (or if the hook does not exist - same thing) it returns `false`.
     *
     * @param {String} type
     * @param {Function} callback
     * @returns {Boolean}
     */
    deregisterHook (type, callback) {
        if (hasOwnProperty.call(registry, type)) {
            let index = registry[type].indexOf(callback);

            if (index !== -1) {
                registry[type].splice(index, 1);
                return true;
            }
        }
        return false;
    },

    /**
     * Invokes all the registered hooks.
     *
     * The (second) data argument is passed as the second argument to each of the callbacks, after the event
     * objects. The event object allows the callback to prevent the default behaviour of the hook.
     *
     * @example
     *     if (PageProof.Hook.invokeHook(PageProof.Hook.DOWNLOAD_ORIGINAL_FILE)) {
     *         // Do the default action (download the original file)
     *     }
     *
     * @param {String} type
     * @param {*} [data]
     * @returns {Boolean}
     */
    invokeHook (type, data) {
        if (hasOwnProperty.call(registry, type)) {
            // Make sure to get a static copy of the registered callbacks (in-case any of the callbacks
            // actually removes another callback as it's handler.
            let callbacks = registry[type].slice();

            let isDefaultPrevented = false,
                preventDefault = () => isDefaultPrevented = true;

            callbacks.forEach((callback) => {
                let event = { isDefaultPrevented, preventDefault, data };
                let returnValue = callback(event, data);

                if (returnValue === false) {
                    preventDefault();
                }
            });

            return ! isDefaultPrevented;
        }
        return true;
    }
};