/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class BackgroundService {
    onBeforeUnloadTasks = [];

    onBeforeNavigationTasks = [];

    skippingDialogSelected = false;

    /**
     * @param {$q} $q
     * @constructor
     */
    constructor($q, $location, $timeout, $route, modalService, storageService, userService, walkthrough) {
        this.$$ = { $q, $location, $timeout, $route, modalService, storageService, userService, walkthrough };
        this.previousPath = $location.path();
        this.user = this.$$.userService.getUser();

        this.skipDialogProps = {
            selected: false,
            onChange: (bool) => this.onSkipChange(bool),
        };
    }

    /**
     * Create a new task.
     *
     * @param {String} [description]
     * @returns {Function}
     */
    create (description) {
        let deferred = this.$$.$q.defer(),
            task = { description, deferred };

        this.onBeforeUnloadTasks.push(task);

        deferred.promise.then(() => {
            this.onBeforeUnloadTasks.splice(this.onBeforeUnloadTasks.indexOf(task), 1);
        });

        return deferred.resolve;
    }

    createNavigation({title, description, variables}) {
        const deferred = this.$$.$q.defer();
        const task = { title, description, variables, deferred };
        const tabTask = this.create(description);

        this.onBeforeNavigationTasks.push(task);

        deferred.promise.then(() => {
            this.onBeforeNavigationTasks.splice(this.onBeforeNavigationTasks.indexOf(task), 1);
            tabTask();
        });

        return deferred.resolve;
    }

    clearTasks() {
        this.onBeforeNavigationTasks.length = 0;
    }

    ignoreBackgroundTasks() {
        this.ignoreTasks = true;
    }

    onSkipChange = (selected) => {
        const dialogStorage = this.$$.storageService('pageproof.app.proof.' + this.user.id + '.');
        const skippedDialogs = dialogStorage.json('dialogs') || [];

        if (selected) {
            this.skipDialogProps.selected = true;
            if (skippedDialogs.indexOf('navigate-away') === -1) {
                skippedDialogs.push('navigate-away');
            }
        } else {
            const index = skippedDialogs.indexOf('navigate-away');
            this.skipDialogProps.selected = false;
            skippedDialogs.splice(index, 1);
        }

        if (skippedDialogs.length !== 0) {
            dialogStorage.json('dialogs', skippedDialogs);
        } else {
            dialogStorage.remove('dialogs');
        }
    }

    _delegateMiddlewareService() {
        const nextUrl = this.$$.$location.url();
        const nextPath = this.$$.$location.path();
        const getVariables = (variables) => {
            return (variables && Object.keys(variables.owners).length);
        }

        console.debug('backgroundService._delegateMiddlewareService', nextUrl, this.onBeforeNavigationTasks.length);
        if (nextPath !== this.previousPath && this.onBeforeNavigationTasks.length && !this.ignoreTasks && !this.skipDialogProps.selected) {
            const message = this.onBeforeNavigationTasks
                .map(task => task.description)
                .filter(description => (description && description.length))
                .join('\n\n');
            const title = this.onBeforeNavigationTasks
                .map(task => task.title)
                .filter(title => (title && title.length))[0];
            const variables = this.onBeforeNavigationTasks
                .map(task => task.variables)
                .filter(variables => getVariables(variables))[0];
                this.$$.modalService.translate(
                    title || 'navigate-away.default-title',
                    message || 'navigate-away.default-message',
                    [{
                        type: 'warning-outline',
                        text: 'navigate-away.will-do-later',
                        click: () => {
                            this.previousPath = nextPath;
                            this.$$.$location.url(nextUrl);
                        },
                    }, {
                        type: 'text',
                        text: 'navigate-away.will-do-now'
                    }],
                    {
                        skipProps: this.skipDialogProps,
                        variables,
                    }
                );
            // This set timeout is required so that we return false first to prevent navigation
            setTimeout(() => {
                this.$$.walkthrough.dismiss();
            }, 1);
            return false;
        }
        this.ignoreTasks = false;
        this.previousPath = nextPath;
        return true;
    }
}

app
    .service('backgroundService', BackgroundService)
    .run(function ($rootScope, $location, $route, modalService, backgroundService) {
        let onbeforeunload = window.onbeforeunload;

        /**
         * When the user attempts to unload the page (by reloading, or navigating away) - check whether
         * there are any pending tasks in the backgroundService.tasks array - and display a message accordingly.
         *
         * @param {...*} args
         * @returns {String}
         */
        window.onbeforeunload = (...args) => {
            let tasks = backgroundService.onBeforeUnloadTasks;

            if (tasks.length) {
                // There are x tasks waiting to complete...
                return `There ${tasks.length > 1 ? 'are' : 'is'} ${tasks.length} task${tasks.length > 1 ? 's' : ''} pending completion.

${tasks.map((tasks) => '- ' + tasks.description).join('\n')}`;
            }

            // If there was an `onbeforeunload` function registered before, then call it too.
            if (angular.isFunction(onbeforeunload)) {
                return onbeforeunload(...args);
            }
        };
    });
