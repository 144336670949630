/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

'use strict';

/**
 * Domain Admin - Dashboard
 *
 * @author Jacob Marshall <jacob@pageproof.com>
 */
ppxControllers.controller('DomainAdmin_Dashboard',
    function ($scope, $rootScope, $api, $translate, seoService, userService, backendService, browserService, storageService, sdk) {
        $scope.tab = 'active';
        $scope.users = {
            active: [],
            inactive: [],
            notActivated: [],
            invitations: [],
        };
        $scope.vm = {
            search: '',
        };

        var storage = storageService('pageproof.app.dashboard.')

        var USER_CSV_FIELDS = {
            active: ['Name', 'Email', 'Team Administrator', 'Can Log In', 'Account Activated', 'Last Log In'],
            inactive: ['Name', 'Email', 'Team Administrator', 'Can Log In', 'Account Activated', 'Last Log In'],
            notActivated: ['Name', 'Email'],
            invitations: ['Name', 'Email'],
        };

        $scope.selectTab = function(tab) {
            $scope.tab = tab;
            this.updatePageTitle();
        };

        updatePageTitle = function() {
            var title = 'team-dashboard.header';
            if ($scope.tab === 'inviteForm') {
                title = '';
            }
            seoService.set({
                title: 'domain-admin.menu.team',
                pageTitle: title,
                translateTitle: true,
                translatePageTitle: !!title,
            });
        }

        var loggedInUser = userService.getUser();

        getDomain = function(email) {
            return email.slice(email.indexOf('@'));
        }

        toggleView = function() {
            if ($scope.view === 'tile') {
                $scope.view = 'list';
            } else {
                $scope.view = 'tile';
            }
            storage.set('view', $scope.view);
        }

        var navigationProps = this.navigationProps = {
            get selectedTab() {
                if ($scope.tab) {
                    return $scope.tab;
                }
            },
            get view() {
                if ($scope.view) {
                    return $scope.view;
                }
            },
            tabs: [
                {
                    id: 'active',
                    label: 'domain-admin.menu.tabs.active',
                    users: [],
                    isStatic: true,
                    translate: true,
                },
                {
                    id: 'inactive',
                    label: 'domain-admin.menu.tabs.deactivated',
                    users: [],
                    isStatic: true,
                    translate: true,
                },
                {
                    id: 'notActivated',
                    label: 'domain-admin.menu.tabs.incomplete',
                    users: [],
                    isStatic: true,
                    translate: true,
                },
                {
                    id: 'inviteForm',
                    label: 'domain-admin.menu.tabs.invite-team',
                    isStatic: false,
                    translate: true,
                },
                {
                    id: 'invitations',
                    label: 'domain-admin.menu.tabs.invite-team-pending',
                    users: [],
                    isStatic: false,
                    translate: true,
                },

            ],
            onClick: $scope.selectTab,
            onToggle: toggleView,
        }

        this.inviteFormProps = {
            domain: getDomain(loggedInUser.email),
            onSelectTab: $scope.selectTab,
        };

        $scope.loggedInUserId = userService.getUser().id;

        seoService.set({
            title: 'domain-admin.menu.team',
            pageTitle: 'team-dashboard.header',
            translateTitle: true,
            translatePageTitle: true,
        });

        if (browserService.is('mobile')) {
            $scope.view = 'list';
        } else {
            $scope.view = storage.get('view') || 'tile';
        }

        $scope.update = function () {
            if ($scope.tab === 'inviteForm') {
                return;
            }

            if ($scope.tab === 'invitations') {
                return sdk.admin.team.users.getInvitations()
                    .then(function (invitedUsers) {
                        $scope.users.invitations = invitedUsers.map(function(invitedUser) {
                            return Object.assign({ invitationId: invitedUser.id }, invitedUser.user);
                        });

                        for (var i = 0; i < navigationProps.tabs.length; i++) {
                            if (navigationProps.tabs[i].id === 'invitations') {
                                navigationProps.tabs[i].users = $scope.users.invitations;
                                break;
                            }
                        }
                        $scope.$apply();
                    })
            }

            var endpoint = $scope.tab;
            if (endpoint === 'notActivated') {
                endpoint = 'active';
            }
            var tab = $scope.tab;
            return $api
                    .domain
                    .getUsers
                    .fetch([endpoint])
                    .then(function (users) {
                        console.log('users', tab, users);
                        if (tab === 'notActivated') {
                            $scope.users.notActivated = users.filter(function(user) {
                                return user.LastLogin === '0001-01-01T00:00:00'
                            });
                        } else {
                            $scope.users[tab] = users.filter(function(user) {
                                return user.LastLogin !== '0001-01-01T00:00:00'
                            });
                        }
                        for (var i = 0; i < navigationProps.tabs.length; i++) {
                            if (navigationProps.tabs[i].id === tab) {
                                navigationProps.tabs[i].users = $scope.users[tab];
                                break;
                            }
                        }
                    });
        };

        $scope.openUserDetails = function (userId) {
            $rootScope.$broadcast('DomainAdminProfileController::load', {
                userId: userId,
                userEnabled: $scope.tab === 'active',
                onStatusChanged: $scope.update
            });
        };

        $scope.openInvitedUserDetails = function (user) {
            $rootScope.$broadcast('DomainAdminProfileController::load', {
                userId: user.id,
                user: {
                    InvitationId: user.invitationId,
                    UserId: user.id,
                    Email: user.email,
                    Name: user.name,
                    IsRegistered: user.hasRegistered,
                },
                isPendingInvitedUser: !!user.invitationId,
                onStatusChanged: $scope.update,
            });
        };

        $scope.getDeleteUserButtonProps = function(user) {
            return {
                onDelete: function() {
                    return $scope.deleteUser(user.Email);
                },
            };
        };

        $scope.deleteUser = function(email) {
            return backendService
                .fetch('domain.user.delete', {Email: email})
                .data()
                .then(function() {
                    return $scope.update();
                });
        };

        $scope.formatCSVReportDate = function(date) {
            if (date !== '0001-01-01T00:00:00') {
                return moment(date).locale(i18n.getLocale()).format('LLLL');
            }

            return '';
        };

        $scope.getUserCSVFieldValue = function(user) {
            return {
                Email: user.Email || user.email,
                Name: user.Name || '',
                'Team Administrator': user.IsAdmin ? 'Yes' : 'No',
                'Can Log In': user.Enabled ? 'Yes' : 'No',
                'Account Activated': this.formatCSVReportDate(user.Registered),
                'Last Log In': this.formatCSVReportDate(user.LastLogin),
            };
        };

        $scope.getUserExportData = function() {
            var that = this;
            var search = that.vm.search.toLowerCase();
            return this.users[this.tab].filter(function(user) {
                // user object is passed with lowercase name & email property when in invitation tab otherwise capitalized.
                var email = (user.Email || user.email).toLowerCase();
                var name = (user.Name || '').toLowerCase();
                
                return name.includes(search) || email.includes(search);
            }).map(function(user) {
                var fieldValues = that.getUserCSVFieldValue(user);

                return USER_CSV_FIELDS[that.tab]
                .reduce(function (rowData, field) {
                    var csvObj = Object.assign({}, rowData);
                    csvObj[field] = fieldValues[field];
                    return  csvObj;
                }, {});
            });
        };

        $scope.exportDashboard = function() {
            var csvObjArr = this.getUserExportData();
            
            var that = this;
            var csvFileName = $translate.instant(
                navigationProps.tabs.find(function(tab) {
                    return tab.id === that.tab;
                }).label
            );

            var csvFile = window.generalfunctions_csv(csvObjArr);
            window.downloadFile(csvFileName + '.csv', new Blob([csvFile]));
        };

        $scope.loggedIn = function (time) {
            if(time !== "0001-01-01T00:00:00"){
                return moment.utc(time).locale(i18n.getLocale()).fromNow();
            }
        };

        $scope.searchKeydown = function($event) {
            if($event.key === 'Enter') {
                $event.target.blur();
            }
        }

        $scope.$watch('tab', $scope.update);

        $scope.$on('DomainAdmin_Dashboard::setAdmin', function () {
            $scope.update();
        });

        $scope.update();
    }

);
