/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.run(function ($rootScope, $location, $route, NavigationService, backgroundService) {
    /**
     * Ignores a redirect from causing the route to change.
     *
     * Make sure you directly invoke any method on the $location service before calling this, as this will
     * break the next route recalculation.
     *
     * Be careful using this if you're redirecting the user to a page that has different middleware, as the
     * middleware runs before this event, meaning that if middleware modifies scope data, the scope data will
     * be changed - and not necessarily valid for the current view.
     *
     * @example
     *     class ExampleController {
     *         changePage (pageNumber) {
     *             this.currentPage = pageNumber;
     *             this.$location.path('/proof/PABC123/' + pageNumber).ignore();
     *         }
     *     }
     *
     *     exampleCtrl.changePage(2); // Would update whatever watchers are listening to the `currentPage`
     *                                // property, and change the browsers URL bar to link to the direct link
     *                                // to the page, so if the user refreshes, they are back where they were.
     *
     * @returns {$location}
     */
    $location.ignore = function () {
        let lastRoute = $route.current;

        let un = $rootScope.$on('$locationChangeSuccess', () => {
            $route.current = lastRoute;
            un();
        });

        return $location;
    };

    /**
     * Changes the navigation animation to backwards.
     *
     * @example
     *     $location.backward().path('dashboard');
     *
     * @returns {$location}
     */
    $location.backward = function () {
        // Tell the navigation service that we're navigating backwards (sets the $rootScope.slideClass property)
        // which is passed into the body element of the app, and controls the direction of the animation.
        NavigationService.back();

        return $location;
    };

    /**
     * Changes the navigation animation to forwards.
     *
     * @see {$location.backward}
     *
     * @returns {$location}
     */
    $location.forward = function () {
        // Change the direction of the next redirect to the forwards animation. This isn't required unless the
        // animation has previously been set to backwards. But it's best to always call this in case it has been.
        NavigationService.forward();

        return $location;
    };

    /**
     * Ignore any background navigation tasks, and navigate to new location
     *
     */
    $location.force = function() {
        backgroundService.ignoreBackgroundTasks();
        return $location;
    };
});
