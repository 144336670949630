/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class AppService {
    /**
     * @param {Object} UserService
     *
     * @constructor
     * @ngInject
     */
    constructor (UserService, $translate, $q) {
        this.app = UserService;
        this.$translate = $translate;
        this.$q = $q;
    }

    /**
     * Shows the global application loader.
     *
     * @see {Application.hideLoader}
     *
     * @param {String} [message]
     * @param {Object[]} [buttons]
     */
    showLoader (message, buttons = []) {
        this.app.showLoader();

        if (angular.isString(message)) {
            this.app.showLoaderMessage(message);
        }

        if (angular.isDefined(buttons)) {
            this.app.showLoaderButton(buttons);
        }
    }

    /**
     *  Takes translate strings (messages) as a single string or an array of strings, an array of buttons, and translates them into text, as defined in en.json or equivalent.
     *  @param {Object[]} [messages]
     *  @param {Object[]} [buttons]
     *  @param {Object} [options]
     *
     */
    showTranslatedLoaderMessage (messages, buttons = [], options = {}) {
        let queue = [];
        let allMessages = [];
        if (typeof messages === 'object') {
            messages.forEach((message, index) => {
                queue.push(this.$translate(message, options).then((result) => {
                    allMessages[index] = result;
                }))
            });
        } else {
            queue.push(this.$translate(messages, options).then((result) => {
                allMessages = result;
            }))
        }

        buttons.forEach((button) => {
            queue.push(this.$translate(button.text).then((result) => {
                button.text = result;
            }));
        });

        return this.$q.all(queue).then(() => {
            if (typeof(messages) === 'object') {
                allMessages = allMessages.join(' ');
            }
            return this.showLoader(allMessages, buttons);
        })
    }

    /**
     * Hides the global application loader.
     *
     * @see {Application.hideLoader}
     */
    hideLoader () {
        this.app.hideLoader();
    }
}

app.factory('appService', function ($injector) {
    return $injector.instantiate(AppService);
});
