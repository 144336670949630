/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.filter('textCommentWithMention', function() {
    return function (str) {
        if (str && str.length) {
            const obj = window.generalfunctions_parseCommentText(str);
            const text = window.__pageproof_quark__.sdk.util.comments.text(obj.tokens);
            return truncateStr(text, 50).replace(/\n/g, ' ').replace(/\s+/g, ' ').trim();
        }
        return str;
    };
});
