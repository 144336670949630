/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.factory('MockPPDashboardProof', function (PPProofStatus, PPDashboardProof) {
    /**
     * Creates a mock object which inherits from the PPDasbboardProof prototype.
     *
     * Any properties/getters/setters can be overridden here, as `Object.defineProperty` is called
     * instead of a simple property assignment. This can help bypass any `PPProof` or `PPDashboardProof`
     * checks what require additional properties set.
     *
     * @param {Object} props
     * @returns {PPDashboardProof}
     */
    function createMock (props) {
        let proof = Object.create(PPDashboardProof.prototype);
        proof.id = null; // MAKE SURE `id` IS NULL (prevents default behaviour)
        proof.title = 'Example';
        proof.dueDate = moment(); // Fixes null `dueDate` in `getCachedDueDate`
        Object.keys(props).forEach((key) => {
            let type = typeof props[key] === 'function' ? 'get' : 'value';
            Object.defineProperty(proof, key, { [type]: props[key] });
        });
        return proof;
    }

    return inspect({
        VIDEO_PROCESSING: createMock({
            title: 'My cat video',
            extension: 'mp4',
            fileId: 'MOCK',
            fileStatus: 'Ripping',
            fileStatusMessage: 'Video-Processing-' + (Math.random() * 100)
        }),
        OVERDUE: createMock({
            title: 'Brochure design',
            status: PPProofStatus.FINAL_APPROVING,
            fileId: 'MOCK',
            workflowId: 'MOCK',
            shouldShowThumbnail: true,
            dueDate: moment().subtract(7, 'days')
        }),
        DUE_IN_X: createMock({
            title: 'Campaign TVC',
            status: PPProofStatus.FINAL_APPROVING,
            fileId: 'MOCK',
            workflowId: 'MOCK',
            shouldShowThumbnail: true,
            dueDate: moment().add(3, 'days')
        }),
        CHANGES_REQUESTED: createMock({
            title: 'Promotional catalogue',
            status: PPProofStatus.CHANGES_REQUESTED,
            fileId: 'MOCK',
            workflowId: 'MOCK',
            shouldShowThumbnail: true
        }),
        PROOFERS_NEEDED: createMock({
            title: 'Campaign poster',
            status: PPProofStatus.NEW,
            fileId: 'MOCK',
            shouldShowThumbnail: true
        })
    });
});