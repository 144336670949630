/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/*
proof statuses:

 New = 0,

 Proofing = 10,

 FinalProofing = 30,

 Changes = 50,

 AwaitingNewVersion = 60,

 Approved = 70,

 Closed = 100
 */

function Proof(app) {
    this.application = app;
    this.api = app.api;
    this.enc = app.enc;

    this.fileData = {};
    this.proofId = "";
    this.quality = "";

    var proofData = this.application.GetProofData();
    if(isset(proofData["proofId"])){
        this.proofId = proofData["proofId"];
    }
    this.genericError = this.application.GetConfigSetting("UserErrorMessage_GenericUserMessage");

    this.debuggPDFJS = true;
    this.useDataUri = false;

    this.team = new Team(this.application, "proofs");
    this.users = new Users(this.application);

    this.imageType = "";
};

Proof.prototype.Create = function(title, tags, dueDate, reminders, description, fileId, previousVersion, firstVersion, groupId, reference, callBackFunc) {
    var updateObj = {};

    updateObj.Title = stripTags(title);

    if(isset(dueDate)){
        updateObj.DueDate = stripTags(dueDate);
    }
    if(isset(reminders)){
        updateObj.Reminders = reminders;
    }
    if(isset(description)){
        updateObj.Description = stripTags(description);
    }
    if(isset(fileId)){
        updateObj.FileId = fileId;
    }
    if (isset(groupId)) {
        updateObj.GroupId = groupId;
    }

    if (isset(previousVersion)) {
        updateObj.PreviousProofId = previousVersion;
    }

    if (isset(reference)) {
        updateObj.Reference = reference;
    }

    if (isset(updateObj)) {
        var self = this;
        this.api.CallAPI("proofs", "create", updateObj, function(){
            var returnData = jsonDecode(this.responseText);
            self.SetProofId(returnData["ProofId"]);
            if(isset(tags) && isset(returnData["ProofId"])){
                var tag = new Tag(self.application);
                tag.Create(returnData["ProofId"], tags, function(){});
            }
            if(isset(firstVersion) && isset(returnData["ProofId"])){
                self.CreateVersion(firstVersion, returnData["ProofId"], function(){
                    self.DeleteVersionSearch(previousVersion, function(){});
                });
            }else{
                console.log("no prevoius version or proof id....");
            }
            callBackFunc(returnData);
        });
    } else {
        this._showErrorDialogue("empty updateObj (Proof Create method)");
    }
};

Proof.prototype._showErrorDialogue = function(techInfo) {
    this.application.showErrorBox(this.genericError, techInfo);
};

Proof.prototype._logError = function(techInfo) {
    this.application.logError(techInfo);
};

Proof.prototype.Update = function(proofId, updateObj, callBackFunc) {
    if (isset(proofId)) {
        updateObj.ProofId = proofId;
        if (isset(updateObj)) {
            var self = this;
            if(isset(updateObj.Tags) && isset(updateObj.ProofId)){
                var tag = new Tag(self.application);
                tag.Update(updateObj.ProofId, updateObj.Tags, function(){});
            }
            this.api.CallAPI("proofs", "update", updateObj, function(){
                var returnData = jsonDecode(this.responseText);
                self.SetProofId(returnData["ProofId"]);
                if(isFunc(callBackFunc)) callBackFunc(returnData);
            });
        } else {
            throw new Error("empty updateObj " + jsonEncode(updateObj) + "(Proof Update method)");
        }
    } else {
        throw new Error("empty proofId (Proof Update method)");
    }
};

Proof.prototype.UpdateMessage = function(proofId, message, callBackFunc) {
    if (isset(proofId)) {
        if(message != null){
            this.api.CallAPI("proofs", "updateMessage", {ProofId:proofId, OwnerMessage:message}, function(){
                var returnData = jsonDecode(this.responseText);
                callBackFunc(returnData);
            });
        }
    } else {
        throw new Error("empty proofId (Proof UpdateMessage method)");
    }
};

Proof.prototype.AssignVersionOwner = function(proofId, callBackFunc){
    if (isset(proofId)) {
        this.api.CallAPI("proofs", "update", {ProofId:proofId}, function(){
            var returnData = jsonDecode(this.responseText);
            callBackFunc(returnData);
        });
    } else {
        throw new Error("empty proofId");
    }
};

Proof.prototype.NotifyProofOwners = function(proofId, callBackFunc) {
    if (isset(proofId)) {
        this.api.CallAPI("proofs", "notifyProofOwners", proofId, function(){
            var returnData = jsonDecode(this.responseText);
            if(isFunc(callBackFunc)) callBackFunc(returnData);
        });
    } else {
        throw new Error("empty proofId");
    }
};

Proof.prototype.ChangesComplete = function(proofId, callBackFunc) {
    if (isset(proofId)) {
        this.api.CallAPI("proofs", "changesComplete", proofId, function(){
            var returnData = jsonDecode(this.responseText);
            callBackFunc(returnData);
        });
    } else {
        this._showErrorDialogue("empty proofId (Proof Update method)");
    }
};

Proof.prototype.CreateVersion = function(parentProofId, proofId, callBackFunc) {
    if (isset(proofId) && isset(parentProofId)) {
        this.api.CallAPI("proofs", "createVersion", {ProofId:parentProofId, VersionId: proofId}, function(){
            var returnData = jsonDecode(this.responseText);
            callBackFunc(returnData);
        });
    } else {
        throw new Error("Empty proof or parent proof id. proofId: " + proofId + ", parentProofId: " + parentProofId);
    }
};

// Proof.prototype.Lock = function(proofId, callBackFunc) {
//     if (isset(proofId)) {
//         var self = this;
//         this.api.CallAPI("proofs", "lock", proofId, function(){
//             var returnData = jsonDecode(this.responseText);
//             callBackFunc(returnData);
//         });
//     } else {
//         throw new Error("empty proofId (Proof lock method)");
//     }
// };

// Proof.prototype.Unlock = function(proofId, callBackFunc) {
//     if (isset(proofId)) {
//         var self = this;
//         this.api.CallAPI("proofs", "unlock", proofId, function(){
//             var returnData = jsonDecode(this.responseText);
//             callBackFunc(returnData);
//         });
//     } else {
//         throw new Error("empty proofId (Proof unlock method)");
//     }
// };

Proof.prototype.GetChangesOnProof = function(proofId, timeStamp, callBackFunc) {
    if(empty(proofId)) return "";
    this.api.CallAPI("proofs", "getRecent", {ProofId:proofId, TimeStamp:timeStamp}, function(){
        var returnData = jsonDecode(this.responseText);
        if(isFunc(callBackFunc)) callBackFunc(returnData);
    });
};

Proof.prototype.log = function(data){
    if(this.debuggPDFJS == true) console.log(data);
};

// looks like old method not in use any more (ParseImage, ParseProof, ParseMS). Only using parsePDF
Proof.prototype.ParseProof = function(file, name, tags, dueDate, reminders, message, proofId, previousVersion, callBackFunc){
    if(isset(proofId)){
        self.Update(proofId, {Title:name, Tags:tags, DueDate:dueDate, Reminders:reminders, Description:message}, function(returnData) {
            returnData["numberOfPages"] = numberOfPages;
            callBackFunc(returnData);
        });

    }else{
        self.Create(name, tags, dueDate, reminders, message, "", previousVersion, function(returnData) {
            returnData["numberOfPages"] = numberOfPages;
            callBackFunc(returnData);
        });
    }
}

Proof.prototype.ParsePDF = function(file, name, tags, dueDate, reminders, message, proofId, previousVersion, firstVersion, groupId, reference, callBackFunc){
    var self = this;
    var numberOfPages = 1;

    if(isset(proofId)){

        var obj = {};

        if(isset(name)){
            obj.Title = name;
        }

        if(isset(tags)){
            obj.Tags = tags;
        }

        if(isset(dueDate)){
            obj.DueDate = dueDate;
        }

        if(isset(reminders)){
            obj.reminders = reminders;
        }

        if(isset(message)){
            obj.Description = message;
        }

        if (isset(reference)) {
            obj.Reference = reference;
        }

        self.Update(proofId, obj, function(returnData) {
            returnData["numberOfPages"] = numberOfPages;
            callBackFunc(returnData);
        });

    }else{
        self.Create(name, tags, dueDate, reminders, message, "", previousVersion, firstVersion, groupId, reference, function(returnData) {
            returnData["numberOfPages"] = numberOfPages;
            callBackFunc(returnData);
        });
    }
};

// looks like old method not in use any more (ParseImage, ParseProof, ParseMS). Only using parsePDF
Proof.prototype.ParseImage = function(file, name, tags, dueDate, reminders, message, proofId, previousVersion, callBackFunc){
    if(empty(file)){
        this._showErrorDialogue("empty file object (Proof ParseImages method)");
        return "";
    }
    var self = this;
    var reader = new FileReader();
    reader.onload = function() {
        var img = new Image();
        img.onload = function() {
            if (isset(img.height) && isset(img.width)) {
                if(isset(proofId)){
                    self.Update(proofId, {Title:name, Tags:tags, DueDate:dueDate, Reminders: reminders, Description:message}, function(returnData) {
                        callBackFunc(returnData);
                    });
                }else{
                    self.Create(name, tags, dueDate, reminders, message, "", previousVersion, function(returnData) {
                        callBackFunc(returnData);
                    });
                }
            } else {
                this._showErrorDialogue("empty file params, height: " + img.height + ", width: " + img.width + " (Proof ParseImage method)");
            }
        };
        img.src = reader.result;
    };
    reader.onerror = function(event) {
        this._showErrorDialogue("read file error: " + event.target.error.toString() + " (Proof ParseImage method)");
    };
    reader.readAsDataURL(file);
};

// looks like old method not in use any more (ParseImage, ParseProof, ParseMS). Only using parsePDF
Proof.prototype.ParseMS = function(file, name, tags, dueDate, reminders, message, proofId, previousVersion, callBackFunc){
    if(empty(file)){
        this._showErrorDialogue("empty file object (Proof ParseMS method)");
        return "";
    }
    if(isset(proofId)){
        this.Update(proofId, {Title:name, Tags:tags, DueDate:dueDate, Reminders:reminders, Description:message}, function(returnData) {
            callBackFunc(returnData);
        });
    }else{
        this.Create(name, tags, dueDate, reminders, message, "", previousVersion, function(returnData) {
            callBackFunc(returnData);
        });
    }
};

// Proof.prototype.UnsetOriginal = function(proofId, callBackFunc){
//     this.api.CallAPI("proofs", "unsetOriginal", proofId, function(){
//         var jsonResponse = jsonDecode(this.responseText);
//         callBackFunc(jsonResponse);
//     });
// };

Proof.prototype.Load = function(proofId, callBackFunc) {
    if (isset(proofId)) {
        this.SetProofId(proofId);
        this.api.CallAPI("proofs", "getById", proofId, callBackFunc);
    } else {
        throw new Error("Empty proofId");
    }
};

Proof.prototype.DistributeProof = function(proofId, callBackFunc) {
    if (isset(proofId)) {
        this.SetProofId(proofId);
        this.api.CallAPI("proofs", "distributeProof", proofId, callBackFunc);
    } else {
        throw new Error("Empty proofId");
    }
};

Proof.prototype.Reopen = function(proofId, callBackFunc) {
    if (isset(proofId)) {
        var self = this;
        this.api.CallAPI("proofs", "reopen", proofId, function(){
            var returnData = jsonDecode(this.responseText);
            callBackFunc(returnData);
        });
    } else {
        this._showErrorDialogue("empty proofId (Proof Update method)");
    }
};

Proof.prototype.Search = function(params, all, filterBy, callBackFunc) {
    this.api.CallAPI("proofs", "search", {Query:params, All:all, FilterBy:filterBy}, callBackFunc);
};

Proof.prototype.HasStarted = function(proofId, callBackFunc) {
    if (isset(proofId)) {
        var self = this;
        self.Load(proofId, function() {
            var returnData = jsonDecode(this.responseText);
            if (isset(returnData)) {
                if (returnData["Status"] > 0 && isset(returnData["WorkflowId"])) {
                    if(isFunc(callBackFunc)) callBackFunc(true, returnData["Status"], returnData["WorkflowId"]);
                }else{
                    if(isFunc(callBackFunc))  callBackFunc(false, 0, "");
                }
            }
        });
    } else {
        throw new Error("no proofId set");
    }
};

Proof.prototype.Start = function(proofId, previousVersion, callBackFunc) {
    if (isset(proofId)) {
        var self = this;
        self.Load(proofId, function() {
            var returnData = jsonDecode(this.responseText);
            if (isset(returnData)) {
                if (returnData["Status"].toString() == "0") {
                    self.api.CallAPI("proofs", "start", {ProofId:proofId}, function(){
                        var returnData = jsonDecode(this.responseText);
                        if(isFunc(callBackFunc)) callBackFunc(returnData);
                    });
                }else{
                    if(isFunc(callBackFunc))  callBackFunc("");
                }
            }
        });
    } else {
        throw new Error("no proofId set (Proof Load method)");
    }
};

//getVersions

Proof.prototype.GetVersions = function(proofId, callBackFunc) {
    this.api.CallAPI("proofs", "getVersions", proofId, function(){
        var returnData = jsonDecode(this.responseText);
        if(isFunc(callBackFunc)) callBackFunc(returnData);
    });
};

Proof.prototype.GetOriginalVersion = function(proofId, callBackFunc) {
    this.api.CallAPI("proofs", "getVersions", proofId, function(){
        var returnData = jsonDecode(this.responseText);
        if(isFunc(callBackFunc)) callBackFunc(returnData);
    });
};

Proof.prototype.AssignOwner = function(proofId, callBackFunc) {
    this.api.CallAPI("proofs", "assignOwner", {ProofId:proofId}, function(){
        var returnData = jsonDecode(this.responseText);
        if(isFunc(callBackFunc)) callBackFunc(returnData);
    });
};

Proof.prototype.GetAllProofs = function(type, callBackFunc) {
    this.api.CallAPI("proofs", "getAll", type, callBackFunc);
};

Proof.prototype.GetProcessing = function(callBackFunc) {

    console.log("GetProcessing...");

    this.api.CallAPI("proofs", "getProcessing", "", function(){
        var returnData = jsonDecode(this.responseText);
        if(isFunc(callBackFunc)) callBackFunc(returnData);
    });
};

Proof.prototype.Cancel = function(proofId, callBackFunc) {
    this.api.CallAPI("proofs", "cancel", proofId, callBackFunc);
};

Proof.prototype.Delete = function(proofId, callBackFunc) {
    this.api.CallAPI("proofs", "delete", proofId, callBackFunc);
};

//deleteversionsearch

Proof.prototype.DeleteVersionSearch = function(proofId, callBackFunc) {
    this.api.CallAPI("proofs", "deleteVersionSearch", proofId, callBackFunc);
};

//updateVersionSearch

Proof.prototype.UpdateVersionSearch = function(proofId, callBackFunc) {
    this.api.CallAPI("proofs", "updateVersionSearch", proofId, callBackFunc);
};

Proof.prototype.GetActive = function(callBackFunc) {
    if (isset(proofId)) {
        this.api.CallAPI("proofs", "getById", proofId, callBackFunc);
    } else {
        this._showErrorDialogue("no proofId set (Proof GetActive method)");
    }
};

Proof.prototype.GetAuditLog = function(proofId, callBackFunc) {
    if (isset(proofId)) {
        this.api.CallAPI("audit", "getById", proofId, function(){
            var jsonResponse = jsonDecode(this.responseText);
            callBackFunc(jsonResponse);
        });
    }
};

Proof.prototype.SetProofId = function(proofId){
    this.proofId = proofId;
    this.application.SetProofData(proofId);
};

Proof.prototype.GenerateTags = function(name) {
    var lastDotIndex = name.lastIndexOf('.');
    return name && (lastDotIndex > 0 ? name.substring(0, lastDotIndex) : name)
                       .replace(/(\.)/g, '')
                       .replace(/(\-|_)/g, ' ')
                       .replace(/\s+/g, ' ')
                       .split(' ')
                       .filter(function (tag) {
                           return tag && tag.trim().length;
                       })
                       .join(' ');
};

Proof.prototype.GenerateData = function(fileObj) {
    var data = fileObj;
    var type = getFileType(data["name"]);

    this.fileData = {
        path: data["path"],
        name: getFileName(data["name"]),
        size: data["size"],
        type: type.toLowerCase(),
        tagString: this.GenerateTags(data["name"]),
        height: 0,
        width: 0,
        numberOfPages: 0
    };

};

Proof.prototype.GetFileData = function(attr){
    if(attr!=="" && ( typeof this.fileData[attr] !== 'undefined' ) ){
        return this.fileData[attr];
    }else{
        return this.fileData;
    }
};

//getOriginalFile

Proof.prototype.DownloadOriginalFileLarge = function(proofId, fileId, number, callBackFunc, notifyCallback, errorCallback, additionalParams) {
    var self = this;
    this.api.CallAPI("proofs", "getfilechunk", [proofId, fileId, number], function () {
        var jsonResponse = jsonDecode(this.responseText);
        callBackFunc(jsonResponse);
    }, notifyCallback, errorCallback, additionalParams);
};


Proof.prototype.GetProofStatus = function(fileId, callBackFunc) {
    if (isset(fileId)) {
        var status = "no_status";
        this.api.CallAPI("proofs", "getImageStatus", fileId, function(){
            var jsonResponse = jsonDecode(this.responseText);
            if(isset(jsonResponse)){
                status = jsonResponse;
            }
            callBackFunc(status, fileId);
        });
    } else {
        this._showErrorDialogue("empty params, fileId: " + fileId + ", (Proof GetImageStatus method)");
    }
};

Proof.prototype.GetProofStatusStr = function(proofId, callBackFunc) {
    if (isset(proofId)) {
        var status = "no_status";
        this.api.CallAPI("proofs", "getProofStatusStr", proofId, function(){
            var jsonResponse = jsonDecode(this.responseText);
            callBackFunc(jsonResponse);
        });
    } else {
        throw new Error("Empty proofId.");
    }
};

//proof admin methods

Proof.prototype.AddAdmin = function(proofId, email, callBackFunc) {
    if (isset(proofId)) {

        var user = new Users(this.application);
        var self = this;

        var permData = {ProofCoOwner:"1"};

        user.SearchUser(email, function(){
            var returnData = jsonDecode(this.responseText);
            console.log("returnData", returnData);
            if(returnData.length > 0){
                var userId = returnData[0]["UserId"];
                permData.RelatedId = proofId;
                permData.UserId = userId;
                self.team.Add(permData, function(response){
                    callBackFunc(response, userId);
                });
            }else{
                user.CreateUser(email, function(){
                    var returnData = jsonDecode(this.responseText);
                    if(returnData["UserId"]){
                        permData.RelatedId = proofId;
                        permData.UserId = returnData["UserId"];
                        self.team.Add(permData, function(response){
                            callBackFunc(response, returnData["UserId"]);
                        });
                    }
                });
            }
        });

    } else {
        throw new Error("no proofId");
    }
};

Proof.prototype.AddAdminMany = function(proofId, emails, callBackFunc) {
    if (isset(proofId) && isset(emails)) {
        var self = this;
        var permData = {CoOwner:"1"};
        permData.Emails = emails;
        permData.RelatedId = proofId;
        self.team.AddMany(permData, function(response){
            callBackFunc(response);
        });
    } else {
        throw new Error("no proofId");
    }
};

Proof.prototype.UpdateAdmin = function(memberId, permissions, callBackFunc) {
    if (isset(memberId)) {
        this.team.Update(memberId, permissions, function(response){
            callBackFunc(response);
        });
    } else {
        throw new Error("no memberId");
    }
};

Proof.prototype.RemoveAdmin = function(memberId, callBackFunc) {
    if (isset(memberId)) {
        this.team.Delete(memberId, "ProofCoOwner", function(response){
            callBackFunc(response);
        });
    } else {
        throw new Error("no memberId");
    }
};

Proof.prototype.GetAllAdmins = function(proofId, callBackFunc) {
    if (isset(proofId)) {
        this.team.GetList(proofId, function(response){
            callBackFunc(response);
        });
    } else {
        throw new Error("no proofId");
    }
};

Proof.prototype.GetAdmin = function(proofId, userId, callBackFunc) {
    if (isset(proofId)) {
        this.team.GetList(proofId, function(response){
            callBackFunc(response);
        });
    } else {
        throw new Error("no proofId");
    }
};

Proof.prototype.isCoOwner = function(proofId, email, callbackFunc){
    var owners = 0;
    var people = 0;
    var expectedPeople = 0;
    var self = this;
    self.GetAllAdmins(proofId, function(returnData){
        if(returnData.length > 0){
            expectedPeople = returnData.length;
            returnData.forEach(function(value, index){
                self.users.LoadFromRepo(value["UserId"], function(response){
                    people ++;
                    if(response["email"] == email){
                        owners ++;
                    }
                    if(people == expectedPeople){
                        callbackFunc(owners);
                    }
                });
            });
        }else{
            callbackFunc(owners);
        }
    });
};

Proof.prototype.Validate = function(proofId, callBackFunc) {
    this.api.CallAPI("admin", "validate", proofId, function(){
        callBackFunc(jsonDecode(this.responseText));
    });
};

Proof.prototype.PremissionCheck = function(proofId, workflowId, callBackFunc){
    this.team.CheckProof(proofId, workflowId, callBackFunc);
}
