/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.factory('PPDomainBranding', function (PPModel) {
    const domainBrandingTransformer = {
        type: 'Type',
        enabled: 'Enabled',
        size: 'Size',
        width: 'Width',
        height: 'Height',
        url: 'URL',
        alignment: 'Alignment',
    };

    class PPDomainBranding extends PPModel {
        /**
         * @param {object} initialData
         * @constructor
         */
        constructor(initialData) {
            super();
            Object.assign(this, initialData);
        }

        /**
         * Whether the branding setting is enabled. Controls weather consumer endpoints return the branding
         * settings. Admin endpoints still return the branding settings regardless of this field.
         *
         * @type {boolean}
         */
        enabled = false;

        /**
         * The alignment setting (L, C or R).
         *
         * @type {string}
         */
        alignment = 'C';

        /**
         * Wether the branding setting exists. If not, the app hides the preview & toggle on/off sections.
         * 
         * @type {boolean}
         */
        get exists() {
            return typeof this.url === 'string' && this.url.length;
        }

        /**
         * Transforms a domain branding data object to the PPDomainBranding object.
         *
         * @param {object} data
         * @returns {PPDomainBranding}
         */
        updateFromDomainBrandingData(data) {
            this.transform(data, domainBrandingTransformer);
            return this;
        }

        /**
         * Creates a PPDomainBranding object from a domain branding data object.
         *
         * @param {object} data
         * @returns {PPDomainBranding}
         */
        static from(data) {
            return new PPDomainBranding()
                .updateFromDomainBrandingData(data);
        }
    }

    return PPDomainBranding;
});
