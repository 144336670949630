/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
function InlineSvgDirective ($http, cacheFactory) {
    let cache = cacheFactory((src, resolve) => {
        resolve($http.get(src).then(({ data }) => data));
    });

    return {
        restrict: 'E',
        link (scope, element, attr) {
            attr.$observe('src', (src) => {
                cache.get(src).then((svg) => {
                    element.html(svg);
                });
            });
        }
    };
}

app
    .directive('inlineSvg', InlineSvgDirective);
