/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
angular
    .module('createProof')
    .constant('callbackConfig', {
        task: {
            options: {
                concurrent: 1
            }
        },
        queue: {
            options: {
                concurrent: 1,
                interval: 1e3 // 1 second
            }
        }
    })
    .factory('callbackService', function ($rootScope, $q, $map, $interval, $timeout, callbackConfig, $exceptionHandler) {
        var callbackService;

        return callbackService = {
            /**
             * Invoke multiple callbacks (async, or sync), either one after the other or several at a time.
             *
             * @example
             *     var doStuff = callbackService.tasks([
             *         function (done) {
             *             setTimeout(function () {
             *                 done('First callback');
             *             }, 100);
             *         },
             *         function () {
             *             return 'Second callback';
             *         }
             *     ]);
             *
             *     doStuff(function (results) {
             *         results; // ['First callback', 'Second callback']
             *     });
             *
             * The above example invokes each callback one after the other (which is the default behaviour),
             * the first callback does an async call to `setTimeout` which calls the `done` function for that
             * task, and passes through 'First callback'. After the `done` function is invoked, the next task
             * is started. The second task is sync, so it does not need the `done` function, it just returns
             * it's result. If the task does not contain an argument, it is assumed the task is sync, and
             * expects the return value to be the results of that task.
             *
             * @example
             *     var doStuff = callbackService.tasks([
             *         function (done) {
             *             setTimeout(function () {
             *                 done('First callback');
             *             }, 200);
             *         },
             *         function (done) {
             *             setTimeout(function () {
             *                 done('Second callback');
             *             }, 100);
             *         }
             *     ], {
             *         concurrent: 2
             *     });
             *
             *     doStuff(function (results) {
             *         results; // ['First callback', 'Second callback']
             *     });
             *
             * The above example invokes two callbacks at a time. The second argument to `callbackService.tasks`
             * is an options object, which adds additional behaviour. Even though the tasks are not resolved
             * in the same order as the first example, the `results` array from the `doStuff` callback contains
             * the results in the same order as defined.
             *
             * @param {Function[]} tasks
             * @param {Object} options
             * @returns {Function}
             */
            tasks: function (tasks, options) {
                options = angular.extend({}, callbackConfig.task.options, options);

                function start (callback) {
                    var queue = angular.copy(tasks),
                        results = new Array(queue.length),
                        completed = 0;

                    function next () {
                        var index = tasks.length - queue.length,
                            task = queue.shift();

                        function done (result) {
                            results[index] = result;
                            completed++;

                            progress({
                                index: index,
                                completed: completed,
                                total: tasks.length,
                                queue: queue.length,
                                remaining: tasks.length - completed,
                                percent: (completed / tasks.length) * 100
                            });

                            if (completed < tasks.length) {
                                next();
                            } else {
                                complete();
                            }
                        }

                        if (task) {
                            var args = [task.length ? done : null, index],
                                response = task.apply(undefined, args);

                            if (angular.isDefined(response)) {
                                // If the callback is sync, invoke the done with whatever returns from
                                // the done callback (instead of invoking the next arg).
                                $q.when(response).then(done);
                            }
                        }
                    }

                    function progress (event) {
                        // Invoke a callback every time we pop a task off the queue
                        if (angular.isFunction(options.each)) {
                            options.each(event);
                        }
                    }

                    function complete () {
                        callback(results);
                    }

                    var process = options.concurrent;
                    while (process--) {
                        next();
                    }
                }

                return start;
            },
            /*

             let queue = callbackService.queue((proof, resolve, reject) => {
                 decryptProofThumbnail(proof.id).then((thumbnail) => {
                     if (thumbnail) {
                         resolve(thumbnail);
                     } else {
                         destroy(Error('Could not decrypt thumbnail (proof id: ' + proof.id + ')'));
                     }
                 });
             });

             queue.push(proof, (err, thumbnail) => {
                 proof.thumbnail = thumbnail;
             });

             queue.process();

             */
            queue (handle, options) {
                options = angular.extend({}, callbackConfig.queue.options, options);

                let $$processing = 0,
                    log = $$log.create('callbackService.queue'),
                    interval,
                    timeout,
                    items = [];

                function _log (message) {
                    log(message, `(remaining = ${items.length}, processing = ${$$processing})`);
                }

                function process () {
                    if ($$processing < options.concurrent && items.length) {
                        $$processing++;

                        let { data, callback } = items.shift(),
                            { resolve, promise } = $q.defer(),
                            complete = () => {
                                $$processing--;
                                _log('Finished processing queue item');
                                schedule();
                            };

                        _log('Processing queue item');
                        resolve($q.when(handle(data)));

                        promise
                            .then((result) => {
                                complete();
                                callbackService.safe(callback, undefined, [null, result]);
                            })
                            .catch((err) => {
                                complete();
                                callbackService.safe(callback, undefined, [err]);
                            });
                    }
                }

                function schedule (time = 0) {
                    if (timeout) {
                        $timeout.cancel(timeout);
                    }
                    $timeout(process, time);
                }

                return {
                    unshift (data, callback) {
                        items.unshift({ data, callback });
                        schedule();
                    },
                    push (data, callback) {
                        items.push({ data, callback });
                        schedule();
                    },
                    process (time) {
                        schedule(time);
                    },
                    destroy () {
                        $interval.cancel(interval);
                    }
                };
            },
            /**
             * Takes an array of function and invokes each of the functions one after the other, removing the
             * reference from the original array object.
             *
             * @param {Function[]} callbacks
             */
            clean: function (callbacks) {
                while (callbacks.length) {
                    callbackService.safe(callbacks.shift());
                }
            },
            /**
             * Safely invokes a function and returns the returning value from the original invocation.
             *
             * If the function throws an error, the error will be passed back to the $exceptionHandler service
             * which prevents the error from causing the current stack to end. And optionally passed to the
             * 'caught' argument (function) as the first argument.
             *
             * @example
             *     var foo = callbackService.safe(function () {
             *         return 'bar';
             *     });
             *
             *     console.log(foo); // bar
             *
             * @param {Function} fn
             * @param {*} [that]
             * @param {*} [args]
             * @param {Function} [after]
             * @param {Function} [caught]
             * @returns {*}
             */
            safe: function (fn, that, args, after, caught) {
                var value;

                try {
                    if (angular.isFunction(fn)) {
                        value = fn.apply(that, args);
                    }
                } catch (err) {
                    $exceptionHandler(err);

                    if (angular.isFunction(caught)) {
                        callbackService.safe(caught, null, [err]);
                    }
                } finally {
                    if (angular.isFunction(after)) {
                        callbackService.safe(after, null, [value]);
                    }
                }

                return value;
            },
            /**
             * Invokes a function once, and only once.
             *
             * When the function is invoked for the first time, the unbind argument is invoked with the original
             * function object, and the value it returned when it was invoked.
             *
             * @example
             *     var foo = callbackService.once(function (index) {
             *         return 'bar' + index;
             *     }, function (fn, value) {
             *         foo = undefined;
             *     });
             *
             *     console.log(foo(1)); // bar1
             *     console.log(foo(2)); // throws - undefined is not a function
             *
             * @param {Function} fn
             * @param {Function} [unbind]
             * @returns {Function}
             */
            once: function (fn, unbind) {
                var hasInvoked = false,
                    value;

                return function () {
                    if ( ! hasInvoked) {
                        value = callbackService.safe(fn, this, arguments,
                            function (value) {
                                hasInvoked = true;

                                if (angular.isFunction(unbind)) {
                                    unbind(fn, value);
                                }
                            }
                        );
                    }

                    return value;
                };
            },
            /**
             * Invokes an array of callbacks.
             *
             * @param {Array} arr
             * @param {*} [that]
             * @param {Array} [args]
             * @param {Function} [after]
             * @param {Function} [caught]
             * @return {*[]}
             */
            all (arr, that, args, after, caught) {
                if ( ! angular.isArray(arr)) {
                    throw new TypeError('callbackService.all expects Array as first argument, ' + (typeof arr) + ' given.');
                }

                let callbacks = [].slice.apply(arr),
                    values = [];

                callbacks.forEach((callback) => {
                    let value = callbackService.safe(callback, that, args, after, caught);
                    values.push(value);
                });

                return values;
            }
        };
    });
