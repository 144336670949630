/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
ppxControllers.controller('proofWorkflow', ['$scope', '$rootScope', '$routeParams', '$translate', '$location', '$sce', 'UserService', 'appService', 'DataService', 'mode', 'NavigationService', 'SegmentIo', 'eventService', '$timeout', 'domService', 'apiService', '$q', 'userRepositoryService', 'backendService', 'seoService', 'storageService', 'sdk', 'userService','$filter',  function ($scope, $rootScope, $routeParams, $translate, $location, $sce, UserService, appService, DataService, mode, NavigationService, SegmentIo, eventService, $timeout, domService, apiService, $q, userRepositoryService, backendService, seoService, storageService, sdk, userService, $filter) {

    $scope.application = UserService; //make the application class avaliable to this scope
    $scope.data = DataService; //make the dataservice avaliable to this scope
    $scope.appService = appService;

    $scope.workFlow = new WorkFlow($scope.application); //setup the workflow object

    $scope.users = new Users($scope.application); //setup the workflow object

    $scope.mode = mode;

    $scope.userData = $scope.application.GetUserData();

    $scope.storage = storageService('pageproof.app.');

    $scope.email = $scope.userData["userEmail"];
    $scope.userId = $scope.userData["userId"];

    //workflows steps
    $scope.workflowData = {proofId: "", workflowId: "", workflowSteps: []};

    $scope.managementMode = false;
    $scope.sharedMode = false;
    $scope.domReady = "true";



    $scope.avatars = [];

    $scope.existingWorkflows = [];

    // workflow email search variables
    $scope.filteredWorkflows = [];
    $scope.workflowEmailsFetched = false;
    $scope.searchQuery = '';


    $scope.sharedErrorText = "shared.invalid-email";

    $scope.workflowType = "owned";

    $scope.showHelpPanel = true;

    $scope.WorkflowTemplateNumbers = [];

    $scope.isOwnedByTeam = false;
    $scope.loggedInUserData = userService.getUser();

    $scope.isMac = window.__pageproof_bridge__.browserService.os === 'mac';
    $scope.isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    $scope.isSmallScreen = window.innerWidth <= 750;

    $scope.$on('$destroy', eventService.on(window, 'resize', function () {
        $scope.isSmallScreen = window.innerWidth <= 750;
    }));

    $scope.hideHelpPanel = function () {
        $scope.showHelpPanel = false;
    };

    $scope.changeWorkflow = function (workflowId) {

        if (isset(workflowId)) {
            $location.path("proof-workflow/" + workflowId);
            if (!$scope.$$phase) $scope.$apply();
        }

    };

    $scope.toggleFavorite = function (workflow) {
        if (!workflow.isFavorite) {
            sdk.workflows.favorite(workflow.id);
        } else {
            sdk.workflows.unfavorite(workflow.id);
        }
        workflow.isFavorite = !workflow.isFavorite;
    };

    $scope.showFavoriteStar = !$location.absUrl().includes("team-workflows");

    $scope.createWorkflowUrl = function (idOrPath) {
        var path = '/workflows/templates/' + idOrPath;

        if ($scope.mode === 'team-workflows') {
            path += '?from=team';
        } else if ($scope.workflowType === 'shared') {
            path += '?from=shared';
        }

        return path
    };

    // Passing 'new' as workflow id to route to the new workflow page
    $scope.openWorkflow = function (workflowId) {
        if (empty(workflowId)) return "";

        $scope.appService.showTranslatedLoaderMessage("loader-message.drawing-workflow");
        $scope.application.showLoader();

        $location.url($scope.createWorkflowUrl(workflowId));
        if (!$scope.$$phase) $scope.$apply();
    };

    $scope.usedModifierKey = function (event) {
        return $scope.isMac ? event.metaKey : event.ctrlKey;
    };

    $scope.duplicateWorkflow = function (workflowId, workflowTitle) {
        if (empty(workflowId) || empty(workflowTitle)) return "";
        window.__pageproof_quark__.features.duplicateWorkflowTemplate(workflowId, workflowTitle);
    };

    $scope.setWorkflowTemplateNumbers = function (num, index) {
        $scope.WorkflowTemplateNumbers[index] = num;
    };

    $scope.checkForSharedWorkFlows = function (callBackFunc) {

        $scope.workFlow.GetAllWorkFlows("shared", function () {
            var returnData = jsonDecode(this.responseText);
            if (returnData.length > 0) {
                $scope.setWorkflowTemplateNumbers(returnData.length, 'shared');
                if (isFunc(callBackFunc)) callBackFunc(true);
            } else {
                if (isFunc(callBackFunc)) callBackFunc(false);
            }
        });
    };

    $scope.checkForWorkFlows = function (callBackFunc) {

        $scope.workFlow.GetAllWorkFlows("owned", function () {
            var returnData = jsonDecode(this.responseText);
            if (returnData.length > 0) {
                $scope.setWorkflowTemplateNumbers(returnData.length, 'owned');
                if (isFunc(callBackFunc)) callBackFunc(true);
            } else {
                if (isFunc(callBackFunc)) callBackFunc(false);
            }
        });
    };

    $scope.deleteWorkflow = function (workflowId) {
        var wfId = workflowId || $scope.workflowId;

        if (isset(wfId)) {
            $scope.workFlow.Delete(wfId, function (retData) {
                if (retData["ResponseStatus"] === "OK" && !$scope.returning) {
                    if ($location.path() === '/workflow-dashboard' ||
                        $location.path() === '/team-workflows') {
                        var newWorkflows = $scope.existingWorkflows.filter(function (wf) {
                            return wf.id !== wfId;
                        });
                        $scope.existingWorkflows = newWorkflows;
                        filterSearchResults();
                        if (!$scope.$$phase) $scope.$apply();
                    } else {
                        $scope.navigateBackToDashboard();
                    }
                }
            });
        }
    };

    $scope.navigateBackToDashboard = function () {
        if ($location.search().from === 'team-workflows' && isTeamAdminOnWorkflow()) {
            $location.search('from', null);
            $location.path("team-workflows/");
            if (!$scope.$$phase) $scope.$apply();
        } else if ($scope.workflowType == "shared") {
            $location.path("workflow-dashboard-shared/");
            if (!$scope.$$phase) $scope.$apply();
        } else if ($scope.workflowType == "owned") {
            $location.path("workflow-dashboard/");
            if (!$scope.$$phase) $scope.$apply();
        } 
    };

    $scope.openDelete = false;

    $scope.toggleDeleteArea = function (toggle) {
        $scope.openDelete = toggle;
    };

    $scope.toggleDeleteBox = function (toggle, id) {
        for (var i = 0; i < $scope.existingWorkflows.length; i++) {
          if ($scope.existingWorkflows[i].id === id) {
            $scope.existingWorkflows[i].openDelete = toggle;
            break;
          }
        }
    };

    $scope.showSharedMenu = false;

    $scope.navWorkflow = "";
    $scope.navWorkflowShared = "";

    $scope.navigateTo = function (path) {
        $location.path(path + "/");
        if (!$scope.$$phase) $scope.$apply();
    };

    function filterSearchResults() {
        if (!$scope.filteredWorkflows) {
            return;
        }

        if ($scope.searchQuery.includes('@')) {
            $scope.filteredWorkflows = $filter('filter')($scope.existingWorkflows, { emails: $scope.searchQuery });
        } else {
            $scope.filteredWorkflows = $filter('filter')($scope.existingWorkflows, {name: $scope.searchQuery });
            $filter('orderBy')($scope.filteredWorkflows, 'name');
        }
    }

    $scope.workflowTemplateDownloadProps = {  
        get mode() {
            return $scope.mode;
        },
        get filteredWorkflowIds() {  
            return $scope.filteredWorkflows.map(function (workflow) { return workflow.id; });  
        },
        get searchQuery() {  
            return $scope.searchQuery;  
        },
    }
    
    $scope.$watch('searchQuery', filterSearchResults);

    $scope.loadWorkflowUsersEmail = function() {
        if ($scope.workflowEmailsFetched) {
            return;
        } else {
            if ($scope.mode === 'team-workflows') {
                sdk.admin()
                    .then(function(admin) {
                        admin.team.workflows._getOwnedEmailList()
                            .then(function (data) {
                                $scope.updateWorkflowWithUsersEmail(data);
                            }).then(function() {
                                filterSearchResults();
                            })
                    }).catch(function(error) {
                        $scope.workflowEmailsFetched = false;
                    });
            } else {
                sdk.workflows[$scope.sharedMode ? '_getSharedEmailList' : '_getOwnedEmailList']()
                    .then(function (data) {
                        $scope.updateWorkflowWithUsersEmail(data);
                    }).then(function() {
                        filterSearchResults();
                    }).catch(function(error) {
                        $scope.workflowEmailsFetched = false;
                    });
            }
        }
    };

    $scope.updateWorkflowWithUsersEmail = function(data) {
        var workflowEmailsIndex = {};
        data.forEach(function(workflow) {
            workflowEmailsIndex[workflow.id] = workflow.emails;
        });
        $scope.existingWorkflows.forEach(function(workflow) {
            workflow.emails = workflowEmailsIndex[workflow.id];
        });
        $scope.workflowEmailsFetched = true;
    };

    $scope.searchWorkflowKeydown = function($event) {
        if ($event.key === 'Enter') {
            $event.target.blur();
        }
    }

    $scope.toggleSharedMenu = function (callBackFunc) {
        $scope.checkForSharedWorkFlows(function (result) {
            if (result == true) {
                $scope.showSharedMenu = true;
                if (isFunc(callBackFunc)) {
                    callBackFunc();
                } else {
                    if (!$scope.$$phase) $scope.$apply();
                }
            } else {
                if (isFunc(callBackFunc)) {
                    callBackFunc(false);
                }
            }
        });
    };

    $scope.loadDetailedWorkflows = function (page, shared, callback) {
        if ($scope.mode === 'team-workflows') {
            sdk.admin()
                .then(function(admin) {
                    admin.team.workflows.owned()
                        .then(function (data) {
                            callback(data);
                        });
                })
        } else {
            sdk.workflows[shared ? 'shared' : 'owned']()
                .then(function (data) {
                    callback(data);
                });
        }
    };

    $scope.updateDetailedWorkflows = function (data) {
        $scope.showExistingWorkflows = true;
        $scope.existingWorkflows = data;
        $scope.filteredWorkflows = data;
        
        if ($scope.searchQuery) {
          filterSearchResults();
        }

        if (!$scope.$$phase) $scope.$apply();
    };

    //proof / workflow permission code

    $scope.newAdmin = "";
    $scope.admins = [];
    $scope.adminError = "Please enter a valid<br> email address";

    $scope.showNewCoOwner = false;
    $scope.newCoOwner = "";
    $scope.coOwners = [];
    $scope.showCoOwnerError = false;
    $scope.coOwnerError = "invalid-email";

    $scope.showNewCoOwnerTemp = false;
    $scope.coOwnerTemps = [];
    $scope.showCoOwnerError = false;
    $scope.coOwnerTempError = "invalid-email";

    $scope.handleManyCoOwnersAddRunning = false;

    /******* Workflow template new look *******/
    $scope.vm = {
        tab: $routeParams.tab || 'workflow'
    };

    var isTeamAdminOnWorkflow = function () {
        var userData = $scope.loggedInUserData;
        return userData.isDomainAdmin && $scope.workflowData.teamId === userData.teamId;
    }


    $scope.setHeader = function () {
        if ($location.path().indexOf('workflow-dashboard') !== -1) {
            seoService.set({
                title: 'main-header.workflows',
                pageTitle: 'main-header.workflows',
                translateTitle: true,
                translatePageTitle: true,
            });
        }
        if ($location.path().indexOf('team-workflows') !== -1) {
            seoService.set({
                title: 'main-header.workflows.team',
                pageTitle: 'main-header.workflows.team',
                translateTitle: true,
                translatePageTitle: true,
            });
        }
    };

    /******* Workflow template new look ends *******/

    $scope.$on('$destroy', function () {
        if ($scope.returning && $scope.deleteTempWorkflow) {
            $scope.storage.remove('delete_workflow_id');
            $scope.deleteWorkflow(false, $scope.workflowId);
        }
    });

    $scope.isWorkflowTemplate = false;

    $scope.init = function () {
        if ($scope.mode == "dashboard" || $scope.mode == "dashboard-shared" || $scope.mode == "manage" || $scope.mode == "manage-shared" || $scope.mode == "manage-new") {
            $scope.managementMode = true;
            $('header h1').text('Workflows');
        }

        console.log("$scope.mode", $scope.mode);

        if ($scope.mode == "dashboard") {
            $scope.workflowType = "owned";
            $scope.navWorkflow = "selected";
            $scope.navWorkflowShared = "";
            $scope.sharedMode = false;
        } else if ($scope.mode == "dashboard-shared") {
            $scope.workflowType = "shared";
            $scope.navWorkflowShared = "selected";
            $scope.navWorkflow = "";
            $scope.sharedMode = true;
        }

        $scope.workflowId = "";

        console.log("$scope.mode: " + $scope.mode);


        console.log("workflow id:" + $scope.workflowId);

        if ($scope.mode == "manage") {
            $timeout(function () {
                angular.element("#workFlowName").focus();
            }, 800);
        }

        $scope.setHeader();

        $scope.toggleSharedMenu();

        $scope.checkForWorkFlows();

        $scope.loadDetailedWorkflows(0, $scope.sharedMode, $scope.updateDetailedWorkflows);
    };

    if (isset($scope.proofId)) {
        $scope.proof.HasStarted($scope.proofId, function (hasStarted) {
            if (hasStarted) {
                $location.path("proof-already-started/" + $scope.proofId);
            } else {
                $scope.init();
            }
        });
    } else {
        $scope.init();
    }


}]);
