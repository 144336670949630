/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.filter('ellipsis', function () {
    return (text, { length } = options) => {
        if (text && angular.isString(text)) {
            return text.trim().substring(0, length).trim() + (text.length > length ? '...' : '');
        } else {
            return '';
        }
    };
});
