/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
function DeferVisibleDirective (deferVisibleService) {
    return {
        link (scope, $element, attrs) {
            let element = $element.get(0);

            let off = deferVisibleService.hook(({ height } = stats) => {
                let { top } = element.getBoundingClientRect();

                if (top > 0 && top < height) {
                    let unbindHook = scope.$eval(attrs.deferVisible);

                    // If the callback returns a boolean, unbind the hook, if it returns anything else
                    // keep the hook alive until it does return something. Be careful.
                    if (unbindHook === true || unbindHook === false) {
                        off();
                    }
                }
            });

            scope.$on('$destroy', off);
        }
    };
}

function DeferVisibleService ($rootScope, $timeout, callbackService, eventService) {
    let force = 0,
        hooks = [],
        stats = { top: 0, height: 0 },
        viewElement,
        scrollEvent;

    function update (hooks) {
        callbackService.all(hooks, undefined, [stats]);
    }

    function hookView () {
        viewElement = angular.element('.slide').last().get(0);

        if (angular.isFunction(scrollEvent)) {
            callbackService.safe(scrollEvent);
        }

        if (viewElement) {
            scrollEvent = eventService.on(viewElement, 'scroll', handleScroll);
            handleScroll();
        }
    }

    function handleScroll () {
        stats.top = viewElement.scrollTop;
        force++;
    }

    function handleResize () {
        stats.height = window.innerHeight - 100;
        force++;
    }

    $rootScope.$on('$routeChangeSuccess', () => {
        $timeout(() => hookView(), 1);
    });

    eventService.on(window, 'resize', handleResize);

    $timeout(() => hookView(), 1);

    $rootScope.$watch(() => force, () => update(hooks));
    $rootScope.$watchCollection(() => hooks, update);

    hookView();
    handleResize();

    return {
        hook (callback) {
            hooks.push(callback);

            return function () {
                let index = hooks.indexOf(callback);

                if (index !== -1) {
                    hooks.splice(index, 1);
                }
            };
        },
        recalculate () {
            force++;
        }
    };
}

app
    .service('deferVisibleService', DeferVisibleService)
    .directive('deferVisible', DeferVisibleDirective);
