/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class DragController {
    data = null;
    whenAllowDrag = null; // Invoked to check whether the drag is allowed to be dragged
    whenDragOnto = null; // Invoked to check whether the drag can be dropped onto a drop
    whenDrag = null; // Invoked when the drag has been dropped (drop data passed)
}

class DropController {
    data = null;
    whenAllowDrop = null; // Invoked to check whether the drop can be dropped onto
    whenDropOnto = null; // Invoked to check whether the drop can be dropped onto by a drag
    whenDrop = null; // Invoked when a drag has been dropped onto it (drag data passed)
}

class DragDropService {
    constructor (eventService) {
        this.$$ = { eventService };
    }

    cloneElement (element, offset) {
        let ghost = angular.element('<div />')
            .addClass('app__drag-drop__clone')
            .html(element.clone(false).css({
                width: element.outerWidth()
            }))
            .appendTo('body');

        return {
            move (x, y) {
                ghost.css('transform', 'translate3d(' + (x - offset.left) + 'px, ' + (y - offset.top) + 'px, 0)');
                //ruler('x-o', 'red', 'red', 0, null, 0, x);
                //ruler('y-o', 'red', 'red', y, 0, null, 0);
                //ruler('x-f', 'blue', 'blue', 0, null, 0, x - offset.left);
                //ruler('y-f', 'blue', 'blue', y - offset.top, 0, null, 0);
            },
            remove () {
                ghost.remove();
            }
        };
    }

    register (dragCtrl, element) {
        this.$$.eventService.on(element, 'mousedown', (event) => {

            let clientRect = element.get(0).getBoundingClientRect(),
                offset = {
                    top: event.pageY - clientRect.top,
                    left: event.pageX - clientRect.left
                };

            //ruler('x-r', 'green', 'green', 0, null, 0, clientRect.left);
            //ruler('y-r', 'green', 'green', clientRect.top, 0, null, 0);

            let mousemove = this.$$.eventService.once(window, 'mousemove', (event) => {
                if (inspect(dragCtrl.whenAllowDrag(), '<= dragCtrl.whenAllowDrag')) {
                    let dropElement, dropScope, dropCtrl;

                    let clone = this.cloneElement(element, offset),
                        mouseover = this.$$.eventService.on(window, 'mouseover', (event) => {
                            if (dropElement) dropElement.removeClass('app__drag-drop__onto');
                            dropElement = angular.element(event.target).closest('[drop]');
                            dropScope = dropElement.scope();

                            if (dropScope && dropScope.dropCtrl) {
                                dropCtrl = dropScope.dropCtrl;

                                if (inspect(dropCtrl.whenAllowDrop(), '<= dropCtrl.whenAllowDrop') &&
                                    inspect(dragCtrl.whenDragOnto({ data: dropCtrl.data }), '<= dragCtrl.whenDragOnto') &&
                                    inspect(dropCtrl.whenDropOnto({ data: dragCtrl.data }), '<= dropCtrl.whenDropOnto')) {
                                    dropElement.addClass('app__drag-drop__onto');
                                } else {
                                    dropCtrl = null;
                                }
                            } else {
                                dropCtrl = null;
                            }
                        }),
                        mousemove = this.$$.eventService.on(window, 'mousemove', (event) => {
                            clone.move(event.pageX, event.pageY);
                        }),
                        mouseup = this.$$.eventService.once(window, 'mouseup', () => {
                            if (dropCtrl) {
                                dropElement.removeClass('app__drag-drop__onto');
                                dragCtrl.whenDrag({ data: dropCtrl.data });
                                dropCtrl.whenDrop({ data: dragCtrl.data });
                            }

                            clone.remove();
                            mousemove();
                            mouseover();
                        });
                        clone.move(event.pageX, event.pageY);
                }
            });

            this.$$.eventService.once(window, 'mouseup', () => {
                mousemove();
            });
        });
    }
}

function DragDirective (directiveHelper, dragDropService) {
    return {
        require: ['drag'],
        controller: DragController,
        controllerAs: 'dragCtrl',

        link (scope, element, attr, [dragCtrl]) {
            directiveHelper.oneWayBinding(scope, attr, 'dragData', dragCtrl, 'data');
            directiveHelper.callbackBinding(scope, attr, 'whenAllowDrag', dragCtrl, 'whenAllowDrag');
            directiveHelper.callbackBinding(scope, attr, 'whenDragOnto', dragCtrl, 'whenDragOnto');
            directiveHelper.callbackBinding(scope, attr, 'whenDrag', dragCtrl, 'whenDrag');
            dragDropService.register(dragCtrl, element);
        }
    };
}

function DropDirective (directiveHelper) {
    return {
        require: ['drop'],
        controller: DropController,
        controllerAs: 'dropCtrl',

        link (scope, element, attr, [dropCtrl]) {
            directiveHelper.oneWayBinding(scope, attr, 'dropData', dropCtrl, 'data');
            directiveHelper.callbackBinding(scope, attr, 'whenAllowDrop', dropCtrl, 'whenAllowDrop');
            directiveHelper.callbackBinding(scope, attr, 'whenDropOnto', dropCtrl, 'whenDropOnto');
            directiveHelper.callbackBinding(scope, attr, 'whenDrop', dropCtrl, 'whenDrop');
        }
    };
}

app
    .service('dragDropService', DragDropService)
    .directive('drag', DragDirective)
    .directive('drop', DropDirective);
