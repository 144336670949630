/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
angular
    .module('ppxGlobalControllers')
    .factory('domService', function ($q, $rootScope, $location, $timeout, eventService, callbackService) {
        var domService

        return domService = {
            /**
             * Returns the parent element of another element of which is scrolls within.
             *
             * Defaults to the body element if no matches were found.
             *
             * @param {angular.element|String} element
             * @param {Boolean} includeHidden
             * @returns {angular.element}
             */
            scrollParent: function (element, includeHidden) {
                if (angular.isString(element)) {
                    element = angular.element(element);
                }

                if (element.length > 1) {
                    console.warn('domService.scrollParent given multiple elements, using first matching element.\nBe warned, this may not reap the correct behaviour if the intended element is not the first occurence in the document.');

                    element = angular.element(element[0]);
                }

                var position = element.css('position'),
        			excludeStaticParent = position === 'absolute',
        			overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/,
        			scrollParent = element.parents().filter(function () {
        				var parent = angular.element(this);
        				if (excludeStaticParent && parent.css('position') === 'static') {
        					return false;
        				}
        				return overflowRegex.test(parent.css('overflow') + parent.css('overflow-y') + parent.css('overflow-x'));
        			}).eq(0);

                return position === 'fixed' || ! scrollParent.length ? angular.element(element[0].ownerDocument || document) : scrollParent;
            },
            /**
             * Scrolls the element into view.
             *
             * @param {angular.element|String} element
             * @param {Number} [time]
             * @param {Number} [additional]
             * @param {Boolean} [immediate]
             * @returns {$q}
             */
            scrollTo: function (element, time, additional, immediate) {
                var deferred = $q.defer();

                function handleDomServiceScrollTo () {
                    if (angular.isString(element)) {
                        element = angular.element(element);
                    }

                    var parent = domService.scrollParent(element),
                        offset = parent[0].scrollTop + element.offset().top;

                    if ( ! angular.isNumber(time)) {
                        time = 1e3;
                    }

                    if (angular.isNumber(additional)) {
                        offset += additional;
                    }

                    //var cancelAnimate = eventService.on(parent, 'scroll mousedown DOMMouseScroll mousewheel keyup', function (event) {
                    //    if (event.which > 0 || event.type === 'mousedown' || event.type === 'mousewheel') {
                    //        parent.stop();
                    //        cancelAnimate();
                    //    }
                    //});

                    parent.stop().animate({ scrollTop: offset }, time, function () {
                        deferred.resolve();
                    });
                }

                if (immediate) {
                    handleDomServiceScrollTo();
                } else {
                    $timeout(handleDomServiceScrollTo);
                }

                return deferred.promise;
            },
            /**
             * Hijacks a function.
             *
             * @param {Object} obj
             * @param {String} name
             * @param {Function} fn
             * @returns {Function}
             */
            hijack: function (obj, name, fn) {
                var old = obj[name];

                obj[name] = function () {
                    fn.call(this, old, this, [].slice.apply(arguments));
                };

                return callbackService.once(function () {
                    obj[name] = old;
                });
            },
            _fullscreen: (function () {
                var element = 'fullscreenElement',
                    request = 'requestFullscreen',
                    exit = 'exitFullscreen';

                if ('mozFullScreenElement' in document) {
                    element = 'mozFullScreenElement';
                    request = 'mozRequestFullScreen';
                    exit = 'mozCancelFullScreen';
                }

                else if ('webkitFullscreenElement' in document) {
                    element = 'webkitFullscreenElement';
                    request = 'webkitRequestFullscreen';
                    exit = 'webkitExitFullscreen';
                }

                else if ('msFullscreenElement' in document) {
                    element = 'msFullscreenElement';
                    request = 'msRequestFullscreen';
                    exit = 'msExitFullscreen';
                }

                return {
                    element: element,
                    request: request,
                    exit: exit
                };
            })(),
            /**
             *
             * @param {angular.element|Element} element
             */
            fullscreen: function (element) {
                if (element instanceof angular.element.fn.constructor) {
                    element = element.get(0);
                }

                if ( ! (document[this._fullscreen.element])) {
                    (element[this._fullscreen.request])();
                    return true;
                } else {
                    (document[this._fullscreen.exit])();
                    return false;
                }
            },

            isFullScreen: function () {
                return (document[this._fullscreen.element]);
            }
        };
    });
