/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.factory('PPProofWorkflow', function (PPModel) {
    const proofWorkflowTransformer = {
        skipCount: 'CountSkip',
        completeCount: 'CountComplete',
        description: 'Description',
        name: 'Title',
        ownerId: 'UserId',
        id: 'WorkflowId'
    };

    class PPProofWorkflow extends PPModel {
        /**
         * The array of workflow steps.
         *
         * @type {PPProofWorkflowStep[]}
         */
        steps = [];

        /**
         * The array of workflow managers.
         *
         * @type {PPUser[]}
         */
        workflowManagers = [];

        /**
         * The array of workflow manager user ids.
         *
         * @type {PPUser[]}
         */
        workflowManagerIds = [];

        /**
         * Update the workflow from the workflow data.
         *
         * @param {Object} data
         */
        updateFromProofWorkflowData (data) {
            this.transform(data, proofWorkflowTransformer);
        }

        /**
         * Returns the current step.
         *
         * @returns {PPProofWorkflowStep}
         */
        getCurrentStep () {
            let currentStep;

            // Reverse the steps array and find the first match...
            this.steps.slice().reverse().some((step) => {
                // Grab the first step if it's complete and visible
                if (step.isComplete === false && step.isVisible === true) {
                    currentStep = step;
                    return true;
                }
            });

            // Return the current step
            return currentStep;
        }

        /**
         * Create a proof workflow with a proof workflow data.
         *
         * @param {Object} proofWorkflowData
         */
        static from (proofWorkflowData) {
            let proofWorkflow = new this();
            proofWorkflow.updateFromProofWorkflowData(proofWorkflowData);
            return proofWorkflow;
        }
    }

    return PPProofWorkflow;
});
