/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class ToolbarController {
    padding = 0;
    tools = [];
    positions = [];

    addTool(toolbarTool) {
        this.tools.push(toolbarTool);
        return this.removeTool.bind(this, toolbarTool);
    }

    removeTool(toolbarTool) {
        const index = this.tools.indexOf(toolbarTool);
        if (index !== -1) {
            this.tools.splice(index, 1);
        }
    }

    calculateToolWidths() {
        this.tools.forEach((toolbarTool) => toolbarTool.getWidthOrCalculate());
    }

    calculateToolPositions() {
        let position = 0;
        this.tools.forEach((toolbarTool, index) => {
            console.log(position);
            this.positions[index] = position;
            if (toolbarTool.isVisible) {
                position += toolbarTool.getWidthOrCalculate();
                position += this.padding;
            }
        });
    }

    getToolPosition(toolbarTool) {
        return this.positions[this.tools.indexOf(toolbarTool)];
    }

    visibilityDidChange() {
        console.log('visibility did change');
        this.calculateToolWidths();
        this.calculateToolPositions();
        this.tools.forEach((toolbarTool, index) => {
            toolbarTool.position = this.positions[index];
        });
    }
}

class ToolbarToolController {
    _hasJustCalculated = false;

    width = 0;
    calculatedWidth = 0;

    /**
     * @constructor
     */
    constructor() {
        this.$$ = {};
    }

    /**
     * Gets the actual width of the tool (either calculated or specified).
     *
     * @returns {number}
     */
    getWidth() {
        return this.width || this.calculatedWidth;
    }

    /**
     *
     */
    getWidthOrCalculate() {
        if ( ! this.width && ! this._hasJustCalculated) {
            return this.calculateWidth();
        }
        return this.getWidth();
    }

    /**
     * Update the known calculated width.
     *
     * @returns {number}
     */
    calculateWidth() {
        this._hasJustCalculated = true;
        this.$$.$scope.$applyAsync(() => this._hasJustCalculated = false);

        this.calculatedWidth = this.$$.$element.get(0).getBoundingClientRect().width;
        return this.calculatedWidth;
    }
}

function ToolbarDirective($timeout) {
    return {
        require: ['toolbar'],
        controller: ToolbarController,
        controllerAs: 'toolbarCtrl',
        transclude: true,
        replace: true,
        template: `
            <div
                class="app__toolbar"
                ng-transclude>
            </div>
        `,

        link(scope, element, attr, [toolbar]) {
            $timeout(() => toolbar.visibilityDidChange());
        }
    };
}

function ToolbarToolDirective(directiveHelper) {
    return {
        require: ['^toolbar', 'toolbarTool'],
        controller: ToolbarToolController,
        controllerAs: 'toolbarToolCtrl',
        transclude: true,
        replace: true,
        template: `
            <div
                class="app__toolbar__tool"
                ng-class="{'app__toolbar__tool--visible': toolbarToolCtrl.isVisible}"
                ng-style="{'transform': 'translateX(' + toolbarToolCtrl.position + 'px) translateY(-50%)'}"
                ng-transclude>
            </div>
        `,
        scope: true,

        link(scope, element, attr, [toolbar, toolbarTool]) {
            toolbarTool.$$.$scope = scope;
            toolbarTool.$$.$element = element;

            directiveHelper.oneWayBinding(scope, attr, 'width', toolbarTool, 'width');
            directiveHelper.oneWayBinding(scope, attr, 'isVisible', toolbarTool, 'isVisible');

            scope.$watch(attr.isVisible, (isVisible, previousIsVisible) => {
                if (isVisible !== previousIsVisible) {
                    toolbar.visibilityDidChange(toolbarTool);
                }
            });

            scope.$on('$destroy', toolbar.addTool(toolbarTool));
        }
    };
}

app.directive('toolbar', ToolbarDirective);
app.directive('toolbarTool', ToolbarToolDirective);