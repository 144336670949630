/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.factory('PPProofComment', function ($q, PPModel, PPProofCommentAttachment, PPProofCommentSnapshot, PPCommentMarkType) {
    let { booleanTransformer, dateTransformer, numberTransformer, truthyTransformer, jsonTransformer } = PPModel;

    const proofCommentTransformer = {
        agrees: 'Agrees',
        hasAttachment: truthyTransformer('Attachments'),
        isTodo: booleanTransformer('Change'),
        id: 'CommentId',
        createdAt: dateTransformer('Created'),
        isDone: booleanTransformer('Done'),
        isChange: booleanTransformer('Change'),
        isPrivate: 'Private',
        ownerEmail: 'Email',
        encryptedComment: 'EncryptedComment',
        envelope: 'Envelope',
        pageNumber: numberTransformer('Page'),
        proofId: 'ProofId',
        updatedAt: dateTransformer('Updated'),
        editedDate: dateTransformer('EditedDate'),
        editedByUserId: 'EditedByUserId',
        hasBeenUpdated: (data, comment) => comment.createdAt.isBefore(comment.editedDate),
        ownerId: 'UserId',
        metadata: jsonTransformer('Metadata', 'object'),
        sourceMetadata: 'SourceMetadata',
        pins: (data) => window.__pageproof_quark__.sdk.util.pins.parse(data.XY).map((pin, index) => {
            // If there's a first (primary) pin, and a box fill, attach the fill with the pin object
            if (index === 0 && data.BoxFillType) {
                pin.fill = {
                    type: data.BoxFillType,
                    image: data.BoxFillImage,
                };
            }
            return pin;
        }),
        parentId: 'Parent',
        mentionedIds: 'MentionedIds',
        number: 'Number',
        attachments: (data, comment) => {
            const index = {};
            if (comment.attachments) {
                comment.attachments.forEach((attachment) => {
                    index[attachment.id] = attachment;
                });
            }

            if (data.Attachments) {
                return data.Attachments.map((attachmentData) => {
                    const existingAttachment = index[attachmentData.FileId];
                    if (existingAttachment) {
                        existingAttachment.updateFromProofCommentData(attachmentData);
                        return existingAttachment;
                    } else {
                        return PPProofCommentAttachment.from(attachmentData);
                    }
                });
            }

            return comment.attachments || [];
        },
        snapshot: (data, comment) => {
            if (data.Snapshot && (!comment.snapshot || !('$progress' in comment.snapshot))) {
                return PPProofCommentSnapshot.from(data);
            }
        }
    };

    class PPProofComment extends PPModel {
        /**
         * The colors possible for highlighting (used for `orbSelect` directive).
         *
         * @type {Object[]}
         */
        static highlightColors = [
            {name: 'None', color: 'gray', value: null},
            {name: 'Approved', color: 'green', value: 'Approved'},
            {name: 'Highlight', color: 'red', value: 'Highlight'}
        ];

        /**
         * The names of the possible highlights.
         *
         * @type {string[]}
         */
        static highlightNames = [
            'Approved', 'Highlight'
        ];

        /**
         * This value is populated when a comment has been/is scheduled to be decrypted.
         *
         * It is used to check whether an 'encryptedComment' should be decrypted.
         *
         * @type {String}
         */
        $encryptedComment = null;

        /**
         * Whether the comment has a file attached.
         *
         * @type {Boolean}
         */
        hasAttachment = false;

        /**
         * Whether the comment is marked as a to-do.
         *
         * @type {Boolean}
         */
        isTodo = false;

        /**
         * The comment id.
         *
         * @type {String}
         */
        id = null;

        /**
         * When the comment was created.
         *
         * @type {moment}
         */
        createdAt = null;

        /**
         * Whether the comment is marked as done.
         *
         * @type {Boolean}
         */
        isDone = false;

        /**
         * The email of the user who owns the comment.
         *
         * @type {String}
         */
        ownerEmail = null;

        /**
         * The encrypted value of the comment.
         *
         * @type {String}
         */
        encryptedComment = null;

        /**
         * The envelope of the comment.
         *
         * @type {String}
         */
        envelope = null;

        /**
         * The proof id of the proof the comment is on.
         *
         * @type {String}
         */
        proofId = null;

        /**
         * When the comment was last updated (agree, comment change, etc...)
         *
         * @type {moment}
         */
        updatedAt = null;

        /**
         * The last date and time the comment was edited.
         *
         * @type {moment}
         */
        editedDate = null;

        /**
         * The user id, who has last edited the comment.
         *
         * @type {String}
         */
        editedByUserId = null;

        /**
         * The user if of the user who owns the comment.
         *
         * @type {String}
         */
        ownerId = null;

        /**
         * The pin data for the comment.
         *
         * Note: This object is passed directly to a `pin` directive in order for it to render correctly.
         *
         * Warning: Make sure this value is also updated when x/y is updated - and make sure to update the x/y
         * values of the actual comment object when these values change.
         *
         * @see {PinController.whenUpdate}
         * @type {Pin[]}
         */
        pins = [];

        /**
         * The comment if of a parent (if the comment is a reply).
         *
         * Note: This value is only set if the comment is a reply - normal comments are null.
         *
         * @type {String|null}
         */
        parentId = null;

        /**
         * The comment's attachments.
         *
         * @type {Array<PPProofCommentAttachment>}
         */
        attachments = [];

        /**
         * The decrypted comment text.
         *
         * @type {String}
         */
        decryptedComment = null;

        /**
         * The comment text for a proof comment.
         *
         * Note: This property is used for user defined changes to a comment.
         *
         * @type {String}
         */
        comment = null;

        /**
         * The owner of the comment's user object.
         *
         * @type {PPUser}
         */
        ownerUser = null;

        /**
         * The array of comment replies.
         *
         * @type {PPProofComment[]}
         */
        replies = [];

        /**
         * The user ids for users who agree with the comment.
         *
         * @type {String[]}
         */
        agrees = [];

        /**
         * The page number the comment is on.
         *
         * @type {Number}
         */
        pageNumber = 0;

        /**
         * Array of user ids which have been mentioned in the comment.
         *
         * @type {String[]}
         */
        mentions = [];

        /**
         * The additional (unstructured) metadata attached to the comment.
         *
         * @type {object}
         */
        metadata = {};

        /**
         * Metadata about where the comment was sourced. 
         *
         * @type {object}
         */
        sourceMetadata = {};

        /**
         * A flag, which tells if comment has been saved or not
         *
         * @type {Boolean}
         */
        isSaved = true;

        /**
         * @constructor
         */
        constructor () {
            super();

            /**
             * Returns the cached parsed label.
             *
             * @type {Function}
             */
            this.getCachedLabel = cachedIO(
                () => this.comment || this.decryptedComment,
                (comment) => PPProofComment.parseLabel(comment)
            );

            /**
             * Returns the cached created date.
             *
             * @type {Function}
             */
            this.getCachedCreatedDate = cachedIO(
                () => +this.createdAt,
                () => this.createdAt.fromNow()
            );

            /**
             * Returns the cached formatted metadata.
             *
             * @type {Function}
             */
            this.getCachedFormattedMetadata = cachedIO(
                () => JSON.stringify(this.metadata),
                () => this.getFormattedMetadata()
            );
        }

        /**
         * Adds a/or many replies to the replies array.
         *
         * @param {...PPProofComment} replies
         */
        addReplies (...replies) {
            replies.forEach(reply => this.replies.push(reply));
        }

        /**
         * Removes a/or many replies to the replies array.
         *
         * @param {...PPProofComment} replies
         */
        removeReplies (...replies) {
            replies.forEach((reply) => {
                let index = this.replies.indexOf(reply);
                if (index !== -1) {
                    this.replies.splice(index, 1);
                }
            });
        }

        serialiseCommentData() {
            return $q((resolve) => {
                let fileContent = null;
                    resolve({
                        ownerId: this.ownerId,
                        proofId: this.proofId,
                        id: this.id,
                        creationToken: this.creationToken,
                        pageNumber: this.pageNumber,
                        envelope: this.envelope,
                        encryptedComment: this.encryptedComment,
                        decryptedComment: this.decryptedComment,
                        comment: this.comment,
                        pins: this.pins,
                        parentId: this.parentId,
                        mentions: this.mentions,
                        metadata: this.metadata,
                        sourceMetadata: this.sourceMetadata,
                        createdAt: this.createdAt,
                        attachment: this.attachment,
                        attachments: this.attachments,
                        snapshot: this.snapshot,
                        isPrivate: this.isPrivate,
                        isTodo: this.isTodo,
                        file: (this.attachment ? this.attachment.$file : null),
                        fileData: fileContent
                    });
            });
        }

        deSerialiseCommentData(data) {
            this.ownerId = data.ownerId,
            this.proofId = data.proofId,
            this.id = data.id,
            this.creationToken = data.creationToken,
            this.pageNumber = data.pageNumber,
            this.envelope = data.envelope,
            this.encryptedComment = data.encryptedComment,
            this.decryptedComment = data.decryptedComment,
            this.comment = data.comment,
            this.pins = data.pins,
            this.parentId = data.parentId,
            this.mentions = data.mentions,
            this.metadata = data.metadata,
            this.sourceMetadata = data.sourceMetadata,
            this.createdAt = data.createdAt,
            this.attachment = data.attachment,
            this.attachments = data.attachments,
            this.fileData = data.fileData,
            this.snapshot = data.snapshot,
            this.isPrivate = data.isPrivate,
            this.isTodo = data.isTodo
        }

        /**
         * Whether the proof comment is a parent.
         *
         * @returns {Boolean}
         */
        get isParent () {
            return !! ( ! this.parentId || ! this.parentId.length);
        }

        /**
         * Whether the proof comment is a reply.
         *
         * @returns {Boolean}
         */
        get isReply () {
            return !! (this.parentId && this.parentId.length);
        }

        /**
         * Whether the comment is a marquee (the pin is a box).
         *
         * @returns {Boolean}
         */
        get isMarquee () {
            return this.x2 !== null && this.y2 !== null;
        }

        /**
         * Whether the comment is a line.
         *
         * @returns {Boolean}
         */
        get isLine () {
            // If the x2 or y2 have their own location, and they're not both the same
            return (this.x2 === null || this.y2 === null) && this.x2 !== this.y2;
        }

        /**
         * Whether the user (by userId) agrees with the comment.
         *
         * @param {String} userId
         */
        doesUserAgree (userId) {
            return this.agrees.indexOf(userId) !== -1;
        }

        /**
         * Updates the proof from a proof data object.
         *
         * @param {Object} data
         */
        updateFromProofCommentData(data) {
            this.transform(data, proofCommentTransformer);
        }

        /**
         * Updates the comment status from a comment Mark Type.
         * @param {Object} data
         * @param {String} markType
         * @param {Boolean} state
         */
        updateFromCommentMarkType(data, markType, state = true) {
            const { TODO, DONE, UNMARKED } = PPCommentMarkType;
            if (markType === DONE) {
                data.isDone = state;
            } else if (markType === TODO) {
                data.isTodo = state;
                data.isDone = false;
            } else if (markType === UNMARKED) {
                data.isTodo = false;
                data.isDone = false;
            }
        }

        /**
         * Parses a comment for it's label.
         *
         * @param {String} comment
         * @returns {String|null}
         */
        static parseLabel (comment) {
            return window.generalfunctions_getCommentLabel(comment);
        }

        /**
         * Replaces a comments label.
         *
         * @param {String} comment
         * @param {String} label
         * @returns {String}
         */
        static replaceLabel (comment, label) {
            if (comment === null) {
                comment = '';
            }

            let currentLabel = PPProofComment.parseLabel(comment);

            if (currentLabel === label) {
                return comment; // Nothing needs to be replaced, it's the same
            }

            if (label) {
                if (currentLabel) {
                    // Just pull off anything at the beginning (before the colon)
                    return label + comment.substring(comment.indexOf(':'));
                } else {
                    // Prepend the label (and add a space if the comment doesn't start with one)
                    return label + ':' + (comment[0] !== ' ' ? ' ' : '') + comment;
                }
            } else {
                if (currentLabel) {
                    // Required if the comment has a space after the colon
                    let offset = 1 + (comment.indexOf(':') === comment.indexOf(': ') ? 1 : 0);

                    // Removing the label, grab everything after the first colon
                    return comment.substring(comment.indexOf(':') + offset);
                } else {
                    // Removing a label with no label already
                    return comment;
                }
            }
        }

        /**
         * Gets a user defined label (prefixing the comment text).
         *
         * Note: The label matches the case the user provided, so ensure you normalise the case before
         * attempting to verify predefined labels with attached logic.
         *
         * @returns {String|null}
         */
        getLabel () {
            return this.getCachedLabel(); // Cached IO (see constructor)
        }

        /**
         * Whether the comment has a label defined (prefixing the comment text).
         *
         * Note: This will always be `null` before comment decryption (as it's only retrievable from the
         * decrypted comment text).
         *
         * @param {String} [has]
         * @returns {Boolean}
         */
        hasLabel (has = null) {
            let label = this.getLabel();

            if (has) {
                if (label) {
                    return String(has).toLowerCase() === String(label).toLowerCase();
                } else {
                    return false;
                }
            } else {
                return label !== null;
            }
        }

        /**
         * Returns the highlight color of the comment.
         *
         * @returns {String}
         */
        getHighlight () {
            if (this.hasLabel()) {
                let label = String(this.getLabel()).toLowerCase();

                switch (label) {
                    case 'approved': return 'green';
                    case 'highlight': return 'yellow';
                }
            }
            return null;
        }

        /**
         * Whether the encrypted comment has been decrypted.
         *
         * @returns {Boolean}
         */
        hasDecrypted () {
            return this.$encryptedComment !== this.encryptedComment;
        }

        /**
         * Whether the comment has metadata attached.
         *
         * @returns {boolean}
         */
        hasMetadata() {
            return Object.keys(this.getMetadata()).length >= 1;
        }

        /**
         * Returns the metadata of the comment.
         *
         * @returns {object}
         */
        getMetadata() {
            return this.metadata || {};
        }

        /**
         * Sets the metadata object (overriding all existing data).
         *
         * @param {object} data
         */
        setMetadata(data) {
            this.metadata = data;
        }

        /**
         * Returns the source metadata of the comment.
         *
         * @returns {object}
         */
        getSourceMetadata() {
            return this.sourceMetadata || {};
        }

        /**
         * Merge the `data` argument with the existing metadata. If the comment doesn't have any metadata, a new empty object
         * is created, and the additional data is merged with it.
         *
         * @param {object} data
         * @returns {object}
         */
        mergeMetadata(data) {
            if (!this.metadata) {
                this.metadata = {};
            }
            $.extend(this.metadata, data);
            return this.metadata;
        }

        /**
         * Gets the metadata as a string, to be used to send to the server.
         *
         * @returns {string}
         */
        getMetadataJSON() {
            if (this.metadata) {
                return JSON.stringify(this.metadata);
            } else {
                return '{}';
            }
        }

        getFormattedMetadata() {
            const STATIC_ENDPOINT_MATCH = /^https?:\/\/(.*)\.static\.pageproof\.com$/;
            const formatted = {};
            if (this.metadata && this.metadata.page && this.metadata.page.path) {
                const fakePrefix = 'https://localhost';
                const url = new URL(this.metadata.page.path, fakePrefix);
                if (url.origin.match(STATIC_ENDPOINT_MATCH)) {
                    // Manually fake the origin for all px-static urls
                    url.protocol = 'https:';
                    url.host = 'localhost';
                }
                formatted.path = {
                    before: '',
                    middle: url.pathname,
                    after: url.search + url.hash,
                };
                if (url.origin !== fakePrefix) {
                    formatted.path.before = url.origin;
                }
            }
            return formatted;
        }

        // DEPRECATION WARNINGS

        get isChange () {
            console.error('PPProofComment.isChange will be removed in the near future. Please use PPProofComment.isTodo instead, as isChange is not updated within the app.');
            return this.DEPRECATED_isChange;
        }

        set isChange (value) {
            return this.DEPRECATED_isChange = value;
        }

        /**
         * Creates a ProofComment object from a proof comment data object.
         *
         * @param {Object} proofCommentData
         */
        static from(proofCommentData) {
            let proofComment = new this();
            proofComment.updateFromProofCommentData(proofCommentData);
            return proofComment;
        }
    }

    return PPProofComment;
});
