/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.factory('PPProofCommentSnapshot', function (PPModel, PPFile, PPFileStatus) {
    const proofCommentSnapshotTransformer = {
        id: 'Snapshot',
        extension: 'SnapshotExtn',
        name: 'SnapshotName',
        chunks: 'SnapshotChunks',
        status: 'SnapshotStatus',
    };

    class PPProofCommentSnapshot extends PPFile {
        /**
         * This is always set to `1`, as a snapshot is always an image.
         * 
         * @type {number}
         */
        numberOfPages = 1;

        /**
         * The status of the file.
         *
         * Options
         *  - OK
         *
         * @type {String}
         */
        status = null;

        /**
         * Returns whether the attachment has finished processing.
         *
         * Checks if the attachment has an ID (uploading files do not have an ID), and checks that the status
         * is set to 'OK' which is confirmation the file has finished ripping.
         *
         * @returns {Boolean}
         */
        get hasProcessed () {
            return this.id && this.status === PPFileStatus.OK;
        }

        /**
         * Update the proof comment data from the comment data.
         *
         * @param {Object} data
         */
        updateFromProofCommentData (data) {
            this.transform(data, proofCommentSnapshotTransformer);
        }

        /**
         * Create a proof comment attachment with a comment data object.
         *
         * @param {Object} proofCommentData
         */
        static from (proofCommentData) {
            let proofCommentAttachment = new this();
            proofCommentAttachment.updateFromProofCommentData(proofCommentData);
            return proofCommentAttachment;
        }

        deSerialiseSnapshotData (snapshot) {
            this.numberOfPages = snapshot.numberOfPages,
            this.status = snapshot.status
        }

        /**
         * The type of "attachment", alternatives to this type are "snapshot".
         *
         * @type {string}
         */
        static type = 'snapshot';
    }

    return PPProofCommentSnapshot;
});
