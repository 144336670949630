/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

try {
    const searchParams = new URLSearchParams(location.search);
    // eg. ?rainbow-date=01/06/2021
    var simulateRainbowDate = searchParams.get('rainbow-date');
    // eg. ?rainbow-hash=ac42e1de
    var simulateRainbowHash = searchParams.get('rainbow-hash');
} catch (err) {}

class RainbowService {
    constructor($q, utilService, userService, browserService) {
        this.$$ = {$q, utilService, userService, browserService};
        this.sha512 = (...args) => utilService.sha512(args.join(' ')).substring(0, 8);
    }
    load(key) {
        let hooks = {};
        const hash = simulateRainbowHash || this.sha512(simulateRainbowDate || moment().format('DD/MM/YYYY'), key);
        const browser = this.$$.browserService.name;

        window['_rainbow' + hash] = (_hooks) => {
            hooks = _hooks;
            delete window['_rainbow' + hash];
        };

        const ready = this.$$.$q.all([
            this.$$.utilService.loadScript('/rainbow/?' + hash + '/script'),
            this.$$.utilService.loadStyle('/rainbow/?' + hash + '/style')
        ]).then(() => hooks);

        return new RainbowHooks(ready, this.$$.userService.isLoggedIn()
            ? this.$$.utilService.md5(this.$$.userService.getUser().id) : null, browser);
    }
}

class RainbowHooks {
    constructor(ready, userHash, browser) {
        this.hooks = {};
        this.ready = ready
            .then((hooks) => (this.hooks = $.extend({}, hooks)))
            .then((hooks) => {
                if (hooks.killjoys && hooks.killjoys.indexOf(userHash) !== -1) {
                    return new Promise(() => { /* never resolve the promise for people who have opted-out of the rainbow feature */ });
                }
                return hooks;
            });
        this.call('onRainbowLoad', userHash, browser);
    }

    call(method, ...args) {
        return this.ready.then(() => {
            if (Object.prototype.hasOwnProperty.call(this.hooks, method)) {
                return this.hooks[method].apply(this.hooks, args);
            }
        });
    }
}

app.service('rainbowService', RainbowService);
