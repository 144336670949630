/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.constant('PPProofStatus', createEnumeration({
    NEW: 0,
    PROOFING: 10,
    FINAL_APPROVING: 30,
    CHANGES_REQUESTED: 50,
    AWAITING_NEW_VERSION: 60,
    HAS_NEW_VERSION: 66,
    APPROVED: 70,
    CLOSED: 100
}));
