/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
angular
    .module('ppxGlobalControllers')
    .value('mousetrap', window.Mousetrap)
    .factory('mousetrapService', function ($rootScope, mousetrap) {
        /**
         * Wraps a function from outside of angular, and runs a $digest.
         * Returns a function that prevents the callback from being fired.
         *
         * @param {Function} callback
         * @returns {Function}
         */
        function applyCallback (callback) {
            var canceled = false;

            /**
             * If the callback has not yet been canceled, invoke the callback
             * and run an angular $digest. This helps when mousetrap invokes
             * the callback from the native event system.
             *
             * @returns {*|undefined}
             */
            function handler () {
                if ( ! canceled) {
                    var that = this, args = arguments;
                    return $rootScope.$apply(function () {
                        callback.apply(that, args);
                    });
                }
            }

            /**
             * Prevents the callback from being fired.
             *
             * @type {Function}
             */
            handler.cancel = function () {
                canceled = true;
            };

            return handler;
        }

        return {
            /**
             * Watches for keyboard shortcut.
             *
             * @param {String|Array} shortcut
             * @param {Function} callback
             * @returns {Function}
             */
            watch: function (shortcut, callback) {
                var handler = applyCallback(callback);
                mousetrap.bind(shortcut, handler);
                return handler.cancel;
            },
            /**
             * Records the user input.
             *
             * @param {Function} callback
             * @returns {Function}
             */
            record: function (callback) {
                var handler = applyCallback(callback);
                mousetrap.record(handler);
                return handler.cancel;
            }
        };
    });
