/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
function AutoGrowDirective($timeout) {
    const target = '.auto-textarea';

    function getCalculatedHeight(meta, element) {
        const style = getComputedStyle(element);
        let height = element.scrollHeight;
        const defaultHeight = 28;
        if (!element.scrollHeight) {
            height = defaultHeight;
        }
        const borderWidth = parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth);
        const calculatedHeight =  borderWidth + height + 'px';
        return calculatedHeight;
    }

    function autogrow(meta, element) {
        if (element.value !== meta.value && (element.value || meta.value)) {
            meta.value = element.value;
            element.style.setProperty('height', 0, 'important');
            element.style.setProperty('height', getCalculatedHeight(meta, element), 'important');
        }
    }

    return {
        restrict: 'E',
        require: ['?ngModel'],
        link(scope, element, attr, [modelCtrl]) {
            if (element.is(target)) {
                const meta = {
                    value: null
                };
                const bound = autogrow.bind(null, meta, element[0]);

                $timeout(bound, true);
                scope.$evalAsync(bound);
                element.on('input', bound);

                if (modelCtrl) {
                    modelCtrl.$formatters.push((value) => {
                        scope.$evalAsync(bound);
                        return value;
                    });
                }
            }
        }
    };
}

app.directive('textarea', AutoGrowDirective);