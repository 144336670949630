/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const template = `
    <div class="typeahead {{reversed}}" style="{{inlineStyles}}" ng-show="addressBookCtrl.isEnabled && addressBookCtrl.filterBy && addressBookCtrl.filteredOptions.length">
        <ul class="typeahead__options">
            <li ng-click="use(option)" tabindex="0" class="typeahead__option" ng-mouseover="addressBookCtrl.selectedIndex = $index" ng-class="{ 'typeahead__option--selected': $index === addressBookCtrl.selectedIndex }" ng-repeat="option in addressBookCtrl.filteredOptions track by $index ">
                <span ng-if="addressBookCtrl.useFilterBy" ng-bind-html="option | highlight:addressBookCtrl.filterBy"></span>
                <span ng-if="!addressBookCtrl.useFilterBy" ng-bind-html="option"></span>
                <span class="option-delete" ng-click="delete(option); $event.stopPropagation();">×</span>
            </li>
        </ul>
    </div>
`;

class AddressBookController {
    /**
     *
     * @type {String}
     */
    filterBy = null;

    /**
     *
     * @type {String[]}
     */
    availableOptions = [];

    /**
     *
     * @type {String[]}
     */
    filteredOptions = [];

    /**
     *
     * @type {Boolean}
     */
    isEnabled = false;

    /**
     *
     * @type {Number}
     */
    selectedIndex = 0;

    /**
     *
     * @type {String}
     */
    selectedOption = null;

    /**
     *
     * @type {Boolean}
     */
    useFilterBy = true;

    /**
     *
     * @type {String}
     */
    reversed = "";

    /**
     *
     * @type {String}
     */
    inlineStyles = "";

    selectItem () {
        if (this.onSelect) {
            this.onSelect();
        }
    }

}

function AddressBookDirective ($compile, $filter, scopeService, eventService, $addressBook, addressBookRepositoryService, UserService, directiveHelper) {
    eventService.on($(window), 'search-by-email', (event) => {
        var data = UserService.GetUserData();
        addressBookRepositoryService.get(data.userId).then(function (emailData){
            var index = emailData.emails.indexOf(event.email);
            if (index === -1) {
                emailData.emails.push(event.email);
                addressBookRepositoryService.set(data.userId, emailData);
            }
        });
    });

    return {
        restrict: 'A',
        require: ['addressbook', 'ngModel'],
        controller: 'AddressBookController',
        controllerAs: 'addressBookCtrl',
        scope: true,
        priority: 2,
        link (scope, element, attrs, [addressBookCtrl, ngModel]) {

            let filter = $filter('filter');

            let transcludeElement = $compile(template)(scope);
            transcludeElement.insertAfter(element);

            if (attrs.flowVertical) {
                scope.reversed = "reverse";
            }

            if (attrs.inlineStyles) {
                scope.inlineStyles = attrs.inlineStyles;
            }

            if (attrs.onSelect) {
                directiveHelper.callbackBinding(scope, attrs, 'onSelect', addressBookCtrl, 'onSelect');
            }

            scope.use = function(option) {

                console.log("option: " + option);

                element.val(option);
                ngModel.$setViewValue(option);
                ngModel.$commitViewValue();
                addressBookCtrl.isEnabled = false;
                addressBookCtrl.selectItem();
            };

            scope.delete = function(option) {
                $addressBook.delete(option, function(){
                    element.val("");
                    ngModel.$setViewValue("");
                    ngModel.$commitViewValue();

                    if( addressBookCtrl.filteredOptions.length == 1 && ( addressBookCtrl.filteredOptions[0] == option) ){
                        addressBookCtrl.filteredOptions.length = 0;
                    }
                });
            };

            scope.deleteOption = function(option) {
                var idx = -1;
                addressBookCtrl.availableOptions.forEach(function(item, index){
                    if(item == option){
                        idx = index;
                    }
                });
                if(idx > -1){
                    addressBookCtrl.availableOptions.splice(idx, 1);
                }
            };

            scope.load = function(){
                addressBookCtrl.availableOptions.length = 0;
                $addressBook.load(function(emails){
                    addressBookCtrl.availableOptions = emails;
                    if( emails.length == 0 && addressBookCtrl.isEnabled ) addressBookCtrl.isEnabled = false; //hide the list if its open and there are no emails to show
                    if (!scope.$$phase) {
                        scope.$apply();
                    }
                });
            };

            eventService.on(element, 'keydown keyup', (event) => {
                let keyCode = event.which;
                console.log('addressBookCtrl', addressBookCtrl.selectedOption);
                addressBookCtrl.isEnabled = true;

                if (element.val().length && (keyCode === 13 || keyCode === 9)) {

                    if( addressBookCtrl.selectedOption ) {
                        event.preventDefault();
                        addressBookCtrl.isEnabled = false;

                        element.val(addressBookCtrl.selectedOption);
                        ngModel.$setViewValue(addressBookCtrl.selectedOption);
                        ngModel.$commitViewValue();
                    }
                    addressBookCtrl.isEnabled = false;
                }

                if (event.type === 'keydown' && element.val().length && (keyCode === 38 || keyCode === 40)) {
                    event.preventDefault();
                    addressBookCtrl.selectedIndex += (keyCode === 38 ? -1 : keyCode === 40 ? 1 : 0);
                    addressBookCtrl.selectedIndex = Math.max(0, Math.min(addressBookCtrl.filteredOptions.length - 1, addressBookCtrl.selectedIndex));
                }

            });

            scope.$watch(() => ngModel.$viewValue, (filterBy) => {

                addressBookCtrl.filterBy = filterBy;
            });

            scopeService.watchMany(
                scope,
                [() => addressBookCtrl.availableOptions, () => addressBookCtrl.filterBy],
                ([availableOptions, filterBy]) => {
                    addressBookCtrl.filteredOptions = filter(availableOptions, filterBy);
                }
            );

            scope.$watchCollection(() => addressBookCtrl.filteredOptions, (filteredOptions) => {
                addressBookCtrl.selectedIndex = filteredOptions.indexOf(addressBookCtrl.selectedOption);
            });

            scopeService.watchMany(
                scope,
                [() => addressBookCtrl.selectedIndex, () => addressBookCtrl.filteredOptions],
                ([selectedIndex]) => {
                    addressBookCtrl.selectedIndex = selectedIndex in addressBookCtrl.filteredOptions ? selectedIndex : 0;
                    addressBookCtrl.selectedOption = addressBookCtrl.filteredOptions[addressBookCtrl.selectedIndex] || null;
                }
            );

            var data = UserService.GetUserData();

            addressBookRepositoryService.cache.on('remove', data.userId, function(){
                scope.load();
            });

            scope.load();

            console.log("address book loaded");

        }
    };
}

app.controller('AddressBookController', AddressBookController).directive('addressbook', AddressBookDirective);
