/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
function UserEmailDirective ($parse, backendService) {
    return {
        link(scope, element, attr) {
            let userId = $parse(attr.userId)(scope);
            backendService
                .fetch('user.load', {userId})
                .data()
                .then((user) => {
                    element.text(user.Email);
                });
            //userRepositoryService.get(attr.userId).then((user) => {
            //    element.text(user.email);
            //});
        }
    };
}

app.directive('userEmail', UserEmailDirective);