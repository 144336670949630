/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.constant('PPProofActionType', createEnumeration([
    'COMPLETE_MANDATORY',
    'COMPLETE_NON_MANDATORY',
    'COMPLETE_APPROVER',
    'UNLOCK_PROOF_COMPLETE',
    'COMPLETE_MANDATORY_MESSAGE',
    'TODOS_COMPLETE',
    'REDIRECT_DASHBOARD',
    'REDIRECT_BRIEF_SETUP'
]));
