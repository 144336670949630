/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const EXPIRE_TIMEOUT = env('user_expire_timeout', 24 * (60 * (60 * 1000))); // 24 hours (1 day)

class UserRepository extends BaseDatabaseCache {
    constructor ($q, backendService) {
        super();
        this.$q = $q;
        this.backendService = backendService;
    }
    clearExpiredDatabaseEntries (db) {
        return db.users.where('createdAt').belowOrEqual(Date.now() - EXPIRE_TIMEOUT).delete();
    }
    resolveFromDatabase (db, userId) {
        return db.users.where('userId').equals(userId)
            .first((item) => {
                if (item) {
                    console.log('user resolved from DB', item);
                    return item;
                } else {
                    throw new Error('Cannot resolve/find item in database.');
                }
            });
    }
    resolveFromServer (userId) {
        return this.backendService.fetch('user.load', {userId: userId}).data()
            .then((data) => {
                console.log('user resolved from Server', data);
                if( data.UserId ){
                    return {userId:data.UserId, email:data.Email, name:data.Name};
                }else{
                    throw new Error('Cannot fetch item from server.');
                }
            });
    }
    storeInDatabase (db, userId, data) {
        return db.users.add({userId:userId, email:data.email, name:data.name});
    }
    removeFromDatabase (db, userId) {
        return db.users.where('userId').equals(userId).delete();
    }
}

app.service('userRepositoryService', UserRepository);

app.run(function(userRepositoryService){
    window.$$userRepositoryService = userRepositoryService;
});