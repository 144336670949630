/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class EllipsisController {
    enabled = false;
    color: 'inherited';
}

function EllipsisDirective () {
    return {
        require: ['ellipsis'],
        controllerAs: 'ellipsisCtrl',
        controller: 'EllipsisController',
        scope: true,
        transclude: true,
        template: `
            <div>
                <span ng-transclude
                    class="app__ellipsis-content">
                </span>
                <span ng-if="ellipsisCtrl.enabled"
                    class="app__ellipsis-loader"
                    ng-class="['app__ellipsis-loader--' + ellipsisCtrl.color]">
                    <span>.</span><span>.</span><span>.</span>
                </span>
            </div>
        `,

        link (scope, element, attrs, ctrls) {
            let [ ellipsisCtrl ] = ctrls;

            scope.$watch(attrs.ellipsis, (enabled) => {
                if (attrs.ellipsis) {
                    ellipsisCtrl.enabled = !! enabled;
                }
            });
        }
    };
}

function EllipsisOptionsDirective ($parse) {
    return {
        require: ['ellipsis'],

        link (scope, element, attrs, ctrls) {
            let [ ellipsisCtrl ] = ctrls;

            let ellipsisOptionsAttr = $parse(attrs.ellipsisOptions);
            scope.$watch(() => ellipsisOptionsAttr(scope), (options) => {
                Object.keys(options).forEach((key) => {
                    ellipsisCtrl[key] = options[key];
                });
            }, true);
        }
    };
}

app
    .controller('EllipsisController', EllipsisController)
    .directive('ellipsis', EllipsisDirective)
    .directive('ellipsisOptions', EllipsisOptionsDirective);
