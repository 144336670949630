/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class FilePreviewController {
    /**
     * Whether the component is ready (after initial loading).
     *
     * @type {Boolean}
     */
    $ready = false;

    /**
     *
     * @type {Object}
     */
    $pageCache = null;

    /**
     * Whether there is a page currently loading.
     *
     * @type {Boolean}
     */
    $loading = false;

    /**
     * The file's id.
     *
     * @type {String}
     */
    id = null;

    /**
     * The number of pages in the attachment.
     *
     * @type {Number}
     */
    pageCount = 0;

    /**
     * The current page number.
     *
     * @type {Number}
     */
    pageNumber = -1;

    /**
     * File extension
     *
     * @type {String}
     */
    fileExtension = '';

    /**
     * Callback when the user attempts to close the file preview.
     *
     * @type {Function}
     */
    whenClose = null;

    /**
     * Whether the user can download the file.
     *
     * @type {Boolean}
     */
    canDownload = null;

    /**
     * Callback when the user clicks the download button.
     *
     * @type {Function}
     */
    whenDownload = null;

    /**
     * @constructor
     */
    constructor (attachmentService, cacheFactory, UserService) {
        this.$$ = { attachmentService, cacheFactory, UserService };

        // Create the page cache (for loading page data - image data)
        this.$pageCache = cacheFactory(this.$handlePageCache.bind(this));
    }

    get hasPrevious () {
        return this.pageNumber > 1;
    }

    get hasNext () {
        return this.pageNumber < this.pageCount;
    }

    switchPage (pageNumber) {
        let previousPageNumber = this.pageNumber;
        this.pageNumber = Math.min(Math.max(pageNumber, 1), this.pageCount || 1);
        console.log('switchpage', previousPageNumber, this.pageNumber, pageNumber);
        if (this.pageNumber !== previousPageNumber) {
            this.$loading = true;
            return this
                .$pageCache.get(this.pageNumber)
                .then((url) => {
                    // If the attachment has multiple pages, only the current page is displayed.
                    if (pageNumber === this.pageNumber) {
                        this.image = url;
                        this.$loading = false;
                    }
                    this.$ready = true;
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }

    $handlePageCache (pageNumber, resolve) {
        this.$$
            .attachmentService
            .preview(this.id, pageNumber)
            .then((blob) => {
                // Resize the blob (and chuck away the large size)
                resizeImageBlob(blob, 800, (resizedBlob) => {
                    resolve(URL.createObjectURL(resizedBlob));
                });
            });
    }

    previous () {
        this.switchPage(this.pageNumber - 1);
    }

    next () {
        this.switchPage(this.pageNumber + 1);
    }

    initAttachment (attachment) {
        this.id = attachment.id;
        this.pageCount = attachment.numberOfPages;
        this.fileExtension = attachment.extension;
        this.switchPage(1);
    }

    /**
     * Close the file preview (up to the host).
     */
    close () {
        this.whenClose();
        this.cleanup();
    }

    /**
     * Download the original file (up to host).
     */
    download () {
        // Make sure the user can download & there isn't already a download happening
        if (this.canDownload && ! this.$downloading) {
            let promise = this.whenDownload();

            // If the callback returned a promise, then we can optionally display a progress bar
            // on top of the download button (and if the promise returns a blob, we can cache it)
            if (promise && angular.isFunction(promise.then)) {
                this.handleDownload(promise);
            }
        }
    }

    /**
     *
     * @param {$q<Blob|*>} promise
     */
    handleDownload (promise) {
        this.$downloading = true;
        this.$downloadProgress = 0;

        promise
            .then(null, null, (progress) => {
                // Update the download progress (invisible prop - used in template)
                this.$downloadProgress = progress;
            })
            .finally(() => {
                // Reset the download state to update the button
                this.$downloading = false;
            });
    }

    /**
     * Revokes any object URLs so when the controller is destroyed, it does not
     * maintain references to active blobs.
     */
    cleanup () {
        this.$pageCache.keys().forEach((info) => {
            // Get the promise form the page cache, and revoke the object url
            this.$pageCache.get(info).then(URL.revokeObjectURL);
        });
        // Clear the cache so we don't try and use revoked object urls
        this.$pageCache.clear();
    }
}

function FilePreviewDirective (directiveHelper) {
    return {
        restrict: 'E',
        require: ['filePreview'],
        transclude: true,
        replace: true,
        templateUrl: 'templates/partials/proof/components/file-preview.html',
        controller: 'FilePreviewController',
        controllerAs: 'filePreviewCtrl',
        scope: true,

        link (scope, element, attr, [filePreviewCtrl]) {
            directiveHelper.callbackBinding(scope, attr, 'whenClose', filePreviewCtrl, 'whenClose');
            directiveHelper.oneWayBinding(scope, attr, 'canDownload', filePreviewCtrl, 'canDownload');
            directiveHelper.callbackBinding(scope, attr, 'whenDownload', filePreviewCtrl, 'whenDownload');

            if ('attachment' in attr) {
                // Initialize the file preview from an attachment object
                filePreviewCtrl.initAttachment(scope.$eval(attr.attachment));
            }

            // Cleanup after the decryption & blob creation
            scope.$on('$destroy', () => filePreviewCtrl.cleanup());
        }
    };
}

app
    .controller('FilePreviewController', FilePreviewController)
    .directive('filePreview', FilePreviewDirective);
