/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.factory('PPProofVideoMeta', function () {
    class PPProofVideoMeta {
        /**
         * The url to the video.
         *
         * @type {String}
         */
        url = null;

        /**
         * The access token/bearer token for the video.
         *
         * @type {String}
         */
        bearer = null;

        /**
         *
         * @param {String} url
         * @param {String} bearer
         */
        constructor (url, bearer) {
            this.url = url;
            this.bearer = bearer;
        }
    }

    return PPProofVideoMeta;
});
