/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class BaseDatabaseCache {
    /**
     * @constructor
     */
    constructor () {
        injectorInvoke(this._init, this);
    }

    /**
     * @ngInject
     */

    _init ($q, cacheFactory, userService, callbackService, dbService, apiService, browserService) {
        this.$$ = {$q, cacheFactory, userService, callbackService, dbService, apiService, browserService};
        this.cache = cacheFactory(this._resolveEntry.bind(this));
        this._clearExpiredDatabaseEntries();
    }

    ///**
    // * Whether the browser supports indexedDB.
    // *
    // * @returns {Boolean}
    // */
    //supportsDatabase () {
    //    return ! this.$$.browserService.is('safari');
    //}
    

    /**
     * Clears out any expired entries from the database.
     */
    _clearExpiredDatabaseEntries () {
        if (typeof this.clearExpiredDatabaseEntries === 'function') {
            this.$$.dbService.wrap((db) => this.clearExpiredDatabaseEntries(db));
        }
    }

    _resolveFromDatabase (info) {
        return this.$$.dbService.wrap((db) => this.resolveFromDatabase(db, info));
    }

    _resolveFromServer (info) {
        return this.$$.$q.when(this.resolveFromServer(info));
    }

    _serialiseForDatabase (info, data) {
        return this.$$.$q.when(this.serialiseForDatabase(info, data));
    }

    _storeInDatabase (info, data) {
        return this.$$.dbService.wrap((db) => this.storeInDatabase(db, info, data));
    }

    _removeFromDatabase (info) {
        return this.$$.dbService.wrap((db) => this.removeFromDatabase(db, info));
    }

    _addToDatabase (info, data) {
        return this.$$.dbService.wrap((db) => this.addToDatabase(db, info, data));
    }

    /**
     * In charge of delegating tasks to the inheritor.
     *
     * @param {*} info
     * @param {Function} done
     */
    _resolveEntry (info, done) {
        done(
            this._resolveFromDatabase(info)
                .catch(() => {
                    return this._resolveFromServer(info).then((result) => {

                        if (result == null) {
                            throw new Error('Unable to resolve from either local database or remote server.');
                        }
                        // Don't need to wait for this, it doesn't matter if we ever put it in, but we try
                        this._serialiseForDatabase(info, result).then((serialised) => (
                            this._storeInDatabase(info, serialised)
                        ));
                        return result;
                    });
                })
                .then((result) => {
                    return result;
                })
        );
    }

    /**
     * Utility function to help serialise blobs for the database.
     *
     * @param {Blob} blob
     * @returns {Object}
     */
    serialiseDatabaseBlob (blob) {
        let browser = this.$$.browserService;
        return this.$$.$q((resolve) => {
            if (browser.is('safari') || (browser.is('chrome') && browser.version <= 36)) {
                blobToDataURL(blob, (dataUrl) => resolve({type: 'base64', blob: dataUrl}));
            } else {
                resolve({type: 'blob', blob});
            }
        });
    }

    /**
     * Parses blobs from the database by type & blob.
     *
     * @param {String} type
     * @param {Blob|String} blob
     * @returns {Blob}
     */
    parseDatabaseBlob (type, blob) {
        return this.$$.$q((resolve) => {
            switch (type) {
                case 'base64':
                    resolve(dataURLToBlob(blob));
                    break;
                case 'blob':
                    resolve(blob);
                    break;
                default:
                    throw new Error('Unknown blob type "' + type + '"');
            }
        });
    }

    get (info) {
        return this.cache.get(info);
    }

    update (info) {
        return this.remove(info)
            .then(() => {
                return this.cache.update(info);
            });
    }

    remove (info) {

        console.log("remove info", info);

        return this._removeFromDatabase(info)
            .catch(() => null)
            .then(() => {
                this.cache.remove(info);
            });
    }

    set (info, data) {
        return this._removeFromDatabase(info)
            .then(() => this._storeInDatabase(info, data))
            .then(() => this.cache.remove(info));
    }

    //

    clearExpiredDatabaseEntries (db) {
        throw new Error('You MUST implement the `clearExpiredDatabaseEntries(db)` method on the DatabaseCache to ensure orphan/expired entries are removed.');
    }

    resolveFromDatabase (db, info) {
        throw new Error('You MUST implement the `resolveFromDatabase(db, info)` method on the DatabaseCache.');
    }

    resolveFromServer (info) {
        throw new Error('You MUST implement the `resolveFromServer(info)` method on the DatabaseCache.');
    }

    serialiseForDatabase (info, data) {
        return data;
    }

    storeInDatabase (db, info, data) {
        throw new Error('You MUST implement the `storeInDatabase(db, info, data)` method on the DatabaseCache.');
    }

    removeFromDatabase (db, info) {
        throw new Error('You MUST implement the `removeFromDatabase(db, info)` method on the DatabaseCache.');
    }

    addToDatabase (db, info, data){
        return info;
    }
}

BaseDatabaseCache.prototype._init.$inject = ['$q', 'cacheFactory', 'userService', 'callbackService', 'dbService', 'apiService', 'browserService'];

window.BaseDatabaseCache = BaseDatabaseCache;
