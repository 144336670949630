/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
(function () {
    'use strict';

    function polyfill(obj, property, patch) {
        return obj[property] = patch(obj[property]);
    }

    if ( ! ('console' in window)) window.console = {};

    var noop = function () {};

    var logs = ['debug', 'log', 'time', 'timeEnd', 'info', 'warn'];
    logs.forEach(function (type) {
        polyfill(window.console, type, function (func) { return func || noop; });
    });
})();
