/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.filter('videoTime', ($sce) => {
    return (sec, format = '2digit') => {
        return $sce.trustAsHtml(getTimecode(sec, format === '4digit'));
    };
});

const EPSILON = 0.00000000000000000000001;
const DROPFRAME = false;

// Duplicated in /directives/videoPlayer.es6.js
function generateSMPTE(timeInSeconds, frameRate) {
    const totalFrames = Math.floor(timeInSeconds * frameRate);
    let droppedFramesPerMinute = 0;
    let frameNumber = totalFrames;
    
    // Calculate drop-frame adjustment (2 frames dropped every minute except every 10th minute)
    // See http://www.andrewduncan.net/timecodes/ for a good explanation of drop-frame
    const isDropFrame = frameRate === 29.97 || frameRate === 59.94;
    if (isDropFrame) {
        droppedFramesPerMinute = Math.round(frameRate * (2 / 30));

        // Calculations drop frames in 10 minute blocks, drop frames don't occur on minutes divisible by 10
        const framesPerBlock = Math.round(frameRate * 60 * 10);
        const dropFramesPerBlock = droppedFramesPerMinute * 9;
        const numberOfBlocks = Math.floor(totalFrames / framesPerBlock);
        const dropFramesInBlocks = dropFramesPerBlock * numberOfBlocks;
        frameNumber += dropFramesInBlocks;

        // Calculate drop frames in the remaining frames (after the last whole 10 minute block)
        const timeCodesPerMinute = (Math.round(frameRate) * 60) - droppedFramesPerMinute;
        const totalRemainingFrames = totalFrames % framesPerBlock;
        const totalRemainingMinutes = Math.floor((totalRemainingFrames - droppedFramesPerMinute) / timeCodesPerMinute)
        // Only add drop frames if there is more than 1 minute remaining
        if (totalRemainingFrames > timeCodesPerMinute) {
            frameNumber += droppedFramesPerMinute * totalRemainingMinutes;
        }
    }

    const roundedFrameRate = Math.round(frameRate);
    const totalSeconds = Math.floor(frameNumber / roundedFrameRate);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);

    const remainingFrames = frameNumber % roundedFrameRate;
    const remainingSeconds = totalSeconds % 60;
    const remainingMinutes = totalMinutes % 60;
    const remainingHours = totalHours % 24;

    const formattedHours = remainingHours.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
    const formattedFrames = remainingFrames.toString().padStart(2, '0');

    const separator = isDropFrame ? ';' : ':';

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}${separator}${formattedFrames}`;
}

function getTimecode(sec, detailed, framesPerSecond) {
    // Ensure sec is a non-negative number
    sec = Math.max(0, sec);

    if (detailed) {
        return generateSMPTE(sec, framesPerSecond);
    }

    const centisecond = (sec % 1).toFixed(2).substring(2);
    let hours = null,
        minutes = Math.floor(sec / 60),
        seconds = '' + Math.floor(sec % 60);

    if (minutes >= 60) {
        hours = Math.floor(minutes / 60);
        hours = ('' + hours).length === 1 ? '0' + hours : hours;
        minutes = Math.floor(minutes % 60);

        if (minutes < 10) {
            minutes = '0' + minutes;
          }
    }

    if (seconds.length === 1) {
        seconds = '0' + seconds;
    }

    if(detailed) {
        hours = hours ? hours : '00';
        minutes = String(minutes).length === 1 ? '0' + minutes : minutes;
    }
    
    return detailed 
        ? `${hours}:${minutes}:${seconds}:${centisecond}`
        : `${hours ? hours + ':' : ''}${minutes}:${seconds}`;
}

window.generalfunctions_getTimecode = getTimecode;

function toTimecode(absoluteTime) {
    if ((typeof absoluteTime !== 'number') || isNaN(absoluteTime) || !isFinite(absoluteTime) || (absoluteTime < 0)) {
        return null;
    }

    var timecode = "";
    var framesPerSecond = 30;
    var frameCount = absoluteTimeToFrames(absoluteTime, framesPerSecond);

    if (DROPFRAME && (framesPerSecond > 29.97) && (framesPerSecond < 29.98)) {
        // Custom logic for 29.97 drop frame timecodes
        var days = Math.floor((frameCount / 107892) / 24);
        var hours = Math.floor((frameCount / 107892) % 24);
        var minutes = Math.floor(((frameCount + (2 * Math.floor((frameCount - (107892 * hours)) / 1800)) - (2 * Math.floor((frameCount - (107892 * hours)) / 18000)) - (107892 * hours)) / 1800) % 60);
        var seconds = Math.floor(((frameCount - (1798 * minutes) - (2 * Math.floor(minutes / 10)) - (107892 * hours)) / 30) % 60);
        var frames = Math.floor((frameCount - (30 * seconds) - (1798 * minutes) - (2 * Math.floor(minutes / 10)) - (107892 * hours)) % 30);

        timecode = formatTimecode([days, hours, minutes, seconds, frames], true);
    } else {
        // General logic for other timecodes
        var framesPerSecond = normalizeFrameRate(framesPerSecond);
        var framesPerMinute = framesPerSecond * 60;
        var framesPerHour = framesPerMinute * 60;

        var days = Math.floor((frameCount / framesPerHour) / 24);
        var hours = Math.floor((frameCount / framesPerHour) % 24);
        var minutes = Math.floor(((frameCount - (framesPerHour * hours)) / framesPerMinute) % 60);
        var seconds = Math.floor(((frameCount - (framesPerMinute * minutes) - (framesPerHour * hours)) / framesPerSecond) % 60);
        var frames = Math.floor((frameCount - (framesPerSecond * seconds) - (framesPerMinute * minutes) - (framesPerHour * hours)) % framesPerSecond);

        timecode = formatTimecode([days, hours, minutes, seconds, frames], false);
    }

    return timecode;
}

function absoluteTimeToFrames(absoluteTime, frameRate) {
    return Math.floor(frameRate * (absoluteTime + EPSILON));
}

function normalizeFrameRate(frameRate) {
    if ((frameRate > 23.97) && (frameRate < 23.98)) {
        return 24;
    }
    if ((frameRate > 29.97) && (frameRate < 29.98)) {
        return 30;
    }
    return frameRate;
}

function formatTimecode(timeElements, dropFrame) {
    var formattedTimecode = timeElements.reduce(function (acc, curr, i, arr) {
        var separator = !dropFrame || i < arr.length - 1 ? ':' : ';';
        if (i !== 0) {
            if (i === arr.length - 1) {
                var framesFixed = curr > 9 ? curr.toFixed(0) : '0' + curr.toFixed(0);
                return acc + framesFixed;
            } else {
                var timeFixed = curr > 9 ? curr.toFixed(0) : '0' + curr.toFixed(0);
                var boldOrNormalTimeFixed = curr > 0 ? '<b>' + timeFixed + '</b>' : timeFixed;
                return acc + boldOrNormalTimeFixed + separator;
            }
        } else if (curr > 0) {
            var boldDays = '<b>' + curr.toFixed(0) + '</b>' + separator;
            return acc + boldDays;
        } else {
            return acc;
        }
    }, '');
  return formattedTimecode;
}
