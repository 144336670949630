/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
var PopupBlockedError = createError('PopupBlockedError');

angular
    .module('createProof')
    .constant('importConfig', {
        providers: [
            'Dropbox',
            'Drive',
            'Box',
            'Onedrive',
            'url',
            'Canva'
        ],
        names: {
            dropbox: 'Dropbox',
            drive: 'Google Drive',
            box: 'Box',
            onedrive: 'OneDrive',
            url: 'Url',
        },
        keys: {
            dropbox: {
                appKey: env('dropbox_app_key')
            },
            drive: {
                apiKey: env('drive_api_key'),
                clientId: env('drive_client_id'),
                appId: env('drive_app_id')
            },
            box: {
                clientId: env('box_client_id')
            },
            onedrive: {
                clientId: env('onedrive_client_id')
            }
        }
    })
    .factory('importPreloadService', function ($q, utilService, importConfig, domService) {
        return {
            /**
             * @see https://www.dropbox.com/developers/dropins/chooser/js
             *
             * @param {Function} resolve
             */
            dropbox: function (resolve) {
                resolve(
                    utilService
                        .loadScript('https://www.dropbox.com/static/api/2/dropins.js', {
                            'id': 'dropboxjs',
                            'data-app-key': importConfig.keys.dropbox.appKey
                        })
                );
            },
            /**
             * @see https://developers.google.com/picker/docs/
             * @see https://developers.google.com/identity/sign-in/web/reference#advanced 
             * @see https://developers.google.com/drive/api/v3/quickstart/js
             *
             * @param {Function} resolve
             * @param {Function} reject
             */
            drive: function (resolve, reject) {
                // var undo = domService.hijack(window, 'open', function (open, that, args) {
                //     try {
                //          open.apply(that, args);
                //     } catch (err) {
                //         undo();
                //         reject(new PopupBlockedError('Google Drive failed to trigger authentication popup.'));
                //     }
                // });

                utilService.
                    loadScript('https://apis.google.com/js/api.js?onLoad=_loadPicker').then(function() {
                        gapi.load('client:auth2:picker', setUpClient);
                    });

                function setUpClient() {
                    gapi.client.setApiKey(importConfig.keys.drive.apiKey);
                    gapi.client.load('drive', 'v3', authorize);
                }

                function authorize(err) {
                    gapi.auth2.authorize(
                        {
                          'client_id': importConfig.keys.drive.clientId,
                          'scope': 'https://www.googleapis.com/auth/drive.readonly',
                          'immediate': false,
                          'supportsAllDrives': true
                        },
                        onAuthResult
                    );
                }

                function onAuthResult(result) {
                    if (result.error) {
                        reject(result.error)
                        return;
                    } 
                    resolve();
                }
            },
            /**
             * @see https://developers.box.com/the-box-file-picker/
             *
             * @param {Function} resolve
             * @param {Function} reject
             */
            box: function (resolve) {
                resolve(
                    utilService
                        .loadScript('https://app.box.com/js/static/select.js')
                );
            },
            /**
             * @see https://dev.onedrive.com/sdk/javascript-picker-saver.htm
             *
             * @param {Function} resolve
             */
            onedrive: function (resolve) {
                resolve(
                    utilService
                        .loadScript('https://js.live.net/v7.2/OneDrive.js')
                );
            }
        };
    })
    .factory('importProviderService', function (importConfig, UserService) {
        var fileMimes = UserService.config.AllowedFileMimeTypes,
            fileTypes = UserService.config.AllowedFileTypes,
            fileExtensions = fileTypes.map(function (fileType) {
                return '.' + fileType;
            }),
            fileExtensionsString = fileExtensions.join(',');

        return {
            /**
             * @see https://www.dropbox.com/developers/dropins/chooser/js
             *
             * @param {Function} resolve
             * @param {Function} reject
             * Returns an array with the selected files.
             */
            dropbox: function (resolve, reject) {
                try {
                    Dropbox.choose({
                        success: function (files) {
                            var data = files.map(function (file) {
                                return {
                                    name: getFileName(file.name),
                                    extension: getFileType(file.name),
                                    url: file.link,
                                    size: file.bytes
                                };
                            });
                            resolve(data);
                        },
                        cancel: reject,
                        extensions: fileExtensions,
                        linkType: 'direct',
                        multiselect: true
                    });
                } catch (err) {
                    reject(new PopupBlockedError('Dropbox failed to trigger popup.'));
                }
            },
            /**
             * @see http://stuff.dan.cx/js/filepicker/google/filepicker.js
             * @see https://developers.google.com/picker/docs/
             *
             * @param {Function} resolve
             */
            drive: function (resolve, reject) {
                var accessToken = gapi.client.getToken().access_token;

                var sharedDrives = new google.picker.DocsView()
                    .setIncludeFolders(true)
                    .setMimeTypes(fileMimes.join(','))
                    .setEnableDrives(true);
                    
                var googleDrive = new google.picker.DocsView()
                    .setIncludeFolders(true)
                    .setMimeTypes(fileMimes.join(','))

                var picker = new google.picker.PickerBuilder()
                    .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
                    .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
                    .setAppId(importConfig.keys.drive.appId)
                    .setOAuthToken(accessToken)
                    .addView(googleDrive)
                    .addView(sharedDrives)
                    .setCallback(callback)
                    .build()
                    .setVisible(true);

                function callback(data) {
                    switch (data.action) {
                        case google.picker.Action.PICKED:
                        var batch = gapi.client.newBatch();

                            data.docs.forEach(function(doc) {
                                var request = gapi.client.drive.files.get({
                                    fileId: doc.id,
                                    fields: 'id, name, size',
                                    supportsAllDrives: true
                                });
                                batch.add(request);
                            });

                            batch.execute(function(responses) {
                                var results = [];
                                for (var id in responses) {
                                    var response = responses[id];
                                    if (response.error) {
                                        console.error(response.error);
                                    } else {
                                        results.push(response.result);
                                    }
                                }
                                load(results);
                            });
                            break;
                        case google.picker.Action.CANCEL:
                            reject();
                            break;
                    }
                }

                function load(files) {
                    var data;

                    data = files.map(function (file) {
                        return {
                            name: getFileName(file.name),
                            extension: getFileType(file.name),
                            url: 'https://www.googleapis.com/drive/v3/files/' + file.id + '?alt=media',
                            size: parseFloat(file.size || -1),
                            meta: {
                                accessToken: accessToken
                            }
                        };
                    });

                    resolve(data);
                }
            },
            /**
             * @see https://developers.box.com/the-box-file-picker/
             *
             * @param {Function} resolve
             * @param {Function} reject
             */
            box: function (resolve, reject) {
                var boxSelect = new BoxSelect({
                    clientId: importConfig.keys.box.clientId,
                    linkType: 'direct',
                    multiselect: true,
                    extensions: fileExtensions,
                });

                boxSelect.success(function (response) {
                    var files = response;

                    var data = files.map(function (file) {
                        return {
                            name: getFileName(file.name),
                            extension: getFileType(file.name),
                            url: file.link,
                            size: file.bytes
                        };
                    });
    
                    resolve(data);  
                });

                boxSelect.cancel(reject);

                try {
                    boxSelect.launchPopup();
                } catch (err) {
                    reject(new PopupBlockedError('Box failed to trigger popup.'));
                }
            },
            /**
             * @see https://dev.onedrive.com/sdk/javascript-picker-saver.htm
             *
             * @param {Function} resolve
             * @param {Function} reject
             */
            onedrive: function (resolve, reject) {
                OneDrive.open({
                    success: function (response) {
                        var files = response.value;

                        var data = files.map(function (file) {
                            return {
                                name: getFileName(file.name),
                                extension: getFileType(file.name),
                                url: file["@microsoft.graph.downloadUrl"],
                                size: file.size
                            };
                        });
                        resolve(data);
                    },
                    cancel: reject,
                    error: reject,
                    clientId: importConfig.keys.onedrive.clientId,
                    action: 'download',
                    accountSwitchEnabled: true,
                    multiSelect: true,
                    advanced: {
                        redirectUri: window.origin + '/onedrive.php',
                        filter: fileExtensionsString,
                    }
                });
            }
        };
    })
    .factory('importService', function ($q, $api, callbackService, importPreloadService, importProviderService, importConfig, UserService, SegmentIo) {
        var importService;

        return importService = {
            /**
             * Returns the internal provider name.
             *
             * @param {String} provider
             * @returns {String}
             */
            name: function (provider) {
                return provider.charAt(0).toLowerCase() + provider.substring(1);
            },
            /**
             * Returns the provider name from a lowercase.
             *
             * @param {String} provider
             * @returns {String}
             */
            normalise: function (provider) {
                var found;

                angular.forEach(importConfig.providers, function (key) {
                    if ( ! found && key.toLowerCase() === provider.toLowerCase()) {
                        found = key;
                    }
                });

                return found;
            },
            /**
             * Gets the pretty name of the provider.
             *
             * @param {String} provider
             * @returns {String}
             */
            pretty: function (provider) {
                var name = this.name(provider);
                return importConfig.names[name];
            },
            /**
             * Is a valid provider.
             *
             * @param {String} provider
             * @returns {Boolean}
             */
            isProvider: function (provider) {
                return importConfig.providers.some(function (key) {
                    return key.toLowerCase() === provider.toLowerCase();
                });
            },
            /**
             * Prompts the user to import a file from a 3rd party.
             *
             * @param {String} provider
             * @returns {$q}
             */
            from: function (provider) {
                if ( ! this.isProvider(provider)) {
                    // If the provider was not found in the importServiceConfig.providers array
                    throw new Error('Unknown import provider "' + provider + '". ' +
                                    'Could not invoke importService.from.');
                }

                var that = this,
                    name = this.name(provider);

                return $q(function (resolve, reject) {
                    // Make sure that all the libraries dependencies are preloaded
                    importService.preload(name).then(function () {
                        // Resolve the promise with the result from the providers promise
                        resolve(importService.provider(name));
                    });
                });
            },
            /**
             * Verifies the file can be accessed by the backend.
             *
             * @param {Object} file
             * @returns {$q}
             */
            verify: function (file) {
                var url, accessToken;

                url = file.url;

                if (file.meta && file.meta.accessToken) {
                    accessToken = file.meta.accessToken;
                }

                return $api.proofs.permissionCheck.fetch({
                    url: url, accessToken: accessToken
                });
            },
            /**
             * Invokes the provider which opens the UI for the various integrations.
             *
             * Requires the preload function to be called first, so the provider has access
             * to the required dependencies.
             *
             * @see {importService.preload}
             *
             * @param {String} provider
             * @returns {$q}
             */
            provider: function (provider) {
                var name = this.name(provider),
                    fn = importProviderService[name];

                return $q(fn);
            },
            /**
             * Invokes the preloader which installs (includes) the providers dependencies.
             *
             * @param {String} provider
             * @returns {$q}
             */
            preload: function (provider) {
                var name = this.name(provider),
                    fn = importPreloadService[name];

                if ( ! this.$$preload[name]) {
                    // If there is no preload currently created for the provider, create a
                    // new promise, and cache to the $$preload property.
                    this.$$preload[name] = $q(fn);
                }

                return this.$$preload[name];
            },
            /**
             * The cached preload promises.
             *
             * @type {Object}
             */
            $$preload: {},
            /**
             * Popup blocked error class.
             *
             * @type {PopupBlockedError}
             */
            PopupBlockedError: PopupBlockedError
        };
    });
