/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/**
 * Created by chris on 6/03/15.
 */

ppxControllers.controller('proofStarted', ['$scope', '$routeParams', '$location', '$sce', 'UserService', 'DataService', 'NavigationService', function($scope, $routeParams, $location, $sce, UserService, DataService, NavigationService) {
    $scope.application = UserService; //make the application class avaliable to this scope

    $scope.init = function(){
        $scope.application.hideLoader();
    };

    $scope.goToProof = function(){
        $location.path("proof-screen/" + $routeParams.proofId);
    };

    $scope.init();

}]);