/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
(function ($) {
    'use strict';

    var $documentElement = $(document.documentElement);

    $documentElement
        .addClass('js')
        .removeClass('no-js');

    var Modernizr = window.Modernizr = {
        storage: (function () {
            try {
                return 'Storage' in window && window.localStorage && typeof window.localStorage.setItem === 'function';
            } catch (err) {
                return false;
            }
        })(),
        touch: (function () {
            return ('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch;
        })(),
        webworkers: (function () {
            return window.Worker;
        })(),
        'pointer-events': (function () {
            return 'pointerEvents' in document.createElement('div').style && (navigator.userAgent.indexOf('MSIE 10') === -1) && (!navigator.userAgent.match(/Trident\/7\./));
        })()
    };

    Object.keys(Modernizr).forEach(function (test) {
        $documentElement.addClass(Modernizr[test] ? test : 'no-' + test);
    });
})(window.jQuery);
