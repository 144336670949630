/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/**
 * Domain Admin - Team dashboard
 *
 * @author Anna Blackwell <anna@pageproof.com>
 */

class TeamDashboardController extends BaseDashboardController {
  constructor() {
    super();

    this.props = {
      user: this.user,
      from: 'team-dashboard',
      color: 'green',
    };
  }
}

app.controller(
  'TeamDashboardController',
  TeamDashboardController
);
