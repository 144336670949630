/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.filter('versionFilter', function() {
    return function (versionArr, selectedItem) {
        if (versionArr.length > 0) {
            var arrayToReturn = [];
            for (let i=0; i < versionArr.length; i++){
                if (versionArr[i].value !== selectedItem) {
                    arrayToReturn.push(versionArr[i]);
                }
            }
            return arrayToReturn;
        }
        return versionArr;
    };
});
