/* Copyright (C) PageProof Holdings limited - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Authored by: <chris@pageproof.com>, <braden@pageproof.com>, <marcus@pageproof.com> - September 2014
 */

'use strict';

/**
 * Marketing - Docs
 *
 * @author Jacob Marshall <jacob@pageproof.com>
 */
ppxControllers.controller('Docs', [

    '$scope', '$rootScope', '$location', '$routeParams', 'UserService', 'NavigationService',
    function ($scope, $rootScope, $location, $routeParams, UserService, NavigationService) {
        $scope.application = UserService;

        $scope.hasBack = !! $routeParams.back;

        $scope.back = NavigationService.wrapBack(function () {
            $location.path($routeParams.back).search({});
        });
    }

]);
