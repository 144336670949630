/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
ppxControllers
    .controller('ImportedController', function ($scope, $interval, $timeout, importProvider, UserService, DataService, rainbowService) {
        UserService.hideLoader();

        $scope.provider = importProvider;
        $scope.providerKey = importProvider.toLowerCase();

        var rainbowHooks = rainbowService.load('progress');
        rainbowHooks.call('onLoad');
        
        $scope.progress = 0;

        var progressInterval = $interval(tickProgress, 1e3);
        $timeout(tickProgress);

        function tickProgress () {
            $scope.progress = Math.min($scope.progress + Math.random(), 1);

            if ($scope.progress === 1) {
                $interval.cancel(progressInterval);
            }
            rainbowHooks.call('onProgressChange', $scope.progress, $scope.progress === 1);
        }

        $scope.$on('$destroy', function () {
            DataService.clearData();
            rainbowHooks.call('onDestroy');
        });
    });
