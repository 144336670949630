/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.factory('PPProofPermissions', function (UserService, $api, PPProofType, PPProofStatus, PPCommentMarkType, $filter) {


    class PPProofPermissions {

        /**
         * Proof Permission class for the proof page. Usage:
         *
         *     var pp = new PPProofPermissions({userId:"someId", userEmail:"some@email.com"}, ppProofObject);
         *     var permissions = pp.fetchUserPermissions(); //gets the users permission object (described below)
         *
         *     pp.loadProof(anUpdatedPPProofObject); //reloads the permissions for changes in the proof
         *     var permissions = pp.fetchUserPermissions(); //gets the updated users permission object

            Permission objects returns from 'fetchUserPermissions' method:

            General proof object:

            generalObj = {
                userType:"", the user type as defined in the userType array
                isLatestVersion:false, if proof is latest
                proofStatus:"", the proofs status code
                proofType: "", proof type 1 means standard proof || 2 means brief
                isLocked:false, if the proof is locked
                isClosed:false, if the proof is closed
                isApproved:false, if the proof is approved
                canToggleGlobalMarks: false, if the user can globally mark every comment as changed (to-do) or done
                relatedIds: {
                    usersStepId:"", the logged in users stepId
                    currentStepId:"", the current stepId
                    userIdLocked:"", the id of the user who locked the proof
                    finalApproverUserId:"" the final approvers userId
                }
            }

            Proofer levels permissions:

            proof page level:

            proofLevel: {
                            isProofOwner: false,
                            isFinalApprover: false,
                            isEditor: false,
                            isCoOwner: false,
                            isApprover: false,
                            isLockedUser: false,
                            isOnCurrentStep: false,
                            isFinished: false,
                            isAdminUser: false,
                            canDownloadAttachments:false,
                            isInviter: false,
                        },

            proof comment level:

            note: if canMarkAsDone is false, and canViewDone is true, then the user can see the done toggle but not mark as done. If both are false then the user can't see the done toggle at all. This is the same for the changes toggles (To-dos)

            commentLevel: {
                            canCreate: true,
                            canEdit: true,
                            canReply: true,
                            canMarkAsDone: false,
                            canMarkAsChanged: false, - changes are To-do's in the front end
                            canViewDone: false,
                            canViewChanged: false - this is whether the To-do's switch appears to not
                        },

            proof pin default colours:

            pinLevel: {
                            greyPin:false,
                            greenPin:false,
                            redPin:true
                       }

         */

        /**
         * Array of proof page user types. One of these types will be returned to the proof page.
         *
         * @type {Array}
         */
        userTypes = [
            'proofer', //a standard WF proofer. User can be mandatory or not. This doesn't effect the proof page. 0
            'proofOwner', //the proof owner, but not connected to the WF. 1
            'finalApprover', //the final approver, but the user is NOT a owner or co-owner 2
            'editor', //a normal editor 3
            'approverProofer', //a proof approver. This type works like a final approver 4
            'adminProofer', //a admin proofer thats NOT on the WF 5
            'adminProoferActive', //a admin proofer that's on WF step 6
            'prooferOwnerPlusFinalApprover', //a proof owner thats also the final approver 7
            'proofCoOwnerPlusFinalApprover', //a co-owner thats also the final approver 8
            'proofOwnerPlusProofer', //a proof owner that is also a normal proofer 9
            'proofCoOwnerPlusProofer', //a co-owner that is also a normal proofer 10
            'proofOwnerPlusApproverProofer', //a proof owner that is also a approver proofer 11
            'proofCoOwnerPlusApproverProofer', //a proof co-owner that is also a approver proofer 12
            'proofCoOwner', //the proof owner, but not connected to the WF. 13
            'adminProoferInActive', //a admin proofer that's on WF step 14
            'recipient', //a recipient of a brief 15
            'unlistedReviewer', //an unlisted reviewer 16
            'viewOnlyReviewer' // an view only reviewer 17
        ];

        /**
         * The ppProof object
         *
         * @type {PPProof}
         */
        proof = null;

        /**
         * The user object (standard userData type)
         *
         * @type {object}
         */
        user = null;

        /**
         * The logged in users email
         *
         * @type {string}
         */
        userEmail = "";

        /**
         * The looged in users userId
         *
         * @type {string}
         */
        userId = "";

        /**
         * The proof owners userId.
         *
         * @type {string}
         */
        proofOwnerUserId = "";

        /**
         * The current pages proofId.
         *
         * @type {string}
         */
        proofId = "";

        /**
         * The current pages proof status.
         *
         * @type {int}
         */
        proofStatus = 0; //default not started

        /**
         * The array of co owners on the proof.
         *
         * @type {PPUser[]}
         */
        ppCoOwners = [];

        /**
         * The array of wf managers on the proof.
         *
         * @type {PPUser[]}
         */
        ppWorkflowManagers = [];

        /**
         * The object that holds the proofers permissions
         *
         * @type {object}
         */
        permissionObj = null;

        /**
         * The type of proofer the logged in user is. This is generated from the userTypes array above
         *
         * @type {string}
         */
        userType = "";

        /**
         * Boolean value representing if the proof is at the final approver stage
         *
         * @type {bool}
         */
        isFinalApproverStage = false;

        /**
         * Boolean value representing if the proof owner is the current proofer
         *
         * @type {bool}
         */
        ownerIsCurrentProofer = false;

        /**
         * Boolean value representing if the proof co-owner is the current final approver proofer
         *
         * @type {bool}
         */
        coOwnerIsCurrentFinalApprover = false;

        /**
         * The currently logged in users stepId
         *
         * @type {string}
         */
        usersStepId = ""; //logged in users stepId

        /**
         * The current wf stepId (defined as the step with values of visible:1 complete:0)
         *
         * @type {string}
         */
        currentStepId  = ""; //the currently active stepId

        /**
         * UserId of the user who has locked the proof
         *
         * @type {string}
         */
        userIdLocked = "";

        /**
         * UserId of the final approver proofer
         *
         * @type {string[]}
         */
        finalApproverUserIds = [];

        /**
         * Boolean value representing if the logged in user is the proof owner
         *
         * @type {bool}
         */
        isProofOwner = false;

        /**
         * Boolean value representing if the logged in user is the final approver proofer
         *
         * @type {bool}
         */
        isFinalApprover = false;

        /**
         * Boolean value representing if the logged in user is the proof editor
         *
         * @type {bool]}
         */
        isEditor = false;

        /**
         * Boolean value representing if the logged in user is the brief recipient
         *
         * @type {bool]}
         */
        isRecipient = false;

        /**
         * Boolean value representing if the logged in user is a proof co-owner
         *
         * @type {boolean}
         */
        isCoOwner = false;

        /**
         * Boolean value representing if the logged in user is a approver proofer
         *
         * @type {bool}
         */
        isApprover = false;

        /**
         * Boolean value representing if the logged in user is a mandatory proofer
         *
         * @type {bool}
         */
        isMandatory = false;

        /**
         * Boolean value representing if the logged in user is a skipped proofer
         *
         * @type {bool}
         */
        isSkipped = false;

        /**
         * Boolean value representing if the logged in user is the proofer who locked the proof
         *
         * @type {bool}
         */
        isLockedUser = false;

        /**
         * Boolean value representing if the logged in user is the inviter in workflow of proof
         *
         * @type {bool}
         */
        isInviter = false;

        /**
         * Boolean value representing if the logged in user is a workflow manager
         *
         * @type {bool}
         */
        isAdminUser = false;

        /**
         * Boolean value representing if the logged in user is on the current workflow step
         *
         * @type {bool}
         */
        isOnCurrentStep = false;

        /**
         * Boolean value representing if the logged in user is on a past workflow step
         *
         * @type {bool}
         */
        isOnPastStep = false;

        /**
         * Boolean value representing if the logged in user is a proofer who has finished
         *
         * @type {bool}
         */
        isFinished = false;

        /**
         * Boolean value representing if the current proof is the latest version of proof
         *
         * @type {bool}
         */
        isLatestVersion = false;

        /**
         * Boolean value representing if the logged in user is a proofer who has finished on a past step
         *
         * @type {bool}
         */
        isFinishedPast = false;

        /* proof level permissions */
        /**
         * Boolean value representing if the proof is locked
         *
         * @type {bool}
         */
        isLocked = false;

        /**
         * Boolean value representing if the proof is closed
         *
         * @type {bool}
         */
        isClosed = false;

        /**
         * Boolean value representing if the proof is approved
         *
         * @type {bool}
         */
        isApproved = false;

        /**
         * Boolean value representing if the proof has an editor set
         *
         * @type {bool}
         */
        isWithEditor = false;

        /**
         * Deprecated - refers to the old logic mode on the proof page, but shouldn't be relied on
         *
         * @type {bool}
         */
        readOnlyMode = false;

        /**
         * Deprecated - refers to the old logic mode on the proof page, but shouldn't be relied on
         *
         * @type {bool}
         */
        globalReadOnlyMode = false;

        /* hide/show tools */
        /**
         * Deprecated - refers to the old logic on the proof page, but shouldn't be relied on. Use the 'canCreate' parameter of the commentlevel permissions instead
         *
         * @type {bool}
         */
        showCommentTool = false;

        /**
         * Boolean value representing if attachments can be downloaded by the logged in user
         *
         * @type {bool}
         */
        canDownloadAttachments = true;

        /**
         * Boolean value representing if the user can see and click the global mark all dones / changes at the top of the comments pane.
         *
         * @type {bool}
         */
        canToggleGlobalMarks = false;

        /**
         * Object that holds the general proof data, such as stepId's and finalApproverId's.
         *
         * @type {object}
         */
        generalObj = {
            userType: "",
            proofStatus: "",
            proofType: "",
            isLocked: false,
            isClosed: false,
            isApproved: false,
            canToggleGlobalMarks: false,
            isFinalApproverStage: false,
            isFinished: false,
            isFinishedPast: false,
            isLatestVersion: false,
            isWithEditor: false,
            relatedIds: {
                usersStepId: "",
                currentStepId: "",
                userIdLocked: "",
                finalApproverUserIds: [],
                editorId: ""
            }
        };


        /**
         * Permissions object that holds each user type, and their prospective permission levels, based of proof status. So, for example, a common user permission level may be: userType:proofer, status:10, proofLevel->isFinished:true.
         * When adding new user types to this object, the user needs to have a permission object for each proof status code. When the permission behaviour is duplicated across multiple status, the refer:[statusCode] can be used.
         * This means for the given status code, use the permissions set at the refer parameter of the same userType object.
         *
         * @type {bool}
         */
        permissions = {

            proofer:{
                10:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: false,
                        canViewChanged: false,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                30:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                50:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                60:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                66: {
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                70:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                100:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                }
            },
            proofOwner:{
                0:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                10:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                30:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                50:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                60:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                66: {
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                70:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                100:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                }
            },
            proofCoOwner:{
                0:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                10:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                30:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                50:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                60:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                66: {
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                70:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                100:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                }
            },
            finalApprover:{
                10:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: true,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: false,
                        canViewChanged: false,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                30:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: true,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: true,
                        isOnCurrentStep: true,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                50:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: true,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                60:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: true,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                66: {
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: true,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                70:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: true,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                100:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: true,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                }
            },
            editor:{
                10:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: true,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: false,
                        canViewChanged: false,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                30:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: true,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                50:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: true,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                60:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: true,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                66: {
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                70:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                100:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                }
            },
            recipient: {
                0:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: true,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: false,
                        canViewChanged: false,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                10:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory: false,
                        isRecipient: true,
                        canDownloadAttachments: true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin: false,
                        greenPin: false,
                        redPin: true
                    }
                },
                30:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory: false,
                        isRecipient: true,
                        canDownloadAttachments: true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin: false,
                        greenPin: false,
                        redPin: false
                    }
                },
                50:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory: false,
                        isRecipient: true,
                        canDownloadAttachments: true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin: true,
                        greenPin: false,
                        redPin: false
                    }
                },
                60:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory: false,
                        isRecipient: true,
                        canDownloadAttachments: false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin: true,
                        greenPin: false,
                        redPin: false
                    }
                },
                66:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory: false,
                        isRecipient: true,
                        canDownloadAttachments: false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin: true,
                        greenPin: false,
                        redPin: false
                    }
                },
                70:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory: false,
                        isRecipient: true,
                        canDownloadAttachments: false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin: true,
                        greenPin: false,
                        redPin: false
                    }
                },
                100:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory: false,
                        isRecipient: true,
                        canDownloadAttachments: false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin: true,
                        greenPin: false,
                        redPin: false
                    }
                }
            },
            approverProofer:{
                10:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: false,
                        canViewChanged: false,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                30:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                50:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                60:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                66: {
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                70:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                100:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                }
            },
            adminProofer:{
                10:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: true,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: false,
                        canViewChanged: false,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                30:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: true,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                50:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: true,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                60:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: true,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                66: {
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: true,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                70:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: true,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                100:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: true,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                }
            },
            adminProoferActive:{
                10:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: false,
                        canViewChanged: false,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                30:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                50:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                60:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                66: {
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                70:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                100:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                }
            },
            prooferOwnerPlusFinalApprover:{
                0:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: true,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                10:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: true,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                30:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: true,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                50:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                60:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                66: {
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                70:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                100:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                }
            },
            proofCoOwnerPlusFinalApprover:{
                0:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: true,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                10:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: true,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                30:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: true,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                50:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                60:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                66: {
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                70:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                100:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                }
            },
            proofOwnerPlusProofer:{
                0:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                10:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                30:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                50:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                60:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                66: {
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                70:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                100:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                }
            },
            proofCoOwnerPlusProofer:{
                0:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                10:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                30:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                50:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                60:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                66: {
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                70:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                100:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                }
            },
            proofOwnerPlusApproverProofer:{
                0:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                10:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                30:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                50:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                60:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                66: {
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                70:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                100:{
                    proofLevel: {
                        isProofOwner: true,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                }
            },
            proofCoOwnerPlusApproverProofer:{
                0:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                10:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                30:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: true,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                50:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                60:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: true,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                66: {
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                70:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                100:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: true,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:true
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                }
            },
            adminProoferInActive: {
                10:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: true,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: false,
                        canViewChanged: false,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                30:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: true,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                50:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                60:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                66: {
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                70:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                100:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                }
            },
            unlistedReviewer: {
                0: {
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: false,
                        canViewChanged: false,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                10: {
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: false,
                        canViewChanged: false,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                30:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: true,
                        canEdit: true,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:true
                    }
                },
                50:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:true,
                        greenPin:false,
                        redPin:false
                    }
                },
                60:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: true,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: true
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                66: {
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                70:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                100:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                }
            },
            viewOnlyReviewer: {
                10:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isViewOnlyReviewer: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: false,
                        canViewChanged: false,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                30:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isViewOnlyReviewer: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                50:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isViewOnlyReviewer: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                60:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isViewOnlyReviewer: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                66: {
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isViewOnlyReviewer: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                70:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isViewOnlyReviewer: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                },
                100:{
                    proofLevel: {
                        isProofOwner: false,
                        isFinalApprover: false,
                        isEditor: false,
                        isCoOwner: false,
                        isApprover: false,
                        isViewOnlyReviewer: true,
                        isLockedUser: false,
                        isOnCurrentStep: false,
                        isFinished: false,
                        isAdminUser: false,
                        isMandatory:false,
                        isRecipient: false,
                        canDownloadAttachments:false
                    },
                    commentLevel: {
                        canCreate: false,
                        canEdit: false,
                        canReply: false,
                        canMarkAsDone: false,
                        canMarkAsChanged: false,
                        canViewDone: true,
                        canViewChanged: true,
                        canAgree: false
                    },
                    pinLevel: {
                        greyPin:false,
                        greenPin:false,
                        redPin:false
                    }
                }
            }
        };

        /**
         * Constructor. Takes a userData object, and a PPProof object.
         * Please note that the userData object comes from the application.GetUserData() call, and IS NOT a ppUser type object.
         *
         * @param {userData} - the userData object from the application class
         * @param {ppProof} - a ppProof object containing a full proof. (This includes co-owners, managers, wf and wf steps, wf users. Also proof comments if there are any) This can be left empty and passed to loadProof if there is no proof available on initialisation
         *
         */

        constructor ( userData, ppProof ) {

            this.user = userData;

            this.setUserData(); //set the user email and Id
            if(ppProof) this.loadProof(ppProof);

        }

        /**
         * [Private] updates the proofs related id's.
         *
         */

        updateGeneralObjRelatedIds(){
            this.generalObj.relatedIds.usersStepId = this.usersStepId;
            this.generalObj.relatedIds.currentStepId = this.currentStepId;
            this.generalObj.relatedIds.userIdLocked = this.userIdLocked;
            this.generalObj.relatedIds.finalApproverUserIds = this.finalApproverUserIds;
            this.generalObj.relatedIds.editorId = this.editorId;
        }

        /**
         * [Private] updates the general objects parameters.
         *
         */

        updateGeneralObj(){
            this.generalObj.userType = this.userType;
            this.generalObj.proofStatus = this.proofStatus;
            this.generalObj.proofType = this.proofType;
            this.generalObj.isLocked = this.isLocked;
            this.generalObj.isClosed = this.isClosed;
            this.generalObj.isApproved = this.isApproved;
            this.generalObj.canToggleGlobalMarks = this.canToggleGlobalMarks;
            this.generalObj.isFinished = this.isFinished;
            this.generalObj.isFinishedPast = this.isFinishedPast;
            this.generalObj.isLatestVersion = this.isLatestVersion;
            this.generalObj.isFinalApproverStage = this.isFinalApproverStage;
            this.generalObj.isWithEditor = this.isWithEditor;
        }

        /**
         * [Private] sets the user information from the userData object.
         *
         */

        setUserData(){
            this.userEmail = this.user.email || this.user.userEmail;
            this.userId = this.user.id || this.user.userId;
        }

        hasWorkflow() {
            return !! this.proof.workflow;
        }

        /**
         * [Public] Loads the proof data and assigns class variables, then starts off the chain of processing. This can be called again to reset the permission object after a scope change.
         *
         */

        loadProof(ppProof){

            if(!ppProof){
                throw new Error("No ppProof object set.");
                return "";
            }

            this.proof = ppProof;

            this.proofId = this.proof.id;
            this.proofStatus = this.proof.status;
            this.proofType = this.proof.proofType;
            this.isLocked = this.proof.isLocked;
            this.isClosed = this.proof.isClosed;
            this.isApproved = this.proof.hasApproved;
            this.isLatestVersion = this.proof.isLatestVersion;
            this.userIdLocked = this.proof.lockerId;
            this.proofOwnerUserId = this.proof.ownerId;
            this.recipientId = this.proof.recipient;
            this.unlistedReviewer = this.proof.role === window.__pageproof_quark__.sdk.Enum.ProofRole.UNLISTED_REVIEWER;
            if(this.proof.coOwners.length > 0){
                this.ppCoOwners = this.proof.coOwners;
            }

            //todo delete this
            if(this.proof.editorId){
                this.editorId = this.proof.editorId;
            }

            if (this.proof.editorIds.length) {
                this.editorIds = this.proof.editorIds;
            }

            if (this.proof.filter) {
                this.filter = this.proof.filter;
            }

            if (this.hasWorkflow()) {
                if(this.proof.workflow.workflowManagers.length > 0){
                    this.ppWorkflowManagers = this.proof.workflow.workflowManagers;
                }

                this.loadCurrentStep();
                this.setIsFinishedInPastStep();
                this.setIsUserOnPastStep();
                this.setIfUserIsInviter();
                this.setIfUserIsViewOnlyReviewer();
            }

            this.setIsUserOnCurrentStep();

            this.setIsCoOwner();
            this.setIsAdmin();

            this.setBaseProofUserTypes();

            this.setIfUserIsCoOwnerAndFinalApprover();
            this.hasUserLockedProof();

            this.determineUserType();

            console.log("userType", this.userType);

            this.permissionObj = this.getProoferPermissionObjByStatus();

            this.updatePreviousVersionPermissions();

            this.updateMarkAllType();

            this.updateDynamicProoferPermssions();

            this.updateGeneralObjRelatedIds();

            this.updateGeneralObj();

            this.updatePinLevel();

            this.updateCanAgreePermissions();

            this.updateCanCreate();

            this.updateCanMarkTodoDones();

            // Allow all users to always download attachments
            this.permissionObj.proofLevel.canDownloadAttachments = true;

            console.log("this.permissionObj", this.permissionObj);

        }

        /**
         * [Public] Fetches the users current permissions.
         *
         */

        fetchUserPermissions(){
            return {general:this.generalObj, proofer:this.permissionObj, proof:this.proof};
        }

        /**
         * [Private] Fetches the users current permissions.
         *
         */

        setBaseProofUserTypes () {
            if (this.userId === this.proofOwnerUserId) {
                this.isProofOwner = true;
            }

            if (this.finalApproverUserIds.includes(this.userId)) {
                this.isFinalApprover = true;
            }

            if (this.isInEditors(this.userId)) {
                this.isEditor = true;
            }

            if (this.userId === this.recipientId) {
                this.isRecipient = true;
            }

            if (this.proof.editorIds.length) {
                this.isWithEditor = true;
            }
        }

        isInEditors (userId) {
            return this.proof.editorIds.some((id) => {
                return id === userId;
            });
        }

        setCanToggleGlobalMarks() {
            if (this.isProofOwner === true || this.isFinalApprover === true || this.isEditor === true || this.isApprover === true || this.isCoOwner === true){
                this.canToggleGlobalMarks = true;
            }
        }

        /**
         * [Private] works out if the user is on the current step.
         *
         */

        setIsUserOnCurrentStep(){
            if( ( ( this.usersStepId != "" ) && this.usersStepId == this.currentStepId ) ) {
                this.isOnCurrentStep = true;
            }
        }

        /**
         * [Private] works out if the user is on a past step.
         *
         */

        setIsUserOnPastStep(){
            if(this.proof.workflow.steps){
                this.proof.workflow.steps.forEach((step, index) => {
                    step.users.forEach((stepUser, index) => {
                        if(stepUser.id === this.userId){
                            if(step.isComplete === true){
                                this.isOnPastStep = true;
                                this.usersStepId = step.id;
                            } else if(step.isVisible === true) {
                                this.usersStepId = step.id;
                            }
                        }
                    });
                });
            }
        }

        /**
         * [Private] works out if the users latest or next role is view only reviewer.
         *
         */

        setIfUserIsViewOnlyReviewer(){
            let hasBeenSeenInWorkflow = false;

            if (this.proof.workflow.steps) {
                this.proof.workflow.steps.forEach((step) => {
                    if (step.isVisible === true || !hasBeenSeenInWorkflow) {
                        step.users.forEach((stepUser) => {
                            if (stepUser.id === this.userId) {
                                hasBeenSeenInWorkflow = true;
                                this.isViewOnlyReviewer = !!stepUser.isViewOnly;
                            }
                        });
                    }
                });
            }
        }

        /**
         * [Private] works out if the user is a current owner / final approver.
         *
         */

        setIfUserIsCoOwnerAndFinalApprover(){
            if( this.isCoOwner ){
                if(this.finalApproverUserIds.includes(this.userId) && this.isFinalApproverStage){
                    this.coOwnerIsCurrentFinalApprover = true;
                }
            }
        }

        /**
         * [Private] works out if the user has locked the proof.
         *
         */

        hasUserLockedProof(){
            if(this.userId == this.userIdLocked){
                this.isLockedUser = true;
            }
        }

        /**
         * [Private] loads the current step.
         *
         */

        loadCurrentStep(){
            var currentStep = null;
            if(this.proof.workflow.steps){
                this.proof.workflow.steps.forEach((step, index) => {
                    if(step.isComplete === false && step.isVisible === true){
                        currentStep = step;
                    }else if(step.isComplete === true && step.isVisible === true){



                    }
                    if (step.position === 1000){
                        this.finalApproverUserIds = step.users.map(user => user.id);
                    }
                });
                if (currentStep) {
                    this.ppStep = currentStep;
                    this.currentStepId = this.ppStep.id;
                    if (this.ppStep.position === 1000) {
                        this.isFinalApproverStage = true;
                    }
                    this.searchStepForUser();
                }else{
                    console.warn("No current step");
                }
            }else{
                console.warn("No step data found");
            }
        }

        /**
         * [Private] searches the current step for the logged in user.
         *
         */

        searchStepForUser(){
            var user = null;
            if( this.ppStep.users ){
                this.ppStep.users.forEach((stepUser, index) => {
                    if(stepUser.id === this.userId){
                        user = stepUser;
                        if(stepUser.isApprover === true){
                            this.isApprover = true;
                        }
                        if(stepUser.isComplete === true){
                            this.isFinished = true;
                        }
                        if(stepUser.isMandatory === true){
                            this.isMandatory = true;
                        }
                        if (stepUser.isSkipped) {
                            this.isSkipped = true;
                        }
                    }
                });
                if(user){
                    this.usersStepId = this.ppStep.id;
                }
            }
        }

        /**
         * [Private] searches the workflow to see if the user is finished in a past step.
         *
         */

        setIsFinishedInPastStep(){
            if(this.proof.workflow.steps){
                this.proof.workflow.steps.forEach((step, index) => {
                    step.users.forEach((stepUser, index) => {
                        if (stepUser.id === this.userId && step.isVisible) {
                            this.isFinishedPast = !! stepUser.isComplete;
                        }
                    });
                });
            }
        }

        /**
         * searches if logged in user is inviter in workflow
         */
        setIfUserIsInviter() {
            if(this.proof.workflow.steps){
                this.proof.workflow.steps.some((step) => {
                    step.users.some((stepUser) => {
                        if(stepUser.id === this.userId && stepUser.isInviter === true){
                            this.isInviter = true;
                            return true;
                        }
                    });
                });
            }
        }

        /**
         * [Private] searches the co-owner object for the logged in user.
         *
         */

        setIsCoOwner(){
            if( this.ppCoOwners ){
                this.ppCoOwners.forEach((user) => {
                    if(user.id == this.userId){
                        this.isCoOwner = true;
                    }
                });
            }else{
                console.warn("No co-owners");
            }
        }

        /**
         * [Private] searches the workflow manager object for the logged in user.
         *
         */

        setIsAdmin(){

            console.log("this.ppWorkflowManagers", this.ppWorkflowManagers);

            if( this.ppWorkflowManagers ){
                this.ppWorkflowManagers.forEach((user) => {
                    if(user.id == this.userId){
                        this.isAdminUser = true;
                    }
                });
            }else{
                console.warn("No admins");
            }
        }

        updatePreviousVersionPermissions() {
            if (!this.isLatestVersion) {
                this.updateProoferPermissions(this.userType, this.proofStatus, "commentLevel", "canMarkAsDone", false);
            }
        }

        updateCanAgreePermissions() {
            if (this.proofType === PPProofType.BRIEF) {
                this.updateProoferPermissions(this.userType, this.proofStatus, "commentLevel", "canAgree", false);
            }
        }

        updatePinLevel() {
            if (this.proofType === PPProofType.BRIEF) {
                if ((this.proofStatus === PPProofStatus.NEW && (this.isProofOwner || this.isCoOwner)) ||
                    (this.proofStatus === PPProofStatus.AWAITING_NEW_VERSION)) {
                    this.updateProoferPermissions(this.userType, this.proofStatus, "pinLevel", 'redPin', true);
                }
            }
        }

        updateCanCreate() {
            if (
                this.proofType === PPProofType.BRIEF &&
                [PPProofStatus.PROOFING, PPProofStatus.AWAITING_NEW_VERSION].includes(this.proofStatus) &&
                this.isEditor
            ) {
                this.updateProoferPermissions(this.userType, this.proofStatus, "commentLevel", 'canCreate', true);
            } else if (this.proofType === PPProofType.PROOF) {
                if (this.isLocked &&
                    (['proofer', 'unlistedReviewer'].includes(this.userType) || (this.userType === 'approverProofer' && !this.isLockedUser))) {
                    this.updateProoferPermissions(this.userType, this.proofStatus, "commentLevel", 'canCreate', false);
                    this.updateProoferPermissions(this.userType, this.proofStatus, "commentLevel", 'canEdit', false);
                }
            }
        }

        updateCanMarkTodoDones() {
            if (this.proofType === PPProofType.BRIEF) {
                if ((this.proofStatus === PPProofStatus.NEW || this.proofStatus === PPProofStatus.PROOFING) &&
                    (this.isProofOwner || this.isCoOwner || this.isEditor)) {
                    if (this.isRecipient) {
                        this.updateProoferPermissions(this.userType, this.proofStatus, "commentLevel", 'canMarkAsChanged', true);
                        this.updateProoferPermissions(this.userType, this.proofStatus, "commentLevel", 'canMarkAsDone', true);
                    } else if (this.isEditor) {
                        this.updateProoferPermissions(this.userType, this.proofStatus, "commentLevel", 'canMarkAsDone', true);
                    } else {
                        this.updateProoferPermissions(this.userType, this.proofStatus, "commentLevel", 'canMarkAsChanged', true);
                        this.updateProoferPermissions(this.userType, this.proofStatus, "commentLevel", 'canMarkAsDone', false);
                    }
                    this.updateProoferPermissions(this.userType, this.proofStatus, "commentLevel", 'canViewDone', true);
                    this.updateProoferPermissions(this.userType, this.proofStatus, "commentLevel", 'canViewChanged', true);
                } else if (this.proofStatus === PPProofStatus.AWAITING_NEW_VERSION) {
                    if (this.isRecipient) {
                        this.updateProoferPermissions(this.userType, this.proofStatus, "commentLevel", 'canMarkAsDone', true);
                        this.updateProoferPermissions(this.userType, this.proofStatus, "commentLevel", 'canMarkAsChanged', true);
                    } else if (this.isProofOwner || this.isCoOwner) {
                        this.updateProoferPermissions(this.userType, this.proofStatus, "commentLevel", 'canMarkAsDone', false);
                        this.updateProoferPermissions(this.userType, this.proofStatus, "commentLevel", 'canMarkAsChanged', false);
                    }
                }
            } else if (this.proofType === PPProofType.PROOF) {
                if (this.proofStatus === PPProofStatus.FINAL_APPROVING
                    && this.isLocked
                    && this.isApprover
                    && !this.isLockedUser) {
                        this.updateProoferPermissions(this.userType, this.proofStatus, "commentLevel", 'canMarkAsChanged', false);
                    }
            }
        }

        updateMarkAllType () {
            const { TODO, DONE, UNMARKED } = PPCommentMarkType;
            let type = null,
                isAnyCommentsForMarking = false,
                markAllOption = {};
            if (this.proofType === PPProofType.BRIEF) {
                if (this.isRecipient && this.proof.status <= PPProofStatus.AWAITING_NEW_VERSION) {
                    type = 'all'
                } else if (this.isProofOwner && this.proof.status === PPProofStatus.PROOFING) {
                    type = 'todo';
                } else if (this.isEditor
                    && (this.proof.status === PPProofStatus.PROOFING || this.proof.status === PPProofStatus.AWAITING_NEW_VERSION)) {
                    type = 'done';
                }
            } else if ((this.isProofOwner || this.isCoOwner) && this.proof.status <= PPProofStatus.AWAITING_NEW_VERSION) {
                    type = 'all';
            } else {
                if ((this.isFinalApprover || this.isApprover ) && this.proof.status === 30) {
                    type = 'todo';
                } else if (this.isEditor
                    && (this.proof.status === PPProofStatus.CHANGES_REQUESTED || this.proof.status === PPProofStatus.AWAITING_NEW_VERSION)) {
                    type = 'done';
                }
            }

            if (type) {
                let markAsTodoCount = 0,
                    markAsDoneCount = 0,
                    unMarkTodoCount = 0,
                    unMarkDoneCount = 0,
                    unMarkAllCount = 0;

                let filteredComments = [];
                if (this.filter) {
                    this.proof.pages.forEach(page => {
                        filteredComments = $filter('filter')(page.comments, this.filter.fn);
                        filteredComments.forEach((comment) => {
                            const { isDone, isTodo, isPrivate } = comment;

                            if (isPrivate) {
                                return;
                            }

                            markAsTodoCount += ((!isDone && !isTodo) || (type !== 'todo' && isDone) ? 1 : 0);
                            markAsDoneCount += (!isDone ? 1 : 0);
                            unMarkTodoCount += (isTodo && !isDone ? 1 : 0);
                            unMarkDoneCount += (isDone ? 1 : 0);
                            unMarkAllCount += (isDone || isTodo ? 1 : 0);
                        });
                    });
                } else {
                    this.proof.pages.forEach((page) => {
                        page.comments.forEach((comment) => {
                            const { isDone, isTodo, isPrivate } = comment;

                            if (isPrivate) {
                                return;
                            }

                            markAsTodoCount += ((!isDone && !isTodo) || (type !== 'todo' && isDone) ? 1 : 0);
                            markAsDoneCount += (!isDone ? 1 : 0);
                            unMarkTodoCount += (isTodo && !isDone ? 1 : 0);
                            unMarkDoneCount += (isDone ? 1 : 0);
                            unMarkAllCount += (isDone || isTodo ? 1 : 0);
                        });
                    });
                }

                switch (type) {
                    case 'todo':
                        markAllOption = {
                            'markAsTodo': { key: 'markAsTodo', type: TODO, state: true, count: markAsTodoCount },
                            'unMarkTodo': { key: 'unMarkTodo', type: TODO, state: false, count: unMarkTodoCount },
                        };
                        isAnyCommentsForMarking = markAsTodoCount || unMarkTodoCount;
                        break;
                    case 'done':
                        markAllOption = {
                            'markAsDone': { key: 'markAsDone', type: DONE, state: true, count: markAsDoneCount },
                            'unMarkDone': { key: 'unMarkDone', type: DONE, state: false, count: unMarkDoneCount },
                        };
                        isAnyCommentsForMarking = markAsDoneCount || unMarkDoneCount;
                        break;
                    case 'all':
                        markAllOption = {
                            'markAsTodo': { key: 'markAsTodo', type: TODO, state: true, count: markAsTodoCount },
                            'markAsDone': { key: 'markAsDone', type: DONE, state: true, count: markAsDoneCount },
                            'unMarkAll': { key: 'unMarkAll', type: UNMARKED, state: false, count: unMarkAllCount },
                        };
                        isAnyCommentsForMarking = markAsTodoCount || markAsDoneCount || unMarkAllCount;
                        break;
                    default:
                        throw new Error('Unknown mark all type.');
                }
            }

            this.updateProoferPermissions(this.userType, this.proofStatus, "commentLevel", "canMarkAll", (type !== null && isAnyCommentsForMarking));
            this.updateProoferPermissions(this.userType, this.proofStatus, "commentLevel", "markAll", markAllOption);
        }

        /**
         * [Private] updates the proofers permissions object based off status and dynamic conditions.
         *
         */

        updateDynamicProoferPermssions(){
            this.updateProoferPermissions(this.userType, this.proofStatus, "proofLevel", "isOnCurrentStep", this.isOnCurrentStep);
            this.updateProoferPermissions(this.userType, this.proofStatus, "proofLevel", "isLockedUser", this.isLockedUser);
            this.updateProoferPermissions(this.userType, this.proofStatus, "proofLevel", "isAdminUser", this.isAdminUser);
            this.updateProoferPermissions(this.userType, this.proofStatus, "proofLevel", "isMandatory", this.isMandatory);
            this.updateProoferPermissions(this.userType, this.proofStatus, "proofLevel", "isFinished", this.isFinished);
            this.updateProoferPermissions(this.userType, this.proofStatus, "proofLevel", "isInviter", this.isInviter);
        }

        /**
         * [Private] updates the proofers permissions with the value passed in.
         *
         */

        updateProoferPermissions(prooferType, status, permissionLevel, permission, value){
            this.permissions[prooferType][status][permissionLevel][permission] = value;
        }

        /**
         * [Private] returns a proofers permission object by status.
         *
         */

        getProoferPermissionObjByStatus(){
            return this.permissions[this.userType][this.proofStatus];
        }

        /**
         * [Private] returns a permission for a proofer.
         *
         */

        getProoferPermission(prooferType, status, permissionLevel){
            return this.userTypes[prooferType][status][permissionLevel];
        }

        /**
         * [Private] returns a userType by its index.
         *
         */

        getUserTypeByIndx(indx){
            return this.userTypes[indx];
        };

        /**
         * [Private] determines what type of proofer is logged in.
         *
         */

        determineUserType(){

            console.log("user vars -- : ", ( this.isProofOwner ) , ( this.isOnCurrentStep ) , ( !this.isFinished ) , ( this.isApprover ) );


            if( ( this.isProofOwner ) && ( this.isApprover ) ) {

                this.userType = this.getUserTypeByIndx(11); //proofOwnerPlusApproverProofer

                this.ownerIsCurrentProofer = true;

            }else if( ( this.isCoOwner ) && ( this.isApprover ) ) {

                this.userType = this.getUserTypeByIndx(12); //proofCoOwnerPlusApproverProofer

                this.ownerIsCurrentProofer = true;

            }else if( ( this.isProofOwner ) && ( this.isFinalApprover ) && this.isFinalApproverStage){

                this.userType = this.getUserTypeByIndx(7); //prooferOwnerPlusFinalApprover

            }else if( ( this.isProofOwner && ( !this.isOnCurrentStep ) && ( !this.coOwnerIsCurrentFinalApprover ) ) ){

                this.userType = this.getUserTypeByIndx(1); //proofOwner

            }else if( ( this.isCoOwner && ( ! this.coOwnerIsCurrentFinalApprover && !this.isOnCurrentStep  ) ) ){

                this.userType = this.getUserTypeByIndx(13); //proofCoOwner

            }else if( ( this.isCoOwner ) && ( this.isOnCurrentStep ) && ( !this.isFinished ) && ( !this.coOwnerIsCurrentFinalApprover ) ){

                this.userType = this.getUserTypeByIndx(10); //proofCoOwnerPlusProofer

            }else if( ( this.isCoOwner ) && ( this.coOwnerIsCurrentFinalApprover ) ){

                this.userType = this.getUserTypeByIndx(8); //proofCoOwnerPlusFinalApprover

            }else if( ( this.isFinalApprover ) && this.isFinalApproverStage ){

                this.userType = this.getUserTypeByIndx(2); //finalApprover

            }else if( ( this.isApprover ) && ( this.isOnCurrentStep ) ){

                this.userType = this.getUserTypeByIndx(4); //approverProofer

            } else if ( this.isEditor ) {

                this.userType = this.getUserTypeByIndx(3); //editor

            } else if (this.isRecipient) {

                this.userType = this.getUserTypeByIndx(15); //recipient

            } else if ((this.isAdminUser) && (this.usersStepId == "")) {

                this.userType = this.getUserTypeByIndx(5); //adminProofer

            }else if( ( this.isAdminUser ) && ( !this.isOnCurrentStep ) ){

                this.userType = this.getUserTypeByIndx(14); //adminProoferInActive

            }else if( ( this.isAdminUser ) && ( this.isOnCurrentStep ) ){

                this.userType = this.getUserTypeByIndx(6); //adminProoferActive

            }else if( ( this.isProofOwner ) && ( !this.isFinalApproverStage ) && this.isOnCurrentStep){

                this.userType = this.getUserTypeByIndx(9); //proofOwnerPlusProofer

            }else if( ( this.isCoOwner ) && ( !this.isFinalApproverStage ) && this.isOnCurrentStep){

                this.userType = this.getUserTypeByIndx(10); //proofCoOwnerPlusProofer

            } else if (this.isViewOnlyReviewer) {
                this.userType = this.getUserTypeByIndx(17); // view only reviewer
            } else if (this.unlistedReviewer) {
                this.userType = this.getUserTypeByIndx(16); // unlistedReviewer
            } else {
                this.userType = this.getUserTypeByIndx(0); // normal proofer
            }

        }

    }

    return PPProofPermissions;
});
