/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const loaderTemplate = `
    <div class="app__loader">
        <div class="app__loader__progress">
            <div class="app__loader__progress__bar" ng-style="{width: loaderCtrl.progress + '%'}"></div>
        </div>
        <div class="app__loader__message" ng-transclude></div>
    </div>
`;

function LoaderDirective(directiveHelper) {
    return {
        restrict: 'E',
        require: ['loader'],
        controller: function() {
            this.progress = 0;
        },
        controllerAs: 'loaderCtrl',
        template: loaderTemplate,
        transclude: true,
        replace: true,
        link(scope, element, attrs, [loaderCtrl]) {
            directiveHelper.oneWayBinding(scope, attrs, 'progress', loaderCtrl, 'progress'); // 0-100
        }
    };
}

app.directive('loader', LoaderDirective);
