/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.factory('PPProofCommentAttachment', function (PPModel, PPFile, PPFileStatus) {
    let { numberTransformer } = PPModel;

    const proofCommentAttachmentTransformer = {
        id: 'FileId',
        extension: 'Extn',
        name: 'Name',
        numberOfPages: numberTransformer('Pages'),
        chunks: 'Chunks',
        status: 'Status'
    };

    class PPProofCommentAttachment extends PPFile {
        /**
         * The number of pages in the file.
         *
         * @type {Number}
         */
        numberOfPages = 0;

        /**
         * The status of the file.
         *
         * Options
         *  - OK
         *
         * @type {String}
         */
        status = null;

        /**
         * Returns whether the attachment has finished processing.
         *
         * Checks if the attachment has an ID (uploading files do not have an ID), and checks that the status
         * is set to 'OK' which is confirmation the file has finished ripping.
         *
         * @returns {Boolean}
         */
        get hasProcessed () {
            return this.id && this.status === PPFileStatus.OK;
        }

        /**
         * Update the proof comment data from the comment data.
         *
         * @param {Object} data
         */
        updateFromProofCommentData (data) {
            this.transform(data, proofCommentAttachmentTransformer);
        }

        /**
         * Create a proof comment attachment with a comment data object.
         *
         * @param {Object} proofCommentData
         */
        static from (proofCommentData) {
            let proofCommentAttachment = new this();
            proofCommentAttachment.updateFromProofCommentData(proofCommentData);
            return proofCommentAttachment;
        }

        deSerialiseAttachmentData (attachment) {
            this.numberOfPages = attachment.numberOfPages,
            this.status = attachment.status
        }

        /**
         * The type of "attachment", alternatives to this type are "snapshot".
         *
         * @type {string}
         */
        static type = 'attachment';
    }

    return PPProofCommentAttachment;
});
