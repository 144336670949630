/* Copyright (C) PageProof Holdings limited - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Authored by: <chris@pageproof.com>, <braden@pageproof.com>, <marcus@pageproof.com> - September 2014
 */

'use strict';

/**
 * Marketing - Homepage
 *
 * @author Jacob Marshall <jacob@pageproof.com>
 */
ppxControllers.controller('Logout', [

    '$scope', '$rootScope', '$location', 'UserService', 'NavigationService', 'Activity', '$webSocket', '$window',
    function ($scope, $rootScope, $location, application, navigation, Activity, $webSocket, $window) {
        application.Logout(function () {
            Activity.clearData();
            $webSocket.close();
            navigation.back(function () {
                $window.location.href = env('marketing_url');
            });
        });
    }

]);
