/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
angular
    .module('ppxGlobalControllers')
    .factory('$api',
        function ($q, $http, UserService) {
            var service = {},
                application = UserService,
                api = application.api,
                endpoints = api.apiEndPoints;

            function getNormalisedKey (normalisedKeys, key) {
                var lowerIndex,
                    lowerKey = key.toLowerCase(),
                    lowerNormalisedKeys = normalisedKeys.map(function (key) {
                        return key && key.toLowerCase();
                    });

                if ((lowerIndex = lowerNormalisedKeys.indexOf(lowerKey)) !== -1) {
                    return normalisedKeys[lowerIndex];
                }

                return key;
            }

            function validateParams (params, requireParams) {
                var isChain = Array.isArray(params) || typeof params === 'string';

                if ( ! requireParams.length || isChain) {
                    return params;
                }

                var validParams = {};

                Object.keys(params).forEach(function (key) {
                    validParams[getNormalisedKey(requireParams, key)] = params[key];
                });

                requireParams.forEach(function (key) {
                    if ( ! validParams.hasOwnProperty(key)) {
                        throw new Error('Required parameter "' + key + '" was not provided.');
                    }
                });

                return validParams;
            }

            Object.keys(endpoints).forEach(function (action) {
                service[action] = service[action] || {};
                Object.keys(endpoints[action]).forEach(function (method) {
                    var requireParams = endpoints[action][method].params || [];

                    service[action][method] = {
                        /**
                         * The known parameters which are required by the endpoint.
                         *
                         * @type {Array}
                         */
                        params: requireParams,

                        /**
                         * Invokes a specific api endpoint, with the specified `rawParams`
                         *
                         * @param {Object|Array|undefined} rawParams
                         * @returns {$q}
                         */
                        fetch: function (rawParams) {
                            var defer = $q.defer(),
                                resolve = defer.resolve,
                                reject = defer.reject,
                                promise = defer.promise,
                                params = validateParams(rawParams, requireParams);

                            api.fetch(action, method, params, function $api__callback (err, result) {
                                // Resolving a promise within Angular forces a digest, so there is no issue with firing
                                // an API request out side of the Angular scope.
                                if (err) {
                                    reject(err);
                                } else {
                                    resolve(result);
                                }
                            });

                            return promise;
                        }
                    };
                });
            });

            // Alias the `$api.error` property to all the valid error responses
            service.error = api.errors;
            service.error.key = api.errorStatus;
            service.error.code = api.errorCodes;

            /**
             * Verifies whether a response (or 'something') is an error.
             *
             * If the err is an Object, it will check whether either the ResponseStatus, or
             * ResponseCode properties are active, and checks if they're entries into the api's
             * list of error codes/statuses.
             *
             * If the err is a String, it checks if the string is within the api's list of
             * error statuses.
             *
             * If the err is a Number, it checks if the number is within the api's list of
             * error codes.
             *
             * @param {Object|String|Number} err
             * @returns {Boolean}
             */
            service.error.is = function is (err) {
                switch (typeof err) {
                    case 'object':
                        return err && ((err.ResponseStatus &&                           // ResponseStatus property
                                            typeof err.ResponseStatus === 'string' &&   // is a string
                                            is(err.ResponseStatus)) ||                  // is in the error status list

                                       (err.ResponseCode &&                             // ResponseCode property
                                            typeof err.ResponseCode === 'number' &&     // is a number
                                            isFinite(err.ResponseCode) &&               // and is finite (not NaN or Infinity)
                                            is(err.ResponseCode)));                     // is in the error code list
                    case 'string':
                        return service.error.key.indexOf(err) !== -1;
                    case 'number':
                        return service.error.code.indexOf(err) !== -1;
                    default:
                        return false;
                }
            };

            console.log(service);
            console.dir($q);

            return service;
        }
    )
    .factory('apiService', function ($api) {
        return $api;
    });
