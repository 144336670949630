/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.factory('PPModel', function () {
    class PPModel {
        /**
         * Sets the properties of a model based on a set of data transformers.
         *
         * If a transformer's value is `undefined` the transformer does not set the property.
         * So functions NEED to return a value in order to take effect.
         *
         * Returns the array of keys the transformer modified.
         *
         * @param {*} data
         * @param {Object} map
         * @returns {String[]}
         */
        transform (data, map) {
            return Object.keys(map).filter(key => {
                let transformer = map[key],
                    value;

                if (typeof transformer === 'string') {
                    value = data[transformer];
                }

                else if (angular.isFunction(transformer)) {
                    try {
                        value = transformer(data, this);
                    } catch (err) {
                        console.warn('[PPModel#transform] Function transformer (' + transformer.name + ') threw an error - ' + err.message);
                        console.error(err);
                    }
                }

                if (typeof value === 'undefined') {
                    return false;
                }

                this[key] = value;
                return true;
            });
        }

        /**
         * Returns a standard date transformer for a model instance.
         *
         * Returns `null` if the date is invalid (determined by `moment.prototype.isValid`) or `0001-01-01T00:00:00`.
         *
         * @param {String} property
         * @param {String} format
         * @returns {Function}
         */
        static dateTransformer (property, format = moment.ISO_8601) {
            return (data) => {
                let date = moment(moment.utc(data[property], format).toDate());

                if (date.unix() === -62135596800) { // 0001-01-01T00:00:00
                    return null;
                }

                if ( ! date.isValid()) {
                    return null;
                }

                return date;
            };
        }

        /**
         * Returns a number transformer for a model instance.
         *
         * @param {String} property
         * @returns {Function}
         */
        static numberTransformer (property) {
            return (data) => {
                let num = parseFloat(data[property]);

                if (isNaN(num)) {
                    return null;
                }

                return num;
            }
        }

        /**
         * Returns a db boolean transformer for a model instance.
         *
         * @param {String} property
         * @returns {Function}
         */
        static booleanTransformer (property) {
            return data => String(data[property]) == '1';
        }

        /**
         * Returns an array length transformer for a model instance.
         *
         * @param {String} property
         * @returns {Function}
         */
        static arrayLengthTransformer (property) {
            return data => (data[property] && data[property].length) || 0;
        }

        /**
         * Returns a timestamp transformer for a model instance.
         *
         * @param {String} property
         * @returns {Function}
         */
        static timestampTransformer (property) {
            return data => moment.unix(data[property]);
        }

        /**
         * Returns whether or not a property exists in a data.
         *
         * @param {String} property
         * @returns {Function}
         */
        static hasPropertyTransformer (property) {
            return data => property in data;
        }

        /**
         * Returns whether or not a property is truthy.
         *
         * @param {String} property
         * @returns {Function}
         */
        static truthyTransformer (property) {
            return data => !! data[property];
        }

        /**
         * Returns the stringified data, as JSON (array, or object).
         *
         * @param {String} property
         * @returns {Function}
         */
        static jsonTransformer (property, strictType = null) {
            return data => {
                let result = null;
                let parsed = null;
                try {
                    parsed = JSON.parse(data[property]);
                } catch(err) {
                    // Ignore parsing errors, for now.
                }
                const strictTypeValue = strictType
                    ? String(strictType).toLowerCase() : null;
                switch (strictTypeValue) {
                    case 'object': {
                        if (Object.prototype.toString.call(parsed) === '[object Object]') {
                            result = parsed;
                        } else {
                            result = {};
                        }
                        break;
                    }
                    case 'array': {
                        if (parsed && typeof parsed.length === 'number') { // Loose checking
                            result = parsed;
                        } else {
                            result = [];
                        }
                        break;
                    }
                    default: {
                        // Ignore what kind of type the actual parsed as
                        result = parsed;
                        break;
                    }
                }
                return result;
            };
        }
    }

    return PPModel;
});
