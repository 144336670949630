/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const EXPIRE_TIMEOUT = env('comment_expire_timeout', 7 * (24 * (60 * (60 * 1000)))); // 1 week

class CommentRepository extends BaseDatabaseCache {
    identifiers = {};
    identifierCache = {};
    constructor (utilService, userService, backendService, apiService, sdk) {
        super();
        this.utilService = utilService;
        this.userService = userService;
        this.backendService = backendService;
        this.apiService = apiService;
        this.sdk = sdk;
    }
    clearExpiredDatabaseEntries (db) {
        return db.comments.where('createdAt').belowOrEqual(Date.now() - EXPIRE_TIMEOUT).delete();
    }
    resolveFromDatabase (db, info) {
        return db.comments.where('hash').equals(info)
            .first((item) => {
                if (item && item.comment && item.comment.length) {
                    return item.comment;
                } else {
                    throw new Error('Cannot resolve/find item in database.');
                }
            });
    }
    /**
     * Does not resolve from server, but instead resolves from the comment object which was
     * passed through to `getCommentIdentifier` before the call.
     */
    resolveFromServer (info) {
        return this.decryptComment(this.getCommentByIdentifier(info));
    }
    serialiseForDatabase (info, data) {
        let comment = this.getCommentByIdentifier(info);
        return {
            commentId: comment.id,
            hash: info,
            createdAt: Date.now(),
            comment: data
        };
    }
    storeInDatabase (db, info, data) {
        return db.comments.add(data);
    }
    removeFromDatabase (db, info) {
        return db.comments.where('commentId').equals(info).delete();
    }
    decryptComment (comment) {
        return this.sdk.crypto().decryptComment(comment.encryptedComment, comment.envelope, this.sdk.keyPairs.getPrivateKeyPEMs());
    }

    /**
     * Attempt to get the original comment object by identifier.
     *
     * @param {String} id
     * @returns {PPProofComment}
     */
    getCommentByIdentifier (id) {
        if (this.identifiers.hasOwnProperty(id)) {
            return this.identifiers[id];
        }
        return null;
    }
    /**
     *
     * @param {PPProofComment} comment
     * @returns {String}
     */
    getCommentIdentifier (comment) {
        const hashData = comment.id + comment.encryptedComment;
        let id = this.identifierCache[hashData];
        if (!id) {
            id = this.utilService.md5(hashData);
            this.identifierCache[hashData] = id;
        }
        this.identifiers[id] = comment;
        return id;
    }

    /**
     * Loads in bulk, any decrypted comment text for the given comments that have already been decrypted.
     *
     * This method does not attempt to decrypt any comments that are not in the cache.
     *
     * This method can be used for performance reasons, as it is much more performant than loading the comments individually.
     *
     * @param {PPProofComment[]} comments The comments to load data for.
     * @returns {Promise<{ [commentId: string]: string }>}
     */
    localBulk(comments) {
        const hashes = comments.map(comment => this.getCommentIdentifier(comment));
        return new Promise((resolve, reject) => {
            this.$$.dbService.ready().then((db) => {
                db.comments.where('hash').anyOf(hashes).toArray((records) => {
                    const localComments = {};
                    records.forEach((record) => {
                        localComments[record.commentId] = record.comment;
                    });
                    resolve(localComments);
                });
            }, reject);
        });
    }

    /**
     * @param {Object} comment
     * @returns {Object}
     */
    transformServerCommentObject (comment) {
        return {
            id: comment.CommentId,
            encryptedComment: comment.EncryptedComment,
            envelope: comment.Envelope,
            proofId: comment.ProofId
        };
    }

    loadCommentsForProofWithPageNumber (proofId, pageNumber) {
        return this.apiService
            .comments.getForProofPage
            .fetch({
                proofId: proofId,
                Page: pageNumber
            });
    }
}

app.service('commentRepositoryService', CommentRepository);

/*

let comment = PPProofComment.from(commentFromServer);
let id = commentRepositoryService.getCommentIdentifier(comment);
commentRepositoryService.get(id).then((decryptedComment) => {
    comment.decryptedComment = decryptedComment;
});

 */
