/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class ProofCacheService {

    /**
     * Cached proof request promise objects
     */
    $$proofPromises = {};

    constructor(proofRepositoryService, $q, features) {
        this.$$ = {
            proofRepositoryService,
            $q,
            features,
        }
    }

    getProof(id, callback) {
        return this.getProofFromCache(id, callback);
    }

    getProofFromCache(id, callback) {
        if (this.isPromiseCached(id)) {
            this.updateProofFromServer(id, callback);
            return this.getProofFromCacheById(id)
                .then(proof => {
                    proof.isFromCache = true;
                    return proof;
                });
        } else {
            return this.getProofFromServer(id);
        }
    }

    updateProofFromServer(id, callback) {
        this.getProofFromServer(id)
            .then(proof => {
                this.$$proofPromises[id] = proof;
                if (callback) {
                    callback(proof);
                }
            });
    }

    getProofFromCacheById(id) {
        return this.$$.$q(resolve => {
            let promise = this.$$proofPromises[id];
            resolve(promise);
        });
    }

    getProofFromServer(id) {
        let promise = (
            this.$$
            .proofRepositoryService
            .getById(id, angular.extend({
                referrer: this.$$.features.referrer,
                proof: true,
                status: true,
                versions: true,
                workflow: true,
                finalApprover: true,
                comments: false,
                coOwners: true,
                owner: true,
                editor: true,
                recipient: true,
                workflowManagers: true,
                video: true,
                decisionOptions: true,
            }))
        );
        this.cacheProofPromise(promise, id);
        return promise;
    }

    cacheProofPromise(promise, id) {
        if (!this.isPromiseCached(id)) {
            this.$$proofPromises[id] = promise;
        }
    }
    
    removeCachedProof(id) {
        delete this.$$proofPromises[id]; 
    }

    isPromiseCached(id) {
        return this.$$proofPromises.hasOwnProperty(id);
    }
}

app.service('proofCacheService', ProofCacheService);
