/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.factory('PPProofActions', function (SegmentIo, $q, apiService, $location, PPProofStatus, PPProofActionType, PPProofButtonState, features, appService, PPProofType, sdk) {

    class PPProofActions {

        /**
         *
         * ProofActions object
         *
         * Class to handle dynamically switching buttons on the proof page. Usage:
         *
         *   var proofActions = new PPProofActions(ppProofPermissionsObject);
         *
         *   var buttonStateEnum = proofActions.getButtonState(); //get the current button state enum (described in list below)
         *
         *   proofActions.updateActions(ppProofPermissionsObject); //update the actions object based off a changed permission object
         *
         *      -- or --
         *
         *   proofActions.updateCommentAction(ppProof); //this method takes a ppProof object and counts the to-dos / dones to work out the button action
         *
         *   var updatedButtonStateEnum = proofActions.getButtonState(); //get the updated button state enum, based off changes from the updateActions method
         *
         *      -- other public methods: --
         *
         *   proofActions.setProofMessage('some message to the fa or owner or editor'); //sets the proofer message for the owner/FA/editor
         *
         *   proofActions.action(); //this method gets called when the user clicks on their button. It will redirect to the dashboard when its finished.
         *
         *
         */

        /**
         * The ppProofPermissions object
         *
         * @type {PPProof}
         */
        proofPermissions = null;

        /**
         * The user object
         *
         * @type {userData}
         */
        user = null;

        /**
         * Enum of the proof button states
         *
         * @type {object}
         */

        buttonStates = {
            showFinished: PPProofButtonState.FINISHED,
            showApproveAndUnlock: PPProofButtonState.APPROVE_AND_UNLOCK,
            showApproveAndUnlockLimitedCommentVisibility: PPProofButtonState.APPROVE_AND_UNLOCK_LIMITED_COMMENT_VISIBILITY,
            showGatekeeperSendChanges: PPProofButtonState.GATEKEEPER_SEND_CHANGES,
            showApprove: PPProofButtonState.APPROVE,
            showApproveLimitedCommentVisibility: PPProofButtonState.APPROVE_LIMITED_COMMENT_VISIBILITY,
            showApproverSendChanges: PPProofButtonState.APPROVER_SEND_CHANGES,
            showUploadNewVersion: PPProofButtonState.UPLOAD_NEW_VERSION,
            showSendBrief: PPProofButtonState.SEND_BRIEF,
            showDecisionGiven: PPProofButtonState.DECISION_GIVEN,
        }

        /**
         * The current button state. `null` means show no button
         *
         * @type {String}
         */
        buttonState = null;

        /**
         * The proof message
         *
         * @type {string}
         */
        message = null;

        /**
         * The current stepId
         *
         * @type {string}
         */
        currentStepId = "";

        /**
         * The users stepId
         *
         * @type {string}
         */
        usersStepId = "";

        /**
         * The editor's UserId
         *
         * @type {string}
         */
        editorUserId = "";

        /**
         * The current page number
         *
         * @type {int}
         */
        pageNumber = 1;

        /**
         * Boolean value representing if the proof is locked
         *
         * @type {bool}
         */
        isLocked = false;

        /**
         * Boolean value representing if the proof is closed
         *
         * @type {bool}
         */
        isClosed = false;

        /**
         * UserId of the user who has locked the proof
         *
         * @type {string}
         */

        userIdLocked = "";

        /**
         * Boolean value representing if the proof is at the final approver stage
         *
         * @type {bool}
         */
        isFinalApproverStage = false;

        /**
         * Boolean value representing if the proofer is on the current step
         *
         * @type {bool}
         */
        isOnCurrentStep = false;

        /**
         * Boolean value representing if the proofer is in a past step
         *
         * @type {bool}
         */
        isOnPastStep = false;

        /**
         * Boolean value representing if the logged in user is a proofer who has finished on a past step
         *
         * @type {bool}
         */

        isFinishedPast = false;

        /**
         * Boolean value representing if the proofer is finished on the current step
         *
         * @type {bool}
         */
        isFinished = false;

        /**
         * Boolean value representing if the logged in user is the final approver proofer
         *
         * @type {bool}
         */
        isFinalApprover = false;

        /**
         * Boolean value representing if the logged in user is a approver proofer
         *
         * @type {bool}
         */
        isApprover = false;

        /**
         * Boolean value representing if the logged in user is a mandatory proofer
         *
         * @type {bool}
         */
        isMandatory = false;

        /**
         * Boolean value representing if the logged in user is the proof owner
         *
         * @type {bool}
         */
        isProofOwner = false;

        /**
         * Boolean value representing if the logged in user is the proof co-owner
         *
         * @type {bool}
         */
        isCoOwner = false;

        /**
         * Boolean value representing if the proof is with a editor to marking dones
         *
         * @type {bool}
         */
        isWithEditor = false;

        /**
         * Number of changes on proof
         *
         * @type {int}
         */
        changes = 0;

        /**
         * number of dones on proof
         *
         * @type {int}
         */
        dones = 0;

        /**
         * Constructor. Takes a PPProof object.
         *
         * @param {ppProof} - a ppProof object containing a full proof. (This includes co-owners, managers, wf and wf steps, wf users. Also proof comments if there are any)
         *
         */

        constructor ( ppProofPermissions ) {
            if(ppProofPermissions) this.updateActions(ppProofPermissions);
        }

        /**
         * [Public] Public method that updates the buttons actions based of the passed in ppProofPermissions object.
         *
         */

        updateActions(ppProofPermissions){
            this.proofPermissions = ppProofPermissions;
            this.user = ppProofPermissions.user;
            this.proof = ppProofPermissions.proof;
            this.proofId = ppProofPermissions.proofId;
            this.userType = ppProofPermissions.userType;
            this.isFinalApprover = ppProofPermissions.isFinalApprover;
            this.isApprover = ppProofPermissions.isApprover;
            this.isMandatory = ppProofPermissions.isMandatory;
            this.isProofOwner = ppProofPermissions.isProofOwner;
            this.isCoOwner = ppProofPermissions.isCoOwner;
            this.isEditor = ppProofPermissions.isEditor;
            this.isRecipient = ppProofPermissions.isRecipient;
            this.isLocked = ppProofPermissions.isLocked;
            this.userId = ppProofPermissions.userId;
            this.userIdLocked = ppProofPermissions.userIdLocked;
            this.isFinalApproverStage = ppProofPermissions.isFinalApproverStage;
            this.isFinished = ppProofPermissions.isFinished;
            this.proofStatus = ppProofPermissions.proofStatus;
            this.isWithEditor = ppProofPermissions.isWithEditor;
            this.currentStepId = ppProofPermissions.currentStepId;
            this.isOnCurrentStep = ppProofPermissions.isOnCurrentStep;
            this.isOnPastStep = ppProofPermissions.isOnPastStep;
            this.usersStepId = ppProofPermissions.usersStepId;
            this.isFinishedPast = ppProofPermissions.isFinishedPast;
            this.isLatestVersion = ppProofPermissions.isLatestVersion;
            this.isClosed = ppProofPermissions.isClosed;
            this.proofType = ppProofPermissions.proofType;
            this.groupId = this.proof.groupId;
            this.isSkipped = ppProofPermissions.isSkipped;

            console.log("ppProofPermissions", ppProofPermissions);


            this.determineButtonState();

            this.determineDecisionButtonState();
        }

        /**
         * [Public] Public method that updates the buttons actions based of the passed in ppProof object.
         *
         */

        updateCommentActions(ppProof){
            this.countChangesAndDones(ppProof);
            this.determineButtonState(true);
        }

        /**
         * [Private] determines the button state
         *
         */

        determineButtonState(overRideCount = false){

            if(!overRideCount) this.countChangesAndDones(this.proof);

            console.log("action userType: ", this.userType);

            switch(this.userType){

                case 'prooferOwnerPlusFinalApprover':
                case 'proofCoOwnerPlusFinalApprover':

                    if (this.isClosed === true) {
                        this.setButtonState("");
                    } else if (this.canUploadNewVersion()) {
                        this.setButtonState("showUploadNewVersion");
                    } else if (this.proofStatus === PPProofStatus.FINAL_APPROVING) {
                        if (this.isFinished) {
                            this.setButtonState("");
                        } else if (this.proof.todoCount > 0) {
                            this.setButtonState("showApproverSendChanges");
                        } else {
                            this.setButtonState("showApprove");
                        }
                    }
                    break;

                case 'proofOwner':
                case 'proofCoOwner':
                    if (this.proofType === PPProofType.BRIEF && this.proofStatus === PPProofStatus.NEW) {
                        this.setButtonState("showSendBrief");
                    }  else if (this.canUploadNewVersion()) {
                        this.setButtonState("showUploadNewVersion");
                    } else {
                        this.setButtonState("");
                    }
                    break;

                case 'recipient':
                    if (this.canUploadNewVersion()) {
                        this.setButtonState("showUploadNewVersion");
                    } else {
                        this.setButtonState("");
                    }
                    break;

                case 'finalApprover':

                    if (this.isClosed) {
                        this.setButtonState("");
                    } else if (this.canUploadNewVersion()) {
                        this.setButtonState("showUploadNewVersion");
                    } else if (this.proofStatus == PPProofStatus.FINAL_APPROVING) {
                        if (this.isFinished) {
                            this.setButtonState("");
                        } else if (this.proof.todoCount > 0) {
                            this.setButtonState("showApproverSendChanges");
                        } else {
                            this.setButtonState("showApprove");
                        }
                    } else {
                        this.setButtonState("");
                    }
                    break;

                case 'proofCoOwnerPlusApproverProofer':
                case 'proofOwnerPlusApproverProofer':

                    if (this.isClosed) {
                        this.setButtonState("");
                    } else if (this.canUploadNewVersion()) {
                        this.setButtonState("showUploadNewVersion");
                    } else if (this.proofStatus == PPProofStatus.FINAL_APPROVING && this.isOnCurrentStep && !this.isFinished) {
                        if (!this.isLocked || this.userIdLocked === this.userId) {
                            if (this.proof.todoCount > 0 && !this.isSkipped) {
                                this.setButtonState("showGatekeeperSendChanges");
                            } else if (this.isSkipped) {
                                this.setButtonState("showFinished");
                            } else {
                                this.setButtonState("showApproveAndUnlock");
                            }
                        } else {
                            this.setButtonState("");
                        }
                    } else {
                        this.setButtonState("");
                    }
                    break;

                case 'approverProofer':

                    if (this.isClosed) {
                        this.setButtonState("");
                    } else if (this.canUploadNewVersion()) {
                        this.setButtonState("showUploadNewVersion");
                    } else if (this.proofStatus == PPProofStatus.FINAL_APPROVING && this.isOnCurrentStep && !this.isFinished) {
                        if (!this.isLocked || this.userIdLocked === this.userId) {
                            if (this.proof.todoCount > 0) {
                                this.setButtonState("showGatekeeperSendChanges");
                            } else {
                                this.setButtonState("showApproveAndUnlock");
                            }
                        } else {
                            this.setButtonState("");
                        }
                    } else {
                        this.setButtonState("");
                    }
                    break;

                case 'editor':

                    if (this.canUploadNewVersion()) {
                        this.setButtonState("showUploadNewVersion");
                    } else {
                        this.setButtonState("");
                    }
                    break;

                case 'adminProofer':

                    if(this.isClosed === true || this.isLocked === true) {
                        this.setButtonState("");
                    }else{
                        this.setButtonState("");
                    }
                    break;

                case 'adminProoferInActive':

                    if (this.isClosed === true || this.isLocked === true) {
                        this.setButtonState("");
                    } else if (this.canFinish()) {
                        this.setButtonState("showFinished");
                    } else {
                        this.setButtonState("");
                    }
                    break;

                case 'adminProoferActive':

                    if(this.isClosed === true || this.isLocked === true) {
                        this.setButtonState("");
                    }else if(this.isFinalApproverStage === true && this.isLocked === false && this.canFinish()){
                        this.setButtonState("showFinished");
                    }else{
                        this.setButtonState("");
                    }
                    break;

                case 'proofCoOwnerPlusProofer':
                case 'proofOwnerPlusProofer':

                    console.log("ya: ", this.isClosed, this.isLocked, this.isFinalApproverStage, this.isFinished);
                    if (this.canUploadNewVersion()) {
                        this.setButtonState("showUploadNewVersion");
                    } else if (this.isClosed === true || this.isLocked === true) {
                        this.setButtonState("");
                    } else if (this.isLocked === false && this.canFinish()) { //on current // past step
                        this.setButtonState("showFinished");
                    } else {
                        this.setButtonState("");
                    }
                    break;
                case 'unlistedReviewer':
                    if (!this.proof.hasDecisionsEnabled ||
                        ![PPProofStatus.NEW, PPProofStatus.PROOFING, PPProofStatus.FINAL_APPROVING].includes(this.proofStatus)) {
                        this.setButtonState("");
                    } else if (this.proof.decisionOptions && this.proof.decisionOptions.currentDecision) {
                        this.setShowDecisionChangeButton('reviewer', this.proof.decisionOptions.currentDecision.id);
                    } else if (this.isLocked === true) {
                        this.setButtonState("");
                    } else {
                        this.setButtonState("showFinished");
                    }
                    break;
                default : //proofer

                    if(this.isClosed === true || this.isLocked === true) {
                        this.setButtonState("");
                    }else if(this.isLocked === false && this.canFinish()) {
                        this.setButtonState("showFinished");
                    } else if ((this.isFinished || this.isFinishedPast)) {
                        this.setButtonState("");
                    } else {
                        this.setButtonState("showFinished");
                    }
                    break;

            }

            /*
            Special override for proofer's that aren't finished and are in past steps
            */

            if(this.buttonState === null && this.canFinishFromPastStep()){
                this.setButtonState("showFinished");
            }

            /* Prevent users who cannot see all the comments from returning the proof or returning todos */
            if (this.proof.commentVisibility !== null && this.proof.teamId !== this.user.teamId) {
                switch (this.buttonState) {
                    case PPProofButtonState.APPROVE:
                    case PPProofButtonState.APPROVER_SEND_CHANGES: {
                        this.setButtonState("showApproveLimitedCommentVisibility");
                        break;
                    }

                    case PPProofButtonState.APPROVE_AND_UNLOCK:
                    case PPProofButtonState.GATEKEEPER_SEND_CHANGES: {
                        this.setButtonState("showApproveAndUnlockLimitedCommentVisibility");
                        break;
                    }
                }
            }

            if (!this.isLatestVersion) {
                this.setButtonState("");
            }

            console.log("this.buttonState:", this.buttonState);

        }

        determineDecisionButtonState() {
            if (!this.buttonState &&
              this.proof.workflow &&
              [PPProofStatus.NEW, PPProofStatus.PROOFING, PPProofStatus.FINAL_APPROVING].includes(this.proofStatus)) {
                const steps = this.proof.workflow.steps.slice().reverse();
                steps.some(step => {
                    return step.users.some(user => {
                        if (user.id === this.userId && user.isComplete) {
                            const decisionUserType = step.position === 1000
                                ? 'approver'
                                : user.isViewOnly
                                    ? 'view-only'
                                    : user.isApprover
                                        ? 'gatekeeper'
                                        : 'reviewer';
                            this.setShowDecisionChangeButton(decisionUserType, user.decision);
                            return true;
                        }
                    });
                });
            }
        }

        setShowDecisionChangeButton(userType, decision) {
            this.setButtonState("showDecisionGiven");
            this.proof.decisionUserType = userType;
            this.proof.decisionValue = decision;
        }

        /**
         * [Private] detects whether the user can finish or not if they are in a past step
         *
         */

        canFinishFromPastStep(){
            if(this.isFinishedPast === false && this.isOnPastStep === true && ( this.isLocked === false && this.isClosed === false ) ){
                return true;
            }else{
                return false;
            }
        }

        /**
         * [Private] detects whether the user can finish or not
         *
         */

        canFinish(){
            if( this.isFinished === false && this.isOnCurrentStep === true ){
                return true;
            }else{
                return false;
            }
        }

        /**
         * [Private] detects whether the user can view/use upload new version button on proof screen
         *
         */
        canUploadNewVersion() {
            if (this.proofType === PPProofType.BRIEF) {
                return (
                    ['editor', 'recipient'].includes(this.userType) &&
                    [PPProofStatus.PROOFING, PPProofStatus.AWAITING_NEW_VERSION].includes(this.proofStatus)
                );
            }

            return (
                (this.isEditor || this.userType.includes('Owner')) &&
                [PPProofStatus.CHANGES_REQUESTED, PPProofStatus.AWAITING_NEW_VERSION, PPProofType.HAS_NEW_VERSION].includes(this.proofStatus)
            );
        }

        /**
         * [Private] counts changes and dones
         *
         */

        countChangesAndDones(ppProof){
            const hasCommentsLoaded = ppProof.pages.some(page => {
                return page.comments.length;
            });
            if (hasCommentsLoaded) {
                ppProof.pages.forEach((pageVal, index) => {
                    pageVal.comments.forEach((comVal, comIndx) => {
                        if (comVal.isTodo === true) {
                            this.changes ++;
                        }
                        if (comVal.isDone === true) {
                            this.dones ++;
                        }
                    });
                });
            } else {
                this.changes = ppProof.todoCommentCount;
            }
        }

        /**
         * [Private] sets the button state
         *
         */

        setButtonState(state){
            if(state === ""){
                this.buttonState = null;
                return;
            }
            this.buttonState = this.buttonStates[state];
        }

        /**
         * [Public] get the current button state
         *
         */

        getButtonState() {
            return this.buttonState;
        }

        /**
        * [Private] redirect to the path
        *
        */
        redirectTo(path, importantActionOnDashboard, proofId, action) {
            if (!importantActionOnDashboard && features.flags.finished === 'close-window') {
                path = 'proof/finished-proofing';
            } else if (proofId) {
                path = `dashboard/proof?proofId=${proofId}&hasFileError=0`;
            } else if (path === 'dashboard' && this.groupId) {
                path = `dashboard/group?groupId=${this.groupId}`;
            }
            const ffName = `${action}.redirect`;
            const customRedirectUrl = features.flags[ffName];
            if (customRedirectUrl) {
                // todo maybe extract this somewhere else
                const redirectUrl = customRedirectUrl
                    .replace(/\{proof\.id\}/g, proofId);

                appService.showLoader();
                location.href = redirectUrl;
                return $q.when(false);
            }
            $location.url(path);
            return $q.when(false);
        }

        /**
        * [Public] set the proofer message if there is one
        *
        */

        setProofMessage(message){
            this.message = message;
        }

        /**
        * [Private] update the proofer message
        *
        */

        updateProofMessage () {
            return apiService.proofs.updateMessage.fetch({
                ProofId: this.proofId,
                OwnerMessage: stripTags(this.message)
            });
        }

        /**
        * [Private] fire finished proofing
        *
        */

        finishedProofing(){
            return apiService.workflows.complete.fetch({UserId:this.userId, StepId:this.currentStepId});
        }

        /**
         * [Private] fire changesComplete
         *
         */

        changesComplete(){
            return apiService.proofs.changesComplete.fetch(this.proofId);
        }

        /**
         * [Private] fire finished proofing for non-mandatory proofers
         *
         */

        finishedProofingNonMandatory(){
            //debugger;
            var stepId = this.currentStepId;
            if(this.isOnPastStep === true){
                stepId = this.usersStepId;
            }
            return apiService.workflows.completeNonMandatory.fetch({UserId:this.userId, StepId:stepId});
        }

        /**
        * [Private] fire finished proofing for an approver
        *
        */

        finishedProofingApprover(){
            return apiService.workflows.approverSendTodos.fetch({UserId:this.userId, StepId:this.currentStepId});
        }


        /**
         * [Public] sets the editor on a proof and redirects to the dashboard once complete
         *
         */

        setEditor (email) {
            return sdk.proofs.editors.add(this.proofId, {email: email}, stripTags(this.message))
                .then(() => this.redirectTo("dashboard"));
        }

        /**
        * [Private] unlock the proof
        * todo delete it
        */

        unlockProof(){
            return sdk.proofs.unlock(this.proofId);
        }

        /**
        * [Private] lock the proof
        * todo delete it
        */

        lockProof(){
            return sdk.proofs.lock(this.proofId);
        }

        /**
        *
        *
        * @see {PPProofButtonState}
        * @returns {String}
        */

        getActionType () {
            if ((this.isProofOwner
                || this.isCoOwner
                || this.isEditor
                || this.isRecipient)
                && this.proofStatus === PPProofStatus.AWAITING_NEW_VERSION) {

                return PPProofActionType.REDIRECT_DASHBOARD; //special case for some actions that redirect to the dashboard

            } else if ((this.isProofOwner || this.isCoOwner)
                        && this.proofStatus === PPProofStatus.NEW
                        && this.proofType === PPProofType.BRIEF) {
                return PPProofActionType.REDIRECT_BRIEF_SETUP; //send user to brief set up page

            } else if (this.isFinalApproverStage && this.isFinalApprover && this.proofStatus === PPProofStatus.FINAL_APPROVING) {

                return PPProofActionType.COMPLETE_MANDATORY_MESSAGE; //send complete for FA is changes are > 0 or not

            } else if (this.isApprover && this.proof.todoCount > 0 && this.proofStatus === PPProofStatus.FINAL_APPROVING) {

                return PPProofActionType.COMPLETE_APPROVER; //send complete if they are a approver, uses special endpoint

            } else if (this.isApprover && this.proof.todoCount === 0 && this.proofStatus === PPProofStatus.FINAL_APPROVING) {

                return PPProofActionType.UNLOCK_PROOF_COMPLETE; //unlock and send complete if the approver locks proof but doesn't mark changes

            } else if ((this.isProofOwner || this.isCoOwner) && this.proofStatus === PPProofStatus.CHANGES_REQUESTED) {

                return PPProofActionType.TODOS_COMPLETE; //send complete if the approver locks proof but doesn't mark changes
            }
            else if ((this.isRecipient || this.isEditor)
                && this.proofStatus === PPProofStatus.PROOFING
                && this.proofType === PPProofType.BRIEF) {
                return PPProofActionType.TODOS_COMPLETE; //send complete if recipient/editor tries to upload proof for a brief

            } else if (this.isEditor && this.proofStatus === PPProofStatus.CHANGES_REQUESTED) {

                return PPProofActionType.TODOS_COMPLETE; //send complete for the editor

            } else if ( !this.isMandatory ){

                return PPProofActionType.COMPLETE_NON_MANDATORY; //send complete for non-mandatory proofers

            } else {

                return PPProofActionType.COMPLETE_MANDATORY; //send complete for mandatory proofers

            }
        }

        getActionHandler (actionType, proofId, isNextProof) {

            console.log("actionType", actionType);

            switch (actionType) {
                case PPProofActionType.COMPLETE_MANDATORY_MESSAGE:
                    return () => {
                        return this.updateProofMessage()
                            .then(() => this.finishedProofing())
                            .then(() => !isNextProof && !(this.isProofOwner || this.isCoOwner) && this.redirectTo("dashboard"));
                    };
                    break;
                case PPProofActionType.COMPLETE_APPROVER:
                    return () => {
                        return this.updateProofMessage()
                            .then(() => this.finishedProofingApprover())
                            .then(() => !isNextProof && !(this.isProofOwner || this.isCoOwner) && this.redirectTo("dashboard"));
                    };
                    break;
                case PPProofActionType.UNLOCK_PROOF_COMPLETE:
                    return () => {
                        return this.finishedProofing()
                            .then(() => !isNextProof && this.redirectTo("dashboard"));
                    };
                    break;
                case PPProofActionType.COMPLETE_MANDATORY:
                    return () => {
                        return this.finishedProofing()
                            .then(() => !isNextProof && this.redirectTo("dashboard"));
                    };
                    break;
                case PPProofActionType.COMPLETE_NON_MANDATORY:
                    return () => {
                        return this.finishedProofingNonMandatory()
                            .then(() => !isNextProof && !(this.isProofOwner || this.isCoOwner) && this.redirectTo("dashboard"));
                    };
                    break;
                case PPProofActionType.TODOS_COMPLETE:
                    return () => {
                        return this.changesComplete()
                            .then(() => this.redirectTo("dashboard", true, proofId, 'upload-new-version'));
                    };
                    break;
                case PPProofActionType.REDIRECT_DASHBOARD:
                    return () => {
                        return this.redirectTo("dashboard", true, proofId, 'upload-new-version');
                    };
                    break;
                case PPProofActionType.REDIRECT_BRIEF_SETUP:
                    return () => {
                        window.__pageproof_quark__.proofSetup.requestProofSetup(
                            {
                                parameters: {
                                    proofType: 'brief',
                                    updateProof: {
                                        id: this.proof.id,
                                    },
                                    backUrl: $location.url(),
                                },
                                initialize: {
                                    proof: {
                                        id: this.proof.id,
                                        name: this.proof.title,
                                        tags: this.proof.tags,
                                        reference: this.proof.reference || undefined,
                                        integrationReferences: this.proof.integrationReferences,
                                        dueDate: this.proof.dueDate,
                                        isPublic: this.proof.isPublic,
                                        canBePublic: this.proof.canBePublic,
                                        file: {
                                            type: 'local',
                                            id: this.proof.file.id,
                                            name: this.proof.file.name,
                                            size: this.proof.file.size,
                                            status: 'ok'
                                        }
                                    }
                                }
                            },
                            function () {
                                $location.url('/create');
                            }
                        );
                        return Promise.resolve(false);
                    };
                    break;
                default:
                    throw new Error(`Unknown action type "${actionType}"`);
                    break;
            }
        }



        /**
        * [Public] the action method get called after the proofer clicks the 'action button'
        *
        * @see {PPProofActions.getActionType}
        * @deprecated
        */

        action(){
            return this.getActionHandler(this.getActionType())();
        }

    }

    return PPProofActions;
});
