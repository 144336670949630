/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.config(function ($provide) {
    $provide.decorator('$q', function ($delegate, $rootScope) {
        let q = $delegate,
            defer = q.defer;

        let $q = promise,
            $defer = deferred;

        // Pull through the existing helper functions on the `$q` service
        Object.keys(q).forEach(key => $q[key] = q[key]);

        // Replace `$q.defer` with the new deferred
        $q.defer = $defer;

        // Add a `$q.track` helper function that tracks pending
        $q.track = track;

        function promise (fn) {
            let promise = q(fn);

            promise.$$pending = true;
            promise.finally(
                () => promise.$$pending = false
            );

            return promise;
        }

        function deferred () {
            let that = defer();
            let promise = that.promise;

            promise.$$pending = true;
            promise.then(
                () => promise.$$pending = false,
                () => promise.$$pending = false
            );

            return that;
        }

        function track (promise) {
            promise.$$pending = true;
            promise.finally(
                () => promise.$$pending = false
            );
            return promise;
        }

        return $q;
    });
});
