/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class OnHoldController {
    duration = 1000;

    /**
     * Updates the controllers configuration.
     *
     * @param {Object} options
     */
    configure (options) {
        if (options) {
            if ('duration' in options) {
                this.duration = options.duration;
            }
        }
    }
}

function OnHoldDirective ($parse, $timeout, eventService) {
    return {
        controller: OnHoldController,
        link (scope, element, attrs, ctrl) {
            let timeout,
                mouseDownEvent,
                cancelEvent,
                callback,
                done;

            callback = $parse(attrs.onHold);

            done = (event) => {
                if (angular.isFunction(callback)) {
                    callback(scope, { $event: event });
                }
            };

            mouseDownEvent = eventService.on(element, 'mousedown touchstart', (event) => {
                if ($(event.target).is('textarea, input')) {
                    return;
                }

                timeout = $timeout(() => done(event), ctrl.duration);

                cancelEvent = eventService.on(element, 'mouseup mouseleave touchend', () => {
                    $timeout.cancel(timeout);
                });
            });

            scope.$on('$destroy', () => {
                mouseDownEvent();

                if (angular.isFunction(cancelEvent)) {
                    cancelEvent();
                }

                if (timeout) {
                    $timeout.cancel(timeout);
                }
            });
        }
    };
}

function OnHoldOptionsDirective () {
    return {
        require: 'onHold',
        link (scope, element, attrs, ctrl) {
            scope.$watch(() => attrs.onHoldOptions, (options) => {
                if (options) {
                    ctrl.configure(scope.$eval(options));
                }
            });
        }
    };
}

app
    .directive('onHold', OnHoldDirective)
    .directive('onHoldOptions', OnHoldOptionsDirective);

