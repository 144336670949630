/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
//constructor function
function Tag(app) {
    this.application = app;
    this.api = app.api;
    this.enc = app.enc;
    this.genericError = this.application.GetConfigSetting("UserErrorMessage_GenericUserMessage");
}

Tag.prototype.Create = function(relatedId, tagStr, callBackFunc) {
    if(empty(relatedId)){
        this._showErrorDialogue("no relatedId set (Tag Create method)");
        return "";
    }
    if(empty(tagStr)){
        this._showErrorDialogue("no tagStr set (Tag Create method)");
        return "";
    }

    //var tagsArray = tagStr.split(" ");
    //if(tagsArray.length > 0){
    //    var i = 0;
    //    while(i < tagsArray.length){
    //        if(empty(tagsArray[i])) break;
    //        this.api.CallAPI("tags", "create", {RelatedId:relatedId, Tag:stripTags(tagsArray[i])}, function(){});
    //        i++;
    //    }
    //}

    this.api.CallAPI('tags', 'create', {
        RelatedId: relatedId,
        Tag: tagStr.replace(/\s+/g, ',')
    }, function () {
        callBackFunc('true');
    });
};

Tag.prototype.Update = function(relatedId, tagStr, callBackFunc) {
    if(empty(relatedId)){
        this._showErrorDialogue("no relatedId set (Tag Update method)");
        return "";
    }
    var self = this;
    var tagsArray = tagStr.split(" ");
    var deleteTags = [];
    var returnedTags = [];

    if(tagsArray.length > 0){
        this.LoadForProof(relatedId, function(tags){

            if(tags.length > 0){
                var i = 0;
                while(i < tags.length){
                    if(empty(tags[i]["Value"])) break;
                    if(!inArray(tagsArray, tags[i]["Value"])){
                        deleteTags.push(tags[i]["Value"]);
                        deleteTags.push(tags[i]["Value"]);
                    }else{
                        returnedTags.push(tags[i]["Value"]);
                    }
                    i++;
                }
            }

            var e = 0;

            while(e < tagsArray.length){
                if(empty(tagsArray[e])) break;
                if(!inArray(returnedTags, tagsArray[e])){
                    self.api.CallAPI("tags", "create", {RelatedId:relatedId, Tag:stripTags(tagsArray[e])}, function(){
                        var returnData = jsonDecode(this.responseText);
                        console.log(returnData);
                    });
                }
                e++;
            }
            
            if(deleteTags.length > 0){
                var d = 0;
                while(d < deleteTags.length){
                    if(empty(deleteTags[d])) break;
                    self.api.CallAPI("tags", "delete", {RelatedId:relatedId, Tag:stripTags(deleteTags[d])}, function(){
                        var returnData = jsonDecode(this.responseText);
                        console.log(returnData);
                    });
                    d++;
                }
                
            }
            callBackFunc("true");
        });
    }
};


Tag.prototype._showErrorDialogue = function(techInfo) {
    this.application.showErrorBox(this.genericError, techInfo);
}; 

Tag.prototype.LoadForProof = function(relatedId, callBackFunc) {
    if(empty(relatedId)){
        this._showErrorDialogue("no relatedId set (Tag LoadForProof method)");
        return "";
    }
    this.api.CallAPI("tags", "getByProofId", relatedId, function(){  
        var returnData = jsonDecode(this.responseText);
        callBackFunc(returnData);
    });
};

Tag.prototype.Search = function(query, callBackFunc) {
    if(empty(query)){
        this._showErrorDialogue("no query set (Tag Search method)");
        return "";
    }
    this.api.CallAPI("tags", "search", query, function(){  
        var returnData = jsonDecode(this.responseText);
        callBackFunc(returnData);
    });
};

Tag.prototype.SearchUser = function(query, callBackFunc) {
    if(empty(query)){
        this._showErrorDialogue("no query set (Tag SearchUser method)");
        return "";
    }
    this.api.CallAPI("tags", "searchUser", query, function(){  
        var returnData = jsonDecode(this.responseText);
        callBackFunc(returnData);
    });
};

Tag.prototype.Delete = function(relatedId, tag, callBackFunc) {
    this.api.CallAPI("tags", "delete", {RelatedId:relatedId, Tag:tag}, callBackFunc);
};

