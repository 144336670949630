/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

ppxControllers.controller('proofSetup', ['$scope', '$rootScope', '$routeParams', '$translate', '$location', '$sce', 'dateService', 'UserService', 'appService', 'DataService', 'NavigationService', 'SegmentIo', '$api', 'importService', '$filter', 'PPProofType', 'sdk', 'proofRepositoryService', function($scope, $rootScope, $routeParams, $translate, $location, $sce, dateService, UserService, appService, DataService, NavigationService, SegmentIo, $api, importService, $filter, PPProofType, sdk, proofRepositoryService) {
    $scope.proofSetupCtrl = $scope;

    $scope.application = UserService; //make the application class avaliable to this scope
    $scope.data = DataService; //make the dataservice avaliable to this scope

    $scope.proofData = $scope.data.getData(); //get file and proof data passed from dashboard on drop
    $scope.briefData = $scope.proofData;

    $scope.appService = appService;

    var Enum = window.__pageproof_quark__.sdk.Enum;

    $scope.workFlow = new WorkFlow($scope.application); //setup the workflow object
    $scope.proof = new Proof($scope.application);
    $scope.team = new Team($scope.application, 'workflows');
    $scope.users = new Users($scope.application); //setup the workflow object
    $scope.tag = new Tag($scope.application);

    var userData = $scope.application.GetUserData();

    $scope.proofNameMaxLength = 255;

    $scope.email = userData["emailAddress"];
    $scope.userId = userData["userId"];
    $scope.referenceEnabled = userData.features.proofReference;

    $scope.proofNameNote = $scope.data.expectsEmail() ? 'email.placeholder' : null;

    $scope.headerTranslationString = $scope.data.expectsEmail() ? "proof.setup.header.email" : "proof.setup.header";
    $scope.name = $scope.proofData.name;
    $scope.tags = $scope.proofData.tag;
    $scope.file = $scope.proofData.$file;
    this.reference = $scope.proofData.reference;

    $scope.protoProofs = $scope.proofData.multipleFile.protoProofs;
    $scope.savedWorkflowId = $scope.proofData.multipleFile.workflowId;
    $scope.returning = $scope.proofData.multipleFile.returning;
    $scope.coOwners = $scope.proofData.multipleFile.coOwners;
    $scope.groupName = $scope.proofData.multipleFile.groupName;
    $scope.groupId = $scope.proofData.groupId; // if addign a or many proofs to a collection

    $scope.downloadOption = $scope.proofData.downloadOption;

    $scope.hoursToDueDate = 24;

    $scope.isApproverAnInviter = $scope.application.storage.getJSON('proof_approver_is_inviter_preferred_' + $scope.userId) || false;

    if ($scope.data.hasImportData()) {
        var data = $scope.data.getImportData();
        $scope.name = data.name;
        $scope.importType = data.type;
    }

    $scope.loadGroupData = function() {
        sdk.proofs.groups.load($scope.groupId)
            .then(function (data) {
                $scope.isGroupEmpty = data.isEmpty;
                $scope.groupName = data.name;
            });
    };

    if ($scope.groupId) {
        $scope.loadGroupData();
    }

    $scope.populateDueDateAndMessageFields = function() {
        var proofId = this.proofId;
        var nearestDate = function(date, duration) {//sets date interval (eg. minutes, hours) to nearest provided duration, eg. duration: 15 mins, time 10:41 will be set to 10:45, 12:05 will be set to 12:00
            return moment(Math.round((+date) / (+duration)) * (+duration));
        };
        sdk.preferences.get([
            Enum.Preference.DEFAULT_MESSAGE_TO_REVIEWERS,
            Enum.Preference.DEFAULT_DUE_DATE,
            Enum.Preference.DEFAULT_DUE_TIME
        ])
            .then(function(preferences) {
                if (preferences && !proofId) {
                    var messageToReviewers = preferences[0];
                    var dueDate_ = preferences[1];
                    var dueDate = dueDate_.getValueOrDefault();
                    var dueTime = preferences[2];
                    dueTime = dueTime.getValueOrDefault();

                    // Only set the default message to reviewers if the user hasn't yet entered
                    // a custom message. We assume that they are writing a custom message for a reason.
                    if (!$scope.message) {
                        $scope.message = messageToReviewers.value;
                    }
                    $scope.defaultMessage = messageToReviewers.value;

                    $scope.showSuggestedDueDate = (dueDate === dueDate_.defaultValue);

                    var now = new Date();
                    var dayToday = now.getDay();
                    var setUpDate = parseInt(dueDate, 10);
                    var roundedDate = nearestDate(now, moment.duration(15, 'minutes'));
                    if (dueDate) {
                        if (dayToday === 6) { //the day of week is Saturday
                            setUpDate = setUpDate + 1;
                        }
                        if (dayToday < 6 && (dayToday + setUpDate) > 5) { //excludes weekend from due date calculation, eg. setup Friday, dueDate is 2 days, triggered Tuesday, weekend is skipped
                            setUpDate = setUpDate + 2;
                        }
                        var due = roundedDate.add(setUpDate, 'days').format('h:mma, Do MMMM YYYY');
                        $scope.dueDateDisplay = due;
                    }
                    if (dueTime) {
                        var date = moment(dueTime, 'h:mm a');
                        roundedDate = roundedDate.hours(date.hours()).minutes(date.minutes()).format('h:mma, Do MMMM YYYY');
                        $scope.dueDateDisplay = roundedDate;
                    }
                    $scope.getHoursToDueDate($scope.dueDateDisplay);
                }
            });
    };
    $scope.timeDiff = 'in 3 days';

    $scope.message = $scope.proofData.message;
    $scope.dueDateDisplay = $scope.proofData.dueDateDisplay || $scope.timeDiff;
    $scope.dueDate = $scope.proofData.dueDate;
    $scope.touchedDueDate = false;
    $scope.showSuggestedDueDate = true;
    $scope.continue_pressed = false;
    $scope.doesWFEditNeed = true;
    $scope.proceedButtonMessage = 'button.next';
    $scope.proceedButtonStyle = 'outline';

    var OPTION_CREATE_NEW_WORKFLOW = {
        name: '',
        value: ''
    };

    OPTION_CREATE_NEW_WORKFLOW.name = 'proof.setup.create-new-workflow';

    var OPTION_USE_CURRENT_WORKFLOW = {
        name: '',
        value: 'temp' // is replaced when the current workflow id is loaded
    };

    OPTION_USE_CURRENT_WORKFLOW.name = 'proof.setup.use-current-workflow';

    $scope.existingWorkflows = [OPTION_CREATE_NEW_WORKFLOW];

    $scope.workFlowId = "";
    $scope.proofId = "";
    $scope.previousVersion = "";
    $scope.firstVersion = "";

    $scope.validateProofName = function(){
        var element = document.getElementById('name');
        $scope.name = element.value;
        $scope.validate();
        if(!$scope.name) return "";

        var nameLength = $scope.name.length;
        if (nameLength > $scope.proofNameMaxLength) {
            $scope.name = $scope.name.substr(0, $scope.proofNameMaxLength);
        }

        $scope.proofNameNote = nameLength >= $scope.proofNameMaxLength ?
            'shortened-proof-name' : null;
    };

    $('header h1').text('');

    $scope.loadProofData = function(){

        if(isset($scope.proofData["proofId"])){
            $scope.proofId = $scope.proofData["proofId"];
        }
        if(isset($scope.proofData["workflowId"])){
            $scope.workFlowId = $scope.proofData["workflowId"];
        }
    };

    $scope.searchWFObject = function(workflowId){
        if($scope.existingWorkflows.length > 0){
            var c = 0;
            while(c < $scope.existingWorkflows.length){
                if($scope.existingWorkflows[c]["workflowId"] == workflowId){
                    return true;
                }
                c++;
            }
        }
        return false;
    };

    function updateProceedButton() {
        if($scope.doesWFEditNeed) {
            $scope.proceedButtonMessage = "button.next";
            $scope.proceedButtonStyle = "outline";
        } else if (DataService.expectsEmail()) {
            $scope.proceedButtonStyle = "main";
            $scope.proceedButtonMessage = "button.get-email-address";
        } else {
            $scope.proceedButtonStyle = "main";
            $scope.proceedButtonMessage = "button.send";
        }
    }

    $scope.loadExistingWorkFlows = function(callbackFunc){

        $scope.workFlow.GetAllWorkFlows("owned", function(){
            var returnData = jsonDecode(this.responseText);

            if(returnData.length > 0){
                // $scope.existingWorkflows = [];
                var i = 0;
                var css = "";
                var sel = "";
                while(i < returnData.length){
                    if(isset(returnData[i]["Title"]) && isset(returnData[i]["WorkflowId"])){
                        $scope.existingWorkflows.push({
                            name:returnData[i]["Title"],
                            value:returnData[i]["WorkflowId"]});

                    }
                    i++;
                }
            }
            if(isFunc(callbackFunc)) callbackFunc();
        });
    };

    $scope.onItemSelected = function(id){
        $scope.selectedItem = id;
        $scope.changedWorkflowId = id;
        $scope.updateWFChangeRadioBtn(id);
    };

    $scope.onChange = function(date) {
        var dateTimeValue = moment(date).format('h:mma, Do MMMM YYYY');
        $scope.dueDateDisplay = dateTimeValue;
        $scope.showSuggestedDueDate = false;
        $scope.getHoursToDueDate($scope.dueDateDisplay);
    };

    $scope.getHoursToDueDate = function(date) {
        if (!(/^\s+$/.test(date))) {
            var dateNow = moment.utc(new Date());
            var dateChosen = dateService.parse(date);
            var duration = moment.duration(dateChosen.diff(dateNow));
            var minutes = Math.floor(duration.asMinutes());
            if (minutes < 60) {
                $scope.hoursToDueDate = 0;
            }
            if (minutes >= 60 && minutes < 180) {
                $scope.hoursToDueDate = 1;
            }
            if (minutes >= 180 && minutes < 1440) {
                $scope.hoursToDueDate = 3;
            }
            if (minutes >= 1440 && minutes < 2880) {
                $scope.hoursToDueDate = 24;
            }
            if (minutes >= 2880 && minutes < 4320) {
                $scope.hoursToDueDate = 48;
            }
            if (minutes >= 4320) {
                $scope.hoursToDueDate = 72;
            }
        }
    }

    $scope.updateReminderOptions = function(event) {
        $scope.getHoursToDueDate(event.target.value);
    };

    $scope.updateWFChangeRadioBtn = function(id, isPreviousVersion) {
        if (!isPreviousVersion) {
            isPreviousVersion = false;
        }
        if (isPreviousVersion || id !== "") {
            $scope.showWFChangeRadioBtn = true;
            $scope.doesWFEditNeed = (typeof $scope.getWorkflowEditPreferred() !== 'undefined') ? $scope.getWorkflowEditPreferred() : false;
        } else {
            $scope.doesWFEditNeed = true;
            $scope.showWFChangeRadioBtn = false;
        }
        updateProceedButton();
        $scope.setProofProps();
    };

    $scope.loadSharedWorkFlows = function(callbackFunc){
        $scope.workFlow.GetAllWorkFlows("shared", function(){
            var returnDataShared = jsonDecode(this.responseText);
            if(returnDataShared.length > 0){
                var i = 0;
                while(i < returnDataShared.length){
                    if(isset(returnDataShared[i]["Title"]) && isset(returnDataShared[i]["WorkflowId"])){
                        if($scope.searchWFObject(returnDataShared[i]["WorkflowId"]) == false) {
                            $scope.existingWorkflows.push({
                                name: returnDataShared[i]["Title"],
                                value: returnDataShared[i]["WorkflowId"]
                            });
                        }

                    }
                    i++;
                }
            }
            if(isFunc(callbackFunc)) callbackFunc();
            if(!$scope.$$phase) $scope.$apply();
        });
    };

    $scope.back = NavigationService.wrapBack(function () {
            $rootScope.goHomepage();
    });

    $scope.changedWorkflowId = "";

    $scope.selectWorkflow = function(workflowId){
        $scope.changedWorkflowId = workflowId;
        if(isset(workflowId)){
            $scope.showCreateNewOptionInDrop = true;
        }else{
            $scope.showCreateNewOptionInDrop = false;
        }
        if(!$scope.$$phase) $scope.$apply();
    };

    $scope.canDownload = false;
    $scope.disableDownloadToggler = false;

    $scope.makeDownloadable = function(bool) {
        $scope.canDownload = bool;
        $scope.storedDownloadPreferred(bool);
        $scope.storedDownloadOption(bool);
        $scope.setProofProps();
    };

    $scope.storedDownloadPreferred = function(bool){
        $scope.application.storage.setJSON('download_preferred_' + $scope.userId, bool);
    };

    $scope.storeWorkflowEditPreferred = function(bool){
        $scope.doesWFEditNeed = bool;
        $scope.application.storage.setJSON('workflow_edit_preferred_' + $scope.userId, bool);
        updateProceedButton()
        $scope.setProofProps();
    };

    $scope.getWorkflowEditPreferred = function() {
        return $scope.application.storage.getJSON('workflow_edit_preferred_' + $scope.userId);
    };

    $scope.getFileId = function() {
        return ($scope.fileStatus === 'Error') ? $scope.proofData.fileId : $scope.fileId;
    }

    $scope.storedDownloadOption = function(bool){
        $scope.data.setDownloadOption(bool);
        var fileId = $scope.getFileId();
        if ($scope.proofId && fileId) {
            proofRepositoryService.toggleDownloadOption(fileId, bool);
        }
    };

    $scope.getDownloadPreferredOption = function(){

        console.log("$scope.fileStatus ", $scope.fileStatus );

        if( isset($scope.proofId) ){

            if( isset($scope.proofId) && $scope.fileStatus == "Error" ){
                $scope.handleDownloadOption();
            }else{
                console.log("download is : ", $scope.application.storage.getJSON('download_preferred_' + $scope.userId) );
                if( $scope.downloadOption ){
                    $scope.canDownload = true;
                }else{
                    $scope.canDownload = false;
                }
            }

            if (!$scope.$$phase) $scope.$apply();

        }else{
            $scope.handleDownloadOption();
        }
        $scope.setProofProps();
    };

    $scope.handleDownloadOption = function(){
        if( ( $scope.application.storage.getJSON('download_preferred_' + $scope.userId) === undefined ) || ( $scope.application.storage.getJSON('download_preferred_' + $scope.userId) === true ) ){
            $scope.canDownload = true;
            $scope.storedDownloadOption(true);
        }else{
            $scope.canDownload = false;
            $scope.storedDownloadOption(false);
        }
        if (!$scope.$$phase) $scope.$apply();
    };

    $scope.updateMessage = function(){
        var element = document.getElementById('message');
        $scope.message = element.value;
    };

    $scope.continue = function() {
        $scope.updateDueDate();

        if($scope.continue_pressed === true){
            return "";
        }

        if(empty($scope.dueDateDisplay)){
            showError("#due_date", "");
            return "";
        }

        if(empty($scope.name) && !$scope.data.expectsEmail()){
            showError("#name");
            return "";
        }

        if (empty($scope.name) && $scope.data.expectsEmail()) {
            $scope.name = ""; // We set this proof name to an empty string to increase compatability with existing systems.
        }

        $scope.continue_pressed = true;

        $scope.tags = $("#tags").val();

        $scope.data.addTag($scope.tags);

        $scope.application.showLoader();
        $scope.data.addMessage($scope.message);

        $scope.data.addName($scope.name);

        if ($scope.referenceEnabled) {
            $scope.data.addReference($scope.proofSetupCtrl.reference);
        }

        if($scope.application.IsLoggedIn()) {

            if(isset($scope.proofId) && $scope.isReRun == true){
                var updateObj = {
                    Title: stripTags($scope.name),
                    Tags: stripTags($scope.tags),
                    DueDate: stripTags($scope.dueDate),
                    Description: stripTags($scope.message)
                };

                $scope.proof.Update($scope.proofId, updateObj, function () {
                    if($scope.skipNextStep == true){
                        $scope.appService.showTranslatedLoaderMessage("loader-message.drawing-workflow");
                        $scope.redirectToPath();
                    }else{
                        $location.path("proof-one/");
                    }
                });

            }else if(isset($scope.proofId) && $scope.isReRun == false) {
                var updateObj = {
                    Title: stripTags($scope.name),
                    Tags: stripTags($scope.tags),
                    DueDate: stripTags($scope.dueDate),
                    Description: stripTags($scope.message)
                };

                if ($scope.changedWorkflowId != "") {

                    //if(isset($scope.workFlowId)) $scope.workFlow.Delete($scope.workFlowId); //unsets old WF if there is one. why?

                    $scope.duplicate(function () {

                        updateObj["WorkflowId"] = $scope.workFlowId;

                        $scope.proof.Update($scope.proofId, updateObj, function (returnData) {
                            if (isset(returnData["ProofId"]) && isset(returnData["UserId"])) {
                                $scope.proof.PremissionCheck(returnData["ProofId"], $scope.workFlowId, function(){
                                    $scope.appService.showTranslatedLoaderMessage("loader-message.drawing-workflow");
                                    $scope.redirectToPath();
                                    if (!$scope.$$phase) $scope.$apply();
                                });
                            }
                        });
                    });

                } else {
                    $scope.proof.Update($scope.proofId, updateObj, function (returnData) {
                        if (isset(returnData["ProofId"]) && isset(returnData["UserId"])) {
                            $location.path("proof-one/");
                            if (!$scope.$$phase) $scope.$apply();
                        }
                    });
                }
            }
        }else{

            if($scope.changedWorkflowId != ""){
                $scope.data.addChangedWorkflowId();
            }

            $location.path("login");
            if(!$scope.$$phase) $scope.$apply();
        }

    };

    $scope.duplicate = function(callBackFunc){
        $scope.workFlow.Duplicate($scope.changedWorkflowId, $scope.proofId, function(returnData){
            if(isset(returnData["WorkflowId"])){
                $scope.workFlowId = returnData["WorkflowId"];
                $scope.data.addWorkflowId($scope.workFlowId);
                if(isFunc(callBackFunc)) callBackFunc();
            }
        });
    };

    $scope.validate = function(){
        if (empty($scope.name) && !$scope.data.expectsEmail()){
            showError("#name");
        } else{
            showError("#name", "", "hide");
        }
    };

    $scope.showLoader = function(id){
        $("#spinner_" + id).show();
    };

    $scope.hideLoader = function(id){
        $("#spinner_" + id).hide();
    };

    $scope.setUserData = function(userId, email, priv, pub, token, sharedKey) {
        if (isset(userId) && isset(email) && isset(token) && isset(sharedKey)) {
            return $scope.application.SetUserData(userId, email, "", "", token, sharedKey);
        } else {
            $scope.application.logControllerError("Empty user data params: userId: " + userId + ", email: " + email);
        }
        return false;
    };

    $scope.redirectToPath = function(){
        if (!$scope.doesWFEditNeed) {
            $scope.proof.Start($scope.proofId, $scope.proofData["previousVersion"], function(){});
            if ($scope.data.hasImportData()) {
                $location.url('imported/' + $scope.data.getImportType().toLowerCase());
            }
        } else {
            $location.path("proof-workflow/" + $scope.workFlowId);
        }
    };

    $scope.showCreateNewOption = true;
    $scope.showReuseOption = false;

    $scope.showCreateNewOptionInDrop = false;
    $scope.showReuseOptionInDrop = false;

    $scope.selectedItem = "";

    $scope.dropData = {};

    $scope.isReRun = DataService.isReupload();
    $scope.proofData.isReupload = $scope.isReRun;

    $scope.skipNextStep = false;

    $scope.supportedFileTypes = "";

    $scope.beforeInit = function(callBackFunc){

        if ($routeParams.proofId) {
            $scope.proofId = $routeParams.proofId;

            $scope.data.addProofId($scope.proofId);

            $scope.data.addProofRestart(true);

            $scope.fromProofId = true;

            $scope.proof.HasStarted($scope.proofId, function (hasStarted) {

                if (hasStarted) {

                    $location.path("proof-already-started/" + $scope.proofId);

                } else {
                    if(isFunc(callBackFunc)) callBackFunc();
                }
            });
        }
    };

    $scope.fromProofId = false;
    $scope.fileStatus = "";

    $scope.loadProof = function(callbackfunc) {
        sdk.proofs.load($scope.proofId)
            .then(function(data) {
                $scope.briefData.name = data.name;
                $scope.briefData.dueDate = data.dueDate;
                if(isFunc(callbackfunc)) callbackfunc();
            });
    };

    $scope.loadExistingProof = function (callbackfunc) {
        $scope.proof.Load($scope.proofId, function () {
            var returnData = jsonDecode(this.responseText);

            console.log("proof load returnData: ", returnData);

            $scope.fileStatus = returnData["FileStatus"];
            $scope.downloadOption = !!returnData["DownloadOriginal"];
            $scope.fileId = returnData['FileId'];

            if ($scope.fileStatus !== 'Error') {
                $scope.disableDownloadToggler = !returnData["OriginalStore"];
            }

            if (isset(returnData["Title"])) {
                $scope.name = stripTags(returnData["Title"]);
            }

            if (isset(returnData["Description"])) {
                $scope.message = stripTags(returnData["Description"]);
            }

            if (isset(returnData['Reference'])) {
                $scope.proofSetupCtrl.reference = returnData['Reference'];
            }

            if (isset(returnData["WorkflowId"])) {
                $scope.data.addWorkflowId(returnData["WorkflowId"]);
                $scope.workFlowId = returnData["WorkflowId"];
                $scope.skipNextStep = true;
            }

            if ($routeParams.proofId) {

                if (isset(returnData["DueDate"])) {

                    $scope.dueDate = returnData["DueDate"];

                    var date = new DateTime();

                    $scope.dueDateDisplay = date.getLocalTimeFromUTCstr(returnData["DueDate"]);

                    $scope.updateDueDate();

                }

            }

            if(isFunc(callbackfunc)) callbackfunc();

            $scope.tag.LoadForProof($scope.proofId, function (returnData) {
                $scope.tags = "";
                $("#tags").empty();

                if (returnData.length > 0) {
                    var tagsArray = [];
                    var i = 0;
                    while (i < returnData.length) {
                        tagsArray.push(stripTags(returnData[i]["Value"]));
                        i++;
                    }

                    $scope.tags = tagsArray.join(" ");

                    if (!$scope.$$phase) $scope.$apply();

                    if (isset($scope.tags)) deferUi('input', $("#tags"), '{{tags}}', window.bby_ui.forms.initAutoTags);
                }

            });

        });
    };

    function ensureWorkflowOrder(existingWorkflows) {
        var workflows = $filter('orderBy')(existingWorkflows, 'name');
        workflows.splice(workflows.indexOf(OPTION_CREATE_NEW_WORKFLOW), 1);
        workflows.unshift(OPTION_CREATE_NEW_WORKFLOW);
        if (workflows.indexOf(OPTION_USE_CURRENT_WORKFLOW) !== -1) {
            workflows.splice(workflows.indexOf(OPTION_USE_CURRENT_WORKFLOW), 1);
            workflows.unshift(OPTION_USE_CURRENT_WORKFLOW);
        }
        return workflows;
    }

    $scope.init = function(){

        if($scope.application.IsLoggedIn()) {
            //if a co-owner/owner setting up a workflow , which was not done earlier by owner
            $scope.beforeInit(function(){
                $scope.loadExistingProof(function(){
                    $scope.setProofProps();
                    $scope.getDownloadPreferredOption();
                });
            });

            $scope.loadProofData();

            $scope.setProofProps();
            if ($scope.dueDateDisplay) {
                $scope.getHoursToDueDate($scope.dueDateDisplay);
            }
            console.log("p irr:", $scope.proofId, $scope.isReRun);
            //first time start of proof upload
            if(!$scope.isReRun) $scope.getDownloadPreferredOption();
            //if file get error and user is uploading  new file
            if($scope.isReRun){
                $scope.loadExistingProof(function(){
                    $scope.getDownloadPreferredOption();
                });
            } else {

                $scope.loadExistingWorkFlows(function () {
                    $scope.loadSharedWorkFlows(function () {
                        $scope.existingWorkflows = ensureWorkflowOrder($scope.existingWorkflows);
                    });
                    if (isset($scope.proofData["previousVersion"])) { //flag to denote proof is version. keeping as PREVIOUS PROOF ID JUST INCASE ITS NEEDED IN THE FUTURE

                        $scope.proof.GetOriginalVersion($scope.proofData["previousVersion"], function (returnData) {

                            $scope.previousVersion = $scope.proofData["previousVersion"];

                            if (returnData.length > 0 && isset(returnData[0]["ProofId"])) { //find the original proof id if there is one
                                $scope.firstVersion = returnData[0]["ProofId"];
                            } else {
                                $scope.firstVersion = $scope.previousVersion; //if not, then use the passed prevoius proof id
                            }

                            console.log("$scope.previousVersion:" + $scope.previousVersion);

                            console.log("$scope.firstVersion:" + $scope.firstVersion);

                            $scope.proof.Load($scope.previousVersion, function () {
                                var returnData = jsonDecode(this.responseText);

                                console.log("proof load returnData: ", returnData);

                                if (isset(returnData["Title"])) {
                                    $scope.name = stripTags(returnData["Title"]);
                                }

                                if (isset(returnData["Reference"])) {
                                    $scope.proofSetupCtrl.reference = returnData['Reference'];
                                }

                                if (isset(returnData["Description"])) {
                                    $scope.message = returnData['Description'];
                                }
                                $scope.previousVersionMessage = returnData["Description"];

                                if (isset(returnData["WorkflowId"])) {

                                    OPTION_USE_CURRENT_WORKFLOW.value = returnData["WorkflowId"];
                                    $scope.existingWorkflows.push(OPTION_USE_CURRENT_WORKFLOW);
                                    $scope.existingWorkflows = ensureWorkflowOrder($scope.existingWorkflows);

                                    $scope.selectedItem = returnData["WorkflowId"];

                                    console.log(" $scope.selectedItem: " + $scope.selectedItem);

                                    $scope.showCreateNewOption = false; //turn off option for default
                                    $scope.showCreateNewOptionInDrop = true; //but turn it on in the list

                                    $scope.showReuseOption = true; //show reuse option
                                    $scope.showReuseOptionInDrop = false; //hide it in the drop down

                                    if (!$scope.$$phase) $scope.$apply();

                                    $scope.changedWorkflowId = returnData["WorkflowId"];
                                    $scope.workFlowId = returnData["WorkflowId"];
                                } else if (returnData['Type'] === PPProofType.BRIEF) {
                                    OPTION_USE_CURRENT_WORKFLOW.value = 'BRIEF_TEMP_WORKFLOW_ID';
                                    $scope.existingWorkflows.push(OPTION_USE_CURRENT_WORKFLOW);
                                    $scope.existingWorkflows = ensureWorkflowOrder($scope.existingWorkflows);
                                    $scope.selectedItem = 'BRIEF_TEMP_WORKFLOW_ID';
                                    $scope.changedWorkflowId = 'BRIEF_TEMP_WORKFLOW_ID';
                                    $scope.finalApprovalEmailId = returnData['UserId'];
                                }

                                $scope.tag.LoadForProof($scope.previousVersion, function (returnData) {
                                    $scope.tags = "";
                                    $("#tags").empty();

                                    if (returnData.length > 0) {
                                        var tagsArray = [];
                                        var i = 0;
                                        while (i < returnData.length) {
                                            tagsArray.push(stripTags(returnData[i]["Value"]));
                                            i++;
                                        }
                                        $scope.tags = tagsArray.join(" ");

                                        if (!$scope.$$phase) $scope.$apply();
                                    }

                                    if (isset($scope.tags)) deferUi('input', $("#tags"), '{{tags}}', window.bby_ui.forms.initAutoTags);

                                });

                            });
                        });
                        $scope.updateWFChangeRadioBtn("", !! ($scope.proofData["workflowId"] || $scope.proofData["previousVersion"])); //workflowId, isPreviousVersion
                    }
                });
            }

        } else {
            $scope.getDownloadPreferredOption();
        }


        $scope.application.hideLoader();
        $scope.touchedDueDate = false;
        $scope.populateDueDateAndMessageFields();
        $scope.updateDueDate();
        $('#due').keypress(function(e){
            if (e.keyCode == 13) return false;
        });
        $('#name').keypress(function(e){
            if (e.keyCode == 13) return false;
        });
        if(!isset($scope.proofData["previousVersion"]) && $scope.isReRun == false && $scope.fromProofId == false) {
            if($scope.data.getImportType() === 'url') {
                window.bby_ui.forms.initTag();
            } else {
                deferUi('input', $("#tags"), '{{tags}}', window.bby_ui.forms.initAutoTags);
            }
        }
    };

    $scope.updateDueDate = function () {
        var display = $scope.data.getData().dueDateDisplay;
        var emptyString = /^\s+$/.test($('#due').val());
        if (emptyString) {
            $('#due').val("");
        }
        var dueDateDisplay = $('#due').val() || display || $scope.timeDiff;

        if ($scope.touchedDueDate) {
            $scope.showSuggestedDueDate = false;
        }
        var date = dateService.parse(dueDateDisplay);

        var remainder = 0;
        // Rounds the default due date ('3 days from now') to the nearest quarter hour. Rounds down if <= 7 minutes past the quarter hour, otherwise rounds up.
        if (!$scope.touchedDueDate) {
            if (date.minute() % 15 <= 7) {
                remainder = (date.minute() % 15) * -1;
            } else {
                remainder = 15 - date.minute() % 15;
            }
        }

        $scope.dueDate = moment.utc(date).add('minutes', remainder).format();
        $scope.dueDateDisplay = date.add('minutes', remainder).format('h:mma, Do MMMM YYYY');
        $scope.data.addDueDate($scope.dueDate);
        $scope.data.addDueDateDisplay($scope.dueDateDisplay);
        $('#due').val($scope.dueDateDisplay);
        $scope.touchedDueDate = true;
        if (!$scope.$$phase) $scope.$apply();
        $scope.getHoursToDueDate(dueDateDisplay);
    };

    $scope.setBriefProps = function() {
        if ($scope.briefData.isReupload) {
            $scope.proofId = $scope.proofData["proofId"];
            $scope.loadProof(function() {
                $scope.briefProps = {
                    brief: $scope.briefData,
                };
            })
        } else {
            $scope.briefProps = {
                brief: $scope.briefData,
            };
        }
    };

    $scope.setProofProps = function() {
        $scope.proofProps = {
            downloadSwitch: {
                value: $scope.canDownload,
                onChange: $scope.makeDownloadable,
                message: ($scope.canDownload ? 'proof.setup.can-be-downloaded' : 'proof.setup.cant-be-downloaded'),
                disabled: $scope.disableDownloadToggler,
            },
            workflowEditSwitch: {
                value: $scope.doesWFEditNeed,
                onChange: $scope.storeWorkflowEditPreferred,
                message: ($scope.doesWFEditNeed ? 'proof.setup.toggle.make-changes' : 'proof.setup.toggle.no-changes'),
            }
        };
    };

    $scope.setBriefProps();

    $scope.setAsInviter = function(bool) {
        $scope.isApproverAnInviter = bool;
        $scope.application.storage.setJSON('proof_approver_is_inviter_preferred_' + $scope.userId, bool);
    }

    this.multipleProofProps = {
        workflows: $scope.existingWorkflows || [],
        protoProofs: $scope.protoProofs || [],
        workflowId: $scope.savedWorkflowId,
        returning: $scope.returning,
        coOwners: $scope.coOwners,
        get groupName() {
            return $scope.groupName;
        },
        groupId: $scope.groupId,
        get isApproverAnInviter() {
            return $scope.isApproverAnInviter;
        },
        setAsInviter: $scope.setAsInviter,
        referenceEnabled: $scope.referenceEnabled,
        get timeLeft() {
            return $scope.hoursToDueDate;
        },
    };

    this.dateTimePickerIconButtonProps = {
        onChange: $scope.onChange,
    };

    this.selectWorkflowProps = {
        get workflows() {
            if ($scope.existingWorkflows) {
                return $scope.existingWorkflows;
            }
        },
        get selectedWorkflow() {
            if ($scope.selectedItem) {
                return $scope.selectedItem;
            } else {
                return '';
            }
        },
        onItemSelected: $scope.onItemSelected,
    };

    this.popUpReminderProps = {
        passReminderValue: function (reminders) {
            if (reminders.length) {
                $scope.data.addReminders(reminders);
            } else {
                $scope.data.addReminders(null);
            }
        },
        isOwner: true,
        proof: {
            id: $scope.proofData.proofId || null,
            reminders: $scope.proofData.proofId && $scope.data.getReminders(),
        },
        inCreateProofProccess: true,
        get timeLeft() {
            return $scope.hoursToDueDate;
        },
    };

    this.messageRadioBtnProps = {
        selected: false,
        onChange: function (bool) {
            $scope.proofSetupCtrl.messageRadioBtnProps.selected = bool;
            $scope.message = bool && $scope.defaultMessage ? $scope.defaultMessage : $scope.previousVersionMessage;
        },
    };

    $scope.init();
}]);
