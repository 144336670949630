/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const COMMENT_SORT_FN = sortObjectArrayFn('createdAt', true);

app.factory('PPProofPage', function (PPModel, PPProofComment, $q) {
    class PPProofPage extends PPModel {
        /**
         * The proof the page belongs to.
         *
         * @type {String}
         */
        proofId = null;

        /**
         * The pages comments.
         *
         * @type {PPProofComment[]}
         */
        comments = [];

        /**
         * The pages filtered comments.
         *
         * @type {PPProofComment[]}
         */
        filteredComments = [];

        /**
         * The pages todos.
         *
         * @type {PPProofComment[]}
         */
        todos = [];

        /**
         * The page number.
         *
         * @type {Number}
         */
        pageNumber = 0;

        /**
         * The image previews for the page.
         *
         * @type {Object}
         */
        image = { low: null, high: null, thumb: null };

        /**
         * Adds a/or many comments to the comments array.
         *
         * @param {...PPProofComment} comments
         */
        addComments (...comments) {
            comments.forEach(comment => this.comments.push(comment));
        }

        /**
         * marks a parent comment for toDo
         * @param commentId
         * @returns {boolean}
         */
        markCommentById(commentId) {
            for (let index = 0, length = this.comments.length; index < length; index++) {
                if (this.comments[index].id === commentId) {
                    this.comments[index].isTodo = true;
                    return true;
                }
            }
            return false;
        }

        /**
         * Finds a commentObj in page based on creationToken
         * @param creationToken
         * @returns {*}
         */
        findCommentByCreationToken(creationToken) {
            return $q((resolve) => {
                let commentObj = null;
                this.comments.some((comment) => {
                    if (comment.creationToken === creationToken) {
                        commentObj = comment;
                        return true;
                    } else {
                        comment.replies.some((reply) => {
                            if (reply.creationToken === creationToken) {
                                commentObj = reply;
                                return true;
                            }
                        });
                    }
                });
                resolve(commentObj);
            });
        }

        /**
         * Adds a/or many todos to the todos array.
         *
         * @param {...PPProofComment} todos
         */
        addTodos (...todos) {
            todos.forEach(todo => this.todos.push(todo));
        }

        /**
         * Sort the comments by their createdAt date.
         *
         * @see {sortObjectArrayFn}
         */
        sortComments () {
            this.comments.sort(COMMENT_SORT_FN);
        }

        /**
         * Update the comment data of a page.
         *
         * @param {Object} recentCommentsData
         * @returns {PPProofComment[]}
         */
        updateFromRecentCommentsData (recentCommentsData) {
            let updatedComments = [];

            const identifierIndex = {};
            const indexComment = (comment) => {
                identifierIndex[comment.id] = comment;
                identifierIndex[comment.encryptedComment] = comment;
            };
            this.comments.forEach((comment) => {
                indexComment(comment);
                comment.replies.forEach(indexComment);
            });

            const replies = [];

            recentCommentsData.Comments.forEach((commentData) => {
                let comment = identifierIndex[commentData.CommentId] || identifierIndex[commentData.EncryptedComment];
                if (comment) {
                    // The comment/reply already exists - simple... update the object with the new data
                    comment.updateFromProofCommentData(commentData);
                    updatedComments.push(comment);
                } else {
                    // The comment/reply is new (we don't have a reference to it locally yet). Construct a new object
                    // for it. If it's a reply, push it onto a different list for us to go over after - as the parent
                    // comment may not have an object yet. And if it's a comment, simply push it to the comments array.
                    comment = PPProofComment.from(commentData);
                    if (comment.parentId) {
                        replies.push(comment);
                    } else {
                        this.comments.push(comment);
                        // Index the new comment - so when we get round to handling the replies, we can quickly locate
                        // the parent comments the replies are associated with.
                        indexComment(comment);
                    }
                    updatedComments.push(comment);
                }
            });

            replies.forEach((comment) => {
                const parentComment = identifierIndex[comment.parentId];
                if (parentComment) {
                    parentComment.replies.push(comment);
                }
            });

            // Shuffle the comments into the correct order
            this.sortComments();

            // Return the comment objects which may require decryption
            return updatedComments;
        }
    }

    return PPProofPage;
});
