/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const ANIMATION_DURATION = 250;

class SlideRevealController {
    constructor ($element) {
        this.$$ = { $element };
        this.previousIsOpen = false;
    }

    updateIsOpen(isOpen) {
        if (!!isOpen === this.previousIsOpen) {
            return;
        }
        const element = this.$$.$element[0];
        const style = element.style;
        style.display = 'block';
        const height = element.scrollHeight + 'px';
        let immediateHeight = 0;
        let beforeHeight = 0;
        let afterHeight = 0;
        if (isOpen) {
            immediateHeight = beforeHeight = height;
            afterHeight = '';
        } else {
            immediateHeight = height;
            beforeHeight = afterHeight = 0;
        }
        style.height = immediateHeight;
        clearTimeout(this._timeout);
        this._timeout = setTimeout(() => {
            style.height = beforeHeight;
            clearTimeout(this._timeout);
            this._timeout = setTimeout(() => {
                style.height = afterHeight;
                if (afterHeight === 0) {
                    style.display = 'none';
                }
            }, ANIMATION_DURATION);
        });
        this.previousIsOpen = !!isOpen;
    }
}

function SlideRevealDirective (directiveHelper) {
    return {
        require: ['slideReveal'],
        template: `
            <div class="slide-reveal" style="display: none; height: 0" ng-transclude></div>
        `,
        controller: SlideRevealController,
        controllerAs: 'slideRevealCtrl',
        transclude: true,
        replace: true,

        link (scope, element, attr, [slideRevealCtrl]) {
            directiveHelper.oneWayBinding(
                scope,
                attr,
                'isOpen',
                slideRevealCtrl,
                'isOpen',
                false,
                value => slideRevealCtrl.updateIsOpen(value)
            );
        }
    };
}

app
    .directive('slideReveal', SlideRevealDirective);