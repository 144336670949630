/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

const AUDIO_PLAYER_VOLUME_KEY = 'pageproof.app.user.audio-player-volume';
class AudioWaveformController {
    constructor($scope, $element, eventService) {
        this.$$ = {$scope, $element, eventService};

        this.container = null;
        this.canvas = null;
        this.element = null;
        this.file = null;
        this.transclude = null;
        this.waveform = null;
        // this.zoom = 10;
        this.isReady = false;
        this.isLoading = true;
        this.isPlaying = false;
        this.loadingProgress = 0;
        this.time = 0;
        this.duration = 0;
        this.initialVolume = 1;

        this.waveformTooltip = null;

        this.options = {
            waveColor: '#7f8c8d',
            progressColor: '#138b3b',
            cursorColor: '#728182',
            cursorWidth: 2,
        };
    }

     /**
     * Whether the video is at the start.
     *
     * @type {boolean}
     */
     get canSkipBackward() {
        if (this.waveform) {
            return this.waveform.backend.startPosition > 0;
        }
    }

     /**
     * Whether the user has reached the end of the video.
     *
     * @type {boolean}
     */
     get canSkipForward() {
        if (this.waveform) {
        return this.waveform.backend.startPosition < this.duration;
        }
    }

     /**
     * Whether the audio is shorter than 6.
     * Used to check if we allow skipping
     * @type {boolean}
     */
     get isShortAudio() {
        return this.duration < 6;
    }

    createWaveform() {
        this.waveform = WaveSurfer.create({
            container: this.element[0],
            waveColor: this.options.waveColor,
            progressColor: this.options.progressColor,
            cursorWidth: this.options.cursorWidth,
            fillParent: true,
            hideScrollbar: true,
            height: 600,
        });

        this.isReady = false;
        this.waveform.on('ready', this.$$.eventService.$$wrap(() => {
            this.loadingProgress = 100;
            this.isReady = true;
            this.isLoading = false;
            this.duration = this.waveform.getDuration();
            this.didLoadWaveform();
            this.setWaveformTooltipElement();
            this.waveform.setVolume(this.initialVolume);
        }));
        this.waveform.on('loading', this.$$.eventService.$$wrap((progress) => {
            this.loadingProgress = progress * .96; // Limit percentage to 96% (for large files it goes to 100% pretty quickly) (and 1996 was the best year of all time)
            this.didUpdateLoadingProgress();
        }));
        this.waveform.on('play', this.$$.eventService.$$wrap(() => this.didStartPlaying()));
        this.waveform.on('pause', this.$$.eventService.$$wrap(() => this.didPause()));
        this.waveform.on('audioprocess', () => this.didUpdateTime(this.waveform.getCurrentTime()));
        this.waveform.on('seek', (seek) => this.didUpdateSeek(seek));

        this.$$.eventService.on(window, 'resize', throttle(() => {
            this.didChangeBoundingRect();
        }, 100));
    }

    didUpdateLoadingProgress() {
        // console.debug(this.loadingProgress);
    }

    loadFile() {
        if (typeof this.file === 'string') {
            this.waveform.load(this.file);
        } else if (this.file instanceof Blob) {
            this.waveform.loadBlob(this.file);
        }
    }

    injectTransclude() {
        const $container = angular.element('<div />');
        $container.css({position: 'absolute', top: 0, left: 0, height: '100%', zIndex: 2});
        $container.css({width: '100%'});
        this.container = $container;

        const $wave = this.element.find('> wave');
        this.canvas = $wave.find('> canvas');

        this.transclude(($transclude) => {
            $container.append($transclude);
            $wave.append($container);
        });
    }

    checkBootstrap() {
        if (
            window.WaveSurfer &&
            this.file &&
            this.transclude
        ) {
            if (!this.waveform) {
                this.createWaveform();
            }
            if (!this.$$injectTransclude) {
                this.$$injectTransclude = true;
                this.injectTransclude();
            }
            this.loadFile();
        }
    }

    updateContainerWidth() {
        // this.container.width(this.canvas.width());
    }

    updateTooltipPosition() {
        if (this.waveformTooltip && this.waveform) {
            this.waveformTooltip.style.left = `${this.waveform.getCurrentTime() / this.waveform.getDuration() * 100}%`;
        }
    }

    didLoadWaveform() {
        this.didChangeBoundingRect();
        this.didUpdateZoom();
    }

    didLoadLibrary() {
        this.checkBootstrap();
    }

    didReceiveFile(file) {
        this.file = file;
        this.checkBootstrap();
    }

    didReceiveTransclude(transclude) {
        this.transclude = transclude;
        this.checkBootstrap();
    }

    didReceiveZoom(zoom) {
        // let previousZoom = this.zoom;
        // let normalizedZoom = Math.round(Math.max(10, Math.min(100, zoom)));
        //
        // if (previousZoom !== normalizedZoom) {
        //     this.zoom = normalizedZoom;
        //
        //     if (this.isReady) {
        //         this.waveform.zoom(this.zoom);
        //         this.didUpdateZoom();
        //     }
        // }
    }

    didUpdateZoom() {
        this.updateContainerWidth();
    }

    didChangeBoundingRect() {
        if (this.waveform) {
            this.waveform.zoom();
            this.waveform.toggleScroll();
            this.waveform.drawer.updateProgress(this.waveform.getCurrentTime() / this.waveform.getDuration());
            this.updateTooltipPosition();
        }
    }

    didStartPlaying() {
        this.isPlaying = true;
    }

    didPause() {
        this.isPlaying = false;
    }

    setWaveformTooltipElement() {
        this.waveformTooltip = this.rootElement.querySelector('[data-waveform-tooltip]');
    }

    didUpdateTime(time) {
        var previousTime = this.time;
        this.time = Math.floor(time);

        if (previousTime != this.time && !this.$$.$scope.$$phase) {
            this.$$.$scope.$apply();
        }
        if (!this.waveformTooltip) {
            this.setWaveformTooltipElement();
        }
        this.updateTooltipPosition();
    }

    didUpdateSeek(seek) {
        this.didUpdateTime(this.duration * seek);
    }

    destroy() {
        this.waveform.destroy();
    }

    // media controls

    scrub(seconds) {
        this.waveform.seekTo(seconds / this.duration);
    }

    skipForward(seconds) {
        if (!this.canSkipForward) {
            return;
        }
        this.waveform.skipForward(seconds);
    }
    
    skipBackward(seconds) {
        if (!this.canSkipBackward) {
            return;
        }
        this.waveform.skipBackward(seconds);
    }

    toggle() {
        this.waveform.playPause();
    }

    pause() {
        if (this.waveform.isPlaying()) {
            this.waveform.pause();
            return true;
        } else {
            return false;
        }
    }

    play() {
        if (!this.waveform.isPlaying()) {
            this.waveform.play();
            return true;
        } else {
            return false;
        }
    }

    setVolume(volume) {
        this.waveform.setVolume(volume);
    }
}

function AudioWaveformDirective(directiveHelper, utilService) {
    let waveformLibrary = null;

    return {
        require: ['audioWaveform'],
        controller: AudioWaveformController,
        controllerAs: 'audioWaveformCtrl',
        template: `
            <div class="app__audio-waveform">
                <div ng-if="audioWaveformCtrl.isReady" class="page__proof__audio-container--waveform-tooltip" data-waveform-tooltip>
                    {{ audioWaveformCtrl.time | videoTime }}
                </div>
                <loader ng-if="!audioWaveformCtrl.isReady" progress="audioWaveformCtrl.loadingProgress">
                    Hang on a sec, bringing in audio
                </loader>
                <div ng-style="{opacity: audioWaveformCtrl.isReady ? 1 : 0}" style="transition: opacity .3s ease-in-out"></div>
            </div>
        `,
        replace: true,
        transclude: true,

        link(scope, element, attrs, [audioWaveformCtrl], transclude) {
            audioWaveformCtrl.element = element.find('> div:last');
            audioWaveformCtrl.rootElement = element[0];

            if (!waveformLibrary) {
                waveformLibrary = utilService.loadScript('dist/lib/wavesurfer.js');
            }
            waveformLibrary.then(() => audioWaveformCtrl.didLoadLibrary());

            directiveHelper.oneWayBinding(scope, attrs, 'file', audioWaveformCtrl, 'file', false,
                audioWaveformCtrl.didReceiveFile.bind(audioWaveformCtrl));

            if ('zoom' in attrs) {
                directiveHelper.oneWayBinding(scope, attrs, 'zoom', audioWaveformCtrl, 'zoom', false,
                    audioWaveformCtrl.didReceiveZoom.bind(audioWaveformCtrl));
            }

            if ('initialVolume' in attrs) {
                directiveHelper.oneWayBinding(scope, attrs, 'initialVolume', audioWaveformCtrl, 'initialVolume');
            }

            if ('bind' in attrs) {
                directiveHelper.controllerBinding(scope, attrs, 'bind', audioWaveformCtrl);
            }

            // Is this required?
            // directiveHelper.callback(scope, attrs, 'whenBind')();

            audioWaveformCtrl.didReceiveTransclude(transclude);

            scope.$on('$destroy', () => audioWaveformCtrl.destroy());
        }
    };
}

app.directive('audioWaveform', AudioWaveformDirective);