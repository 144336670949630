/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

class DomainAdminProfileController {
  constructor(
    $scope,
    $timeout,
    $userRepository,
    $rootScope,
    UserService,
    userService,
    backendService,
    SegmentIo,
    sdk
  ) {
    this.$$ = {
      $scope,
      $timeout,
      $userRepository,
      $rootScope,
      UserService,
      userService,
      backendService,
      SegmentIo,
      sdk,
    };

    this.$$.application = this.$$.UserService;
    this.loggedInUser = userService.getUser();
    this.users = new Users(this.$$.UserService);
    this.open = false;
    this.userId = '';
    this.userEnabled = true;
    this.onStatusChanged = null;

    this.props = {
      user: {},
      self: false,
      lastLoggedIn: '',
      isPendingInvitedUser: false,
      onAddAvatar: this.onAddAvatar,
      onResetAvatar: this.onResetAvatar,
      onUpdateName: this.updateName,
      onToggleUserEnabled: this.toggleUserEnabled,
      onToggleDomainAdmin: this.toggleDomainAdmin,
      onRemoveUserFromTeam: this.removeUserFromTeam,
      onResendInvitation: this.onResendInvitation,
      onCancelInvitation: this.onCancelInvitation,
    };

    this.initEventListeners();
  }

  reset = () => {
    this.userId = '';
    this.onStatusChanged = null;
    this.props.user = {};
  }

  load = () => {
    this.open = true;
    const id = this.userId;
    this.$$.backendService
      .fetch('domain.profile.load', {UserId: id})
      .data()
      .then(user => {
        const enabled = this.props.user.enabled;
        this.props.user = user;
        this.props.user.enabled = enabled;
        this.props.self = this.loggedInUser.id === user.UserId;
        this.props.lastLoggedIn = this.loggedIn(user.LastLogin);
      });
  }

  initEventListeners = () => {
    this.$$.$scope.$on('DomainAdminProfileController::load', (event, args) => {
      this.reset();
      if (args.userId) {
        this.userId = args.userId;
        this.props.user.enabled = !!args.userEnabled;
        this.onStatusChanged = args.onStatusChanged;
        this.props.isPendingInvitedUser = args.isPendingInvitedUser;
  
        if (args.isPendingInvitedUser) {
          this.open = true;
          this.props.user = args.user;
        } else {
          this.load();
        }
      }
    });
  }

  onAddAvatar = file => this.$$.sdk.admin.team.users.uploadAvatar(this.userId, file)
      .then(() => this.invokeOnStatusChanged());

  onResetAvatar = () => this.$$.sdk.admin.team.users.deleteAvatar(this.userId)
      .then(() => this.invokeOnStatusChanged());

  toggleUserEnabled = (userEnabled) => {
    this.props.user.enabled = userEnabled;
    this.users.Domain_SetUserEnabled(this.props.user.Email, userEnabled, (err) => {
      if (!err) {
        this.invokeOnStatusChanged();
      } else {
        this.props.user.enabled = !userEnabled;
        console.error('Domain_SetUserEnabled failed to respond with a success.');
      }
    });
  }

  onResendInvitation = email => this.$$.sdk.admin.team.users.invite(email);

  onCancelInvitation = invitationId => this.$$.sdk.admin.team.users.revokeInvitation({ invitationId })
      .then(() => {
        this.invokeOnStatusChanged();
        this.close();
      });

  close = () => {
    this.open = false;
    // After the animation has finished, reset the scope
    this.$$.$timeout(this.reset, 500);
  }

  invokeOnStatusChanged = () => {
    if (typeof this.onStatusChanged === 'function') {
      this.onStatusChanged(this.userId, this.userEnabled);
    }
  }

  updateName = (event) => {
    if (this.props.user) {
      const userId = this.userId;
      this.$$.backendService
        .fetch('domain.profile.update', {
          Name: event.target.value,
          UserId: userId,
        })
        .data()
        .then(() => {
          this.$$.$userRepository.update(userId);
        });
    }
  }

  toggleDomainAdmin = (isSet) => {
    const userId = this.userId;
    this.props.user.IsAdmin = isSet;
    this.$$.backendService
      .fetch('domain.profile.admin.set', {
        Email: this.props.user.Email,
        IsSet: isSet,
        UserId: userId,
      })
      .data()
      .then(() => {
        this.$$.$rootScope.$broadcast('DomainAdmin_Dashboard::setAdmin');
        this.$$.SegmentIo.track(55, {
          'userId': userId,
          'isAdmin': isSet,
        });
      });
  }

  removeUserFromTeam = (user) => {
    return this.$$.sdk.admin()
      .then(admin => admin.team.users.remove({ id: user.UserId }))
      .then(data => {
        if (!data.error) {
          this.invokeOnStatusChanged();
          this.close();
        }
      });
  }

  loggedIn(time) {
    if (time === "0001-01-01T00:00:00"){
      return "";
    } else {
      return moment.utc(time).locale(i18n.getLocale()).fromNow();
    }
  }
}

app.controller(
  'DomainAdminProfileController',
  DomainAdminProfileController
);
