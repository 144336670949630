/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

class PrintService {
    constructor($rootScope, $controller, browserService, modalService, appService, SegmentIo, sdk) {
        this.$$ = {$rootScope, $controller, browserService, modalService, appService, SegmentIo, sdk};
    }
    
    subscribers = [];
    jobs = [];

    printProof(proofId, options, versions) {
        const {version, content, layout, groupBy, pageSize, isAnonymized} = options;
        let {includeAllPages} = options;
        let proofIds = [];
        switch(version) {
            case 'all-versions':
                proofIds = versions.map(v => v.id);
                break;
            default:
                proofIds = [options.version];
                break;
        }

        if (content === 'proof') {
            includeAllPages = true;
        }

        
        this.$$.SegmentIo.track(29, {
            'proof id(s)': proofIds.join(','),
            'system': 'current',
            pageSize: pageSize.toLowerCase(),
            comments: content,
            layout,
            groupBy,
            includeAllPages,
            isAnonymized,
        });

        return new Promise(resolve => {
            this.$$.sdk.printing.create({proofIds, content, layout, groupBy, pageSize, includeAllPages, isAnonymized}).then(({data}) => {
                const job = {name: data.filename.name, id: data.token, };
                this.notify([...this.jobs, job]);
                resolve(data);
            });
        });
    } 

    getPrintProofOptions(proofId) {
        return this.$$.sdk.printing.options(proofId);
    }

    subscribe(id, fn) {
        this.subscribers.push({id, fn});
    }

    unsubscribe(id) {
        this.subscribers = this.subscribers.filter(subs => subs.id != id);
    }

    complete(id) {
        const newJobs = this.jobs.filter(j => j.id != id);
        this.notify(newJobs);
    }

    notify(newJobs) {
        this.jobs = newJobs;
        this.subscribers.forEach(sub => sub.fn(this.jobs))
    }
}

app.service('printService', PrintService);
