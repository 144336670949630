/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/**
 * Domain Admin - Integrations
 *
 * @author Anna Blackwell <anna@pageproof.com>
 */
class IntegrationsController {
    constructor(
        seoService,
        backendService,
        appService,
        SegmentIo
    ) {
        this.$$ = {
            seoService,
            backendService,
            appService,
            SegmentIo
        };
        this.$$.seoService.set({
            title: 'domain-admin.menu.integrations',
            pageTitle: 'domain-admin.menu.integrations',
            translateTitle: true,
            translatePageTitle: true,
        });

        const props = this.props = {
            updateProps: this.updateProps,
            updateIntegration: this.updateIntegration,
            connectToZapier: this.connectToZapier,
            integrations: ['Slack', 'MSTeams', 'Basecamp', 'Zapier'].map((name) => {
                return {
                    type: name,
                    connectString: '',
                    authString: '',
                    enabled: false,
                };
            }),
        };

        this.load();
    }

    updateProps = (name, value, key) => {
        for (let i = 0; i < this.props.integrations.length; i++) {
            if (this.props.integrations[i].type === name) {
                this.props.integrations[i][key] = value;
            }
        }
    }

    load = () => {
        this.$$.SegmentIo.track(47);
        this.$$.appService.showTranslatedLoaderMessage('loader-message.loading-please-wait');

        this.$$
            .backendService
            .fetch('domain.integrations.load')
            .data()
            .then((data) => {
                for (let i = 0; i < data.length; i++) {
                     for (let j = 0; j < this.props.integrations.length; j++) {
                         if (data[i].Type === this.props.integrations[j].type.toLowerCase()) {
                             this.props.integrations[j].enabled = (data[i].Enabled === 1);
                             this.props.integrations[j].connectString = data[i].ConnectString;
                             this.props.integrations[j].authString = data[i].AuthString;
                         }
                     }
                }
                this.$$.appService.hideLoader();
            })
            .catch((error) => {
                console.error('An error has occurred: ', error);
            });
    }

    updateIntegration = (type) => {
        let enabled;
        let connectString;
        for (let i = 0; i < this.props.integrations.length; i++) {
            if (this.props.integrations[i].type === type) {
                enabled = this.props.integrations[i].enabled;
                connectString = this.props.integrations[i].connectString || null;
            }
        }
        this.$$.SegmentIo.track(48, {
            integration: type,
            enabled: enabled,
        });
        this.$$
            .backendService
            .fetch('domain.integrations.update', {
                type: type,
                enabled: enabled ? 1 : 0,
                authString: null,
                connectString: connectString,
            })
            .data()
            .catch((error) => {
                console.error('An error has occurred: ', error);
            });
    }

    connectToZapier = () => {
      window.open(env('zapier_url'), '_blank');
    }

}

app.controller(
    'IntegrationsController',
    IntegrationsController
);
