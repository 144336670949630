/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
ppxControllers
    .controller('KeyboardShortcutsController', function ($scope, shortcutService) {
        $scope.shortcutService = shortcutService;

        $scope.changeShortcut = function (name) {
            $scope.staging = name;
            shortcutService.custom(name, function () {
                $scope.staging = null;
            });
        };
    });
