/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class TeamInvitationController {
    constructor($routeParams, $location, $rootScope, userService, UserService, sdk) {
        this.invitationId = $routeParams.invitationId;
        this.$location = $location;
        this.$rootScope = $rootScope;
        this.UserService = UserService;
        this.userService = userService;
        this.sdk = sdk;
        this.props = {
            loading: true,
            error: null,
            team: null,
            onAccept: this.accept.bind(this),
            onDecline: this.decline.bind(this),
        };
        this.handleError = this.handleError.bind(this);
        this.init();
    }

    handleError(err) {
        switch (err.response && err.response.data && err.response.data.ResponseStatus) {
            case 'ERROR_NOT_FOUND': {
                this.props.error = `team-invitation.error.not-found`;
                break;
            }
            default: {
                this.props.error = `team-invitation.error.generic`;
                break;
            }
        }
        this.props.loading = false;
    }

    init() {
        this.sdk.teams.invitations.info(this.invitationId)
            .then((info) => {
                this.props.loading = false;
                this.props.team = info;
            }, this.handleError);
    }

    accept() {
        this.props.loading = true;
        this.sdk.teams.invitations.accept(this.invitationId)
            .then(() => {
                window.__pageproof_setLogo(this.props.team.logo, 'user');
                this.UserService.UpdateUserData({
                  brandingUrl: this.props.team.logo,
                  teamId: this.props.team.teamId,
                });
                this.userService.populateUser()
                    .catch(() => {})
                    .then(() => {
                        // intentionally not using $location here, so the app loads the user data for permissions on the dashboard. simply redirecting using $location causes the old permissions to be used.
                        location.href = '/dashboard';
                    });
            }, this.handleError);
    }

    decline() {
        this.props.loading = true;
        this.sdk.teams.invitations.decline(this.invitationId)
            .then(() => {
                this.$location.url('/dashboard');
            }, this.handleError);
    }
}

app.controller('TeamInvitationController', TeamInvitationController);
