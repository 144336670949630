/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

'use strict';

angular
    .module('ppxControllers')
    .controller('HeaderController',
        function ($scope, $rootScope, $location, navigationService, UserService, userService, browserService, storageService, Activity, seoService) {
            $scope.showMobileMenu = false;
            $scope.showStartProof = false;
            $scope.isProofOnMobile = false;
            $scope.userService = userService;
            $scope.activityService = Activity;
            $scope.storage = storageService('pageproof.app.notifications.' + $scope.userService.$$userId + '.');
            $scope.activityData = $scope.activityService.getData();
            $scope.isMobile = browserService.is('mobile');

            angular.forEach(['playVideo', 'scrollFeatures', 'getStarted'], function (name) {
                $scope[name] = function () {
                    if ($location.path() === '/') {
                        $rootScope.$emit('marketing.' + name);
                    } else {
                        var search = {};
                        search[name] = 1;

                        $location.path('/').search(search);
                    }
                };
            });

            $scope.toggleMobileMenu = function () {
                $scope.showMobileMenu = ! $scope.showMobileMenu;
            };

            $rootScope.$on('$locationChangeSuccess', function () {
                $scope.showMobileMenu = false;
            });

            $rootScope.$on('marketing.toggleStartProof', function (event, show) {
                $scope.showStartProof = show;
            });

            $rootScope.$on('headerVersions', function (event, data) {
                $scope.isProofOnMobile = data.enable;
                $scope.versionProps = data.enable && data.versionProps;
                $scope.toggleProofInfo = data.toggleProofInfo;
            });

            $rootScope.$on('setCollectionMenuProps', function (event, data) {
                $scope.collectionMenuProps = data;
            });

            $rootScope.$on('setProofScreenMobileMenuProps', function (event, data) {
                $scope.proofScreenMobileMenuProps = data;
            });

            var locationListener = $rootScope.$on('$locationChangeSuccess', function () {
                $scope.promptExtensionProps.currentLocation = $location.absUrl();
            });

            var computeBrowserSupport = function () {
              var isSupportedBrowser = (browserService.is('chrome')
                || (browserService.is('firefox') && parseFloat(browserService.version) >= 47));
              return (!browserService.is('mobile') && isSupportedBrowser);
            };

            $scope.isNotificationsSnoozed = $scope.storage.json('snoozed');

            $scope.promptExtensionProps = {
                supportedBrowser: computeBrowserSupport(),
                currentLocation: $location.absUrl(),
                cancelListener: locationListener,
            }

            $scope.getShowGiftMenu =  function() {
                return $scope.userService.$$user && $scope.userService.$$user.isActivated && !$scope.isProofOnMobile && $location.url() !== '/create';
            };

            $scope.notificationTrayProps = {
                onPassValue: function(option) {
                    if (option === 'snooze') {
                        if (!$scope.storage.json('snoozed')) {
                            $scope.storage.json('snoozed', true);
                            $scope.isNotificationsSnoozed = true;
                        } else {
                            $scope.storage.remove('snoozed');
                            $scope.isNotificationsSnoozed = false;
                        }
                    } else {
                        $scope.activityService.toggleActivity();
                    }
                },
                get isSnoozed() {
                    return $scope.isNotificationsSnoozed;
                },
                onSnoozeToggle: function() {
                    $scope.activityService.toggleActivity();
                },
                get notificationsCount() {
                    return $scope.activityData.count || 0;
                },
            }
        }
    );
