/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const Static = window.Static;
delete window.Static;

const DOMAIN = 'com.pageproof.app';
const FILE_COUNT_KEY = DOMAIN + '.file-count';
const THROTTLE_KEY = DOMAIN + '.throttle';

const cache = {};

app.factory('staticService', ($q, $rootScope, eventService) => {
    class StaticService {
        origin;
        commander;

        constructor(url) {
            this.url = url;
            this.commander = $q.defer();
        }

        register() {
            if (cache[this.url]) {
                this.origin = cache[this.url];
            }
            if (!this.origin) {
                this.origin = $q.when(Static.register(this.url));
                cache[this.url] = this.origin;
            }
            return this.origin;
        }

        command(frame) {
            this.commander.resolve(this.ready().then(origin => origin.command(frame)));
            return this.commander.promise;
        }

        ready(callback) {
            return this.register().then(callback);
        }

        upload(files) {
            return this.ready(origin => {
                return origin.clear()
                    .then(() => origin.batch(files))
                    .then(event => origin.setting(FILE_COUNT_KEY, event.data.count));
            });
        }

        clear() {
            return this.ready(origin => origin.clear());
        }

        destroy() {
            const p = this.ready(origin => origin.destroy());
            delete cache[this.url];
            return p;
        }

        snapshot() {
            return this.commander.promise.then(commander => commander.snapshot());
        }

        scroll(top, left) {
            return this.commander.promise.then(commander => commander.scroll(top, left));
        }

        throttle(name, speed) {
            return this.ready(origin => (
                Promise.resolve(
                    Promise.all([
                        typeof name !== 'undefined' && origin.setting(THROTTLE_KEY, name),
                        typeof speed !== 'undefined' && origin.throttle(speed),
                    ])
                ).then(() => (
                    Promise.all([
                        origin.setting(THROTTLE_KEY),
                        origin.throttle(),
                    ])
                ))
            ));
        }

        getPageInfo() {
            return this.commander.promise.then(commander => commander.getPageInfo());
        }

        stopPage() {
            return this.commander.promise.then(commander => commander.runPromise('stop-page'));
        }

        isComplete() {
            return this.ready(origin => {
                return origin.setting(FILE_COUNT_KEY)
                    .then(count => origin.keys().then(keys => keys.length === count));
            });
        }

        entry() {
            return this.ready(origin => {
                return origin.keys()
                    .then((keys) => {
                        const topLevelHtmlFiles = keys
                            .filter(name => (name.indexOf('.htm') !== -1))  // have .html or .htm in the filename
                            .filter(name => (name.lastIndexOf('/') === 0)); // and only have one "/" in the path (meaning they're at the top level)
                        if (topLevelHtmlFiles.length === 1) {
                            return topLevelHtmlFiles[0];
                        }
                        return '/';
                    });
            });
        }

        logs(callback) {
            let off;
            this.ready(origin => {
                off = origin.logs(data => {
                    const cb = callback.bind(null, data);
                    $rootScope.$apply(cb);
                });
            });
            return () => off && off();
        }

        onContentResize(callback) {
            let off;
            this.commander.promise.then(commander => {
                off = commander.onContentResize((...args) => {
                    const cb = callback.bind(null, ...args);
                    $rootScope.$apply(cb);
                });
            });
            return () => off();
        }
    }

    return url => {
        const origin = new StaticService(url);
        origin.register();
        return origin;
    };
});