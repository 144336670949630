/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class OAuthAuthorizeController extends BaseController {
    constructor($routeParams) {
        super();
        this.$$import(this.$$dependencies([
            'backendService',
            '$timeout',
        ]));
        this.props = {
            loading: false,
            authorizing: false,
            waiting: true,
            details: null,
            onAuthorize: () => this.authorize(),
        };
        this.oauthParams = {
            client_id: $routeParams.client_id,
            scope: $routeParams.scope,
            redirect_uri: $routeParams.redirect_uri,
            state: $routeParams.state,
            response_type: $routeParams.response_type,
        };
        this.load();
    }

    load() {
        this.props.loading = true;
        this.$$.backendService
            .fetch('oauth.client', this.oauthParams)
            .data().then(response => {
                this.props.loading = false;
                this.props.details = response;
                this.$$.$timeout(() => {
                    this.props.waiting = false;
                }, 2000);
            });
    }

    authorize() {
        if (this.props.waiting) {
            return;
        }
        this.props.authorizing = true;
        this.$$.backendService
            .fetch('oauth.authorize', this.oauthParams)
            .data().then(response => {
                if (response) {
                    let url = this.oauthParams.redirect_uri;
                    url += (url.indexOf('?') === -1 ? '?' : '&');
                    url += 'code=' + response.authorization_code;
                    url += '&state=' + this.oauthParams.state;
                    location.href = url;
                } else {
                    alert('Uh oh, something went wrong...');
                }
        });
    }
}

app.controller('OAuthAuthorizeController', OAuthAuthorizeController);
