/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
angular
    .module('ppxGlobalControllers')
    .factory('dateService', function (moment) {
        function getRegexMatches (matches) {
            return Object
                .keys(matches)
                .slice(1)
                .filter(function (key) {
                    return isFinite(Number(key));
                })
                .map(function (key) {
                    return matches[key];
                });
        }

        return {
            /**
             * Parses a user input date string. By default, it uses a moment object, of the
             * current day, in the local time zone.
             *
             * @param {String} input
             * @param {moment} initial
             * @returns {moment}
             */
            parse: function (input, initial) {
                var str = input,
                    m = initial || moment(),
                    test = function (regex, cb) {
                        var matches, before = m.clone();
                        if (matches = regex.exec(str)) {
                            try {
                                var arr = getRegexMatches(matches).map(function (arg) {
                                    return isFinite(Number(arg)) ? Number(arg) : ((arg && arg.toLowerCase) ? arg.toLowerCase() : arg);
                                });

                                console.log('invoke test', str, arr);
                                cb.apply(null, arr);

                                str = str.replace(matches[0], '');
                            } catch (err) {
                                m = before; // revert the date as it was before
                                console.error('Error parsing date.', err);
                            }
                        }
                    };

                // tomorrow
                test(/(tomorrow|yesterday)/gi, function (match) {
                    m[match === 'tomorrow' ? 'add' : 'subtract'](1, 'day');
                });

                // next week
                test(/next (week|month|year)/gi, function (type) {
                    m.add(1, type + 's');
                });

                // in 2 weeks
                test(/in (\d+|a(n?)) (second|minute|hour|day|week|month|year)/gi, function (amount, _1, type) {
                    if ( ! isFinite(amount)) amount = 1; // a or an

                    m.add(amount, type + 's');
                });

                // in 2 business days / in 2bd (shorthand)
                test(/in (\d+) (business|working) day/gi, inXBusinessDays);
                test(/in (\d+)bd/gi, inXBusinessDays);

                function inXBusinessDays(days) {
                    days = parseInt(days, 10);

                    // source: https://github.com/moment/moment/issues/1947#issue-44081374
                    var increment = days / Math.abs(days);
                    m.add(Math.floor(Math.abs(days) / 5) * 7 * increment, 'days');
                    var remaining = days % 5;
                    while (remaining !== 0) {
                        m.add(increment, 'days');
                        if (m.isoWeekday() !== 6 && m.isoWeekday() !== 7) {
                            remaining -= increment;
                        }
                    }
                }

                test(/mid(day|night)/gi, function (type) {
                    m.hour(type === 'day' ? 12 : type === 'night' ? 24 : 0).minute(0).second(0);
                });

                test(/noon/gi, function () {
                  m.hour(12).minute(0).second(0);
                });

                var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
                    daysKeys = {},
                    daysRegex = days
                        .map(function (day) {
                            var key = day.substring(0, 3),
                                value = day.substring(3);
                            daysKeys[key] = day;
                            return key + '(' + value + ')?';
                        }).join('|');

                // last sunday
                test(new RegExp('(last|next|this)?\\s?(' + daysRegex + ')', 'gi'), function (type, day) {
                    day = daysKeys[day] || day;

                    // The day index (0 - 6, sun - sat)
                    var index = days.indexOf(day);
                    var today = new Date().getDay();
                    var diff = 0;
                    if (index <= today) {
                        diff = diff + 7;
                    }

                    m.day(type === 'this' ? index :
                          ! type ? index + diff :
                          type === 'next' ? index + 7 :
                          type === 'last' ? index - 7 :
                          undefined);
                });

                // 01:02:03 am
                test(/([0-9][0-9]?)[:.]?([0-9][0-9]?)?[:.]?([0-9][0-9]?)?\s?(am|pm)/gi, function (hour, minutes, seconds, type) {
                    if (hour === 12 && type === 'am') { // 12 am
                        hour -= 12;
                    } else if (hour >= 1 && hour <= 11 && type === 'pm') { // 1 - 11 pm
                        hour += 12;
                    }

                    // 1  am  -  0
                    // 2  am  -  0
                    // 12 am  -  -12
                    // 1  pm  -  +12
                    // 2  pm  -  +12
                    // 12 pm  -  0

                    /*

                     0 "Friday, September 11, 2015 12:45 AM"   -12
                     1 "Friday, September 11, 2015 1:45 AM"    0
                     2 "Friday, September 11, 2015 2:45 AM"    0
                     3 "Friday, September 11, 2015 3:45 AM"    0
                     4 "Friday, September 11, 2015 4:45 AM"    0
                     5 "Friday, September 11, 2015 5:45 AM"    0
                     6 "Friday, September 11, 2015 6:45 AM"    0
                     7 "Friday, September 11, 2015 7:45 AM"    0
                     8 "Friday, September 11, 2015 8:45 AM"    0
                     9 "Friday, September 11, 2015 9:45 AM"    0
                     10 "Friday, September 11, 2015 10:45 AM"  0
                     11 "Friday, September 11, 2015 11:45 AM"  0
                     12 "Friday, September 11, 2015 12:45 PM"  0
                     13 "Friday, September 11, 2015 1:45 PM"   +12
                     14 "Friday, September 11, 2015 2:45 PM"   +12
                     15 "Friday, September 11, 2015 3:45 PM"   +12
                     16 "Friday, September 11, 2015 4:45 PM"   +12
                     17 "Friday, September 11, 2015 5:45 PM"   +12
                     18 "Friday, September 11, 2015 6:45 PM"   +12
                     19 "Friday, September 11, 2015 7:45 PM"   +12
                     20 "Friday, September 11, 2015 8:45 PM"   +12
                     21 "Friday, September 11, 2015 9:45 PM"   +12
                     22 "Friday, September 11, 2015 10:45 PM"  +12
                     23 "Friday, September 11, 2015 11:45 PM"  +12

                     */

                    m.seconds(seconds || 0).minutes(minutes || 0).hour(hour);
                });

                var months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
                    monthsKeys = {},
                    monthsRegex = months
                        .map(function (month) {
                            var key = month.substring(0, 3),
                                value = month.substring(3);
                            monthsKeys[key] = month;
                            return key + '(' + value + ')?';
                        }).join('|');

                // january
                test(new RegExp('(' + monthsRegex + ')', 'gi'), function (month) {
                    m.month(monthsKeys[month] || month);
                });

                // day/month(/year) as numbers (e.g. 15/2/2017, 15-2-17, 15 2)
                test(/([0-9]([0-9])?)[\s\/\-]([0-9]([0-9])?)[\s\/\-]?([0-9]{2}([0-9]{2})?)?/gi, function (date, _1, month, _2, year) {
                    m.month(month - 1);
                    m.date(date);
                    if (year) {
                        if (year < 100) {
                            year += 2000;
                        }
                        m.year(year)
                    }
                });

                // 1st
                test(/([0-9]([0-9])?)\s?(st|nd|rd|th)?/gi, function (date) {
                    m.date(date);
                });

                // 2015
                test(/([0-9]{4})/gi, function (year) {
                    m.year(year);
                });

                return m;
            }
        };
    });
