/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.constant('PPProofDialogType', createEnumeration([
    'FINAL_APPROVER_LOCK',
    'APPROVER_LOCK',
    'OWNER_TODOS_REQUESTED',
    'EDITOR_TODOS_REQUESTED',
    'TODOS_WITH_EDITOR',
    'PROOF_APPROVED',
    'PROOF_LOCKED',
    'NEW_VERSION_REQUIRED',
    'ATTACHMENT_SIZE',
    'NEW_BRIEF',
    'BRIEF_SENT_TO_EDITOR',
    'RECIPIENT_UPLOAD_PROOF',
    'BRIEF_WITH_EDITOR',
    'PROOF_REQUIRED_FOR_BRIEF',
    'UNSAVED_COMMENT',
    'REQUEST_ACCESS',
    'MISSING_REVIEWER_OWNER',
    'THIRD_PARTY_COOKIES',
]));
