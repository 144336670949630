/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const AUDIO_PLAYER_VOLUME_KEY = 'pageproof.app.user.audio-player-volume';

class AudioProofController extends GenericProofController {
    audioDownloadProgress = -1;
    freeDrawingAvailable = false;

    constructor() {
        super();
        this.$$import(this.$$dependencies([
            'fileService',
            'temporaryStorageService',
            'PPProofControllerHook',
        ]));
        this.initAudioSupport();

        this.volumeProps = {
            volume: +window.localStorage.getItem(AUDIO_PLAYER_VOLUME_KEY) || 1,
            onChange: volume => this.updateVolume(volume),
        }

        this.bookmarkProps = {
            proofId: this.proofId,
            scrub: time => this.scrubToTime(time),
        }

    }

    initHooks() {
        super.initHooks();

        this.registerHook(
            this.$$.PPProofControllerHook.WHEN_SWITCH_VERSION,
            (id) => ('proof/audio/' + id)
        );
    }

    initWatchers() {
        super.initWatchers();

        this.beforeDestroy(this.$watch('isCommenting', (isCommenting) => {
            if (isCommenting && this.audioWaveform) {
                // If the user has initialised commenting, pause the video
                this.audioWaveform.pause();
            }
        }));

        this.beforeDestroy(this.$watch('showComments', () => {
            if (this.audioWaveform) {
                // When opening the comment pane, watcher catches the showComments value change.
                // And call the this.audioWaveform.didChangeBoundingRect() before audio-container's style is applied
                // For preventing the pre-calling of the didChangeBoundingRect, added the $timeout.
                this.$$.$timeout(() => this.audioWaveform.didChangeBoundingRect());
            }
        }));

        this.beforeDestroy(this.$watch('commentPaneWidth', () => {
            if (this.audioWaveform) {
                // When changing the comment pane width, watcher catches the commentPaneWidth value change.
                // And call the this.audioWaveform.didChangeBoundingRect() before audio-container's style is applied
                // For preventing the pre-calling of the didChangeBoundingRect, added the $timeout.
                this.$$.$timeout(() => this.audioWaveform.didChangeBoundingRect());
            }
        }));
    }

    initKeyboardShortcuts () {
        super.initKeyboardShortcuts();

        // Toggle the audio waveform's playing state (default key binding: [space])
        this.beforeDestroy(this.$$.shortcutService.watch('playPause', () => {
            if (this.audioWaveform) {
                this.audioWaveform.toggle();
            }
        }));

           // Scrub to next frame (default key binding: [.)
        this.beforeDestroy(this.$$.shortcutService.watch('nextFrame', () => {
            if (this.audioWaveform) {
                this.audioWaveform.skipForward(1);
            }
        }));

        // Scrub to the previous frame (default key binding: [,])
        this.beforeDestroy(this.$$.shortcutService.watch('previousFrame', () => {
            if (this.audioWaveform) {
                this.audioWaveform.skipBackward(1);
            }
        }));

        // Skip 5 seconds ahead (default key binding: [right])
        this.beforeDestroy(this.$$.shortcutService.watch('nextPage', () => {
            if (this.audioWaveform && !this.audioWaveform.isShortAudio) {
                this.audioWaveform.skipForward(5);
            }
        }));

        // Skip backwards 5 seconds (default key binding: [left])
        this.beforeDestroy(this.$$.shortcutService.watch('previousPage', () => {
            if (this.audioWaveform && !this.audioWaveform.isShortAudio) {
                this.audioWaveform.skipBackward(5);
            }
        }));

        this.beforeDestroy(() => {
            this.audioWaveform.destroy();
        });
    }

    initAudioSupport() {
        this.supportsAudio = typeof window.AudioContext !== 'undefined';

        this.load().then(() => {
            if (this.supportsAudio) {
                this.loadWaveform();
            } else {
                this.loadFallbackWaveform();
            }
        }).then(() => this.didLoad());
    }

    loadWaveform() {
        this.audioDownloadProgress = 0;
        return (
            this.$$.temporaryStorageService
                .auto(['#audio', this.proof.id], () => {
                    // Todo use fileService.downloadFile rather which uses SDK
                    const {promise, cancel} = this.$$.fileService.download(
                        this.proof.id,
                        this.proof.file.id,
                        this.proof.file.chunks,
                        'audio/' + this.proof.file.extension // TODO Get the actual mime-type (not sure it matters though)
                    );
                    this.beforeDestroy(cancel); // Make sure we cancel if the user decides to navigate away from the proof page
                    return promise;
                })
                .then((file) => {
                    this.file = this.proof.file.$file = file;
                    this.audioDownloadProgress = 100;
                }, () => {
                    this.audioDownloadProgress = -1;
                }, (percent) => {
                    this.audioDownloadProgress = Math.floor(percent * 100);
                })
        );
    }

    loadFallbackWaveform() {
        this.$$.modalService.translate(
            'media.unsupported-browser.title',
            'media.unsupported-browser.message',
            [{type: 'primary', text: 'media.unsupported-browser.button'}]
        );
    }

    getTimeFromPosition(x) {
        return this.audioWaveform.duration * (x + .5);
    }

    getTimeAndDuration(data) {
        const start = this.getTimeFromPosition(data.x || data.x1);
        const end = data.x2 !== null ? this.getTimeFromPosition(data.x2) : start;
        const time = Math.min(start, end);
        const duration = Math.abs(end - start);

        return {
            time,
            duration,
        };
    }

    stampTimeAndDuration(pin, data) {
        Object.assign(pin, this.getTimeAndDuration(data));
    }

    canUsePinHistory() {
        return this.isCommenting;
    }

    /**
     *
     * @param {Object} data
     * @returns {PPProofComment}
     */
    startCreateComment (data) {
        let comment = super.startCreateComment(data, undefined, (pins) => {
            this.stampTimeAndDuration(pins[pins.length - 1], data);
        });
        this.scrubToComment(comment);
        return comment;
    }

    /**
     * Stamps the comment's time/duration before it sends a request to create comment.
     *
     * @param {PPProofComment} comment
     * @returns {PPProofComment}
     */
    finishCreateComment(comment) {
        comment.pins.forEach((pin) => this.stampTimeAndDuration(pin, pin));
        return super.finishCreateComment(comment);
    }

    /**
     * Update the comment pin's xy12 (which changes their time/duration).
     *
     * @param {PPProofComment} comment
     * @param {Object} data
     * @param {number} pinIndex
     */
    updatePin(comment, data, pinIndex) {
        return super.updatePin(comment, data, pinIndex, (pin) => {
            this.stampTimeAndDuration(pin, pin);
            this.scrubToComment(comment);
        });
    }

    updateTemporaryPin(data, pinIndex) {
        super.updateTemporaryPin(data, pinIndex, (pins) => {
            this.stampTimeAndDuration(pins[pinIndex], data);
        });
    }

    /**
     * When the selection of a comment updates.
     *
     * @param {PPProofComment} previous
     * @param {PPProofComment} current
     */
    selectionUpdate(previous, current) {
        super.selectionUpdate(previous, current);

        if (current) {
            // Scrub to the comment's position on the video
            this.scrubToComment(current);
        }
    }

    /**
     * Scrubs to a comment.
     *
     * @param {PPProofComment} comment
     */
    scrubToComment(comment) {
        if (comment.pins.length === 1) {
            this.audioWaveform.scrub(comment.pins[0].time);
            this.audioWaveform.pause();
        }
    }

    updateVolume(volume) {
        this.volumeProps.volume = volume;
        this.audioWaveform.setVolume(volume);
        window.localStorage.setItem(AUDIO_PLAYER_VOLUME_KEY, volume);
    }

    toggleCollectionView() {
        super.toggleCollectionView();
        if (this.audioWaveform) {
            this.audioWaveform.pause();
        }
    }

    scrubToTime(time) {
        this.audioWaveform.scrub(time);
    }

    /**
     * Toggles focus mode of audio proof page
     */
    toggleFocusMode = () => {
        if (!this.isSmallScreen || this.focusMode) {
            this.focusMode = !this.focusMode;
            this.toggleFocusModeInHeader(this.focusMode);
        }
    }
}

app.controller('AudioProofController', AudioProofController);
