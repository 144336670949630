/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
angular
    .module('ppxGlobalControllers')
    .factory('$addressBook',
        function (UserService, addressBookRepositoryService) {
            var service = {},
                application = UserService,
                user = new Users(application),
                self = this;

            /* Add and add many methods are deprecated because the functionality is in the API*/

            service.add = function(email, callbackFunc) {
                if(validateEmail(email)){
                    var data = application.GetUserData();
                    addressBookRepositoryService.get(data.userId).then(function (emailData){
                        var index = emailData.emails.indexOf(email);
                        if (index === -1) {
                            emailData.emails.push(email);
                            addressBookRepositoryService.set(data.userId, emailData).then(function() {
                                if( isFunc(callbackFunc) ) callbackFunc();
                            });
                        } else {
                            if( isFunc(callbackFunc) ) callbackFunc();
                        }
                    });
                } else {
                    if( isFunc(callbackFunc) ) callbackFunc();
                }
            };

            service.load = function(callbackFunc) {
                //user.GetAddressBook(function(emails){
                //    if(emails.length > 0){
                //        if( isFunc(callbackFunc) ) callbackFunc(emails); //make it unique just in case
                //    }
                //});

                var data = application.GetUserData();

                addressBookRepositoryService.get(data.userId).then(function(emailData){

                    // console.log("emailData", emailData);

                    var data = [];
                    if(emailData.length >= 0){
                        data = emailData;
                    }else if(emailData.emails.length >= 0){
                        data = emailData.emails;
                    }

                    if (callbackFunc) callbackFunc(window.generalfunctions_unique(data)); //make it unique just in case
                });
            };

            service.delete = function(email, callbackFunc){
                if(validateEmail(email)){
                    user.DeleteFromAddressBook(email, function(response){
                        var data = application.GetUserData();

                        addressBookRepositoryService.get(data.userId).then(function(emailData){
                            emailData.emails.splice(emailData.emails.indexOf(email), 1);
                            return addressBookRepositoryService.set(data.userId, emailData);
                        }).then(callbackFunc);
                    });
                }
            };

            return service;
        }
    )
    .factory('addressBook', function ($addressBook) {
        return $addressBook;
    });
