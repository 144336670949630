/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const EXPIRE_TIMEOUT = env('avatar_expire_timeout', 24 * (60 * (60 * 1000))); // 24 hours (1 day)

class AvatarRepository extends BaseDatabaseCache {
    constructor ($q, backendService, PPUserAvatar) {
        super();
        this.$q = $q;
        this.backendService = backendService;
        this.PPUserAvatar = PPUserAvatar;
    }
    clearExpiredDatabaseEntries (db) {
        return db.avatars.where('createdAt').belowOrEqual(Date.now() - EXPIRE_TIMEOUT).delete();
    }
    resolveFromDatabase (db, info) {
        return db.avatars.where('userId').equals(info.userId)
            .first((item) => {
                if (item) {
                    let avatar = new this.PPUserAvatar();
                    avatar.hasAvatar = item.hasAvatar;
                    avatar.isRegistered = item.isRegistered;
                    avatar.initials = item.initials;

                    if (item.hasAvatar) {
                        // If the user has an avatar, parse it and return the avatar object
                        return this.parseDatabaseBlob(item.imageType, item.image).then((image) => {
                            avatar._image = image;
                            avatar.image = URL.createObjectURL(image);
                            return avatar;
                        });
                    }

                    return avatar;
                } else {
                    throw new Error('Cannot resolve/find item in database.');
                }
            });
    }
    resolveFromServer (info) {
        return this.backendService.fetch('user.avatar', {userId: info.userId}).data()
            .then((data) => {
                let avatar = new this.PPUserAvatar();
                avatar.updateFromUserAvatarData(data);
                return avatar;
            });
    }
    serialiseForDatabase (info, avatar) {
        return this.serialiseDatabaseBlob(avatar._image).then(({type, blob}) => {
            return {
                userId: info.userId,
                createdAt: Date.now(),
                hasAvatar: avatar.hasAvatar,
                isRegistered: avatar.isRegistered,
                initials: avatar.initials,
                image: blob,
                imageType: type
            };
        });
    }
    storeInDatabase (db, info, data) {
        return db.avatars.add(data);
    }
    removeFromDatabase (db, info) {
        return db.avatars.where('userId').equals(info.userId).delete();
    }
}

app.service('avatarRepositoryService', AvatarRepository);