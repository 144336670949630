/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/*
Team object, constructor has to be passed a type as well as the application oject. Type determines what api is acted on, eg: api/proof/team, api/workflow/team etc
 */

function Team(app, apiType) {
    this.application = app;
    this.api = app.api;
    this.apiType = apiType;
    this.permissionsList = {};

    if( !this.apiType ){
        console.warn('No apiType set');
    }

    this.SetPermissions();

};

Team.prototype.SetPermissions = function() {
    this.permissionsList = [
        "Perm_WorkflowAdmin"
    ];
};

Team.prototype.Add = function(updateObj, callBackFunc) {
    if (isset(updateObj)) {
        this.api.CallAPI(this.apiType, "teamAdd", updateObj, function(){
            var returnData = jsonDecode(this.responseText);
            callBackFunc(returnData);
        });
    } else {
        throw new Error("empty updateObj");
    }
};

Team.prototype.AddMany = function(updateObj, callBackFunc) {
    if (isset(updateObj)) {
        this.api.CallAPI(this.apiType, "teamAddMany", updateObj, function(){
            var returnData = jsonDecode(this.responseText);
            callBackFunc(returnData);
        });
    } else {
        throw new Error("empty updateObj");
    }
};

Team.prototype.Update = function(memberId, updatePermissionsObj, callBackFunc) {

    var updateObj = {};

    updateObj.MemberId = memberId;

    updatePermissionsObj.forEach(function(value, index){
        updateObj[index] = value;
    });

    if (isset(updateObj)) {
        this.api.CallAPI(this.apiType, "teamUpdate", updateObj, function(){
            var returnData = jsonDecode(this.responseText);
            callBackFunc(returnData);
        });
    } else {
        throw new Error("empty updateObj");
    }
};

Team.prototype.Delete = function(memberId, type, callBackFunc) {
    if (isset(memberId) && isset(type)) {

        var allowedTypes = ["ProofCoOwner","WorkflowInviter","WorkflowReadWrite",
            "WorkflowCopyToProof", "WorkflowTemplateCoowner"];

        var data = {MemberId:memberId};

        if(inArray(allowedTypes, type)){
            data[type] = 0;
        }

        this.api.CallAPI(this.apiType, "memberSet", data, function(){
            var returnData = jsonDecode(this.responseText);
            if(isFunc(callBackFunc)) callBackFunc(returnData);
        });
    } else {
        throw new Error("empty teamMemberId, or type");
    }
};

Team.prototype.GetList = function(relatedId, callBackFunc) {
    if (isset(relatedId)) {
        this.api.CallAPI(this.apiType, "teamGetList", relatedId, function(){
            var returnData = jsonDecode(this.responseText);
            if(isFunc(callBackFunc)) callBackFunc(returnData);
        });
    } else {
        throw new Error("empty params");
    }
};

Team.prototype.GetUserFromList = function(related_Id, userId, callBackFunc) {
    if (isset(related_Id)) {
        this.api.CallAPI(this.apiType, "teamGetlist", related_Id, function(){
            var returnData = jsonDecode(this.responseText);
            returnData.forEach(function(value, index){
                if(value["userId"] == userId){
                    if(isFunc(callBackFunc)) callBackFunc(value);
                }
            });
        });
    } else {
        throw new Error("empty params");
    }
};

Team.prototype.CheckProof = function(proofId, workflowId, callBackFunc) {
    if ( isset(proofId) && isset(workflowId) ) {
        this.api.CallAPI("proofs", "check", {ProofId:proofId, WorkflowId:workflowId}, function(){
            var returnData = jsonDecode(this.responseText);
            if(isFunc(callBackFunc)) callBackFunc(returnData);
        });
    } else {
        throw new Error("empty params CheckProof");
    }
};