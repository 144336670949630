/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.constant('PPProofButtonState', createEnumeration([
    'NONE',
    'FINISHED',
    'APPROVE_AND_UNLOCK',
    'APPROVE_AND_UNLOCK_LIMITED_COMMENT_VISIBILITY',
    'GATEKEEPER_SEND_CHANGES',
    'APPROVE',
    'APPROVE_LIMITED_COMMENT_VISIBILITY',
    'APPROVER_SEND_CHANGES',
    'UPLOAD_NEW_VERSION',
    'SEND_BRIEF',
    'DECISION_GIVEN'
]));
