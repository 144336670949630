/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.filter('commentWithMention', function($sce) {
    return function (str) {
        if (str.length > 0) {
            const obj = window.generalfunctions_parseCommentText(str);
            const html = window.__pageproof_quark__.sdk.util.comments.html(obj.tokens);
            return $sce.trustAsHtml(html);
        }
        return str;
    };
});
