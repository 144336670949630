/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

Error.WithMetaData = function (message, metadata, origin) {
    if ( ! this instanceof Error.WithMetaData)
        return new Error.WithMetaData(message, metadata, origin);

    Error.call(this);

    this.message = {
        message: message,
        metadata: metadata,
        origin: origin,
        error: this
    };
};

Error.WithMetaData.prototype = Object.create(Error.prototype);
Error.WithMetaData.prototype.constructor = Error.WithMetaData;
Error.WithMetaData.name = 'Error.WithMetaData';
