/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const buttonTypes = {
    main: [
        'main',

        'green',
        'white-text',

        'hover:green',
        'hover:white-text'
    ],
    primary: [
        'green',
        'white-text',

        'hover:green',
        'hover:white-text'
    ],
    outline: [
        'green-outline',
        'green-text',

        'hover:green',
        'hover:white-text'
    ],
    text: [
        'green-text',

        'hover:green-text'
    ],
    danger: [
        'red',
        'white-text',

        'hover:red',
        'hover:white-text'
    ],
    'danger-outline': [
        'red-outline',
        'red-text',

        'hover:red',
        'hover:white-text',
    ],
    'warning': [
        'orange',
        'white-text',

        'hover:orange',
        'hover:white-text'
    ],
    'warning-outline': [
        'orange-outline',
        'orange-text',

        'hover:orange',
        'hover:white-text',
    ],
    'icon': [
        'icon',
        'auto-width',

        'hover:icon'
    ],
    'orange-text': [
        'orange-text',

        'hover:orange-text'
    ],
    disabled: [
        'disabled',
    ],
};

function BtnServiceProvider($parse) {
    /**
     *
     * @param {String[]} attr
     * @param {$rootScope.Scope} scope
     * @returns {String[]|undefined}
     */
    function getButtonAttribute(attr, scope) {
        if (attr) {
            if (attr in buttonTypes) {
                return buttonTypes[attr];
            } else {
                let parsed = $parse(attr)(scope);

                if (angular.isArray(parsed)) {
                    return parsed;
                } else if (parsed in buttonTypes) {
                    return buttonTypes[parsed];
                }
            }
        }
    }

    /**
     *
     * @param {String[]} names
     * @returns {String[]}
     */
    function getPrefixedButtonClasses(names) {
        return names.map((name) => 'app__btn--' + name.replace(':', '--'));
    }

    return {
        getButtonAttribute,
        getPrefixedButtonClasses
    };
}

/**
 * btn
 *
 * Maps a name to a set of app button classes to clean up
 *
 * @example
 *     <button btn="primary">
 *         Hello World
 *     </button>
 *
 *     <button btn="['green', 'white-text', 'hover:green', 'hover:white-text']">
 *         Hello World
 *     </button>
 */
function BtnDirective(btnService) {
    return {
        link(scope, element, attr) {
            element.addClass('app__btn');

            scope.$watch(() => attr.btn, (type, previousType) => {
                if (previousType) {
                    let names = btnService.getButtonAttribute(previousType, scope);
                    element.removeClass(btnService.getPrefixedButtonClasses(names).join(' '));
                }

                let names = btnService.getButtonAttribute(type, scope);
                element.addClass(btnService.getPrefixedButtonClasses(names).join(' '));
            });
        }
    };
}

app
    .factory('btnService', BtnServiceProvider)
    .directive('btn', BtnDirective);
