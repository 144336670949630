/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class TeamSCIMSettingsController extends BaseController {
  constructor($scope, sdk, seoService) {
    super();

    this.$$ = {
      $scope,
      sdk,
      seoService,
    }

    this.$scope = $scope;
    this.sdk = sdk;
    this.seoService = seoService;

    this.props = {
      loading: true,
      settings: null,
      onEnable: this.onEnable,
      onDisable: this.onDisable,
      onRegenerateAccessToken: this.onRegenerateAccessToken,
    };

    this.$$.seoService.set({
      title: 'domain-admin.menu.scim',
      translateTitle: true,
    });

    this.onLoad();

    this.seoService.set({
      pageTitle: 'domain-admin.menu.scim',
      translatePageTitle: true,
    });
  }

  update = () => {
    if (!this.$scope.$$phase) {
      this.$scope.$apply();
    }
  }

  catch = (err) => {
    if (err && err.request && err.response && err.response.message) {
      this.props.error = err.response.message;
    } else {
      this.props.error = 'An unexpected error occurred.';
    }
  }

  onLoad = () => {
    this.props.loading = true;
    this.sdk.admin()
      .then(admin => admin.team.scim.settings())
      .then((settings) => {
        this.props.loading = false;
        this.props.settings = settings;
      })
      .catch(this.catch)
      .then(this.update);
  }

  onEnable = () => {
    this.props.loading = true;
    this.props.settings.enabled = true;
    this.sdk.admin()
      .then(admin => admin.team.scim.enable())
      .then((settings) => {
        this.props.settings = settings;
        this.props.loading = false;
      })
      .catch(this.catch)
      .then(this.update);
  }

  onRegenerateAccessToken = (type) => {
    return this.sdk.admin()
      .then(admin => admin.team.scim.regenerateAccessToken(type))
      .then((settings) => {
        this.props.settings = settings;
      })
      .catch(this.catch)
      .then(this.update);
  }

  onDisable = () => {
    this.props.loading = true;
    this.props.settings = {
      enabled: false,
    };
    this.sdk.admin()
      .then(admin => admin.team.scim.disable())
      .then(() => {
        this.props.loading = false;
      })
      .catch(this.catch)
      .then(this.update);
  }
}

app.controller('TeamSCIMSettingsController', TeamSCIMSettingsController);
