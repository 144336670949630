/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
angular
    .module('ppxGlobalControllers')
    .constant('websocketConfig', {
        class: window.MozWebSocket || window.WebSocket,
        options: {
            url: null,
            reconnectInterval: 30e3, // 30 seconds
            reconnectLimit: 2,
            protocols: [],
            autoConnect: true,
            autoReconnect: true,
            enableQueue: true,
            verbose: true // TODO Change this based on environment
        }
    })
    .factory('websocketService', function ($rootScope, $timeout, eventService, websocketConfig, UserService) {
        /*
         * Properties
         *
         * Object options
         * Object[] queue
         * Object callbacks
         *
         * WebSocket $$socket
         * Number $$reconnectTries
         * Function $$reconnectTimeout
         * Boolean $$forceClose
         */

        /*
         * Events
         *
         * open (no arguments)
         * reopen (no arguments)
         * message
         *   Object|Array|undefined The parsed message (undefined if not JSON format)
         *   String The un-parsed message (useful if the message is not JSON format)
         * close
         *   Boolean Whether the close was caused programmatically
         */

        /**
         * Creates a new WebsocketService instance.
         *
         * @param {Object} options
         * @constructor
         */
        function WebsocketService (options) {
            this.options = angular.extend({}, websocketConfig.options, options);
            this.queue = [];
            this.callbacks = {};

            if (this.options.autoConnect) {
                this.$$init();
            }
        }

        /**
         * Returns a function that can be queued.
         *
         * @param {Function} method
         * @returns {Function}
         */
        WebsocketService.$$queued = function (method) {
            var wrapped;

            return wrapped = function () {
                // If we're connected, invoke the method
                if (this.isConnected()) {
                    return method.apply(this, arguments);
                }

                // Queue messages when the socket is not connected (offline)
                else if (this.options.enableQueue) {
                    // Push the context, function & arguments to be queued
                    this.queue.push({
                        context: this,
                        fn: wrapped,
                        args: arguments
                    });
                }
            };
        };

        var fn = WebsocketService.prototype;

        /**
         * Initialises the connection to the websocket.
         *
         * Handles the registration of events from the websocket (open, message, close).
         *
         * @see {eventService.on}
         */
        fn.$$init = function () {
            this.$$socket = new websocketConfig.class(this.options.url, this.options.protocols);

            eventService.on(this.$$socket, 'open', this.$$open.bind(this));
            eventService.on(this.$$socket, 'message', this.$$message.bind(this));
            eventService.on(this.$$socket, 'close', this.$$close.bind(this));
            eventService.on(this.$$socket, 'error', this.$$close.bind(this));

            //eventService.on(this.$$socket, 'open message close error',
            //    this.$$debug.bind(this, $$log.create('websocketService.$$debug')));
        };

        ///**
        // *
        // */
        //fn.$$debug = function (log, event) {
        //    if (this.options.verbose === true) {
        //        switch (event.type) {
        //            case 'open':
        //                log('Opening connection to "' + this.options.url + '".');
        //                break;
        //            case 'message':
        //                var data = event.data;
        //                try { data = JSON.parse(data) } catch (err) {}
        //
        //                log('Received message from websocket "' + this.options.url + '".', data);
        //                break;
        //            case 'close':
        //                log('Websocket connection closed to "' + this.options.url + '".');
        //                break;
        //            case 'error':
        //                log('Error in websocket "' + this.options.url + '".');
        //                break;
        //        }
        //    }
        //};

        /**
         * Convenience method for whether the websocket is currently open/connected.
         *
         * @returns {Boolean}
         */
        fn.isConnected = function () {
            return this.$$socket && (this.$$socket.readyState === (websocketConfig.class.OPEN || 1));
        };

        /**
         * When the websocket is opened (connected properly).
         *
         * Resets any pending reconnect settings/properties (ie. $$reconnectTries, $$reconnectTimeout)
         * which are used to internally track how many times the socket has tried to reconnect.
         */
        fn.$$open = function () {
            // Reset the force close property (as we're back open)
            this.$$forceClose = false;

            // Reset the reconnect tries, so subsequent disconnects won't be affected
            this.$$reconnectTries = 0;

            // If we've been trying to reconnect, clear the timeout
            if (angular.isFunction(this.$$reconnectTimeout)) {
                this.$$reconnectTimeout();
                this.$$reconnectTimeout = null;

                // Trigger a reconnected (reopen) event, for clarity
                this.$$emit('reopen');
            }

            // Process the queue (as there may be items waiting)
            this.$$processQueue();

            // Emit the open event (calls all the registered event listeners)
            this.$$emit('open');
        };

        /**
         * Parses an event (to a object/array).
         *
         * If an event cannot be parsed as JSON, `undefined` is returned. You have been warned.
         * This is to make sure that if checking for truthy, it doesn't pass - even if it failed.
         *
         * @param {Event} event
         * @returns {Object|Array}
         */
        fn.$$parse = function (event) {
            var data;

            try {
                data = JSON.parse(event.data);
            } catch (err) {
                // data = event.data;
            }

            return data;
        };

        /**
         * Fired when the websocket received a message event.
         *
         * @param {Event} event
         */
        fn.$$message = function (event) {
            var data = this.$$parse(event);

            this.$$emit('message', [data, event.data]);
        };

        /**
         * Adds an event handler.
         *
         * @param {String} type
         * @param {Function} callback
         */
        fn.$$on = function (type, callback) {
            if ( ! this.callbacks[type]) {
                this.callbacks[type] = [];
            }

            var callbacks = this.callbacks[type];
            callbacks.push(callback);

            return function () {
                callbacks.splice(callbacks.indexOf(callback), 1);
            };
        };

        /**
         * Emits an event.
         *
         * @param {String} type
         * @param {Array} [args]
         */
        fn.$$emit = function (type, args) {
            var callbacks;

            if (callbacks = this.callbacks[type]) {
                angular.forEach(callbacks, function (callback) {
                    callback.apply(this, args);
                }, this);
            }
        };

        /**
         * Fired when the websocket closes the connection/disconnects due to connectivity issues.
         *
         * Causes a timeout to be registered to re-connect to the websocket. This is limited to the
         * reconnectLimit option (default: 10).
         */
        fn.$$close = function () {
            if ( ! this.$$forceClose && this.options.autoReconnect === true) {
                // Schedule a reconnect attempt in the specified reconnectTimeout ms
                this.$$reconnectTimeout = $timeout(
                    this.$$reconnect.bind(this),
                    this.options.reconnectInterval
                );
            }

            // Emit the close event, calls all the registered event listeners
            this.$$emit('close', [this.$$forceClose === true]);
        };

        /**
         * Reconnect to the websocket (used internally when the socket is closes).
         *
         * @see {WebsocketService.$$close}
         */
        fn.$$reconnect = function () {
            if ( ! angular.isDefined(this.$$reconnectTries)) {
                this.$$reconnectTries = 0;
            }

            // If the amount of tries exceeds the reconnect limit, stop trying
            if (this.$$reconnectTries >= this.options.reconnectLimit) {
                return;
            }

            // Increment the number of times we've tried to reconnect
            this.$$reconnectTries++;

            // Reconnect to the websocket
            this.$$init();
        };

        /**
         * Registers an event listener.
         *
         * @param {Event} event
         * @param {Function} callback
         */
        fn.on = function (event, callback) {
            return this.$$on(event, callback);
        };

        /**
         * Processes the queue.
         *
         * @returns {Boolean}
         * @private
         */
        fn.$$processQueue = function () {
            if ( ! this.isConnected()) return false;

            while (this.queue.length) {
                var job = this.queue.shift();

                // Invoke the function from the queue (apply context & arguments)
                job.fn.apply(job.context, job.args);
            }

            return true;
        };

        /**
         * Sends data to the websocket.
         *
         * @param {String|ArrayBuffer|ArrayBufferView|Blob} data
         */
        fn.send = WebsocketService.$$queued(function (data) {
            this.$$socket.send(data);
        });

        /**
         * Sends a signed message to the websocket.
         *
         * @param {Object|String|*} message
         */
        fn.sign = WebsocketService.$$queued(function (message) {
            var encryption = UserService.enc,
                timestamp = encryption.GetAuthenticationTimestamp(),
                data = {
                    message: message,
                    timestamp: timestamp,
                    token: encryption.userToken,
                    hmac: encryption.GetAuthenticationHeader('SOCKET', 'socketURL', timestamp)
                };

            this.send(JSON.stringify(data));
        });

        /**
         * Opens the websocket (if the socket is currently disconnected).
         *
         * @see {WebsocketService.isConnected}
         */
        fn.open = function () {
            if ( ! this.isConnected()) {
                this.$$init();
            }
        };

        /**
         * Closes the websocket (if the socket is currently connected).
         *
         * @see {WebsocketService.isConnected}
         */
        fn.close = function () {
            if (this.isConnected()) {
                this.$$forceClose = true;
                this.$$socket.close();
            }
        };

        /**
         * @see {WebsocketService}
         */
        return function (options) {
            return new WebsocketService(options);
        };
    });
