/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class CommentFilter {
    /**
     * @param {String|null} name
     * @param {String[]|null} [args]
     * @param {Function} [fn]
     * @constructor
     */
    constructor (name, args, fn) {
        this.name = name;
        this.args = args || [];
        this.fn = fn || (() => true);
    }

    /**
     * The filter which filters out comments made by a/many users by either the user
     * id or the user's email address.
     *
     * @param {String[]} users
     * @returns {CommentFilter}
     */
    static user (...users) {
        return new CommentFilter(
            'user', users,
            comment => users.some((user) => {
                const pdfAuthorId = comment.sourceMetadata && comment.sourceMetadata.pdfImport && ('pdfAuthor:' + comment.sourceMetadata.pdfImport.author);
                
                if (pdfAuthorId ? pdfAuthorId === user : (comment.ownerEmail === user || comment.ownerId === user)) {
                    return true;
                } else {
                    return comment.replies.some(reply => {
                        const replyPdfAuthorId = reply.sourceMetadata && reply.sourceMetadata.pdfImport && ('pdfAuthor:' + reply.sourceMetadata.pdfImport.author);

                        return replyPdfAuthorId ? replyPdfAuthorId === user : (reply.ownerEmail === user || reply.ownerId === user);
                    });
                }
            })
        );
    }

    static mention (...users) {
        return new CommentFilter(
            'mention', users,
            comment => users.some((user) => {
                if (comment.mentionedIds) {
                    const mentions = comment.mentionedIds;
                    if (mentions.includes(user) || mentions.includes('~everyone')) {
                        return true;
                    } else {
                        return comment.replies.some(reply => {
                            return reply.mentionedIds.includes(user) || reply.mentionedIds.includes('~everyone');
                        });
                    }
                }
            })
        );
    }

    static pages (...pageNumbers) {
        return new CommentFilter(
            'pages', pageNumbers,
            comment => pageNumbers.some(pageNumber => {
                return Number(pageNumber) === comment.pageNumber;
            })
        );
    }

    static webPages (...page) {
      return new CommentFilter(
          'web-pages', page,
          comment => (page.includes(window.__pageproof_quark__.comments.getCommentWebPagePath(comment.metadata.page.path)))
      );
    }

    static importFileName(...fileName) {
        return new CommentFilter(
            'import-file-name', fileName,
            comment => {
                const commentFileName = comment.sourceMetadata && comment.sourceMetadata.pdfImport && comment.sourceMetadata.pdfImport.fileName;
                return commentFileName && fileName.includes(commentFileName);
            }
        );
    }

    static label (...type) {
        return new CommentFilter(
            'label', type,
            comment => (type.includes(comment.getLabel()))
        );
    }

    static deviceCategory (...type) {
        return new CommentFilter(
            'device-category', type,
            comment => (type.includes(window.__pageproof_quark__.comments.getCommentDeviceCategory(comment.metadata.device.id)))
        );
    }

    /**
     * @returns {CommentFilter}
     */
    static page (pageNumber) {
        return new CommentFilter(
            'unmarked', [pageNumber],
            comment => (comment.pageNumber === Number(pageNumber) || comment.parentId)
        );
    }

    /**
     * @returns {CommentFilter}
     */
    static unmarked () {
        return new CommentFilter(
            'unmarked', null,
            comment => ((!comment.isTodo && !comment.isDone && !comment.isPrivate) || comment.parentId)
        );
    }

    /**
     * @returns {CommentFilter}
     */
    static private () {
        return new CommentFilter(
            'private', null,
            comment => (comment.isPrivate || comment.replies.some(reply => reply.isPrivate))
        );
    }

    /**
     * The filter which filters out only the todos on a proof.
     *
     * @returns {CommentFilter}
     */
    static todo () {
        return new CommentFilter(
            'todo', null,
            comment => ((comment.isTodo && !comment.isDone) || comment.parentId)
        );
    }

    /**
     * The filter which filters out only the dones on a proof.
     *
     * @returns {CommentFilter}
     */
    static done () {
        return new CommentFilter(
            'done', null,
            comment => (comment.isDone || comment.parentId)
        );
    }

    /**
     * @returns {CommentFilter}
     */
    static agrees () {
        return new CommentFilter(
            'agrees', null,
            comment => ((comment.agrees && comment.agrees.length > 0) || comment.parentId || comment.replies && comment.replies.some(reply => reply.agrees && reply.agrees.length > 0))
        );
    }

    /**
     * @returns {CommentFilter}
     */
    static noAgrees () {
        return new CommentFilter(
            'no-agrees', null,
            comment => ((!comment.agrees.length) || (comment.parentId && comment.replies.some(reply => !reply.agrees.length)))
        );
    }

    /**
     * @returns {CommentFilter}
     */
    static attachments () {
        return new CommentFilter(
            'attachments', null,
            comment => (!!comment.attachments.length || comment.replies && comment.replies.some(reply => !!reply.attachments.length))
        );
    }

    /**
     * @returns {CommentFilter}
     */
    static search (inputText = '') {
        const getCommentContent = (content, decryptedContent) => {
            if (!(content || decryptedContent)) return '';
            const tokens = window.generalfunctions_parseCommentText(content || decryptedContent).tokens;
            return window.__pageproof_quark__.sdk.util.comments.text(tokens).toLowerCase();
        }
        const lowerCasedText = inputText.toLowerCase();
        return new CommentFilter(
            'search', [inputText],
            ({comment, decryptedComment, replies, number}) => {
                return !(lowerCasedText === '') && (inputText === ('#' + number)
                    || getCommentContent(comment, decryptedComment).includes(lowerCasedText)
                    || replies.some(({comment, decryptedComment}) => getCommentContent(comment, decryptedComment).includes(lowerCasedText)));
            }
        )
    }

    /**
     * @returns {CommentFilter}
     */
    static replies () {
        return new CommentFilter(
            'replies', null,
            comment => comment.replies && comment.replies.length > 0
        )
    }

    /**
     *
     * @returns {CommentFilter}
     */
    static all () {
        return new CommentFilter(null);
    }
}

class CommentFilterService {
    /**
     * Parses the filter string and returns the function which to run the comments through.
     *
     * @param {String} filter
     * @returns {Object}
     */
    parse (filter) {
        let name = null,
            args = [];

        if (filter && filter.length) {
            const indexOfSeparator = filter.indexOf(':');
            let [_name, _args = ''] = [
                (indexOfSeparator !== -1 
                    ? filter.substring(0, indexOfSeparator) 
                    : filter
                ),
                filter.substring(indexOfSeparator + 1)
            ];
            [name, args] = [_name, _args.split(',').filter((arg) => arg.length)];
        }

        return {name, args};
    }

    /**
     * Stringifies the filter name & arguments.
     *
     * @param {CommentFilter} filter
     * @returns {String}
     */
    stringify (filter) {
        let str = '';
        if (filter.name && filter.name.length) {
            str += filter.name;
            if (filter.args && filter.args.length) {
                str += ':' + filter.args.join(',');
            }
        }
        return str;
    }

    /**
     * Returns the comment filter for a specific type.
     *
     * @param {Object} filter
     * @returns {CommentFilter}
     */
    filter (filter) {
        switch (filter.name) {
            case 'user':
                return CommentFilter.user(...filter.args);
            case 'mention':
                return CommentFilter.mention(...filter.args);
            case 'label':
                return CommentFilter.label(...filter.args);
            case 'pages':
                return CommentFilter.pages(...filter.args);
            case 'private':
                return CommentFilter.private();
            case 'unmarked':
                return CommentFilter.unmarked();
            case 'todo':
                return CommentFilter.todo();
            case 'done':
                return CommentFilter.done();
            case 'attachments':
                return CommentFilter.attachments();
            case 'agrees':
                return CommentFilter.agrees();
            case 'no-agrees':
                return CommentFilter.noAgrees();
            case 'page':
                return CommentFilter.page(filter.args[0]);
            case 'search':
                return CommentFilter.search(filter.args[0]);
            case 'device-category':
                return CommentFilter.deviceCategory(...filter.args);
            case 'web-pages':
                return CommentFilter.webPages(...filter.args);
            case 'import-file-name':
                return CommentFilter.importFileName(...filter.args);
            case 'replies':
                return CommentFilter.replies();
            default:
                return CommentFilter.all();
        }
    }
}

app.service('commentFilterService', CommentFilterService);
