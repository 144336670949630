/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class ModalController {
    /**
     * The buttons.
     *
     * @type {ModalButtonController[]}
     */
    buttons = [];
}

class ModalButtonController {
    /**
     * The text within the modal button.
     *
     * @type {Function}
     */
    element = null;
}

function ModalDirective (directiveHelper) {
    return {
        templateUrl: 'templates/partials/proof/components/modal.html',
        controller: 'ModalController',
        controllerAs: 'modalCtrl',
        transclude: true,
        scope: true,
        replace: true,

        link (scope, element, attr, modalCtrl) {
            directiveHelper.callbackBinding(scope, attr, 'whenDismiss', modalCtrl, 'whenDismiss');
            directiveHelper.oneWayBinding(scope, attr, 'isDismissable', modalCtrl, 'isDismissable');
            scope.attr = attr;
            if ('layout' in attr) {
                switch (attr.layout) {
                    case 'global':
                        element.appendTo('body');
                        scope.$on('$destroy', () => element.remove());
                        break;
                }
            }
        }
    };
}

function ModalServiceProvider($q, $translate, $sce, $rootScope) {
    let modalService;

    return modalService = {
        $$modals: [],

        createWithComponent(name, props, onDestroy = null, isDismissable = true) {
            let modal;
            modalService.$$modals.push(modal = {
                layout: 'global',
                isDismissable,
                component: { name, props },
                destroy
            });


            function destroy() {
                modalService.$$modals.splice(modalService.$$modals.indexOf(modal), 1);
                if (!$rootScope.$$phase) { $rootScope.$apply(); }
                if (onDestroy) { onDestroy(); }
            }

            if (!$rootScope.$$phase) { $rootScope.$apply(); }

            return modal;
        },

        create (title, message, buttons = [], options = {}, isDismissable) {
            let modal;

            if ( ! buttons.length) {
                buttons.push({ type: 'primary', text: 'Dismiss' });
            }

            buttons.forEach((button) => {
                let callback = button.click;

                button.click = function () {
                    destroy();

                    if (angular.isFunction(callback)) {
                        callback();
                    }
                };
            });

            modalService.$$modals.push(modal = {
                title: $sce.trustAsHtml(String(title)),
                message: $sce.trustAsHtml(String(message)),
                buttons,
                magic: options ? options.magic : null,
                isDismissable,
                skipDialogProps: options && options.skipProps 
                    ? Object.assign(options.skipProps, { shouldshow: true }) 
                    : { shouldshow: false },
                destroy
            });

            function destroy () {
                modalService.$$modals.splice(
                    modalService.$$modals.indexOf(modal), 1);
            }

            return destroy;
        },

        translate (title, message, buttons = [], options = {}) {
            let queue = [];

            queue.push($translate(title).then((result) => title = result));
            queue.push($translate(message, options.variables).then((result) => message = result));

            buttons.forEach((button) => {
                queue.push($translate(button.text).then((result) => {
                    button.text = result;
                }));
            });

            return $q.all(queue).then(() => {
                return modalService.create(title, message, buttons, options);
            });
        },
    };
}

function ModalButtonDirective (eventService) {
    return {
        priority: 1001,
        require: ['^modal', 'modalButton'],
        controller: 'ModalButtonController',
        transclude: 'element',

        link (scope, element, attr, [modalCtrl, buttonCtrl], transclude) {
            buttonCtrl.element = transclude;
            modalCtrl.buttons.push(buttonCtrl);

            scope.$on('$destroy', () => {
                modalCtrl.buttons.splice(modalCtrl.buttons.indexOf(buttonCtrl), 1);
            });
        }
    };
}

app
    .controller('ModalController', ModalController)
    .controller('ModalButtonController', ModalButtonController)
    .directive('modal', ModalDirective)
    .directive('modalButton', ModalButtonDirective)
    .factory('modalService', ModalServiceProvider);
