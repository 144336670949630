/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.factory('PPProofWorkflowStep', function (PPModel) {
    let { booleanTransformer, numberTransformer, dateTransformer } = PPModel;

    const proofWorkflowStepTransformer = {
        isComplete: booleanTransformer('Complete'),
        description: 'Description',
        isEmpty: 'IsEmpty',
        position: numberTransformer('Position'),
        name: 'Title',
        dueDate: dateTransformer('DueDate'),
        isVisible: booleanTransformer('Visible'),
        id: 'StepId',
        workflowId: 'WorkflowId'
    };

    class PPProofWorkflowStep extends PPModel {
        /**
         * The array opf users on the step.
         *
         * @type {PPProofWorkflowUser[]}
         */
        users = [];

        /**
         * Whether the step has complete.
         *
         * @type {Boolean}
         */
        isComplete = false;

        /**
         * The description of the step.
         *
         * @type {String}
         */
        description = null;

        /**
         * Whether the step is empty.
         *
         * @type {Boolean}
         */
        isEmpty = false;

        /**
         * The position of the step.
         *
         * @type {Number}
         */
        position = 0;

        /**
         * The name of the step.
         *
         * @type {String}
         */
        name = null;

        /**
         * An optional due date for the step.
         *
         * @type {Date|undefined}
         */
        dueDate = undefined;

        /**
         * Whether the step is visible.
         *
         * @type {Boolean}
         */
        isVisible = false;

        /**
         * The id of the step.
         *
         * @type {String}
         */
        id = null;

        /**
         * The workflow id the step belongs to.
         *
         * @type {String}
         */
        workflowId = null;

        /**
         * Returns whether the workflow step is the final approver step.
         *
         * @type {Boolean}
         */
        get isFinalApproverStep () {
            return this.position === 1000;
        }

        /**
         * Update the workflow step from the workflow step data.
         *
         * @param {Object} data
         */
        updateFromProofWorkflowStepData (data) {
            this.transform(data, proofWorkflowStepTransformer);
        }

        /**
         * Create a proof workflow step with a proof workflow step data object.
         *
         * @param {Object} proofWorkflowStepData
         */
        static from (proofWorkflowStepData) {
            let proofWorkflowStep = new this();
            proofWorkflowStep.updateFromProofWorkflowStepData(proofWorkflowStepData);
            return proofWorkflowStep;
        }
    }

    return PPProofWorkflowStep;
});
