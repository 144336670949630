/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class BaseController {
    /**
     * The internal dependencies (so deps don't end up overriding properties).
     *
     * @type {Object<String, *>}
     */
    $$ = {};

    /**
     * The controllers local scope.
     *
     * @type {$rootScope.Scope}
     */
    $$scope = null;

    /**
     * Whether the controller has been destroyed.
     *
     * @type {Boolean}
     */
    $$destroyed = false;

    /**
     * Whether the page has animated in.
     *
     * @type {Boolean}
     */
    $$entered = false;

    /**
     * The callbacks to fire before the scope is destroyed.
     *
     * @type {Function[]}
     */
    $$beforeDestroy = [];

    /**
     * The callbacks to fire after the slide animation has complete.
     *
     * @type {Function[]}
     */
    $$afterEnter = [];

    /**
     * Construct the BaseController.
     *
     * This constructor does not require anything injected into it, it calculates what the `init` method needs
     * injected, and injects it using the app's `$injector`.
     *
     * @see {$injector.invoke}
     */
    constructor () {
        injectorInvoke(this.$$init, this);
    }

    /**
     * Register the event listener that lets the controller unbind all it's listeners - and in general; clean
     * up after itself. This functionality can be utilised if you call the `BaseController.beforeLeave` method.
     *
     * @param {Object} $route
     * @param {Function} $parse
     * @param {Function} $timeout
     * @param {Object} $routeParams
     * @param {Object} $location
     * @param {Object} callbackService
     */
    $$init ($route, $parse, $timeout, $routeParams, $location, callbackService) {
        let locals = $route.current.locals,
            $scope = locals.$scope;

        this.$$locals = locals;
        this.$$scope = $scope;
        this.$$parse = $parse;
        this.$$routeParams = $routeParams;
        this.$$location = $location;

        $scope.$on('$destroy', (event) => {
            this.$$destroyed = true;
            callbackService.clean(this.$$beforeDestroy);
        });

        $timeout(() => {
            this.$$entered = true;
            callbackService.clean(this.$$afterEnter);
        }, 1e3);
    }

    /**
     * Sets the dependencies in the controller.
     *
     * @param {Object<String, *>} dependencies
     */
    $$import (dependencies) {
        for (let name in dependencies) {
            if (Object.prototype.hasOwnProperty.call(dependencies, name)) {
                this.$$[name] = dependencies[name];
            }
        }
    }

    /**
     * Registers a callback for when the controller is destroyed.
     *
     * @param {Function} callback
     */
    beforeDestroy (callback) {
        if (this.$$destroyed) {
            this.$$.callbackService.safe(callback);
        } else {
            this.$$beforeDestroy.push(callback);
        }
    }

    /**
     * Registers a callback for when the slide animation is complete.
     *
     * @param {Function} callback
     */
    afterEnter (callback) {
        if (this.$$entered) {
            this.$$.callbackService.safe(callback);
        } else {
            this.$$afterEnter.push(callback);
        }
    }

    /**
     *
     * @param {String|Function} watchExpression
     * @returns {Function}
     */
    $$getExpressionWatcherFn (watchExpression) {
        let watcherFn;

        if (angular.isFunction(watchExpression)) {
            watcherFn = watchExpression;
        } else {
            let watcherExpression = this.$$parse(watchExpression);
            watcherFn = () => watcherExpression(this);
        }

        return watcherFn;
    }

    bindRouteParams (bindings, replaceHistory = false, callback) {
        let watchers = [],
            transformers = {
                read (value) { return value; },
                write (value) { return value; }
            };

        bindings.forEach((binding) => {
            let {key, searchParam} = binding;

            let watcher = this.$watch(key, handler.bind(this, binding), true);
            watchers.push(watcher);

            //if (key in this.$$routeParams) {
                let initialValue = this.$$routeParams[searchParam || key],
                    transform = binding.read || transformers.read;

                this[key] = transform(initialValue);
            //}
        });

        function handler (binding, value, previousValue) {
            let {key, searchParam} = binding;

            if (value !== previousValue) {
                let currentParam = this.$$location.search()[searchParam || key];

                if ( ! angular.equals(currentParam, value)) {
                    let transform = binding.write || transformers.write,
                        param = transform(value);

                    if (angular.isString(param) && ! param.length) {
                        param = null;
                    }

                    const currentSearch = this.$$location.search();

                    if (currentSearch[searchParam || key] != param) {
                        this.$$location.search(searchParam || key, param);
                        if (replaceHistory) {
                            this.$$location.replace();
                        }
                        this.$$location.ignore();
                    }
                }

                if (angular.isFunction(callback)) {
                    callback(key, value);
                }
            }
        }

        return () => {
            while (watchers.length) {
                watchers.shift()();
            }
        };
    }

    /**
     * Inject dependencies.
     *
     * @param {String[]} names
     * @param {Boolean} autoBind
     */
    $$dependencies (names = [], autoBind = false) {
        let deps = {};

        names.forEach((name) => {
            deps[name] = this.$$locals[name] || injectorInvoke.$injector.get(name);

            if (autoBind) {
                this[name] = deps[name];
            }
        });

        return deps;
    }

    /**
     * Creates a watcher that's context is set to the controller, not the $scope.
     *
     * @param {String|Function} watchExpression
     * @param {Function} listener
     * @param {Boolean} [objectEquality]
     * @returns {Function}
     */
    $watch (watchExpression, listener, objectEquality) {
        let watcherFn = this.$$getExpressionWatcherFn(watchExpression);
        return this.$$scope.$watch(watcherFn, listener, objectEquality);
    }

    /**
     *
     * @param {String|Function} obj
     * @param {Function} listener
     * @returns {Function}
     */
    $watchCollection (obj, listener) {
        let watcherFn = this.$$getExpressionWatcherFn(obj);
        return this.$$scope.$watchCollection(watcherFn, listener);
    }

    /**
     *
     * @param {String[]|Function[]} watchExpressions
     * @param {Function} listener
     * @returns {Function}
     */
    $watchGroup (watchExpressions, listener) {
        let watcherFns = watchExpressions.map((watchExpression) => {
            return this.$$getExpressionWatcherFn(watchExpression);
        });
        return this.$$scope.$watchGroup(watcherFns, listener);
    }

    /**
     * Closely watches an array of expressions.
     *
     * @param {*[]} watchExpressions
     * @param {Function} listener
     * @returns {Function}
     */
    $watchMany (watchExpressions, listener) {
        let hasBeenDestroyed = false,
            hasListenerScheduled = false,
            isFirstRun = true,
            oldValues = Array.apply(null, new Array(watchExpressions.length)),
            newValues = Array.apply(null, new Array(watchExpressions.length)),
            watchers = watchExpressions.map((watchExpression, index) => {
                return this.$watch(watchExpression, handleWatcherCallback.bind(this, index), true);
            });

        function handleWatcherCallback (index, newValue, oldValue) {
            newValues[index] = newValue;
            oldValues[index] = oldValue;

            if ( ! hasListenerScheduled) {
                hasListenerScheduled = true;
                this.$$scope.$evalAsync(doWatchManyListener.bind(this));
            }
        }

        function doWatchManyListener () {
            if ( ! hasBeenDestroyed) {
                hasListenerScheduled = false;
                listener(newValues, oldValues, isFirstRun, this);
                isFirstRun = false;
            }
        }

        return function destroyWatchMany () {
            hasBeenDestroyed = true;
            while (watchers.length) {
                watchers.shift()();
            }
        };
    }
}

BaseController.prototype.$$init.$inject = [
    '$route', '$parse', '$timeout', '$routeParams', '$location', 'callbackService'
];

window.BaseController = BaseController;
