/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.factory('PPReminder', function (PPModel) {
    let { dateTransformer } = PPModel;

    const PPReminderTransformer = {
        id: 'ReminderId',
        entityId: 'EntityId',
        action: 'Action',
        relativeDate: 'RelativeDate',
        relativeTo: 'RelativeTo',
        absoluteDate: dateTransformer('TriggerDate'),
    };

    class PPReminder extends PPModel {
        /**
         * Reminder id
         *
         * @type {String}
         */
        id = null;

        /**
         * Entity id reminder belongs to (can be proof /comment )
         *
         * @type {Number}
         */
        entityId = 0;

        /**
         * Action taken for the reminder
         *
         * @type {String}
         */
        action = null;

        /**
         * Action have to take before this time
         *
         * @type {Number}
         */
        relativeDate = null;

        /**
         * Reminders relative date can be relate to dueDate, create date
         *
         * @type {String}
         */
        relativeTo = null;

        /**
         * Reminder's trigger date
         *
         * @type {moment}
         */
        absoluteDate = null;

        /**
         * Update from the reminder data.
         *
         * @param {Object} data
         */
        updateFromReminderData (data) {
            this.transform(data, PPReminderTransformer);
        }


        /**
         * Create a reminder with a reminder object.
         *
         * @param {Object} proofCommentData
         */
        static from (reminderData) {
            let reminder = new this();
            reminder.updateFromReminderData(reminderData);
            return reminder;
        }
    }

    return PPReminder;
});
