/* Copyright (C) PageProof Holdings limited - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

'use strict';

angular
    .module('ppxGlobalControllers')
    .value('activePageConfig', {
        activeClass: 'active'
    })
    .directive('activePage', function ($rootScope, $location, activePageConfig) {
        return {
            restrict: 'A',
            scope: {
                url: '@activePage'
            },
            link: function (scope, element, attrs) {
                scope.update = function () {
                    var currentPath = $location.path().substring(1),
                        isActive = currentPath === scope.url;

                    console.log(currentPath, scope.url, isActive);

                    // Add or remove the active class based on whether we're on the specified page
                    // TODO Make this more efficient (don't do this add/remove each time the url changes)
                    element[isActive ? 'addClass' : 'removeClass'](activePageConfig.activeClass);
                };

                scope.update();

                scope.$on('$destroy', // unbind the event listener when the element is destroyed
                    $rootScope.$on('$locationChangeSuccess', scope.update));
            }
        };
    });
