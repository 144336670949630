/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.factory('PPUser', function (PPModel, PPAccountType) {
    let { dateTransformer } = PPModel;

    const userTransformer = {
        type: 'AccountType',
        teamType: 'TeamType',
        isActivated: 'Activated',
        country: 'Country',
        credits: 'Credits',
        email: 'Email',
        emailPreferences: 'EmailPreference',
        hasAvatar: 'HasAvatar',
        hasTOTP: 'TotpEnabled',
        industry: 'Industry',
        lastLogin: dateTransformer('LastLogin'),
        lastUpload: dateTransformer('LastUpload'),
        name: 'Name',
        registeredAt: dateTransformer('Registered'),
        id: 'UserId',
        features: 'Features',
        teamId: 'DomainId',
        teamName: 'TeamName',
        teamIsTrial: 'TeamIsTrial',
        teamIsPlus: 'TeamIsPlus',
    };

    const localUserTransformer = {
        type: 'accountType',
        teamType: 'teamType',
        isActivated: 'isActivated',
        isDomainAdmin: 'isDomainAdmin',
        features: 'features',
        lastLogin: dateTransformer('lastLogin'),
        lastUpload: dateTransformer('lastUpload'),
        email: 'userEmail',
        teamId: 'teamId',
        id: 'userId'
    };

    class PPUser extends PPModel {
        /**
         * The user unique identifier.
         *
         * @type {String}
         */
        id = null;

        /**
         * The users account type.
         *
         * 0 = Free
         * 1 = Premium
         *
         * @type {Number}
         */
        type = 0;

        /**
         * The user name.
         *
         * @type {String}
         */
        name = null;

        /**
         * The user email address.
         *
         * @type {String}
         */
        email = null;

        /**
         * The users email notification preferences (only applies to your own user object).
         *
         * @type {Object|null}
         */
        emailPreferences = null;

        /**
         * The users avatar (url).
         *
         * @type {String}
         */
        avatar = null;

        /**
         * The users credit count.
         *
         * @type {Number}
         */
        credits = 0;

        /**
         * The users selected country name.
         *
         * @example "New Zealand" or "United Kingdom"
         * @type {String}
         */
        country = null;

        /**
         * The users chosen industry.
         *
         * @type {String}
         */
        industry = null;

        /**
         * The users team id.
         *
         * @type {String}
         */
        teamId = null;

        /**
         * The users team name.
         *
         * @type {String}
         */
        teamName = null;

        /**
         * The users last login date time.
         *
         * @type {moment}
         */
        lastLogin = null;

        /**
         * The users last upload date time.
         *
         * @type {moment}
         */
        lastUpload = null;

        /**
         * The users registration date time.
         *
         * @type {moment}
         */
        registeredAt = null;

        /**
         * Whether the user has an avatar.
         *
         * @type {Boolean}
         */
        hasAvatar = false;

        /**
         * Whether the user has activated their account.
         *
         * @type {Boolean}
         */
        isActivated = false;

        /**
         * Whether the user has enabled MFA (Multi-factor authentication).
         *
         * @type {Boolean}
         */
        hasTOTP = false;

        /**
         * Whether the user is in charge of a domain subscription.
         *
         * @type {Boolean}
         */
        isDomainAdmin = false;

        /**
         * Features of a domain.
         *
         * @type {Object}
         */
        features = {};

        /**
         * The encryption object for the user.
         *
         * @type {PageProofEncryption}
         */
        encryptionData = null;

        /**
         * Whether the user is premium.
         *
         * @returns {Boolean}
         */
        get isPremium () {
            return this.type === PPAccountType.PREMIUM;
        }

        /**
         * Returns the initials for a user.
         *
         * @returns {String}
         */
        get initials () {
            if (this.name && this.name.length) {
                return this.name.charAt(0).toUpperCase();
            } else if (this.email && this.email.length) {
                return this.email.charAt(0).toUpperCase();
            } else {
                return '?';
            }
        }

        /**
         * Gets the users encryption object.
         *
         * @returns {PageProofEncryption}
         */
        getEncryptionData () {
            return this.encryptionData;
        }

        /**
         * If the user has a name, return the name, or if not, return email.
         *
         *
         */
        getDisplayName () {
            if (this.name) {
                return this.name
            } else {
                return this.email
            }
        }

        /**
         *
         * @param {Object} data
         */
        updateFromAvatarData (data) {
            let avatarBlob = dataURLToBlob('data:image/png;base64,' + data.Image);
            this.avatar = URL.createObjectURL(avatarBlob);
        }

        /**
         * Updates the user from a user data object.
         *
         * @param {Object} data
         */
        updateFromUserData (data) {
            this.transform(data, userTransformer);
        }

        /**
         * Updates the user from a localStorage user data object.
         *
         * @param {Object} data
         */
        updateFromLocalStorageData(data) {
            this.transform(data, localUserTransformer);
        }

        /**
         * Updates the user's encryption object.
         *
         * @param {PageProofEncryption} data
         */
        updateFromEncryptionData (data) {
            this.encryptionData = data;
        }

        /**
         * Creates a User object from a user data object.
         *
         * @param {Object} userData
         */
        static from(userData) {
            let user = new this();
            user.updateFromUserData(userData);
            return user;
        }
    }

    return PPUser;
});
