/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const AVATAR_URL_PREFIX = 'data:image/png;base64,';

app.factory('PPUserAvatar', function (browserService, PPModel) {
    const userAvatarTransformer = {
        hasAvatar: (data) => data.ResponseStatus === 'OK',
        isRegistered: 'IsRegistered',
        image: (data, model) => {
            if (data.Image) {
                let dataUrl = AVATAR_URL_PREFIX + data.Image;
                model._image = dataURLToBlob(dataUrl);

                if (browserService.is('ie')) {
                    return dataUrl;
                } else {
                    return URL.createObjectURL(model._image);
                }
            } else {
                return null;
            }
        },
        initials: (data) => getUserInitials(data.Initial)
    };

    class PPUserAvatar extends PPModel {
        /**
         * The prefix the base64 encoded avatars.
         *
         * @type {String}
         */
        static BASE64_PREFIX = AVATAR_URL_PREFIX;

        /**
         * Whether the user has an avatar to display.
         *
         * @type {Boolean}
         */
        hasAvatar = false;

        /**
         * Whether the user has registered.
         *
         * @type {Boolean}
         */
        isRegistered = false;

        /**
         * The avatar image url.
         *
         * @type {String|null}
         */
        image = null;

        /**
         * The avatar blob.
         *
         * @type {Blob}
         */
        _image = null;

        /**
         * The initials for the avatar to display as a fallback.
         *
         * @type {String}
         */
        initials = null;

        /**
         * Updates the user avatar from a user avatar object.
         *
         * @param {Object} data
         */
        updateFromUserAvatarData (data) {
            this.transform(data, userAvatarTransformer);
        }

        /**
         * Creates a PPUserAvatar object from a user avatar data object.
         *
         * @param {Object} userAvatarData
         */
        static from (userAvatarData) {
            let userAvatar = new this();
            userAvatar.updateFromUserAvatarData(userAvatarData);
            return userAvatar;
        }
    }

    return PPUserAvatar;
});
