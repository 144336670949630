/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
'use strict';

/* Controllers */

var ppxGlobalControllers = angular.module('ppxGlobalControllers', ['moment']);

angular
.module('ppxControllers')
.directive('ngMatch', [
    function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function ($scope, element, attrs, control) {
                $scope.$watch(
                    function () {
                        return $scope.$eval(attrs.ngModel) ===
                               $scope.$eval(attrs.ngMatch);
                    },
                    function (matches) {
                        control.$setValidity('matches', matches);
                    }
                );
            }
        };
    }
]);

ppxGlobalControllers.factory('NavigationService', ['$rootScope', '$timeout', function ($rootScope, $timeout) {
    var inProgress = false;

    /**
     * Returns a callback, which, when invoked, accepts another callback, which
     * will be invoked after the $globalScope's slideClass has been changed.
     *
     * @param {String} slideClass
     * @returns {Function}
     */
    function go (slideClass) {
        return function (callback, args) {
            // Tell the service that the next navigation has been programmatically invoked
            inProgress = true;

            // Set the $globalScope's slideClass
            $rootScope.slideClass = slideClass;

            // Invoke the callback (if provided, and if a function)
            if (typeof callback === 'function') {
                callback.call(null, args);
            }

            // Make sure that the $globalScope updates all bindings to slideClass
            if ( ! $rootScope.$$phase) $rootScope.$apply();
        };
    }

    /**
     * Wraps a service method, allowing a method to alias as another method.
     *
     * @param {String} method
     * @returns {Function}
     */
    function wrap (method) {
        return function (/* callback */) {
            var that = this,
                args = [].slice.apply(arguments);

            return function () {
                // Invoke the method, including the arguments from the previous
                // call to the wrapped method.
                that[method].apply(that, args.concat([].slice.apply(arguments)));
            };
        };
    }

    var service = {
        /**
         * Triggers the service to set the state of the $globalScope's slideClass
         * property, which is used by ngAnimate as the animation type.
         *
         * Accepts a callback, which is invoked after the $globalScope's slideClass
         * has been set, which is perfect time to redirect the user.
         *
         * @example
         *     NavigationService.back(function () {
         *         $location.path('dashboard');
         *     });
         *
         * @param {Function} callback
         */
        back: go('slideBack'),

        /**
         * Does the same as NavigationService.back, but sets the slideClass to the
         * animation type which pushes controllers in a different direction.
         *
         * @see NavigationService.back
         * @param {Function} callback
         */
        forward: go('slide'),

        /**
         * Manually sets the $globalScope's slideClass, and optionally accepts a
         * callback, which (much like NavigationService.back) invokes the callback
         * after everything else has been set.
         *
         * @param {String} slideClass
         * @param {Function} [callback]
         */
        go: function (slideClass, callback) {
            go(slideClass)(callback);
        },

        /**
         * An alias of the back method, providing a way of creating a closure for
         * using for $scope helpers.
         *
         * @example
         *     $scope.back = NavigationService.wrapBack(function () {
         *         $location.path('dashboard');
         *     });
         *
         * @param {Function} callback
         */
        wrapBack: wrap('back'),

        /**
         * Does the same as NavigationService.wrapBack, but sets the slideClass to
         * the animation type which pushes controllers in a different direction.
         *
         * @see NavigationService.wrapBack
         * @param {Function} callback
         */
        wrapForward: wrap('forward')
    };

    $rootScope.$on('$routeChangeStart', function (event) {
        // If the event has been fired due to a method being called from the service
        // default the animation direction to the right (forward).
        if ( ! inProgress) {
            service.forward();
        }

        // Reset the 'inProgress' flag to false, to prevent circling round and round
        inProgress = false;

        $timeout(function () {
            // Reset the slideClass back to the original (this should be cleaned up
            // when the $timeout dependency is replaced with a better solution).
            $rootScope.slideClass = 'slide';
        }, 1e3 + 100);
    });

    return service;
}]);

ppxGlobalControllers.run(['NavigationService', function (NavigationService) {
    // Make sure that the service gets loaded with the module
}]);


ppxGlobalControllers.factory('Map', function($map) {
    return $map;
});

ppxGlobalControllers.factory('$map', function () {
    function $map () {
        this._keys = [];
        this._values = [];
    }

    $map.equals = deprecated(function $map__equals (arg1, arg2) {
        return angular.equals.apply(this, arguments);
    }, 'Please use `angular.equals(arg1, arg2)` instead.');

    $map.create = function () {
        return new $map;
    };

    $map.create.equals = $map.equals;

    $map.prototype = {
        constructor: $map,

        set: function (key, value) {
            var index = this.index(key);

            if (index === -1) {
                this._keys.push(key);
                this._values.push(value);
            } else {
                this._values[index] = value;
            }
        },
        get: function (key) {
            var index;

            if ((index = this.index(key)) > -1) {
                return this.values()[index];
            }
        },
        index: function (key) {
            var match = -1, check;

            this.keys().forEach(function (item, index) {
                if (match === -1 && (check = angular.equals(item, key))) {
                    match = index;
                }
            });

            return match;
        },
        has: function (key) {
            return this.index(key) > -1;
        },
        remove: function (key) {
            var index;

            if ((index = this.index(key)) > -1) {
                var value = this._values[index];

                this._keys.splice(index, 1);
                this._values.splice(index, 1);

                return value;
            }
        },
        keys: function () {
            return this._keys;
        },
        values: function () {
            return this._values;
        }
    };

    return $map.create;
});

ppxGlobalControllers.factory('$cacheRegistry',
    function ($q, $map, $rootScope) {
        return function (callback) {
            var items = $map(),
                scope = $rootScope.$new(false),
                registry;

            $(window).on('userlogout.pageproof', function () {
                console.debug('Caught the logout event (clearing registry).');
                // Clears out any items in the registry which were rejected due to the user not being
                // logged into PageProof with an active session.
                registry.clear();
            });

            return registry = {
                /**
                 * Manually runs the registry's callback, in order to load an item.
                 *
                 * @param {*} info
                 * @returns {$q.deferred.promise}
                 */
                load: function (info) {
                    var deferred = $q.defer();
                    callback(info, deferred.resolve, deferred.reject);

                    return deferred.promise;
                },
                /**
                 * Sets the internal reference to a specific item.
                 *
                 * @param {*} info
                 * @param {*} data
                 */
                set: function (info, data) {
                    var original = data,
                        isUpdate = items.has(info);

                    // Wrap the object in a promise, to make sure that we're always returning a promise
                    // from the `get` function - this means you can `set` to anything.
                    data = $q(function (resolve, reject) {
                        resolve(original);
                    });

                    if (isUpdate) {
                        // If there is already a deferred object which is resolved, pass through the updated
                        // data, which should propagate through to existing code references.
                        items.get(info).then(function () {
                            return data;
                        });
                    }

                    // Sets the internal reference to the item within the $map
                    items.set(info, data);

                    // Trigger an update event to notify any listeners of a change
                    this.emit(isUpdate ? 'update' : 'init', info, original);
                },
                keys: function () {
                    return items.keys();
                },
                remove: function (info) {
                    items.remove(info);
                    this.emit('remove', info);
                },
                update: function (info) {
                    this.set(info, this.load(info));
                },
                clear: function () {
                    var that = this;
                    angular.forEach(this.keys(), function (info) {
                        that.remove(info);
                    });
                },
                /**
                 * Gets (and potentially fetches) an item from the repository.
                 *
                 * If the second argument is provided, then you can manually trigger the
                 * repository to re-fetch (update) the reference it has locally.
                 *
                 * @param {*} info
                 * @param {Boolean} [update]
                 * @returns {*}
                 */
                get: function (info, update) {
                    if ( ! angular.isDefined(update)) {
                        update = false;
                    }

                    // If you wish to update the repository's reference...
                    // OR if there is no item within the repository yet...
                    if (update === true || ! items.has(info)) {
                        this.update(info);
                    }

                    return items.get(info);
                },
                /**
                 * Emits an event within the $cacheRegistry's scope.
                 *
                 * @param {String} event
                 * @param {*} info
                 * @param {*} [data]
                 */
                emit: function (event, info, data) {
                    scope.$emit(event, {
                        info: info,
                        data: data
                    });
                },
                /**
                 * Registers an event listener within the $cacheRegistry's scope.
                 *
                 * Returns the function that un-binds the event listener.
                 *
                 * @see {$rootScope.Scope#on}
                 * @param {String} event
                 * @param {*} info
                 * @param {Function} callback
                 * @returns {Function}
                 */
                on: function (event, info, callback) {
                    return scope.$on(event, function (event, meta) {
                        if (angular.equals(meta.info, info)) {
                            callback(meta.data);
                        }
                    });
                }
            };
        };
    }
);

ppxGlobalControllers.factory('cacheFactory', function ($cacheRegistry) {
    return $cacheRegistry;
});

ppxGlobalControllers.factory('$userRepository', [

    '$cacheRegistry', 'UserService',
    function ($cacheRegistry, UserService) {
        var users = new Users(UserService);
        return $cacheRegistry(function (userId, resolve, reject) {
            if(userId) {
                users.Load(userId, function (response) {
                    resolve(jsonDecode(response.target.responseText));
                });
            } else {
                reject(Error('No user id provided to $userRepository.'));
            }
        });
    }

]);

ppxGlobalControllers.directive('ppAvatar', [
    '$rootScope',
    function ($rootScope) {
        return {
            restrict: 'E',
            scope: {
                userId: '@',
                proofId: '@',
                workflowStep: '=',
                workflowStepCallback: '&',
                size: '@',
                mandatory: '@',
                nudged: '@',
                admin: '@',
                approver: '@',
                finalApprover: '@',
                disableStatus: '=',
                showNameAndEmail: '=',
            },
            transclude: true,
            templateUrl: 'templates/partials/globals/avatar.html',
            link: function ($scope, $element, $attrs) {
                $scope.vm = $scope;
                var overlay = null;

                $scope.getWorkflowStep = function(){
                    return $scope.workflowStepCallback() || $scope.workflowStep;
                };

                $scope.avatarSizePx = function() {
                    switch ($scope.size) {
                        case 's': return 40;
                        case 'xs': return 30;
                        case 'm': return 100;
                        case 'l': return 150;
                    }
                    return 60;
                };

                $scope.reset = function () {
                    $scope.loading = false;
                    $scope.avatar = null;
                    $scope.showUserDetails = false;
                };

                $scope.showUserDetails = true;

                $scope.hideOptions = function() {
                    $scope.revealOptions = false;
                    $scope.removeOverlay();
                };

                $scope.openUserDetails = function () {
                    if( ! $scope.proofId) return;
                    if (window.__pageproof_ctrl__.appCtrl.isMobile) {
                        $scope.revealOptions = !$scope.revealOptions;
                        if ($scope.revealOptions) {
                            $scope.addOverlay();
                        }
                        return;
                    }
                    $rootScope.$broadcast('openUserDetails', {proofId: $scope.proofId, userId: $scope.userId, workflowStep: $scope.getWorkflowStep()});
                };

                $scope.removeOverlay = function() {
                    if (overlay) {
                        overlay.remove();
                        overlay = null;
                    }
                };

                $scope.addOverlay = function() {
                    $scope.removeOverlay();
                    overlay = document.createElement('div');
                    var avatarOptionsElem = $element.find('.avatar-options');

                    if (avatarOptionsElem) {
                        var optionsPosition = avatarOptionsElem.get(0).getBoundingClientRect();
                        [
                            {
                                top: 0,
                                left: 0,
                                right: 0,
                                height: Math.round(optionsPosition.top) + 20 + 'px', // 20px for arrow margin
                            },
                            {
                                top: Math.round(optionsPosition.top + optionsPosition.height + 20) + 'px',
                                left: 0,
                                right: 0,
                                bottom: 0,
                            },
                            {
                                top: 0,
                                left: 0,
                                bottom: 0,
                                width: Math.round(optionsPosition.left) + 'px',
                            },
                            {
                                top: 0,
                                left: Math.round(optionsPosition.left + optionsPosition.width) + 'px',
                                right: 0,
                                bottom: 0,
                            },
                        ].forEach(function(styles) {
                            var overlayChildDiv = document.createElement('div');
                            Object.assign(overlayChildDiv.style, {zIndex: 999, position: 'absolute'}, styles);

                            overlayChildDiv.addEventListener('click', function onClickOverlay() {
                                $scope.hideOptions();
                                if (!$scope.$$phase) $scope.$apply();
                            }, false);

                            overlay.append(overlayChildDiv);
                        });
                    }
                    document.body.appendChild(overlay);
                };

                $scope.reset();
            }
        };
    }

]);

ppxGlobalControllers.directive('ppHelpPane', [

    '$timeout', '$q', 'UserService',
    function ($timeout, $q, application) {
        return {
            restrict: 'E',
            scope: {
                page: '@'
            },
            transclude: true,
            templateUrl: 'templates/partials/globals/help-pane.html',
            link: function ($scope, $element, $attrs) {

                $scope.storage = application.storage;

                var pageData = undefined;
                var storageKey = 'help-' + $scope.page;

                if(isset($scope.page)) pageData = $scope.storage.getJSON(storageKey);

                if(pageData != undefined && pageData.hidden == true){
                    $scope.showHelpPanel = false;
                    $scope.showLightBulb = true;
                }else{
                    $scope.showHelpPanel = true;
                    $scope.showLightBulb = false;
                }

                $scope.hideHelpPanel = function(){
                    $scope.showHelpPanel = false;
                    $scope.showLightBulb = true;
                    $scope.storage.setJSON(storageKey, {hidden:true});
                };

                $scope.unhideHelpPanel = function(){
                    $scope.showHelpPanel = true;
                    $scope.showLightBulb = false;
                    $scope.storage.setJSON(storageKey, {hidden:false});
                };
            }
        };
    }

]);

ppxGlobalControllers.directive('ppDropDown', [
    '$filter',

    function ($filter) {

        function _ ($scope) {
            if ( ! $scope.$$phase && ! $scope.$root.$$phase) {
                $scope.$apply();
            }
        }

        return {
            restrict: 'E',
            scope: {
                items: '=itemsDropdown',
                defaultViewItem: '=defaultViewItem',
                title: '@title',
                selectedItem: '=selectedItem',
                onDropdownChange: '=onDropdownChange'
            },
            templateUrl: 'templates/partials/globals/dropdown.html',
            link: function ($scope, $element, $attrs) {

                $scope.isOnDrop = false;
                $scope.viewItem = null;

                $scope.workflowCustomOrder = function(workflowItems){
                        switch (workflowItems.title) {
                            case 'Create new workflow':
                                return 2;

                            case 'Use current workflow':
                                return 1;

                            default:
                                return workflowItems.title;
                        }
                };

                $scope.toggleViewItem = function (id, toggle){
                    if($scope.items.length > 0){
                        var i = 0;
                        while(i < $scope.items.length){
                            if($scope.items[i]["id"] == id){
                                $scope.items[i]["canShow"] = toggle;
                                break;
                            }
                            i++;
                        }
                    }
                    return true;
                };

                $scope.toggleDefaultViewItem = function (toggle){
                    if($scope.items.length > 0){
                        var i = 0;
                        while(i < $scope.items.length){
                            if($scope.items[i]["id"] == "defaultViewItem"){
                                $scope.items[i]["canShow"] = toggle;
                                break;
                            }
                            i++;
                        }
                    }
                    return true;
                };

                $scope.getViewItem = function (id){
                    if($scope.items.length > 0){
                        var i = 0;
                        while(i < $scope.items.length){
                            if($scope.items[i]["id"] == id){
                                return $scope.items[i]["title"];
                            }
                            i++;
                        }
                        return "";
                    }
                };

                $scope.reset = function () {
                    $scope.items = [];
                    $scope.defaultViewItem = "";
                    $scope.title = "";
                    $scope.selectedId = "";
                    $scope.onDropdownChange = null;
                    $scope.isOnDrop = false;
                    $scope.viewItem = null;
                };

                $scope.itemSelected = function(id) {

                    $("#view-loader").removeClass("is-active");

                    if(isset($scope.selectedId)){
                        $scope.toggleViewItem($scope.selectedId, true); //put the old item back in the list
                    }

                    if(id != "defaultViewItem") {
                        $scope.selectedId = id;
                        $scope.viewItem = $scope.getViewItem(id);
                        $scope.toggleViewItem(id, false);
                        $scope.toggleDefaultViewItem(true); //put the default in the list
                        if($scope.onDropdownChange != null) $scope.onDropdownChange($scope.selectedId);
                        _($scope);
                    }else{
                        $scope.viewItem = $scope.defaultViewItem;
                        $scope.onDropdownChange("default");
                        $scope.toggleDefaultViewItem(false);
                        _($scope);
                    }
                };

                $scope.canCloseDrop = function(){
                    if($scope.isOnDrop == false) $("#view-loader").removeClass("is-active");
                };

                $scope.toggleDropDown = function(active){
                    if(active == false){
                        $scope.isOnDrop = false;
                        $("#view-loader").removeClass("is-active");
                    }else{
                        $("#view-loader").addClass("is-active");
                    }

                    $scope.search = "";

                    $("#select_text").focus();

                };

                $scope.hasEntered = function () {
                    $scope.isOnDrop = true;
                };

                $scope.$watch('items', function () {
                    $scope.items.push({id:"defaultViewItem", canShow:false, title:$scope.defaultViewItem});
                    $scope.viewItem = $scope.defaultViewItem;
                });

                $scope.$watch('selectedItem', function () {

                    if($scope.selectedItem != "" && typeof $scope.selectedItem != 'undefined'){
                        $scope.selectedId = $scope.selectedItem;
                        $scope.viewItem = $scope.getViewItem($scope.selectedItem);
                        $scope.toggleViewItem($scope.selectedItem, false);
                        $scope.toggleDefaultViewItem(true); //put the default in the list
                        if($scope.onDropdownChange != null) $scope.onDropdownChange($scope.selectedId);
                        _($scope);
                    }
                });

            }
        };
    }

]);

ppxGlobalControllers.factory('uiService', [

    function () {
        var tmp = document.createElement('div');

        return {
            transformRule: (function () {
                var rules = ['MozTransform', 'webkitTransform', 'MsTransform', 'msTransform'];

                return rules.reduce(function (current, rule) {
                        return current || (typeof tmp.style[rule] !== 'undefined' ? rule : current);
                    }, null) || 'transform';
            })(),
            matchesSelector: (function () {
                var funcs = [
                    'matches', 'webkitMatches', 'mozMatches', 'msMatches',
                    'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector'
                ];

                return funcs.reduce(function (current, func) {
                        return current || (typeof tmp[func] === 'function' ? func : current);
                    }, null) || 'matchesSelector';
            })(),

            parseTranslate: function (translate) {
                var x = 0, y = 0, z = 0;

                var _3d = (/translate3d\((-?\w+)px?,\s+?(-?\w+)px?,\s+?(-?\w+(px)?)\)/).exec(translate);
                if (_3d) {
                    x = parseFloat(_3d[1]);
                    y = parseFloat(_3d[2]);
                    z = parseFloat(_3d[3]);
                }

                return { x: x, y: y, z: z };
            }
        };
    }

]);

//
ppxGlobalControllers.controller('global_proofInfo', function($scope, $routeParams, $location, $sce, UserService, DataService, dateService, proofInfoService, domService, SegmentIo, $timeout, $addressBook, browserService, apiService, $q, $rootScope, $api, PPDashboardProof, userRepositoryService, PPProofWorkflowStep, backendService, PPProofStatus, proofRepositoryService, PPProofType, sdk, userService, storageService, features, seoService) {

    /*
    REMINDER
    Please note: when adding any new variables to this controller, they have to be reset back to there default value in the resetVars() method below. Stuffs will go kaput otherwise.
    */
    $scope.proofInfoCtrl = $scope;
    $scope.application = UserService;
    $scope.data = DataService;

    $scope.workFlow = new WorkFlow($scope.application); //setup the workflow object
    $scope.proof = new Proof($scope.application);
    $scope.users = new Users($scope.application); //setup the users object
    $scope.tag = new Tag($scope.application);

    $scope.userData = "";

    $scope.email = "";
    $scope.userId = "";

    $scope.proofId = "";
    $scope.workflowId = "";
    $scope.proofOwnerUserId = "";
    $scope.proofOwnerIsBot = false;
    $scope.numberOfPages = "";
    $scope.numberOfPagesStr = "";
    $scope.fileId = "";
    $scope.tags = [];

    $scope.showWorkFlows = false;
    $scope.proofOwnerEmail = "";
    $scope.proofInfoCtrl.prooferMessage = "";
    $scope.editingProoferMessage = false;
    $scope.proofThumDataUrl = "img/interface/no-thumbnail.gif";
    $scope.showThumb = true;
    $scope.workflowAlert = false;
    $scope.dueDate = "";

    $scope.workflowData = {};

    $scope.avatars = [];

    $scope.isProofArchived = false;

    $scope.isLoaded = false;

    $scope.ProofId = "";

    $scope.isOwned = false;
    $scope.isCoOwner = false;
    $scope.isAdmin = false;

    $scope.proofOwnerCssLoading = true;
    $scope.proofOwnerAvatar = "";

    $scope.showEditors = true;

    $scope.versionNumber = 1;

    $scope.commentCountTotal = 0;
    $scope.todoCommentCount = 0;
    $scope.dueDateDisplayClass = "";
    $scope.dueDateDisplay = "";

    $scope.fileStatus = "";
    $scope.isInProcessing = false;
    $scope.wfStructure = "";
    $scope.statusText = "";

    $scope.stepCount = 0;

    $scope.showNoWorkflowMessage = false;
    $scope.showStartWorkflowMessage = false;
    $scope.showFileErrorMessage = false;

    $scope.showWorkflows = false;

    $scope.finalApproverStep = false;
    $scope.finishedCount = 0;
    $scope.skippedCount = 0;
    $scope.viewCount = 0;
    $scope.decisionSummaryRef = {current: null};

    $scope.finalApproverStage = false;

    $scope.canDeleteCurrentProof = false;
    $scope.canDeleteAllVersions = false;

    $scope.pleaseWaitDeletingProof = false;
    $scope.pleaseWaitDeletingAllVersions = false;

    $scope.deleteInProgress = false;

    $scope.showLoader = false;

    $scope.proofClosed = false;

    $scope.numberOfVersions = 1;
    $scope.showOwnedReadOnly = false;

    $scope.overlay = "yellow";
    $scope.statusCode = 0;

    $scope.isBaseVersion = false;

    $scope.showCloseBtn = false;
    $scope.isLatestVersion = false;

    $scope.latestProofId = "";
    $scope.previousProofId = "";

    $scope.showDashBtn = false;
    $scope.showProofPane = true;

    $scope.showEditorReplaceInput = false;

    $scope.showMetaReadOnly = false;

    $scope.showFileProcessingMessage = false;
    $scope.showAwaitingEmailMessage = false;
    $scope.showAwaitingFileMessage = false;

    $scope.percentage = "";
    $scope.loadingBar = false;

    $scope.startBtnTxt = "start";

    $scope.newAdmin = "";
    $scope.admins = [];
    $scope.adminError = "Please enter a valid email address";

    $scope.showNewCoOwner = false;
    $scope.coOwners = [];
    $scope.owners = [];
    $scope.editors = [];
    $scope.showCoOwnerError = false;
    $scope.coOwnerError = "invalid-email";

    $scope.from = "";

    $scope.hasIntegrationError = false;
    $scope.integrationError = { provider: '', message: '' };

    $scope.isLocked = false;

    $scope.hasOriginal = false;
    $scope.downloadBtnStatus = false;

    $scope.showRemovedMessage = false;
    $scope.showAccessDeniedMessage = false;
    $scope.showAccessRestrictedMessage = false;

    $scope.approvers = [];

    $scope.approvedDate = null;

    $scope.canManage = false;

    $scope.proofManageMode = false;
    $scope.isOpenProofManage = false;

    $scope.proofManageActions = {
        return: false,
        archive: false,
        delete: false,
        deleted: false,
        revert: false,
        disabled: false,
        approve: false,
        returnTodos: false,
        disabledConfirm: false,
        sendToEditor: false,
        reUploadFile: false,
        addChecklist: false,
    };

    $scope.isOverDue = false;
    $scope.hoursToDueDate = 24;

    $scope.proofManageConfirm = $scope.proofManageActions;

    $scope.adminsPromise = null;

    $scope.showTags = false;
    $scope.showReference = false;
    $scope.showAllOwners = false;
    $scope.additionalOwners = [];
    $scope.additionalEditors = [];

    $scope.type = 'proof';
    $scope.showNudgeAll = false;

    $scope.$proof = {};
    $scope.finalCompleteStepId = '';
    $scope.currentStepId = null;
    $scope.showDatesDetails = false;

    $scope.isTeamAdmin = false;

    $scope.dateTimeValue = null;

    $scope.emailAddressTip = "proof-manage.email.copy.tip";
    $scope.copyToClipboardText = "button.copy-to-clipboard";

    $scope.generatedEmail = null;

    $scope.showWorkflowDetails = false;

    $scope.proofTeamType = 0;

    $scope.resetVars = function(){
        $scope.proofId = "";
        $scope.workflowId = "";
        $scope.proofOwnerUserId = "";
        $scope.proofOwnerIsBot = false;
        $scope.numberOfPages = "";
        $scope.numberOfPagesStr = "";
        $scope.fileId = "";
        $scope.tags = [];

        $scope.showWorkFlows = false;
        $scope.proofOwnerEmail = "";
        $scope.proofInfoCtrl.prooferMessage = "";
        $scope.editingProoferMessage = false;
        $scope.proofThumDataUrl = "img/interface/no-thumbnail.gif";
        $scope.showThumb = true;
        $scope.workflowAlert = false;
        $scope.dueDate = "";

        $scope.workflowData = [];

        $scope.generatedEmail = null;

        $scope.isLoaded = false;
        $scope.isOwned = false;
        $scope.isCoOwner = false;
        $scope.isAdmin = false;

        $scope.versionNumber = 1;

        $scope.proofOwnerAvatar = "";

        $scope.commentCountTotal = 0;
        $scope.todoCommentCount = 0;
        $scope.dueDateDisplayClass = "";
        $scope.dueDateDisplay = "";
        $scope.fileStatus = "";
        $scope.isInProcessing = false;
        $scope.orginalWorkflowId = "";
        $scope.avatars = [];
        $scope.statusText = "";

        $scope.wfStructure = "";

        $scope.stepCount = 0;
        $scope.processingClose = false;
        $scope.showLoader = false;

        $scope.finalApproverStep = false;
        $scope.finishedCount = 0;
        $scope.skippedCount = 0;
        $scope.viewCount = 0;
        $scope.decisionSummaryRef = {current: null};
        $scope.finalApproverStepData = undefined;


        $scope.showEditors = true;

        $scope.proofClosed = false;

        $scope.showNoWorkflowMessage = false;
        $scope.showStartWorkflowMessage = false;
        $scope.showAwaitingEmailMessage = false;
        $scope.showAwaitingFileMessage = false;

        $scope.showWorkflows = false;

        $scope.overlay = "yellow";
        $scope.statusCode = 0;

        $scope.numberOfVersions = 1;

        $scope.baseProofId = "";

        $scope.finalApproverStage = false;

        $scope.canDeleteCurrentProof = false;
        $scope.canDeleteAllVersions = false;

        $scope.pleaseWaitDeletingProof = false;
        $scope.pleaseWaitDeletingAllVersions = false;

        $scope.deleteInProgress = false;

        $scope.isBaseVersion = true;

        $scope.showLoader = false;

        $scope.proofClosed = false;

        $scope.showOwnedReadOnly = false;
        $scope.showCloseBtn = false;

        $scope.isLatestVersion = false;
        $scope.latestProofId = "";
        $scope.previousProofId = "";

        $scope.showDashBtn = false;
        $scope.showProofPane = true;


        $scope.showEditorReplaceInput = false;

        $scope.showFileErrorMessage = false;

        $scope.showMetaReadOnly = false;

        $scope.showFileProcessingMessage = false;

        $scope.percentage = "";

        $scope.loadingBar = false;

        $scope.startBtnTxt = "start";

        $scope.newAdmin = "";
        $scope.admins = [];
        $scope.adminError = "Please enter a valid email address";

        $scope.showNewCoOwner = false;
        $scope.coOwners = [];
        $scope.owners = [];
        $scope.editors = [];
        $scope.showCoOwnerError = false;
        $scope.coOwnerError = "invalid-email";

        $scope.from = "";

        $scope.hasIntegrationError = false;
        $scope.integrationError = { provider: '', message: '' };

        $scope.isLocked = false;

        $scope.scopeVars = {
            newCoOwner: "",
            newAdmin: "",
            newEditorEmail: "",
            editorToBeReplaced: "",
            todoRevertMessage: "",
            approvalRevertMessage: "",
            manualApprovalMessage: "",
        };

        $scope.hasOriginal = false;
        $scope.downloadBtnStatus = false;

        $scope.showRemovedMessage = false;
        $scope.showAccessDeniedMessage = false;
        $scope.showAccessRestrictedMessage = false;

        $scope.approvers = [];

        $scope.approvedDate = null;

        $scope.canManage = false;

        $scope.proofManageMode = false;
        $scope.isOpenProofManage = false;

        $scope.proofManageActions = {
            return: false,
            archive: false,
            delete: false,
            deleted: false,
            revert: false,
            disabled: false,
            approve: false,
            returnTodos: false,
            disabledConfirm: false,
            sendToEditor: false,
            reUploadFile: false,
            addChecklist: false,
        };

        $scope.isOverDue = false;
        $scope.hoursToDueDate = 24;

        $scope.proofManageConfirm = $scope.proofManageActions;

        $scope.pendingToggleUserInviter = {};

        $scope.adminsPromise = null;

        $scope.proofInfoCtrl.props = {};

        $scope.showTags = false;
        $scope.showReference = false;
        $scope.showAllOwners = false;
        $scope.additionalOwners = [];
        $scope.additionalEditors = [];

        $scope.type = 'proof';
        $scope.showNudgeAll = false;

        $scope.$proof = {};
        $scope.finalCompleteStepId = '';
        $scope.currentStepId = null;
        $scope.showDatesDetails = false;

        $scope.isTeamAdmin = false;

        $scope.dateTimeValue = null;

        $scope.emailAddressTip = "proof-manage.email.copy.tip";
        $scope.copyToClipboardText = "button.copy-to-clipboard";

        $scope.showWorkflowDetails = false;

        $scope.proofTeamType = 0;

        $scope.isSafari = browserService.is('safari')
    };

    $scope.convertLegacyWorkflowStep = function(legacyWorkflowStep){
        var workflowStep = new PPProofWorkflowStep();
        workflowStep.stepId = legacyWorkflowStep.stepId;
        workflowStep.isComplete = ((legacyWorkflowStep.isComplete === 1) || (legacyWorkflowStep.Complete === 1)); //.Complete is for last workflowStep
        workflowStep.isVisible = ((legacyWorkflowStep.isVisible === 1) || (legacyWorkflowStep.Visible === 1)); //.Visible is for last workflowStep
        return workflowStep;
    };

    $scope.showAdditionalOwners = function() {
        $scope.showAllOwners = true;
    };

    $scope.isInAdmin = function(email, returnData){
        var found = null;
        $scope.admins.forEach(function(value, index){
            if(value["email"] == email){
                if(returnData){
                    found = value;
                }else{
                    found = true;
                }
            }
        });
        return found;
    };

    $scope.isApprover = function(userId, returnData){
        var found = null;
        $scope.approvers.forEach(function(value, index){
            if(value["userId"] == userId){
                if(returnData){
                    found = value;
                }else{
                    found = true;
                }
            }
        });
        return found;
    };

    $scope.pendingToggleUserInviter = {};

    $scope.isOwner = function (userId) {
        return $scope.proofOwnerUserId === userId;
    };

    $scope.isOwnerOrCoOwner = function (userId) {
        return $scope.isOwner(userId) || $scope.isCoOwnerByUserId(userId);
    };

    $scope.$watch("scopeVars.newAdmin", function(){
        if(isset($scope.scopeVars.newAdmin)) {
            $scope.addNewAdmin();
        }
    });

    $scope.addNewAdmin = function(){

        $scope.newAdmin = $scope.scopeVars.newAdmin.toLowerCase();

        if(!validateEmail($scope.newAdmin)){
            $scope.adminError = "Please enter a valid email address";
            if (!$scope.$$phase) $scope.$apply();
            return "";
        }

        if($scope.isInAdmin($scope.newAdmin)){
            $scope.adminError = "This person is already a manager";
            if (!$scope.$$phase) $scope.$apply();
            return "";
        }

        if($scope.isInCoOwner($scope.newAdmin)){
            $scope.adminError = "This person is an Owner";
            if (!$scope.$$phase) $scope.$apply();
            return "";
        }

        if($scope.newAdmin == $scope.proofOwnerEmail){
            if($scope.proofOwnerEmail == $scope.email){
                $scope.adminError = "You are already the owner";
            }else{
                $scope.adminError = $scope.proofOwnerEmail + " is already the owner";
            }
            if (!$scope.$$phase) $scope.$apply();
            return "";
        }

        $scope.workFlow.AddAdmin($scope.workflowId, $scope.newAdmin, "WorkflowInviter", function(returnData, userId){

            SegmentIo.track(33, {
                'workflow id': $scope.workflowId,
                'user id': userId
            }); //33:{name:'Workflow Manager Added'},

            $scope.addToAdmins({email:$scope.newAdmin, memberId:returnData["MemberId"], userId:userId, isActive:true, showRemove:true, showMenu:true});
            $scope.scopeVars.newAdmin = "";
            $scope.newAdmin = "";
            if (!$scope.$$phase) $scope.$apply();
        });
    };

    $scope.addToAdmins = function(data){
        $scope.admins.push(data);
        if (!$scope.$$phase) $scope.$apply();
    };

    $scope.loadCachedAdmins = function() {
	    if (!$scope.adminsPromise) {
		    $scope.adminsPromise = apiService
			    .workflows.teamGetList
			    .fetch($scope.workflowId)
	    }
		return $scope.adminsPromise;
    };

    //proof co - onwers

    $scope.openNewCoOwnerInput = function(){
        if($scope.showNewCoOwner == true){
            $scope.showNewCoOwner = false;
            $scope.showCoOwnerError = false;
            $scope.scopeVars.newCoOwner = "";
        }else{
            $scope.showNewCoOwner = true;
            $timeout(function(){$("#newCoOwnerVal").focus(); }, 300);
        }
    };

    $scope.viewProfile = function(userId) {
        $rootScope.$broadcast('openUserDetails', {proofId: $scope.proofId, userId: userId});
    };

    $scope.removeCoOwnerOrOwner = function(userId, isOriginalOwner) {
        sdk.proofs.owners.remove($scope.proofId, {id: userId})
            .then(function(returnData) {
                if(returnData.data.ResponseStatus === $scope.application.api.OK) {

                    if (userId === $scope.userId) {
                        $scope.gotoDash();
                    }

                    var indx = -1;
                    $scope.coOwners.forEach(function (value, index) {
                        var userIdToSplice = isOriginalOwner ? $scope.userId : userId;
                        if (value["userId"] === userIdToSplice) {
                            indx = index;
                        }
                    });

                    if (indx > -1) {
                        $scope.coOwners.splice(indx, 1);
                    }

                    $scope.updateAdditionalOwners();
                    if (!$scope.$$phase) $scope.$apply();

                    if (isOriginalOwner) { // if removing original owner
                        $scope.proofOwnerUserId = $scope.userId;
                        $scope.setOwnerEmailById($scope.proofOwnerUserId);
                        $scope.isCoOwner = false;
                        $scope.isOwned = true;
                    }
                }
            });
    };

    $scope.hasNoTodos = function(workflowStep) {
        return (
            workflowStep.isComplete && $scope.statusCode !== 50
        );
    }

    $scope.hasTodos = function(workflowStep) {
        return (
            workflowStep.isComplete && $scope.statusCode === 50 && workflowStep.stepPosition !== $scope.stepCount
        );
    }

    $scope.isInCoOwner = function(email){
        return $scope.coOwners.some(function (user){
            return user.email === email;
        });
    };

    $scope.isInEditors = function(email){
        return $scope.editors.some(function (user){
            return user.email === email;
        });
    };

    $scope.isCoOwnerByUserId = function (userId) {
        return $scope.coOwners.some(function (user){
            return user.userId === userId;
        });
    };

    $scope.isEditorLoggedIn = function (userId) {
        return $scope.editors.some(function (user){
            return user.userId === userId;
        });
    };

    $scope.removeEditorFromEditors = function(idOrEmail) {
        $scope.editors.forEach(function(value, index) {
            if (value["userId"] === idOrEmail || value["email"] === idOrEmail) {
                $scope.editors.splice(index, 1);
                $scope.updateAdditionalEditors();
            }
        });
    };

    $scope.scopeVars = {newCoOwner:"", newAdmin:"", newEditorEmail:""};

    $scope.toggleDownloadOption = function(option) {
        if ($scope.fileId
          && ($scope.isOwnerOrCoOwner($scope.userId) || $scope.isTeamAdmin)
          && !$scope.isAdmin) {
            $scope.downloadBtnStatus = option;
            proofRepositoryService.toggleDownloadOption($scope.fileId, option).then(function(isToggled) {
                if (isToggled && $scope.from === 'proof') {
                    $rootScope.$broadcast('toggleDownloadOnProof', {proofId: $scope.proofId, fileId: $scope.fileId, canDownload: !!option});
                }
            }).catch(function() {
                $scope.downloadBtnStatus = !option;
            });
        }
    };

    $scope.downloadSwitchProps = {
        get value() {
            return $scope.downloadBtnStatus;
        },
        onColor: 'dark-green',
        offColor: 'dark-grey',
        message: 'proof-info.label.download-proof',
        messageColor: 'white',
        onChange: function(option) {
            $scope.toggleDownloadOption(option);
        }
    };

    $scope.canTogglePublicProof = function() {
        return $scope.isOwned &&
            (
                $scope.$proof.canBePublic ||
                $scope.$proof.isPublic
            );
    };

    $scope.toggleProofIsPublic = function(bool) {
        $scope.$proof.isPublic = bool;
        sdk.proofs.update({
            id: $scope.proofId,
            isPublic: bool,
        }).catch(function(error) {
            $scope.$proof.isPublic = !bool;
        });
    }

    $scope.canToggleProofVisibility = function() {
        return $scope.isOwned &&
            (
                $scope.$proof.canHaveCommentVisibility ||
                $scope.$proof.commentVisibility === 'commentCreatorOrProofTeam'
            );
    };

    $scope.toggleCommentVisibility = function(bool) {
        $scope.$proof.commentVisibility = bool ? 'commentCreatorOrProofTeam' : null;
        sdk.proofs.update({
            id: $scope.proofId,
            commentVisibility: $scope.$proof.commentVisibility,
        }).catch(function(error) {
            $scope.$proof.commentVisibility = bool ? null : 'commentCreatorOrProofTeam';
        });
    }

    $scope.updateProofChecklist = function(template) {
        sdk.proofs.update({
            id: $scope.proofId,
            checklist: template,
        }).then(function () {
            $scope.$proof.checklist = template;
            $scope.toggleAddChecklistConfirm();
        });
    }

    $scope.proofIsPublicSwitchProps = {
        get value() {
            return $scope.$proof.isPublic;
        },
        message: 'proof-info.label.share-link',
        messageColor: 'white',
        onColor: 'dark-green',
        offColor: 'dark-grey',
        onChange: function(option) {
            $scope.toggleProofIsPublic(option);
        }
    }

    $scope.proofCommentVisibilitySwitchProps = {
        get value() {
            return $scope.$proof.commentVisibility === 'commentCreatorOrProofTeam' ? true : false;
        },
        message: 'proof-info.label.invisible-reviewer-comments',
        messageColor: 'white',
        onColor: 'dark-green',
        offColor: 'dark-grey',
        onChange: function(option) {
            $scope.toggleCommentVisibility(option);
        }
    }

    $scope.checklistIsRequiredForFinalApprovalSwitchProps = {
        get value() {
            return $scope.$proof.checklist.isRequiredForFinalApproval;
        },
        message: 'proof-manage.checklist.required-for-final-approval',
        messageColor: 'white',
        onColor: 'dark-green',
        offColor: 'dark-grey',
        onChange: function(bool) {
            $scope.$proof.checklist.isRequiredForFinalApproval = bool;
            sdk.proofs.update({
                id: $scope.proofId,
                checklist: {
                    isRequiredForFinalApproval: bool,
                }
            }).catch(function(error) {
                $scope.$proof.checklist.isRequiredForFinalApproval = !bool;
            });
        }
    };

    $scope.checklistShouldOpenOnProofLoadSwitchProps = {
        get value() {
            return $scope.$proof.checklist.shouldOpenOnProofLoad;
        },
        message: 'proof-manage.checklist.open-on-proof-load',
        messageColor: 'white',
        onColor: 'dark-green',
        offColor: 'dark-grey',
        onChange: function(bool) {
            $scope.$proof.checklist.shouldOpenOnProofLoad = bool;
            sdk.proofs.update({
                id: $scope.proofId,
                checklist: {
                    shouldOpenOnProofLoad: bool,
                }
            }).catch(function(error) {
                $scope.$proof.checklist.shouldOpenOnProofLoad = !bool;
            });
        }
    };

    function _addManyNewCoOwner (onAllVersions) {
        var onAllVersions = onAllVersions || false;
        var emailStr = $scope.scopeVars.newCoOwner.toLowerCase();
        if (emailStr.length < 1) {
            return;
        } else {
            var emails = getEmailArr(emailStr);
            var erroredEmails = [];
            var errorMsgs = [];

            var handleAfterAddingOwner = function (error, msg, email, type) {
                if (error) {
                    if (erroredEmails.indexOf(email) === -1) {
                        erroredEmails.push(email);
                    }
                    if (errorMsgs.indexOf(msg) === -1) {
                        errorMsgs.push(msg);
                    }
                }
                if (erroredEmails.length > 0) {
                    $scope.scopeVars.newCoOwner = erroredEmails;
                    $scope.coOwnerError = errorMsgs;
                    $scope.showCoOwnerError = true;
                    $scope.showNewCoOwner = true;
                } else {
                    $scope.openNewCoOwnerInput();
                }
                if (!$scope.$$phase) $scope.$apply();
            };

            if (onAllVersions && $scope.from !== 'team-dashboard') {
                if ($scope.handleAddNewCoOwnerRunning) {
                    return false;
                }
                $scope.handleAddNewCoOwnerRunning = true;
                var emailArr = [];
                emails.forEach(function (email) {
                    if (validateEmail(email)) {
                        emailArr.push({ email: email });
                    } else {
                        handleAfterAddingOwner(true, 'invalid-email', email);
                    }
                });
                sdk.proofs.owners.add($scope.latestProofId, emailArr)
                    .then(function (response) {
                        var addedOwners = response.data;
                        // segment for multiple owner add?
                        if (addedOwners.length) {
                            repeatAsyncForEach(addedOwners, function (user, next, idx) {
                                if (user.Added) {
                                    $scope.addToCoOwners({
                                        email: user.Email,
                                        memberId: user.MemberId,
                                        userId: user.UserId,
                                        isActive: true,
                                        showRemove: !user.IsBot,
                                    });
                                }
                                handleAfterAddingOwner(false, '');
                                $timeout(next)
                            });
                        }
                        $scope.handleAddNewCoOwnerRunning = false;
                    });
            } else {
                repeatAsyncForEach(emails, function(emailToAdd, next, idx) {
                    $scope.addNewCoOwner(emailToAdd, onAllVersions, function (error, msg, email) {
                        console.debug("many owner added result", error, msg, email);
                        handleAfterAddingOwner(error, msg, email);
                        $timeout(next);
                    });
                });
            }
        }
    };

    $scope.addManyNewCoOwner = function() {
        _addManyNewCoOwner();
    };

    $scope.addManyNewCoOwnerOnAllVersions = function() {
        _addManyNewCoOwner(true);
    };

    $scope.refreshProofInfo = function (from, userId) {
        if ($scope.userId === userId) {
            $scope.processingClose = false;
            $scope.load();
        }
    };

    $scope.hideCoOwnerErrors = function() {
        if ($scope.showCoOwnerError === true) {
            $scope.showCoOwnerError = false;
        }
        if (!$scope.$$phase) $scope.$apply();
    };

    $scope.addNewCoOwner = function(email, onAllVersions, callback){
        var callback = callback || angular.noop;

        if ($scope.handleAddNewCoOwnerRunning) {
            callback(false, 'addNewCoOwner already running');
            return;
        }
        $scope.handleAddNewCoOwnerRunning = true;

        if(!validateEmail(email)){
            $scope.handleAddNewCoOwnerRunning = false;
            callback(true, 'invalid-email', email);
            return;
        }

        if($scope.isInCoOwner(email)){
            $scope.handleAddNewCoOwnerRunning = false;
            callback(true, 'person-is-already-owner', email);
            return;
        }

        if(email == $scope.proofOwnerEmail){
            var errorStr = ($scope.proofOwnerEmail == $scope.email) ? 'you-are-already-owner' : 'person-is-already-owner';
            $scope.handleAddNewCoOwnerRunning = false;
            callback(true, errorStr, email);
            return;
        }

        if ($scope.isInEditors(email)) {
            $scope.handleAddNewCoOwnerRunning = false;
            callback(true, 'person-is-editor', email);
            return;
        }

        if ($scope.from === 'team-dashboard') {
            backendService
                .fetch('domain.dashboard.addOwner', {
                    email: email,
                    relatedId: $scope.proofId,
                    allVersions: onAllVersions, // if owner have to be added on all versions
                })
                .data()
                .then(function(response) {
                    $scope.addToCoOwners({
                        email: email,
                        memmberId: response[0].MemberId,
                        userId: response[0].UserId,
                        isActive: true,
                        showRemove: !response[0].IsBot,
                    });
                    $scope.refreshProofInfo('coowner-added', response.UserId);
                    $scope.handleAddNewCoOwnerRunning = false;
                    callback(false, 'Owner added successfully', email);
                    if (!$scope.$$phase) $scope.$apply();
                })
                .catch(function(error) {
                    $scope.handleAddNewCoOwnerRunning = false;
                    if (error.data.ResponseStatus === 'ERROR_NO_ACCESS_WRITE') {
                        callback(true, 'person-is-not-in-domain', email);
                        return;
                    }
                });
        } else {
            $scope.proof.AddAdmin($scope.proofId, email, function (returnData, userId) {

                SegmentIo.track(31, {
                    'proof id': $scope.proofId,
                    'user id': userId
                }); //31:{name:'Co-Owner Added'},

                $scope.addToCoOwners({
                    email: email,
                    memberId: returnData["MemberId"],
                    userId: userId,
                    isActive: true,
                    showRemove: !returnData['IsBot'],
                });
                $scope.handleAddNewCoOwnerRunning = false;
                callback(false, 'Owner added successfully', email);
                if (!$scope.$$phase) $scope.$apply();
            });
        }
    };

    $scope.updateOwners = function () {
        $scope.owners = [];
        $scope.coOwners.forEach(function (coowner) {
            $scope.owners.push(coowner);
        });
        $scope.owners.push({ email: $scope.proofOwnerEmail, userId: $scope.proofOwnerUserId });
    }

    $scope.addToCoOwners = function(data){
        if ( ! $scope.isInCoOwner(data.email)) {
            $scope.coOwners.push(data);
        }
        $scope.updateOwners();
        $scope.updateAdditionalOwners();
        if (!$scope.$$phase) $scope.$apply();
    };

    $scope.isBotUser = function (userId) {
        return $scope.$proof.owners.some(function (owner) {
            return owner.UserId === userId && owner.IsBot;
        });
    }

    $scope.loadCoOwners = function () {
        var tasks = [],
            coOwners = $scope.coOwnerIds;

        if(coOwners.length > 0) {
            coOwners.forEach(function (coownerId, index) {
                tasks.push(
                    userRepositoryService.get(coownerId).then(function(userData){
                        $scope.addToCoOwners({
                            email: userData["email"],
                            userId: userData["userId"],
                            isActive: true,
                            showRemove: !$scope.isBotUser(userData["userId"]),
                        });
                    })
                );
            });
        }
        return $q.all(tasks);
    };

    $scope.loadEditorUsers = function(editors) {
        var tasks = [];

        if(editors.length > 0) {
            editors.forEach(function (editorId, index) {
                tasks.push(
                    userRepositoryService.get(editorId).then(function(userData){
                        if(!$scope.isInEditors(userData.email)) {
                            $scope.editors.push({
                                email: userData["email"],
                                userId: userData["userId"],
                                isActive: true,
                                showRemove: true,
                                showMenu: true
                            });
                        }
                    })
                );
            });
            return $q.all(tasks)
                        .then(function() {
                            $scope.updateAdditionalEditors();
                        });
        }
    };

    $scope.getOwners = function () {
        var owners = [];
        owners.push($scope.proofOwnerEmail.toLowerCase());
        $scope.coOwners.forEach(function (coOwner) {
            owners.push(coOwner.email.toLowerCase());
        });

        return owners;
    };

    $scope.isCoOwnerLoggedIn = function(loggedInUser, callbackFunc){
        var res = $scope.coOwnerIds.indexOf(loggedInUser) !== -1;
        if (callbackFunc) {
            callbackFunc(res);
        } else {
            return res;
        }
    };

    $scope.updateAdditionalOwners = function() {
        var owners = [];
        for (var i = 0; i < $scope.coOwners.length; i++) {
            owners.push($scope.coOwners[i].email);
        }
        owners.shift();
        $scope.additionalOwners = owners;
    };

    $scope.updateAdditionalEditors = function() {
        var editors = [];
        for (var i = 2; i < $scope.editors.length; i++) {
            editors.push($scope.editors[i].email);
        }
        $scope.additionalEditors = editors;
    };

    $scope.isAdminLoggedIn = function(loggedInUser, callbackFunc){

        if(empty($scope.workflowId)) return "";
		return $scope.loadCachedAdmins()
			.then(function(returnData){
            var res = false;

            console.log("returnData, loggedInUser", returnData, loggedInUser);

            if(returnData.length > 0){
                returnData.forEach(function(value, index){
                    if(value["UserId"] == loggedInUser){
                        res = true;
                    }
                });
            }
            callbackFunc(res);
        });
    };

    $scope.canReCreateProof = function() {
        return $scope.downloadBtnStatus && $scope.$proof.hasApproved;
    }
    
    $scope.reCreateProof = function () {
        sdk.proofs.load($scope.proofId).then(function (proof) {
            $scope.closeInfoPane();

            sdk.files.duplicate(proof.fileId).then(function (fileRecord) {
                window.__pageproof_quark__.proofSetup.requestProofSetup(
                    {
                        initialize: {
                            proof: {
                                name: proof.name,
                                tags: proof.tags,
                                messageToReviewers: proof.messageToReviewers,
                                canDownload: proof.canDownload,
                                isPublic: proof.isPublic,
                                commentVisibility: proof.commentVisibility,
                                file: fileRecord.id ? {
                                    id: fileRecord.id,
                                    name: fileRecord.name + '.' + fileRecord.extension,
                                    size: fileRecord.size,
                                    status: 'processing',
                                    type: 'local'
                                } : undefined
                            },
                            owners: proof.owners.map(function (owner) { return owner.email; })
                        }
                    },
                    function () {
                        $location.path('create');
                    }
                );
            })
        });
    }

    $scope.gotoOnBoarding = function(){
        var proofId = $scope.proofId;
        sdk.proofs.load(proofId).then(function (proof) {
            $scope.closeInfoPane();

            var isAwaitingEmail = proof.source === 'email' && proof.fileStatus === 'Created';


            window.__pageproof_quark__.proofSetup.requestProofSetup(
                {
                    parameters: {
                        updateProof: {
                            id: proof.id
                        }
                    },
                    initialize: {
                        proof: {
                            id: proof.id,
                            name: proof.name,
                            reference: proof.reference || undefined,
                            tags: proof.tags,
                            messageToReviewers: proof.messageToReviewers,
                            dueDate: proof.dueDate,
                            integrationReferences: proof.integrationReferences,
                            reminders: proof.reminders,
                            canDownload: proof.canDownload,
                            isPublic: proof.isPublic,
                            commentVisibility: proof.commentVisibility,
                            canBePublic: proof.canBePublic,
                            availableWorkflowRoles: proof.availableWorkflowRoles,
                            canAddChecklist: proof.canAddChecklist,
                            isAwaitingEmail: isAwaitingEmail || undefined,
                            file: proof.fileId ? {
                                id: proof.fileId,
                                name: proof.name + '.' + proof.fileExtension,
                                size: proof.fileSize,
                                status: 'processing',
                                type: isAwaitingEmail ? 'email' : 'local',
                                icon: isAwaitingEmail ? window.__pageproof_quark__.proofSetup.addFile.generateEmailIconForProof() : undefined,
                            } : undefined
                        },
                        checklist: proof.checklist,
                        workflow: proof.workflowId
                            ? { id: proof.workflowId }
                            : undefined,
                        owners: proof.owners.map(function (owner) { return owner.email; })
                    }
                },
                function () {
                    $location.path('create');
                }
            );
        });
    };

    $scope.gotoDash = function(path) {
        $scope.resetVars();
        $scope.closeInfoPane();
        if (path) {
            $location.url(path).force();
        } else if ($scope.isBaseVersion
                    && ($location.path().indexOf('/dashboard') === -1 && $location.path().indexOf('/team-dashboard') === -1)) {
            $location.path('dashboard').force();
        }
        if (!$scope.$$phase) $scope.$apply();
    };

    $scope.goToCollectionDash = function() {
        var groupId = $scope.$proof.groupId;
        $scope.closeInfoPane();
        $location.path('/dashboard/group').search({ groupId: groupId });
    }

    $scope.toggleEditorReplaceInput = function(editor) {
        $scope.scopeVars.editorToBeReplaced = editor ? editor.email : '';
        $scope.scopeVars.oldEditor = $scope.scopeVars.editorToBeReplaced;
        $scope.showEditorReplaceInput = !$scope.showEditorReplaceInput;
        $scope.showNewEditorInput = false;
        $scope.showEditorError = false;
        if ($scope.showEditorReplaceInput) {
            $timeout(function(){angular.element("#editorToBeReplaced").focus(); });
        }
    };

    $scope.toggleNewEditorInput = function() {
        $scope.showNewEditorInput = !$scope.showNewEditorInput;
        $scope.showEditorReplaceInput = false;
        $scope.showEditorError = false;
        $scope.scopeVars.newEditorEmail = "";
        if ($scope.showNewEditorInput) {
            $timeout(function(){angular.element("#newEditorEmail").focus(); });
        }
    };

    $scope.removeEditor = function(editorId) {
        return sdk.proofs.editors.remove($scope.proofId, {id: editorId})
            .then(function(response) {
                $scope.removeEditorFromEditors(editorId);
                return true;
            });
    };

    $scope.hideEditorErrors = function() {
        $scope.showEditorError = false;
    };

    $scope.isValidEditor = function(email, callback) {
        var callback = callback || angular.noop;

        if (!validateEmail(email)) {
            callback(true, 'invalid-email', email);
            return;
        }

        if ($scope.isInCoOwner(email)) {
            callback(true, 'person-is-already-owner', email);
            return;
        }

        if (email == $scope.proofOwnerEmail) {
            var errorStr = ($scope.proofOwnerEmail == $scope.email) ? 'you-are-already-owner' : 'person-is-already-owner';
            callback(true, errorStr, email);
            return;
        }

        if ($scope.isInEditors(email)) {
            callback(true, 'person-is-already-editor', email);
            return;
        }

        callback(false, '', email);
        return;
    };

    $scope.addNewEditor = function() {
        var email = $scope.scopeVars.newEditorEmail.toLowerCase();
        if (email.length < 1) {
            return;
        }

        if ($scope.handleAddEditorRunning) {
            return;
        }
        $scope.handleAddEditorRunning = true;

        $scope.isValidEditor(email, function(error, msg, email) {
            if (error) {
                $scope.handleAddEditorRunning = false;
                $scope.showEditorError = true;
            } else {
                sdk.proofs.editors.add($scope.proofId, {email: email})
                .then(function(res){
                    $scope.loadEditorUsers(res.data);
                    $scope.toggleNewEditorInput();

                    $scope.showEditorError = false;
                    $scope.scopeVars.newEditorEmail = "";
                    $scope.handleAddEditorRunning = false;
                    if(!$scope.$$phase) $scope.$apply();
                });
            }
        });
    };

    $scope.replaceEditor = function() {
        var email = $scope.scopeVars.editorToBeReplaced.toLowerCase(),
            oldEditor = $scope.scopeVars.oldEditor;
        if (email.length < 1) {
            return;
        }

        if ($scope.handleAddEditorRunning) {
            return;
        }
        $scope.handleAddEditorRunning = true;

        $scope.isValidEditor(email, function(error, msg, email) {
            if (error) {
                $scope.handleAddEditorRunning = false;
                $scope.showEditorError = true;
            } else {
                sdk.proofs.editors.replace($scope.proofId, {email: oldEditor}, {email: email})
                    .then(function(res){
                        $scope.loadEditorUsers(res.data);
                        $scope.removeEditorFromEditors(oldEditor);
                        $scope.toggleEditorReplaceInput();

                        $scope.showEditorError = false;
                        $scope.scopeVars.editorToBeReplaced = "";
                        $scope.handleAddEditorRunning = false;
                        if(!$scope.$$phase) $scope.$apply();
                    });
            }
        });
    };

    $scope.startWorkflow = function(){
        if(empty($scope.proofId)) return "";

        $scope.deleteInProgress = true;
        $scope.startBtnTxt = "starting";
        $scope.proof.Start($scope.proofId, "", function(){
            $scope.loadWorkFlow($scope.workflowId, "", function(){

                $scope.proof.Load($scope.proofId, function(returnData){

                    var returnData = jsonDecode(this.responseText);

                    if(isset(returnData)){

                        $scope.showStartWorkflowMessage = false;
                        $scope.deleteInProgress = false;

                        $scope.proof.GetProofStatusStr($scope.proofId, function(returnData){
                            $scope.parseStatusText(returnData);
                            if(!$scope.$$phase) $scope.$apply();
                        });

                        $scope.showWorkFlows = true;

                        $scope.statusCode = returnData["Status"];

                        $scope.handleOverlay();

                        if(!$scope.$$phase) $scope.$apply();

                    }

                });


            });
        });

    };

    $scope.loadThumb = function(fileId, callBackFunc){
        sdk.files
            .thumbnail(fileId)
            .then(function(returnData){
                callBackFunc(window.URL.createObjectURL(returnData));
            })
    };

    $scope.nudgeAllUsers = function() {
        return __pageproof_quark__.features.nudgeAll($scope.workflowId)
            .then(function() {
                $scope.showWorkflowDetails = false;
                $scope.refreshWorkflow();
                $rootScope.$broadcast("reloadWorkflow", { workflowId: $scope.workflowId });
                $scope.showNudgeAll = false;
            });
    }

    $scope.skipAllUsers = function() {
        $scope.showWorkflowDetails = false;
        return sdk.workflows.skipAll($scope.workflowId)
            .then(function() {
                $scope.refreshDecisionSummary();
                $rootScope.$broadcast("reloadWorkflow", { workflowId: $scope.workflowId });
            });
    }

    $scope.onWorkflowUpdate = function() {
        $scope.refreshWorkflow();
        $rootScope.$broadcast("reloadWorkflow", { workflowId: $scope.workflowId });
    }   

    $scope.refreshProofInfo = function() {
        $scope.refreshWorkflow();
    }

    $scope.toggleDatesDetails = function() {
        $scope.showDatesDetails = !$scope.showDatesDetails;
    }

    $scope.parseStatusText = function (statusText) {
        if ($scope.fileStatus == "error") {
            $scope.statusText = "file-error";
        } else if ($scope.fileId === '') {
            $scope.statusText = 'awaiting-file';
        } else if ($scope.fileStatus === "created" && $scope.$proof.source === "email") {
            $scope.statusText = "awaiting-email";
        }else if($scope.fileStatus === "proccessing"){
            $scope.statusText = "processing";
        }else{
            if(statusText.toLowerCase() == "proofing (primary_email)") {
                $scope.statusText = "proofing-fa";
            } else if ($scope.$proof.hasEditors && (statusText === "To-dos requested" || statusText === "Awaiting New Version")) {
                $scope.statusText = 'with-editors';
            } else if (statusText == "Awaiting New Version" && $scope.isOwned) {
                $scope.statusText = "new-version";
            }else if(statusText == "Awaiting New Version" && !$scope.isOwned){
                $scope.statusText = "awaiting-new-version";
            } else if (statusText === 'Has New Version') {
                $scope.statusText = 'has-new-version';
            } else if (statusText == "Closed") {
                if ($scope.$proof.hasApproved) {
                    $scope.statusText = "approved-and-archived";
                } else {
                    $scope.statusText = "archived";
                }
            } else if (statusText.match(/\((.*)\)$/)) {
                var stepName = /\((.*)\)$/.exec(statusText);
                if (stepName) {
                    stepName = stepName[1];
                    $scope.statusStepName = stepName;
                    $scope.statusText = "proofing-step";
                }
            } else if (statusText === "In Final Proofing" || statusText === 'Proofing') {
                $scope.statusText = 'in-proofing';
            } else if (statusText === "To-dos requested") {
                $scope.statusText = 'to-dos';
            } else if ($scope.statusCode === PPProofStatus.HAS_NEW_VERSION) {
                $scope.statusText = 'has-new-version';
            }
            else {
                $scope.statusText = statusText;
            }
        }
        if(!$scope.$$phase) $scope.$apply();
    };

    $scope.refreshWorkflow = function (forFA) {
        if (!$scope.workflowId) {
            return;
        }
        $scope.loadWorkFlow($scope.workflowId, "", function(){

            $scope.proof.GetProofStatusStr($scope.proofId, function(returnData){
                $scope.parseStatusText(returnData);
                $scope.handleOverlay();
            });

            $scope.showWorkFlows = true;

            if(!$scope.$$phase) $scope.$apply();
        });
    };

    $scope.formatDate = function(date) {
        return (date !== '0001-01-01T00:00:00' && date !== '1900-01-01T00:00:00') ? moment(moment.utc(date).toDate()).format('h:mma[,] Do MMMM YYYY') : '';
    };

    $scope.refreshDecisionSummary = function() {
        $scope.decisionSummaryRef.current.refreshDecisionSummary();
    };

    $scope.loadWorkFlow = function(workflowId, loadAsCopy, callBackFunc){

        $scope.loadCoOwners().then(function(){

            if (isset(workflowId)) {
                $scope.workflowId = workflowId;
                showLoader($scope.workflowId);
                $scope.orginalWorkflowId = $scope.workflowId;
                $scope.data.addWorkflowId($scope.workflowId);
            }

            $scope.workFlow.Load($scope.workflowId, function () {
                var returnData = jsonDecode(this.responseText);
                var completeSteps = [];
                if (isset(returnData["WorkflowId"])) {
                    $scope.showNudgeAll = false;
                    $scope.isLastStepVisible = false;

                    $scope.workflowData["workflowId"] = returnData["WorkflowId"];
                    $scope.workflowData["proofId"] = returnData["ProofId"];
                    $scope.workflowData["title"] = stripTags(returnData["Title"]);
                    $scope.workflowData["stepCount"] = 0;
                    $scope.proofOwnerId = returnData["UserId"];
                    $scope.completeStepsCount = 0;
                    returnData["WorkflowSteps"].forEach(function(workflowStep) {
                        if (workflowStep.Complete === 1 && workflowStep.Visible === 1) {
                            completeSteps.push(workflowStep);
                        }
                        $scope.completeStepsCount = completeSteps.length - 1;
                    });

                    if ($scope.proofOwnerId == $scope.userId) {
                        $scope.workflowData["isOwner"] = true;
                    } else {
                        $scope.workflowData["isOwner"] = false;
                    }

                    $scope.workflowId = returnData["WorkflowId"];

                    if (isset(returnData["Description"])) {
                        $scope.workFlowDescription = stripTags(returnData["Description"]);
                    }

                    if (returnData["CountComplete"] > 0) {
                        $scope.finishedCount = returnData["CountComplete"];
                    }

                    if (returnData["CountSkip"] > 0) {
                        $scope.skippedCount = returnData["CountSkip"];
                    }

                    if (returnData["CountViewed"] > 0) {
                        $scope.viewCount = returnData["CountViewed"];
                    }

                    $scope.workflowData.steps = [];

                    if (returnData["WorkflowSteps"].length > 0) {
                        $scope.showStartWorkflowMessage = !returnData['WorkflowSteps'][0]['Visible'] && !$scope.statusCode && $scope.fileStatus === "ok" && $scope.fileId;
                        $scope.showWorkFlows = true;

                        $scope.isLastStepVisible = !!returnData['WorkflowSteps'][returnData['WorkflowSteps'].length - 1]['Visible'];

                        returnData["WorkflowSteps"].forEach(function(workflowStep) {
                            const mappedStep = {
                                name: workflowStep.Title,
                                dueDate: workflowStep.DueDate,
                                mandatoryDecisionThreshold: workflowStep.MandatoryDecisionThreshold,
                                users: [],
                            }

                            workflowStep["Users"].forEach(function(user) {
                                mappedStep.users.push({
                                    email: user.Email,
                                    role: user.Role,
                                    permissions: { inviter: user.IsInviter },
                                });

                                if (!$scope.showNudgeAll) {
                                    var wasRecentlyNudged = false;

                                    if (isset(user["NudgedInStep"]) && user["NudgedInStep"] != "0001-01-01T00:00:00") {
                                        var date = new DateTime();
                                        var diff = date.getDiff(user["NudgedInStep"], "minutes");

                                        var nudgeWait = $scope.application.GetConfigSetting("NudgeWaitMinutes");

                                        if (diff <= nudgeWait) {
                                            wasRecentlyNudged = true;
                                        }
                                    }
                                        
                                    if ($scope.showNudge(user.UserId, user.CompleteInStep, workflowStep.Visible, wasRecentlyNudged, user.Role)) {
                                        $scope.showNudgeAll = true;
                                    }
                                }
                            });
                            $scope.workflowData.steps.push(mappedStep);
                        });
                    } else {
                        $scope.showWorkFlows = true;
                        $scope.workflowDidChange();

                        if (!$scope.$$phase) $scope.$apply();

                        if (isFunc(callBackFunc)) callBackFunc();
                    }

                }
            });
        });

    };

    $scope.canNudgeUsers = function() {
       return (
          ($scope.isOwnerOrCoOwner($scope.userId) || $scope.isTeamAdmin) &&
          [PPProofStatus.NEW, PPProofStatus.PROOFING, PPProofStatus.FINAL_APPROVING].includes($scope.$proof.status)
       )
    }

    $scope.showSkipAll = function() {
        return window.generalfunctions_canSkipAll($scope.statusCode, $scope.isLastStepVisible);
    };

    $scope.showNudge = function(userId, isFinished, stepVisible, nudged, role) {
        return (userId !== $scope.userId) && ($scope.statusCode === 10 || $scope.statusCode === 30) && !isFinished && stepVisible && !nudged && role !== 'view-only';
    };

    $scope.showSkip = function(userId, isFinished, stepVisible, stepComplete, isSkipped, isMandatory) {
        return (userId !== $scope.userId) && ($scope.statusCode === 10 || $scope.statusCode === 30) && !isFinished && stepVisible && !stepComplete && !isSkipped && isMandatory;
    };

    $scope.toggleShowTagsReference = function (item) {
        var isOpen = $scope[item];
        $scope.showTags = false;
        $scope.showReference = false;
        $scope[item] = !isOpen;
  };

    $scope.addNewEmailStepRunning = false;

    $scope.addNewEmailStepRunning = false;

    $scope.stepUserRole = function (step, user) {
        if (step.stepPosition === 1000) { return 'approver'; }
        if (user.approver) { return 'gatekeeper'; } 
        if (user.mandatory) { return 'mandatory'; } 
        return 'reviewer';
    };

    $scope.canShowWorkflowOptions = function() {
        if (!($scope.workflowData && $scope.workflowData.steps && $scope.workflowData.steps.length)) {
            return false;
        }

        return $scope.isOwnerOrTeamMember || $scope.showNudgeAll || $scope.showSkipAll();
    };

    $scope.onSaveWorkflowTemplate = function() {
        return __pageproof_quark__.features.saveWorkflowTemplate({ steps: $scope.workflowData.steps }, $scope.getOwners());
    };

    $scope.getInfoPaneWorkflowOptionsProps = function() {
        return {
            onNudgeAll: $scope.showNudgeAll && $scope.nudgeAllUsers,
            onSkipAll: $scope.showSkipAll() && $scope.skipAllUsers,
            onSaveWorkflowTemplate: $scope.isOwnerOrTeamMember && $scope.onSaveWorkflowTemplate,
        };
    };

    $scope.updateStepMandatoryDecisionThreshold = function(stepId, mandatoryDecisionThreshold) {
        return sdk.workflows.steps.update(stepId, {
            mandatoryDecisionThreshold: mandatoryDecisionThreshold,
        });
    };
    
    $scope.updateProofTitle = function (title) {
        $scope.proofTitle = title;
    };

    $scope.sendUpdatedTitle = function () {
        if (empty($scope.proofTitle.trim()) && $scope.$proof.source !== 'email') return "";
        $scope.proof.Update($scope.proofId, { Title: stripTags($scope.proofTitle) }, function (returnData) {
          if ($scope.from == "proof") {
            var titleH1 = $('header').find('h1');
            titleH1.html($scope.proofTitle);
          }
        });
      };

    var updateReferenceServer = debounce(function () {
      $scope.proof.Update($scope.proofId, {Reference:$scope.$proof.reference}, function(){ });
    }, 1000)

    $scope.updateReference = function (reference) {
        if ($scope.$proof.isReferenceEnabled) {
            $scope.$proof.reference = reference;
            updateReferenceServer();
        }
    }

    $scope.toggleEditingProoferMessage = function() {
        if ($scope.statusCode <= 30 && $scope.isOwned) {
            $scope.editingProoferMessage = !$scope.editingProoferMessage;
            $timeout(function(){angular.element("#prooferMessage").focus(); });
        }
    }

    $scope.updateProoferMessage = function() {
        $scope.proofInfoCtrl.prooferMessage = val("#prooferMessage");
        sdk.proofs.setMessageToReviewers(
            $scope.proofId,
            stripTags($scope.proofInfoCtrl.prooferMessage)
        );
        $scope.editingProoferMessage = !$scope.proofInfoCtrl.prooferMessage;
    };

    $scope.updateEditorMessage = function () {
        if ($scope.$proof.proofType === PPProofType.BRIEF) {
            sdk.briefs.update({
                briefId: $scope.proofId,
                data: { editorMessage: stripTags($scope.$proof.editorMessage) }
            });
        } else {
            $scope.proof.Update(
                $scope.proofId,
                { EditorMessage: stripTags($scope.$proof.editorMessage) }
            );
        }
    };

    $scope.updateDueDate = function () {
        console.log('$scope.updateDueDate => %s', $scope.dueDateDisplay);

        var date = dateService.parse($scope.dueDateDisplay);

        $scope.dueDate = moment.utc(date).format();
        $scope.dueDateDisplay = date.format('h:mma, Do MMMM YYYY');

        $scope.data.addDueDate($scope.dueDate);
        $scope.data.addDueDateDisplay($scope.dueDateDisplay);
    };

    $scope.onDateChange = function(date) {
        $scope.dateTimeValue = moment(date).format('h:mma, Do MMMM YYYY');
        $scope.parseDateDisplay("reminder");
    };

    $scope.parseDateDisplay = function(type) {
        if (type &&  type === 'reminder') {
            $("#dueDateDisplay").val($scope.dateTimeValue);
        }
        $scope.dueDateDisplay = val("#dueDateDisplay");

        var dateNow = new DateTime();
        if(isset($scope.dueDateDisplay)){
            showError("#due_date", "", "hide");

            var date = dateService.parse($scope.dueDateDisplay);

            $scope.dueDate = moment.utc(date).format();
            if (dateNow.isProofOverDue($scope.dueDate)) {
                $scope.dueDateDisplayClass = "alert";
                $scope.isOverDue = true;
            } else {
                $scope.dueDateDisplayClass = "";
                $scope.isOverDue = false;
            }
            $scope.dueDateDisplay = date.format('h:mma, Do MMMM YYYY');
            $("#dueDateDisplay").val($scope.dueDateDisplay);

            if(!$scope.$$phase) $scope.$apply();

        }else{
            var date = dateService.parse('');
            $scope.dueDate = moment.utc(date).format();
            $scope.dueDateDisplay = date.format('h:mma, Do MMMM YYYY');
            $("#dueDateDisplay").val($scope.dueDateDisplay);
            $scope.isOverDue = false;
            if (!$scope.$$phase) $scope.$apply();
        }

        $scope.getOverdueOrTriggered(date);

        $scope.proof.Update($scope.proofId, {DueDate:stripTags($scope.dueDate)});
    };

    $scope.openProof = function(){
        if ($scope.fileStatus == "ok" &&
            !$scope.showFileProcessingMessage &&
            $scope.showFileErrorMessage == false) {
            if ($scope.from !== 'proof') {
                $location.path("proof-screen/" + $scope.proofId);
            }
            $scope.closeInfoPane();
        }
    };

    $scope.isAllMarkedOrNoWorkflow = function () {
        return !$scope.$proof.workflowId ||
        ($scope.$proof.todoCount + $scope.$proof.doneCount + $scope.$proof.privateCount === $scope.$proof.commentCount);
    };

    $scope.confirmReUploadFile = function() {
        SegmentIo.track(62, {
            'proof id': $scope.proofId,
        }); //62:{name:'Proof File Replace'},
        $scope.gotoDash('/dashboard/proof?proofId=' + $scope.proofId + '&replacingFile=1');
    };

    $scope.toggleAddChecklistConfirm = function() {
        $scope.proofManageConfirm.addChecklist = !$scope.proofManageConfirm.addChecklist;
    };

    $scope.toggleReUploadFileConfirm = function() {
        $scope.proofManageConfirm.reUploadFile = !$scope.proofManageConfirm.reUploadFile;
    };
    
    $scope.toggleReturnConfirm = function() {
        $scope.proofManageConfirm.return = !$scope.proofManageConfirm.return;
    };

    $scope.toggleApproveConfirm = function() {
        $scope.proofManageConfirm.approve = !$scope.proofManageConfirm.approve;
    };

    $scope.toggleArchiveConfirm = function() {
        $scope.proofManageConfirm.archive = !$scope.proofManageConfirm.archive;
    };

    $scope.toggleDeleteConfirm = function() {
        $scope.proofManageConfirm.delete = !$scope.proofManageConfirm.delete;
    };

    $scope.toggleReturnTodosConfirm = function () {
        $scope.proofManageConfirm.returnTodos = !$scope.proofManageConfirm.returnTodos;
    };

    $scope.toggleSendToEditorConfirm = function () {
        $scope.proofManageConfirm.sendToEditor = !$scope.proofManageConfirm.sendToEditor;
    };

    $scope.toggleRevertConfirm = function(type) {
        var revertType = 'revert' + type.charAt(0).toUpperCase() + type.slice(1);
        $scope.proofManageConfirm[revertType] = !$scope.proofManageConfirm[revertType];
    }

    $scope.toggleRevertConfirmApproval = function() {
        $scope.toggleRevertConfirm('approval');
    }

    $scope.toggleRevertConfirmTodos = function() {
        $scope.toggleRevertConfirm('todos');
    }

    $scope.toggleDisabled = function(key, bool) {
        $scope.proofManageConfirm[key] = bool || !$scope.proofManageConfirm[key];
    };

    $scope.resetProofManageConfirmObj = function() {
        $scope.proofManageConfirm = $scope.proofManageActions;
    };

    $scope.uploadNewVersion = function () {
        if ($scope.statusCode === PPProofStatus.CHANGES_REQUESTED) {
            $scope.confirmReturn();
        } else if ($scope.statusCode === PPProofStatus.AWAITING_NEW_VERSION) {
            var path = ($scope.from === 'team-dashboard') ? '/team-dashboard' : 'dashboard/proof?proofId=' + $scope.proofId + '&hasFileError=0';
            $scope.gotoDash(path);
        } else {
            $scope.toggleReturnConfirm();
        }
    };

    $scope.confirmReturn = function () {
      var path = ($scope.from === 'team-dashboard') ? '/team-dashboard' : 'dashboard/proof?proofId=' +$scope.proofId+ '&hasFileError=0';
        if ($scope.$proof.proofType === PPProofType.BRIEF) {
            apiService.proofs.changesComplete.fetch($scope.proofId)
                .then(function (returnData) {
                    if (returnData.ResponseStatus === 'OK') {
                        $scope.gotoDash(path);
                    }
                });
        } else if ($scope.proofId) {
            $scope.returnProof($scope.proofId).then(function (returnData) {
                $scope.toggleDisabled('disabled');
                if (returnData.ResponseStatus === 'OK') {
                    $scope.gotoDash(path);
                }
            });
        }
    };

    $scope.returnProof = function(proofId) {
        return backendService
            .fetch('proof.return', {
                proofId: proofId
            })
            .data()
            .then(function (returnData) {
                return returnData;
            });
    };

    $scope.revertProof = function(proofId, type, message) {
        var endpoint = 'proof.revert-' + type;
        var data = {
            proofId: proofId,
            messageToSender: message,
        };
        return backendService.fetch(endpoint, data).data();
    };

    $scope.confirmReturnTodos = function (canMarkAll) {
        $scope.toggleDisabled(canMarkAll ? 'disabledConfirm' : 'disabled');
        return sdk.proofs.returnTodos($scope.proofId, {markCommentsAsTodo: canMarkAll})
            .then(function (returnData) {
                $scope.toggleDisabled('disabled', false);
                $scope.toggleDisabled('disabledConfirm', false);
                if (returnData.data.indexOf($scope.proofId) !== -1) {
                    $scope.toggleReturnTodosConfirm();
                    $scope.closeInfoPane();
                }
            });
    }

    $scope.confirmManualApproval = function () {
        $scope.toggleDisabled('disabled');
        var message = $scope.scopeVars.manualApprovalMessage;
        sdk.proofs.approve($scope.proofId, message)
            .then(function () {
                $scope.toggleDisabled('disabled');
            });
        $scope.closeInfoPane();
    }

    $scope.confirmRevert = function(type) {
        var message = '';
        if (type === 'todos') {
            message = $scope.scopeVars.todoRevertMessage;
        } else if (type === 'approval') {
            message = $scope.scopeVars.approvalRevertMessage;
        }
        if ($scope.proofId) {
            $scope.toggleDisabled('disabled');
            $scope.revertProof($scope.proofId, type, message)
                .then(function () {
                    $scope.closeProofManagePane();
                })
                .catch(function () {
                    // If request failed make the user feel like
                    // he pressed the button but nothing happend so something is wrong.
                    $timeout(function () {
                        $scope.toggleDisabled('disabled');
                    }, 1000);
                });
        }
    };

    $scope.confirmClose = function () {

        $scope.toggleDisabled('disabled');
        if($scope.proofClosed == true){
            $scope.proof.Reopen($scope.proofId, function (retData){
                if(retData["ResponseStatus"] == "OK"){

                    $scope.proofClosed = false;

                    $scope.showOwnedReadOnly = false;

                    $scope.loadProofData(function(){
                        $scope.isLoaded = true;
                        if (!$scope.$$phase) $scope.$apply();
                    });
                    $scope.toggleDisabled('disabled');

                    $scope.toggleArchiveConfirm();
                    $scope.closeInfoPane();
                    if(!$scope.$$phase) $scope.$apply();
                }
            });
        } else {
            sdk.proofs.archive([$scope.proofId])
                .then(function(response) {
                    $scope.proofClosed = true;

                    $scope.showOwnedReadOnly = true;
                    $scope.toggleDisabled('disabled');

                    $scope.loadProofData(function(){
                        $scope.isLoaded = true;
                        if (!$scope.$$phase) $scope.$apply();
                    });

                    $scope.toggleArchiveConfirm();
                    $scope.closeInfoPane();
                    if(!$scope.$$phase) $scope.$apply();
                })
                .catch(function() {
                    //todo if not archived, any erro msg in info pane
                });
        }
    };

    $scope.deleteInProgress = false;
    $scope.confirmDelete = function(type){

        if($scope.deleteInProgress == true) return "";

        const recentProofsStorage = storageService('pageproof.app.recentProofs.' + $scope.userId + '.');

        $scope.deleteInProgress = true;
        $scope.resetProofManageConfirmObj();

        $scope.proofManageConfirm.deleted = true;

        if(type == "all"){

            $scope.pleaseWaitDeletingProof = false;
            $scope.pleaseWaitDeletingAllVersions = true;
            $scope.showProofPane = false;
            $scope.proof.Delete($scope.baseProofId, function(){
                var returnData = jsonDecode(this.responseText);
                if(returnData["ResponseStatus"] == "OK"){
                    $scope.deleteInProgress = false;
                    $scope.showDashBtn = true;
                    $scope.$proof.versionArr.forEach(function(proofId) {
                        recentProofsStorage.remove(proofId);
                    });
                    if (!$scope.$$phase) $scope.$apply();
                }

            });
        }else{

            $scope.pleaseWaitDeletingProof = true;
            $scope.pleaseWaitDeletingAllVersions = false;
            $scope.showProofPane = false;

            if($scope.isLatestVersion == true && $scope.previousProofId != ""){
                $scope.proof.UpdateVersionSearch($scope.previousProofId, function(){});
            }

            $scope.proof.Delete($scope.proofId, function(){
                var returnData = jsonDecode(this.responseText);
                if(returnData["ResponseStatus"] == "OK") {
                    $scope.deleteInProgress = false;
                    $scope.showDashBtn = true;
                    recentProofsStorage.remove($scope.proofId);
                    if (!$scope.$$phase) $scope.$apply();
                }
            });

        }

    };

    $scope.isOwnerOfVersion = function(version) {
        return version.OwnerIds.indexOf($scope.userId) !== -1;
    }

    $scope.getVersionData = function (versions) {
        if (versions.length) {
            $scope.numberOfVersions = versions.length;
            $scope.latestProofId = versions[versions.length - 1].ProofId;

            if (versions.length > 1) {
                $scope.previousProofId = versions[versions.length - 2].ProofId;
            }

            if (versions[0].Version === 1 || !versions[0].Version) {
                $scope.baseProofId = versions[0].ProofId;
            }

            var ownsAllVersions = true;

            var i = 0;
            while(i < versions.length){
                if(versions[i].ProofId === $scope.proofId){
                    $scope.versionNumber = versions[i].Version;
                }
                if (!$scope.isOwnerOfVersion(versions[i])) {
                    ownsAllVersions = false;
                }
                i++;
            }

            $scope.canDeleteCurrentProof = $scope.proofId !== $scope.baseProofId;
            $scope.canDeleteAllVersions = ownsAllVersions;

            if ($scope.proofId === $scope.baseProofId && $scope.versionNumber > 1) {
                $scope.isBaseVersion = true;
            }

            if ($scope.proofId === $scope.latestProofId) {
                $scope.isLatestVersion = true;
                $scope.showCloseBtn = $scope.fileStatus === 'ok';
            }

        } else {
            $scope.canDeleteCurrentProof = true;
            $scope.canDeleteAllVersions = false;
            $scope.baseProofId = $scope.proofId;
            $scope.isBaseVersion = true;
            $scope.isLatestVersion = true;
            $scope.showCloseBtn = $scope.fileStatus === 'ok';
        }

        if ($scope.statusCode > 30) {
            $scope.makeMetaReadOnly();
        }
    };

    $scope.makeMetaReadOnly = function(){
        $scope.showMetaReadOnly = true;
    };

    $scope.handleOverlay = function(){

        if ($scope.statusCode === 10 || $scope.statusCode === 30){
            $scope.overlay = "yellow";
        }

        if (($scope.statusCode === 0 && $scope.fileStatus !== 'ripping') || $scope.showFileErrorMessage || $scope.statusCode === 100){
            $scope.overlay = "grey";
        }

        if ($scope.statusCode === 50 || $scope.statusCode === 60 || $scope.statusCode === 70 || $scope.fileStatus === 'ripping'){
            $scope.overlay = "green";
        }

        if ($scope.$proof.proofType === PPProofType.BRIEF) {
            $scope.overlay = "blue";
        }
        $scope.proofInfoCtrl.props.overlay = $scope.overlay;
        if(!$scope.$$phase) $scope.$apply();

    };

    $scope.hasProofReturned = function(visible, complete) {
        // console.debug("data at here", visible, complete, $scope.statusCode);
        return visible
            && !complete
            && ($scope.statusCode === PPProofStatus.AWAITING_NEW_VERSION ||
                $scope.statusCode === PPProofStatus.CHANGES_REQUESTED);
    };

    $scope.canReturnTodos = function () {
        return $scope.$proof.proofType === PPProofType.PROOF &&
            $scope.$proof.commentCount &&
            ($scope.$proof.commentCount !== $scope.$proof.privateCount) &&
            ($scope.statusCode === Number(PPProofStatus.NEW)
                || $scope.statusCode === Number(PPProofStatus.PROOFING)
                || $scope.statusCode === Number(PPProofStatus.FINAL_APPROVING)
                || $scope.statusCode === Number(PPProofStatus.AWAITING_NEW_VERSION));

    };

    $scope.canShowAddChecklist = function () {
        return ($scope.isOwned && $scope.$proof.canAddChecklist && !$scope.$proof.checklist) &&
            ($scope.$proof.proofType === PPProofType.PROOF) &&
            ($scope.statusCode === Number(PPProofStatus.PROOFING) ||
            $scope.statusCode === Number(PPProofStatus.FINAL_APPROVING));
    };

    $scope.shouldShowEmail = function () {
        return $scope.$proof.source === 'email' && ($scope.fileStatus === "created" || $scope.fileStatus === "error");
    }

    $scope.copyEmailAddress = function (pane) {
        if (pane === 'proof-manage') {
            $scope.emailAddressTip = "proof-manage.email.copy.tip.fetching";
        }

        sdk.proofs.generateEmail($scope.$proof.id, {}).then(function (generated) {
            window.generalfunction_copyToClipboard(generated.email);
            if (pane === 'proof-manage') {
                $scope.emailAddressTip = "proof-manage.email.copy.tip.copied";
            } else {
                $scope.copyToClipboardText = "button.copy-to-clipboard-finished";
            }
        });
        $scope.startWorkflow();
    }

    $scope.onGenerateEmail = function () {
        sdk.proofs.generateEmail($scope.$proof.id, {}).then(function (generated) {
            $scope.generatedEmail = generated.email;
        })
        return $scope.generatedEmail;
    }

    $scope.canReturnProof = function () {
        switch ($scope.$proof.proofType) {
            case PPProofType.PROOF:
                return (
                        $scope.statusCode >= Number(PPProofStatus.NEW)
                        && $scope.statusCode <= Number(PPProofStatus.AWAITING_NEW_VERSION)
                        && !$scope.$proof.hasFileError
                        && !(['Created', 'Uploading', 'Queued', 'Ripping'].includes($scope.$proof.fileStatus))
                );
            case PPProofType.BRIEF:
                return $scope.userId === $scope.$proof.recipient
                        && ($scope.statusCode === PPProofStatus.PROOFING || $scope.statusCode === PPProofStatus.AWAITING_NEW_VERSION);
            default:
                return false;
        }
    };

    $scope.hasLimitedCommentVisibility = function () {
        if ($scope.$proof.commentVisibility === null) {
            return false;
        }
        
        if ($scope.$proof.commentVisibility === 'commentCreatorOrProofTeam') {
            return $scope.$proof.teamId !== $scope.userData.teamId;
        }

        // Future proof for other visibility options
        return true;
    };

    $scope.canSendToEditor = function () {
        if (!features.flags['send-to-editor']) {
            return false;
        }

        switch ($scope.$proof.proofType) {
            case PPProofType.PROOF:
            return ($scope.statusCode === Number(PPProofStatus.CHANGES_REQUESTED)
                || ($scope.statusCode === Number(PPProofStatus.AWAITING_NEW_VERSION) && $scope.isLatestVersion));
            case PPProofType.BRIEF:
                return (($scope.statusCode === Number(PPProofStatus.PROOFING)
                    || $scope.statusCode === Number(PPProofStatus.AWAITING_NEW_VERSION))
                    && $scope.userId === $scope.$proof.recipient);
            default:
                return false;
        }
    };

    $scope.canRevertApproval = function() {
        return ($scope.statusCode === Number(PPProofStatus.APPROVED) && $scope.isOwned && !$scope.isAdmin);
    };

    $scope.canBeApprovedManually = function() {
        return (
            $scope.$proof.proofType === PPProofType.PROOF &&
            $scope.fileStatus === "ok" &&
            $scope.statusCode >= Number(PPProofStatus.NEW) &&
            $scope.statusCode < Number(PPProofStatus.HAS_NEW_VERSION)
        );
    }

    $scope.canRevertTodos = function() {
        return ($scope.statusCode === Number(PPProofStatus.CHANGES_REQUESTED)
            && $scope.isOwned && !$scope.isAdmin)
    };

    $scope.setOwnerEmailById = function(userId) {
        userRepositoryService.get(userId)
            .then(function(result) {
                if (result.email) {
                    $scope.proofOwnerEmail = result.email;
                }
            });
    }

    $scope.removeOwnerIdFromCoOwners = function () {
        var index = $scope.coOwnerIds.indexOf($scope.$proof.ownerId);
        if (index !== -1) {
            $scope.coOwnerIds.splice(index, 1);
        }
    };

    $scope.loadAllComments = function(proof) {
        return (
            proofRepositoryService
                .$getCommentsById(proof.id, proof)
        );
    }

    $scope.loadProofData = function(callback) {
        sdk.graphql('' +
            'query ppxapp_getTrueProofStatus($id: ID!) {' + 
                'proof (id: $id) {' +
                    'status ' + 
                    'isArchived' +
                '}' +
            '}',
            { id: $scope.proofId },
            { throwOnError: false })
        .then(function(result) {
            $scope.isProofArchived = result.data.proof.isArchived
            $scope.actualProofStatus = result.data.proof.status
        });

        backendService
            .fetch('proof.info', {
                proofId: $scope.proofId,
            })
            .data()
            .then(function(returnData) {
                $scope.$proof = PPDashboardProof.from(returnData);
                $scope.$proof.updateFromVersionData(returnData.Versions);
                $scope.setProps();
                $scope.proofAuditFeature = returnData.AuditFeature;
                $scope.proofTitle = returnData.Title;
                $scope.proofOwnerUserId = returnData.UserId;
                $scope.proofOwnerIsBot = $scope.isBotUser($scope.proofOwnerUserId);
                $scope.workflowId = returnData.WorkflowId;
                $scope.fileStatus = returnData.FileStatus.toLowerCase();
                $scope.isInProcessing = $scope.application.isProofIdInProcessingArray($scope.proofId);
                $scope.approvedDate = returnData.ApprovedDate;
                $scope.isLocked = returnData.Locked;
                $scope.hasOriginal = returnData.OriginalStore === 1;
                $scope.downloadBtnStatus = returnData.DownloadOriginal === 1;
                $scope.proofInfoCtrl.prooferMessage = returnData.Description;
                $scope.fileId = returnData.FileId;
                $scope.statusCode = returnData.Status;
                $scope.editingProoferMessage = !$scope.proofInfoCtrl.prooferMessage && $scope.statusCode <= 30;
                $scope.todoCommentCount = returnData.CountTodoComments;
                $scope.proofTeamType = returnData.TeamType;
                $scope.scopeVars.manualApprovalMessage = returnData.OwnerMessage;

                const userData = userService.getUser();
                $scope.userData = userData;
                $scope.isTeamAdmin = userData.isDomainAdmin && returnData.TeamId === userData.teamId;
                $scope.isOwned = $scope.proofOwnerUserId === $scope.userId || $scope.isTeamAdmin;
                $scope.parseStatusText(returnData.StatusText);
                $scope.proofClosed = returnData.Closed;
                $scope.showOwnedReadOnly = returnData.Closed;
                $scope.showCloseBtn = $scope.statusCode !== PPProofStatus.HAS_NEW_VERSION && $scope.fileStatus === 'ok';
                $scope.type = (returnData.Type === PPProofType.BRIEF) ? 'brief' : 'proof';
                $scope.$proof.tags = this.getTagsArray(returnData.Tags);
                $scope.proofInfoCtrl.props.tagsProps.tags = $scope.$proof.tags;
                $scope.coOwnerIds = $scope.$proof.ownerIds;
                $scope.removeOwnerIdFromCoOwners();
                $scope.createdDate = returnData.CreateDate;
                $scope.startedDate = returnData.StartDate;
                $scope.isOwnerOrTeamMember = $scope.userData.teamId === returnData.TeamId || $scope.workflowData.isOwner;
                $scope.isTeamMember = $scope.userData.teamId === returnData.TeamId;

                $scope.loadAllComments($scope.$proof);

                if (returnData.ParentComments !== '0') {
                    $scope.commentCountTotal = returnData.ParentComments;
                }

                if ($scope.$proof.title === "" && $scope.$proof.source === "email") {
                   $scope.proofTitlePlaceholder = "proof-info.title.email-placeholder";
                }

                $scope.editorIds = returnData.EditorIds;
                if ($scope.editorIds) {
                    $scope.loadEditorUsers($scope.editorIds);
                }

                if (!$scope.$$phase) {
                    $scope.$apply();
                }

                var isStatic = returnData.FileCategory === "static";
                if (isStatic && returnData.Pages) {
                    $scope.numberOfPages = returnData.Pages;
                }

                if ($scope.fileStatus === "error") {
                    $scope.showFileErrorMessage = true;
                    $scope.proofInfoCtrl.props.showFileErrorMessage = true;
                    $scope.showNoWorkflowMessage = false;
                    $scope.showStartWorkflowMessage = false;
                    $scope.showLoader = false;
                } else if (!$scope.fileId) {
                    $scope.showAwaitingFileMessage = true;
                    $scope.showFileErrorMessage = false;
                    $scope.showLoader = false;
                } else if ($scope.fileStatus !== "ok" && !$scope.fileStatus === "created") {
                    $scope.showFileProcessingMessage = true;
                } else if ($scope.fileStatus === "created" && $scope.$proof.source === "email") {
                    $scope.showAwaitingEmailMessage = true;
                }

                var integrationMessage,
                    integrationMatch;

                $scope.isOverDue = $scope.$proof.dueDate && moment().isAfter($scope.$proof.dueDate);

                $scope.getOverdueOrTriggered = function(date) {
                    var dateToday = new Date().getTime();
                    var dateDue = date ? date._d.getTime() : $scope.$proof.dueDate._d.getTime();
                    var difference = Math.floor((dateDue - dateToday) / 1000 / 60);

                    if (difference < -2880) {
                        $scope.hoursToDueDate = -72;
                    }
                    if (difference >= -2880 && difference < -1440) {
                        $scope.hoursToDueDate = -48;
                    }
                    if (difference >= -1440 && difference < 0) {
                        $scope.hoursToDueDate = -24;
                    }
                    if (difference >= 0 && difference < 60) {
                        $scope.hoursToDueDate = 0;
                    }
                    if (difference >= 60 && difference < 180) {
                        $scope.hoursToDueDate = 1;
                    }
                    if (difference >= 180 && difference < 1440) {
                        $scope.hoursToDueDate = 3;
                    }
                    if (difference >= 1440 && difference < 2880) {
                        $scope.hoursToDueDate = 24;
                    }
                    if (difference >= 2880 && difference < 4320) {
                        $scope.hoursToDueDate = 48;
                    }
                    if (difference >= 4320) {
                        $scope.hoursToDueDate = 72;
                    }
                }

                $scope.getOverdueOrTriggered();

                $scope.popUpReminderProps = {
                    variant: 'proof',
                    isOwner: $scope.isOwner($scope.userId) || $scope.isCoOwnerLoggedIn($scope.userId) || $scope.isTeamAdmin,
                    proof: $scope.$proof,
                    get timeLeft() {
                        return $scope.hoursToDueDate;
                    },
                };

                if ((integrationMessage = $scope.integrationError.message = returnData.FileStatusMessage) &&
                    (integrationMatch = integrationMessage.match(/(Dropbox|Google Drive|Box)/gi))) {
                    $scope.hasIntegrationError = true;
                    $scope.integrationError.provider = integrationMatch[0];
                } else {
                    $scope.hasIntegrationError = false;
                }

                $scope.handleOverlay();

                if ($scope.$proof.progressPercent !== -1) {
                    $scope.percentage = "width: " + ($scope.$proof.progressPercent * 100) + '%';
                    $scope.loadingBar = true;
                }
                $scope.$proof.versionArr = Object.keys($scope.$proof.versions);

                $scope.getVersionData(returnData.Versions);

                $scope.setOwnerEmailById($scope.proofOwnerUserId);

                if ($scope.workflowId) {
                    $scope.loadWorkFlow($scope.workflowId, "", function() {
                        $scope.showWorkFlows = true;

                        $scope.addManualApprovalBreak();
                        
                        if (!$scope.$$phase) {
                            $scope.$apply();
                        }
                    });
                } else {
                    $scope.showNoWorkflowMessage = true;
                    $scope.showWorkFlows = false;
                    $scope.loadCoOwners();
                }

                if (!$scope.fileId) {
                    // n/a
                } else if ($scope.$proof.hasFileError) {
                    $scope.showThumb = false;
                    $scope.showLoader = false;
                } else {
                    $scope.loadThumb($scope.fileId, function(thumbDataUrl) {
                        if (thumbDataUrl !== "waiting" && thumbDataUrl !== "no_data" && thumbDataUrl !== "enc_failed") {
                            $scope.proofThumDataUrl = $sce.trustAsHtml(thumbDataUrl);
                            $scope.proofInfoCtrl.props.thumbUrl = $scope.proofThumDataUrl;
                            $scope.showThumb = true;
                            $scope.showLoader = false;
                        }
                        if (!$scope.$$phase) {
                            $scope.$apply();
                        }
                    });
                }

                var date = new DateTime();
                $scope.dueDate = returnData.DueDate;
                $scope.dueDateDisplay = date.getLocalTimeFromUTCstr(returnData.DueDate);

                if(date.isProofOverDue(returnData.DueDate) && $scope.statusCode <= 30) {
                    $scope.dueDateDisplayClass = "alert";
                }

                if (typeof callback === 'function') {
                    callback();
                }
            })
            .catch(function(error) {
                if (window.isBriefId($scope.proofId)) {
                    $scope.proofInfoCtrl.type = 'brief';
                }
                if (error.data.ResponseCode === $scope.application.api.errors.ERROR_NOT_FOUND) {
                    $scope.showProofPane = false;
                    $scope.showRemovedMessage = true;
                } else if (error.data.ResponseCode === $scope.application.api.errors.ERROR_NO_ACCESS) {
                    $scope.showProofPane = false;
                    $scope.showAccessDeniedMessage = true;
                } else if (error.data.ResponseCode == $scope.application.api.errors.ERROR_RESTRICTED) {
                    $scope.showProofPane = false;
                    $scope.showAccessRestrictedMessage = true;
                } else {
                    $scope.showProofPane = false;
                    $scope.showAccessDeniedMessage = true;
                }
                if (!$scope.$$phase) {
                    $scope.$apply();
                }
            });
    };

    $scope.setProps = function() {
        $scope.proofInfoCtrl.props = {
            brief: $scope.$proof,
            closeInfoPane: $scope.closeInfoPane,
            openBrief: $scope.openProof,
            thumbUrl: $scope.proofThumDataUrl,
            readOnly: ($scope.$proof.status !== PPProofStatus.PROOFING && $scope.$proof.status !== PPProofStatus.AWAITING_NEW_VERSION)
                        || ($scope.$proof.ownerId !== $scope.userId && $scope.$proof.recipient !== $scope.userId),
            overlay: $scope.overlay,
            userId: $scope.userId,
            tagsProps: {
                tags: $scope.$proof.tags,
                readOnly: !$scope.canManage,
                onChange: $scope.updateTags,
                onAddMultiple: $scope.addTags,
                onRemove: $scope.deleteTag,
            },
            integrationReferencesProps: {
                isCurrentUserInProofTeam: $scope.$proof.isCurrentUserInProofTeam,
                proofId: $scope.$proof.id,
                integrationReferences: $scope.$proof.integrationReferences || [],
                onChange: $scope.updateIntegrationReferences,
            },
            showUserDetails: function(id) {
                if(!id) return;
                $rootScope.$broadcast('openUserDetails', {proofId: $scope.proofId, userId: id});
            },
        };
    };

    getTagsArray = function(tags) {
        var tagsArray = [];
        tags.forEach(function(tag) {
            tagsArray.push(tag.Value);
        });
        return tagsArray;
    };

    $scope.closeProofManagePane = function() {
        $scope.proofManageMode = false;
        $scope.resetProofManageConfirmObj();
        if (!$scope.showProofPane) $scope.gotoDash();
        if ($scope.open) {
            proofInfoService.close();
        }
    };

    $scope.openProofManagePane = function(options) {
        $scope.isOpenProofManage = true;
        $scope.proofManageMode = true;    

        if (options && options.approve !== undefined) {
            $scope.proofManageConfirm.approve = options.approve;
        }
    };

    $scope.changeToProofInfoPane = function() {
        $scope.proofManageMode = false;
    }

    $scope.closeInfoPane = function () {
        $scope.resetVars();
        proofInfoService.close();
    };

    proofInfoService.on('open', function () {
        $scope.open = true;
    });

    proofInfoService.on('close', function () {
        $scope.open = false;
        if ($scope.proofManageMode) {
            $scope.closeProofManagePane();
        }
    });

    proofInfoService.on('beforeopen', function (event, data) {
        $scope.resetVars();
        $scope.proofId = data.id;
        $scope.from = data.from;
        $scope.canManage = (data.options) ? data.options.canManage : false;
        $scope.load();
    });

    proofInfoService.on('beforeclose', function (event) {
        $scope.proofId = ""; //keeping $scope.proofId even after closing of proof info pane, was causing updating tags of this last opened proofId's tag on new proof upload process
        $scope.processingClose = false;
    });

    proofInfoService.on('openManageProof', function(event, options) {
        $scope.openProofManagePane(options);
    });

    $scope.processingClose = false;

    $scope.emailList = [];

    $scope.load = function(){

        $addressBook.load(function(res){

            $scope.emailList = [];

            console.log("$scope.emailList ", $scope.emailList );

            $scope.emailList = res;
        });

        $("#info_contents").animate({ scrollTop: 0 }, 1e3);

        if($scope.processingClose == true) return "";

        $scope.processingClose = true;

        $scope.userData = $scope.application.GetUserData();
        $scope.userId = $scope.userData.userId;
        $scope.email = $scope.userData["userEmail"];

        $scope.updateTags = function(tagsArray) {
            $scope.proofInfoCtrl.props.tagsProps.tags = tagsArray;
        }

        $scope.updateIntegrationReferences = function(integrationReferences) {
            $scope.proofInfoCtrl.props.integrationReferencesProps.integrationReferences = integrationReferences;
        }

        $scope.addTags = function(newTags) {
            if (newTags.length) {
                sdk.proofs.setTags($scope.proofId, newTags);
            }
        }

        $scope.deleteTag = function(tag) {
            if (tag) {
                sdk.proofs.deleteTag($scope.proofId, tag);
            }
        };

        $scope.loadProofData(function () {

            $scope.isCoOwnerLoggedIn($scope.userId, function(isCoOwner){
                if( isCoOwner ){
                    $scope.isOwned = true; //this over rides the other isOwned set in loadProof
                    $scope.isCoOwner = true;
                }
            });

            $scope.isAdminLoggedIn($scope.userId, function(isAdmin){
                if( isAdmin && !$scope.isOwnerOrCoOwner($scope.userId)){
                    $scope.isOwned = true; //this over rides the other isOwned set in loadProof
                    $scope.isAdmin = true;
                    $scope.makeMetaReadOnly();
                }

                if($scope.proofClosed == true){
                    $scope.showOwnedReadOnly = true;
                }

            });

            $scope.isLoaded = true;
            $scope.loadCoOwners().then(function() {
                var isBriefRecipient = $scope.$proof.recipient === $scope.userId && $scope.type === 'brief';
                $scope.canManage = $scope.isOwnerOrCoOwner($scope.userId) || isBriefRecipient || $scope.isTeamAdmin;
                $scope.proofInfoCtrl.props.tagsProps.readOnly = !$scope.canManage;
            });
            if (!$scope.$$phase) {
                $scope.$apply();
            }
        });

        this.dateTimePickerIconButtonProps = {
            onChange: $scope.onDateChange,
            size: 17,
        };
    };
});

ppxGlobalControllers.controller('UserDetails', [

    '$scope', '$rootScope', '$routeParams', '$location', '$sce', 'appService', 'UserService', 'DataService', '$api', '$q', 'proofRepositoryService', 'PPProofStatus', 'proofInfoService',
    function($scope, $rootScope, $routeParams, $location, $sce, appService, UserService, DataService, $api, $q, proofRepositoryService, PPProofStatus, proofInfoService) {

        $scope.application = UserService; //make the application class avaliable to this scope
        $scope.appService = appService;

        var userData = $scope.application.GetUserData();

        $scope.userId = "";
        $scope.proofId = "";
        $scope.workflowId = "";
        $scope.usersStepId = "";
        $scope.currentStepId = "";

        $scope.loggedInUserId = userData.userId;

        $scope.showDetails = false;
        $scope.name = "";
        $scope.email = "";
        $scope.commentCount = 0;
        $scope.formattedDateStr = "";
        $scope.commentReady = false;
        $scope.nudgedAt = "";
        $scope.nudgeDateFormatStr = "";
        $scope.proofStatus = 0;

        $scope.isOwner = false;
        $scope.isOwnersAvatar = false;
        $scope.coOwners = [];
        $scope.userFinished = false;
        $scope.userSkipped = false;
        $scope.isOnStep = false;
        $scope.isOnPreviousStep = false;
        $scope.isPreviousStep = false;
        $scope.isFutureStep = false;
        $scope.userHasNoAccessYet = false;
        $scope.isSelf = false;
        $scope.userLocked = false;
        $scope.isFinalApproverStep = false;
        $scope.isFinalApprover = false;
        $scope.skipped = false;
        $scope.nudged = false;
        $scope.proofLocked = false;
        $scope.userIsMandatory = false;
        $scope.showConfirm = false;
        $scope.nudgedTimes = [];
        $scope.type = 'proof';
        $scope.onDate = false;

        $scope.getUserCommentCount = function (proofId, userId) {
            return $api.proofs.commentUserCount.fetch(proofId).then(function (data) {
                var meta = null;
                if (userId in data)
                    meta = data[userId]; // {Count,Timestamp}
                return meta;
            });
        };



        $rootScope.$on("openUserDetails", function(vars, args){
            $scope.appService.showTranslatedLoaderMessage("loader-message.grabbing-profile");
            $scope.application.showLoader();
            $scope.userId = args.userId;
            $scope.proofId = args.proofId;
            $scope.workflowStep = args.workflowStep;

            console.debug("test data", $scope.workflowStep);
            if($scope.workflowStep){
                $scope.isPreviousStep = ($scope.workflowStep.isComplete || $scope.workflowStep.Complete);
                $scope.isFutureStep = !($scope.workflowStep.isVisible || $scope.workflowStep.Visible);
            } else {
                $scope.isPreviousStep = false;
            }


            $scope.loadUser();
            $scope.loadProof().then(function(){
                if($scope.userCommentCount > 0){
                    $scope.formattedDateStr = $scope.getProcessedDateString();
                    $scope.commentCount = $scope.userCommentCount;
                }

                // Where a user has been nudged in 1 or multiple steps, return the most recent time that user was nudged.
                var latestNudgedTime;
                if ($scope.nudgedTimes.length) {
                    latestNudgedTime = $scope.nudgedTimes.reduce(function(a, b) {
                        if (a > b) {
                            return a;
                        } else {
                            return b;
                        }
                    });
                }

                if ($scope.nudgedTimes.length) {
                    $scope.nudgedAt = latestNudgedTime.date;
                }

                if ($scope.nudgedAt != "0001-01-01T00:00:00" && $scope.nudgedAt != "") {
                    $scope.processDateNudge($scope.nudgedAt);
                }

                if ($scope.workflowStep) {
                    $scope.usersStepId = $scope.workflowStep.stepId;
                }

                if( $scope.userId == $scope.loggedInUserId ){
                    $scope.isSelf = true;
                }

                if( $scope.isFinalApproverStep && $scope.isFinalApprover && ( $scope.proofStatus >= 30 ) ){
                    $scope.userLocked = true;
                }

                console.log("params: ",$scope.isPreviousStep, $scope.isFutureStep, $scope.isOnStep, $scope.userFinished, $scope.userSkipped, $scope.isFinalApproverStep, $scope.isFinalApprover, ( $scope.proofStatus >= 30 ));

                $scope.showDetails = true;

                $scope.doUserHasAccessYetFunc();
                console.log("params: ",$scope.isPreviousStep, $scope.isFutureStep, $scope.isOnStep, $scope.userFinished);
                $scope.appService.hideLoader();
            });
        });

        $scope.cancelSkip = function(){
            $scope.showConfirm = false;
        };

        $scope.getProcessedDateString = function() {
            $scope.onDate = false;
            var date = moment.utc($scope.userCommentTimestamp).local();
            var now = moment();
            var diffDays = now.diff(date, 'days');
            var diffMins = now.diff(date, 'minutes');
            var dateString = '';
            if (diffMins === 0) {
                dateString = 'datetime.just-now';
            } else if (diffMins === 1) {
                dateString = 'datetime.1-minute-ago';
            } else if (diffMins > 1 && diffMins < 60) {
                dateString = 'datetime.minutes-ago';
            } else if (diffDays === 1) {
                dateString = 'datetime.1-day-ago';
            } else if (diffDays > 1 && diffDays < 7) {
                dateString = 'datetime.days-ago';
            } else {
                dateString = date.format('Do MMMM YYYY');
                $scope.onDate = true;
            }
            return dateString;
        }

        $scope.doUserHasAccessYetFunc = function(){
            if($scope.isFutureStep && !$scope.isOnPreviousStep && !$scope.isOnStep && !$scope.isOwnersAvatar){
                $scope.userHasNoAccessYet = true;
            }
        };

        $scope.showComments = function() {
            var userFilter = 'user:' + $scope.userId;
            proofInfoService.close();
            if ($location.path().indexOf('/proof') !== -1) {
                $rootScope.$broadcast('commentUserFilterSet', userFilter);
            } else {
                $location.url('proof/static/' + $scope.proofId + '?filter='  + userFilter);
            }
            $scope.close();
        };

        $scope.close = function(){
            $scope.userId = "";
            $scope.showDetails = false;
            $scope.name = "";
            $scope.email = "";
            $scope.formattedDateStr = "";
            $scope.commentCount = 0;
            $scope.usersComments = [];
            $scope.nudgedAt = "";
            $scope.nudgeDateFormatStr = "";
            $scope.usersStepId = "";
            $scope.proofId = "";
            $scope.workflowId = "";
            $scope.currentStepId = "";
            $scope.isPreviousStep = false;
            $scope.isFutureStep = false;
            $scope.userHasNoAccessYet = false;
            $scope.isOwner = false;
            $scope.isOwnersAvatar = false;
            $scope.coOwners = [];
            $scope.userFinished = false;
            $scope.userSkipped = false;
            $scope.userLocked = false;
            $scope.isOnStep = false;
            $scope.isOnPreviousStep = false;
            $scope.isSelf = false;
            $scope.isFinalApproverStep = false;
            $scope.isFinalApprover = false;
            $scope.proofStatus = 0;
            $scope.skipped = false;
            $scope.nudged = false;
            $scope.proofLocked = false;
            $scope.userIsMandatory = false;
            $scope.userCommentCount = 0;
            $scope.userCommentTimestamp = null;
            $scope.nudgedTimes = [];
            $scope.type = 'proof';
        };

        $scope.loadUser = function(){
            $api
                .users
                .load
                .fetch($scope.userId)
                .then(function (user) {
                    $scope.name = user.Name;
                    $scope.email = user.Email;
                });

        };


      $scope.processDateNudge = function(date){
            var dt = new DateTime();
            var date = dt.getFormatedDateStrForComment(date);

            if(strstr(date, "minutes ago")){
                $scope.nudgeDateFormatStr = date;
            }else if(strstr(date, "just now")){
                $scope.nudgeDateFormatStr =  date;
            }else if(strstr(date, "1 minute ago")){
                $scope.nudgeDateFormatStr = date;
            }else if(strstr(date, "1 day ago")){
                $scope.nudgeDateFormatStr = date;
            }else if(strstr(date, "days ago")){
                $scope.nudgeDateFormatStr = date;
            }else{
                $scope.nudgeDateFormatStr = "on the " + date;
            }
        };

        $scope.usersComments = [];

        $scope.loadCoOwner = function(){
            return $api.proofs.teamGetList.fetch($scope.proofId);
        };

        $scope.isInCoOwners = function(userId) {
            return ($scope.coOwners.indexOf(userId) >= 0)? true : false;
        };

        $scope.managersOwner = function(){
            return $api.workflows.teamGetList.fetch($scope.workflowId);
        };

        $scope.loadProof = function(){
            return $api
                .proofs
                .getById
                .fetch($scope.proofId)
                .then(function (proof) {

                    var promises = [];

                    console.log("proof",  proof);

                    if( proof.UserId == $scope.loggedInUserId ){
                        $scope.isOwner = true;
                    }

                    if( proof.ProofLockerId == $scope.userId ){
                        $scope.userLocked = true;
                    }

                    $scope.proofLocked = proof.Locked;

                    $scope.proofStatus = parseInt(proof.Status);

                    if (window.isBriefId($scope.proofId)) {
                        $scope.type = 'brief';
                    }

                    $scope.loadCoOwner().then(function(coOwners){

                        var owners = [];
                        coOwners.forEach(function(owner, indx) {
                            owners.push(owner.UserId);
                            $scope.coOwners.push(owner.UserId);

                        });

                        if(( inArray(owners, $scope.loggedInUserId) )){
                            $scope.isOwner = true;
                        }
                        owners.length = 0;

                    });

                    if(proof.WorkflowId){
                        $scope.workflowId = proof.WorkflowId;

                        $scope.managersOwner().then(function(managers){

                            var mans = [];
                            managers.forEach(function(person, indx){
                                mans.push(person.UserId);
                                $scope.coOwners.push(person.UserId);
                            });

                            if( ( inArray(mans, $scope.loggedInUserId) )){
                                $scope.isOwner = true;
                            }

                            mans.length = 0;

                        });


                        var pages = parseInt(proof.Pages);

                        var pagesArray = [];
                        var i = 1;
                        while(i <= pages){
                            pagesArray.push(i);
                            i++;
                        }

                        promises.push($api.workflows.getDetailedById.fetch($scope.workflowId).then(function(wf){
                            if(wf){

                                return $q.all(wf.WorkflowSteps.map(function(step, indx) {

                                        if(step.Visible == 1 && step.Complete == 0){
                                            $scope.currentStepId = step.StepId;
                                            if(step.Position == "1000"){
                                                $scope.isFinalApproverStep = true;
                                            }
                                        }

                                        // For users who are in multiple workflow steps, return an array of date/times they have been nudged in all steps they are included in.
                                        for (var i = 0; i < step.Users.length; i++) {
                                            if (step.Users[i].UserId === $scope.userId && (step.Users[i].NudgedInStep !== "0001-01-01T00:00:00" && step.Users[i].NudgedInStep !== "")) {
                                                $scope.nudgedTimes.push(new DateTime(step.Users[i].NudgedInStep));
                                            }
                                        }

                                        if(step.Position == "1000"){
                                            var stepUser = step.Users.find(function(user){
                                                return user.UserId == $scope.userId;
                                            });
                                            if(stepUser){
                                                $scope.isFinalApprover = true;
                                                if(step.Visible == 1 && step.Complete == 0){
                                                    $scope.isOnStep = true;
                                                }
                                                $scope.usersStepId = step.StepId;
                                                $scope.nudgedAt = stepUser.NudgedInStep;

                                                if($scope.currentStepId == $scope.usersStepId){
                                                    $scope.userIsMandatory = true;
                                                    $scope.userFinished = stepUser.CompleteInStep;
                                                    $scope.userSkipped = stepUser.SkippedInStep;
                                                }
                                            }
                                        }else{
                                            step.Users.forEach(function(val){
                                                if(val.UserId == $scope.userId){

                                                    console.log("val", val);


                                                    if(step.Visible == 1 && step.Complete == 0){
                                                        $scope.isOnStep = true;
                                                    }
                                                    if(step.Visible == 1 && step.Complete == 1){
                                                        $scope.isOnPreviousStep = true;
                                                    }
                                                    //this below can be used when we need to know if clicked user is in future steps too
                                                    // if(step.Visible == 0 && step.Complete == 0){
                                                    //     $scope.isOnFutureStep = true;
                                                    // }
                                                    $scope.usersStepId = step.StepId;
                                                    if($scope.currentStepId == $scope.usersStepId){
                                                        $scope.userIsMandatory = val.MandatoryInStep;
                                                        $scope.userFinished = val.CompleteInStep;
                                                        $scope.userSkipped = val.SkippedInStep;
                                                        $scope.nudgedAt = val.NudgedInStep;
                                                    }
                                                }
                                            });
                                        }
                                    //set isOwnersAvatar to true, if clicked avatar is a owner or coowner
                                    if(($scope.userId == proof.UserId) || $scope.isInCoOwners($scope.userId)){
                                        $scope.isOwnersAvatar = true;
                                    }
                                }));
                            }

                        }));

                    }

                    promises.push($scope.getUserCommentCount($scope.proofId, $scope.userId).then(function (meta) {
                        if (meta) {
                            $scope.userCommentCount = meta.Count;
                            $scope.userCommentTimestamp = meta.Timestamp;
                        }
                    }));

                    return $q.all(promises);

                });

        };

    }]);
