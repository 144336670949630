/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
angular
    .module('moment', [])
    .factory('moment', function () {
        // Make sure the moment global object's default locale is 'en',
        // as moment-js changed the default to be the last locale file imported.
        moment.locale('en');
        return window.moment;
    });
