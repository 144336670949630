/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.filter('verticalList', function () {
    return (arr) => {
        if (!arr || !arr.length) {
            return '';
        } else if (arr.length === 1) {
            return arr[0];
        } else {
            return arr.join('<br />');
        }
    };
});
