/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
angular
    .module('ppxGlobalControllers')
    .factory('navigationService', function ($rootScope, $location, storageService) {
        var navigationStorage = storageService('pageproof.app.navigation.'),
            navigationService;

        return navigationService = {
            /**
             * Sets the user's intended page url.
             *
             * @param {String} url
             */
            setIntended: function (url) {
                if (navigationService.hasIntended()) {
                    // Warn the developer if they're unintentionally overriding the existing intended url
                    console.warn('[navigationService] Call to setIntended will override the existing value (%s).',
                        navigationService.getIntended());
                }

                navigationStorage.set('intended', url);
            },
            /**
             * Whether the user has an intended page url.
             *
             * @returns {String}
             */
            hasIntended: function () {
                return navigationStorage.has('intended');
            },
            /**
             * Returns the users intended page url.
             *
             * @returns {String}
             */
            getIntended: function () {
                return navigationStorage.get('intended');
            },
            /**
             * Redirects the user to a page where they intended to go originally
             * OR take them to a fallback url (which the javascript intends).
             *
             * @param {String} fallback
             * @see $location
             */
            redirectIntended: function (fallback) {
                if (navigationService.hasIntended()) {
                    var intendedUrl = navigationService.getIntended(),
                        intendedParsedUrl = navigationService.$$parseUrl(intendedUrl);

                    // When the location next changes, check if it ended up where it was intending, and if so
                    // clear the intended url. So clearing the intended url is purely internal for redirects, but
                    // can still be used if manually removing the intended url.
                    /* var off = $rootScope.$on('$locationChangeSuccess', function (event, newState) {
                        if (newState === intendedParsedUrl) {
                            navigationService.clearIntended();
                        }

                        // Remove the event listener ;)
                        off();
                    }); */

                    // Attempt to redirect the user to their intended location
                    $location.url(intendedUrl);
                }
                else if (angular.isDefined(fallback)) {
                    // There was no intended page url, so we can fallback (if there is a fallback)
                    $location.url(fallback);
                }
                else {
                    // Warn the developer, because there was no intended url, or fallback ;(
                    console.debug('[navigationService] Did not have an intended URL, or a fallback.');
                }
            },
            /**
             * Clears the users intended page url.
             *
             * @see #redirectIntended
             */
            clearIntended: function () {
                navigationStorage.remove('intended');
            },
            /**
             * Returns a fully fledged url from a segmented url.
             *
             * @param {String} url
             */
            $$parseUrl: function (url) {
                // Create the url parsing element (and set the url)
                var anchor = document.createElement('a');
                anchor.href = url;

                // Extract the url from the element (and gc the element)
                var parsed = anchor.href;
                anchor = null;

                // Return the parsed url ;)
                return parsed;
            },

            /**
             * Scrolls to an element on screen.
             *
             * @param {HTMLElement|jQLite|jQuery|String} selector
             * @param {Integer} [time]
             * @param {Function} [callback]
             */
            scrollTo: function (selector, time, callback) {
                var mainElement = angular.element('[ng-view]'),
                    element = angular.element(selector),
                    offset = element.offset(),
                    scrollTop = offset && (offset.top - 100),
                    currentScrollTop = mainElement.prop('scrollTop');

                if ( ! angular.isDefined(time)) {
                    time = 1000;
                }

                // Ensure that the callback is, in fact, a function
                callback = callback || angular.noop;

                console.log({ selector: selector, time: time, scrollTop: scrollTop, currentScrollTop: currentScrollTop });

                // Check if the current scrollTop is equal to the (normalised) scrollTop
                // if it is - then callback immediately, as the animation is not required.
                if (Math.max(0, scrollTop) === mainElement.prop('scrollTop')) {
                    callback();
                    return;
                }

                mainElement.stop().animate({
                    scrollTop: currentScrollTop + scrollTop
                }, time, callback);
            }
        }
    })
    .run(function ($rootScope, navigationService) {
        $rootScope.$on('$locationChangeSuccess', function (event, newState) {
            if (navigationService.hasIntended()) {
                var intendedUrl = navigationService.getIntended(),
                    intendedParsedUrl = navigationService.$$parseUrl(intendedUrl);

                if (newState === intendedParsedUrl) {
                    navigationService.clearIntended();
                }
            }
        })
    });
