/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/**
 * Created by chris on 6/03/15.
 */

ppxControllers.controller('fileSize', ['$scope', '$routeParams', '$location', '$sce', 'UserService', function($scope, $routeParams, $location, $sce, UserService) {
    $scope.application = UserService; //make the application class avaliable to this scope

    $scope.back = function(){

        if($scope.application.IsLoggedIn()){
            $location.backward().path("dashboard");
        }else{
            $location.backward().path("/");
        }

        if(!$scope.$$phase) $scope.$apply();
    };

    $scope.bytesToSize = function(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return 'n/a';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (i == 0) return bytes + ' ' + sizes[i];
        return (bytes / Math.pow(1024, i)).toFixed(0) + ' ' + sizes[i];
    };

    $scope.size = $scope.bytesToSize($scope.application.GetConfigSetting("MaxUploadSize"));
    
    $scope.application.hideLoader();

}]);
