/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.filter('version', function () {
    return (version, type) => {
        const parts = String(version).split('.');
        switch (type) {
            case 'major': return parts[0];
            case 'minor': return parts[1];
            case 'patch': return parts[2];
            default: return parts.slice(0, 3).join('.');
        }
    };
});
