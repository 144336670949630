/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
angular
    .module('ppxGlobalControllers')
    .constant('seoConfig', {
        properties: ['description', 'author', 'lastModified']
    })
    .factory('seoService',
        function ($translate) {
            var seo = {};

            return {
                /**
                 * Sets (and clears in the process) the meta details.
                 *
                 * @see {seoService.update}
                 * @param {Object} meta
                 * @returns {Object}
                 */
                set: function (meta) {
                    // Clear out the data that is currently stored
                    var old = this.clear();

                    // Set all the updated properties
                    this.update(meta);

                    // Return the old data stored in the `seo` object, this is for convenience
                    return old;
                },
                /**
                 * Sets the meta details, updating those that are provided, and keeping old
                 * ones in existence. Using the {set} function will clear out any existing meta
                 * details, and set the ones provided.
                 *
                 * @see {seoService.set}
                 * @param meta
                 */
                update: function (meta) {
                    if ('pageTitle' in meta && typeof meta.translatePageTitle === 'undefined') {
                        // If we're updating pageTitle, but we've omitted the translatePageTitle flag, set the flag
                        // to false. This prevents calls to `seoService.update()` from updating pageTitle but not
                        // resetting the translatePageTitle flag back to false.
                        meta.translatePageTitle = false;
                    }
                    if ('title' in meta && typeof meta.translateTitle === 'undefined') {
                        meta.translateTitle = false;
                    }
                    Object.keys(meta).forEach(function (key) {
                        // Update the properties passed through from the `update` request
                        seo[key] = meta[key];
                    });
                },
                /**
                 * Clears out any existing meta details.
                 *
                 * @see {seoService.update}
                 * @see {seoService.set}
                 * @returns {Object}
                 */
                clear: function () {
                    var old = {};

                    Object.keys(seo).forEach(function (key) {
                        // If there is data set copy it to `old` (it's important that the check
                        // is there, because we don't want to copy `undefined` or `null` values to
                        // the old object. Seeing as the result of a `delete` will cause the value
                        // to be set to `undefined`.
                        if (seo[key]) old[key] = seo[key];

                        // Delete the property from the seo object
                        delete seo[key];
                    });

                    return old;
                },
                /**
                 * Gets a property from the seo object.
                 *
                 * @param {String} property
                 * @returns {*|undefined}
                 */
                get: function (property) {
                    return seo[property];
                },
                /**
                 * Checks whether the is currently a value in the seo object.
                 *
                 * @param {String} property
                 * @returns {Boolean}
                 */
                has: function (property) {
                    return seo.hasOwnProperty(property) && !! seo[property];
                },
                /**
                 * Returns all the properties and their values stored in the seo object.
                 * This object is read-only, and will not update the privately stored seo object.
                 *
                 * @returns {Object}
                 */
                all: function () {
                    var all = {};

                    Object.keys(seo).forEach(function (key) {
                        // Copies the value of the seo object and places within `all`
                        all[key] = seo[key];
                    });

                    return all;
                }
            };
        }
    );
