/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

class DashboardController extends BaseDashboardController {

    /**
     * @constructor
     * @ngInject
     */
    constructor (
        browserService,
        modalService
    ) {
        super();
        this.browserService = browserService;
        this.modalService = modalService;

        const props = this.props = {
            user: this.user,
            from: 'dashboard',
            showDialog: (type, options) => this.showDialog(type, options),
        };
    }

    hideMobileKeyboard(event) {
        if (this.browserService.is('mobile')) {
            event.target.blur();
        }
    }

    showDialog(type, options) {
        switch(type){
            case 'print': {
                const { destroy } = this.modalService.createWithComponent('PrintOptionsContainer', this.getPrintDialogProps(options));
                this.dialog = destroy;
                break;
            }
            default: {
                break;
            }
        }
    }

    getPrintDialogProps({proofId, proofType}) {
        return {
            proofId,
            proofType,
            onCancel: () => this.dialog(),
            onFinished: () => this.dialog()
          }
    }

    closeDialog() {
        this.dialog = null;
    }

    // todo implement combine proof functionality

    // confirmCombine(previousVersion, newVersion) {
    //     this.previousVersion = previousVersion;
    //     this.newVersion = newVersion;
    //     this.queueThumbnail(this.previousVersion, true);
    //     this.dialog = {
    //         type: 'confirm-combine-proofs',
    //         location: 'modal',
    //     }
    //     if (!this.$$.$scope.$$phase) this.$$.$scope.$apply();
    // }

    // combineProofs() {
    //     if (!this.newVersion.isVersion
    //         && this.newVersion.isOwnerOrCoOwner
    //         && this.previousVersion.hasUploader) {
    //         this.$$.sdk.proofs.versions.attach(this.previousVersion.id, this.newVersion.id)
    //             .then(() => {
    //                 this.dismissCombineProofs();
    //                 // this.refreshCurrentTab();
    //             });
    //     }
    // }

    // dismissCombineProofs() {
    //     this.previousVersion = this.newVersion = null;
    // }
    /**
     * Selects a proof for doing bulk actions on it
     * @param {PPDashboardProof} proof
     */
}

app.controller('DashboardController', DashboardController);
