/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

const DEFAULT_COMMENT_DURATION = 0;

app.factory('PPCompareProof', (
    $q,
    PPProofPermissions,
    PPProofPage,
    PPProofComment,
    commentRepositoryService,
    PPProofType,
    PPProofStatus,
    proofCacheService,
    proofRepositoryService
) => {
    class PPCompareProof {
        /**
         * The proof to compare.
         *
         * @type {String}
         */
        proofId = null;

        /**
         * The comments of shown page.
         *
         * @type {PPProofComment[]}
         */
        comments = [];

        /**
         * The shown page.
         *
         * @type {PPProofPage}
         */
        page = {
            pageNumber: 1,
        };

        /**
         * The shown page number
         *
         * @type {Number}
         */
        pageNumber = 1;

        /**
         * The proof object
         *
         * @type {Object}
         */
        proofData = {};

        /**
         * If comments have loaded for shown page
         *
         * @type {Boolean}
         */
        commentsLoaded = false;

        flags = {
            commentDuration: DEFAULT_COMMENT_DURATION,
            updatePermissions: true,
            orderCommentsBy: '-createdAt',
        };

        user = null;

        /**
         * The permission on proof
         *
         * @type {PPProofPermissions}
         */
        permissions = null;

        /**
         * The array of allowed versions of proof
         * @type {Array}
         */
        allowedVersions = [];

        /**
         * type of the compare side (left | right)
         */
        type = null;

        constructor(user, type, pageNumber) {
            this.user = user;
            this.type = type;
            this.pageNumber = pageNumber ? parseInt(pageNumber) : 1;
        }

        get isGifFile() {
            return this.proofData.extension === 'gif';
        }

        get isScrubberVisible() {
            return !this.isGifFile;
        }

        updateFlags() {
            this.flags.orderCommentsBy = (this.proofData.isVideo || this.proofData.isAudio)
                ? 'time'
                : '-createdAt';
        }

        loadProofData() {
            if (this.proofId) {
                return proofCacheService.getProof(this.proofId)
                    .then(retData => {
                        this.proofData = retData;
                        this.pages = retData.pages.length;
                        this.title = retData.title;
                        this.version = retData.version;
                        this.fileId = retData.fileId;
                    });
            }
        }

        loadProofPermissons() {
            const permissions = new PPProofPermissions(this.user, this.proofData);
            this.proofData.$permissions = permissions;
            this.permissions = permissions.fetchUserPermissions();
        }

        getCommentPinDataForPage() {
            if (this.proofId && this.pageNumber) {
                this.comments = [];
                return commentRepositoryService.loadCommentsForProofWithPageNumber(this.proofId, this.pageNumber).then((retData) => {
                    if (retData.Comments.length > 0) {
                        this.comments = retData.Comments
                            .filter(comment => comment.Parent === "") //only parent comments
                            .map((comment) => {
                                let commentObj = PPProofComment.from(comment);
                                commentObj.showMark = true;
                                commentRepositoryService.decryptComment(commentRepositoryService.transformServerCommentObject(comment))
                                    .then((decryptedComment) => {
                                        commentObj.decryptedComment = commentObj.comment = decryptedComment;
                                        commentObj.highlight = this.getHighlight(PPProofComment.parseLabel(decryptedComment));
                                    });
                                return commentObj;
                        });
                        this.addReplies(retData.Comments);
                    }
                });
            }
        }

        loadAllComments() {
            return (
                proofRepositoryService
                .$getCommentsById(this.proofData.id, this.proofData)
            );
        }

        addReplies(replies) {
            replies.forEach((reply) => {
                if (reply.Parent) {
                    let replyObj = PPProofComment.from(reply);
                    commentRepositoryService.decryptComment(commentRepositoryService.transformServerCommentObject(reply)).then((decryptedComment) => {
                        replyObj.decryptedComment = replyObj.comment = decryptedComment;
                    });
                    this.comments.some((comment) => {
                        if (comment.id === replyObj.parentId) {
                            comment.replies.push(replyObj);
                        }
                    });
                }
            });
        }

        addNewlyCreatedComment(comment) {
            comment.highlight = this.getHighlight(PPProofComment.parseLabel(comment.decryptedComment));
            comment.showMark = true;
            this.comments.push(comment);
        }

        /**
         * if a user can reply when canAddCommentReplies is off (only reviewers can't reply when canAddCommentReplies is off)
         * @returns {boolean}
         */
        canReply() {
            return this.proofData.canAddCommentReplies ||
                (
                  this.proofData.isOwnerOrCoOwner ||
                  this.proofData.isEditor ||
                  this.proofData.recipient === this.user.id
                );
        }

        getHighlight(label) {
            if (label) {
                label = label.toLowerCase();
                switch (label) {
                    case 'approved': return 'green';
                    case 'highlight': return 'yellow';
                }
            }
            return null;
        }

        calculateCommentCounts() {
            this.proofData.pages[this.pageNumber - 1].comments = this.comments;
            return Promise.resolve(this.proofData.calculateCommentCounts());
        }

        canShowCommentTodoDoneCounts() {
            // Should show only if shown page have different type of comment counts, not all of the pages
            // As all page comments only get loads if more then one page, hence pages might not be all time exist, as well all comment counts on those pages
            // if only one page in proof that means proof.todoCOunt, proof.doneCount will also be valid for comment header
            const page = this.proofData.pages ? this.proofData.pages[this.pageNumber - 1] : this.proofData;
            return this.permissions && this.comments.length > 0 && (
                (this.permissions.proofer.commentLevel.canMarkAll) ||
                (this.permissions.proofer.commentLevel.canViewChanged && page.todoCount >= 1) ||
                (this.permissions.proofer.commentLevel.canViewDone && page.doneCount >= 1) ||
                (page.agreeCount >= 1) ||
                (page.labelCount) ||
                (page.attachmentCount >= 1)
            );
        }

    }

    return PPCompareProof;
});
