/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.factory('PPProofWorkflowUser', function (PPModel, PPUser) {
    let { dateTransformer } = PPModel;

    const proofWorkflowUserTransformer = {
        isMandatory: 'MandatoryInStep',
        isSkipped: 'SkippedInStep',
        isComplete: 'CompleteInStep',
        isApprover: 'ApproverInStep',
        isViewOnly: 'IsViewOnlyInStep',
        isInviter: 'IsInviter',
        isNudged: (data) => {
            return dateTransformer('NudgedInStep')(data) !== null;
        },
        nudgedAt: dateTransformer('NudgedInStep'),
        decision: 'Decision',
        decisionDate: dateTransformer('DecisionDate')
    };
 
    class PPProofWorkflowUser extends PPUser {
        /**
         * Update the workflow from the workflow data.
         *
         * @param {Object} data
         */
        updateFromProofWorkflowUserData (data) {
            this.transform(data, proofWorkflowUserTransformer);
        }

        /**
         * @param {Object} proofWorkflowUserData
         */
        static from (proofWorkflowUserData) {
            let proofWorkflowUser = new this();
            proofWorkflowUser.updateFromUserData(proofWorkflowUserData);
            proofWorkflowUser.updateFromProofWorkflowUserData(proofWorkflowUserData);
            return proofWorkflowUser;
        }
    }

    return PPProofWorkflowUser;
});
