/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class TabsController {
    /**
     * The registry of scopes.
     *
     * @type {$rootScope.Scope[]}
     */
    tabs = [];

    /**
     * The registry of labels.
     *
     * @type {angular.element[]}
     */
    labels = [];

    /**
     * The current tab id.
     *
     * @type {String}
     */
    current = null;

    /**
     * @param {$rootScope.Scope} $scope
     * @constructor
     */
    constructor ($scope) {
        $scope.$watchCollection(() => this.tabs, () => this.applyCurrentTab());
    }

    /**
     * Adds a tab from the registry.
     *
     * @param {$rootScope.Scope} scope
     */
    addTab (scope) {
        this.tabs.push(scope);
    }

    /**
     * Removes a tab from the registry.
     *
     * @param {$rootScope.Scope} scope
     */
    removeTab (scope) {
        this.tabs.splice(this.tabs.indexOf(scope), 1);
    }

    /**
     * Sets the tabs controller's current tab (id).
     *
     * @param {String} id
     */
    setCurrentTab (id) {
        this.current = id;
        this.applyCurrentTab();
    }

    /**
     * Applies the current tab.
     */
    applyCurrentTab () {
        this.tabs.forEach((scope) => {
            scope.tabCtrl.setCurrent(scope.tabCtrl.getId() === String(this.current));
        });
    }

    orderBy ({ tabCtrl } = tabScope) {
        return tabCtrl.priority;
    }

    addLabel(label) {
        this.labels.push(label);
        return () => this.removeLabel(label);
    }

    removeLabel(label) {
        this.labels.splice(this.labels.indexOf(label), 1);
    }
}

class TabController {
    /**
     * The tab's unique id.
     *
     * @type {String}
     */
    id = null;

    /**
     * The tab's title element.
     *
     * @type {String|angular.element}
     */
    title = null;

    /**
     * Whether the tab is currently active.
     *
     * @type {Boolean}
     */
    current = false;

    /**
     * The priority of the tab.
     *
     * @type {Number}
     */
    priority = 1;

    /**
     * Whether to highlight the tab.
     *
     * @type {Boolean}
     */
    highlight = false;

    /**
     * Sets the tab's id.
     *
     * @param {String} id
     */
    setId (id) {
        this.id = id;
    }

    /**
     * Gets the tab's id.
     *
     * @returns {String}
     */
    getId () {
        return this.id;
    }

    /**
     * Set the tab's title element.
     *
     * @param {String|angular.element} title
     */
    setTitle (title) {
        this.title = title;
    }

    /**
     * Gets the tab's title element.
     *
     * @returns {String|angular.element}
     */
    getTitle () {
        return this.title;
    }

    /**
     * Sets whether the tab is current (defaults to true).
     *
     * @param {Boolean} [current]
     */
    setCurrent (current = true) {
        this.current = current;
    }

    /**
     * Returns whether the tab is currently the active one.
     *
     * @returns {Boolean}
     */
    isCurrent () {
        return this.current;
    }

    /**
     * Returns whether the tab is highlighted.
     *
     * @returns {Boolean}
     */
    isHighlighted () {
        return this.highlight;
    }
}

function TabsDirective () {
    return {
        require: 'ngModel',
        controller: 'TabsController',
        controllerAs: 'tabsCtrl',
        templateUrl: 'templates/partials/globals/tabs.html',
        replace: true,
        transclude: true,
        link: function (scope, element, attr, modelCtrl) {
            let tabsCtrl = scope.tabsCtrl;

            /**
             * When the `ngModel` `$modelValue` changes, update the `currentTab`, ensure that the `$modelValue`
             * is set before attempting to do so. This does not effect the controller if the `$modelValue` is triggered
             * from the controller itself.
             */
            scope.$watch(() => modelCtrl.$modelValue, $modelValue => {
                if ($modelValue) {
                    tabsCtrl.setCurrentTab($modelValue);
                }
            });

            /**
             * When the `currentTab` changes, update the current `$viewValue`.
             */
            scope.$watch(() => tabsCtrl.current, current => {
                if (current) {
                    modelCtrl.$setViewValue(current);
                }
            });
        }
    };
}

function TabDirective (directiveHelper) {
    return {
        require: '^tabs',
        controller: 'TabController',
        controllerAs: 'tabCtrl',
        templateUrl: 'templates/partials/globals/tab.html',
        replace: true,
        transclude: true,
        scope: true,
        link: function (scope, element, attr, tabsCtrl) {
            let tabCtrl = scope.tabCtrl;

            tabsCtrl.addTab(scope);

            scope.$on('$destroy', () => {
                tabsCtrl.removeTab(scope);
            });

            if (attr.id) {
                tabCtrl.setId(attr.id);
            }

            directiveHelper.oneWayBinding(scope, attr, 'tabHighlight', tabCtrl, 'highlight');

            attr.$observe('name', name => {
                if (name.length) {
                    tabCtrl.setTitle(name);
                }
            });
        }
    };
}

function TabPriorityDirective ($parse) {
    return {
        require: ['tab'],

        link (scope, element, attr, [tabCtrl] = ctrls) {
            let $$priority = $parse(attr.tabPriority);

            scope.$watch(() => $$priority(scope), (priority) => {
                if (angular.isNumber(priority)) {
                    tabCtrl.priority = priority;
                } else {
                    tabCtrl.priority = null;
                }
            });
        }
    };
}

function TabTitleDirective () {
    return {
        require: '^tab',
        template: '',
        replace: true,
        transclude: true,
        link: (scope, element, attr, tab, transclude) => {
            tab.setTitle(transclude(scope, angular.noop));
        }
    };
}

function TabLabelDirective () {
    return {
        require: ['^tabs'],
        template: '',
        replace: true,
        transclude: true,
        link: (scope, element, attr, [tabsCtrl], transclude) => {
            scope.$on('$destroy', tabsCtrl.addLabel(transclude(scope, angular.noop)));
        }
    };
}

function TabTranscludeDirective () {
    return {
        link: (scope, element, attr) => {
            scope.$watch(
                () => {
                    return (scope.tab && scope.tab.tabCtrl || scope.tabCtrl)[attr.tabTransclude];
                },
                transclude => {
                    if (transclude) {
                        element.html(transclude);
                    }
                }
            );
        }
    };
}

app
    .controller('TabsController', TabsController)
    .controller('TabController', TabController)
    .directive('tabs', TabsDirective)
    .directive('tab', TabDirective)
    .directive('tabTitle', TabTitleDirective)
    .directive('tabPriority', TabPriorityDirective)
    .directive('tabLabel', TabLabelDirective)
    .directive('tabTransclude', TabTranscludeDirective);
