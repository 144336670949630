/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class TinkerController {
    /**
     * The data to inspect.
     *
     * @type {*}
     */
    data = null;

    /**
     * Returns the type of value.
     *
     * @param {*} value
     * @returns {String}
     */
    getType (value) {
        switch (true) {
            case value == null: return 'null';
            case angular.isArray(value): return 'array';
            case angular.isObject(value): return 'object';
            case angular.isNumber(value): return 'number';
            default: return typeof value;
        }
    }
}

function TinkerDirective ($compile, directiveHelper) {
    return {
        require: ['tinker'],
        templateUrl: 'templates/partials/proof/components/tinker.html',
        replace: true,
        controller: 'TinkerController',
        controllerAs: 'tinkerCtrl',
        scope: true,

        compile (element, link) {
            var contents = element.contents().remove(),
                compiled;

            return {
                post (scope, element, attr, [tinkerCtrl]) {
                    if ( ! compiled) {
                        compiled = $compile(contents);
                    }

                    compiled(scope, (clone) => {
                        element.append(clone);
                    });

                    directiveHelper.twoWayBinding(
                        scope.$parent, attr, 'data', tinkerCtrl, 'data'
                    );
                }
            };
        }
    };
}

app
    .controller('TinkerController', TinkerController)
    .directive('tinker', TinkerDirective);