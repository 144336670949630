/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/**
 * Created by chris on 6/03/15.
 */

ppxControllers.controller('Access', ['$scope', '$routeParams', '$location', '$sce', 'UserService', 'DataService', 'NavigationService', 'sdk', 'backendService',  function($scope, $routeParams, $location, $sce, UserService, DataService, NavigationService, sdk, backendService) {
    $scope.application = UserService; //make the application class avaliable to this scope
    $scope.data = DataService; //make the dataservice avaliable to this scope

    $('header h1').text('');

    $scope.requestBeenSend = false;

    $scope.userEmail = $scope.application.userData.userEmail;

    $scope.back = NavigationService.wrapBack(function () {
        $location.path('dashboard');
    });

    $scope.backToProof = function() {
        if (history.length > 1) {
          history.back();
        } else {
          $scope.back();
        }
    }

    $scope.goToProof = function() {
        window.location.reload();
    }

    $scope.gotoDash = function(){
        var path = (($scope.type === '7' || $scope.type === '8') ? 'workflow-dashboard' : 'dashboard');
        $location.path(path);
    };

    $scope.sendAddRequest = function(event) {
        var path = $location.$$path;
        var lastIndex = path.lastIndexOf('/');
        var proofId = path.substring(lastIndex + 1);
        $scope.inProcess = true;
        switch ($routeParams.type) {
        case '1':
            return sdk.proofs.requestAccess(proofId)
                .then(function () {
                    $scope.requestBeenSend = true;
                    $scope.inProcess = false;
                });
        case '15':
            return backendService
                .fetch('domain.dashboard.addOwner', {
                    email: $scope.userEmail,
                    relatedId: proofId,
                    allVersions: false,
                })
                .data()
                .then(function() {
                    $scope.requestBeenSend = true;
                    $scope.inProcess = false;
                });
        default:
            return false;
        }
    }

    $scope.init = function(){

        if (Number($routeParams.type) > 0 && Number($routeParams.type) <= 16) {

            $scope.type = $routeParams.type;
        } else {
            $scope.type = "0"
        }

        $scope.application.hideLoader();
    };

    $scope.init();

}]);
