/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
'use strict';

/* App Module */

var createProof = window.app = angular.module('createProof', [
  'ngRoute',
  'ngSanitize',
  'ngAnimate',
  'ppxControllers',
  'ppxGlobalControllers',
  'angular-bugsnag',
  'marshall.storage',
  'px.mention',
  'rzModule',
  'px.canvas',
  'react'
]);

if (location.search.indexOf('__$rootScope_debug__') !== -1) {
  createProof.config(['$provide', function ($provide) {
      $provide.decorator('$rootScope', function ($delegate) {
          var _emit = $delegate.$emit;

          $delegate.$emit = function () {
              console.debug.apply(console, ['$delegate.$emit'].concat([].slice.apply(arguments)));
              return _emit.apply(this, arguments);
          };

          var _broadcast = $delegate.$broadcast;

          $delegate.$broadcast = function () {
              console.debug.apply(console, ['$delegate.$broadcast'].concat([].slice.apply(arguments)));
              return _broadcast.apply(this, arguments);
          };

          return $delegate;
      });
  }]);
}

createProof.config(['bugsnagProvider', function (bugsnagProvider) {
    var releaseStage = env('bugsnag_release_stage');

    var extensionInfoMetadata = {};
    var pullExtensionInfoInterval = setInterval(pullExtensionInfo, 10 * 1000);

    function pullExtensionInfo() {
      detectExtension().then(function (extensionInfo) {
        if (extensionInfo) {
          clearInterval(pullExtensionInfoInterval);

          if (extensionInfo === true) {
            extensionInfoMetadata.extension = { version: 'unknown' };
          } else {
            extensionInfoMetadata.extension = extensionInfo;
          }
        } else {
          // keep trying, until we get info - the user may install the extension after the page has loaded?
        }
      });
    }
    pullExtensionInfo();

    bugsnagProvider
        .apiKey(env('bugsnag_key'))
        .releaseStage(releaseStage)
        .user({})
        .metaData({ request: { referrer: env('referrer') } })
        .appVersion(PageProof.version)
        .beforeNotify(['$log', 'browserService', function ($log, browserService) {
            return function (err, metadata) {
                if (err.message === 'SecurityError' && browserService.name === 'ie') {
                    // "SecurityError" in IE contain little-to-no information about the issue, we omit reporting them
                    return false;
                }
                if (err.message && err.message.error instanceof Error.WithMetaData) {
                    var error = err.message.error.message;

                    err.message = error.message;
                    err.metaData = $.extend(err.metaData, error.metadata);
                }
                err.metaData = $.extend(err.metaData, extensionInfoMetadata);
                return true;
            }
        }]);
}]);

createProof.run(function(backgroundService){

    window.$$backgroundService = backgroundService;

});

createProof.factory('UserService', function(){
    return new Application();
});

createProof.service('DataService', function(){
  var dataList = {
      multipleFile: {
          protoProofs: [],
          workflowId: '',
          returning: false,
          coOwners: [],
      },
  };

  var addName = function(name){
      dataList.name = name;
  };

  var addTag = function(tag){
      dataList.tag = tag;
  };

  var addFile = function(file){
      dataList.$file = file;
  };

  var addFileId = function(fileId) {
    dataList.fileId = fileId;
  }

  var setMultipleFile = function(multipleFile) {
      dataList.multipleFile = multipleFile;
  };

  var resetMultipleFile = function() {
      setMultipleFile({
          protoProofs: [],
          workflowId: '',
          returning: false,
          coOwners: [],
      })
  };

  var setCoOwner = function(coOwners) {
    dataList.multipleFile.coOwners = coOwners;
  };

  var dropReturning = function() {
      dataList.multipleFile.returning = false;
  };

  var setProtoProofs = function(files) {
      dataList.multipleFile.protoProofs = files;
  };

  var setGroupId = function(groupId) {
    dataList.groupId = groupId;
  }

  var addMessage = function(message){
      dataList.message = message;
  };

  var addDueDate = function(dueDate){
      dataList.dueDate = dueDate;
  };

  var addDueDateDisplay = function(dueDateDisplay){
      dataList.dueDateDisplay = dueDateDisplay;
  };

  var addReminders = function(reminders){
    dataList.reminders = reminders;
  }

  var getReminders = function() {
    return dataList.reminders;
  }

  var addProofData = function(proofData){
      dataList.proofData = proofData;
  };

  var emptyProofData = function(){
      dataList.proofData = {};
  };

  var addProofId = function(proofId){
      dataList.proofId = proofId;
  };

  var addWorkflowId = function(workflowId){
      dataList.workflowId = workflowId;
  };

  var addPrimaryEmailStepId = function(primaryEmailStepId){
      dataList.primaryEmailStepId = primaryEmailStepId;
  };

  var addFinalApprovalEmailId = function(finalApprovalEmailId){
      dataList.finalApprovalEmailId = finalApprovalEmailId;
  };

  var addExtraApprovalEmails = function(extraApprovalEmails){
      dataList.extraApprovalEmails = extraApprovalEmails;
  };

  var addFinalApprovalEmail = function(finalApprovalEmail){
      dataList.finalApprovalEmail = finalApprovalEmail;
  };

  var addChangedWorkflowId = function(changedWorkflowId){
      dataList.changedWorkflowId = changedWorkflowId;
  };

  var addGlobalDatas = function(obj){
      dataList.globalDatas = obj;
  };

  var addNewVersionProofId = function(proofId){
    dataList.previousVersion = proofId;
  };

  var setIsEditorOnFirstVersion = function (bool) {
    dataList.isEditorOnFirstVersion = bool;
  };

  var addToggleIsRerun = function(){
    dataList.toggleIsRerun = true;
  };

  var addInputs = function(inputs) {
      dataList.inputs = inputs;
  };

  var isReupload = function () {
    return dataList.toggleIsRerun === true;
  };

  var addProgressIsTrue = function(){
    dataList.addProgressIsFinished = true;
  };

  var setDownloadOption = function(bool){
      dataList.downloadOption = bool;
  };

  var addFileOrigin = function(org){
    dataList.fileOrigin = org;
  }

  var addReference = function (reference) {
    dataList.reference = reference;
  }

  var storeImageUrl = function(fileId, imageUrl, pageNumber, versionNumber, imageUrlHi){

    if(imageUrlHi != ""){
      var i = 0;
      while(i < dataList.imageUrlData.length){
        if(dataList.imageUrlData[i]["fileId"] == fileId && dataList.imageUrlData[i]["pageNumber"] == pageNumber && dataList.imageUrlData[i]["versionNumber"] == versionNumber){
          console.log("setting image url");
          dataList.imageUrlData[i]["imageUrlHi"] = imageUrlHi;
          break;
        }
        i++;
      }
    }else{
      dataList.imageUrlData.push({fileId:fileId, imageUrl:imageUrl, imageUrlHi:imageUrlHi, pageNumber:pageNumber, versionNumber:versionNumber});
    }
  };

    var addProofRestart = function(bool){
        dataList.proofRestart = bool;
    }

  var getData = function(){
      return dataList;
  };

  var setImportData = function (type, file) {
      dataList.name = file.name + '.' + file.extension;
      dataList.import.type = type;
      dataList.import.name = file.name;
      dataList.import.extension = file.extension;
      dataList.import.size = file.size;
      dataList.import.url = file.url;
      dataList.import.meta = file.meta || {};
  };

  var getImportData = function () {
      return dataList.import;
  };

  var hasImportData = function () {
      return !! getImportData().type;
  };

  var getImportType = function () {
      return dataList.import.type || null;
  };

  var clearData = function(){
      dataList.name = '';
      dataList.tag = '';
      dataList.message = '';
      dataList.$file = null;
      dataList.dueDate = '';
      dataList.dueDateDisplay = '';
      dataList.workflowId = '';
      dataList.proofId = '';
      dataList.primaryEmailStepId = '';
      dataList.finalApprovalEmailId = '';
      dataList.uploadProgress = '';
      dataList.finalApprovalEmail = '';
      dataList.changedWorkflowId = '';
      dataList.previousVersion = '';
      dataList.toggleIsRerun = false;
      dataList.globalDatas = '';
      dataList.imageUrlData = [];
      dataList.extraApprovalEmails = {};
      dataList.proofData = {};
      dataList.inputs = [];
      dataList.proofRestart = false;
      dataList.fileId = null;
      dataList.isEditorOnFirstVersion = false;

      dataList.addProgressIsFinished = false;

      dataList.activity = [];

      dataList.import = {
          type: null,
          url: null,
          size: 0,
          name: null,
          extension: null
      };

      dataList.downloadOption = null;
      dataList.addFileOrigin = '';
      dataList.groupId = null;
      dataList.reference = null;
      dataList.expectsEmail = false;
      dataList.emailAddress = null;
  };

  var addExpectsEmailProof = function () {
      dataList.expectsEmail = true;
  }

  var addEmailProofAddress = function(email) {
      dataList.emailAddress = email;
  }

  var expectsEmail = function() {
    return dataList.expectsEmail === true;
  }

  var hasFile = function () {
      var hasMultiple = dataList.multipleFile.protoProofs.length > 0;
      return !! dataList.$file || hasMultiple || hasImportData() || expectsEmail();
  };

  var clearFile = function () {
      dataList.$file = dataList.import.type = null;
  };

  clearData();

  return {
    setMultipleFile: setMultipleFile,
    resetMultipleFile: resetMultipleFile,
    dropReturning: dropReturning,
    setCoOwner: setCoOwner,
    setProtoProofs: setProtoProofs,
    setGroupId: setGroupId,
    addName: addName,
    addTag: addTag,
    addMessage: addMessage,
    addDueDate: addDueDate,
    addDueDateDisplay: addDueDateDisplay,
    addReminders: addReminders,
    getReminders: getReminders,
    addFile: addFile,
    addProofData: addProofData,
    addWorkflowId: addWorkflowId,
    addProofId: addProofId,
    addPrimaryEmailStepId: addPrimaryEmailStepId,
    addFinalApprovalEmailId: addFinalApprovalEmailId,
    addExtraApprovalEmails: addExtraApprovalEmails,
    addFinalApprovalEmail: addFinalApprovalEmail,
    addChangedWorkflowId:addChangedWorkflowId,
    addGlobalDatas: addGlobalDatas,
    addToggleIsRerun:addToggleIsRerun,
    isReupload: isReupload,
    addNewVersionProofId: addNewVersionProofId,
    addInputs:addInputs,
    emptyProofData: emptyProofData,
    storeImageUrl: storeImageUrl,
    getData: getData,
    clearData: clearData,
    hasFile: hasFile,
    clearFile: clearFile,
    addProofRestart:addProofRestart,
    addProgressIsTrue: addProgressIsTrue,
    setImportData: setImportData,
    hasImportData: hasImportData,
    getImportData: getImportData,
    getImportType: getImportType,
    setDownloadOption: setDownloadOption,
    addFileOrigin: addFileOrigin,
    addFileId: addFileId,
    setIsEditorOnFirstVersion: setIsEditorOnFirstVersion,
    addReference: addReference,
    addExpectsEmailProof: addExpectsEmailProof,
    expectsEmail: expectsEmail,
    addEmailProofAddress: addEmailProofAddress,
  };
});

createProof.service('Activity', function($rootScope){
    var dataList = {activity:[], timestamp:null, count:0, loadMoreCount:0};

    var addActivity = function(act){
        dataList.activity = act;
    };

    var addActivityCount = function(count){
        dataList.count = count;
    };

    var addActivityTimestamp = function(timestamp){
        dataList.timestamp = timestamp;
    };

    var addLoadMoreCount = function(count){
        dataList.loadMoreCount = count;
    }

    var getData = function(){
        return dataList;
    };

    var clearData = function(){
        dataList.activity.length = 0;
        dataList.timestamp = null;
        dataList.count = 0;
        dataList.loadMoreCount = 0;
    };

    var toggleActivity = function() {
        $rootScope.$broadcast('notifications-toggle');
    };

    clearData();

    return {
        getData: getData,
        clearData: clearData,
        addActivity:addActivity,
        addActivityCount:addActivityCount,
        addLoadMoreCount:addLoadMoreCount,
        addActivityTimestamp:addActivityTimestamp,
        toggleActivity:toggleActivity
    };
});

createProof.run(function($rootScope, Activity) {
    $rootScope.notifications = Activity;
});

createProof.service('$download', function(){
    var dataList = {proofId:""};

    var setProofId = function(proofId){
        dataList.proofId = proofId;
    }

    var getData = function(){
        return dataList;
    };

    var clearData = function(){
        dataList.proofId = "";
    };

    clearData();

    return {
        getData: getData,
        clearData: clearData,
        setProofId:setProofId
    };
});

createProof.service('$webSocket', ['UserService', 'websocketService', function(UserService, websocketService) {

    var websocketUri = UserService.api.websocketURI;

    var messageHandler = null;
    var onOpenHandler = null;
    var onReOpenHandler = null;
    var onCloseHandler = null;
    var hasValidConnection = false;

    var socketType = "";

    var websocket = null;

    var socketTypes = ["activity","download"];

    var setMessageHandler = function (callbackfunc) {
        if (!isFunc(callbackfunc)) return;
        messageHandler = callbackfunc;
    };

    var setOnLoadHandler = function (callbackfunc) {
        if (!isFunc(callbackfunc)) return;
        onOpenHandler = callbackfunc;
    };

    var setOnReLoadHandler = function (callbackfunc) {
        if (!isFunc(callbackfunc)) return;
        onReOpenHandler = callbackfunc;
    };

    var setOnCloseHandler = function (callbackfunc) {
        if (!isFunc(callbackfunc)) return;
        onCloseHandler = callbackfunc;
    };

    var openSocket = function (type) {
        socketType = type;
        if (hasValidConnection == false) {
            console.log("openning connection");
            open();
        } else {
            console.log("connection open");
        }
    };

    var open = function () {

        if (empty(socketType)) {
            throw new Error("No socket type set. Call 'setSocketType' to set endpoint.");
            return false;
        }

        if (!inArray(socketTypes, socketType)) {
            throw new Error("Unknown socket type set.");
            return false;
        }

        websocket = websocketService({url: websocketUri + socketType});

        websocket.on("message", function (dataObj, rawData) {

            if (rawData == "OK") {
                hasValidConnection = true;
            }

            console.log("hasValidConnection:", hasValidConnection);

            if (messageHandler != null) messageHandler(dataObj || rawData);

        });

        websocket.on("open", function () {

            websocket.sign("Authenticate");

            if (onOpenHandler != null) onOpenHandler();

        });

        websocket.on("reopen", function () {

            websocket.sign("Authenticate");

            console.warn("Reopenning connection");

            if (onReOpenHandler != null) onReOpenHandler();

        });

        websocket.on("close", function () {

            hasValidConnection = false;

            if (onCloseHandler != null) onCloseHandler();

        });

    };

    var send = function (data) {
        if (websocket != null) {
            websocket.send(data);
        }
    };

    var close = function () {
        if (websocket != null) {
            websocket.close();
        }
    };

    var hasOpenConnection = function(){
        return hasValidConnection;
    }

    return {
        setMessageHandler: setMessageHandler,
        setOnLoadHandler: setOnLoadHandler,
        setOnReLoadHandler: setOnReLoadHandler,
        setOnCloseHandler: setOnCloseHandler,
        close: close,
        send: send,
        openSocket: openSocket,
        hasOpenConnection:hasOpenConnection
    };

}]);

createProof.factory('Base64', function() {
    var keyStr = 'ABCDEFGHIJKLMNOP' +
        'QRSTUVWXYZabcdef' +
        'ghijklmnopqrstuv' +
        'wxyz0123456789+/' +
        '=';
    return {
        encode: function (input) {
            var output = "";
            var chr1, chr2, chr3 = "";
            var enc1, enc2, enc3, enc4 = "";
            var i = 0;

            do {
                chr1 = input.charCodeAt(i++);
                chr2 = input.charCodeAt(i++);
                chr3 = input.charCodeAt(i++);

                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;

                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }

                output = output +
                keyStr.charAt(enc1) +
                keyStr.charAt(enc2) +
                keyStr.charAt(enc3) +
                keyStr.charAt(enc4);
                chr1 = chr2 = chr3 = "";
                enc1 = enc2 = enc3 = enc4 = "";
            } while (i < input.length);

            return output;
        },

        decode: function (input) {
            var output = "";
            var chr1, chr2, chr3 = "";
            var enc1, enc2, enc3, enc4 = "";
            var i = 0;

            // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
            var base64test = /[^A-Za-z0-9\+\/\=]/g;
            if (base64test.exec(input)) {
                alert("There were invalid base64 characters in the input text.\n" +
                "Valid base64 characters are A-Z, a-z, 0-9, '+', '/',and '='\n" +
                "Expect errors in decoding.");
            }
            input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

            do {
                enc1 = keyStr.indexOf(input.charAt(i++));
                enc2 = keyStr.indexOf(input.charAt(i++));
                enc3 = keyStr.indexOf(input.charAt(i++));
                enc4 = keyStr.indexOf(input.charAt(i++));

                chr1 = (enc1 << 2) | (enc2 >> 4);
                chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                chr3 = ((enc3 & 3) << 6) | enc4;

                output = output + String.fromCharCode(chr1);

                if (enc3 != 64) {
                    output = output + String.fromCharCode(chr2);
                }
                if (enc4 != 64) {
                    output = output + String.fromCharCode(chr3);
                }

                chr1 = chr2 = chr3 = "";
                enc1 = enc2 = enc3 = enc4 = "";

            } while (i < input.length);

            return output;
        }
    };
});



createProof.factory('SegmentIoAPI', ['$http', 'Base64', function($http, Base64){

    var segmentApiUrl = "https://api.segment.io/v1/";

    var callbackFunc  = null;
    var userId = "";
    var anonymousId = "";
    var debug = true;

    var guid = function() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

    var setUserId = function(id){
        userId = id;
    };

    var setResponseCallback = function(callback){
        callbackFunc = callback;
    };

    var identify = function(userId, directCb){
        if(empty(userId)) throw new Error("empty anonymousId");
        callAPI("identify", {userId:userId}, function(data, status){
            if(isFunc(directCb)) directCb(data, status);
        });
    };

    var alias = function(userId, directCb){
        if(empty(anonymousId)) throw new Error("empty anonymousId");
        callAPI("alias", {userId:userId, previousId:anonymousId}, function(data, status){
            if(isFunc(directCb)) directCb(data, status);
        });
    };

    var track = function(event, eventData, directCb){

        if(empty(userId) && empty(anonymousId)){ //no logged in userId, so get an anonymous one
            anonymousId = guid();
        }else if(isset(userId) && isset(anonymousId)){ //if there is a logged in userId and an anonymousId, do the alias
            alias(userId, function(){
                anonymousId = "";
                track(event, eventData, directCb); //then call track again to match first call
            });
            return ""
        }

        var object = {};

        if(isset(userId)){
            object["userId"] = userId;
        }else{
            object["anonymousId"] = anonymousId;
        }

        object["event"] = event; //str eg "login"

        if(isset(eventData)){
            object["properties"] = eventData; //must be a obj eg {key:somedata}
        }

        if( debug ){
            console.log("POST object:", object)
        }

        callAPI("track", object, function(data, status){
            if(isFunc(directCb)) directCb(data, status);
        });
    };

    var callAPI = function(endPoint, data, directCb){

        var auth = 'Basic ' + Base64.encode(env('segment_project') + ':');
        $http({
          method: 'POST',
          url: segmentApiUrl + endPoint,
          data: data,
          headers: {
            'Authorization': auth,
          }
        }).success(function(data, status) {
            if(callbackFunc != null) callbackFunc(data, status);
            if(isFunc(directCb)) directCb(data, status);
        }).error(function(data, status, headers, config){ });
    };


    return {callAPI:callAPI, setResponseCallback:setResponseCallback, setUserId:setUserId, identify:identify, track:track};

}]);

createProof.factory('SegmentIo', ['UserService', '$window', 'SegmentIoAPI', 'bugsnag', function(UserService, $window, SegmentIoAPI, bugsnag){

  var events = {
      1:{name:"Proof Drop Not Supported"},
      3:{name:"Proof Drop Success"},
      16:{name:"Workflow Template Saved"},
      21:{name:"Proof Opened"},
      23:{name:"Workflow Template Created"},
      25:{name:"Used Workflow Template"},
      26:{name:'Proof File Sent (External)'},
      27:{name:'Proof File Select (External)'},
      28:{name:'Proof File Error (External)'},
      29:{name:'Print Proof'}, // Record proofIds
      30:{name:'Print Proof (no todos)'},
      31:{name:'Co-Owner Added'},
      32:{name:'Co-Owner Removed'},
      33:{name:'Workflow Manager Added'},
      35:{name:'Delete Comment'},
      36:{name:'Proof File Select Permission Denied (External)'},
      42:{name:'Proofer removed from proof'}, //record workflow ID, step ID, user ID
      44:{name:'Presentation upgrade'},
      45:{name:'Compare mode'},
      46:{name:'Smart Compare'},
      47:{name:'Opened Integrations'},
      48:{name:'Changed Integration'}, //record integration, enabled
      49:{name:'Used HTML Proofing'},
      50:{name:'Branding Email'},
      51:{name:'Branding Logo'},
      52:{name:'Upgrade to teams pricing page'},
      53:{name:'Multiple proof create'},
      54:{name:'Multiple proof drop success'},
      55:{name:'Changed domain admin permission'}, // record userId, isAdmin
      56:{name:'Proof added to collection'},
      57:{name:'Audit downloaded'} ,// proofId
      58:{name:'Opened Reporting'},
      59:{name:'Report Generated'},
      60:{name:'Proof file downloaded for preview'},
      61:{name:'Color separation mode'},
      62:{name:'Proof File Replace'},
      63:{name:'Print Proof Legacy'},
      64:{name:'Barcode scanner'},
      65:{name:'File not supported'},
      66:{name:'Proof Files Combined (External)'},
      67:{name:'Skip reviewers who previously approved'}
  };

  var userData = {};

  var userEmail = "";
  var userId = "";

  var debug = true;

  var logEvent = function(event, params){
    if( debug ){
      if( empty(params) ) params = {};
      var message = "Event Log"
      if( empty( userId ) ){
        message = "Event Log (anonymous)"
      }
      console.log(message + ": ", event, jsonEncode(params));
    }
  };

  var loadUser = function(){
      userData = UserService.GetUserData();
      if( userData["userEmail"] && userData["userId"] ) {
          userEmail = userData["userEmail"];
          userId = userData["userId"];
          SegmentIoAPI.setUserId(userId);
      }
  }

  var identifyNewUser = function(userId){
      console.log("identifing user:" + userId);

      SegmentIoAPI.identify(userId, function(data, status){});
  };

  var track = function(eventNumber, infoObj){
      if( empty(eventNumber) ) return "";

      loadUser(); //reload the users data

      var event = events[eventNumber]["name"];

      if( isset(infoObj) ){
          bugsnag.leaveBreadcrumb(event, infoObj);
          SegmentIoAPI.track(event, infoObj, function(data, status){
              console.log("track", data, status);
          });
      }else{
          bugsnag.leaveBreadcrumb(event);
          SegmentIoAPI.track(event, "", function(data, status){
              console.log("track", data, status);
          });
      }
  };

  function trackCustom (eventName, eventData) {
      SegmentIoAPI.track(eventName, eventData || '', function (data, status) {
          console.log('tracked (custom)', data, status)
      });
  }

  return {identifyNewUser:identifyNewUser, track:track, trackCustom: trackCustom};

}]);

createProof.service('VarService', function(){
  var dataList = {fromTerms:"", fromPrivacy:"", email:""};

  var setFromTerms = function(bool){
      dataList.fromTerms = bool;
  };

  var setFromPrivacy = function(bool){
      dataList.fromPrivacy = bool;
  };

  var setEmail = function(email){
      dataList.email = email;
  };

  var getData = function(){
      return dataList;
  };

  return {
    setFromPrivacy: setFromPrivacy,
    setFromTerms: setFromTerms,
    setEmail: setEmail,
    getData: getData
  };
});

createProof.run(function () {
    console.log("hiding spinner");
    angular.element('#page-load').css('display', 'none');
});

createProof.config(['$animateProvider', function ($animateProvider) {
    $animateProvider.classNameFilter(/^(?:(?!no-animation|no-ng-animate).)*$/);
}]);

createProof.run(function($http, $templateCache, $location, $timeout, $rootScope) {
    var allowedUrlArray = ['static', 'audio', 'video'];
    $rootScope.$on('$locationChangeSuccess', function () {
        var curUrlPart1 = $location.path().split('/')[1];
        var curUrlPart2 = $location.path().split('/')[2];
        if (curUrlPart1 === 'proof' && allowedUrlArray.indexOf(curUrlPart2) !== -1) {
            var files = [
                'templates/partials/proof/components/buttons/approve-and-unlock-confirm.html',
                'templates/partials/proof/components/buttons/approve-confirm.html',
                'templates/partials/proof/components/buttons/finished-confirm.html',
                'templates/partials/proof/components/buttons/send-changes-confirm.html',
                'templates/partials/proof/components/buttons/send-changes-gatekeeper-confirm.html',
                'templates/partials/proof/components/buttons/send-to-editor-confirm.html',
                'templates/partials/proof/components/buttons/send-to-editor.html',
                'templates/partials/proof/components/buttons-confirm.html',
            ];
            $timeout(function(){
                files.forEach(function(file) {
                    if (typeof $templateCache.get(file) === 'undefined') {
                        $http.get(file).then(function(html) {
                            $templateCache.put(file, html);
                        });
                    }
                });
            }, 10000);
        }
    });
});

//store users online/offline status
createProof.run(function($window, $rootScope) {
    $rootScope.online = navigator.onLine;
    $window.addEventListener('offline', function() {
        $rootScope.$apply(function () {
            $rootScope.online = false;
        });
    }, false);
    $window.addEventListener('online', function() {
        $rootScope.$apply(function () {
            $rootScope.online = true;
        });
    }, false);
});

createProof.run(function($rootScope, localCommentService) {
    localCommentService.initOnlineWatcherForComment();
});

var preload = (function() {
  var cache = {};
  return function(url) {
    return ['utilService', 'appService', function(utilService, appService) {
      var fullUrl = __pageproof_manifest__[url] || url;
      if (!(fullUrl in cache)) {
      appService.showTranslatedLoaderMessage('loader-message.loading-please-wait');
        var q = cache[fullUrl] = utilService.loadScript(fullUrl);
        q.then(function() {
          appService.hideLoader();
        });
      }
      return cache[fullUrl];
    }];
  };
})();

createProof.config(['$routeProvider', '$locationProvider',
  function($routeProvider, $locationProvider) {
    $locationProvider.
      html5Mode({
        enabled: true,
        rewriteLinks: false,
      });

    if ('__routes' in window) {
      angular.forEach(window.__routes, function (route) {
        var meta = {
          templateUrl: 'content/pages/?' + route.name,
          controller: route.controller || 'PageController',
          controllerAs: 'page',
          data: route.data
        };

        if (route.redirect) {
          meta = {
            redirectTo: route.redirect
          };
        }

        $routeProvider.when(route.url, meta);
      });
    }

    if ('__pageproof_next_routes__' in window) {
      Object.keys(window.__pageproof_next_routes__).forEach(function (key) {
        $routeProvider.when(key, window.__pageproof_next_routes__[key]);
      });
    }

    $routeProvider.
      when('/dashboard/collection/:collectionId', {
        template: '',
        controller: function ($location, $routeParams) {
          $location.path('/dashboard/group').search({
            groupId: $routeParams.collectionId,
          });
        }
      }).
      when('/dashboard/:tab?', {
        templateUrl: 'templates/partials/dashboard/dashboard.html',
        controller: 'DashboardController',
        controllerAs: 'dashboardCtrl',
        data: {
          middleware: ['auth'],
          title: 'Dashboard'
        }
      }).
        when('/brief-upload-progress', {
          templateUrl: 'templates/partials/createProof/brief-upload-progress.html',
          controller: 'proofSetup',
          controllerAs: 'proofSetupCtrl',
          data: {
              middleware: ['file'],
              intercom: false
          },
        }).
        when('/__pp_admin', {
            templateUrl: 'templates/partials/createProof/admin.html',
            controller: 'pageProofAdmin',
            data: {
                middleware: ['private.admin'], //'auth', 'file'
                intercom: false
            }
        }).
        when('/audit-log/:proofId', {
            templateUrl: 'templates/partials/createProof/audit-log.html',
            controller: 'auditLog',
            data: {
                middleware: ['auth'],
                intercom: false
            }
        }).
        when('/proof-already-started/:proofId', {
            templateUrl: 'templates/partials/createProof/proof-started.html',
            controller: 'proofStarted',
            data: {
                title: 'Someone beat you to it.',
                intercom: false
            }
        }).
      when('/proof-not-found', {
        templateUrl: 'templates/partials/createProof/proof-removed.html',
        controller: 'proofRemoved',
        data: {
          title: 'Where’d it go?',
          intercom: false
        }
      }).
      when('/file-size', {
        templateUrl: 'templates/partials/createProof/file-size.html',
        controller: 'fileSize',
        data: {
          title: 'Crikey that’s a big file.',
          intercom: false
        }
      }).
      when('/proof-upgrade/:mode', {
        templateUrl: 'templates/partials/createProof/proof-upgrade.html',
        controller: 'proofUpgrade',
        resolve: {
          mode: function () { return 'upgrade' }
        },
        data: {
          middleware: ['auth'],
          intercom: false,
        }
      }).
      when('/proof-unsupported-filetype', {
        templateUrl: 'templates/partials/createProof/proof-unsupported-filetype.html',
        controller: 'proofSetup',
        resolve: {
          mode: function () { return 'unsupported-filetype' }
        },
        data: {
          intercom: false
        }
      }).
      when('/html-proofing', {
        templateUrl: 'templates/partials/createProof/html-proofing.html',
        controller: 'proofSetup',
        data: {
          intercom: false
        }
      }).
      when('/adobe-indesign', {
        templateUrl: 'templates/partials/createProof/adobe-indesign.html',
        controller: 'proofSetup',
        data: {
          intercom: false
        }
      }).
      when('/team-workflows', {
        templateUrl: 'templates/partials/createProof/workflow-dashboard.html',
        controller: 'proofWorkflow',
        resolve: {
          mode: function () { return 'team-workflows' }
        },
        data: {
          middleware: ['domain.admin'],
          title: 'Team workflows',
        }
      }).
      when('/workflow-dashboard', {
        templateUrl: 'templates/partials/createProof/workflow-dashboard.html',
        controller: 'proofWorkflow',
        resolve: {
          mode: function () { return 'dashboard' }
        },
        data: {
          middleware: ['auth'],
          title: 'Workflows',
        }
      }).
      when('/workflow-dashboard-shared', {
        templateUrl: 'templates/partials/createProof/workflow-dashboard.html',
        controller: 'proofWorkflow',
        resolve: {
          mode: function () { return 'dashboard-shared' }
        },
        data: {
          middleware: ['auth'],
        }
      }).
      when('/proof-workflow-manage/:workflowId', {
        redirectTo: function(params) {
          return '/workflows/templates/' + params.workflowId;
        }
      }).
      when('/proof-workflow-manage-shared/:workflowId', {
        redirectTo: function(params) {
          return '/workflows/templates/' + params.workflowId;
        }
      }).
      when('/domain-admin-dashboard', {
          redirectTo: '/team/members',
      }).
      when('/integrations', {
          templateUrl: 'templates/partials/createProof/domain-admin/integrations.html',
          controller: 'IntegrationsController',
          controllerAs: 'integrationsCtrl',
          data: {
            middleware: ['domain.admin'],
            title: 'Integrations',
            intercom: false
          },
      }).
      when('/team-reporting', {
        redirectTo: '/reporting',
      }).
      when('/reporting', {
        templateUrl: 'templates/partials/createProof/reporting.html',
        controller: 'ReportingController',
        controllerAs: 'reportingCtrl',
        data: {
            middleware: ['auth'],
            title: 'Reporting',
            intercom: false
        },
      }).
      when('/team-dashboard/:tab?', {
        templateUrl: 'templates/partials/createProof/domain-admin/team-dashboard.html',
        controller: 'TeamDashboardController',
        controllerAs: 'dashboardCtrl',
        data: {
          middleware: ['domain.admin'],
          title: 'Team dashboard'
        },
      }).
      when('/login', {
        templateUrl: 'templates/partials/createProof/login.html',
        controller: 'LoginController',
        data: {
          middleware: ['guest'],
          title: 'Login'
        }
      }).
      when('/create-account', {
        templateUrl: 'templates/partials/createProof/create-account.html',
        controller: 'LoginController',
        data: {
          middleware: ['guest'],
          title: 'Create an Account',
          intercom: false
        }
      }).
      when('/setup-password', {
        templateUrl: 'templates/partials/createProof/login.html',
        controller: 'LoginController',
        data: {
          middleware: ['guest'],
          title: 'Set a Password',
          intercom: false
        }
      }).
      when('/welcome', {
        template: '<div></div>',
        controller: 'AutoCreateAccountController',
        data: {
          middleware: ['auto.create'],
          title: 'Create an Account',
          intercom: false
        }
      }).
      when('/activate/:userId', {
        templateUrl: 'templates/partials/createProof/activate.html',
        controller: 'ActivateController',
        resolve: {
          mode: function () { return 'onboarding' }
        },
        data: {
          middleware: ['inactive'],
          title: 'Activation',
          intercom: false
        }
      }).
      when('/password/change/success', {
        templateUrl: 'templates/partials/password-reset/success.html',
        controller: 'SuccessPasswordResetController',
        data: {
          middleware: ['login'],
          title: 'Your password has been changed!',
          intercom: false
        }
      }).
      when('/progress', {
        templateUrl: 'templates/partials/createProof/progress.html',
        data: {
          middleware: ['auth'], //auth
          intercom: false
        }
      }).
      when('/logout', {
        template: '',
        controller: 'Logout',
        data: {
          middleware: ['auth'],
          intercom: false
        }
      }).
      when('/password/reset/update', {
        templateUrl: 'templates/partials/createProof/login.html',
        controller: 'LoginController',
        data: {
          middleware: ['guest'],
          title: 'Reset Password',
          mode: 'reset-password',
        },
      }).
      when('/terms', {
        data: {
            middleware: ['marketing']
        }
      }).
      when('/privacy', {
        data: {
            middleware: ['marketing']
        }
      }).
      when('/', {
        redirectTo: '/login',
      }).
      when('/about', {
        data: {
            middleware: ['marketing']
        }
      }).
      when('/pricing', {
        data: {
            middleware: ['marketing']
        }
      }).
      when('/getting-started-with-pageproof', {
          data: {
              middleware: ['marketing']
          }
      }).
      when('/external/:type/:typeId', {
        templateUrl: 'templates/partials/createProof/external/access.html',
        controller: 'external',
        data: {
          middleware: ['auth'],
          intercom: false
        }
      }).
      when('/docs/:docId', {
        templateUrl: function ($routeParams) {
          return 'templates/partials/docs/' + $routeParams.docId + '.html'
        },
        controller: 'Docs',
        data: {
          title: 'Documentation',
          intercom: false
        }
      }).
      when('/proof/finished-proofing', {
        templateUrl: 'templates/partials/proof/pages/finished-proofing.html',
        controller: function() {},
        data: {
          intercom: false
        }
      }).
      when('/blog/:article?', {
        template: '',
        controller: function ($location) {
          $location.path($location.path().replace('/blog', '/learn'));
        },
        data: {
          intercom: false
        }
      }).
      when('/learn', {
        data: {
            middleware: ['marketing']
        }
      }).
      when('/learn/:article', {
        data: {
            middleware: ['marketing']
        }
      }).
      when('/proof-screen/:proofId', {
        template: '<!-- n/a -->',
        controller: 'ProofScreen',
        data: {
          middleware: ['auth'],
          intercom: false
        }
      }).
      when('/access/:type', {
        templateUrl: 'templates/partials/createProof/access.html',
        controller: 'Access',
        data: {
          middleware: ['auth'],
          title: '',
          intercom: false
        }
      }).
      when('/onedrive.php', {
        template: '',
        controller: 'ImportController',
        data: {
          title: 'Connecting to OneDrive...',
          intercom: false
        },
        resolve: {
          importProvider: function () { return 'Onedrive' }
        }
      }).
      when('/import/url', {
        templateUrl: 'templates/partials/createProof/import-from-url.html',
        controller: 'UrlImportController',
        controllerAs: 'urlImportCtrl',
        data: {
          intercom: false
        },
      }).
      when('/import/:provider', {
        template: '',
        controller: 'ImportController',
        data: {
          middleware: ['auth'],
          title: ['$route', 'importService', function ($route, importService) {
            var provider = $route.current.params.provider;
            return 'Connecting to ' + importService.pretty(provider);
          }],
          intercom: false
        },
        resolve: {
          importProvider: function ($route, importService) {
            var provider = $route.current.params.provider;

            if ( ! importService.isProvider(provider)) {
              throw new Error('Import provider "' + provider + '" is not valid.');
            }

            return importService.normalise(provider);
          }
        }
      }).
      when('/imported/:provider', {
        templateUrl: 'templates/partials/createProof/imported.html',
        controller: 'ImportedController',
        data: {
          middleware: ['auth', 'file.import'],
          intercom: false
        },
        resolve: {
          importProvider: function ($route, importService) {
            var provider = $route.current.params.provider;

            if ( ! importService.isProvider(provider)) {
              throw new Error('Import provider "' + provider + '" is not valid.');
            }

            return importService.normalise(provider);
          }
        }
      }).
      when('/__pp_admin/api', {
        templateUrl: 'templates/partials/admin/api.html',
        controller: 'AdminAPIController',
        controllerAs: 'adminAPICtrl',
        data: {
          middleware: ['private.admin'],
          intercom: false
        }
      }).
      when('/proof/compare/:param1/:param2?/:param3?/:param4?', {
        templateUrl: 'templates/partials/proof/compare/index.html',
        controller: 'CompareProofController',
        controllerAs: 'compareProofCtrl',
        data: {
            middleware: ['auth'],
            intercom: false,
        },
        resolve: {
            _fabric: preload('/dist/lib/fabric-3.js')
        },
      }).
      when('/proof/static/:proofId', {
        templateUrl: 'templates/partials/proof/static/index.php',
        controller: 'StaticProofController',
        controllerAs: 'proofCtrl',
        data: {
            middleware: ['auth'],
            intercom: false
        },
        resolve: {
          _fabric: preload('/dist/lib/fabric-3.js'),
        },
      }).
      when('/proof/video/:proofId', {
        templateUrl: 'templates/partials/proof/video/index.php',
        controller: 'VideoProofController',
        controllerAs: 'proofCtrl',
        data: {
            middleware: ['auth'],
            intercom: false
        },
      }).
      when('/proof/audio/:proofId', {
        templateUrl: 'templates/partials/proof/audio/index.php',
        controller: 'AudioProofController',
        controllerAs: 'proofCtrl',
        data: {
            middleware: ['auth'],
            intercom: false
        },
      }).
      when('/proof/web/:proofId', {
        templateUrl: 'templates/partials/proof/web/index.php',
        controller: 'WebProofController',
        controllerAs: 'proofCtrl',
        data: {
            middleware: ['auth'],
            intercom: false,
        },
        resolve: {
          _fabric: preload('/dist/lib/fabric-3.js'),
          _zip: function (zipService) {
            return zipService.prepare();
          },
        },
      }).
      when('/brief/static/:briefId', {
        templateUrl: 'templates/partials/proof/static/index.php',
        controller: 'StaticProofController',
        controllerAs: 'proofCtrl',
        data: {
            middleware: ['auth'],
            intercom: false
        },
        resolve: {
            _fabric: preload('/dist/lib/fabric-3.js'),
        },
      }).
      when('/brief/audio/:briefId', {
        templateUrl: 'templates/partials/proof/audio/index.php',
        controller: 'AudioProofController',
        controllerAs: 'proofCtrl',
        data: {
            middleware: ['auth'],
            intercom: false
        }
      }).
      when('/brief/video/:briefId', {
        templateUrl: 'templates/partials/proof/video/index.php',
        controller: 'VideoProofController',
        controllerAs: 'proofCtrl',
        data: {
            middleware: ['auth'],
            intercom: false
        },
      }).
      when('/brief/web/:briefId', {
        templateUrl: 'templates/partials/proof/web/index.php',
        controller: 'WebProofController',
        controllerAs: 'proofCtrl',
        data: {
            middleware: ['auth'],
            intercom: false
        },
        resolve: {
          _fabric: preload('/dist/lib/fabric-3.js'),
          _zip: function (zipService) {
            return zipService.prepare();
          },
        },
      }).
      when('/printLegacy.php', {
        controller: 'ProofPrintControllerLegacy',
        controllerAs: 'proofPrint',
        templateUrl: 'templates/partials/globals/proof-print-legacy.html',
        data: {
          intercom: false
        },
        resolve: {
          $element: function () {
            return angular.element('[ng-view]');
          }
        }
      }).
      when('/_private/marketing-cross-origin/', {
        controller: 'MarketingCrossOriginController',
        controllerAs: 'marketingCrossOriginCtrl',
        template: '<!-- n/a -->'
      }).
      when('/settings/teams/branding', {
        templateUrl: 'templates/partials/settings/teams/branding.html',
        controller: 'BrandingSettingsController',
        controllerAs: 'brandingSettingsCtrl',
        data: {
          middleware: ['domain.admin'],
          intercom: false,
        }
      }).
      when('/settings/teams/scim', {
        templateUrl: 'templates/partials/settings/teams/scim.html',
        controller: 'TeamSCIMSettingsController',
        controllerAs: 'scimSettingsCtrl',
        data: {
          middleware: ['domain.admin'],
          intercom: false,
        }
      }).
      when('/teams/settings', {
        templateUrl: 'templates/partials/settings/teams/settings.html',
        controller: 'TeamSettingsController',
        controllerAs: 'teamSettingsCtrl',
        data: {
          middleware: ['domain.admin'],
          intercom: false,
        }
      }).
      when('/oauth/authorize', {
        templateUrl: 'templates/partials/oauth/authorize.html',
        controller: 'OAuthAuthorizeController',
        controllerAs: 'oauthAuthorizeCtrl',
        data: {
          middleware: ['auth'],
          intercom: false,
        }
      }).
      when('/not-found', {
        templateUrl: 'templates/partials/errors/not-found.html',
        data: {
          intercom: false
        }
      }).
      when('/reset-local-storage', {
        templateUrl: 'templates/partials/debug/reset-local-storage.html',
        controller: function(){},
        controllerAs: 'ctrl',
        data: {
          intercom: false
        }
      }).
      when('/team/invitation/:invitationId', {
        template: '<react-component name="TeamInvitationContainer" props="teamInvitationCtrl.props"></react-component>',
        controller: 'TeamInvitationController',
        controllerAs: 'teamInvitationCtrl',
        data: {
          middleware: ['auth'],
          intercom: false,
        },
      }).
      otherwise({
        templateUrl: 'templates/partials/errors/not-found.html',
        resolve: {
          redirect: ['$location', function ($location) {
            var referrer = $location.search().redirect_referrer;
            if (referrer !== '2014') {
              var url = new URL($location.absUrl());
              url.searchParams.set('redirect_referrer', '2014');
              location.replace(url.toString());
              return new Promise(function(){});
            }
          }],
        },
        controller: ['bugsnag', '$location', function (bugsnag, $location) {
          bugsnag.notify('NotFoundError', 'Could not find route matching url - "' + $location.url() + '"');
        }]
      });
  }]);

var ppa = window.__pageproof_app__ = {};
createProof.config(function($controllerProvider, $provide, $compileProvider) {
  ppa.controller = function(name, constructor) {
    $controllerProvider.register(name, constructor);
    return this;
  };
  ppa.service = function(name, constructor) {
    $provide.service(name, constructor);
    return this;
  };
  ppa.factory = function(name, factory) {
    $provide.factory(name, factory);
    return this;
  };
  ppa.value = function(name, value) {
    $provide.value(name, value);
    return this;
  };
  ppa.directive = function(name, factory) {
    $compileProvider.directive(name, factory);
    return this;
  };
});
