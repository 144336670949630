/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.filter('highlight', function($sce) {
    return function (str, terms) {
        terms = angular.isArray(terms) ? terms : [terms];
        terms = terms.filter((a) => a && a.trim().length);

        if (terms.length) {
            terms.sort((a, b) => b.length - a.length);
            terms = terms.map((a) => a.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'));

            let regex = new RegExp('(' + terms.join('|') + ')', 'g');
            return $sce.trustAsHtml(str.replace(regex, '<span class="highlight">$&</span>'));
        } else {
            return str;
        }
    };
});
