/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.directive('scrollTo', function ($location, domService, eventService) {
    return {
        /// <a scroll-to="{ element: '#example', time: 1000, add: -100 }">Example</a>
        /// <a scroll-to="'#example'">Example</a>
        link (scope, element, attr) {
            let options = {};

            attr.$observe('scrollTo', (attr) => {
                let parsed = scope.$eval(attr);

                if (angular.isObject(parsed)) {
                    options = parsed;
                } else if (angular.isString(parsed)) {
                    options.element = parsed;
                }
            });

            scope.$on('$destroy', eventService.on(element, 'click', (event) => {
                domService.scrollTo(options.element, options.time, options.add);
            }));
        }
    };
});
