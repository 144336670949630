/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
var _supportedVideoFileTypes = [
    // Supported video file types from Microsoft Azure;
    // https://azure.microsoft.com/en-us/documentation/articles/media-services-azure-media-encoder-formats/
    "mp4",
    "avi",
    'mov',
    'gif',
    'flv',
    'wmv',
    'mkv',
    'm4v',
];

var _supportedAudioFileTypes = [
    'mp3',
    'wav',
];

var _supportedDocumentFileTypes = [
    "pdf",
    "jpg",
    "jpeg",
    "jfif",
    "png",
    "psd",
    "doc",
    "docx",
    "ppt",
    "pptx",
    "xls",
    "xlsx",
    "dot",
    "dotx",
    "pot",
    "potx",
    "xlt",
    "xltx",
    "tif",
    "tiff",
    "psb",
    "ai",
    "eps",
    "cr2",
    "nef",
    "arw",
    "dng",
    "txt",
    "rtf",
    "svg",
    "webp",
    "heic",
    "key",
    "pages",
    "numbers",
    "indd",
    "otf",
    "ttf",
    "ttc",
    "csv",
    "eml",
    "msg",
    "srt",
    "vtt",
    "bmp"
];

var _supportedWebFileTypes = [
    'zip',
];

var _supportedCompareFileTypes = [
    "pdf",
    "jpg",
    "jpeg",
    "jfif",
    "png",
    "psd",
    "doc",
    "docx",
    "ppt",
    "pptx",
    "xls",
    "xlsx",
    "dot",
    "dotx",
    "pot",
    "potx",
    "xlt",
    "xltx",
    "tif",
    "tiff",
    "psb",
    "cr2",
    "nef",
    "arw",
    "dng",
    "txt",
    "rtf",
    "svg",
    "webp",
    "heic",
    "key",
    "pages",
    "numbers",
    "indd",
    "otf",
    "ttf",
    "ttc",
    "csv",
    "srt",
    "vtt",
    "bmp"
];

var _betaFileTypes = [
    // 'zip'
];

function Application(){
    this.config = {
        MaxUploadSize:Number(env('max_upload_size', 524288000)), //500MB - 255 267386880
        MaxAttachmentUploadSize:Number(env('max_attachment_upload_size', 33554432)), //2 mb - 2097152 // 10.1mb - 10100000 // 25MB - 26214400 // 32MB - 33554432
        DashboardRefreshInterval: Number(env('dashboard_refresh_interval', 60000)), // 1 minutes
        CommentRefreshInterval: Number(env('comment_refresh_interval', 15000)), // 15 seconds
        UpdateInterval: Number(env('update_interval', 0)), // Disable updater by default // 5 * (1e3 * 60))), // 5 minutes
        AutoPullUserData: 2 * (60 * 1e3), // 2 minutes
        XHours:48,
        NudgeWaitMinutes:15, // 15 mins (used to be 120 mins - 2 hours)
        ActivityCount:10,
        PDFWorker:"workers/getpdfdata.js",
        MaxImageSizeBreak:15000,
        TrialPeriod:env('trial_days') ? env('trial_days'):14,
        SupportedVideoFileTypes: _supportedVideoFileTypes,
        SupportedAudioFileTypes: _supportedAudioFileTypes,
        SupportedCompareFileTypes: _supportedCompareFileTypes,
        SupportedStaticFileTypes: _supportedDocumentFileTypes,
        AllowedFileTypes: _supportedDocumentFileTypes.concat(_supportedVideoFileTypes, _supportedAudioFileTypes, _supportedWebFileTypes),
        AllowedAttachmentFileTypes: _supportedDocumentFileTypes.concat(['zip','mp4','mov','gif','m4v','wav','xml']), // Add additional filetypes for attachments (outside of document filetypes)
        AllowedFileMimeTypes: [
            'application/pdf',
            'image/jpg',
            'image/jpeg',
            'image/jfif',
            'image/png',
            'image/tif',
            'image/eps',
            'image/dng',
            'image/cr2',
            'image/nef',
            'image/arw',
            'image/gif',
            'video/mp4',
            'video/m4v',
            'video/wmv',
            'video/avi',
            'video/flv',
            'video/mkv',
            'video/mpeg',
            'video/quicktime',
            'audio/mp3',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'image/vnd.adobe.photoshop',
            'image/vnd.adobe.illustrator',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/plain',
            'image/svg',
            'image/webp',
            'image/heic',
            'audio/wav',
            'text/xml',
            'image/bmp',
            'image/tiff'
        ],
        DataUrlImage:"data:image/png;base64,",
        DataUrlJPEG:"data:image/jpg;base64,",
        DataUrlPdf:"data:application/pdf;base64,",
        DataUrlOctetStream:"data:Application/octet-stream;base64,",
        DownloadFileTypes: {
            pdf: "data:application/pdf;base64,",
            jpg: "data:image/jpg;base64,",
            jpeg: "data:image/jpg;base64,",
            jfif: "data:image/jpg;base64,",
            png: "data:image/png;base64,",
            psd: "data:Application/octet-stream;base64,",
            doc: "data:Application/octet-stream;base64,",
            docx: "data:Application/octet-stream;base64,",
            ppt: "data:Application/octet-stream;base64,",
            pptx: "data:Application/octet-stream;base64,",
            xls: "data:Application/octet-stream;base64,",
            xlsx: "data:Application/octet-stream;base64,",
            dot: "data:Application/octet-stream;base64,",
            dotx: "data:Application/octet-stream;base64,",
            pot: "data:Application/octet-stream;base64,",
            potx: "data:Application/octet-stream;base64,",
            xlt: "data:Application/octet-stream;base64,",
            xltx: "data:Application/octet-stream;base64,",
            tif: "data:Application/octet-stream;base64,",
            tiff: "data:Application/octet-stream;base64,",
            psb: "data:Application/octet-stream;base64,",
            ai: "data:Application/octet-stream;base64,",
            eps: "data:Application/octet-stream;base64,",
            mp4: "data:video/mp4;base64,",
            m4v: "data:video/m4v;base64,",
            wmv: "data:video/wmv;base64,",
            avi: "data:video/avi;base64,",
            flv: "data:video/flv;base64,",
            mkv: "data:video/mkv;base64,",
            mpeg: "data:video/mpeg;base64,",
            mpg: "data:video/mpeg;base64,",
            m2v: "data:video/mpeg;base64,",
            mov: "data:video/quicktime;base64,",
            mp3: 'data:audio/mp3;base64,',
            dng: "data:image/dng;base64,",
            cr2: "data:image/cr2;base64,",
            nef: "data:image/nef;base64,",
            arw: "data:image/arw;base64,",
            txt: "data:text/plain;base64,",
            rtf: "data:application/rtf;base64,",
            svg: "data:image/svg;base64,",
            webp: "data:image/webp;base64,",
            heic: "data:image/heic;base64,",
            key: "data:Application/octet-stream;base64,",
            pages: "data:Application/octet-stream;base64,",
            numbers: "data:Application/octet-stream;base64,",
            indd: "data:Application/octet-stream;base64,",
            otf: "data:Application/octet-stream;base64,",
            ttf: "data:Application/octet-stream;base64,",
            ttc: "data:Application/octet-stream;base64,",
            csv: "data:application/plain;base64,",
            eml: "data:application/plain;base64,",
            msg: "data:application/plain;base64,",
            wav: "data:audio/wav;base64,",
            zip: "data:Application/octet-stream;base64,",
            xml: "data:text/xml;base64,",
            srt: "data:text/plain;base64,",
            vtt: "data:text/plain;base64,",
            bmp: "data:image/bmp;base64,",
        },



        ShowTechnicalErrors:true,
        HideErrorPopup:false,
        EnableStrongPasswords:false,
        ResourcesPath:"app/resources/",
        UserErrorMessage_APIError:"There has been an error with the API.",
        UserErrorMessage_GenericUserMessage:"There has been an error."
    };

    this.keys = {
        pubKeyUser:"",
        priKeyUser:"",
        pubKeyServer:""
    };

    this.api = new API(this);

    this.storage = new PageProof.Storage();
    this.sessionStorage = new PageProof.Storage(undefined, "sessionStorage");

    this.enc = new PageProofEncryption();

    this.avatar = "";

    this.worker = null;

    if(this.storage.has('userData')){
        this.userData = this.GetUserData();
        this.ResetEncVars();
    }else{
        this.userData = {};
    }

    if(this.IsLoggedIn()){
        this.showMenu();
    }

    this.useDataUrl = false;
    this.events = {};

    this.clearLegacyLocalStorage();
    this.clearLocalStorage(); 
}

Application.prototype.buildAttachmentDataUrl = function(type, data){
    switch(type) {
        case "pdf":
            return this.GetConfigSetting("DataUrlPdf") + data;
            break;
        case "jpg":
        case "jpeg":
        case "jfif":
            return this.GetConfigSetting("DataUrlJPEG") + data;
            break;
        case "png":
            return this.GetConfigSetting("DataUrlImage") + data;
            break;
        case "doc":
        case "docx":
        case "xls":
        case "xlsx":
        case "ppt":
        case "pptx":
        case "dot":
        case "dotx":
        case "xlt":
        case "xltx":
        case "pot":
        case "potx":
        case "psd":
        case "tiff":
        case "tif":
        case "txt":
        case "rtf":
        case "key":
        case "pages":
        case "numbers":
        case "indd":
        case "otf":
        case "ttf":
        case "ttc":
        case "csv":
        case "eml":
        case "msg":
        case "zip":
        case "xml":
        case "srt":
        case "vtt":
        case "bmp":
                return this.GetConfigSetting("DataUrlOctetStream") + data;
            break;
        default:
            return "";
    }
};

Application.prototype.UpdateUserData = function (changes, clean) {
    var that = this,
        allowedKeys = [
            'userId',
            'userEmail',
            'encObj',
            'userAvatar',
            'accountType',
            'teamType',
            'lastUpload',
            'isActivated',
            'isDomainAdmin',
            'lastLogin',
            'trialEndDate',
            'trialStartDate',
            'trialStatus',
            'brandingUrl',
            'features',
            'domainId', // teamId
        ];

    const unmatchedKeys = {
        domainId: 'teamId',
    };

    // Optionally clean out the user data object (for setting)
    var userData = clean ? {} : (this.GetUserData() || {});
    that.userData = clean ? {} : (that.userData || {});

    Object.keys(changes).forEach(function (_key) {
        var key = (_key.charAt(0).toLowerCase() + _key.substring(1));

        if (allowedKeys.indexOf(key) !== -1) {
            that.userData[unmatchedKeys[key] || key] = changes[_key];
        }
    });

    console.log("that.userData: ", that.userData);

    // Reset the encryption variables (just in case they changed too)
    this.ResetEncVars();

    // Re-set the userData string in sessionStorage
    this.storage.setJSON('userData', that.userData);

    // copy userId into cookies for other subdomains to use
    if(!window.__pageproof_quark__.cookies.get('userId') && that.userData.userId) {
        window.__pageproof_quark__.cookies.set('userId', (that.userData.userId + '; domain=.' + env('root_domain', 'pageproof.com')));
    }

    // Trigger an update for any event listeners
    this._triggerUserDataUpdated();
};

Application.prototype._triggerUserDataUpdated = function () {
    var userData = this.GetUserData();

    if (this.events.userDataUpdated) {
        this.events.userDataUpdated.forEach(function (callback) {
            callback(userData);
        });
    }
};

Application.prototype.on = function (event, callback) {
    if ( ! this.events.hasOwnProperty(event)) {
        this.events[event] = [];
    }

    if (typeof callback === 'function') {
        this.events[event].push(callback);
    }
};

Application.prototype.off = function (event, callback) {
    if ( ! this.events.hasOwnProperty(event)) {
        return;
    }

    var index;
    if ((index = this.events[event].indexOf(callback)) !== -1) {
        this.events[event].splice(index, 1);
    }
};

Application.prototype.SetUserData = function(userId, userEmail, userPassword, accountType, lastUpload, encObj, isActivated, isDomainAdmin, lastLogin, brandingUrl, features, teamId){
    this.UpdateUserData({
        userId: userId,
        userEmail: userEmail,
        encObj: encObj,
        userAvatar: '',
        accountType: accountType,
        lastUpload: lastUpload,
        isActivated: isActivated,
        isDomainAdmin: isDomainAdmin,
        lastLogin: lastLogin,
        brandingUrl: brandingUrl,
        features: features,
        teamId: teamId,
    }, true);

    return isset(this.userData);
};

Application.prototype.SetProofData = function(proofId){
    this.proofData = {proofId:proofId};

    this.storage.setJSON('proofData', this.proofData);

    if(isset(this.proofData)){
        return true;
    }
    return false;
};

Application.prototype.SetImageData = function(imageToken){
    this.imageData = {imageToken:imageToken};

    this.storage.setJSON('imageData', this.imageData);

    if(isset(this.imageData)){
        return true;
    }
    return false;
};

Application.prototype.GetImageData = function(){
    if(this.storage.has('imageData')){
        return this.storage.getJSON('imageData');
    }else if(isset(this.imageData)){
        return this.imageData;
    }else{
        console.log("No image data set.");
    }
    return false;
};

Application.prototype.GetProofData = function(){
    if(this.storage.has('proofData')){
        return this.storage.getJSON('proofData');
    }else if(isset(this.proofData)){
        return this.proofData;
    }else{
        console.log("No proof data set.");
    }
    return false;
};

Application.prototype.GetUserData = function () {
    if ( ! this.userData && this.storage.has('userData')) {
        this.userData = this.storage.getJSON('userData');
    }
    var defaultFeatures = {
        supportChat: true,
        createNewProofs: true,
        proofReference: false,
        publicProofs: false,
        defaultProofIsPublic: false,
    };
    if (this.userData && !angular.equals(this.userData, {}) && !this.userData.features) {
        this.userData.features = defaultFeatures;
    }

    return this.userData;
};

Application.prototype.GetUserAvatar = function(){
    return this.avatar;
};

Application.prototype.IsActivated = function () {
    return this.IsLoggedIn() && !! this.userData.isActivated;
};

Application.prototype.IsDomainAdmin = function () {
    return this.IsLoggedIn() && this.userData.isDomainAdmin;
};

Application.prototype.IsLoggedIn = function(){
    if(isset(this.userData) && isset(this.userData["userId"]) && isset(this.userData["userEmail"])){
       if(isset(this.userData["encObj"])){
           if(isset(this.userData["encObj"]["privateKeyUserPEM"]) &&
                isset(this.userData["encObj"]["publicKeyServerPEM"]) &&
                isset(this.userData["encObj"]["publicKeyUserPEM"]) &&
                isset(this.userData["encObj"]["userSharedSecretKey"]) &&
                isset(this.userData["encObj"]["userToken"])){
                return true;
           }
       }
    }
    return false;
};

Application.prototype.Register = function(email, password, isTemp, proofId, callBackFunc) {
    if (isset(email) && isset(password) && validateEmail(email)) {
        var self = this;
        var enc = this.enc;
        this.GenerateKeys(password, function(publicKey, privateKey, passwordHash){
            self.api.CallAPI("users", "register",
                {
                    Email: email,
                    Password: passwordHash,
                    PublicKey: publicKey,
                    PrivateKey: privateKey,
                    IsTemp: isTemp || false,
                    ProofId: proofId
                }, function() {
                    var returnData = jsonDecode(this.responseText);
                    if(isset(returnData["UserId"])){
                        $(window).trigger($.Event('userregister.pageproof', {
                            user: returnData
                        }));

                        self.Login(email, password, function(result, userData){
                            if (result === 'true') {
                                callBackFunc(null, returnData);
                            } else {
                                callBackFunc(userData.ResponseStatus, userData);
                            }
                        });
                    }else{
                        callBackFunc(returnData.ResponseStatus, returnData);
                    }
            });
        });
    } else {
        return false;
    }
};

Application.prototype.ResetEncVars = function(){

    var userData = this.GetUserData();

    //this.enc.SetPassword(userData["encObj"]["password"]);
    this.enc.SetUserPublicKeyPEM(userData["encObj"]["publicKeyUserPEM"]);
    this.enc.SetServerPublicKeyPEM(userData["encObj"]["publicKeyServerPEM"]);

    this.enc.SetUserPrivateKeyPEM(userData["encObj"]["privateKeyUserPEM"]);

    this.enc.SetUserTokenSharedKey(userData["encObj"]["userToken"], userData["encObj"]["userSharedSecretKey"]);

};

Application.prototype.GenerateKeys = function(password, callBackFunc){

    if(empty(password)) return "";

    var worker = new Worker('workers/workergeneratekeys.js?1');

    worker.addEventListener('message', function(event) {
        worker.terminate();
        callBackFunc(event.data["PublicKey"], event.data["PrivateKey"], event.data["passwordHash"]);
    }, false);

    worker.postMessage({password:password});

};

Application.prototype.clearLocalStorage = function() {
    const now = Date.now();
    const keysToCheck = new Set(Object.keys(localStorage).filter(function(key) {
        return key.startsWith('pageproof.app.proof.');
    }));
    const keysToRemove = [];
    
    keysToCheck.forEach(function(key) {
        if (key.endsWith('expiryDate')) {
            const expiryDate = localStorage.getItem(key);
            const timestamp = Date.parse(expiryDate);
    
            if (!isNaN(timestamp) && now > timestamp) {
                const parts = key.split('.').slice(3, 5);
                const proofID = parts[0];
                const userID = parts[1];
                if (proofID && userID) {
                    const regexPattern = '^pageproof\\.app\\.proof\\.' + proofID + '\\.' + userID + '\\..+$';
                    const regex = new RegExp(regexPattern);
    
                    keysToCheck.forEach(function(potentialKey) {
                        if (regex.test(potentialKey)) {
                            keysToRemove.push(potentialKey);
                        }
                    });
                }
            }
        }
    });
    
    keysToRemove.forEach(function(key) {
        localStorage.removeItem(key);
    });
};

Application.prototype.clearLegacyLocalStorage = function() {
    const cleanedLegacyLocalStorage = localStorage.getItem('pageproof.app.cleanedLegacyLocalStorage');
    
    if (!cleanedLegacyLocalStorage) {
        const legacyKeysToCheck = [
            function(key) { return key.endsWith('.has-seen-multiple-pages'); },
            function(key) { return key.startsWith('pageproof.app.recently-selected-device'); },
            function(key) { return key.startsWith('pageproof.app.measurement-scale'); }
        ];

        const keys = Object.keys(localStorage);
        const legacyKeys = keys.filter(function(key) {
            return legacyKeysToCheck.some(function(check) {
                return check(key);
            });
        });

        legacyKeys.forEach(function(key) {
            localStorage.removeItem(key);
        });

        localStorage.setItem('pageproof.app.cleanedLegacyLocalStorage', 'true');
    }
};


Application.prototype.showMenu = function(){
    $("#show-menu").show();
};

Application.prototype.hideMenu = function(){
    $("#show-menu").hide();
};

Application.prototype.PullUserData = function (callback) {
    var userData = this.GetUserData(),
        users = new Users(this),
        that = this;

    if ( ! (userData && userData.userId)) {
        if (typeof callback === 'function') callback(null);
        return;
    }

    users.Load(userData.userId, function () {
        var updatedUserData = JSON.parse(this.responseText);

        // Fixes a bug where the pull user data updates the lastLogin
        delete updatedUserData.lastLogin;

        that.UpdateUserData(updatedUserData);

        if (typeof callback === 'function') {
            callback(that.GetUserData());
        }
    });
};

Application.prototype.fetchUserData = function(callBackFunc){
    var userData = this.GetUserData();
    var users = new Users(this);
    var self = this;
    users.Load(userData["userId"], function(){
        var userData = jsonDecode(this.responseText);
        if(isFunc(callBackFunc)) callBackFunc(userData);
    });
};

Application.prototype.getLastUploadDate = function(callBackFunc){
    var userData = this.GetUserData();
    var users = new Users(this);
    var self = this;
    users.Load(userData["userId"], function(){
        var lastUpload = "";
        var userData = jsonDecode(this.responseText);
        if(isset(userData["LastUpload"])){
            lastUpload = userData["LastUpload"];
        }
        if(isFunc(callBackFunc)) callBackFunc(lastUpload);
    });
};

Application.prototype.getLastUpload = function(){
    this.getLastUploadDate(function(lastUpload){
        return lastUpload;
    });
};

Application.prototype.isVideoFile = function(fileType){
    var videoExtns = this.config.SupportedVideoFileTypes;
    if(inArray(videoExtns, fileType)){
        return true;
    }else{
        return false;
    }
};

Application.prototype.isAudioFile = function(fileType){
    return this.config.SupportedAudioFileTypes.indexOf(fileType) !== -1;
};

Application.prototype.isComparableFiles = function(fileType) {
    return this.config.SupportedCompareFileTypes.indexOf(fileType) !== -1;
};
Application.prototype.isStaticFile = function(fileType) {
    return this.config.SupportedStaticFileTypes.indexOf(fileType) !== -1;
};

Application.prototype.isWebFile = function(fileType) {
    return fileType === 'zip-Web' || fileType === 'external-Web';
};

Application.prototype.removeMenuAvatar = function(){
    $("#loginAvatar").prop("src", "");
};

Application.prototype.DidLogin = function() {
    this.userData = null; // causes the next `GetUserData` to pull from localStorage
    var userData = this.GetUserData(); // pull from localStorage
    Object.assign(this.enc, userData.encObj);
    this._triggerUserDataUpdated();
    this.showMenu();
    $(window).trigger($.Event('userlogin.pageproof', {
        user: this.userData
    }));
};

Application.prototype.Login = function(email, password, callBackFunc) {
    if (isset(email) && isset(password)) {

        var self = this;
        var enc = this.enc;

        enc.SetPassword(password);

        this.api.CallAPI("users", "login", {Email: email, Password: enc.passwordHash}, function(){
            var returnData = jsonDecode(this.responseText);

            if (isset(returnData.PublicKey) && isset(returnData.PrivateKey) && isset(returnData.Token) && isset(returnData.SharedKey)) {

                enc.SetUserPublicKeyPEM(returnData.PublicKey);
                enc.SetUserPrivateKeyAES(returnData.PrivateKey);
                enc.SetServerPublicKeyPEM(returnData.PublicServerKey);
                enc.SetUserTokenSharedKey(returnData.Token, returnData.SharedKey);
                enc.RemovePassword(); //reset the password
                var saved = self.SetUserData(returnData["UserId"], email, password, returnData["AccountType"], returnData["LastUpload"], enc, returnData.Activated, returnData.IsDomainAdmin, returnData.LastLogin, (returnData.Branding && returnData.Branding.LogoURL) ? returnData.Branding.LogoURL : null, returnData.Features, returnData.DomainId);

                if (saved === true){
                    self.showMenu();

                    callBackFunc("true", returnData);

                    $(window).trigger($.Event('userlogin.pageproof', {
                        user: self.userData
                    }));
                }else{
                    callBackFunc("false", returnData);
                    self.logError("user can not be looged in. Data: " + this.responseText);
                }
            } else {
                callBackFunc("false", returnData);
                self.logError("user can not be logged in. Empty or invalid enc data. email: " + email);
            }
        });
    } else {
        return false;
    }
};

//checkAuth

Application.prototype.CheckAuth = function(userId, code, callBackFunc) {
    if (isset(userId) && isset(code)) {
        this.api.CallAPI("users", "checkAuth", {UserID:userId, AuthCode:code}, function(){
            var returnData = jsonDecode(this.responseText);
            if(isFunc(callBackFunc)) callBackFunc(returnData);
        });
    } else {
        return false;
    }
};

Application.prototype.GenerateAuth = function(userId, callBackFunc) {
    if (isset(userId)) {
        this.api.CallAPI("users", "generateAuth", userId, function(){
            var returnData = jsonDecode(this.responseText);
            if(isFunc(callBackFunc)) callBackFunc(returnData);
        });
    } else {
        return false;
    }
};

Application.prototype.Logout = function(callBackFunc){

    this.api.CallAPI("users", "logout", "", function(){});

    this.userData = {};
    this.storage.remove('userData');
    this.storage.remove('proofData');
    this.storage.remove('imageData');
    this.storage.remove('workflowId');
    this.storage.remove('uploadFinished');
    this.storage.remove('progress');
    this.storage.remove('proof-setup');
    window.__pageproof_quark__.cookies.set('userId', '; domain=.' + env('root_domain', 'pageproof.com'), new Date('1970-01-01'));

    const commentQueue = new PageProof.Storage('pageproof.app.commentQueue.');
    commentQueue.clear();

    this.hideMenu();
    this.removeMenuAvatar();

    $(window).trigger($.Event('userlogout.pageproof'));

    if(isFunc(callBackFunc)) callBackFunc();
};

Application.prototype.toggleDataUri = function(bool){
    this.useDataUrl = bool;
};

Application.prototype.switchImgType = function(type){
    if( !type ) type = "png";
    if(type == "jpg"){
        return "DataUrlJPEG"; //new jpg format
    }else{
        return "DataUrlImage"; //png default
    }
};

Application.prototype.LogUserData = function(){
    console.log(this.storage.get('userData'));
};

Application.prototype.ClearStorageVariables = function(){
    this.storage.remove('userData');
};

Application.prototype.GetUserParams = function(param){
    if(param!=="" && ( typeof this.userData[param] !== 'undefined' ) ){
        return this.userData[param];
    }else{
        return this.userData;
    }
};

Application.prototype.GetConfigSetting = function(setting){
    if(setting!=="" && ( typeof this.config[setting] !== 'undefined' ) ){
        return this.config[setting];
    }else{
        return this.config;
    }
};

Application.prototype.isValidAttachmentFileType = function(ex){
    return this.config["AllowedAttachmentFileTypes"].indexOf(ex) !== -1;
};

Application.prototype.isValidFileType = function(ex){
    if (_betaFileTypes.indexOf(String(ex).toLowerCase()) !== -1) {
        return !!String(this.GetUserData().userEmail).match(/\@pageproof\.com$/);
    }
    return this.config["AllowedFileTypes"].indexOf(ex) !== -1;
};

Application.prototype.isValidAttachmentFileSize = function(size){
    return size <= this.config.MaxAttachmentUploadSize;
};

Application.prototype.isValidFileSize = function(size){
    return size <= this.config.MaxUploadSize;
};

Application.prototype.showErrorBox = function(errorText, technicalData, callBackFunc){};

Application.prototype.logError = function(errorText){};

Application.prototype.logInfo = function(technicalData){};

Application.prototype.logControllerError = function(technicalData){
    this.showErrorBox(this.config["UserErrorMessage_GenericUserMessage"], technicalData);
};

Application.prototype.showServerMessage = function(message, addinfo){
    if(empty(window.bby_ui)) return "";
    if(empty(addinfo)) addinfo = "";
    this.hideLoader();
    window.bby_ui.forms.doServerMsg(message, addinfo);
};

Application.prototype.hideServerMessages = function(){
    if(empty(window.bby_ui)) return "";
    window.bby_ui.forms.clearServerMsg();
};

Application.prototype.showLoader = function(){
    if(empty(window.bby_ui)) return "";
    window.bby_ui.loading.showLoader();
    this.hideLoaderButton();
};

Application.prototype.hideLoader = function(){
    if(empty(window.bby_ui)) return "";
    window.bby_ui.loading.hideLoader();
    this.hideLoaderMessage();
    this.hideLoaderButton();
};

Application.prototype.AddToProcessingArray = function(proofId){
    var arr = this.storage.getJSON('uploadArray', []);
    if(arr.indexOf(proofId) == -1){
        arr.push(proofId);
    }
    this.storage.setJSON('uploadArray', arr);
};

Application.prototype.GetProcessingArray = function(){
    return this.storage.getJSON('uploadArray', []);
};

Application.prototype.isProofIdInProcessingArray = function(proofId){
    var arr = this.sessionStorage.getJSON('uploadArray', []);
    if(arr.indexOf(proofId) == -1){
        return false;
    }else{
        return true;
    }
};

Application.prototype.RemoveFromProcessingArray = function(proofId){
    var arr = this.sessionStorage.getJSON('uploadArray', []);
    var indx = arr.indexOf(proofId);
    if (indx > -1) {
        arr.splice(indx, 1);
    }
    this.sessionStorage.setJSON('uploadArray', arr);
};

Application.prototype.showLoaderMessage = function(message){
    if(!message) message = "Hang on a sec, we’re securing your account details.";
    $("#loader_message").html(message);
    $("#loader_message").show();
    this.hideLoaderButton();
};

Application.prototype.hideLoaderMessage = function(){
    $("#loader_message").hide();
};

Application.prototype.addLoaderButton = function (text, classes, callback) {
    var $el = angular.element('<button class="btn btn-at"></button>');
    $el.text(text).on('click', callback).appendTo('#loader-button-container');

    if (classes) {
        $el.prop('className', classes);
    }
};

Application.prototype.showLoaderButton = function (buttons) {
    var self = this;

    if (buttons) {
        angular.forEach(buttons, function (button) {
            self.addLoaderButton(button.text, button.classes, button.callback);
        });
    }

    $('#loader-button-container').show();
};

Application.prototype.hideLoaderButton = function () {
    $('#loader-button-container').empty().hide();
};
