/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class PinController {
    /**
     * The color of the pin.
     *
     * @type {String}
     */
    color = 'grey';

    /**
     * The xy12 data attached to the pin.
     *
     * @type {Object}
     */
    data = null;

    /**
     * The highlight label.
     *
     * @type {String|null}
     */
    highlight = null;

    /**
     * The surface the pin is on.
     *
     * @type {PinSurfaceController}
     */
    surface = null;

    /**
     * The number of pixels to set as the snapping threshold.
     *
     * @type {Number}
     */
    threshold = 20;

    /**
     * Whether the pin is in the 'selected' state.
     *
     * @type {Boolean}
     */
    isSelected = false;

    /**
     * Whether the pin can be selected.
     *
     * @type {Boolean}
     */
    canSelect = false;

    /**
     * Callback when the user tries to select a pin.
     *
     * @type {Function}
     */
    whenSelect = null;

    /**
     * Whether the pin can be resized/moved.
     *
     * @type {Boolean}
     */
    canUpdate = false;

    /**
     * Callback when the pin is updated.
     *
     * @type {Function}
     */
    whenUpdate = null;

    /**
     * By default pin is visible if allow to show it
     * Will be changing if pin is used in compare's index.html
     * @type {number}
     */
    opacity = 1;

    /**
     * The user id of the user (for avatar preview).
     *
     * @type {string|null}
     */
    userId = null;

    isInverted = false;

    isInteracting = false;

    /**
     * @constructor
     */
    constructor ($scope, $element, pinService) {
        this.$$ = { $element, pinService };
        this.scope = $scope;
        this.data = {};
        this.css = {};
    }

    /**
     *
     */
    position (data, save) {
        let percentX1 = this.$$.pinService.getPointToPercent(data.x1),
            percentY1 = this.$$.pinService.getPointToPercent(data.y1),
            percentX2 = percentX1,
            percentY2 = percentY1;

        if (data.x2 !== null) {
            percentX2 = this.$$.pinService.getPointToPercent(data.x2);
        }

        if (data.y2 !== null) {
            percentY2 = this.$$.pinService.getPointToPercent(data.y2);
        }

        let startX = Math.min(percentX1, percentX2),
            startY = Math.min(percentY1, percentY2),
            width = Math.abs(percentX1 - percentX2),
            height = Math.abs(percentY1 - percentY2);

        let css = {
            icon: {
                top: percentY1 + '%',
                left: percentX1 + '%'
            },
            handle: {
                top: percentY2 + '%',
                left: percentX2 + '%',
                cursor: this.getHandleCursor(data)
            },
            top: {
                top: startY + '%',
                left: startX + '%',
                width: width + '%'
            },
            left: {
                top: startY + '%',
                left: startX + '%',
                height: height + '%'
            },
            right: {
                top: startY + '%',
                left: startX + width + '%',
                height: height + '%'
            },
            bottom: {
                top: startY + height + '%',
                left: startX + '%',
                width: width + '%'
            },
            overlay: {
                top: startY + '%',
                left: startX + '%',
                width: width + '%',
                height: height + '%'
            }
        };

        if (save) {
            this.css = angular.extend(this.css, css);
        }

        this.applyManualPositionCSS();

        return css;
    }

    applyManualPositionCSS(css = this.css, immediate = false) {
        const handle = () => {
            this.$$.$element.find('[data-pin-style]').each(function () {
                const $element = angular.element(this);
                $element.css(css[$element.attr('data-pin-style')]);
            });
        };
        if (immediate) {
            handle();
        } else {
            requestAnimationFrame(handle);
        }
    }

    getHandleCursor (xy12) {
        if (xy12.x2 === null) {
            return 'ns-resize';
        } else if (xy12.y2 === null) {
            return 'ew-resize';
        } else if ((xy12.x2 > xy12.x1 && xy12.y2 > xy12.y1) || (xy12.x2 < xy12.x1 && xy12.y2 < xy12.y1)) {
            return 'nwse-resize';
        } else if ((xy12.x2 < xy12.x1 && xy12.y2 > xy12.y1) || (xy12.x2 > xy12.x1 && xy12.y2 < xy12.y1)) {
            return 'nesw-resize';
        }
    }

    /**
     * Recalculates the elements positions & dimensions.
     */
    reposition () {
        this.position(this.data, true);
    }

    /**
     *
     * @param {Number} x
     * @param {Number} y
     * @param {ClientRect} surface
     * @param {Boolean} [save]
     */
    moveTo (x, y, surface, save) {
        let x1 = x,
            y1 = y,
            x2 = this.data.x2,
            y2 = this.data.y2;

        // start

        if (x2 !== null) {
            let diff = this.data.x2 - this.data.x1;
            x2 = this.$$.pinService.getNormalisedPoint(x + diff);
        }

        if (y2 !== null) {
            let diff = this.data.y2 - this.data.y1;
            y2 = this.$$.pinService.getNormalisedPoint(y + diff);
        }

        // block: auto calc handle

        let offset1 = this.$$.pinService.getOffsetFromPoints(surface, { x: x1, y: y1 }),
            offset2 = this.$$.pinService.getOffsetFromPoints(surface, { x: x2, y: y2 });

        if (x2 !== null) {
            if (this.$$.pinService.isWithinThreshold(offset1.x, offset2.x, this.threshold)) {
                x2 = null;
            }
        }

        if (y2 !== null) {
            if (this.$$.pinService.isWithinThreshold(offset1.y, offset2.y, this.threshold)) {
                y2 = null;
            }
        }

        // end block: auto calc handle

        // end

        let data = { x1, y1, x2, y2 };

        if (save) {
            this.data = angular.extend(this.data, data);
        }

        return data;
    }

    /**
     *
     * @param {Number} x
     * @param {Number} y
     * @param {ClientRect} surface
     * @param {Boolean} [save]
     */
    resizeTo (x, y, surface, save) {
        let x1 = this.data.x1,
            y1 = this.data.y1,
            x2 = x,
            y2 = y;

        // start

        // block: auto calc handle

        let offset1 = this.$$.pinService.getOffsetFromPoints(surface, { x: x1, y: y1 }),
            offset2 = this.$$.pinService.getOffsetFromPoints(surface, { x: x2, y: y2 });

        if (x2 !== null) {
            if (this.$$.pinService.isWithinThreshold(offset1.x, offset2.x, this.threshold)) {
                x2 = null;
            }
        }

        if (y2 !== null) {
            if (this.$$.pinService.isWithinThreshold(offset1.y, offset2.y, this.threshold)) {
                y2 = null;
            }
        }

        // end block: auto calc handle

        // end

        let data = { x1, y1, x2, y2 };

        if (save) {
            this.data = angular.extend(this.data, data);
        }

        return data;
    }

    /**
     * Starts a user interaction (moving pin).
     *
     * @param {Event} event
     * @param {Function} [update]
     * @param {Function} [end]
     * @private
     */
    _startMove (event, update, end) {
        let updateXY = (x, y, surface, container, save, current, callback) => {
            this.$moving = current;

            if (angular.isDefined(x) && angular.isDefined(y)) {
                this.position(this.moveTo(x, y, surface, save), true);
                container.style.cursor = 'move';
            }

            if (angular.isFunction(callback)) {
                callback(this.data);
            }
        };

        this._createCursorHandler(event,
            (x, y, surface, container) => updateXY(x, y, surface, container, false, true, update), // update
            (x, y, surface, container) => updateXY(x, y, surface, container, true, false, end) // end
        );
    }

    /**
     * Starts a user interaction (resizing pin).
     *
     * @param {Event} event
     * @param {Function} [update]
     * @param {Function} [end]
     * @private
     */
    _startResize (event, update, end) {
        let updateXY = (x, y, surface, container, save, current, callback) => {
            this.$resizing = current;

            if (angular.isDefined(x) && angular.isDefined(y)) {
                let xy12 = this.resizeTo(x, y, surface, save);
                this.position(xy12, true);

                container.style.cursor = this.getHandleCursor(xy12);
            }

            if (angular.isFunction(callback)) {
                callback(this.data);
            }
        };

        this._createCursorHandler(event,
            (x, y, surface, container) => updateXY(x, y, surface, container, false, true, update), // update
            (x, y, surface, container) => updateXY(x, y, surface, container, true, false, end) // end
        );
    }

    /**
     *
     * @param {Event} event
     * @param {Function} update
     * @param {Function} end
     * @private
     */
    _createCursorHandler (event, update, end) {
        event.preventDefault();
        event.stopPropagation();

        let that = this,
            pointerMoveHandler,
            pointerUpHandler,
            surface = this.surface.getSurface(),
            hasCursorMoved = false;

        const container = document.fullscreenElement || document.body;

        function handle (event, callback) {
            event.stopPropagation();
            event.preventDefault();

            let { x, y } = that.$$.pinService.getPointFromEvent(event, surface);
            callback(x, y, surface, container);
            hasCursorMoved = true;
        }

        if (typeof event.button !== 'undefined' && event.button !== 0) { // 0 = left click, 1 = middle click, 2 = right click
            return;
        }

        function destroy() {
            that.isInteracting = false;
            that.surface.element.removeEventListener('pointermove', pointerMoveHandler);
            that.surface.element.removeEventListener('pointerup', pointerUpHandler);
            container.style.cursor = '';
        }

        let wrap = (callback) => {
            // Wrap the callback in a forced angular apply (pass through arguments)
            return (...args) => this.scope.$apply(() => callback(...args));
        };

        // update = wrap(update);
        end = wrap(end);

        this.surface.element.addEventListener('pointermove', pointerMoveHandler = (event) => {
            event.preventDefault();
            handle(event, update);
        });

        this.surface.element.addEventListener('pointerup', pointerUpHandler = (event) => {
            event.preventDefault();
            event.stopPropagation();
            setTimeout(() => {
                try {
                    if (hasCursorMoved) {
                        handle(event, end);
                    } else {
                        end(void 0, void 0, surface, container);
                    }
                } finally {
                    destroy();
                }
            });
        });

        this.isInteracting = true;
        handle(event, update);
    }

    /**
     * Attempt to select the pin.
     *
     * @param {Event} event
     * @see {PinController.whenSelect}
     */
    selectPin(event) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }

        if (this.canSelect && ! this.isSelected) {
            this.whenSelect({ data: this.data, box: this.getPinPosition() });
        }
    }

    getPinPosition () {
        let surface = this.surface.getSurface();
        let offset = this.$$.pinService.getOffsetFromPoints(surface, {x: this.data.x1, y: this.data.y1});
        let box= {
            left: offset.x + surface.left,
            top: offset.y + surface.top,
        };
        return box;
    }

    getPinTooltipPosition () {
        const HEADER_HEIGHT = 100;
        const pinHalfWidth = 7;
        const surface = this.surface.getSurface();
        const {x, y} = this.$$.pinService.getOffsetFromPoints(surface, {x: this.data.x1, y: this.data.y1});
        return {
            top: y + HEADER_HEIGHT + pinHalfWidth,
            left: x - pinHalfWidth,
            width: pinHalfWidth * 2,
            surfaceTop: surface.top - HEADER_HEIGHT
        };
    }

    /**
     * If the user can update the pin, start a move action.
     *
     * @see {PinController._startMove}
     */
    movePin(event) {
        if (this.canUpdate) {
            this._startMove(event, null, () => {
                if (this.whenUpdate) {
                    this.whenUpdate({ data: this.data });
                }
            });
        }
    }

    /**
     * If the user can update the pin, start a resize action.
     *
     * @see {PinController._startResize}
     */
    resizePin (event) {
        if (this.canUpdate) {
            this._startResize(event, null, () => {
                if (this.whenUpdate) {
                    this.whenUpdate({ data: this.data });
                }
            });
        }
    }

    getPinColorStyles() {
        return {
            'background-color': !this.isInverted && this.color,
            'border-color': this.isInverted && this.color,
        }
    }
}

function PinDirective ($parse, directiveHelper) {
    return {
        require: ['pin', '?^pinSurface'],
        controller: 'PinController',
        controllerAs: 'pinCtrl',
        templateUrl: 'templates/partials/proof/components/pin.html',
        scope: true,

        link (scope, element, attr, [pinCtrl, pinSurfaceCtrl]) {
            directiveHelper.oneWayBinding(scope, attr, 'color', pinCtrl, 'color');
            directiveHelper.oneWayBinding(scope, attr, 'data', pinCtrl, 'data', true, () => {
                if (!pinCtrl.isInteracting) {
                    pinCtrl.reposition();
                }
            });
            directiveHelper.oneWayBinding(scope, attr, 'canUpdate', pinCtrl, 'canUpdate');
            directiveHelper.callbackBinding(scope, attr, 'whenUpdate', pinCtrl, 'whenUpdate');

            directiveHelper.oneWayBinding(scope, attr, 'isSelected', pinCtrl, 'isSelected');
            directiveHelper.oneWayBinding(scope, attr, 'isInverted', pinCtrl, 'isInverted');
            directiveHelper.oneWayBinding(scope, attr, 'canSelect', pinCtrl, 'canSelect');
            directiveHelper.oneWayBinding(scope, attr, 'opacity', pinCtrl, 'opacity');
            directiveHelper.callbackBinding(scope, attr, 'whenSelect', pinCtrl, 'whenSelect');

            if ('heading' in attr) {
                directiveHelper.expressionBinding(scope, attr, 'heading', pinCtrl, 'heading');
            }

            if ('highlight' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'highlight', pinCtrl, 'highlight');
            }

            if ('caption' in attr) {
                directiveHelper.expressionBinding(scope, attr, 'caption', pinCtrl, 'caption');
            }

            if ('userId' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'userId', pinCtrl, 'userId');
            }

            if ('bind' in attr) {
                $parse(attr.bind).assign(scope, pinCtrl);
            }

            if (pinSurfaceCtrl) {
                pinCtrl.surface = pinSurfaceCtrl;
            }

            element[0].querySelector('[data-pin-event="move"]')
                .addEventListener('pointerdown', (event) => pinCtrl.movePin(event));

            element[0].querySelector('[data-pin-event="resize"]')
                .addEventListener('pointerdown', (event) => pinCtrl.resizePin(event));
        }
    };
}

class PinService {
    /**
     * Convert a point to a percentage.
     *
     * @param {Number} point
     * @returns {Number}
     */
    getPointToPercent (point) {
        return (point + .5) * 100;
    }

    /**
     * Convert a percent to a point.
     *
     * @param {Number} percent
     * @returns {Number}
     */
    getPercentToPoint (percent) {
        return (percent / 100) - .5;
    }

    /**
     * Returns a (normalised) point between -.5 and .5.
     *
     * @param {Number} point
     * @returns {Number}
     */
    getNormalisedPoint (point) {
        return between(point, -.5, .5);
    }

    /**
     * Gets the offset pixels from a point.
     *
     * @param {ClientRect} surface
     * @param {Number} x
     * @param {Number} y
     * @returns {Object}
     */
    getOffsetFromPoints (surface, { x, y }) {
        return {
            x: surface.width * (x + .5),
            y: surface.height * (y + .5)
        };
    }

    /**
     * Gets the points from the offset.
     *
     * @param {ClientRect} surface
     * @param {Number} x
     * @param {Number} y
     * @returns {Object}
     */
    getPointsFromOffset (surface, { x, y }) {
        return {
            x: (x / surface.width) - .5,
            y: (y / surface.height) - .5
        };
    }

    /**
     * Whether something is within a threshold.
     *
     * @param {Number} a
     * @param {Number} b
     * @param {Number} threshold
     * @returns {Boolean}
     */
    isWithinThreshold (a, b, threshold) {
        return (Math.max(a, b) - Math.min(a, b)) <= threshold;
    }

    /**
     * Returns a point given a specific event.
     *
     * @param {Event} event
     * @param {ClientRect} surface
     * @returns {Object}
     */
    getPointFromEvent(event, surface) {
        const pageX = event.pageX;
        const pageY = event.pageY;
        let x = this.getNormalisedPoint(((pageX - surface.left) / surface.width) - .5),
            y = this.getNormalisedPoint(((pageY - surface.top) / surface.height) - .5);

        return { x, y };
    }
}

app
    .service('pinService', PinService)
    .controller('PinController', PinController)
    .directive('pin', PinDirective);
