/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.factory('PPFile', function (PPModel) {
    class PPFile extends PPModel {
        /**
         * The file id.
         *
         * @type {String}
         */
        id = null;

        /**
         * The file extension.
         *
         * @type {String}
         */
        extension = null;

        /**
         * The file name (with extension).
         *
         * @type {String}
         */
        name = null;

        /**
         * The number of chunks (for download) in the file.
         *
         * @type {Number}
         */
        chunks = 0;

        /**
         * The file object for the attachment.
         *
         * @type {Blob|File}
         */
        file = null;

        /**
         * Gets the file's full filename.
         *
         * @returns {string}
         */
        getFilename() {
            return this.name;
        }

        /**
         * Update the proof comment data from a file.
         *
         * @param {File} file
         */
        updateFromFile (file) {
            this.$file = file;
            this.name = file.name;
            this.extension = getFileType(file.name);
        }

        /**
         * Creates a PPFile from a PPProof.
         *
         * @param {PPProof} proof
         * @returns {PPFile}
         */
        static fromProofData (proof) {
            let file = new PPFile();
            file.id = proof.fileId;
            file.name = proof.title;
            file.extension = proof.extension;
            file.chunks = proof.chunks;
            file.size = proof.fileSize;
            return file;
        }
    }

    return PPFile;
});
