/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

class AttachmentService {
    /**
     * @param {Object} $q
     * @param {Object} callbackService
     * @param {Object} UserService
     * @constructor
     */
    constructor ($q, callbackService, sdk, UserService) {
        this.$$ = {$q, callbackService, sdk};

        this.application = UserService;
        this.proof = new Proof(this.application);
    }

    /**
     * Sends an array of files as comment attachments/snapshot to the server.
     *
     * @param {PPProofComment} comment
     * @param {Array<PPProofCommentAttachment|PPProofCommentSnapshot>} attachments
     * @returns {$q}
     */
    upload (comment, attachments) {
        if (!attachments.length) {
            return Promise.resolve();
        }
        const assignedType = Object.getPrototypeOf(attachments[0]).constructor.type;

        const attachmentArr = attachments.map(attachment => ({
            name: attachment.name,
            contents: attachment.$file,
            async: true,
            download: true,
            assignedTo: comment.id,
            assignedType: assignedType,
            onFileDetails: (fileDetails) => {
                attachment.id = fileDetails.id;
            },
            onProgress: (percent) => {
                if (percent === 100) {
                    delete attachment.$progress;
                    attachment.status = 'Queued'; // This will straight away update the attachment object to display
                    attachment.chunks = 1; // Setting chunks to 1 On proof page it will be updated with acutal number on recent endpoint call, for compare page we don't have recent endpoint call
                } else {
                    attachment.$progress = percent;
                }
            },
        }));
        const uploadOptions = {
            progressDecimalPlaces: 10,
        };

        return this.$$.sdk.files.upload(
            attachmentArr,
            uploadOptions
        ).then((_files) => {
            return _files;
        });
    }

    /**
     * Downloads and decrypts a single page preview of an attachment.
     *
     * @param {String} id
     * @param {Number} [pageNumber]
     */
    preview (id, pageNumber = 1) {
        return this.$$.sdk.files.preview(id, pageNumber, 'low')
            .then((blob) => URL.createObjectURL(blob));
    }

    downloadFile(fileId, fileName) {
        let complete = false;
        const deferred = this.$$.$q.defer();
        const cancel = () => {
            if (!complete) {
                deferred.reject(Error('Cancelled before file was downloaded & decrypted.'));
            }
        };

        this.$$.sdk.files.download({
                fileId,
                onProgress: (percent) => {
                    deferred.notify(percent);
                },
                onFileDetails: (fileDetails) => {
                    fileName = fileDetails.name;
                },
            })
            .then((file) => {
                complete = true;
                deferred.resolve(file);
                window.downloadFile(fileName, file);
            })
            .catch(err => deferred.reject(err));

        return {
            cancel,
            promise: deferred.promise
        };
    }

    /**
     * Downloads an entire full chunked file.
     *
     * @param {string} proofId
     * @param {string} fileId
     * @param {number} chunkCount
     * @param {string} fileType
     * @param {string|null} [download]
     * @returns {{cancel: function, promise: {then: function, catch: function, finally: function}}}
     */
    download(proofId, fileId, chunkCount, fileType, download = null) {
        const deferred = this.$$.$q.defer();
        let active = true;

        this.$$.sdk.files.download({
            fileId: fileId,
            onProgress: (percentage) => {
                if (active) {
                    deferred.notify(percentage);
                }
            },
            silent: download === null,
        }).then((blob) => {
            if (active) {
                const file = new Blob([blob], {type: fileType});
                deferred.resolve(file);

                if (download) {
                    downloadFile(download, file);
                }
            }
        }).catch((err) => {
            deferred.reject(err);
        });

        return {
            cancel: () => {
                if (active) {
                    active = false;
                    deferred.reject(new Error('File download cancelled before it could complete.'));
                }
            },
            promise: deferred.promise
        };
    }
}

app
    .service('attachmentService', AttachmentService)
    .service('fileService', AttachmentService); // Can be used for downloading any file too
