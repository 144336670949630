/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
function FlyoutSpacerDirective () {
    return {
        template: `<div class="app__flyout__spacer"></div>`,
        replace: true,

        link (scope, element, attr) {
            let buffer = attr.buffer;

            // scope.$watch('pageHeight', (height) => {
            const height = angular.element('#main').height();
            element.css({ height: height - buffer });
            // });
        }
    };
}

app
    .directive('flyoutSpacer', FlyoutSpacerDirective);