/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
angular
    .module('createProof')
    .run(function (utilService) {
        window.__md5 = utilService.md5;
        window.__sha512 = utilService.sha512;
    })
    .factory('utilService', function ($q, eventService) {
        return {
            /**
             * Parses a string url into it's url segments.
             *
             * @param {String} url
             * @returns {Object}
             */
            parseUrl: function (url) {
                var tmp = document.createElement('a');
                tmp.href = url;

                try {
                    return {
                        protocol: tmp.protocol,
                        hostname: tmp.hostname,
                        port: tmp.port,
                        pathname: tmp.pathname,
                        search: tmp.search,
                        hash: tmp.hash,

                        href: tmp.href
                    };
                } finally {
                    tmp = null;
                }
            },
            /**
             * Stringifies a url object.
             *
             * @param {Object} url
             * @returns {String}
             */
            stringifyUrl: function (url) {
                //tmp.protocol = url.protocol; // https:
                //tmp.hostname = url.hostname; // www.example.com
                //tmp.port = url.port;         // 8080
                //tmp.pathname = url.pathname; // /example/page.php
                //tmp.search = url.search;     // ?foo=bar&test=world
                //tmp.hash = url.hash;         // #hello/world

                return url.protocol + '//' + url.hostname + ((url.port === '443' || url.port === '80' || ! url.port) ? '' : (':' + url.port)) + url.pathname + url.search + url.hash;

                // https://www.example.com:8080/example/page.php?foo=bar&test=world#hello/world
            },
            /**
             * Loads a script element into the page.
             *
             * @param {String} url
             * @param {Object} [attrs]
             * @returns {$q}
             */
            loadScript: function (url, attrs) {
                return $q(function (resolve, reject) {
                    var element = angular.element(document.createElement('script'));

                    eventService.on(element, 'load', function (event) {
                        resolve();
                    });

                    eventService.on(element, 'error', function (err) {
                        reject(err);
                    });

                    element.appendTo('head');
                    element.prop({ src: url, async: 'async' });

                    if (angular.isObject(attrs)) {
                        element.attr(attrs);
                    }
                });
            },
            /**
             * Loads a link (stylesheet) element into the page.
             *
             * Note: Doesn't resolve correct (yet).
             *
             * @param {String} url
             * @param {Object} [attrs]
             * @returns {$q}
             */
            loadStyle: function (url, attrs) {
                return $q(function (resolve, reject) {
                    var element = angular.element(document.createElement('link'));

                    eventService.on(element, 'load', function () {
                        resolve();
                    });

                    eventService.on(element, 'error', function (err) {
                        reject(err);
                    });

                    element.appendTo('head');
                    element.prop({href: url, rel: 'stylesheet'});

                    if (angular.isObject(attrs)) {
                        element.attr(attrs);
                    }
                });
            },
            md5: function (data) {
                var md = forge.md.md5.create();
                md.update(data);
                return md.digest().toHex();
            },
            sha512: function (data) {
                var md = forge.md.sha512.create();
                md.update(data);
                return md.digest().toHex();
            },
            blobToDataURL: function(blob) {
                return $q(function (resolve, reject) {
                    blobToDataURL(blob, function(dataURL) {
                        if (dataURL) {
                            resolve(dataURL);
                        } else {
                            reject(Error('Unable to convert blob to data URL.'));
                        }
                    });
                });
            }
        };
    });
