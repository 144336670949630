/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class SpinnerController {
    enabled = false;
    center = false;
    message = null;
    overlay = false;
    white = false;
}

function SpinnerDirective () {
    return {
        require: ['spinner'],
        templateUrl: 'templates/partials/proof/components/spinner.html',
        controllerAs: 'spinnerCtrl',
        controller: 'SpinnerController',
        scope: true,

        link (scope, element, attrs, ctrls) {
            let [ spinnerCtrl ] = ctrls;

            scope.$watch(attrs.spinner, (enabled) => {
                if (attrs.spinner) {
                    spinnerCtrl.enabled = !! enabled;
                }
            });
        }
    };
}

function SpinnerOptionsDirective ($parse) {
    return {
        require: ['spinner'],

        link (scope, element, attrs, ctrls) {
            let [ spinnerCtrl ] = ctrls;

            let spinnerOptionsAttr = $parse(attrs.spinnerOptions);
            scope.$watch(() => spinnerOptionsAttr(scope), (options) => {
                Object.keys(options).forEach((key) => {
                    spinnerCtrl[key] = options[key];
                });
            }, true);
        }
    };
}

app
    .controller('SpinnerController', SpinnerController)
    .directive('spinner', SpinnerDirective)
    .directive('spinnerOptions', SpinnerOptionsDirective);
