/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class CarouselController {
    count = 0;
    current = 0;
    direction = 'forward';
    nextId () {
        return this.count++;
    }
    setCurrent (id) {
        if (id !== this.current) {
            this.direction = id < this.current ? 'back' : 'forward';
            this.current = id;
        }
    }
    tick () {
        if (this.current < this.count - 1) {
            this.setCurrent(this.current + 1);
        } else {
            this.setCurrent(0);
        }
    }
    get ids () {
        return numbers(this.count);
    }
}

function CarouselDirective ($interval) {
    return {
        require: ['carousel'],
        transclude: true,
        controller: CarouselController,
        controllerAs: 'carouselCtrl',
        templateUrl: 'templates/partials/globals/carousel.html',
        link (scope, element, attr, [carouselCtrl]) {
            if (attr.interval) {
                let interval = $interval(() => carouselCtrl.tick(), Number(attr.interval));
                scope.$on('$destroy', () => $interval.cancel(interval));
            }
        }
    };
}

function CarouselItemDirective () {
    return {
        require: ['^carousel'],
        transclude: true,
        scope: true,
        templateUrl: 'templates/partials/globals/carousel-item.html',
        link (scope, element, attr, [carouselCtrl]) {
            scope.carouselId = carouselCtrl.nextId();
        }
    };
}

app
    .directive('carousel', CarouselDirective)
    .directive('carouselItem', CarouselItemDirective);