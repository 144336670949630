/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
let prepare$q = null;

class ZipService {
    constructor($q, utilService) {
        this.$$ = {
            $q,
            utilService,
        };
    }

    prepare() {
        if (!prepare$q) {
            prepare$q = (
                this.$$.utilService.loadScript('/dist/lib/zip.js').then(() =>
                this.$$.utilService.loadScript('/dist/lib/mime-types.js'))
            ).then(() => {
                zip.workerScriptsPath = '/dist/lib/';
            });
        }
        return prepare$q;
    }

    compress(files) {
        const deferred = this.$$.$q.defer();

        const fileNames = Object.keys(files);
        const fileCount = fileNames.length;
        let fileIndex = 0;

        const writer = new zip.BlobWriter();
        let zipWriter;

        function nextFile() {
            if (fileIndex >= fileCount) {
                createBlob();
                return;
            }
            const name = fileNames[fileIndex];
            const contents = files[name];
            console.log('add', name, contents);
            zipWriter.add(name, new zip.BlobReader(contents), () => {
                fileIndex++;
                nextFile();
            });
        }

        function createBlob() {
            zipWriter.close((blob) => {
                zipWriter = null;
                const downloadable = new Blob([blob], {type: 'application/octet-stream'});
                deferred.resolve(downloadable);
            });
        }

        zip.createWriter(writer, (writer) => {
            zipWriter = writer;
            nextFile();
        }, onerror);

        return deferred.promise;
    }

    extract(file) {
        const EXCLUDE_REGEX = /^__MACOSX\//g;
        const files = {};

        const deferred = this.$$.$q.defer();
        const notify = throttle(deferred.notify, 100);

        zip.createReader(new zip.BlobReader(file), (reader) => {
            reader.getEntries((entries) => {
                if (entries.length) {
                    let index = 0;

                    function next() {
                        const entry = entries[index];

                        if (!entry) {
                            reader.close();
                            deferred.resolve(files);
                            return;
                        }

                        if (entry.directory || entry.filename.match(EXCLUDE_REGEX)) {
                            return next(++index);
                        }

                        const name = '/' + entry.filename;
                        const type = zip.getMimeType(name);

                        entry.getData(new zip.BlobWriter(type), blob => {
                            notify((index + 1) / entries.length);
                            files[name] = blob;
                            next(++index);
                        });
                    }

                    next();
                }
            });
        });

        function hasTopLevel(filenames) {
            return filenames.some(name => {
                // A file at the top level should not have a slash in the path. Indicating it is a file, not a file within another folder.
                return name.substring(1).indexOf('/') === -1;
            });
        }

        function getFolderParts(name) {
            return name.substring(1).split('/').filter(a => a);
        }

        function hasSingleFolder(filenames) {
            let folder = null;
            return filenames.every(name => {
                const parts = getFolderParts(name);
                if (!folder) {
                    folder = parts[0];
                }
                // TODO Remove this when confirmed the code works on an actual malformed zip
                console.debug(folder, parts[0]);
                return folder === parts[0];
            });
        }

        function correctSingleFolder(files) {
            const obj = {};
            Object.keys(files).forEach(name => {
                const path = name.substring(name.substring(1).indexOf('/') + 1);
                obj[path] = files[name];
            });
            return obj;
        }

        return deferred.promise.then(files => {
            const paths = Object.keys(files);
            if (!hasTopLevel(paths) && hasSingleFolder(paths)) {
                return correctSingleFolder(files);
            }
            return files;
        });
    }
}

app.service('zipService', ZipService);