/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
var init = window.Intercom = function () {
    init.q.push(arguments);
};
init.q = [];
window.addEventListener('load', function () {
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://widget.intercom.io/widget/' + env('intercom_key');
    var x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
}, false);

IntercomFashion.init();

IntercomFashion.load('https://static-assets.pageproof.com/fonts/circular-pro.css', true);

IntercomFashion.config({
    userBubble: {
        color: '#138b3b',
        background: 'transparent',
        rounded: true
    },
    adminBubble: {
        color: '#0d6129',
        background: '#e7f3eb',
        rounded: true
    },
    newConversation: {
        color: 'white',
        background: '#138b3b'
    },
    header: {
        background: '#128438'
    },
    modal: {
        background: 'rgba(255, 255, 255, .9)'
    },
    conversation: {
        background: '#fff'
    }
});

IntercomFashion.style('' +
    '#intercom-container * {' +
        'font-family: \'Circular Pro\', sans-serif !important;' +
        'text-rendering: optimizeLegibility;' +
        'font-variant-ligatures: common-ligatures;' +
    '}' +
    '' +
    '#intercom-container .intercom-conversations-header-title {' +
    '    font-size: 21px;' +
    '    line-height: 36px;' +
    '}' +
    '' +
    '#intercom-container .intercom-conversations-header-app-name {' +
    '    display: none;' +
    '}' +
    '' +
    '#intercom-container .intercom-conversations-new-conversation-button {' +
    '    border-radius: 2px;' +
    '    padding-left: 25px;' +
    '    padding-right: 25px;' +
    '}' +
    '' +
    '#intercom-container .intercom-conversations-new-conversation-button span {' +
    '    padding-left: 0;' +
    '    background-image: none;' +
    '    font-weight: bold;' +
    '    text-transform: uppercase;' +
    '    font-size: 12px;' +
    '}' +
    '' +
    '#intercom-container .intercom-comment-container-user .intercom-comment {' +
    '    border: 1px solid #138b3b;' +
    '}' +
    '' +
    '#intercom-container .intercom-comment-container-admin .intercom-comment a:not(.intercom-messenger-card-button),' +
    '#intercom-container .intercom-comment-container-admin .intercom-comment li,' +
    '#intercom-container .intercom-comment-container-admin .intercom-comment:not(.intercom-comment-with-body) a,' +
    '#intercom-container .intercom-comment-container-admin .intercom-comment li a {' +
    '    color: #0d6129' +
    '}' +
    '' +
    '#intercom-container a.intercom-messenger-card-button-primary:hover {' +
'        background-color: #0d5e28;' +
    '}' +
    '' +
    '#intercom-container .intercom-comment-container-user .intercom-comment a,' +
    '#intercom-container .intercom-comment-container-user .intercom-comment li,' +
    '#intercom-container .intercom-comment-container-user .intercom-comment li a {' +
    '    color: #138b3b' +
    '}' +
    '' +
    '#intercom-container .intercom-notification-channels-title {' +
    '    text-align: center;' +
    '    font-size: 12px;' +
    '    font-weight: bold;' +
    '}' +
    '' +
    '#intercom-container .intercom-header-buttons-unread-count {' +
    '    background: #e51c23;' +
    '}' +
    '' +
    '#intercom-container .intercom-notification-channels-title {' +
    '    text-align: center;' +
    '    font-size: 14px;' +
    '    font-weight: bold;' +
    '}' +
    '' +
    '#intercom-container .intercom-reaction-picker .intercom-reaction,' +
    '#intercom-container .intercom-reaction-picker-reaction-selected .intercom-reaction {' +
    '    transform: scale(.8);' +
    '}' +
    '' +
    '#intercom-container .intercom-reaction-picker .intercom-reaction:hover,' +
    '#intercom-container .intercom-reaction-picker-reaction-selected .intercom-reaction-selected {' +
    '    transform: scale(1);' +
    '}' +
    '' +
    '#intercom-container .intercom-reaction-picker {' +
    '    padding-top: 0;' +
    '}' +
'');
