/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class BaseDashboardController extends BaseController {
    /**
     * The logged in user
     *
     * @type {PPUser}
     */
    user = null;

    constructor() {
        super();
        this.$$import(this.$$dependencies([
            'userService',
            'proofInfoService',
            '$timeout',
            'deferVisibleService',
        ]));

        this.user = this.$$.userService.getUser();
    }

    /**
     * Recalculates the visibility of proof tiles.
     */
    recalculateVisibility () {
        this.$$.$timeout(() => {
            this.$$.deferVisibleService.recalculate();
        });
    }
}

window.BaseDashboardController = BaseDashboardController;
