/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
app.filter('percent', function () {
    return (percent, {normalise} = {normalise: true}) => {
        if (typeof percent === 'number') {
            let value = Math.floor(percent * 100);
            if (normalise) {
                value = Math.max(Math.min(value, 100), 0);
            }
            return value + '%';
        } else {
            return '';
        }
    };
});
