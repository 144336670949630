/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

class ReportingController {
    constructor(
        $scope,
        seoService,
        sdk,
        appService,
        SegmentIo
    ) {
        this.$$ = {
            $scope,
            seoService,
            sdk,
            appService,
            SegmentIo
        };
        this.$$.seoService.set({
            title: 'reporting.menu.main',
            pageTitle: 'reporting.menu.main',
            translateTitle: true,
            translatePageTitle: true,
        });

        const props = this.props = {
            generateReport: this.generateReport,
            failed: false,
            requestFinished: true,
            loadReportConfigurations: this.loadReportConfigurations,
            reportConfigurations: null
        };

        this.load();
    }

    generateReport = (reportKey, options) => {
        this.props.requestFinished = false;
        this.props.failed = false;
        this.$$.SegmentIo.track(59);
        this.$$.sdk.reports.create(reportKey,
            options)
            .then((data) => {
                this.props.messageTranslationKey = data.data.translationKey || null;
                this.props.messageTranslationParams = data.data.translationParams || null;
                this.props.messageSeverity = data.data.severity || null;
                this.props.requestFinished = true;
                this.props.failed = false;
            })
            .catch((error) => {
                console.log(error);
                this.props.messageTranslationKey = error.response.data.translationKey || null;
                this.props.messageTranslationParams = error.response.data.translationParams || null;
                this.props.messageSeverity = error.response.data.severity || null;
                this.props.requestFinished = true;
                this.props.failed = true;
            }).then(() => {
                if(!this.$$.$scope.$phase) {
                    this.$$.$scope.$apply();
                }
            });
    }

    loadReportConfigurations = () => {
        this.$$.appService.showTranslatedLoaderMessage('loader-message.loading-please-wait');

        this.$$.sdk.reports.listConfigurations()
            .then((result) => {
                this.props.reportConfigurations = result.data;
                this.$$.appService.hideLoader();

            })
            .catch((error) => {
                console.log(error);
            }).then(() => {
                if(!this.$$.$scope.$phase) {
                    this.$$.$scope.$apply();
                }
            });
    }

    load = () => {
        this.$$.SegmentIo.track(58);
        this.loadReportConfigurations();
    }
}

app.controller(
    'ReportingController',
    ReportingController
);
