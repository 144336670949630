/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/**
* Created by chris on 5/04/16.
*/
const EXPIRE_TIMEOUT = env('user_expire_timeout', 24 * (60 * (60 * 1000))); // 24 hours (1 day)

class AddressBookRepository extends BaseDatabaseCache {
    constructor ($q, backendService, PPUserAvatar, userService) {
        super();
        this.$q = $q;
        this.backendService = backendService;
    }

    clearExpiredDatabaseEntries (db) {
        return db.addressbook.where('createdAt').belowOrEqual(Date.now() - EXPIRE_TIMEOUT).delete();
    }

    resolveFromDatabase (db, ownerId) {
        return db.addressbook.where('ownerId').equals(ownerId)
            .first((item) => {
                if(item) {
                    return item;
                } else {
                    throw new Error('Cannot resolve/find item in database.');
                }
            });
    }

    convertToArray (object) {
        var arr = [];
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                arr.push(object[key].email);
            }
        }
        return arr;
    };


    resolveFromServer (userId) {
        return this.backendService.fetch('addressBook.load').data()
            .then((data) => {
                console.log("address book resolving from server");
                if( data.length >= 0 ){ //check to see if response is a valid array
                    return {ownerId: userId, emails: data};
                }else{
                    throw new Error('Cannot fetch item from server.');
                }
            });
    }

    storeInDatabase (db, userId, emailData) {
        return db.addressbook.add(emailData);
    }

    removeFromDatabase (db, ownerId) {

        console.log("remove from db: ", ownerId);

        return db.addressbook.where('ownerId').equals(ownerId).delete();
    }

    //removeFromDatabaseMultipleConditions(db, info) {
    //    return db.addressbook.where('email').equals(info.email).each(function(result){
    //        if(result.ownerId == info.id){
    //            return db.addressbook.where('id').equals(result.id).delete().then(function(count){
    //            }).catch(function(err){
    //                throw new Error(err);
    //            });
    //        }
    //    });
    //}

    addToDatabase (db, info, data) {

        console.log("adding: ", info, data);

        return db.addressbook.add({ownerId:info, emails:data});
    }
}

app.service('addressBookRepositoryService', AddressBookRepository);

app.run(function(addressBookRepositoryService){
    window.$$addressBookRepositoryService = addressBookRepositoryService;
});
